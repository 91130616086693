import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Carousel(props) {
  const { t } = useTranslation();

  return (
    <section
      id="search_container"
      className="overflow-hidden"
      style={{
        background:
          "url(/static_img/main/main_carousel.png) bottom center / cover no-repeat",
      }}
    >
      <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
        <div className="container m-0 p-0">
          <div className="row m-0 p-0 mt-5">
            <div className="col-12 col-xl-7 m-0 p-0 text-center text-lg-left mt-3 px-lg-5">
              <p className="h2 text-shadow-md ">{t("mainCarouselTitle")}</p>
              <Link to="/SearchMap" className="btn_1 bg-buddible shadow">
                {t("mainCarouselLinkSearchMap")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
