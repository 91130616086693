import React from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();

export default function TourDetailCarousel(props) {
  const { t } = useTranslation();
  const { tourData } = props;

  const picLink = util.changedResourceUrl(tourData.rl_pic_main);

  return (
    <section
      className="parallax-window"
      data-parallax="scroll"
      data-natural-width="1400"
      data-natural-height="600"
      style={{
        height: 600,
        background: `url(${picLink})50% 50%/cover no-repeat`,
      }}
    >
      <div className="parallax-content-2">
        <div className="container">
          <div className="row align-items-end">
            <div className="order-last order-md-first col-12 col-md-8">
              <h1 className="mb-2">{tourData.rl_title}</h1>
              <span className="d-inline-block mr-2 mb-2 font-weight-bold">
                {t("code" + tourData.rl_subject)}
              </span>
              <span className="d-inline-block mr-2 mb-2">
                {tourData.rl_addr_full}
              </span>
              <span className="rating mb-2">
                <i
                  className={
                    tourData.rl_rating > 0
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    tourData.rl_rating > 1
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    tourData.rl_rating > 2
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    tourData.rl_rating > 3
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    tourData.rl_rating > 4
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />

                <small>
                  ({tourData.rl_rating_cnt ? tourData.rl_rating_cnt : t("new")})
                </small>
              </span>
            </div>
            <div className="order-first order-md-last col-12 col-md-4 mb-1 mb-md-0">
              <div id="price_single_main">
                {t("base")} {tourData.rl_guest_min || 1}
                {t("person")}
                <span className="text-white ml-2">
                  <sup>&#8361;</sup>
                  {util.addCommas(tourData.rl_price_base)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
