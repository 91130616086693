import React from "react";

export default function GuideRegistSafetyChangePlace(props) {
  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/safety4GuideLine.jpg")50% 50%/cover no-repeat`,
          }}
        />
      </div>
      <div>
        <p>
          야외, 공공장소, 실내 등 가이드 진행 장소가 어디든 여행 운영 방식을
          일부 변경해야 할 것입니다.
        </p>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">·</div>
              <p>
                공공장소에서 진행되는 여행이라면, 사람이 너무 붐비지 않는 곳으로
                모이는 장소나 방문 장소를 변경하세요. 한 장소에 오래 머물 경우
                바이러스 전파 위험이 높아지므로, 자주 이동하는 것이 좋습니다.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">·</div>
              <p>
                가이드 진행 장소가 실내라면, 꼭 필요한 공간 이외에는 게스트
                출입을 제한하세요.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">·</div>
              <p>
                실내에서 진행하는 여행이라면 충분한 물리적 거리를 확보할 수
                있도록 장소를 야외로 옮기는 것을 고려해보세요.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
