import React, { useEffect, useState } from "react";

import UploadImageToS3WithReactS3 from "../../../../../lib/UploadImageToS3WithReactS3";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "GuideRegistSubmitUserInfo";

let userInfo = {
  gl_pic_social1: "", //신분증
  gl_pic_social2: "",
};

export default function GuideRegistSubmitUserInfo(props) {
  const { guideData, loginInfo } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (guideData) {
      for (let key in userInfo) {
        userInfo[key] = guideData.hasOwnProperty(key)
          ? guideData[key]
          : userInfo[key];
      }
    }
  }, []);

  // 이미지 업로드
  const userCardUpload = (key, res) => {
    if (!res.ret) {
      codeList.Modal.current.alert(
        "이미지 업로드중 오류가 발생했습니다. 다시 시도해주세요."
      );
      return;
    }
    let img = `${res.realFileName}?${res.fileName}`;
    handleUserInfoChange(key, img);
  };

  //사용자 정보 변경
  const handleUserInfoChange = (key, value) => {
    userInfo = {
      ...userInfo,
      [key]: value,
    };
    setIsNeedUpdate(!isNeedUpdate);
    guideDataChange();
  };

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      userInfo,
      () => {}
    );
  };
  return (
    <div className="col-xl-6">
      <div className="d-flex flex-row text-danger">
        <p className="mr-2">※</p>
        <p>신분증의 앞면, 뒷면을 모두 제출해주시기 바랍니다.</p>
      </div>
      <div className="py-3">
        <div className="mt-3 border rounded p-3">
          <h6 className="mb-2">정부 발급 신분증 인증하기</h6>
          <p>
            이는 모든 가이드에게 요구되는 사항입니다. 완료되면 이 페이지로
            자동으로 리디렉션됩니다. 운전 면허증, 신분증 둘다 가능 합니다.
          </p>
          <div className="d-flex flex-column flex-md-row mt-2">
            <div className="flex-grow-1 mr-md-1 mb-2 mb-md-0">
              <UploadImageToS3WithReactS3
                region={`image/guide/${loginInfo.u_email}`}
                callback={(res) => userCardUpload("gl_pic_social1", res)}
                disabled={guideData.gl_confirm_admin === "S"}
              >
                <span className="btn_1 mb-2">신분증 앞면</span>
              </UploadImageToS3WithReactS3>
              <div
                className="rounded border"
                style={{
                  height: 150,
                  background: `url(${
                    userInfo.gl_pic_social1
                      ? userInfo.gl_pic_social1.split("?")[1]
                      : ""
                  })50% 50%/contain no-repeat`,
                }}
              />
            </div>
            <div className="flex-grow-1 ml-1 ">
              <UploadImageToS3WithReactS3
                region={`image/guide/${loginInfo.u_email}`}
                callback={(res) => userCardUpload("gl_pic_social2", res)}
                disabled={guideData.gl_confirm_admin === "S"}
              >
                <span className="btn_1 mb-2">신분증 뒷면</span>
              </UploadImageToS3WithReactS3>
              <div
                className="rounded border"
                style={{
                  height: 150,
                  background: `url(${
                    userInfo.gl_pic_social2
                      ? userInfo.gl_pic_social2.split("?")[1]
                      : ""
                  })50% 50%/contain no-repeat`,
                }}
              />
            </div>
          </div>
        </div>
        {/*
        <div className="mt-3 border rounded p-3">
          <h6>대학교 학생증 인증하기</h6>
          <p>
            이는 모든 가이드에게 요구되는 사항입니다. 완료되면 이 페이지로
            자동으로 리디렉션됩니다.
          </p>
          <div className="d-flex flex-row mt-2">
            <div className="flex-grow-1 mr-1 ">
              <UploadImageToS3WithReactS3
                region="img/guideInfo"
                callback={(res) => userCardUpload("gl_pic_university1", res)}
                disabled={guideData.gl_confirm_admin === "S"}
              >
                <span className="btn_1">학생증 앞면</span>
              </UploadImageToS3WithReactS3>
              <div
                className="rounded border"
                style={{
                  height: 150,
                  background: `url(${
                    userInfo.gl_pic_university1
                      ? userInfo.gl_pic_university1.split("?")[1]
                      : ""
                  })50% 50%/contain no-repeat`,
                }}
              />
            </div>
            <div className="flex-grow-1 ml-1 ">
              <UploadImageToS3WithReactS3
                region="img/guideInfo"
                callback={(res) => userCardUpload("gl_pic_university2", res)}
                disabled={guideData.gl_confirm_admin === "S"}
              >
                <span className="btn_1">학생증 뒷면</span>
              </UploadImageToS3WithReactS3>
              <div
                className="rounded border"
                style={{
                  height: 150,
                  background: `url(${
                    userInfo.gl_pic_university2
                      ? userInfo.gl_pic_university2.split("?")[1]
                      : ""
                  })50% 50%/contain no-repeat`,
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-3 border rounded p-3">
          <h6>대학교 재학증명서 인증하기</h6>
          <p>
            이는 모든 가이드에게 요구되는 사항입니다. 완료되면 이 페이지로
            자동으로 리디렉션됩니다.
          </p>
          <div className="d-flex flex-row mt-2">
            <div className="flex-grow-1 ml-1 " style={{ maxWidth: 350 }}>
              <UploadImageToS3WithReactS3
                region="img/guideInfo"
                disabled={guideData.gl_confirm_admin === "S"}
                callback={(res) =>
                  userCardUpload("gl_pic_university_doc1", res)
                }
              >
                <span className="btn_1">재학증명서</span>
              </UploadImageToS3WithReactS3>
              <div
                className="rounded border"
                style={{
                  height: 150,
                  background: `url(${
                    userInfo.gl_pic_university_doc1
                      ? userInfo.gl_pic_university_doc1.split("?")[1]
                      : ""
                  })50% 50%/contain no-repeat`,
                }}
              />
            </div>
          </div>
        </div>*/}
      </div>
    </div>
  );
}
