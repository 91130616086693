import React, { useEffect, useState } from "react";

import GuideTimeRegistNav from "./component/GuideTimeRegistNav";
import GuideTimeRegistSubject from "./component/guideTimeRegistSubject/GuideTimeRegistSubject";
import GuideTimeRegistPlace from "./component/guideTimeRegistPlace/GuideTimeRegistPlace";
import GuideTimeRegistPhoto from "./component/guideTimeRegistPhoto/GuideTimeRegistPhoto";
import GuideTimeRegistGuest from "./component/guideTimeRegistGuest/GuideTimeRegistGuest";
import GuideTimeRegistCost from "./component/guideTimeRegistCost/GuideTimeRegistCost";
import GuideTimeRegistReserve from "./component/guideTimeRegistReserve/GuideTimeRegistReserve";
import GuideTimeRegistSubmit from "./component/guideTimeRegistSubmit/GuideTimeRegistSubmit";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let pageOpen = {
  GuideTimeRegistSubject: true,
  GuideTimeRegistPlace: false,
  GuideTimeRegistPhoto: false,
  GuideTimeRegistGuest: false,
  GuideTimeRegistCost: false,
  GuideTimeRegistReserve: false,
  GuideTimeRegistSubmit: false,
};

let titles = {
  GuideTimeRegistSubject: "주제",
  GuideTimeRegistPlace: "장소",
  GuideTimeRegistPhoto: "사진",
  GuideTimeRegistGuest: "인원수",
  GuideTimeRegistCost: "기본시간 및 요금",
  GuideTimeRegistReserve: "예약 설정",
  GuideTimeRegistSubmit: "검토 및 제출",
};

let pages = [
  { type: "kind", kind: "myTravel", title: "나의 여행경로" },
  {
    type: "page",
    kind: "myTravel",
    title: "주제",
    key: "GuideTimeRegistSubject",
    component: (params) => <GuideTimeRegistSubject {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "장소",
    key: "GuideTimeRegistPlace",
    component: (params) => <GuideTimeRegistPlace {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "사진",
    key: "GuideTimeRegistPhoto",
    component: (params) => <GuideTimeRegistPhoto {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "인원수",
    key: "GuideTimeRegistGuest",
    component: (params) => <GuideTimeRegistGuest {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "기본시간 및 요금",
    key: "GuideTimeRegistCost",
    component: (params) => <GuideTimeRegistCost {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "예약설정",
    key: "GuideTimeRegistReserve",
    component: (params) => <GuideTimeRegistReserve {...params} />,
  },
  { type: "kind", kind: "submit", title: "서비스 제출" },

  {
    type: "page",
    kind: "submit",
    title: "검토 및 제출",
    key: "GuideTimeRegistSubmit",
    component: (params) => <GuideTimeRegistSubmit {...params} />,
  },
];
let activePage = "GuideTimeRegistSubject";
let activeMenu = "myTravel";
let loginInfo = null;
let partTimeDataFormat = {
  gpt_guide_uid: "",
  gpt_part_time_uid: "",

  gpt_title: "",
  gpt_addr_radius: 5,
  gpt_addr_lat: 0, //위도
  gpt_addr_lng: 0, //경도
  gpt_addr_name: "", //주소이름
  gpt_addr_country: "", //국가코드
  gpt_addr_postal_code: "", //우편번호
  gpt_addr_area_level_1: "", //서울특별시
  gpt_addr_sublocality_level_1: "", //송파구
  gpt_addr_sublocality_level_2: "", //잠실3동
  gpt_addr_premise: "", //27
  gpt_addr_place_id: "", //지역ID
  gpt_area_explain: "", //장소설명
  gpt_guide_explain: "", //가이드알아보는 설명

  gpt_pic_main: "", //대표이미지
  gpt_pics: "", //사진들 'a.jpg|b.jpg|c.jpg'

  gpt_guest_max: 0, //최대인원
  gpt_guest_base: 0, //기본인원
  gpt_price_base: 0, //기본가격
  gpt_price_over_hour: 0, //시간추가가격
  gpt_price_over_people: 0, //인원 추가가격

  gpt_hour_base: "", //기본시간
  gpt_time_start: "", //시작시간

  gpt_time_before: "", //예약전 시간
  gpt_time_add_guest: "", //인원추가전 시간
  gpt_request_yn: "", //예약요청여부

  gpt_agree_yn: "", //동의여부
};
let partTimeData = { ...partTimeDataFormat };
let mobile = false;
let isMobileBackup = false;

const _mClassName = "GuideTimeRegist";

export default function GuideTimeRegist(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  (() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      loginInfo = loginData;
      partTimeData.gpt_guide_uid = loginInfo.gl_guide_uid;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
        partTimeData.gpt_guide_uid = loginInfo.gl_guide_uid;
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
  })();

  useEffect(() => {
    handleMobileView();
    window.addEventListener("resize", handleMobileView);
    if (props) {
      if (typeof props.location.state === "object") {
        if (props.location.state.hasOwnProperty("gpt_part_time_uid")) {
          partTimeData.gpt_part_time_uid =
            props.location.state.gpt_part_time_uid;
          SELECT_GUIDE_PART_TIME_INFO();
        }
      } else {
        partTimeData.gpt_part_time_uid = util.makeUUIDv4();
        setIsLoading(false);
      }
    } else {
      partTimeData.gpt_part_time_uid = util.makeUUIDv4();
      setIsLoading(false);
    }
    return () => {
      window.removeEventListener("resize", handleMobileView);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );

      activePage = "GuideTimeRegistSubject";
      activeMenu = "myTravel";
      pageOpen = {
        GuideTimeRegistSubject: true,
        GuideTimeRegistPlace: false,
        GuideTimeRegistPhoto: false,
        GuideTimeRegistGuest: false,
        GuideTimeRegistCost: false,
        GuideTimeRegistReserve: false,
        GuideTimeRegistSubmit: false,
      };
      partTimeData = { ...partTimeDataFormat };
    };
  }, []);

  //화면 사이즈 확인
  const handleMobileView = () => {
    let windowWidth = window.innerWidth;

    mobile = windowWidth < 768;

    if (mobile !== isMobileBackup) {
      isMobileBackup = mobile;
      setIsOpen(false);
      setIsMobile(mobile);
    }
  };

  //이전에 저장된 파트타임 데이터 가져오기
  const SELECT_GUIDE_PART_TIME_INFO = () => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "GetGuidePartTimeInfoOnReg",
      data: {
        gpt_guide_uid: partTimeData.gpt_guide_uid,
        gpt_part_time_uid: partTimeData.gpt_part_time_uid,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ReturnData"].length) {
          let getData = newData["ReturnData"][0];
          for (let key in partTimeData) {
            partTimeData[key] = getData[key]
              ? getData[key]
              : partTimeDataFormat[key];
          }
          setIsLoading(false);
        } else {
          if (props) {
            if (typeof props.location.state === "object") {
              if (props.location.state.hasOwnProperty("gpt_part_time_uid")) {
                partTimeData.gpt_part_time_uid =
                  props.location.state.gpt_part_time_uid;
                SELECT_GUIDE_PART_TIME_INFO();
              }
            }
          }
        }
      }
    });
  };

  //페이지 데이터 입력정보 확인
  const isPageValidated = () => {
    switch (activePage) {
      case "GuideTimeRegistSubject":
        if (!partTimeData.gpt_title) return false;
        break;
      case "GuideTimeRegistPlace":
        if (
          !partTimeData.gpt_addr_lat &&
          !partTimeData.gpt_addr_lng &&
          !partTimeData.gpt_addr_radius &&
          !partTimeData.gpt_area_explain &&
          !partTimeData.gpt_guide_explain
        )
          return false;
        break;

      case "GuideTimeRegistPhoto":
        if (!partTimeData.gpt_pic_main || !partTimeData.gpt_pics) return false;
        break;

      case "GuideTimeRegistGuest":
        if (!partTimeData.gpt_guest_max || !partTimeData.gpt_guest_base)
          return false;
        break;

      case "GuideTimeRegistCost":
        if (
          !partTimeData.gpt_hour_base ||
          !partTimeData.gpt_time_start ||
          !partTimeData.gpt_price_base ||
          !partTimeData.gpt_price_over_hour ||
          !partTimeData.gpt_price_over_people
        )
          return false;
        break;

      case "GuideTimeRegistReserve":
        if (!partTimeData.gpt_time_before || !partTimeData.gpt_time_add_guest)
          return false;
        break;

      case "GuideTimeRegistSubmit":
        if (partTimeData.gpt_agree_yn !== "Y") return false;
        break;

      default:
        return true;
    }
    return true;
  };

  //내비 페이지 이동
  const handleChangeMenu = (kind, menuKey) => {
    if (menuKey !== "") {
      let pageOpenData = {};
      for (let key in pageOpen) {
        pageOpenData[key] = key === menuKey;
      }
      if (!isPageValidated()) {
        codeList.Modal.current.alert("현재 페이지의 정보를 입력해주세요.");
        return;
      }
      pageOpen = pageOpenData;
      activePage = menuKey;
    }
    activeMenu = kind;
    setIsNeedUpdate(!isNeedUpdate);
    // InsertGuideRouteInfo();
  };

  //하단 페이지 이동
  const handlePageChange = (move) => {
    if (!isPageValidated()) {
      codeList.Modal.current.alert(
        "현재 페이지의 정보 입력후 페이지 이동이 가능합니다."
      );
      return;
    }

    let pageOpenData = [];
    for (let key in pageOpen) {
      pageOpenData.push(key);
    }
    let currentIndex = pageOpenData.findIndex((item) => item === activePage);

    let nextPage = pageOpenData[currentIndex];
    if (move === "next") {
      nextPage = pageOpenData[currentIndex + 1];
    } else if (move === "prev") {
      nextPage = pageOpenData[currentIndex - 1];
    }
    let currentPage = null;
    pages.forEach((item) => {
      if (item.hasOwnProperty("key") && item.key === nextPage) {
        currentPage = item;
      }
    });
    let changePageOpen = {};
    for (let key in pageOpen) {
      changePageOpen[key] = key === nextPage;
    }
    activeMenu = currentPage.kind;
    pageOpen = changePageOpen;
    activePage = nextPage;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //저장 후 나가기
  const handleSaveOut = () => {
    codeList.Modal.current.alert(
      "제출후 나가기를 원하신다면 확인을 눌러주세요.",
      (e) => {
        if (e) {
          INSERT_GUIDE_PART_TIME_INFO(() => {
            props.history.push("/GuideManagement");
          });
        }
      }
    );
  };
  //투어 등록데이터 체인지
  const guideTimeRegistDataChange = (val) => {
    partTimeData = {
      ...partTimeData,
      ...val,
    };
  };

  //파트타임 데이터 저장
  const INSERT_GUIDE_PART_TIME_INFO = (callback) => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "InsertGuidePartTimeInfo",
      data: {
        ...partTimeData,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          if (callback) {
            callback();
          }
        } else {
          codeList.Modal.current.confirm(
            "오류로 인해 저장되지 않았습니다. 나가시겠습니까?",
            (ret) => {
              if (ret) {
                props.history.push("/GuideManagement");
              }
            }
          );
        }
      }
    });
  };

  return (
    <div className="bg-white d-flex flex-row justify-content-start w-100 vh-100">
      <div
        style={{ zIndex: 9 }}
        className={
          !isMobile
            ? "d-block"
            : isOpen
            ? "d-block position-fixed h-100 bg-white"
            : "d-none"
        }
      >
        <GuideTimeRegistNav
          pages={pages}
          activeMenu={activeMenu}
          pageOpen={pageOpen}
          handleChangeMenu={handleChangeMenu}
          handleSaveOut={handleSaveOut}
          {...props}
        />
      </div>
      <div className="flex-fill d-flex flex-column justify-content-between">
        <div className="m-3 p-3 border-bottom d-flex flex-row justify-content-between align-items-center">
          <p className="h4 font-weight-bold">{titles[activePage]}</p>
          <span
            className="h4 cursor_pointer"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <i
              className={`${
                isMobile
                  ? isOpen
                    ? "fa-solid fa-xmark"
                    : "fa-solid fa-bars"
                  : "d-none"
              }`}
            />
          </span>
        </div>
        {!isLoading &&
          pages.map((item, index) => {
            if (item.type === "page") {
              return (
                <div
                  key={item.kind + index}
                  className={`container-fluid flex-fill overflow_scroll_y p-5 ${
                    pageOpen[item.key] ? "d-block" : "d-none"
                  }`}
                >
                  <div className="row">
                    <div className="col-xl-3" />
                    {item.component({
                      partTimeData: partTimeData,
                      loginInfo: loginInfo,
                      guideTimeRegistDataChange: guideTimeRegistDataChange,
                    })}
                    <div className="col-xl-3" />
                  </div>
                </div>
              );
            }
          })}
        <div className="border-top p-3 d-flex flex-row justify-content-between">
          {activePage !== "GuideTimeRegistSubject" ? (
            <div
              className="btn_1 white border"
              onClick={() => {
                handlePageChange("prev");
              }}
            >
              이전
            </div>
          ) : (
            <div />
          )}

          {activePage !== "GuideTimeRegistSubmit" ? (
            <div
              className="btn_1"
              onClick={() => {
                handlePageChange("next");
              }}
            >
              다음
            </div>
          ) : (
            <div
              className="btn_1"
              onClick={() => {
                handleSaveOut();
              }}
            >
              제출
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
