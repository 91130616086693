import React, { useCallback, useEffect, useState } from "react";

import ChatList from "./component/ChatList";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import ChatRoom from "./component/ChatRoom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "Chat";

let isLoad = true;
let _loginInfo = null;

let take = 50;
let skip = 0;
let isMsgRequestActive = true;
let _chatList = {};

export default function Chat() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const [activeKey, setActiveKey] = useState("");

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        _loginInfo = { ...n };
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_CHAT_MESSAGE_SEND,
      _mClassName,
      (b, n) => {
        if (!n) return;
        sendMsgByUser(n);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_CHAT_REQUEST_BEFORE_MESSAGE,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!isMsgRequestActive) return;
        skip = n.msgList.length;
        getChatDataByUser(n);
        // sendMsgByUser(n);
      }
    );
  })();

  useEffect(() => {
    const loginInfo = socket.getLocalDataSet(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS
    );
    if (loginInfo) {
      _loginInfo = { ...loginInfo };
    }

    if (_loginInfo === null) {
      console.log(socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS));
      console.log("로그인이 필요한 서비스입니다.");
      alert("로그인이 필요한 서비스입니다.");
      window.location.replace("/");
    }

    getChatListByUser((newData) => {
      let chatList = {};
      for (let item of newData["returnData"]) {
        chatList[item.crm_main_uid] = {
          ...item,
          msgList: [],
        };
      }
      _chatList = { ...chatList };
      isLoad = false;
      setIsNeedUpdate(!isNeedUpdate);
    });

    return () => {
      take = 50;
      skip = 0;
      isMsgRequestActive = true;
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_CHAT_MESSAGE_SEND,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_CHAT_REQUEST_BEFORE_MESSAGE,
        _mClassName
      );
    };
  }, []);

  // 채팅 목록 요청
  const getChatListByUser = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/chat/JS_getChatListByUser.php",
      msgID: msgID,
      data: {},
      crm_guide_uid: _loginInfo.gl_guide_uid,
      crm_creator_uid: _loginInfo.u_uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 채팅방 메세지 목록 요청
  const getChatDataByUser = (data, callback) => {
    if (data.crm_main_uid !== activeKey) {
      take = 50;
      skip = 0;
      isMsgRequestActive = true;
      _chatList[data.crm_main_uid].msgList = [];
    } else {
      return;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/chat/JS_getChatDataByUser.php",
      msgID: msgID,
      data: {},
      crt_main_uid: data.crm_main_uid,
      u_uid: _loginInfo.u_uid,
      take: take,
      skip: skip,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          _chatList[data.crm_main_uid].msgList = [
            ...newData["returnData"].reverse(),
            ..._chatList[data.crm_main_uid].msgList,
          ];

          isMsgRequestActive = !(newData["returnData"].length === 0);
          setIsNeedUpdate(!isNeedUpdate);
          if (activeKey !== data.crm_main_uid) {
            setActiveKey("");
            setActiveKey(data.crm_main_uid);
          }
        }
      }
    });
  };

  // 메세지 전송(임시)
  const sendMsgByUser = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_sendInquiryMsg.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          _chatList[data.crm_main_uid].msgList = [
            ..._chatList[data.crm_main_uid].msgList,
            {
              ...data,
              isSender: true,
              crt_create_date: new Date(),
              crt_update_date: new Date(),
            },
          ];
          setIsNeedUpdate(!isNeedUpdate);
          if (activeKey !== data.crm_main_uid) {
            setActiveKey(data.crm_main_uid);
          }
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  // (모바일) 채팅방 닫기
  const closeChatRoom = () => {
    setActiveKey("");
  };

  if (isLoad) return <></>;

  const chatRoomProps = {
    _loginInfo: _loginInfo,
    item: _chatList[activeKey],
    sendMsgByUser: sendMsgByUser,
    closeChatRoom: closeChatRoom,
  };

  return (
    <div className="row m-0 p-0 vh-100 bg-white">
      <div className="col-12 col-lg-3 m-0 p-0 border-right">
        <ChatList _chatList={_chatList} getChatDataByUser={getChatDataByUser} />
      </div>
      <div className="d-none d-lg-block col-lg-9 overflow-hidden m-0 p-0">
        {activeKey !== "" && <ChatRoom {...chatRoomProps} />}

        {activeKey === "" && (
          <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
            <p className="h6 mb-0 font-weight-bold text-secondary">
              🔈 대화하고자 하는 채팅을 선택해주세요.
            </p>
          </div>
        )}
      </div>
      {activeKey !== "" && (
        <div className="d-block d-lg-none position-fixed w-100 h-100">
          <ChatRoom {...chatRoomProps} />
        </div>
      )}
    </div>
  );
}
