import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainLogoSVG from "../../../../lib/MainLogoSVG";

let mobile = false;
let isMobileBackup = false;

export default function GuideManagementNav(props) {
  let { pages, activePage, handleActivePageChange } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    handleMobileView();
    window.addEventListener("resize", handleMobileView);
    return () => {
      window.removeEventListener("resize", handleMobileView);
    };
  }, []);

  //모바일 화면여부 확인
  const handleMobileView = () => {
    let windowWidth = window.innerWidth;
    mobile = windowWidth <= 790;
    if (mobile !== isMobileBackup) {
      isMobileBackup = mobile;
      setIsMobile(mobile);
      setIsOpen(false);
    }
  };

  //모바일 내비 열림 닫힘
  const handleMobileMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const navPages = Object.values(pages);

  return (
    <>
      <div
        className={`bg-light position-fixed border-bottom w-100 ${
          isMobile ? "d-block" : "d-flex flex-row"
        }`}
        style={{ zIndex: 99 }}
      >
        <div className="d-flex flex-row justify-content-between">
          <Link
            to="/"
            className="d-flex flex-row align-items-center py-1 px-3 mr-3"
            style={{ height: "53px", width: "180px" }}
          >
            <MainLogoSVG />
          </Link>
          <div className={`py-3 px-4 ${isMobile ? "d-block" : "d-none"}`}>
            <i
              onClick={() => handleMobileMenuOpen()}
              className={`cursor_pointer ${
                isOpen ? "fa-solid fa-xmark" : "fa-solid fa-bars"
              }`}
            />
          </div>
        </div>

        <div
          className={`${
            isMobile ? (isOpen ? "d-block" : "d-none") : "d-flex flex-row"
          }`}
        >
          {navPages.map((page) => {
            return (
              <div
                key={`nav_${page.key}`}
                className={`py-3 px-4 font-weight-bold cursor_pointer ${
                  activePage === page.key ? "bg-buddible text-white" : ""
                }`}
                onClick={() => {
                  handleActivePageChange(page.key);
                  setIsOpen(false);
                }}
              >
                {page.title}
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg-light d-none flex-column flex-lg-row justify-content-between border-bottom">
        <div className="d-flex flex-column flex-lg-row">
          <div className="py-1 px-3 mr-3">
            <Link to="/">
              <div
                style={{
                  height: "100%",
                  width: "180px",
                  background: `url(/img/logo.png) 50% 50%/contain no-repeat`,
                }}
              />
            </Link>
          </div>
          {navPages.map((page) => {
            return (
              <div
                key={`nav_${page.key}`}
                className={`py-3 px-4 font-weight-bold cursor_pointer ${
                  activePage === page.key ? "bg-buddible text-white" : ""
                }`}
                onClick={() => {
                  handleActivePageChange(page.key);
                  setIsOpen(false);
                }}
              >
                {page.title}
              </div>
            );
          })}
        </div>
        <div className="py-3 px-4">
          <i className="fa-solid fa-user" />
        </div>
      </div>
    </>
  );
}
