import React, { useEffect, useState, useRef } from "react";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithReactS3 from "../../../lib/UploadImageToS3WithReactS3";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "MyPageReservationReviewWriteModal";

const serverDataFormat = {
  id: "",
  rrl_idx: "",
  rl_route_uid: "",
  rrl_reserve_uid: "",
  rrl_route_uid: "",
  rrl_user_uid: "",
  rrl_guide_uid: "",
  rrl_start_date: "",
  rrl_end_date: "",
  rrl_guest_adult_num: "",
  rrl_guest_child_num: "",
  rrl_guest_baby_num: "",
  rrl_payment_method: "",
  rrl_payment_card_num: "",
  rrl_payment_order_num: "",
  rrl_coupon_uid: "",
  rrl_cost_total: "",
  rrl_cost_discount: "",
  rrl_cost_guide: "",
  rrl_cost_fees_guide: "",
  rrl_cost_fees_guest: "",
  rrl_cost_paid: "",
  rrl_memo_guest: "",
  rrl_memo_guide: "",
  rrl_status: "",
  rrl_date_reserve: "",
  rrl_date_agree: "",
  rrl_date_cancel: "",
  rrl_cancel_reason: "",
  rrl_cancel_reason_memo: "",
  rrl_guest_over_cnt: "",
  rrl_cost_over_guest: "",
  rrl_cost_over_hour: "",
  rrl_create_date: "",
  rrl_modify_date: "",
  uc_card_name: "",
  uc_card_num: "",
  rl_idx: "",
  cc_ko_name: "",
  rl_pic_main: "",
  rl_title: "",
  rl_desc: "",
  rl_area_cd: "",
  rl_price_base: "",
  rl_addr_full: "",
  gl_name: "",
};

let serviceData = {
  ...serverDataFormat,
};
let uploadFile = "";

export default function MyPageReservationReviewWriteModal(props) {
  const { loginInfo, updateRouteReview } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const controller = {
    ur_rating: useRef(),
    ur_pics: useRef(),
    ur_review: useRef(),
  };

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_MY_PAGE_REVIEW_DATA_CHANED,
      _mClassName,
      (b, n) => {
        if (!n) return;
        handleServiceData(n);
      }
    );
  })();

  useEffect(() => {
    let data = socket.getLocalDataSet(
      MsgIDList.EVENT_MY_PAGE_REVIEW_DATA_CHANED
    );
    if (data) {
      handleServiceData(data);
    }

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_MY_PAGE_REVIEW_DATA_CHANED,
        _mClassName
      );
    };
  }, []);

  // 이용서비스 데이터 핸들러
  const handleServiceData = (data) => {
    serviceData = { ...data };
    if (data.ur_idx) {
      uploadFile = data.ur_pics;
      controller.ur_review.current.value = data.ur_review;
      controller.ur_rating.current.value = data.ur_rating;
      controller.ur_pics.current.value = data.ur_pics;
    }

    setIsNeedUpdate(!isNeedUpdate);
  };

  // 리뷰 이미지 업로드
  const handleFileUpload = (value) => {
    if (!value.ret) {
      codeList.Modal.current.alert(
        "업로드중 오류가 발생했습니다. 다시 시도해주세요."
      );
      return;
    }

    let fileLink = `${value.realFileName}?${value.fileName}`;
    uploadFile = uploadFile !== "" ? `${uploadFile}|${fileLink}` : fileLink;
    controller.ur_pics.current.value = uploadFile;
    setIsNeedUpdate((before) => !before);
  };

  // 별점 선택
  const handleSelectRating = (value) => {
    controller.ur_rating.current.value = Number(value);
    setIsNeedUpdate(!isNeedUpdate);
  };

  // 닫기
  const closeModal = () => {
    controller.ur_review.current.value = "";
    controller.ur_rating.current.value = 0;
    controller.ur_pics.current.value = "";
    uploadFile = "";
    serviceData = { ...serverDataFormat };
    setIsNeedUpdate((prev) => !prev);
    $("#myPageReservationReviewWriteModalClose").click();
  };

  const checkValidation = () => {
    if (Number(controller.ur_rating.current.value) === 0) {
      codeList.Modal.current.alert("별점을 선택해주세요");
      return true;
    }

    if (controller.ur_review.current.value.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요");
      return true;
    }
    return false;
  };

  // 저장
  const actionSave = () => {
    if (!serviceData.rl_route_uid) {
      codeList.Modal.current.alert(
        "오류가 발생했습니다. 다시 시도해주세요.",
        () => {
          closeModal();
        }
      );
      return;
    }

    if (checkValidation()) {
      return;
    }

    updateRouteReview(
      {
        ur_idx: serviceData.ur_idx,
        ur_service_type: "R",
        ur_service_uid: serviceData.rrl_route_uid,
        ur_reserve_uid: serviceData.rrl_reserve_uid,
        ur_user_uid: loginInfo.u_uid,
        ur_rating: controller.ur_rating.current.value,
        ur_pics: controller.ur_pics.current.value,
        ur_review: controller.ur_review.current.value,
        ur_create_date: new Date(),
        ur_update_date: new Date(),
      },
      () => {
        codeList.Modal.current.alert(
          "후기가 정상적으로 등록되었습니다.",
          () => {
            closeModal();
          }
        );
      }
    );
  };

  // 별점 생성
  const createRating = () => {
    let ratings = [];
    for (let i = 1; i <= 5; i++) {
      let selectedRating = controller.ur_rating.current
        ? controller.ur_rating.current.value
        : 0;
      let ratingClass =
        i <= selectedRating
          ? "fa-solid fa-star mr-1 cursor_pointer text-warning"
          : "fa-regular fa-star mr-1 cursor_pointer text-warning";
      ratings.push(
        <i
          className={ratingClass}
          key={i}
          onClick={() => handleSelectRating(i)}
        />
      );
    }
    return ratings;
  };

  return (
    <div
      className="modal fade"
      id="myPageReservationReviewWriteModal"
      tabIndex="-1"
      aria-labelledby="myPageReservationReviewWriteModalLabel"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id="myPageReservationReviewWriteModalLabel"
            >
              리뷰작성
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="myPageReservationReviewWriteModalClose"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="d-flex flex-row align-items-center border-bottom pb-3">
              <div
                className="rounded border mr-3"
                style={{
                  width: 80,
                  height: 80,
                  background: `url(${util.changedResourceUrl(
                    serviceData.rl_pic_main
                  )}) 50% 50% / cover no-repeat`,
                }}
              />
              <div>
                <p className="ellipsis_1 font-weight-bold">
                  {serviceData.rl_title}
                </p>
                <p className="ellipsis_2">{serviceData.rl_desc}</p>
              </div>
            </div>
            <div>
              <div className="row m-0 p-0 py-3">
                <input
                  type="text"
                  className="d-none"
                  ref={controller.ur_pics}
                  defaultValue={uploadFile}
                  readOnly={true}
                />

                {uploadFile !== "" && (
                  <div className={`col-12 pb-3 mb-2`}>
                    <div className="d-flex flex-row flex-wrap">
                      {uploadFile.split("|").map((file, index) => {
                        const url = util.changedResourceUrl(file);
                        return (
                          <div className="pr-1 pb-1" key={url}>
                            <div
                              className="rounded border"
                              style={{
                                background: `url(${url}) 50% 50% / cover no-repeat`,
                                height: 80,
                                width: 80,
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="col-2">
                  <p className="font-weight-bold">사진</p>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    className="d-none"
                    ref={controller.ur_pics}
                    readOnly={true}
                  />
                  <UploadImageToS3WithReactS3
                    region="user/review"
                    callback={handleFileUpload}
                    multi={true}
                  />
                </div>
              </div>
              <div className="row m-0 p-0 pb-3">
                <div className="col-2">
                  <p className="font-weight-bold">별점</p>
                </div>
                <div className="col-10">
                  <input
                    className="d-none"
                    type="number"
                    ref={controller.ur_rating}
                    readOnly={true}
                    defaultValue={0}
                  />
                  <div className="text-warning h6 mb-0">{createRating()}</div>
                </div>
              </div>
              <div className="row m-0 p-0">
                <div className="col-2">
                  <p className="font-weight-bold">리뷰</p>
                </div>
                <div className="col-10">
                  <textarea
                    rows={8}
                    className="form-control w-100"
                    placeholder="여행에서 느낀점을 자유롭게 적어주세요"
                    ref={controller.ur_review}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closeModal}
            >
              닫기
            </button>
            <button className="btn btn-primary" onClick={actionSave}>
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
