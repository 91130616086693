import React from "react";

import TourWithinHourToolbar from "./TourWithinHourToolbar";
import TourWithinHourContents from "./TourWithinHourContents";
import { useTranslation } from "react-i18next";

export default function TourWithinHour(props) {
  const { t } = useTranslation();
  const { routeItem } = props;

  return (
    <section className="container margin_60 font">
      <TourWithinHourToolbar {...props} />
      {routeItem.length > 0 && <TourWithinHourContents routeItem={routeItem} />}
      {routeItem.length === 0 && (
        <p className="h3 text-secondary text-center rounded-lg p-5 bg-white">
          {t("emptyServiceNote")}
        </p>
      )}
    </section>
  );
}
