import React, { useEffect, useState } from "react";
import Nav from "../common/Nav";
import TourDetailCarousel from "./compnent/TourDetailCarousel";
import TourDetailContent from "./compnent/TourDetailContent";
import Footer from "../common/Footer";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "TourDetail";
let loginInfo = null;

let tourData = null;
let reviewData = [];
let reserveData = {};

export default function TourDetail(props) {
  const { t } = useTranslation();
  const [isLoad, setIsLoad] = useState(true);

  (() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          loginInfo = userData;
        }
      }
    );
  })();

  useEffect(() => {
    if (!props.match.params.hasOwnProperty("rl_idx")) {
      codeList.Modal.current.alert(t("inaccessibleMsg"), () => {
        window.location.replace("/");
      });
    }
    // GetSingleDataForCheck();
    requestTourDetail((tour) => {
      tourData = tour;
      for (let i = 0; i < tourData.subData.length; i++) {
        tourData.subData[i].rs_addr_lat = parseFloat(
          tourData.subData[i].rs_addr_lat
        );
        tourData.subData[i].rs_addr_log = parseFloat(
          tourData.subData[i].rs_addr_log
        );
      }

      if (
        tour.rl_confirm_admin !== "Y" &&
        (!loginInfo || Number(loginInfo.u_Auth) !== 8)
      ) {
        alert(t("inaccessibleMsg"));
        window.location.replace("/");
      }

      requestReviewList(tour.rl_route_uid, (review) => {
        reviewData = review;
        requestTourReserveData(tour.rl_route_uid, (reserve) => {
          let temp = {};
          reserve.forEach((item) => {
            temp[item.rrl_start_date] = item.rrl_start_date;
          });
          reserveData = temp;
          setIsLoad(false);
        });
      });
    });

    return () => {
      tourData = null;
      reviewData = [];
      setIsLoad(true);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, [props.match.params.rl_idx]);

  const requestTourDetail = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_detail_select.php",
      msgID: msgID,
      rl_idx: Number(props.match.params.rl_idx),
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"].length) {
          callback && callback(newData["returnData"][0]);
        } else {
          codeList.Modal.current.alert(t("errorMsg"), () => {
            window.location.replace(window.location.href);
          });
        }
      }
    });
  };

  const requestReviewList = (uid, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reviewList/JS_user_review_list_select.php",
      msgID: msgID,
      ur_service_type: "R",
      ur_service_uid: uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData["returnData"]);
        }
      }
    });
  };

  const requestTourReserveData = (uid, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserveRoute/JS_reserve_route_select.php",
      msgID: msgID,
      rrl_route_uid: uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData["returnData"]);
        }
      }
    });
  };

  return (
    <>
      {!isLoad && tourData && (
        <>
          <Nav />
          <main className="bg-white">
            <TourDetailCarousel tourData={tourData} />
            <TourDetailContent
              tourData={tourData}
              loginInfo={loginInfo}
              reviewData={reviewData}
              reserveData={reserveData}
            />
          </main>
          <Footer />
        </>
      )}
    </>
  );
}
