import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router";
import "./CommonSlideNavbarCustomStyle.css";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
// import Utilities from "../lib/Utilities";
const socket = new BuddibleSocket();
const codeList = new CodeList();
// const util = new Utilities();

function CommonSlideNavbar(props) {
  // const navigate = useNavigate();
  let [loginInfo, setLoginInfo] = useState({ uLevel: "" });

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      "CommonSlideNavbar",
      (b, n) => {
        n.returnData[0].uLevel =
          n.returnData[0].comp_cd.indexOf("Z") > -1
            ? 1
            : n.returnData[0].comp_cd.indexOf("B") > -1
            ? 0
            : n.returnData[0].comp_cd.indexOf("A9100") > -1
            ? 3
            : -1;
        setLoginInfo(n.returnData[0]);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      "CommonSlideNavbar",
      (b, n) => {
        codeList.Modal.current.alert("로그인 후 이용해주십시요", () => {
          window.location.replace("/");
        });
      }
    );
  })();
  useEffect(() => {
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setLoginInfo({ ...userdata["returnData"][0] });
      }
    }

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        "CommonSlideNavbar"
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        "CommonSlideNavbar"
      );
    };
  }, []);

  const questionAddNewProduct = () => {
    codeList.Modal.current.confirm(
      "새 상품등록 신청을 진행하시겠습니까? 기 입력된데이터는 삭제됩니다.",
      (ret) => {
        if (ret) {
          socket.fireLocalEventListener(
            MsgIDList.EVENT_PRODUCT_DATA_CLEAR,
            "CommonSlideNavbar",
            "clear",
            () => {}
          );
        }
      }
    );
  };

  return (
    <>
      <ul
        id="accordionSidebar"
        className="navbar-nav sidebar sidebar-light accordion custom-navbar-slider"
      >
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="/main"
        >
          <div className="sidebar-brand-icon" />
          <div className="sidebar-brand-text font-weight-bold h4 mx-3">
            PLAY YEOUNGJONG
          </div>
        </a>

        {props.pages.map((data, index) => {
          if (!(loginInfo.uLevel < data.uLevel)) {
            if (data.hasOwnProperty("page")) {
              let callback =
                data.name === "AddProduct" ? questionAddNewProduct : null;

              return (
                <li
                  // key={data.name}
                  key={`slideNav_${index}`}
                  id={`btnNav${data.name}`}
                  className={`nav-item ${
                    props.pageOpen[data.name] ? "active" : ""
                  }`}
                  onClick={() => props.changedPage(data.name, callback)}
                >
                  <a className="nav-link" href="#">
                    <span>{data.title}</span>
                  </a>
                </li>
              );
            } else {
              return (
                <div
                  // key={data.name}
                  key={`slideNav_${index}`}
                >
                  <hr className="sidebar-divider" />
                  <div className="sidebar-heading">{data.title}</div>
                </div>
              );
            }
          }
        })}
      </ul>
    </>
  );
}

export default CommonSlideNavbar;
