import React, { useState } from "react";

import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const util = new Utilities();
const codeList = new CodeList();

export default function ChatRoomHeader(props) {
  const { item, closeChatRoom, openServiceInfo, setOpenServiceInfo } = props;

  let toUserName = item.isCreator ? item.crm_guide_name : item.crm_creator_name;
  let toUserPic = util.changedResourceUrl(
    item.isCreator ? item.crm_guide_avata_pic : item.crm_creator_avata_pic
  );
  if (toUserPic === "") {
    toUserPic = codeList.publicVar.unProfileImage;
  }

  return (
    <div className="px-3 py-2 border-bottom chat-header">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center">
          <img
            src={toUserPic}
            alt={toUserPic}
            className="rounded-circle avata_pic border square-30 mr-2"
          />
          <div>
            <p className="font-weight-bold">{item.crm_service_title}</p>
            <p>{toUserName}</p>
          </div>
        </div>
        <div className="d-flex flex-row position-relative">
          <button
            className="text-secondary square-30 bg-transparent child-center cursor_pointer_bg rounded-circle"
            onClick={() => setOpenServiceInfo((prevState) => !prevState)}
          >
            <i
              className={
                openServiceInfo ? "fa-solid fa-box-open" : "fa-solid fa-box"
              }
            />
          </button>
          <button
            className="d-lg-none text-secondary square-30 bg-transparent child-center cursor_pointer_bg rounded-circle ml-2"
            onClick={closeChatRoom}
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </div>
      </div>
    </div>
  );
}
