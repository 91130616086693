import React from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const util = new Utilities();
const codeList = new CodeList();

export default function GuideDetailCarousel(props) {
  const { guideData } = props;

  const picLink = util.changedResourceUrl(
    guideData.gl_pic_main || guideData.gl_pic
  );

  return (
    <section
      className="parallax-window"
      data-parallax="scroll"
      data-image-src={picLink}
      data-natural-width="1400"
      data-natural-height="600"
      style={{
        height: 600,
        background: `url(${picLink})50% 50%/cover no-repeat`,
      }}
    >
      <div className="parallax-content-2">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <h1>{guideData.gl_title}</h1>
              <span className="pr-2">{guideData.gl_country_cd}</span>
              <span className="pr-2">{guideData.gl_name}</span>
              <span className="rating">
                <i
                  className={
                    guideData.gl_rating > 0
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    guideData.gl_rating > 1
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    guideData.gl_rating > 2
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    guideData.gl_rating > 3
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />
                <i
                  className={
                    guideData.gl_rating > 4
                      ? "icon-star voted"
                      : "icon-star-empty"
                  }
                />

                <small>({guideData.gl_rating_cnt})</small>
              </span>
            </div>
            {/*
            <div className="col-md-4">
              <div id="price_single_main">
                <span className="text-white">
                  <sup>&#8361;</sup>
                  {util.addCommas(guideData.gl_price_base)}
                </span>
              </div>
            </div>
            */}
          </div>
        </div>
      </div>
    </section>
  );
}
