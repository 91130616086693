import React, { useCallback, useEffect, useState } from "react";

import GuideManagementNav from "./compnent/GuideManagementNav";
import GuideAccountManage from "./compnent/guideAccountManage/GuideAccountManage";
import GuideReserveManage from "./compnent/guideReserveManage/GuideReserveManage";
import GuideServiceManage from "./compnent/guideServiceManage/GuideServiceManage";
import GuideScheduleManage from "./compnent/guideScheduleManage/GuideScheduleManage";
import GuideScheduleEditModal from "./compnent/guideScheduleManage/GuideScheduleEditModal";

import Footer from "../../common/Footer";

import AutoLogin from "../../../lib/AutoLogin";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuideManagement";

const pages = {
  account: {
    title: "대시보드",
    key: "GuideAccountManage",
    component: (params) => <GuideAccountManage {...params} />,
  },
  reserved: {
    title: "예약 내역",
    key: "GuideReserveManage",
    component: (params) => <GuideReserveManage {...params} />,
  },
  service: {
    title: "서비스 관리",
    key: "GuideServiceManage",
    component: (params) => <GuideServiceManage {...params} />,
  },
  schedule: {
    title: "서비스 일정 관리",
    key: "GuideScheduleManage",
    component: (params) => <GuideScheduleManage {...params} />,
  },
  // revenue: {
  //   title: "실적",
  //   key: "GuideProfitManage",
  //   component: (params) => <GuideProfitManage {...params} />,
  // },
};

let activePage = "GuideAccountManage";
let loginInfo = null;
let guideInfo = null;
let isLoading = true; // 기본값 true
let guideRouteList = [];
let scheduleList = [];
let dashboardReserveCnt = {
  All: 0,
  CR: 0,
  GA: 0,
  CC: 0,
  GC: 0,
};
let dashboardReserveList = [];
let dashboardReferenceList = [];

export default function GuideManagement(props) {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  (() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      loginInfo = loginData;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
      }
    );
    let loginFail = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_FAIL);
    if (loginFail) {
      codeList.Modal.current.alert(
        "로그인 후 이용가능한 서비스 입니다.",
        () => {
          props.history.replace("/");
        }
      );
    }
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (b, n) => {
        codeList.Modal.current.alert(
          "로그인 후 이용가능한 서비스 입니다.",
          () => {
            props.history.replace("/");
          }
        );
      }
    );
  })();

  useEffect(() => {
    if (loginInfo !== null) {
      requestGuideInfo((newDate) => {
        guideInfo = newDate["returnData"][0];

        requestRouteServiceList((newData) => {
          guideRouteList = [...newData["returnData"]];
          requestServiceScheduleList((newData) => {
            scheduleList = [...newData["returnData"]];
            requestWeekReservationList("", (newData) => {
              newData["returnData"].forEach((item) => {
                dashboardReserveCnt["All"] = ++dashboardReserveCnt["All"];
                dashboardReserveCnt[item.rrl_status] = ++dashboardReserveCnt[
                  item.rrl_status
                ];
              });
              dashboardReserveList = newData["returnData"];
              requestGuideReference((newData) => {
                dashboardReferenceList = newData["returnData"];
                isLoading = false;
                setIsNeedUpdate(!isNeedUpdate);
              });
            });
          });
        });
      });
    }

    return () => {
      activePage = "GuideAccountManage";
      loginInfo = null;
      guideInfo = null;
      isLoading = true; // 기본값 true
      guideRouteList = [];
      scheduleList = [];
      dashboardReserveCnt = {
        All: 0,
        CR: 0,
        GA: 0,
        CC: 0,
        GC: 0,
      };
      dashboardReserveList = [];
      dashboardReferenceList = [];
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
    };
  }, []);

  // 예약내역 상태 변경
  const changedReservationStatus = (value) => {
    requestWeekReservationList(value, (newData) => {
      dashboardReserveList = newData["returnData"];
      setIsNeedUpdate(!isNeedUpdate);
    });
  };

  // 가이드 프로필 정보 받아오기
  const requestGuideInfo = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guideDetailSelect.php",
      msgID: msgID,
      data: {},
      gl_guide_uid: loginInfo.u_uid,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 이번주 예약 내역 받아오기(대시보드)
  const requestWeekReservationList = (searchState = "", callback) => {
    const today = new Date();
    let startDate = new Date();
    let theDayOfWeek = today.getDay();
    let endDate = +startDate + 1000 * 60 * 60 * 24 * (6 - theDayOfWeek);
    startDate.setDate(startDate.getDate() - theDayOfWeek);

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserve/JS_reserve_list_select.php",
      msgID: msgID,
      data: {},
      sort: [
        {
          field: "rrl_start_date",
          dir: "desc",
        },
      ],
      gl_guide_uid: loginInfo.u_uid,
      searchStartDate: util.getDateToFormat(startDate, "YYYY-MM-DD"),
      searchEndDate: util.getDateToFormat(endDate, "YYYY-MM-DD"),
      searchState: searchState,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 가이드 자료 및 팁 받아오기
  const requestGuideReference = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityMainSelect.php",
      msgID: msgID,
      data: {},
      take: 4,
      skip: 0,
      page: 0,
      sort: [
        {
          field: "cm_create_date",
          dir: "desc",
        },
      ],
      cm_use_yn: "Y",
      cm_del_yn: "N",
      unselect_type: "FORUM",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 투어 서비스 받아오기
  const requestRouteServiceList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_GetGuideRouteList.php",
      msgID: msgID,
      rl_guide_uid: loginInfo.u_uid,
      rl_del_yn: "N",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  // 투어 서비스 사용여부 업데이트
  const updateUseYNRouteService = (route, callback) => {
    //rl_use_yn

    let msg =
      route.rl_use_yn === "N"
        ? `[${route.rl_title}] 서비스의 사용을 중지하시겠습니까?\n중지할 경우 해당 서비스가 노출되지 않습니다. `
        : `[${route.rl_title}] 서비스를 open 하시겠습니까?`;

    codeList.Modal.current.confirm(msg, (ret) => {
      if (ret) {
        let msgID = util.makeUUIDv4();
        let socketMsg = {
          file: "/tour/JS_tour_detail_update.php",
          msgID: msgID,
          ...route,
        };

        socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
          if (newData) {
            if (newData["ret"]) {
              codeList.Modal.current.alert("정상 처리되었습니다.", () => {
                const updateIndex = guideRouteList.findIndex(
                  (item) => item.rl_idx === route.rl_idx
                );
                guideRouteList[updateIndex] = { ...route };
                setIsNeedUpdate(!isNeedUpdate);
              });
            } else {
              codeList.Modal.current.alert(
                "오류가 발생했습니다. 다시 시도해주세요."
              );
            }
          }
        });
      }
    });
  };

  //투어 서비스 삭제
  const deleteRouteService = (route, callback) => {
    if (!route) return;

    if (
      !route.hasOwnProperty("rl_route_uid") &&
      !route.hasOwnProperty("rl_guide_uid")
    )
      return;

    codeList.Modal.current.confirm(
      `[${route.rl_title}] 서비스를 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          let msgID = util.makeUUIDv4();
          let socketMsg = {
            file: "/tour/JS_tour_detail_update.php",
            msgID: msgID,
            ...route,
          };

          socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
            if (newData) {
              if (newData["ret"]) {
                codeList.Modal.current.alert("정상 삭제되었습니다.");
                const delIndex = guideRouteList.findIndex(
                  (item) => item.rl_idx === route.rl_idx
                );
                guideRouteList.splice(delIndex, 1);
                setIsNeedUpdate(!isNeedUpdate);
              } else {
                codeList.Modal.current.alert(
                  "오류가 발생했습니다. 다시 시도해주세요."
                );
              }
            }
          });
        }
      }
    );
  };

  // 등록 스케줄 가져오기
  const requestServiceScheduleList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tourSchedule/JS_tour_schedule_select.php",
      msgID: msgID,
      sl_guide_uid: loginInfo.u_uid,
      sl_del_yn: "N",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  // 등록 스케줄 데이터 업데이트
  const updateServiceSchedule = (updateData, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tourSchedule/JS_tourScheduleUpdate.php",
      msgID: msgID,
      ...updateData,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          requestServiceScheduleList((newData) => {
            scheduleList = newData["returnData"];
            setIsNeedUpdate((before) => !before);
            callback && callback(newData);
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  //활성 페이지 변경
  const handleActivePageChange = (key) => {
    activePage = key;
    setIsNeedUpdate(!isNeedUpdate);
  };

  const MappedComponent = useCallback(
    (props) => {
      const { page } = props;
      return (
        <div
          key={page.key}
          className={`${
            activePage === page.key ? "d-block flex-fill" : "d-none"
          }`}
        >
          {page.component({
            ...props,
          })}
        </div>
      );
    },
    [activePage]
  );

  if (isLoading) return <></>;

  const mappedPages = Object.values(pages);

  return (
    <>
      <AutoLogin />
      <main
        className="bg-white d-flex flex-column "
        style={{ minHeight: "100vh" }}
      >
        <GuideManagementNav
          pages={pages}
          activePage={activePage}
          handleActivePageChange={handleActivePageChange}
        />
        <div className="p-3 flex-fill d-flex" style={{ marginTop: 55 }}>
          {mappedPages.map((page) => {
            return (
              <MappedComponent
                key={page.key}
                page={page}
                loginInfo={loginInfo}
                guideInfo={guideInfo}
                dashboardReserveCnt={dashboardReserveCnt}
                dashboardReserveList={dashboardReserveList}
                dashboardReferenceList={dashboardReferenceList}
                changedReservationStatus={changedReservationStatus}
                guideRouteList={guideRouteList}
                updateUseYNRouteService={updateUseYNRouteService}
                scheduleList={scheduleList}
                updateServiceSchedule={updateServiceSchedule}
                deleteRouteService={deleteRouteService}
              />
            );
          })}
        </div>
      </main>
      <Footer />
      <GuideScheduleEditModal
        loginInfo={loginInfo}
        guideRouteList={guideRouteList}
        updateServiceSchedule={updateServiceSchedule}
      />
    </>
  );
}
