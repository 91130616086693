import React from "react";

export default function GuideRegistSafetyClean(props) {
  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/safety5GuideLine.jpg")50% 50%/cover no-repeat`,
          }}
        />
      </div>
      <div>
        <p>
          실내나 직접 관리하는 공간에서 가이드하는 경우, 시작 전후로 매번 공간을
          철저히 청소 및 살균소독해야 합니다.
        </p>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">· </div>
              <p>
                게스트 출입 공간 청소, 사용 도구 소독, 가이드 진행 중 입었던 옷
                세탁 등을 위해 체험 전후로 충분한 시간 간격을 두세요.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">· </div>
              <p>
                각 여행 회차 간 충분한 청소 및 소독 시간을 확보하려면 하루에
                진행하는 여행 횟수를 조정해야 할 수 있습니다.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
