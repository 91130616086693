import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";

const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "MapList";

let rl_guest_min = 1;
let rl_rating = 0;

export default function MapList(props) {
  const { t } = useTranslation();
  const { serviceList, changedFilters } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const SUBJECT_CODE = {
    NAT: t("codeNAT"),
    SOC: t("codeSOC"),
    SHO: t("codeSHO"),
    RES: t("codeRES"),
    BEC: t("codeBEC"),
    NMK: t("codeNMK"),
    BEV: t("codeBEV"),
    NVW: t("codeNVW"),
    OAT: t("codeOAT"),
    SPO: t("codeSPO"),
    ETM: t("codeETM"),
  };

  //지도 위치 변경
  const changedMapCenter = (lat, lng) => {
    let center = {
      lat: lat,
      lng: lng,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_SEARCHMAP_CENTER_CAHNGED,
      _mClassName,
      center,
      () => {}
    );
  };

  //MapList 컨텐츠 랜더
  const MapItemRender = useCallback(
    ({ item, SUBJECT_CODE, changedMapCenter }) => {
      return (
        <MapItem
          rl_idx={item.rl_idx}
          rl_subject={item.rl_subject}
          rl_pic_main={item.rl_pic_main}
          rl_price_base={item.rl_price_base}
          rl_title={item.rl_title}
          rl_rating={item.rl_rating}
          rl_rating_cnt={item.rl_rating_cnt}
          rl_desc={item.rl_desc}
          rl_addr_lat={item.rl_addr_lat}
          rl_addr_log={item.rl_addr_log}
          SUBJECT_CODE={SUBJECT_CODE}
          changedMapCenter={changedMapCenter}
        />
      );
    },
    []
  );

  return (
    <div
      className="col d-flex flex-column h-100 overflow-hidden"
      style={{ paddingTop: 90 }}
    >
      <div className="pb-3">
        <div className="pt-lg-4">
          <input
            className="form-control"
            placeholder={t("searchMapListInputPlaceholder")}
            onBlur={(e) => changedFilters({ SEARCH_WORD: e.target.value })}
            onKeyPress={(e) => {
              e.charCode === 13 &&
                changedFilters({ SEARCH_WORD: e.target.value });
            }}
            onChange={(e) => {
              e.target.value.trim() === "" &&
                changedFilters({ SEARCH_WORD: e.target.value });
            }}
          />
          <div className="d-flex flex-row justify-content-star mt-2">
            <div className="rounded-pill border py-1 px-3 mr-2 text-secondary bg-light font-weight-bold d-flex flex-row align-items-center">
              <span
                className="cursor_pointer h6 mb-0"
                onClick={() => {
                  if (rl_guest_min === 1) return;
                  --rl_guest_min;
                  changedFilters({ rl_guest_min: rl_guest_min });
                }}
              >
                -
              </span>
              <span className="font-size-08 mx-1">{rl_guest_min}인</span>
              <span
                className="cursor_pointer h6 mb-0"
                onClick={() => {
                  ++rl_guest_min;
                  changedFilters({ rl_guest_min: rl_guest_min });
                }}
              >
                +
              </span>
            </div>
            <div className="rounded-pill border py-1 px-3 mr-2 text-secondary bg-light font-weight-bold d-flex flex-row align-items-center">
              <span
                className="cursor_pointer h6 mb-0"
                onClick={() => {
                  if (rl_rating === 0) return;
                  --rl_rating;
                  changedFilters({ rl_rating: rl_rating });
                }}
              >
                -
              </span>
              <span className="font-size-08 mx-1">
                <i className="icon-star text-warning" />
                {rl_rating} 이상
              </span>
              <span
                className="cursor_pointer h6 mb-0"
                onClick={() => {
                  if (rl_rating === 5) return;
                  ++rl_rating;
                  changedFilters({ rl_rating: rl_rating });
                }}
              >
                +
              </span>
            </div>
            <div
              className="rounded-pill bg-danger py-1 px-3 mr-2 text-white font-weight-bold d-flex flex-row align-items-center cursor_pointer"
              onClick={() => {
                rl_guest_min = 1;
                rl_rating = 0;
                changedFilters({
                  SEARCH_WORD: "",
                  rl_guest_min: 1,
                  rl_rating: 0,
                });
                setIsNeedUpdate((before) => !before);
              }}
            >
              <span className="font-size-08 mx-1">초기화</span>
            </div>
          </div>
        </div>
        <div className="text-center pt-4 d-none d-lg-block">
          <p>{t("searchMapListSubTitle")}</p>
          <p className="h2">{t("searchMapListTitle")}</p>
        </div>
      </div>
      <div className="row overflow_scroll_y flex-fill">
        {serviceList &&
          serviceList.map((item) => {
            return (
              <MapItemRender
                key={item.rl_idx}
                item={item}
                SUBJECT_CODE={SUBJECT_CODE}
                changedMapCenter={changedMapCenter}
              />
            );
          })}
      </div>
    </div>
  );
}

//MapList 컨텐츠
const MapItem = React.memo(
  ({
    rl_idx,
    rl_subject,
    rl_pic_main,
    rl_price_base,
    rl_title,
    rl_rating,
    rl_rating_cnt,
    rl_desc,
    rl_addr_lat,
    rl_addr_log,
    SUBJECT_CODE,
    changedMapCenter,
  }) => {
    const { t } = useTranslation();

    let iconClass = "";
    let popularDisplay = "d-none";
    switch (rl_subject) {
      case "NAT":
        iconClass = "icon_set_1_icon-24";
        break;
      case "SOC":
        iconClass = "icon_set_1_icon-1";
        break;
      case "SHO":
        iconClass = "icon_set_1_icon-50";
        break;
      case "RES":
        iconClass = "icon_set_1_icon-14";
        break;
      case "BEC":
        iconClass = "icon_set_2_icon-108";
        break;
      case "NMK":
        iconClass = "icon_set_3_restaurant-9";
        break;
      case "BEV":
        iconClass = "icon_set_1_icon-60";
        break;
      case "NVW":
        iconClass = "icon_set_1_icon-24";
        break;
      case "OAT":
        iconClass = "icon_set_1_icon-30";
        break;
      case "SPO":
        iconClass = "icon_set_1_icon-40";
        break;

      case "ETM":
        iconClass = "icon_set_1_icon-3";
        break;

      default:
        iconClass = "";
        break;
    }

    const picUrl = util.changedResourceUrl(rl_pic_main);

    if (rl_rating_cnt > 10 && rl_rating) popularDisplay = "d-block";

    return (
      <div className=" col-sm-6 col-xl-4 p-2">
        <div className="tour_container overflow-hidden border shadow-sm m-0">
          <div className={`ribbon_3 popular ${popularDisplay}`}>
            <span>{t("popular")}</span>
          </div>
          <Link to={`/TourDetail/${rl_idx}`}>
            <div className="img_container">
              <div
                style={{
                  background: `url(${picUrl}) 50% 50%/cover no-repeat`,
                  width: "100%",
                  height: 300,
                }}
              />
              <div className="short_info">
                <i className={`${iconClass} h2`} />
                {SUBJECT_CODE[rl_subject]}
                <span className="price">
                  <sup>&#8361;</sup>
                  {util.addCommas(rl_price_base)}
                </span>
              </div>
            </div>
          </Link>

          <div className="tour_title d-flex flex-column justify-content-between">
            <Link to={`/TourDetail/${rl_idx}`}>
              <div className="flex-fill" style={{ height: 100 }}>
                <h3>
                  <strong className="ellipsis_2">{rl_title}</strong>
                </h3>
                <div className="rating">
                  <i
                    className={
                      rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                    }
                  />
                  <small className={rl_rating ? "d-inline-block" : "d-none"}>
                    {t("rating")} {rl_rating_cnt} {t("count")}
                  </small>
                  <p className="ellipsis_2 mt-1">{rl_desc}</p>
                </div>
              </div>
            </Link>
            <div
              className="view_on_map"
              onClick={() => {
                changedMapCenter(rl_addr_lat, rl_addr_log);
              }}
            >
              {t("searchMapListViewLocation")}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
