/*global google*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import OwlCarousel from "react-owl-carousel";

import BuddibleSocket from "../../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../../lib/Utilities";
import CodeList from "../../../../../../lib/CodeList";

import GuideTourRegistTravelRouteModal from "./GuideTourRegistTravelRouteModal";
import Loading from "../../../../../../lib/Loading";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let mapApi = {
  map: null,
  infoWindow: null,
  userLocation: { lat: 0, lng: 0 },
  directionsService: null,
  directionsRenderer: null,
  routeSubData: [],
  makers: [],
};

let routeSubData = []; //경유지 sort 정렬에 사용

export default function GuideTourRegistTravelRoute(props) {
  const { tourData, loginInfo } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [isUnDirectionInfo, setIsUnDirectionInfo] = useState(false);
  const [isLoading, seIsLoading] = useState(false);
  const editModal = useRef();

  useEffect(() => {
    if (loginInfo && tourData) {
      initMap();
      requestRouteSub((newData) => {
        reRendererRouteSubList(newData);
      });
    }
  }, []);

  const reRendererRouteSubList = (newData) => {
    let returnData = newData["returnData"];
    for (let i = 0; i < returnData.length; i++) {
      returnData[i].rs_addr_lat = parseFloat(returnData[i].rs_addr_lat);
      returnData[i].rs_addr_log = parseFloat(returnData[i].rs_addr_log);
    }
    routeSubData = newData["returnData"];
    mapApi.routeSubData = newData["returnData"];
    setServerData(newData["returnData"]);
    setIsNeedUpdate(!isNeedUpdate);
    makeGoogleMapDirection(() => {
      setIsNeedUpdate(!isNeedUpdate);
      seIsLoading(false);
    });
  };

  // 경로 서브
  const requestRouteSub = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_route_sub_detail_select.php",
      msgID: msgID,
      rs_guide_uid: tourData.rl_guide_uid,
      rs_route_uid: tourData.rl_route_uid,
      rs_use_yn: "Y",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  const initMap = () => {
    mapApi.directionsService = new google.maps.DirectionsService();
    mapApi.directionsRenderer = new google.maps.DirectionsRenderer();
    mapApi.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 17,
      scrollwheel: true,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });
    mapApi.directionsRenderer.setMap(mapApi.map);

    mapApi.infoWindow = new google.maps.InfoWindow();
    const locationButton = document.createElement("button");
    locationButton.textContent = "Pan to Current Location";
    locationButton.classList.add("custom-map-control-button");
    mapApi.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      locationButton
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mapApi.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          mapApi.map.setCenter(mapApi.userLocation);
          makeGoogleMapDirection();
        },
        () => {
          handleLocationError(true, mapApi.infoWindow, mapApi.map.getCenter());
        }
      );
    } else {
      handleLocationError(false, mapApi.infoWindow, mapApi.map.getCenter());
    }
  };

  const handleLocationError = () => {};

  const makeGoogleMapDirection = () => {
    let origin = {
      placeId: "",
    };
    let destination = {
      placeId: "",
    };

    const waypoints = [];

    let request = {
      origin: origin,
      destination: destination,
      travelMode: "DRIVING",
    };

    if (mapApi.routeSubData.length <= 1) return;

    if (mapApi.routeSubData.length === 2) {
      origin = { placeId: mapApi.routeSubData[0].rs_placeId };
      destination = {
        placeId: mapApi.routeSubData[mapApi.routeSubData.length - 1].rs_placeId,
      };

      request = {
        origin: origin,
        destination: destination,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
    }
    if (mapApi.routeSubData.length > 2) {
      origin = { placeId: mapApi.routeSubData[0].rs_placeId };
      destination = {
        placeId: mapApi.routeSubData[mapApi.routeSubData.length - 1].rs_placeId,
      };
      for (let i = 0; i < mapApi.routeSubData.length; i++) {
        waypoints.push({
          location: { placeId: mapApi.routeSubData[i].rs_placeId },
          stopover: true,
        });
      }

      request = {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
    }

    for (let i = 0; i < mapApi.makers.length; i++) {
      mapApi.makers[i].setMap(null);
    }
    mapApi.makers = [];

    mapApi.directionsService.route(request, function (response, status) {
      if (status === "OK") {
        setIsUnDirectionInfo(false);
        mapApi.directionsRenderer.setDirections(response);
      } else {
        setIsUnDirectionInfo(true);
        addMakers();
      }
    });

    function addMakers() {
      //console.log("addMakers");
      let bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < mapApi.routeSubData.length; i++) {
        let marker = new google.maps.Marker({
          position: {
            lat: mapApi.routeSubData[i].rs_addr_lat,
            lng: mapApi.routeSubData[i].rs_addr_log,
          },

          title: mapApi.routeSubData[i].rs_street_name,
        });
        bounds.extend(marker.position);

        marker.setMap(mapApi.map);
        mapApi.makers.push(marker);
      }
      //console.log(bounds);
      mapApi.map.fitBounds(bounds);
    }
  };

  const updateRouteSub = (updateData, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_route_sub_detail_update.php",
      msgID: msgID,
      ...updateData,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  //경유지 순서 아래로 이동
  const changeSortDown = (e) => {
    let index = parseInt(e.target.dataset["index"], 0);
    let data = { ...routeSubData[index] };

    data.rs_route_sub_order = parseInt(data.rs_route_sub_order, 0) + 1;
    let nextIndex = index + 1;
    let nextData = { ...routeSubData[nextIndex] };
    nextData.rs_route_sub_order = parseInt(nextData.rs_route_sub_order, 0) - 1;

    let dataList = [...routeSubData];
    dataList[index] = nextData;
    dataList[nextIndex] = data;

    updateRouteSub(dataList[index], (newData) => {
      if (newData.ret) {
        updateRouteSub(dataList[nextIndex], (newData) => {
          if (newData.ret) {
            requestRouteSub((newData) => {
              reRendererRouteSubList(newData);
            });
          } else {
            codeList.Modal.current.alert("데이터 입력중 오류발생");
          }
        });
      } else {
        codeList.Modal.current.alert("데이터 입력중 오류발생");
      }
    });
  };

  //경유지 순서 위로 이동
  const changeSortUp = (e) => {
    let index = parseInt(e.target.dataset["index"], 0);
    let data = { ...routeSubData[index] };

    data.rs_route_sub_order = parseInt(data.rs_route_sub_order, 0) - 1;
    let beforeIndex = index - 1;
    let beforeData = { ...routeSubData[beforeIndex] };
    beforeData.rs_route_sub_order =
      parseInt(beforeData.rs_route_sub_order, 0) + 1;

    let dataList = [...routeSubData];
    dataList[index] = beforeData;
    dataList[beforeIndex] = data;

    updateRouteSub(dataList[index], (newData) => {
      if (newData.ret) {
        updateRouteSub(dataList[beforeIndex], (newData) => {
          if (newData.ret) {
            requestRouteSub((newData) => {
              reRendererRouteSubList(newData);
            });
          } else {
            codeList.Modal.current.alert("데이터 입력중 오류발생");
          }
        });
      } else {
        codeList.Modal.current.alert("데이터 입력중 오류발생");
      }
    });
  };

  //경유지 데이터 수정
  const openEdit = (e, params) => {
    editModal.current.openModal(e, params);
  };

  //경유지 등록
  const changeRouteSubData = (data) => {
    let idx = routeSubData.findIndex(
      (item) => item.rs_route_sub_uid === data.rs_route_sub_uid
    );
    if (idx > -1) {
      routeSubData[idx] = data;
    } else {
      let msgID = util.makeUUIDv4();
      data.rs_route_uid = tourData.rl_route_uid;
      data.rs_route_sub_uid = msgID;
      data.rs_guide_uid = tourData.rl_guide_uid;
      data.rs_route_sub_order = routeSubData.length;
      routeSubData[data.rs_route_sub_order] = data;
    }

    updateRouteSub(data, (ret) => {
      if (ret) {
        requestRouteSub((newData) => {
          reRendererRouteSubList(newData);
          // setServerData(newData["returnData"]);
        });
      } else {
        codeList.Modal.current.alert("데이터 입력중 오류발생");
      }
    });
  };

  //경유지 삭제
  const deleteSubRoute = (idx, e) => {
    let data = { ...routeSubData[idx], rs_use_yn: "N" };

    codeList.Modal.current.confirm(
      `[${data.rs_street_name}]을 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          updateRouteSub(data, () => {
            codeList.Modal.current.alert("정상처리되었습니다.", () => {
              requestRouteSub((newData) => {
                reRendererRouteSubList(newData);
              });
            });
          });
        } else {
          codeList.Modal.current.alert("데이터 입력중 오류발생");
        }
      }
    );
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const SubRouteItemRender = useCallback(
    ({ route, changeSortUp, changeSortDown, openEdit, deleteSubRoute }) => {
      return (
        <SubRouteItem
          route={route}
          changeSortUp={changeSortUp}
          changeSortDown={changeSortDown}
          openEdit={openEdit}
          deleteSubRoute={deleteSubRoute}
        />
      );
    },
    []
  );

  return (
    <>
      <GuideTourRegistTravelRouteModal
        tourData={tourData}
        changeRouteSubData={changeRouteSubData}
        ref={editModal}
      />
      <div className="col-xl-6">
        <div className="mb-3 pb-3 border-bottom">
          <button
            className="btn_1 bg-buddible mt-3"
            data-toggle="modal"
            data-target="#travelRouteModal"
            disabled={tourData.rl_confirm_admin === "S"}
            onClick={(e) => openEdit(e, null)}
          >
            경유지 등록하기
          </button>
        </div>
        <div className="mb-3">
          {data.length > 0 &&
            data.map((route) => {
              return (
                <SubRouteItemRender
                  key={route.rs_route_sub_uid}
                  route={route}
                  changeSortUp={changeSortUp}
                  changeSortDown={changeSortDown}
                  openEdit={openEdit}
                  deleteSubRoute={deleteSubRoute}
                />
              );
            })}
        </div>

        <div className="mb-3 pb-3">
          <h6 className="font-weight-bold">한눈에 볼수있는 나의 여행 경로</h6>
          <p className="mb-2">위치를 선택해 주세요.</p>
          {isUnDirectionInfo && (
            <p>
              ※ 안내 : 도로가 없어 경로 확인이 불가한 서비스입니다. 마커를 통해
              서비스 지역을 확인하세요
            </p>
          )}
          <div id={"map"} style={{ width: "100%", height: "350px" }} />
        </div>
      </div>
    </>
  );
}

const SubRouteItem = React.memo(
  ({ route, changeSortUp, changeSortDown, openEdit, deleteSubRoute }) => {
    const pics = route.rs_pic ? route.rs_pic.split("|") : null;
    return (
      <div className="py-1">
        <div className="border rounded p-3 d-inline-flex flex-row justify-content-start img_container_2 owl-nav-custom-center w-100">
          {pics !== null && (
            <OwlCarousel
              style={{ width: 150 }}
              className="owl-theme"
              loop
              navText={[
                "<span key='prev' class='text-secondary'><i class=\"fa-solid fa-angle-left\"></i></span>",
                "<span key='next' class='text-secondary'><i class=\"fa-solid fa-angle-right\"></i></span>",
              ]}
              nav={pics.length > 1}
              dots={false}
              items={1}
            >
              {pics.map((pic, index) => {
                const splitData = pic.split("?");
                const fileLink = splitData[1];
                return (
                  <div
                    key={index}
                    className="item rounded border bg-light d-flex flex-row justify-content-center align-items-center"
                    style={{
                      height: 150,
                      width: 150,
                      minWidth: 150,
                      background: `url(${fileLink}) 50% 50%/cover no-repeat`,
                    }}
                  />
                );
              })}
            </OwlCarousel>
          )}
          {pics === null && (
            <div
              className="rounded border bg-light d-flex flex-row justify-content-center align-items-center"
              style={{
                height: 150,
                width: 150,
                minWidth: 150,
              }}
            >
              {!route.rs_pic && <span>이미지</span>}
            </div>
          )}
          <div
            className="flex-fill d-flex flex-column justify-content-between pl-3"
            style={{ height: 150 }}
          >
            <div>
              <div className="d-flex flex-row justify-content-between">
                <div className="flex-grow-1">
                  <p className="h6 mb-0 ellipsis_1">{route.rs_street_name}</p>
                </div>
                <div style={{ minWidth: 30 }}>
                  {route.rs_route_sub_order > 0 && (
                    <i
                      className="fas fa-arrow-up cursor_pointer mr-1"
                      data-index={route.rs_route_sub_order}
                      onClick={(e) => changeSortUp(e)}
                    />
                  )}
                  {route.rs_route_sub_order < routeSubData.length - 1 && (
                    <i
                      className="fas fa-arrow-down cursor_pointer ml-1"
                      data-index={route.rs_route_sub_order}
                      onClick={(e) => changeSortDown(e)}
                    />
                  )}
                </div>
              </div>
              <p className="mb-2 ellipsis_1">
                <i className="fa-solid fa-location-dot text-buddible" />{" "}
                {route.rs_addr_full}
              </p>
              <p className="ellipsis_1">{route.rs_desc}</p>
            </div>
            <div className="d-flex flex-row justify-content-end">
              <div
                className="btn_1 mr-1"
                data-toggle="modal"
                data-target="#travelRouteModal"
                onClick={(e) => openEdit(e, route)}
              >
                수정
              </div>
              <div
                className="btn_1 white border ml-2"
                onClick={(e) => deleteSubRoute(route.rs_route_sub_order)}
              >
                삭제
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
