import React, { useState, useEffect, useRef, useMemo } from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { uploadFile } from "react-s3";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

const S3_BUCKET = "playyj";
const ACCESS_KEY = "AKIAW7LFABWASIJAYZPF";
const SECRET_ACCESS_KEY = "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";
const REGION = "ap-northeast-2";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const _mClassName = "CommunityWrite";

let loginInfo = null;
let isAdmin = false;
let uid = null;

export default function CommunityWrite({ match, history }) {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [mainPic, setMainPic] = useState("");
  const [isEditorState, setIsEditorState] = useState();
  const QuillRef = useRef();

  const controller = {
    cm_type: useRef(), // 게시글 분류
    cm_main_pic: useRef(), // 메인이미지
    cm_title: useRef(), // 제목
    cm_sub_title: useRef(), // 부제목
    cm_content: useRef(), // 컨텐츠
    cm_show_yn: useRef(), // 메인노출 여부
    cm_use_yn: useRef(), // 노출여부
  };

  const CATEGORY = {
    NOTICE: t("notice"),
    GUIDE: t("guideTip"),
    GUEST: t("guestTip"),
    NEWS: t("news"),
    FORUM: t("communityForum"),
  };

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData;
      isAdmin = Number(userData.u_Auth) === 8;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
        isAdmin = Number(n.u_Auth) === 8;
      }
    );

    $(".ql-file").append(
      '<span style="display: flex; align-items: center;"><i class="far fa-file" /></span>'
    );

    return () => {
      loginInfo = null;
      isAdmin = false;

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (loginInfo === null) {
      alert(t("checkLoginMsg"));
      window.location.replace("/CommunityMain");
    } else {
      if (!match.params.hasOwnProperty("cm_idx")) {
        setIsNeedUpdate((before) => !before);
        return;
      }
      requestCommunityData((newData) => {
        if (!newData["returnData"][0]) {
          alert(t("errorMsg"));
          history.goBack();
        }

        let data = { ...newData["returnData"][0] };
        uid = data.cm_uid;

        controller.cm_type.current &&
          (controller.cm_type.current.value = data.cm_type);
        controller.cm_main_pic.current &&
          (controller.cm_main_pic.current.value = data.cm_main_pic);
        controller.cm_title.current &&
          (controller.cm_title.current.value = data.cm_title);
        controller.cm_sub_title.current &&
          (controller.cm_sub_title.current.value = data.cm_sub_title);
        controller.cm_content.current &&
          (controller.cm_content.current.value = data.cm_content);
        controller.cm_show_yn.current &&
          (controller.cm_show_yn.current.value = data.cm_show_yn);
        controller.cm_use_yn.current &&
          (controller.cm_use_yn.current.value = data.cm_use_yn);

        setMainPic(data.cm_main_pic.split("?")[1]);
        setIsEditorState(data.cm_content);
      });
    }
  }, [loginInfo]);

  // 수정 게시글 정보 받아오기
  const requestCommunityData = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityDataSelect.php",
      msgID: msgID,
      cm_idx: match.params.cm_idx,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 입력값 검사
  const checkValidation = () => {
    if (
      controller.cm_main_pic.current &&
      controller.cm_main_pic.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWriteMainImageNote"), () => {});
      return false;
    }
    if (
      controller.cm_title.current &&
      controller.cm_title.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWriteTitleNote"), () => {
        controller.cm_title.current.focus();
      });

      return false;
    }
    if (
      controller.cm_sub_title.current &&
      controller.cm_sub_title.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWriteSubTitleNote"), () => {
        controller.cm_sub_title.current.focus();
      });
      return false;
    }
    if (
      controller.cm_type.current &&
      controller.cm_type.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWriteCategoryNote"), () => {
        controller.cm_type.current.focus();
      });
      return false;
    }
    if (
      controller.cm_show_yn.current &&
      controller.cm_show_yn.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWriteExposureNote"), () => {
        controller.cm_show_yn.current.focus();
      });
      return false;
    }
    if (
      controller.cm_use_yn.current &&
      controller.cm_use_yn.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWritePublicNote"), () => {
        controller.cm_use_yn.current.focus();
      });
      return false;
    }
    if (
      controller.cm_content.current &&
      controller.cm_content.current.value.trim() === ""
    ) {
      codeList.Modal.current.alert(t("communityWriteContentsNote"), () => {});
      return false;
    }
    return true;
  };

  // 게시글 저장 액션
  const actionSave = () => {
    if (checkValidation()) {
      const updateData = {
        cm_create_uid: loginInfo.u_uid,
        cm_main_pic: controller.cm_main_pic.current.value, // 메인이미지
        cm_title: controller.cm_title.current.value, // 제목
        cm_sub_title: controller.cm_sub_title.current.value, // 부제목
        cm_type: controller.cm_type.current.value, // 게시글 분류
        cm_show_yn: controller.cm_show_yn.current.value, // 메인노출 여부
        cm_use_yn: controller.cm_use_yn.current.value, // 노출여부
        cm_content: controller.cm_content.current.value, // 컨텐츠
      };
      uploadCommunity(updateData, () => {
        codeList.Modal.current.alert(t("communityWriteSuccessMsg"), () => {
          history.push(`/CommunityList/${updateData.cm_type}`);
        });
      });
    }
  };

  // 게시글 저장
  const uploadCommunity = (updateData, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityUpload.php",
      msgID: msgID,
      ...updateData,
      cm_del_yn: "N",
    };
    socketMsg.cm_uid = uid || msgID;

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 메인이미지 업로드
  const mainPicHandler = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      await uploadFileToS3WithS3(file[0], "community/image", (returnData) => {
        controller.cm_main_pic.current.value = `${returnData.realFileName}?${returnData.fileName}`;
        setMainPic(returnData.fileName);
      });
    };
  };

  // 이미지를 업로드 하기 위한 함수
  const imageHandlerWithQuill = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      await uploadFileToS3WithS3(file[0], "community/image", (returnData) => {
        const range = QuillRef.current?.getEditor().getSelection()?.index;
        if (range !== null && range !== undefined) {
          let quill = QuillRef.current?.getEditor();
          quill?.setSelection(range, 1);
          quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<img width="200" src=${returnData.fileName} alt=${returnData.realFileName} />`
          );

          $(".ql-editor img[src='" + returnData.fileName + "']").attr(
            "width",
            "200"
          );
        }
      });
    };
  };

  // 첨부파일 업로드
  const fileUploadHandlerWithQuill = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      await uploadFileToS3WithS3(file[0], "community/file", (returnData) => {
        const range = QuillRef.current?.getEditor().getSelection()?.index;
        if (range !== null && range !== undefined) {
          let quill = QuillRef.current?.getEditor();

          quill?.setSelection(range, 1);
          quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<a style="color: #5f8ecc;" href=${returnData.fileName} download=${
              returnData.realFileName
            }>※ ${t("attachment")} :  ${returnData.realFileName} </a>`
          );
        }
      });
    };
  };

  // S3 파일 업로드
  const uploadFileToS3WithS3 = (file, region, callback) => {
    let customConfig = { ...config };
    customConfig.dirName = region;
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert(t("uploadFileLimitMsg"));
      return;
    }
    let newName =
      name.split(".")[0] +
      "_" +
      util.todayDateTime() +
      "." +
      name.split(".")[1];
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    uploadFile(newFile, customConfig)
      .then((data) => {
        const returnData = {
          ret: true,
          fileName: `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${region}/${newName}`,
          realFileName: name,
        };
        callback && callback(returnData);
      })
      .catch((err) => {
        const returnData = {
          ret: false,
          fileName: ``,
          realFileName: ``,
        };
        callback && callback(returnData);
      })
      .finally(() => {});
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          [{ align: [] }, { color: [] }, { background: [] }],
          ["clean", "file"],
        ],
        handlers: {
          image: imageHandlerWithQuill,
          link: function (value) {
            if (value) {
              let href = prompt("URL을 입력해 주세요.");
              this.quill.format("link", href);
            } else {
              this.quill.format("link", false);
            }
          },
          file: fileUploadHandlerWithQuill,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  const formats = [
    //'font',
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "clean",
    "parchment",
    "alt",
    "height",
    "width",
    "style",
    "size",
  ];

  return (
    <>
      <Nav />
      <main className="bg-white">
        <div className="container margin_60" style={{ marginTop: 100 }}>
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div>
                <p className="h3 font-weight-bold text-secondary mb-4">
                  {t("communityWriteTitle")}
                </p>

                <div className="row m-0 p-0">
                  <div className="col-lg-4 m-0 mb-2 mb-lg-0 p-0">
                    <input
                      type="text"
                      className="d-none"
                      ref={controller.cm_main_pic}
                    />
                    <div
                      className={`${
                        mainPic === "" ? "bg-light" : ""
                      } d-flex flex-row justify-content-center align-items-center rounded-lg cursor_pointer border`}
                      style={{
                        height: 200,
                        background: `url(${mainPic}) 50% 50% /cover no-repeat`,
                      }}
                      onClick={mainPicHandler}
                    >
                      {mainPic === "" && (
                        <p className="font-weight-bold text-secondary">
                          <i className="fa-regular fa-image mr-2" />
                          {t("main")} {t("image")}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-8 m-0 p-0 pl-lg-2">
                    <div className="d-flex flex-column h-100">
                      <input
                        className="form-control mb-2"
                        type="text"
                        placeholder={t("communityWriteTitleNote")}
                        ref={controller.cm_title}
                      />

                      <textarea
                        rows={2}
                        className="form-control flex-grow-1"
                        placeholder={t("communityWriteSubTitleNote")}
                        ref={controller.cm_sub_title}
                      />
                      <div className="d-flex flex-row mt-2">
                        <select
                          className="form-control mr-2"
                          ref={controller.cm_type}
                        >
                          <option value="">{t("category")}</option>
                          {isAdmin && (
                            <>
                              <option value="NOTICE">{t("notice")}</option>
                              <option value="NEWS">{t("news")}</option>
                              <option value="GUIDE">{t("guideTip")}</option>
                              <option value="GUEST">{t("guestTip")}</option>
                            </>
                          )}
                          <option value="FORUM">{t("communityForum")}</option>
                        </select>
                        {isAdmin && (
                          <select
                            className="form-control mr-2"
                            ref={controller.cm_show_yn}
                          >
                            <option value="">
                              -{t("main")} {t("exposure")}-
                            </option>
                            <option value="Y">{t("exposure")}</option>
                            <option value="N">{t("non-exposure")}</option>
                          </select>
                        )}
                        <select
                          className="form-control"
                          ref={controller.cm_use_yn}
                        >
                          <option value="">
                            -{t("post")} {t("public")}-
                          </option>
                          <option value="Y">{t("public")}</option>
                          <option value="N">{t("private")}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="row mt-2 mx-0 text-editor"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 50,
                  }}
                >
                  <textarea
                    className="form-control d-none"
                    rows={20}
                    ref={controller.cm_content}
                    disabled
                    value={isEditorState}
                    id="textarea"
                  />
                  <ReactQuill
                    style={{
                      minHeight: "500px",
                      marginBottom: 20,
                      width: "100%",
                    }}
                    theme={"snow"}
                    modules={modules}
                    formats={formats}
                    value={isEditorState || ""}
                    ref={(element) => {
                      if (element !== null) {
                        QuillRef.current = element;
                      }
                    }}
                    onChange={(content, delta, source, editor) => {
                      setIsEditorState(editor.getHTML());
                    }}
                    preserveWhitespace={true}
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div
                  className="col-lg-12"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <div>
                    <button
                      className="btn_1 bg-secondary mr-3"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </button>
                    <button onClick={actionSave} className="btn_1">
                      {t("save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
