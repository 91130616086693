import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();
const countryList = codeList.codeCountry;

let itemViewCountBackup = 3;
let screenWidth = window.innerWidth;
if (screenWidth <= 720) {
  itemViewCountBackup = 1.1;
}
if (screenWidth > 720 && screenWidth < 1140) {
  itemViewCountBackup = 2.1;
}
if (screenWidth >= 1140) {
  itemViewCountBackup = 3.1;
}

export default function TourCountryAndKind(props) {
  const { routeItem } = props;
  const { t } = useTranslation();
  const [itemViewCount, setItemViewCount] = useState(itemViewCountBackup);

  useLayoutEffect(() => {
    handleItemCount();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleItemCount);
    return () => {
      window.removeEventListener("resize", handleItemCount);
    };
  }, []);

  //화면사이즈에 따른 보여지는 서비스 개수 조절
  let handleItemCount = () => {
    screenWidth = window.innerWidth;
    let changeViewCount = 0;
    if (screenWidth <= 720) {
      changeViewCount = 1.1;
    }
    //  테블릿
    if (screenWidth > 720 && screenWidth < 1140) {
      changeViewCount = 2.1;
    }
    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 3.1;
    }
    if (itemViewCountBackup !== changeViewCount) {
      itemViewCountBackup = changeViewCount;
      setItemViewCount(changeViewCount);
    }
  };

  const OwlCarouselRenderer = useCallback(() => {
    return (
      <OwlCarousel
        style={{ marginTop: "-30px" }}
        className="owl-theme"
        loop
        margin={10}
        dots={false}
        nav
        items={itemViewCount}
        loadedClass={"owl-loaded custom_owl-loaded"}
        stageOuterClass={"owl-stage-outer custom_owl-stage-outer"}
        navClass={["moveBtn mr-1", "moveBtn ml-1"]}
        navText={[
          "<span key='prev' class='text-secondary'><i class=\"fa-solid fa-angle-left\"></i></span>",
          "<span key='next' class='text-secondary'><i class=\"fa-solid fa-angle-right\"></i></span>",
        ]}
        navContainerClass={"custom_owl-nav"}
      >
        {routeItem.map((item, index) => {
          let { rl_idx, rl_pic_main, rl_addr_country, rl_subject, rl_title } =
            item;

          const picUrl = util.changedResourceUrl(rl_pic_main);

          return (
            <Link
              key={rl_idx}
              to={`/TourDetail/${rl_idx}`}
              className={`mb-2 d-inline-block rounded rounded-lg overflow-hidden position-relative cursor_pointer w-100 `}
              style={{
                background: `url(${picUrl}) 50% 50%/cover no-repeat`,
                height: 300,
              }}
            >
              <div
                className="p-3 position-absolute w-100"
                style={{
                  bottom: 0,
                  background:
                    "linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 1) )",
                }}
              >
                <h4 className="text-white text-center m-0">
                  {countryList.get(rl_addr_country) &&
                    countryList.get(rl_addr_country).name_ko}
                  {codeList.codeSubject.get(rl_subject) &&
                    ` · ${codeList.codeSubject.get(rl_subject).cs_name_kr}`}
                </h4>
                <p className="text-white text-center">{rl_title}</p>
              </div>
            </Link>
          );
        })}
      </OwlCarousel>
    );
  }, [routeItem]);

  return (
    <section className="container margin_60 font">
      <>
        <div className="d-flex flex-row justify-content-between align-items-end w-75">
          <div>
            <h3 className="font-weight-bold m-0">
              {t("tourListCountryAndKindTitle")}
            </h3>
            <p>{t("tourListCountryAndKindDesc")}</p>
          </div>
        </div>
        {routeItem.length > 0 && <OwlCarouselRenderer />}
      </>
      {routeItem.length === 0 && (
        <p className="h5 text-secondary text-center rounded-lg p-5 bg-white">
          {t("emptyServiceNote")}
        </p>
      )}
    </section>
  );
}
