import React, { useEffect, useState } from "react";
import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let service_time = [];
for (let i = 180; i <= 480; i = i + 30) {
  let hour = parseInt(i / 60, 0);
  let time = i % 60;
  let timeName = i / 60;
  service_time.push({
    id: `${i}`,
    name: `${timeName} 시간`,
    val: `${hour < 10 ? `0${hour}` : hour}:${time < 10 ? `0${time}` : time}:00`,
  });
}

let service_start_time = [];
for (let i = 0; i < 1440; i = i + 30) {
  let hour = parseInt(i / 60, 0);
  let time = i % 60;
  service_start_time.push({
    id: `${i}`,
    name: `${hour < 10 ? `0${hour}` : hour}:${time < 10 ? `0${time}` : time}`,
    val: `${hour < 10 ? `0${hour}` : hour}:${time < 10 ? `0${time}` : time}:00`,
  });
}

let cost_data = {
  gpt_hour_base: "",
  gpt_time_start: "",
  gpt_price_base: 0,
  gpt_price_over_hour: 0,
  gpt_price_over_people: 0,
};

export default function GuideTimeRegistCost(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  useEffect(() => {
    if (partTimeData) {
      for (let key in cost_data) {
        cost_data[key] = partTimeData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);
  const handleCostData = (key, val) => {
    cost_data[key] = key.indexOf("price") > -1 ? Number(val) : val;
    guideTimeRegistDataChange(cost_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <h3 className="font-weight-bold">가이드 진행 시간이 어떻게 되나요?</h3>
        <p>대부분의 가이드시간은 3시간 이상입니다.</p>

        <div className="my-3">
          <h4>가이드 기본 시간</h4>
          <select
            className="mt-2 w-100 border rounded-lg p-3"
            onChange={(e) => {
              handleCostData("gpt_hour_base", e.target.value);
            }}
            value={cost_data.gpt_hour_base}
          >
            {service_time.map((item) => {
              return (
                <option key={item.id} value={item.val}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="my-3">
          <h4>보통 몇 시에 가이드를 시작하실 건가요?</h4>
          <p>
            예 : 오후 4시에 시작하고 2시간이 소요되는 경우, 시작을 4시로, 끝나는
            시간을 6시로 선택해 주세요.
          </p>
          <h4 className="mt-2">가이드 시작 시간</h4>
          <select
            className="mt-2 w-100 border rounded-lg p-3"
            onChange={(e) => {
              handleCostData("gpt_time_start", e.target.value);
            }}
            value={cost_data.gpt_time_start}
          >
            {service_start_time.map((item) => {
              return (
                <option key={item.id} value={item.val}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="mb-3 pb-3">
        <h3 className="font-weight-bold">요금 책정</h3>
        <p>
          요금은 가이드가 원하는 대로 책정할 수 있습니다. 예상 수입을 확인하려면
          각 게스트에게 청구하고자 하는 요금을 입력하세요.
        </p>
        <p className="font-weight-bold">
          기본 인원 수를 정하고 요금 책정해주세요. 평균 50,000 ~ 60,000원으로
          책정하고 있습니다.
        </p>
        <div className="d-flex flex-row justify-content-between border-bottom py-4">
          <div className="flex-grow-1">
            <p className="font-weight-bold">예약시 기본요금</p>
            <p>만 19세 이상</p>
          </div>
          <div className="flex-grow-1 px-1">
            <p>KRW 지불액</p>
            <input
              className="p-2 border rounded w-100"
              type="number"
              onChange={(e) => {
                handleCostData("gpt_price_base", e.target.value);
              }}
              value={cost_data.gpt_price_base}
            />
          </div>
          <div className="flex-grow-1 px-1">
            <p>KRW 예상 수입</p>
            <input
              className="p-2 border rounded w-100 disabled"
              readOnly
              readOnly
              value={Math.floor(cost_data.gpt_price_base * 0.8)}
            />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between border-bottom py-4">
          <div className="flex-grow-1">
            <p className="font-weight-bold">시간 초과당 요금</p>
            <p>1시간 단위</p>
          </div>
          <div className="flex-grow-1 px-1">
            <p>KRW 지불액</p>
            <input
              className="p-2 border rounded w-100"
              type="number"
              onChange={(e) => {
                handleCostData("gpt_price_over_hour", e.target.value);
              }}
              value={cost_data.gpt_price_over_hour}
            />
          </div>
          <div className="flex-grow-1 px-1">
            <p>KRW 예상 수입</p>
            <input
              className="p-2 border rounded w-100 disabled"
              readOnly
              value={Math.floor(cost_data.gpt_price_over_hour * 0.8)}
            />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between border-bottom py-4">
          <div className="flex-grow-1">
            <p className="font-weight-bold">인원 추가시 요금</p>
            <p>1인 추가시</p>
          </div>
          <div className="flex-grow-1 px-1">
            <p>KRW 지불액</p>
            <input
              type="number"
              className="p-2 border rounded w-100"
              onChange={(e) => {
                handleCostData("gpt_price_over_people", e.target.value);
              }}
              value={cost_data.gpt_price_over_people}
            />
          </div>
          <div className="flex-grow-1 px-1">
            <p>KRW 예상 수입</p>
            <input
              className="p-2 border rounded w-100 disabled"
              readOnly
              value={Math.floor(cost_data.gpt_price_over_people * 0.8)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
