import React, { useEffect, useState } from "react";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import BuddibleSocket from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
let codeList = new CodeList();
const countryList = codeList.codeCountry;

let itemViewCountBackup = 5;
let screenWidth = window.innerWidth;
//  모바일
if (screenWidth <= 720) {
  itemViewCountBackup = 1;
}
//  테블릿
if (screenWidth > 720 && screenWidth < 1140) {
  itemViewCountBackup = 3;
}
//  데스크 탑
if (screenWidth >= 1140) {
  itemViewCountBackup = 5;
}
export default function TourNewContents(props) {
  const { routeItem } = props;
  const [itemViewCount, setItemViewCount] = useState(itemViewCountBackup);

  useEffect(() => {
    handleItemCount();
    window.addEventListener("resize", handleItemCount);
    return () => {
      window.removeEventListener("resize", handleItemCount);
    };
  }, []);

  //화면사이즈에 따른 보여지는 서비스 개수 조절
  let handleItemCount = () => {
    screenWidth = window.innerWidth;
    let changeViewCount = 0;
    //  모바일
    if (screenWidth <= 720) {
      changeViewCount = 1;
    }
    //  테블릿
    if (screenWidth > 720 && screenWidth < 1140) {
      changeViewCount = 3;
    }
    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 5;
    }
    if (itemViewCountBackup !== changeViewCount) {
      itemViewCountBackup = changeViewCount;
      setItemViewCount(changeViewCount);
    }
  };

  return (
    <div>
      {routeItem.map((item, index) => {
        return (
          <RouteItem
            key={item.rl_idx}
            item={item}
            index={index}
            itemViewCount={itemViewCount}
          />
        );
      })}
    </div>
  );
}

const RouteItem = ({ item, index, itemViewCount }) => {
  const { t } = useTranslation();
  const picUrl = util.changedResourceUrl(item.rl_pic_main);
  let width = 100 / itemViewCount;

  return (
    <Link
      to={`/TourDetail/${item.rl_idx}`}
      className="d-inline-block text-dark p-2"
      style={{ width: width + "%" }}
    >
      <div className="tour_container m-0" style={{ minHeight: 300 }}>
        <div className="img_container">
          <div
            className="owl_carousel_img"
            style={{
              background: `url(${picUrl})50% 50%/cover no-repeat`,
            }}
          />

          <div className="short_info">
            <span className="price">
              <sup>&#8361;</sup> {util.addCommas(item.rl_price_base)}
            </span>
          </div>
        </div>
        <div className="tour_title">
          <h3 className="overflow-hidden text-truncate">
            <strong>
              {countryList.get(item.rl_addr_country) &&
                countryList.get(item.rl_addr_country).name_ko}
              · {item.u_name_full}
            </strong>
          </h3>
          <p className="ellipsis_2" style={{ height: "3em" }}>
            {item.rl_title}
          </p>
          <div className="rating">
            <i
              className={
                item.rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                item.rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                item.rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                item.rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                item.rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <small>({item.rl_rating_cnt})</small>
          </div>
          <div>
            <p className="ellipsis_1">
              {t("base")} &#8361; {util.addCommas(item.rl_price_base)} /{" "}
              {t("addedPerson")} &#8361;
              {util.addCommas(item.rl_over_pay_people)}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
};
