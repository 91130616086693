import React from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();

export default function ReserveServiceInfo(props) {
  const { tourData, request_data } = props;
  const { t } = useTranslation();

  const SUBJECT_CODE = {
    NAT: "자연·환경 관광", //<i className="icon_set_1_icon-24"//
    SOC: "사회·문화 관광", //<i className="icon_set_1_icon-1"//
    SHO: "쇼핑 관광", //<i className="icon_set_1_icon-50"//
    RES: "맛집 휴양", //<i className="icon_set_1_icon-14"//
    BEC: "해변 휴양", //<i className="icon_set_2_icon-108"//
    NMK: "야간 시장", //<i className="icon_set_3_restaurant-9"//
    BEV: "야간 술집", //<i className="icon_set_1_icon-60"//
    NVW: "야간 풍경", //<i className="icon_set_1_icon-24"//
    OAT: "야외 활동", //<i className="icon_set_1_icon-30"//
    SPO: "스포츠 활동", //<i className="icon_set_1_icon-40"//
    ETM: "오락 활동", //<i className="icon_set_1_icon-3"//
  };

  let img = "";
  if (tourData.rl_pic_main.indexOf("?") > -1) {
    img = tourData.rl_pic_main.split("?")[1];
  } else {
    img = codeList.publicVar.imgServerUrl + tourData.rl_pic_main;
  }

  return (
    <div className="pl-xl-3">
      {" "}
      <p className="h4">{t("serviceInfo")}</p>
      <div className="border shadow-sm rounded-lg overflow-hidden">
        <div
          style={{
            background: `url(${img}) 50% 50%/cover no-repeat`,
            height: 200,
          }}
        />
        <div className="p-3">
          <h4>{tourData.rl_title}</h4>
          <span className="d-inline-block mr-2 font-weight-bold">
            {SUBJECT_CODE[tourData.rl_subject]}
          </span>
          <span className="d-inline-block mr-2">{tourData.rl_addr_full}</span>
          <p className="rating mb-2">
            <i
              className={
                tourData.rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                tourData.rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                tourData.rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                tourData.rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
              }
            />
            <i
              className={
                tourData.rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
              }
            />

            <small>
              ({tourData.rl_rating_cnt ? tourData.rl_rating_cnt : t("new")})
            </small>
          </p>

          <div
            className={`${
              tourData.rl_inc_drink === "Y" ||
              tourData.rl_inc_ticket === "Y" ||
              tourData.rl_inc_meal === "Y" ||
              tourData.rl_inc_vehicle === "Y"
                ? "d-block"
                : "d-none"
            }`}
          >
            <h4>{t("tourDetailServiceDescServicesIncluded")}</h4>

            <div className="row py-3">
              <div className="col-md-6">
                <ul className="list_ok mb-0">
                  <li
                    className={
                      tourData.rl_inc_drink === "Y" ? "d-block" : "d-none"
                    }
                  >
                    {tourData.rl_inc_drink_memo
                      ? tourData.rl_inc_drink_memo
                      : t("tourIncDrink")}
                  </li>
                  <li
                    className={
                      tourData.rl_inc_ticket === "Y" ? "d-block" : "d-none"
                    }
                  >
                    {tourData.rl_inc_ticket_memo
                      ? tourData.rl_inc_ticket_memo
                      : t("tourIncTicket")}
                  </li>
                  <li
                    className={
                      tourData.rl_inc_meal === "Y" ? "d-block" : "d-none"
                    }
                  >
                    {tourData.rl_inc_meal_memo
                      ? tourData.rl_inc_meal_memo
                      : t("tourIncMeal")}
                  </li>
                  <li
                    className={
                      tourData.rl_inc_vehicle === "Y" ? "d-block" : "d-none"
                    }
                  >
                    {tourData.rl_inc_vehicle_memo
                      ? tourData.rl_inc_vehicle_memo
                      : t("tourIncVehicle")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="mt-1" />
          <h4 className="mb-2">{t("reserveDetail")}</h4>
          <div className="py-1 d-flex flex-row justify-content-between">
            <p>{t("reserveDate")}</p>
            <p>{request_data.rrl_date_reserve}</p>
          </div>
          <div className="py-1 d-flex flex-row justify-content-between">
            <p>{t("reservePersonnel")}</p>
            <p>
              {t("tourDetailListFilterPersonnelTotal")}{" "}
              {request_data.rrl_guest_total_num}
            </p>
          </div>
          <div className="py-1 d-flex flex-row justify-content-between">
            <p>{t("adult")}</p>
            <p>
              {request_data.rrl_guest_adult_num} {t("person")}
            </p>
          </div>
          <div
            className={`py-1 flex-row justify-content-between ${
              request_data.rrl_guest_child_num ? "d-flex" : "d-none"
            }`}
          >
            <p>{t("child")}</p>
            <p>
              {request_data.rrl_guest_child_num} {t("person")}
            </p>
          </div>
          <div
            className={`py-1 flex-row justify-content-between ${
              request_data.rrl_guest_baby_num ? "d-flex" : "d-none"
            }`}
          >
            <p>{t("baby")}</p>
            <p>
              {request_data.rrl_guest_baby_num} {t("person")}
            </p>
          </div>

          <hr className="my-2" />
          <div className="py-1 d-flex flex-row justify-content-between">
            <p>
              {t("base")} {tourData.rl_guest_min ? tourData.rl_guest_min : 1}
              {t("person")}
            </p>
            <p>&#8361; {util.addCommas(tourData.rl_price_base)}</p>
          </div>
          <div
            className={`py-1 ${
              request_data.rrl_guest_over_cnt ? "d-flex" : "d-none"
            } flex-row justify-content-between`}
          >
            <p>
              {t("added")} {request_data.rrl_guest_over_cnt}
              {t("person")}
            </p>
            <p>
              &#8361;{" "}
              {util.addCommas(
                Number(request_data.rrl_guest_over_cnt) *
                  Number(tourData.rl_over_pay_people)
              )}
            </p>
          </div>
          <div className={`py-1 d-flex flex-row justify-content-between`}>
            <p>{t("serviceFee")}</p>
            <p>&#8361; {util.addCommas(request_data.rrl_cost_fees_guest)}</p>
          </div>
          <div className={`py-1 d-flex flex-row justify-content-between`}>
            <p>{t("vatAndFees")}</p>
            <p>&#8361; {util.addCommas(request_data.rrl_cost_tax)}</p>
          </div>

          <div className="mt-3 pt-3 mb-0 pb-0 border-top d-flex flex-row justify-content-between font-weight-bold h5 text-buddible">
            <p>{t("feeTotal")}</p>
            <p>&#8361; {util.addCommas(request_data.rrl_cost_total)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
