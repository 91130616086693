import React from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const info_guide_activity = [
  {
    title: "예, 이 여행을 전문적으로 가이드하거나 강의한 적이 있습니다.",
    key: "E",
  },
  {
    title:
      "예, 가족이나 친구를 대상으로 이 여행을 비공식적으로 가이드한적이 있습니다.",
    key: "M",
  },
  {
    title: "아니요, 이 여행으로 가이드한 적이 없습니다.",
    key: "N",
  },
];

export default function GuideRegistFindGuideProfessionalism(props) {
  const { guideData } = props;

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = (value) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      "GuideRegistFindGuideProfessionalism",
      { gl_expert: value },
      () => {}
    );
  };

  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/ExpectationPage2.jpg")50% 50%/cover no-repeat`,
          }}
        />
        <p>
          자신만의 독특한 관점과 내부인만 알 수 있는 전문지식을 나눌 수 있는
          열정 가득한 스토리텔러를 찾고 있습니다.
        </p>
      </div>
      <div className="py-3 border-bottom">
        <p>다음과 같은 사항이 여기에 해당 됩니다.</p>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-1">· </div>
              <p>해박한 지식과 열정을 가진 사람</p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-1">· </div>
              <p>전문적인 교육/훈련을 받은 사람이여도 좋고, 아니여도 됩니다.</p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-1">· </div>
              <p>
                해당 분야에서 상당한 성취를 이룬 사람이여도 좋고, 분야에 관심이
                많은사람이여도 됩니다.
              </p>
            </div>
          </li>
        </ul>
        <p>
          게스트는 다른 곳에서 쉽게 찾을 수 없는 특별한 관점을 가진 가이드를
          높이 평가합니다.
        </p>
      </div>

      <div className="py-3">
        <h6 className="font-weight-bold">
          다음 중 본인과 가장 가까운 유형을 선택해 주세요.
        </h6>
        <div className="py-3">
          {info_guide_activity.map((item) => {
            return (
              <div key={item.key} className="mb-3">
                <label className="d-flex flex-row align-items-start">
                  <input
                    type="radio"
                    name="info_guide_activity"
                    className="mr-2 mt-1"
                    value={item.key}
                    defaultChecked={guideData.gl_chance === item.key}
                    disabled={guideData.gl_confirm_admin === "S"}
                    onChange={(e) => {
                      guideDataChange(e.target.value);
                    }}
                  />{" "}
                  <p>{item.title}</p>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
