import React, { useEffect, useState } from "react";

import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import BuddibleSocket, {
  MsgIDList,
} from "../../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../../lib/Utilities";
import CodeList from "../../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let codeSubject = null;
let codeHashtag = null;

let subject_data = {
  rl_subject: "",
  rl_hashtag: [],
};

let hashtagValue = null;
let selectedKeyword = [];

export default function GuideTourRegistSubject(props) {
  let { tourData, loginInfo, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  (() => {
    let dataCodeSubject = codeList.codeSubject;
    if (dataCodeSubject) {
      let subject = [];
      dataCodeSubject.forEach((item, index) => {
        subject.push(item);
      });
      codeSubject = subject;
    }

    let dataCodeHashTag = codeList.codeHashtag;
    if (dataCodeHashTag) {
      let hashtag = [];
      dataCodeHashTag = [...dataCodeHashTag];
      dataCodeHashTag.forEach((item) => {
        hashtag.push({ id: item[0], desc: item[1] });
      });
      codeHashtag = hashtag;
    }
  })();

  useEffect(() => {
    if (tourData) {
      for (let key in subject_data) {
        subject_data[key] = tourData[key];
      }
      requestServiceHashTag();
    }
  }, []);

  //투어 주제 선택
  const handleSelectSubject = (data) => {
    subject_data.rl_subject = data.cs_code;
    handleTourDataChange();
  };

  //투어 주제 삭제
  const handleRemoveSubject = () => {
    subject_data.rl_subject = "";
    handleTourDataChange();
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = () => {
    guideTourRegistDataChange(subject_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  // 해시태그 입력정보 가져오기
  const requestServiceHashTag = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_service_hashtag_select.php",
      msgID: msgID,
      htr_uid: tourData.rl_guide_uid,
      htr_type: "R",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let codeHashtag = [];
          if (newData["returnData"].length > 0) {
            newData["returnData"].forEach((hashtag) => {
              codeHashtag.push({
                id: hashtag.htr_tag_key,
                desc: hashtag.htc_name,
              });
            });
          }
          selectedKeyword = [...codeHashtag];
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  const insertHashTag = (hashtag) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_service_hashtag_select.php",
      msgID: msgID,
      htr_uid: tourData.rl_guide_uid,
      htr_type: "R",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let codeHashtag = [];
          if (newData["returnData"].length > 0) {
            newData["returnData"].forEach((hashtag) => {
              codeHashtag.push({
                id: hashtag.htr_tag_key,
                desc: hashtag.htc_name,
              });
            });
          }
          selectedKeyword = [...codeHashtag];
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  //해시태그 추가
  const InsertHashtag = (hashtag) => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "InsertHashtag",
      data: {
        htr_type: "R",
        htr_uid: tourData.rl_route_uid,
        htr_tag_key: hashtag.id,
        htr_tag_name: hashtag.desc,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          hashtagValue = "";
          requestServiceHashTag();
        }
      }
    });
  };

  //해시태그 전체삭제
  const removeAllHashtag = () => {
    for (let i = 0; i < selectedKeyword.length; i++) {
      RemoveHashtag(selectedKeyword[i].id);
    }
  };

  //해시태그 삭제
  const RemoveHashtag = (deleteKey) => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "RemoveHashtag",
      data: {
        htr_type: "R", //G 가이드, R 루트, P 파트타임
        htr_uid: tourData.rl_route_uid, //htr_type 경우에 따른 uid
        htr_tag_key: deleteKey,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = [...selectedKeyword];
          let deleteIndex = returnData.findIndex(
            (hashtag) => hashtag.id === deleteKey
          );
          returnData.splice(deleteIndex, 1);
          selectedKeyword = [...returnData];
          requestServiceHashTag();
        }
      }
    });
  };

  return (
    <>
      <div className="col-xl-6">
        <div className="mb-3 pb-3">
          <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
            <button
              type="button"
              className="px-5 py-3 bg-white rounded shadow-sm  border font-weight-bold h6"
              style={{ width: 300 }}
              data-toggle="modal"
              data-target="#tourRegistSubjectModal"
              disabled={tourData.rl_confirm_admin === "S"}
            >
              <i className="far fa-folder-open mr-2" />
              주요 주제 선택
            </button>
            {subject_data.rl_subject && (
              <div
                className="border rounded px-5 py-3 d-flex flex-row justify-content-between mt-3"
                style={{ width: 300 }}
              >
                <span>
                  <i className="fas fa-arrow-right mr-2" />
                  {
                    codeSubject.find(
                      (item) => item.cs_code === subject_data.rl_subject
                    ).cs_name_kr
                  }
                </span>
                <span
                  className="font-weight-bold cursor_pointer"
                  onClick={() => {
                    if (tourData.rl_confirm_admin === "S") return;
                    handleRemoveSubject();
                  }}
                >
                  취소
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <GuideTourRegistSubjectModal handleSelectSubject={handleSelectSubject} />
    </>
  );
}

function GuideTourRegistSubjectModal(props) {
  let { handleSelectSubject } = props;
  return (
    <div
      className="modal fade"
      id="tourRegistSubjectModal"
      tabIndex="-1"
      aria-labelledby="tourRegistSubjectModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="tourRegistSubjectModalLabel">
              모든 주제
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body d-flex flex-row justify-content-start flex-wrap">
            {codeSubject !== null &&
              codeSubject.map((subject) => {
                return (
                  <div className="w-50 text-center p-1" key={subject.cs_code}>
                    <div
                      className={`border py-3 shadow-sm rounded cursor_pointer font-weight-bold ${
                        subject_data.rl_subject === subject.cs_code
                          ? "bg-buddible text-white"
                          : ""
                      }`}
                      onClick={() => {
                        handleSelectSubject(subject);
                      }}
                    >
                      {subject.cs_name_kr}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              닫기
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
