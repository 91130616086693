import React, { useEffect, useState } from "react";

import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();
const countryList = codeList.codeCountry;

let itemViewCountBackup = 3;
let screenWidth = window.innerWidth;
if (screenWidth <= 720) {
  itemViewCountBackup = 1;
}
//  테블릿
if (screenWidth > 720 && screenWidth < 1140) {
  itemViewCountBackup = 2;
}
//  데스크 탑
if (screenWidth >= 1140) {
  itemViewCountBackup = 3;
}

export default function TourWithinHourContents(props) {
  const { routeItem } = props;
  const [itemViewCount, setItemViewCount] = useState(itemViewCountBackup);

  useEffect(() => {
    handleItemCount();
    window.addEventListener("resize", handleItemCount);
    return () => {
      window.removeEventListener("resize", handleItemCount);
    };
  }, []);

  //화면사이즈에 따른 보여지는 서비스 개수 조절
  const handleItemCount = () => {
    screenWidth = window.innerWidth;
    let changeViewCount = 0;
    //  모바일
    if (screenWidth <= 720) {
      changeViewCount = 1;
    }
    //  테블릿
    if (screenWidth > 720 && screenWidth < 1140) {
      changeViewCount = 2;
    }
    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 3;
    }
    if (itemViewCountBackup !== changeViewCount) {
      itemViewCountBackup = changeViewCount;
      setItemViewCount(changeViewCount);
    }
  };

  return (
    <div>
      {routeItem.map((item, index) => {
        return (
          <RouteItem
            key={item.rl_idx}
            item={item}
            index={index}
            itemViewCount={itemViewCount}
          />
        );
      })}
    </div>
  );
}

const RouteItem = ({ item, index, itemViewCount }) => {
  const { t } = useTranslation();
  const picUrl = util.changedResourceUrl(item.rl_pic_main);

  let width = 100 / itemViewCount;
  let wideWidth;

  if (itemViewCount === 3) {
    if (index !== 0 && index !== 3) {
      width = 25;
    } else {
      wideWidth = 50;
    }
  }

  return (
    <Link
      to={`/TourDetail/${item.rl_idx}`}
      className="p-1 d-inline-block"
      style={{ width: `${wideWidth ? wideWidth : width}%` }}
    >
      <div
        className={`rounded rounded-lg overflow-hidden position-relative cursor_pointer`}
        style={{
          background: `url(${picUrl}) 50% 50%/cover no-repeat`,
          height: 300,
        }}
      >
        <div
          id="tourListWithinHourItem"
          className="p-3 position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <h4 className="text-white text-center m-0">
            {countryList.get(item.rl_addr_country) &&
              countryList.get(item.rl_addr_country).name_ko}
            {codeList.codeSubject.get(item.rl_subject) &&
              ` · ${codeList.codeSubject.get(item.rl_subject).cs_name_kr}`}
          </h4>
          <p className="text-white text-center">{item.rl_title}</p>
          <div className="bg-buddible mt-2 text-white cursor_pointer py-2 px-3 rounded">
            {t("showDetail")}
          </div>
        </div>
      </div>
    </Link>
  );
};
