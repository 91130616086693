import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import { ko } from "date-fns/esm/locale";

import TourCostDetailModal from "./TourCostDetailModal";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let rrl_data = {
  rrl_guest_adult_num: 1, //성인 인원
  rrl_guest_child_num: 0, //어린이 인원
  rrl_guest_baby_num: 0, //유아 인원
  rrl_cost_total: 0, //총금액
  rrl_cost_fees_guest: 0, //게스트 부담 서비스수수료
  rrl_guest_over_cnt: 0, //초과 인원 수
  rrl_cost_over_guest: 0, // //초가 인원 요금
  rrl_cost_over_hour: 0, //초과 시간 요금
  rrl_date_reserve: null, //예약일자
  //  -----------------
  rrl_cost_tax_free: 0,
  rrl_cost_tax: 0,
  rrl_guest_total_num: 1,
};

let navWide = "auto";

export default function TourSideNav(props) {
  const { tourData, loginInfo, reserveData } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [position, setPosition] = useState({
    position: "unset",
    top: "unset",
  });

  //-------------------------------------------
  const [serviceSchedule, setServiceSchedule] = useState([]);
  const [isSelectsStart, setIsSelectsStart] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const calendar = useRef(null);

  useEffect(() => {
    handleReserveNavSize();
    window.addEventListener("resize", handleReserveNavSize);
    window.addEventListener("resize", handleReserveNavPosition);
    window.addEventListener("scroll", handleReserveNavPosition);
    return () => {
      window.removeEventListener("resize", handleReserveNavSize);
      window.removeEventListener("resize", handleReserveNavPosition);
      window.removeEventListener("scroll", handleReserveNavPosition);
      rrl_data = {
        rrl_guest_adult_num: 1, //성인 인원
        rrl_guest_child_num: 0, //어린이 인원
        rrl_guest_baby_num: 0, //유아 인원
        rrl_cost_total: 0, //총금액
        rrl_cost_fees_guest: 0, //게스트 부담 서비스수수료
        rrl_guest_over_cnt: 0, //초과 인원 수
        rrl_cost_over_guest: 0, // //초가 인원 요금
        rrl_cost_over_hour: 0, //초과 시간 요금
        rrl_date_reserve: null, //예약일자
        //  -----------------
        rrl_cost_tax_free: 0,
        rrl_cost_tax: 0,
        rrl_guest_total_num: 1,
      };
    };
  }, []);

  //예약 내비 사이즈 조절
  const handleReserveNavSize = () => {
    if (window.innerWidth >= 1200) {
      let setWidth = document.getElementById("sidebar").clientWidth - 30;
      navWide = setWidth;
    } else {
      navWide = "auto";
    }
    setIsNeedUpdate(!isNeedUpdate);
  };

  //예약내비 포지션 조절
  const handleReserveNavPosition = () => {
    if (!document.getElementById("tourInfo")) return;
    let scrollValue = window.scrollY;
    let wrapHeight = document.getElementById("tourInfo")?.offsetHeight;

    if (window.innerWidth > 1199) {
      if (scrollValue < 600) {
        setPosition({
          position: "static",
          bottom: "unset",
        });
      }

      if (scrollValue > 600) {
        setPosition({
          position: "fixed",
          top: 100,
        });
      }

      if (scrollValue > wrapHeight - 200) {
        setPosition({
          position: "absolute",
          bottom: 0,
        });
      }
    } else {
      setPosition({
        position: "unset",
        bottom: "unset",
      });
    }
  };

  useEffect(() => {
    if (tourData && tourData.hasOwnProperty("rl_route_uid")) {
      handleGuestCnt("rrl_guest_adult_num", 0);
      requestServiceSchedule(tourData.rl_route_uid);
    }
  }, [tourData]);

  const requestServiceSchedule = (rl_route_uid) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tourSchedule/JS_tour_schedule_select.php",
      msgID: msgID,
      sl_route_uid: tourData.rl_route_uid || rl_route_uid,
      sl_open_yn: "Y",
      sl_del_yn: "N",
      SEARCH_DATE_START: `${util.todayDate("YYYY-MM-DD")} 00:00:00`,
      // SEARCH_DATE_END: util.todayDate("YYYY-MM-DD"),
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let scheduleList = newData["returnData"];
          let setScheduleList = [];
          if (scheduleList.length > 0) {
            scheduleList.forEach((schedule, index) => {
              setScheduleList.push({
                id: index,
                startDate: schedule.sl_sdate.split("T")[0],
                endDate: schedule.sl_edate.split("T")[0],
                openTime:
                  schedule.sl_stime.split(":")[0] +
                  ":" +
                  schedule.sl_stime.split(":")[1],
                endTime:
                  schedule.sl_etime.split(":")[0] +
                  ":" +
                  schedule.sl_etime.split(":")[1],
              });
            });
          }
          setServiceSchedule([...setScheduleList]);
        } else {
          codeList.Modal.current.alert(t("errorMsg"), () => {
            window.location.replace(window.location.href);
          });
        }
      }
    });
  };
  //선택일자 변경
  const onChange = (dates) => {
    const [start] = dates;
    setStartDate(start);
    setIsSelectsStart(start === null);
  };

  //서비스 선택 및 날짜선택 모달 닫기
  const closeDatePicker = (item) => {
    let select_data;
    select_data = `${util.setDate(startDate)} ${item.openTime}`;
    rrl_data = {
      ...rrl_data,
      rrl_date_reserve: select_data,
    };
    calendar.current.setOpen(false);
    setIsNeedUpdate(!isNeedUpdate);
  };

  //게스트 인원및 금액 연산
  const handleGuestCnt = (key, calc) => {
    // calc 0 = minus, 1 = plus
    if (!key) return;
    if (calc && Number(tourData.rl_guest_max) <= rrl_data.rrl_guest_total_num)
      return;

    let cnt = calc ? ++rrl_data[key] : --rrl_data[key];
    if (cnt < 0) cnt = 0;
    if (key === "rrl_guest_adult_num" && cnt === 0) cnt = 1;
    rrl_data[key] = cnt;

    //게스트 총인원
    rrl_data.rrl_guest_total_num =
      rrl_data.rrl_guest_adult_num +
      rrl_data.rrl_guest_child_num +
      rrl_data.rrl_guest_baby_num;

    //게스트 초과인원 수 및 금액
    rrl_data.rrl_guest_over_cnt = 0;
    if (rrl_data.rrl_guest_total_num > Number(tourData.rl_guest_min)) {
      rrl_data.rrl_guest_over_cnt =
        rrl_data.rrl_guest_total_num - Number(tourData.rl_guest_min);
    }
    rrl_data.rrl_cost_over_guest =
      rrl_data.rrl_guest_over_cnt * Number(tourData.rl_over_pay_people);

    //서비스 수수료
    let cost_total =
      Number(tourData.rl_price_base) + rrl_data.rrl_cost_over_guest;
    rrl_data.rrl_cost_fees_guest = Math.round(cost_total * 0.15);

    //부가세 제외 금액
    rrl_data.rrl_cost_tax_free =
      Number(tourData.rl_price_base) + rrl_data.rrl_cost_fees_guest;
    if (rrl_data.rrl_cost_over_guest) {
      rrl_data.rrl_cost_tax_free += rrl_data.rrl_cost_over_guest;
    }

    //부가세
    rrl_data.rrl_cost_tax = rrl_data.rrl_cost_tax_free * 0.1;

    //총 결제 금액
    rrl_data.rrl_cost_total =
      rrl_data.rrl_cost_tax_free + rrl_data.rrl_cost_tax;

    rrl_data.rrl_cost_tax = Math.floor(rrl_data.rrl_cost_tax);
    rrl_data.rrl_cost_tax_free = Math.floor(rrl_data.rrl_cost_tax_free);
    rrl_data.rrl_cost_total = Math.floor(rrl_data.rrl_cost_total);

    setIsNeedUpdate(!isNeedUpdate);
  };

  //예약 요청페이지 이동
  const actionReserve = () => {
    if (!loginInfo || !loginInfo.hasOwnProperty("u_uid")) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }
    if (!rrl_data.rrl_date_reserve) {
      codeList.Modal.current.alert(t("checkSelectDateMsg"));
      return;
    }
    history.push({
      pathname: `/ReserveService/${tourData.rl_idx}`,
      state: rrl_data,
    });
  };

  // 가이드에게 문의 메세지 보내기
  const actionInquiry = () => {
    codeList.Modal.current.alert("오픈 준비중인 서비스입니다.");

    if (!loginInfo || !loginInfo.hasOwnProperty("u_uid")) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }

    codeList.Modal.current.confirm(
      `가이드에게 [${tourData.rl_title}]에 대한 문의메세지를 전송하시겠습니까?`,
      (ret) => {
        if (ret) {
          const data = {
            crm_main_uid: util.makeUUIDv4(),
            crm_guide_uid: tourData.rl_guide_uid,
            crm_service_type: "R",
            crm_service_uid: tourData.rl_route_uid,
            crm_reserve_uid: "",
            crm_status: "CI",
            crm_last_msg: `[${tourData.rl_title}]에 대한 문의요청이 도착했습니다.`,
            crm_last_time: new Date(),
            crm_last_u_uid: loginInfo.u_uid,
            crm_creator_uid: loginInfo.u_uid,
            crm_create_date: new Date(),
            crm_update_date: new Date(),
          };

          createChatRoom(data, (newData) => {
            sendInquiryMsg(data, (newData) => {
              codeList.Modal.current.alert(
                "문의요청 메세지가 정상적으로 전송되었습니다."
              );
            });
          });
        }
      }
    );
  };

  // 채팅방 생성
  const createChatRoom = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_createChatRoom.php",
      msgID: msgID,
      ...data,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다, 다시 시도해주세요"
          );
        }
      }
    });
  };

  // 문의요청 메세지 전송
  const sendInquiryMsg = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_sendInquiryMsg.php",
      msgID: msgID,
      crt_main_uid: data.crm_main_uid,
      crt_msg_uid: msgID,
      crt_type: "9",
      crt_msg: data.crm_last_msg,
      crt_file: "",
      crt_sender_uid: loginInfo.u_uid,
      crt_del_yn: "N",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다, 다시 시도해주세요"
          );
        }
      }
    });
  };

  const filteredReservePossibleDate = (date) => {
    for (let i = 0; i < serviceSchedule.length; i++) {
      const schedule = serviceSchedule[i];
      const dateTimestamp = +new Date(date);
      const startTimestamp = +new Date(schedule.startDate);
      const endTimestamp = +new Date(schedule.endDate);
      if (dateTimestamp >= startTimestamp && dateTimestamp <= endTimestamp) {
        return true;
      }
    }
  };

  //DatePicker 커스텀 인풋
  const DatePickerInput = React.forwardRef(({ onClick }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className="d-block px-5 py-2 rounded border"
      >
        {rrl_data.rrl_date_reserve === null
          ? t("tourDetailSideNavSelectDate")
          : rrl_data.rrl_date_reserve}
      </button>
    );
  });

  //DatePicker 커스텀 컨테이너
  const DatePickerContainer = ({ className, children }) => {
    return (
      <>
        <div className="d-flex flex-row justify-content-between">
          <div className="pt-3">
            <h3 className="m-0 p-0">{t("tourDetailSideNavSelectDate")}</h3>
            <p>{t("tourDetailSideNavSelectDateDesc")}</p>
          </div>
          <span
            className="cursor_pointer"
            onClick={() => calendar.current.setOpen(false)}
          >
            &times;
          </span>
        </div>

        <div className={`${className} min-width-290`}>
          <div style={{ position: "relative" }}>{children}</div>
        </div>

        <div>
          {serviceSchedule.length === 0 && (
            <p>※ 이용가능한 스케줄이 존재하지 않습니다.</p>
          )}
          <ul>
            {serviceSchedule.map((item, index) => {
              let selectDate = +new Date(
                util.getDateToFormat(startDate, "YYYY-MM-DD")
              );
              let sDate = +new Date(item.startDate);
              let eDate = +new Date(item.endDate);
              if (!(selectDate >= sDate && selectDate <= eDate)) return null;

              let checkReserveDate = `${util.getDateToFormat(
                startDate,
                "YYYY-MM-DD"
              )} ${item.openTime}:00`;
              let isReserved = reserveData.hasOwnProperty(checkReserveDate);

              let btn_style = " bg-buddible border border-buddible text-white";
              if (isReserved) {
                btn_style = " bg-light border text-secondary";
              }
              return (
                <li
                  key={index}
                  className={`d-inline-block rounded-pill py-1 px-2 mt-1 mr-2 cursor_pointer ${btn_style}`}
                  onClick={() => {
                    if (isReserved) return;
                    closeDatePicker(item);
                  }}
                >
                  {item.openTime}
                </li>
              );
            })}
          </ul>
          <div className="text-right">
            <span className="d-inline-flex flex-row align-items-center">
              <div
                className="d-inline-block rounded bg-buddible mr-1"
                style={{ width: "1em", height: "1em" }}
              />{" "}
              {t("reserveAvailable")}
            </span>
            <span className="d-inline-flex flex-row align-items-center ml-2">
              <div
                className="d-inline-block rounded bg-light border mr-1"
                style={{ width: "1em", height: "1em" }}
              />{" "}
              {t("reserveNotPossible")}
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <aside className="col-xl-3" id="sidebar" style={{ zIndex: 999 }}>
        <div
          className="theiaStickySidebar mt-4 mt-xl-0"
          style={{ ...position, width: navWide }}
        >
          <TourCostDetailModal rrl_data={rrl_data} tourData={tourData} />
          <div className="border">
            <h3 className="inner mt-0 rounded-top text-center bg-buddible text-white py-3">
              - {t("tourDetailSideNavReserveTitle")} -
            </h3>
            <div className="px-3">
              {/*날짜 입력란*/}
              <div className="d-flex flex-row justify-content-between py-3">
                <label>
                  <i className="icon-calendar-7" /> {t("date")}
                </label>
                <div className="custom_date_picker">
                  <DatePicker
                    onChange={(e) => {
                      onChange(e);
                    }}
                    selected={startDate}
                    startDate={startDate}
                    endDate={startDate}
                    selectsRange
                    selectsStart={isSelectsStart}
                    showPopperArrow={false} //초기 삼각형 존재여부
                    locale={ko} // 달력 언어 설정
                    monthsShown={1} //보여지는 월의 갯수
                    dateFormat="MM월 dd일" // 날짜 표시 형식
                    minDate={new Date()} // 과거 날짜 disable
                    shouldCloseOnSelect={false} //날짜 선택 후 사라지지 않음
                    ref={calendar}
                    customInput={<DatePickerInput />} // input 커스텀
                    calendarContainer={DatePickerContainer}
                    filterDate={filteredReservePossibleDate}
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => {
                      return (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            className="bg-transparent h6 m-0"
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <i className="fa-solid fa-chevron-left" />
                          </button>
                          <div className="flex-grow-1">
                            <p className="h6 font-weight-bold mb-0 text-center text-dark">
                              {date.getFullYear()}년 {date.getMonth() + 1}월
                            </p>
                          </div>
                          <button
                            className="bg-transparent h6 m-0"
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <i className="fa-solid fa-chevron-right" />
                          </button>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>{" "}
              <div className="d-flex flex-row justify-content-between py-2">
                <label>{t("adult")}</label>
                <div className="numbers-row">
                  <input
                    type="text"
                    value={rrl_data.rrl_guest_adult_num}
                    id="adults"
                    className="qty2 form-control bg-white"
                    name="adults_2"
                    readOnly={true}
                  />
                  <div
                    className="inc button_inc"
                    onClick={() => handleGuestCnt("rrl_guest_adult_num", 1)}
                  >
                    +
                  </div>
                  <div
                    className="dec button_inc"
                    onClick={() => handleGuestCnt("rrl_guest_adult_num", 0)}
                  >
                    -
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between py-2">
                {" "}
                <label>{t("child")}</label>
                <div className="numbers-row">
                  <input
                    type="text"
                    value={rrl_data.rrl_guest_child_num}
                    id="adults"
                    className="qty2 form-control bg-white"
                    name="adults_2"
                    readOnly={true}
                  />
                  <div
                    className="inc button_inc"
                    onClick={() => handleGuestCnt("rrl_guest_child_num", 1)}
                  >
                    +
                  </div>
                  <div
                    className="dec button_inc"
                    onClick={() => handleGuestCnt("rrl_guest_child_num", 0)}
                  >
                    -
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between py-2">
                {" "}
                <label>{t("baby")}</label>
                <div className="numbers-row">
                  <input
                    type="text"
                    value={rrl_data.rrl_guest_baby_num}
                    id="adults"
                    className="qty2 form-control bg-white"
                    name="adults_2"
                    readOnly={true}
                  />
                  <div
                    className="inc button_inc"
                    onClick={() => handleGuestCnt("rrl_guest_baby_num", 1)}
                  >
                    +
                  </div>
                  <div
                    className="dec button_inc"
                    onClick={() => handleGuestCnt("rrl_guest_baby_num", 0)}
                  >
                    -
                  </div>
                </div>
              </div>
              {/*금액란*/}
              <div className="d-flex flex-row justify-content-between py-2 m-0">
                <table className="table table_summary border-top-0 p-0 m-0">
                  <tbody>
                    <tr className="total">
                      <td className="px-1">
                        <i
                          className="fa-regular fa-circle-question mr-1 cursor_pointer"
                          data-toggle="modal"
                          data-target="#TourCostDetailModal"
                          onClick={(e) => {
                            e.preventDefault();
                            setTimeout(() => {
                              const backdrop =
                                document.getElementsByClassName(
                                  "modal-backdrop"
                                )[0];
                              if (backdrop) {
                                backdrop.remove();
                              }
                            }, 10);
                          }}
                        />
                        {t("feeTotal")}
                      </td>
                      <td className="text-right px-1">
                        &#8361; {util.addCommas(rrl_data.rrl_cost_total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/*예약 및 문의*/}
              <div
                className="btn_1 btn_full bg-buddible"
                onClick={() => actionReserve()}
              >
                {t("tourDetailSideNavReserveTitle")}
              </div>
              {/*
               // todo 채팅 기능 구현 후 사용(23-12-19:Amber)
               <button
              className="btn_1 btn_full bg-light  text-dark"
              onClick={actionInquiry}
            >
              {t("tourDetailSideNavReserveScheduleInquiry")}
            </button>*/}
            </div>
          </div>
        </div>
      </aside>
    </>
  );
}
