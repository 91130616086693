/*global google*/

import React, { Component } from "react";

class GuideTourRegistPlaceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: props.position,
      selected: [],
    };
  }

  componentDidMount({ map, mapApi } = this.props) {
    this.searchBox = new mapApi.places.SearchBox(this.input);
    // searchBox 에서 장소 선택 시 이벤트 발생(places_changed 는 구글에서 제공 되는 이벤트)
    this.searchBox.addListener("places_changed", this.onPlacesMultiChanged);
    //searhBox 에서 결과가 map 화면에 뿌려주며 화면을 이동
    this.searchBox.bindTo("bounds", map);
  }

  componentWillUnmount({ map, mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.input);
  }

  //여러개의 마커를 표시
  onPlacesMultiChanged = ({ map, mapApi, changedMarker } = this.props) => {
    const selected = this.searchBox.getPlaces();

    const { 0: place } = selected;

    const latLng = {
      lat: selected[0].geometry.location.lat(),
      lng: selected[0].geometry.location.lng(),
    };

    if (!place.geometry) {
      return;
    }

    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(14);
    }
    changedMarker(latLng, map, mapApi);
  };
  render() {
    return (
      <input
        id="pac-input"
        className="form-control mt-3"
        type="text"
        placeholder={
          this.props.tourData.rl_addr_name || "검색어를 입력해주세요"
        }
        disabled={this.props.tourData.rl_confirm_admin === "S"}
        maxLength="20"
        spellCheck={false}
        ref={(ref) => (this.input = ref)}
      />
    );
  }
}

export default GuideTourRegistPlaceSearch;
