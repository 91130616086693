/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { uploadFile } from "react-s3";
import Utilities from "./Utilities";
import CodeList from "./CodeList";

const codeList = new CodeList();
const util = new Utilities();
const S3_BUCKET = "playyj";
const ACCESS_KEY = "AKIAW7LFABWASIJAYZPF";
const SECRET_ACCESS_KEY = "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";
const REGION = "ap-northeast-2";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const UploadImageToS3WithReactS3 = ({
  region,
  callback,
  children,
  disabled = false,
  multi = false,
  max = 0,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoad, setIsLoad] = useState(false);

  const handleUpload = async (file) => {
    let customConfig = { ...config };
    customConfig.dirName = region;
    //console.log(file);
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    //let timestamp = new Date().getTime();
    let newName =
      name.split(".")[0] +
      "_" +
      util.todayDateTime() +
      "." +
      name.split(".")[1];
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    // console.log("handleUpload", newFile, customConfig);

    uploadFile(newFile, customConfig)
      .then((data) => {
        const returnData = {
          ret: true,
          multiple: multi,
          fileName: `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${region}/${newName}`,
          realFileName: name,
        };

        callback && callback(returnData);
      })
      .catch((err) => {
        const returnData = {
          ret: false,
          multiple: multi,
          fileName: ``,
          realFileName: ``,
        };
        callback && callback(returnData);
      })
      .finally(() => {
        !multi && setIsLoad(false);
      });
  };

  const handleFileInput = async (e) => {
    let files = Object.values(e.target.files);

    if (max > 0 && files.length > max) {
      codeList.Modal.current.alert(`최대 ${max}장까지만 등록 가능합니다.`);
      return;
    }

    if (files.length === 0) return;
    setSelectedFile(files);
    setIsLoad(true);
    if (multi) {
      for (const file of files) {
        await handleUpload(file);
      }
      setIsLoad(false);
    } else {
      handleUpload(files[0]);
    }
  };
  const htmlID = `customFile_${util.makeUUIDv4Short()}`;

  return (
    <div className={children ? "" : "form-group w-100 mb-0"}>
      <div className={children ? "" : "custom-file"}>
        <input
          type="file"
          className={`custom-file-input ${children ? "d-none" : ""}`}
          id={htmlID}
          disabled={disabled || isLoad}
          multiple={multi}
          onChange={handleFileInput}
        />
        {children ? (
          <label className="m-0" htmlFor={htmlID}>
            {children}
          </label>
        ) : (
          <label className="custom-file-label" htmlFor={htmlID}>
            파일첨부{" "}
            {isLoad && (
              <i className="fa-solid fa-circle-notch rotate-infinite" />
            )}
          </label>
        )}
      </div>
    </div>
  );
};

export default UploadImageToS3WithReactS3;
