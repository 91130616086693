import React from "react";

export default function GuideRegistSafetyIntro(props) {
  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/safety1Overview1.jpg")50% 50%/cover no-repeat`,
          }}
        />
        <p>
          플레이 영종은 전문가와 협력하여 여행 진행 중 대면 접촉과 관련한 안전
          가이드라인을 새로 마련했습니다. 모든 가이드는 다음과 같은 주요 사항을
          숙지해야 합니다.
        </p>
      </div>
      <div className="py-3">
        <p>다음과 같은 사항이 여기에 해당 됩니다.</p>
        <ul className="pt-3">
          <li className="mb-3"> · 위생 수칙 지키기</li>
          <li className="mb-3"> · 신체 접촉 피하기</li>
          <li className="mb-3"> · 장소 변경</li>
          <li className="mb-3"> · 청결 강화 기준 준수</li>
        </ul>
        <p>각 사항을 좀 더 자세히 알아보겠습니다.</p>
      </div>
    </div>
  );
}
