import React, { useEffect, useRef, useState } from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import CommunityDetailReply from "./CommunityDetailReply";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "CommunityDetail";

let loginInfo = null;
let isCreator = false;

export default function CommunityDetail(props) {
  const { match, history } = props;
  const { t } = useTranslation();
  const [forumData, setForumData] = useState(null);
  const [replyData, setReplyData] = useState([]);
  const [replyCnt, setReplyCnt] = useState(0);
  const replyRef = useRef();

  const CATEGORY = {
    NOTICE: t("notice"),
    GUIDE: t("guideTip"),
    GUEST: t("guestTip"),
    NEWS: t("news"),
    FORUM: t("communityForum"),
  };

  (() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          loginInfo = n;
        }
      }
    );
  })();

  useEffect(() => {
    if (!match.params.hasOwnProperty("cm_idx")) {
      alert(t("inaccessibleMsg"));
      history.goBack();
    }

    requestCommunityDetailData((newData) => {
      if (newData["returnData"].length === 0) {
        alert(t("errorMsg"));
        history.goBack();
      }
      let data = { ...newData["returnData"][0] };
      data.cm_look_cnt = Number(data.cm_look_cnt) + 1;
      if (loginInfo) {
        isCreator = loginInfo.u_uid === data.cm_create_uid;
      }
      setForumData(data);

      requestCommunityReplay(data.cm_uid, (newData) => {
        handleReplyData(newData);
        updateCommunityIncreaseLookCnt({
          cm_idx: data.cm_idx,
          cm_uid: data.cm_uid,
          cm_look_cnt: data.cm_look_cnt,
        });
      });
    });

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  // 게시글 상세정보 받아오기
  const requestCommunityDetailData = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityDetailDataSelect.php",
      msgID: msgID,
      cm_idx: match.params.cm_idx,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 조회수 업데이트
  const updateCommunityIncreaseLookCnt = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityIncreaseLookCnt.php",
      msgID: msgID,
      ...data,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };
  // 게시글 댓글 목록 받아오기
  const requestCommunityReplay = (cr_main_uid, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community_reply/JS_communityReplySelect.php",
      msgID: msgID,
      cr_main_uid: cr_main_uid,
      cr_sub_uid: "",
      // cr_del_yn: "N",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  const handleReplyData = (newData) => {
    let replyMain = newData["returnData"].filter(
      (item) => item.cr_sub_uid === ""
    );
    let replySub = newData["returnData"].filter(
      (item) => item.cr_sub_uid !== ""
    );
    for (let i = 0; i < replyMain.length; i++) {
      let mainItem = replyMain[i];
      mainItem.replySub = [];
      for (let j = 0; j < replySub.length; j++) {
        const subItem = replySub[j];
        if (mainItem.cr_uid === subItem.cr_sub_uid) {
          mainItem.replySub = [...mainItem.replySub, subItem];
        }
      }
    }

    setReplyCnt(parseInt(newData["cnt"]));
    setReplyData(replyMain);
  };

  // 댓글, 대댓글 등록
  const appendReply = (cr_main_uid, cr_sub_uid, reply) => {
    if (loginInfo === null) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }

    if (reply.trim() === "") {
      codeList.Modal.current.alert(t("emptyContentsMsg"));
    }

    let updateData = {
      cr_main_uid: cr_main_uid,
      cr_sub_uid: cr_sub_uid,
      cr_u_uid: loginInfo.u_uid,
      cr_reply: reply,
      cr_del_yn: "N",
      cr_create_date: new Date(),
    };

    updateCommunityReply(updateData, (newData) => {
      requestCommunityReplay(forumData.cm_uid, (newData) => {
        handleReplyData(newData);
      });
    });
  };

  const deleteReply = (reply) => {
    codeList.Modal.current.confirm(
      `[${reply.cr_reply}] ${t("confirmDeleteMsg")}`,
      (ret) => {
        if (ret) {
          const updateData = {
            ...reply,
            cr_del_yn: "Y",
          };
          updateCommunityReply(updateData, (newData) => {
            requestCommunityReplay(forumData.cm_uid, (newData) => {
              handleReplyData(newData);
            });
          });
        }
      }
    );
  };

  // 댓글 insert, update 통신
  const updateCommunityReply = (updateData, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community_reply/JS_communityReplyUpdate.php",
      msgID: msgID,
      cr_uid: msgID,
      ...updateData,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  if (forumData === null) return <></>;

  let creatorName =
    forumData.u_name_full ||
    forumData.u_name_first + " " + forumData.u_name_last;
  let creatorPic = "";

  if (forumData.u_avata_pic.indexOf("http") > -1) {
    if (forumData.u_avata_pic.indexOf("?") > -1) {
      creatorPic = forumData.u_avata_pic.split("?")[1];
    } else {
      creatorPic = forumData.u_avata_pic;
    }
  } else {
    creatorPic = codeList.publicVar.imgServerUrl + forumData.u_avata_pic;
  }

  let replyUserProfile = codeList.publicVar.unProfileImage;
  if (loginInfo) {
    if (loginInfo.u_avata_pic.indexOf("http") > -1) {
      replyUserProfile = loginInfo.u_avata_pic;
    } else {
      replyUserProfile =
        codeList.publicVar.imgServerUrl + loginInfo.u_avata_pic;
    }
  }

  return (
    <>
      <Nav />
      <main style={{ marginTop: 80, minHeight: "100vh" }}>
        <div className="margin_60">
          <div className="row justify-content-center m-0 p-0">
            <div className="col-12 col-lg-7">
              <div className="border bg-white p-4 text-secondary">
                <div className="d-flex flex-row mb-5">
                  <div
                    className="rounded-circle border mr-2"
                    style={{
                      minWidth: 40,
                      height: 40,
                      width: 40,
                      background: `url(${creatorPic}) 50% 50% / cover no-repeat`,
                    }}
                  />
                  <div className="flex-fill d-md-flex flex-md-row justify-content-between">
                    <div>
                      <p className="h6 font-weight-bold text-dark mb-0">
                        {creatorName}
                      </p>
                      <p>
                        {CATEGORY[forumData.cm_type]}
                        <span className="d-none d-md-inline"> · </span>
                        <br className="d-md-none" />
                        {forumData.cm_create_date}{" "}
                      </p>
                    </div>
                    <div>
                      <p className="font-weight-bold text-secondary text-right">
                        {t("view")} {util.addCommas(forumData.cm_look_cnt)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-5">
                  <p className="h5 font-weight-bolder text-dark">
                    {forumData.cm_title}
                  </p>
                  <p className="h6">{forumData.cm_sub_title}</p>
                </div>
                <div
                  className="mb-3 quill-wrap"
                  style={{ fontSize: "1.2em" }}
                  dangerouslySetInnerHTML={{ __html: forumData.cm_content }}
                />
                <div>
                  <div className="border-bottom py-3">
                    <div className="d-flex flex-column flex-md-row justify-content-between">
                      <h3 className="font-weight-bold text-secondary order-1 order-md-0 mt-2 mt-md-0">
                        {t("reply")} {util.addCommas(replyCnt)}
                      </h3>

                      <div className="order-0 order-md-1 text-right">
                        {isCreator && (
                          <Link
                            to={`/CommunityWrite/${forumData.cm_idx}`}
                            className="font-weight-bold text-secondary mr-3"
                          >
                            {t("edit")} <i className="fa-solid fa-pen ml-1"></i>
                          </Link>
                        )}
                        <Link
                          to={`/CommunityList/${forumData.cm_type}`}
                          className="font-weight-bold text-secondary"
                        >
                          {CATEGORY[forumData.cm_type]} {t("showMore")}{" "}
                          <i className="fa-solid fa-arrow-turn-up ml-1" />
                        </Link>
                      </div>
                    </div>
                    <div className="pt-3 d-flex flex-column flex-md-row">
                      <div
                        style={{
                          minWidth: 40,
                          width: 40,
                          height: 40,
                          background: `url(${replyUserProfile}) 50% 50% / cover no-repeat`,
                        }}
                        className="rounded-circle border"
                      />
                      <input
                        className="form-control rounded-xl bg-light border-0 my-2 my-md-0 mx-md-2"
                        type="text"
                        ref={replyRef}
                        disabled={!loginInfo}
                        placeholder={
                          loginInfo
                            ? t("communityReplyInputNote")
                            : t("communityReplyInputNoteLogin")
                        }
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            appendReply(forumData.cm_uid);
                          }
                        }}
                      />
                      <button
                        className="btn_1"
                        style={{ minWidth: 80 }}
                        disabled={!loginInfo}
                        onClick={() => {
                          appendReply(
                            forumData.cm_uid,
                            "",
                            replyRef.current.value
                          );
                        }}
                      >
                        {t("registration")}
                      </button>
                    </div>
                  </div>
                  <div>
                    <CommunityDetailReply
                      replyData={replyData}
                      loginInfo={loginInfo}
                      appendReply={appendReply}
                      deleteReply={deleteReply}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
