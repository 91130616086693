import React, { useCallback, useRef, useState } from "react";

import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const times = [...codeList.publicVar.times];

export default function GuideScheduleManage(props) {
  const { guideInfo, loginInfo, scheduleList, updateServiceSchedule } = props;

  const ItemRenderer = useCallback((props) => {
    return <Item {...props} />;
  }, []);

  return (
    <>
      <div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-2">
          <p className="h5 mb-3 font-weight-bold">
            서비스의 OPEN 일정을 관리하세요
          </p>
          <div className="text-right">
            <button
              className="btn_1"
              data-toggle="modal"
              data-target="#GuideScheduleEditModal"
              disabled={guideInfo.gl_confirm_admin !== "Y"}
            >
              서비스 일정 추가 <i className="fa-solid fa-plus" />
            </button>
          </div>
        </div>

        {scheduleList.length > 0 &&
          scheduleList.map((item) => {
            return (
              <ItemRenderer
                key={item.sl_idx}
                item={item}
                loginInfo={loginInfo}
                updateServiceSchedule={updateServiceSchedule}
              />
            );
          })}

        {scheduleList.length === 0 && (
          <div className="p-5 rounded bg-light text-center">
            <p className="h6 font-weight-bold">
              {guideInfo.gl_confirm_admin !== "Y"
                ? "버디요청 승인 후 이용 가능한 서비스입니다."
                : "등록된 스케줄이 존재하지 않습니다."}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

const Item = React.memo(({ item, loginInfo, updateServiceSchedule }) => {
  let {
    rl_route_uid,
    rl_guide_uid,
    rl_pic_main,
    rl_guest_min,
    rl_guest_max,
    rl_base_hour_duration,
    rl_title,
    sl_idx,
    sl_sdate,
    sl_edate,
    sl_stime,
    sl_etime,
    sl_open_yn,
  } = item;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const controller = {
    sl_sdate: useRef(),
    sl_edate: useRef(),
    sl_stime: useRef(),
    sl_etime: useRef(),
    sl_open_yn: useRef(),
  };

  // 서비스 시작일, 종료일 변환(시작일이 종료일보다 큰 경우)
  const handleScheduleDate = (event) => {
    const name = event.target.name;
    const sDate = +new Date(controller.sl_sdate.current.value);
    const eDate = +new Date(controller.sl_edate.current.value);
    if (sDate > eDate) {
      if (name === "sl_sdate") {
        controller.sl_edate.current.value = event.target.value;
      } else {
        controller.sl_sdate.current.value = event.target.value;
      }
    }
  };

  // 서비스 open 시간 변경
  const changedOpenTime = (e) => {
    if (rl_base_hour_duration === null) {
      codeList.Modal.current.alert("서비스를 우선 선택해주세요");
      controller.sl_stime.current.value = "00:00:00";
      return;
    }

    let value = e.target.value;
    let sTime = times.find((time) => time.value === value);
    let dTime = times.find((time) => time.value === rl_base_hour_duration);

    let endID = Number(sTime.id) + Number(dTime.id);
    if (endID > 1440) endID -= 1440;

    endID = endID.toString();

    let eTime = times.find((time) => time.id === endID);
    controller.sl_etime.current.value = eTime.value;
    setIsNeedUpdate((before) => !before);
  };

  // 유효성 검사
  const checkValidation = (data) => {
    if (data.sl_route_uid.trim() === "") {
      codeList.Modal.current.alert("OPEN 일정을 추가할 서비스를 선택해주세요");
      return false;
    }
    if (data.sl_sdate.trim() === "") {
      codeList.Modal.current.alert("서비스 OPEN 시작일을 선택해주세요", () => {
        controller.sl_sdate.current.focus();
      });

      return false;
    }

    if (data.sl_edate.trim() === "") {
      codeList.Modal.current.alert(
        "서비스 OPEN 종료일을 선택해주세요. 서비스 종료일자가 지나면 게스트에게 노출되지 않아요.",
        () => {
          controller.sl_edate.current.focus();
        }
      );

      return false;
    }

    if (+new Date(data.sl_sdate) > +new Date(data.sl_edate)) {
      codeList.Modal.current.alert(
        "OPEN 종료일을 시작일보다 이후날짜로 선택해주세요.",
        () => {
          controller.sl_sdate.current.focus();
        }
      );

      return false;
    }

    if (data.sl_stime.trim() === "") {
      codeList.Modal.current.alert(
        "서비스 시작 시작을 선택해주세요. 지정된 시간에 게스트에게 서비스를 제공합니다.",
        () => {
          controller.sl_stime.current.focus();
        }
      );

      return false;
    }
    return true;
  };

  // 저장
  const actionSave = () => {
    const updateData = {
      sl_idx: sl_idx,
      sl_guide_uid: rl_guide_uid,
      sl_route_uid: rl_route_uid,
      sl_uid_type: "R",
      sl_sdate: controller.sl_sdate.current.value,
      sl_edate: controller.sl_edate.current.value,
      sl_stime: controller.sl_stime.current.value,
      sl_etime: controller.sl_etime.current.value,
      sl_open_yn: controller.sl_open_yn.current.value,
      sl_del_yn: "N",
    };
    if (checkValidation(updateData)) {
      updateServiceSchedule(updateData, () => {
        codeList.Modal.current.alert(
          "스케줄 정보가 정상변경되었습니다.",
          () => {}
        );
      });
    }
  };

  // 스케줄삭제
  const deleteSchedule = () => {
    codeList.Modal.current.confirm(
      "선택한 스케줄을 삭제하시겠습니까?",
      (ret) => {
        if (!ret) return;
        const updateData = {
          ...item,
          sl_del_yn: "Y",
        };

        updateServiceSchedule(updateData, () => {
          codeList.Modal.current.alert(
            "스케줄이 정상 삭제되었습니다.",
            () => {}
          );
        });
      }
    );
  };

  if (rl_pic_main !== "") {
    if (rl_pic_main.indexOf("http") > -1) {
      rl_pic_main = rl_pic_main.split("?")[1];
    } else {
      rl_pic_main = codeList.publicVar.imgServerUrl + rl_pic_main;
    }
  }

  return (
    <div className="p-3 mb-2 rounded border shadow-sm">
      <div className="row m-0 p-0">
        <div className="col-12 col-lg-12 col-xl-3 m-0 mb-2 mb-xl-0 p-0 px-1">
          <div className="d-flex flex-row align-items-center">
            <img
              src={rl_pic_main}
              alt={rl_title}
              className="mr-3"
              style={{ width: 50, height: 50, objectFit: "contain" }}
            />
            <div>
              <p className="font-weight-bold ellipsis_1">
                <span className="badge badge-success">승인</span> {rl_title}
              </p>
              <p>
                최소인원 : {rl_guest_min}명 · 최대인원 : {rl_guest_max}명
              </p>
              <p>소요시간 : {rl_base_hour_duration}</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-3 m-0 mb-2 mb-xl-0 p-0 px-1">
          <p className="mb-1 font-weight-bold text-secondary">OPEN 기간</p>
          <div className="input-group">
            <input
              className="form-control"
              type="date"
              min={util.todayDate("YYYY-MM-DD")}
              defaultValue={util.getDateToFormat(sl_sdate, "YYYY-MM-DD")}
              ref={controller.sl_sdate}
              name={"sl_sdate"}
              onChange={handleScheduleDate}
            />
            <div className="input-group-text rounded-0 border-right-0 border-left-0">
              -
            </div>
            <input
              className="form-control"
              type="date"
              min={util.todayDate("YYYY-MM-DD")}
              defaultValue={util.getDateToFormat(sl_edate, "YYYY-MM-DD")}
              ref={controller.sl_edate}
              name={"sl_edate"}
              onChange={handleScheduleDate}
            />
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl-3 m-0 mb-2 mb-xl-0 p-0 px-1">
          <p className="mb-1 font-weight-bold text-secondary">
            서비스 제공 시간
          </p>
          <div className="input-group">
            <select
              className="form-control"
              ref={controller.sl_stime}
              onChange={changedOpenTime}
              defaultValue={sl_stime}
            >
              {times.map((time, index) => {
                return (
                  <option key={index} value={time.value}>
                    {time.value}
                  </option>
                );
              })}
            </select>
            <div className="input-group-text rounded-0 border-right-0 border-left-0">
              -
            </div>
            <select
              className="form-control"
              ref={controller.sl_etime}
              disabled={true}
              defaultValue={sl_etime}
            >
              {times.map((time, index) => {
                return (
                  <option key={index} value={time.value}>
                    {time.value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-12 col-lg-12 col-xl-3 m-0 mb-xl-0 p-0 px-1">
          <p className="mb-1 font-weight-bold text-secondary">상태</p>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <select
              className="form-control py-0"
              ref={controller.sl_open_yn}
              defaultValue={sl_open_yn}
            >
              <option value="Y">사용</option>
              <option value="N">중지</option>
            </select>
            <button
              className="btn_1 mx-2"
              style={{ minWidth: 80, height: 40 }}
              onClick={actionSave}
            >
              저장
            </button>
            <button
              className="btn_1 bg-danger"
              style={{ minWidth: 80, height: 40 }}
              onClick={deleteSchedule}
            >
              삭제
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
