import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Nav from "../common/Nav";
import GuideRegistIntroCarousel from "./component/GuideRegistIntroCarousel";
import GuideRegistIntroContent from "./component/GuideRegistIntroContent";
import GuideRegistIntroBanner from "./component/GuideRegistIntroBanner";
import GuideRegistIntroStep from "./component/GuideRegistIntroStep";
import Footer from "../common/Footer";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "GuideRegistIntro";
let loginInfo = null;

export default function GuideRegistIntro() {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData;
      setIsNeedUpdate(!isNeedUpdate);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
        setIsNeedUpdate(!isNeedUpdate);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  const checkLoginData = () => {
    if (loginInfo === null) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }

    if (loginInfo.gl_guide_uid !== "") {
      codeList.Modal.current.confirm(t("confirmEditGuideInfoMsg"), (ret) => {
        if (ret) {
          history.push("/GuideRegist");
        }
      });
      return;
    }

    history.push("/GuideRegist");
  };

  return (
    <>
      <Nav />
      <main>
        <GuideRegistIntroCarousel />
        <GuideRegistIntroContent />
        <GuideRegistIntroBanner checkLoginData={checkLoginData} />
        <GuideRegistIntroStep checkLoginData={checkLoginData} />
      </main>
      <Footer />
    </>
  );
}
