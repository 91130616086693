import React from "react";
import TourNewToolbar from "./TourNewToolbar";
import TourNewContents from "./TourNewContents";
import { useTranslation } from "react-i18next";

export default function TourNew(props) {
  const { routeItem } = props;
  const { t } = useTranslation();

  return (
    <section className="container margin_60 font">
      <TourNewToolbar />
      {routeItem.length > 0 && <TourNewContents routeItem={routeItem} />}

      {routeItem.length === 0 && (
        <p className="h5 text-secondary text-center rounded-lg p-5 bg-white">
          {t("emptyServiceNote")}
        </p>
      )}
    </section>
  );
}
