import React, { useEffect, useState } from "react";

import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let codeSubject = null;
let codeHashtag = null;

let subject_data = {
  gpt_title: "",
};

let hashtagValue = null;
let selectedKeyword = [];

export default function GuideTimeRegistSubject(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  (() => {
    let dataCodeSubject = codeList.codeSubject;
    if (dataCodeSubject) {
      let subject = [];
      dataCodeSubject.forEach((item, index) => {
        subject.push(item);
      });
      codeSubject = subject;
    }

    let dataCodeHashTag = codeList.codeHashtag;
    if (dataCodeHashTag) {
      let hashtag = [];
      dataCodeHashTag = [...dataCodeHashTag];
      dataCodeHashTag.forEach((item) => {
        hashtag.push({ id: item[0], desc: item[1] });
      });
      codeHashtag = hashtag;
    }
  })();

  useEffect(() => {
    if (partTimeData) {
      for (let key in subject_data) {
        subject_data[key] = partTimeData[key];
      }
      GetHashtag();
    }
  }, []);

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (key, val) => {
    subject_data[key] = val;
    guideTimeRegistDataChange(subject_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  //해시태그 입력정보 가져오기
  const GetHashtag = () => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "GetHashtag",
      data: {
        htr_type: "R",
        htr_uid: partTimeData.gpt_part_time_uid,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let tagList = [...newData["ReturnData"]];
          let codeHashtag = [];
          tagList.forEach((hashtag) => {
            codeHashtag.push({
              id: hashtag.htr_tag_key,
              desc: hashtag.htc_name,
            });
          });
          selectedKeyword = [...codeHashtag];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //해시태그 추가
  const InsertHashtag = (hashtag) => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "InsertHashtag",
      data: {
        htr_type: "P",
        htr_uid: partTimeData.gpt_part_time_uid,
        htr_tag_key: hashtag.id,
        htr_tag_name: hashtag.desc,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          hashtagValue = "";
          GetHashtag();
        }
      }
    });
  };

  //해시태그 전체삭제
  const removeAllHashtag = () => {
    for (let i = 0; i < selectedKeyword.length; i++) {
      RemoveHashtag(selectedKeyword[i].id);
    }
  };

  //해시태그 삭제
  const RemoveHashtag = (deleteKey) => {
    let msgID = util.makeUUIDv4();
    let value = {
      method: "RemoveHashtag",
      data: {
        htr_type: "P", //G 가이드, R 루트, P 파트타임
        htr_uid: partTimeData.gpt_part_time_uid, //htr_type 경우에 따른 uid
        htr_tag_key: deleteKey,
      },
    };
    socket.sendMessage(value, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = [...selectedKeyword];
          let deleteIndex = returnData.findIndex(
            (hashtag) => hashtag.id === deleteKey
          );
          returnData.splice(deleteIndex, 1);
          selectedKeyword = [...returnData];
          GetHashtag();
        }
      }
    });
  };

  return (
    <>
      <div className="col-xl-6">
        <div className="mb-3 pb-3 border-bottom">
          <p className="h3 font-weight-bold">어떤 유형의 가이드를 하시나요?</p>
          <p>
            간결하면서도 여행을 잘 표현하며, 흥미를 유발하는 제목을 작성하세요.
          </p>
          <div className="py-5 d-flex flex-column justify-content-center align-items-start">
            <p className="h4">여행 주제에 맞는 제목을 입력하세요.</p>
            <input
              className="border rounded w-100 p-3"
              placeholder="여기에 제목을 입력해주세요"
              onChange={(e) => {
                handleTourDataChange("gpt_title", e.target.value);
                guideTimeRegistDataChange(subject_data);
              }}
              value={subject_data.gpt_title}
            />
          </div>
        </div>
        <div className="mb-3 py-3 border-bottom d-none">
          <p className="h4 font-weight-bold">
            해시태그 형식으로 다른 주제도 추가해보세요. (선택 사항)
          </p>
          <p>
            정말 독특한 여행은 2가지 이상의 주제를 갖고 있습니다. 더 많은 주제를
            추가하여 여행의 다양한 면모를 보여주세요.
            <br />
            <br />
            게스트는 해시태그 검색을 통해 가이드를 선택할 수 있습니다.
            <br />
            <br />
            예를 들어 호치민에서 여행객들은 잘 모르는 숨겨진 맛집을 알고 있다면
            <strong> #맛집 #호치민맛집 #현지인맛집</strong> 한눈에 볼수있는
            해시테그와 <strong>#나만알고싶은맛집</strong> 재미로 관심을 끌수있는
            해시테그도 넣어주세요.
          </p>
        </div>
        <div className="mb-3 py-3 d-none">
          <h6 className="font-weight-bold">나만의 해시태그를 만들어 주세요.</h6>
          <p>게스트는 헤시태그 검색을 통해 가이드를 선택할 수 있습니다.</p>
          <div>
            {codeHashtag != null && (
              <>
                <Autocomplete
                  value={hashtagValue}
                  onChange={(event, newValue) => {
                    let hash = newValue;

                    if (hash === null) {
                      hashtagValue = "";
                    } else if (typeof hash === "object" && hash.id === "") {
                      let splitData = hash.desc.split('"');
                      if (splitData.length === 0) return;
                      hash.desc = splitData[1];
                      hashtagValue = splitData[1];
                    } else {
                      hashtagValue = hash;
                    }

                    let find = selectedKeyword.findIndex(
                      (item) => item.desc === hash.desc
                    );
                    if (find === -1) {
                      selectedKeyword.push(hash);
                      InsertHashtag(hash);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const { inputValue } = params;

                    const filtered = options.filter(
                      (option) => option.desc.indexOf(inputValue) > -1
                    );
                    // Suggest the creation of a new value
                    const isExisting = filtered.some(
                      (option) => inputValue === option.desc
                    );
                    if (inputValue !== "" && !isExisting) {
                      filtered.push({
                        id: "",
                        desc: `해시태그 추가 "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={codeHashtag}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (!option.hasOwnProperty("id")) {
                      return "";
                    }
                    if (typeof option.desc === "string") {
                      return option.desc;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.desc;
                  }}
                  renderOption={(props, option) => {
                    return <div>{props.desc}</div>;
                  }}
                  style={{ width: "100%", margin: "20px 0" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="# 키워드를 입력해주세요."
                      variant="outlined"
                    />
                  )}
                />
              </>
            )}
            <div className="d-flex flex-row justify-content-start flex-wrap">
              {selectedKeyword.length > 0 && (
                <>
                  <div
                    className="p-1 cursor_pointer"
                    onClick={() => removeAllHashtag()}
                  >
                    <div className="rounded rounded-pill border px-4 py-2">
                      전체삭제
                      <i className="far fa-trash-alt ml-2" />
                    </div>
                  </div>
                  {selectedKeyword.map((item, index) => {
                    return (
                      <div key={item.id + "_" + index} className="p-1">
                        <div className="rounded rounded-pill bg-light px-4 py-2">
                          {item.desc}
                          <span
                            className="cursor_pointer font-weight-bold ml-2"
                            onClick={() => RemoveHashtag(item.id)}
                          >
                            &times;
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
