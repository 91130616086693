import React from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import PrivacyPolicy from "./PrivacyPolicy";
import { useParams } from "react-router-dom";

export default function Terms(props) {
  let params = useParams();

  let pages = {
    // termsOfUse: {
    //   id: "termsOfUse",
    //   component: <TermsOfUse />,
    //   title: "GBTS 이용약관",
    // },
    // mallOperationPolicy: {
    //   id: "mallOperationPolicy",
    //   component: <MallOperationPolicy />,
    //   title: "로지스몰 운영정책",
    // },
    // talkOperationPolicy: {
    //   id: "talkOperationPolicy",
    //   component: <TalkOperationPolicy />,
    //   title: "로지톡 운영정책",
    // },
    PrivacyPolicy: {
      id: "PrivacyPolicy",
      component: <PrivacyPolicy />,
      title: "개인정보 처리방침",
    },
    // disclaimer: {
    //   id: "disclaimer",
    //   component: <Disclaimer />,
    //   title: "면책공고",
    // },
  };

  const createdTerms = () => {
    return pages[params["termsType"]].component;
  };

  return (
    <>
      <Nav />
      <main>
        <div className="container p-5 bg-white rounded">{createdTerms()}</div>
      </main>
      <Footer />
    </>
  );
}
