import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Utilities from "../../../lib/Utilities";

const util = new Utilities();

export default function Post(props) {
  const { loginInfo, u_community_cnt, u_community_list } = props;
  const { t } = useTranslation();

  const CATEGORY = {
    NOTICE: t("notice"),
    GUIDE: t("guideTip"),
    GUEST: t("guestTip"),
    NEWS: t("news"),
    FORUM: t("communityForum"),
  };

  const ItemRender = useCallback((props) => {
    return <Item {...props} />;
  }, []);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="d-flex flex-row justify-content-between">
            <h4 className="mb-0 font-weight-bold">
              {t("post")} {u_community_cnt}
              {t("count")}
            </h4>
            <Link to="/CommunityWrite" className="btn btn-sm btn-outline-dark">
              <i className="fa-solid fa-plus mr-2" />
              {t("write")}
            </Link>
          </div>
        </div>
      </div>
      <div>
        {u_community_list.map((item, index) => (
          <ItemRender
            key={item.cm_uid}
            item={{ ...item, ...loginInfo }}
            CATEGORY={CATEGORY}
          />
        ))}
      </div>
    </div>
  );
}

const Item = React.memo(({ item, CATEGORY }) => {
  const { t } = useTranslation();
  const creatorName =
    item.u_name_full || item.u_name_first + " " + item.u_name_last;
  const creatorPic = util.changedResourceUrl(item.u_avata_pic);

  return (
    <div className="p-4 border bg-white mb-3 text-secondary">
      <div className="d-flex flex-row mb-4">
        <div
          className="rounded-circle border mr-2"
          style={{
            height: 40,
            width: 40,
            minWidth: 40,
            background: `url(${creatorPic}) 50% 50% / cover no-repeat`,
          }}
        />
        <div className="flex-fill d-flex flex-column flex-md-row justify-content-between">
          <div>
            <p className="h6 font-weight-bold text-dark mb-0">{creatorName}</p>
            <p>
              {CATEGORY[item.cm_type]} · {item.cm_create_date}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <p className="h5 font-weight-bolder text-dark">{item.cm_title}</p>
        <p className="h6">{item.cm_sub_title}</p>
      </div>
      <div
        className="overflow-hidden position-relative"
        style={{ maxHeight: 250 }}
      >
        <div
          className="mb-3 quill-wrap"
          style={{ fontSize: "1.2em" }}
          dangerouslySetInnerHTML={{ __html: item.cm_content }}
        />
        <div
          className="position-absolute w-100 h-25"
          style={{
            bottom: 0,
            left: 0,
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%)",
          }}
        />
      </div>
      <div className="d-flex flex-row justify-content-between mt-3">
        <div className="font-weight-bold text-secondary">
          <span className="mr-2">
            {t("reply")} {util.addCommas(item.cm_reply_cnt)}
          </span>
          <span>
            {t("view")} {util.addCommas(item.cm_look_cnt)}
          </span>
        </div>
        <Link
          to={`/CommunityDetail/${item.cm_idx}`}
          className="font-weight-bold m-0 text-secondary"
        >
          {t("showDetail")} <i className="fa-solid fa-arrow-turn-up ml-1" />
        </Link>
      </div>
    </div>
  );
});
