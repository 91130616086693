import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuideRegistBasicInfoLanguage";

let langs = null;
let subLangs = null;

const lang_level = [
  { id: "Pr", name: "Proficient - 능숙(원어민 수준)", rating: 5 },
  { id: "Ad", name: "Advanced - 고급(고급 대화)", rating: 4 },
  { id: "In", name: "Intermediate - 중급(일상 대화)", rating: 3 },
  { id: "El", name: "Elementary - 초급(짧은 대화)", rating: 2 },
  { id: "Be", name: "Beginner - 기초(단어 수준)", rating: 1 },
];
let selectedSubLang = {};

export default function GuideRegistBasicInfoLanguage(props) {
  const { guideData, loginInfo } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const $controller = {
    gl_lang_main: useRef(),
  };

  useEffect(() => {
    let codeLanguage = codeList.codeLanguage;
    let setLanguage = [];
    if (codeLanguage) {
      codeLanguage = [...codeLanguage];
      codeLanguage.forEach((lang) => {
        let language = lang[1];
        setLanguage.push({
          id: language.id,
          name: language.name,
          name_ko: language.name_ko,
          level: "",
          glg_use_yn: "N",
        });
      });
    }
    langs = setLanguage;
    subLangs = setLanguage;
    requestGuideSubLanguage((newData) => {
      let dataList = newData["returnData"];

      for (let i = 0; i < dataList.length; i++) {
        let item = dataList[i];
        let getIndex = subLangs.findIndex(
          (lang) => lang.id === item.glg_lang_type
        );
        subLangs[getIndex] = {
          ...subLangs[getIndex],
          level: item.glg_lang_rating ? item.glg_lang_rating : 5,
          glg_use_yn: item.glg_use_yn,
        };
        selectedSubLang[item.glg_lang_type] = subLangs[getIndex];
      }
    });
  }, []);

  // 가이드 서브언어 목록
  const requestGuideSubLanguage = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_sub_language_select.php",
      msgID: msgID,
      glg_guide_uid: loginInfo.u_uid,
      glg_use_yn: "Y",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 서브 언어 업데이트
  const updateGuideSubLanguage = (updateData, callback) => {
    if (guideData.gl_confirm_admin === "S") {
      codeList.Modal.current.alert("검토중에는 정보 변경이 불가합니다.");
      return;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_sub_language_update.php",
      msgID: msgID,
      glg_guide_uid: loginInfo.u_uid,
      ...updateData,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  //메인 언어 변경
  const changedMainLang = () => {
    let data = {};
    for (let key in $controller) {
      data[key] = $controller[key].current.value;
    }
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      data,
      () => {}
    );
  };

  // 서브언어 추가
  const addSubLang = (data) => {
    if (selectedSubLang.hasOwnProperty(data.id)) return;
    selectedSubLang[data.id] = { ...data, level: 5, glg_use_yn: "Y" };
    updateGuideSubLanguage(
      {
        glg_lang_rating: 5,
        glg_lang_type: data.id,
        glg_use_yn: "Y",
      },
      () => {
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
  };

  // 서브언어 삭제
  const removeSubLang = (data) => {
    updateGuideSubLanguage(
      {
        glg_lang_rating: 0,
        glg_lang_type: data.id,
        glg_use_yn: "N",
      },
      () => {
        delete selectedSubLang[data.id];
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
  };

  // 서브언어 레벨 변경
  const changedSubLangLevel = (e, data) => {
    updateGuideSubLanguage(
      {
        glg_lang_rating: e.target.value,
        glg_lang_type: data.id,
        glg_use_yn: "Y",
      },
      () => {
        selectedSubLang[data.id].level = e.target.value;
        setIsNeedUpdate(!isNeedUpdate);
      }
    );
  };

  return (
    <div className="col-xl-6">
      <GuideRegistBasicInfoLanguageModal addSubLang={addSubLang} />
      <div className="mb-3">
        <h4 className="mb-1">1. 가장 편하게 구사하는 언어를 알려주세요.</h4>
        <p className="mb-2">
          이 언어로 읽고, 쓰고, 말할 수 있어야 합니다. 더 많은 언어를 다음에
          추가하실 수 있어요.
        </p>
        <select
          className="form-control mt-2"
          ref={$controller.gl_lang_main}
          defaultValue={guideData.gl_lang_main}
          disabled={guideData.gl_confirm_admin === "S"}
          onChange={(e) => {
            changedMainLang();
          }}
        >
          <option value="">언어를 선택해주세요</option>
          {langs !== null &&
            langs.map((lang) => {
              return (
                <option key={lang.id} value={lang.id}>
                  {lang.name_ko}
                </option>
              );
            })}
        </select>
      </div>
      <div className="mb-5">
        <h4 className="mb-1">
          2. 유창하게 구사할 수 있는 다른 언어를 알려주세요. (선택사항)
        </h4>
        <p className="mb-2">편안하게 가이드할 수 있는 언어만 추가하세요.</p>
        <div>
          {Object.values(selectedSubLang).length > 0 &&
            Object.values(selectedSubLang).map((lang) => {
              return (
                <div
                  className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center border-bottom"
                  key={lang.id}
                >
                  <div
                    className="p-1 p-md-3 font-weight-bold"
                    style={{ minWidth: 200 }}
                  >
                    {lang.name} ({lang.name_ko})
                  </div>
                  <div className="flex-fill p-1 p-md-3">
                    <select
                      className="form-control"
                      disabled={guideData.gl_confirm_admin === "S"}
                      defaultValue={lang.level}
                      onChange={(e) => {
                        changedSubLangLevel(e, lang);
                      }}
                    >
                      {lang_level.map((level) => {
                        return (
                          <option key={level.id} value={level.rating}>
                            {level.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="p-1 p-md-3 text-right">
                    <span
                      className="cursor_pointer"
                      onClick={() => {
                        if (guideData.gl_confirm_admin === "S") return;
                        removeSubLang(lang);
                      }}
                    >
                      <i className="far fa-trash-alt" />
                    </span>
                  </div>
                </div>
              );
            })}
          <button
            className="w-100 border rounded p-3 mt-3 bg-light cursor_pointer"
            data-toggle="modal"
            data-target="#guideRegist_language"
            disabled={guideData.gl_confirm_admin === "S"}
          >
            <i className="fas fa-plus-circle mr-3" />
            언어 추가
          </button>
        </div>
      </div>
      <div className="text-secondary">
        <h6 className="mb-1">※ 팁</h6>
        <p>
          <i className="far fa-hand-point-right mr-2" />
          대표로 설정하신 언어로 읽고, 쓰고, 말할 수 있어야 합니다.
        </p>
        <p>
          <i className="far fa-hand-point-right mr-2" />
          설정하신 언어 외에 구사하시는 언어가 있다면 향후 언제든 체험 페이지에
          추가하실 수 있습니다.
        </p>
      </div>
    </div>
  );
}

function GuideRegistBasicInfoLanguageModal(props) {
  const { addSubLang } = props;
  const [searchText, setSearchText] = useState("");
  return (
    <div
      className="modal fade"
      id="guideRegist_language"
      tabIndex="-1"
      aria-labelledby="guideRegist_language"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered my-0">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="guideRegist_language_title">
              사용 언어 추가
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            className="modal-body d-flex flex-column"
            style={{ height: 300 }}
          >
            <div className="mb-3">
              <input
                className="form-control"
                type="text"
                placeholder="검색어를 입력해주세요"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
              />
            </div>
            <div className="flex-fill overflow_scroll_y">
              {subLangs !== null &&
                subLangs.map((lang) => {
                  if (
                    lang.name_ko.indexOf(searchText) > -1 ||
                    lang.name.indexOf(searchText) > -1
                  ) {
                    return (
                      <div
                        key={lang.id}
                        className={`border-bottom cursor_pointer p-3 ${
                          selectedSubLang.hasOwnProperty(lang.id) &&
                          selectedSubLang[lang.id].glg_use_yn === "Y"
                            ? "bg-light"
                            : ""
                        }`}
                        onClick={() => {
                          addSubLang(lang);
                        }}
                      >
                        {lang.name} ({lang.name_ko})
                      </div>
                    );
                  }
                })}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              닫기
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
            >
              추가
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
