import React from "react";

import Utilities from "../../../lib/Utilities";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const util = new Utilities();

const STATUS_TYPE = {
  CR: "요청",
  GA: "승인",
  CC: "취소",
  GC: "거절",
};

export default function ChatRoomServiceInfo(props) {
  const { item, setOpenServiceInfo } = props;
  const { t } = useTranslation();

  const picUrl = util.changedResourceUrl(item.rl_pic_main);
  const status_text_color =
    item.rrl_status === "CR"
      ? "text-secondary"
      : item.rrl_status === "GA"
      ? "text-info"
      : "text-danger";

  return (
    <div
      className="p-3 chat-list-end overflow_scroll_y"
      style={{ minWidth: 320 }}
    >
      <div className="pb-3">
        <div className="d-flex flex-row align-items-center justify-content-between mb-3">
          <p className="text-secondary font-weight-bold h6 mb-2">서비스 정보</p>
          <button
            className="text-secondary square-30 bg-transparent child-center cursor_pointer_bg rounded-circle"
            onClick={() => setOpenServiceInfo(false)}
          >
            <i className="fa-solid fa-xmark" />
          </button>
        </div>
        <div className="tour_container shadow-none overflow-hidden mb-0">
          <div className="img_container_2 position-relative rounded-xl overflow-hidden">
            <div
              className="w-100"
              style={{
                height: 300,
                background: `url(${picUrl}) 50% 50%/cover no-repeat`,
              }}
            />
            <div className="short_info">
              <i className="icon_set_1_icon-44" />
              {item.rl_area_cd}
              <span className="price">
                <sup>&#8361;</sup> {util.addCommas(item.rl_price_base)}
              </span>
            </div>
          </div>

          <div className="tour_title p-1 mt-2">
            <div className="row m-0 p-0">
              <div className="col-8 m-0 p-0">
                <h3 className="font-weight-bold ellipsis_1">{item.rl_title}</h3>
              </div>
              <div className="col-4 m-0 p-0 text-right">
                <i className="icon-star voted text-warning" /> {item.rl_rating}
              </div>
            </div>
            <span>{item.rl_name ? item.rl_name : "-"}</span>
            <div className="rating">
              <i
                className={
                  item.rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <small>({item.rl_rating_cnt})</small>
            </div>
          </div>
          <div className="tour_desc p-1">
            <p className="ellipsis_2">{item.rl_desc}</p>
            <p className="font-weight-bold">
              {t("base")} {item.rl_guest_min ? item.rl_guest_min : "1"}{" "}
              {t("person")} &#8361;
              {util.addCommas(item.rl_price_base)} / {t("addedPerson")} &#8361;
              {util.addCommas(item.rl_over_pay_people)}
            </p>
          </div>
        </div>
        <div className="mt-2">
          <Link
            to={`/TourDetail/${item.rl_idx}`}
            className="font-weight-bold text-secondary"
          >
            자세히보기
            <i className="fa-solid fa-arrow-right ml-2" />
          </Link>
        </div>
      </div>
      <div className="py-3 border-top">
        <p className="text-secondary font-weight-bold h6 mb-2">예약정보</p>
        {item.crm_status === "CI" && (
          <div className="bg-light border rounded p-3 text-center">
            서비스에 대한 문의가 도착했습니다
          </div>
        )}
        {item.crm_status !== "CI" && (
          <div>
            <div className="border rounded-lg text-center mb-3">
              <div className="border-bottom p-3 font-weight-bold bg-light">
                예약상태
              </div>
              <div className={`p-3 ${status_text_color} font-weight-bold`}>
                {STATUS_TYPE[item.rrl_status]}
              </div>
            </div>

            <div className="row m-0 p-0 mb-2">
              <div className="col m-0 p-0 text-secondary">서비스 시작일시</div>
              <div className="col m-0 p-0 text-right">
                <p>{item.rrl_start_date}</p>
              </div>
            </div>
            <div className="row m-0 p-0 mb-2">
              <div className="col m-0 p-0 text-secondary">서비스 종료일시</div>
              <div className="col m-0 p-0 text-right">
                <p>{item.rrl_end_date}</p>
              </div>
            </div>
            <div className="row m-0 p-0 mb-2">
              <div className="col m-0 p-0 text-secondary">성인</div>
              <div className="col m-0 p-0 text-right">
                <p>{item.rrl_guest_adult_num} 인</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 ${
                item.rrl_guest_child_num !== "0" ? "d-flex" : "d-none"
              }`}
            >
              <div className="col m-0 p-0 text-secondary">어린이</div>
              <div className="col m-0 p-0 text-right">
                <p>{item.rrl_guest_child_num} 인</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 ${
                item.rrl_guest_baby_num !== "0" ? "d-flex" : "d-none"
              }`}
            >
              <div className="col m-0 p-0 text-secondary">유아</div>
              <div className="col m-0 p-0 text-right">
                <p>{item.rrl_guest_baby_num} 인</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
