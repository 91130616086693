import React from "react";
import { useTranslation } from "react-i18next";

export default function GuideRegistIntroBanner({ checkLoginData }) {
  const { t } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 nopadding features-intro-img">
          <div className="features-bg">
            <div
              className="features-img"
              style={{
                background: `url(/images/man-2425121_1280.jpg) 50% 50%/cover no-repeat`,
                boxShadow: " 0px -50px 62px 44px rgba(0,0,0,0.2) inset",
              }}
            />
          </div>
        </div>
        <div className="col-lg-6 nopadding">
          <div className="features-content">
            <p className="h3 mb-2">{t("guideRegistIntroBannerTitle")}</p>
            <p className="mb-3">
              {t("guideRegistIntroBannerDesc1")}
              <br />
              {t("guideRegistIntroBannerDesc2")}
            </p>
            <p>
              <button onClick={checkLoginData} className=" btn_1 white">
                {t("guideRegistIntroCarouselTitle")}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
