import React, { Component } from "react";
import { CommonConstList } from "./CodeList";
import "./stylesheet/Modal.css";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: CommonConstList.Modal.TYPE_INPUT,
      title: "제목",
      message: "로그아웃 하시겠습니까?",
      placeholder: "Input your message here",
      validation: true,
      show: false,
    };
    this.strInput = "";
    this.callback = null;
    this.inputBox = React.createRef();
    this.ButtonType = {
      TYPE_OK: "TYPE_OK",
      TYPE_CANCEL: "TYPE_CANCEL",
    };
  }

  buttonClicked = (e) => {
    let btnType = e.target.dataset.btntype;

    switch (btnType) {
      case this.ButtonType.TYPE_OK:
        //입력값이 필요한 경우
        if (this.state.modalType === CommonConstList.Modal.TYPE_INPUT) {
          if (this.strInput.trim() === "") {
            this.setState({ validation: false });
            return;
          } else {
            if (this.callback) {
              this.callback(true, this.strInput);
            }
          }
        } else {
          if (this.callback) {
            this.callback(true);
          }
        }
        break;
      case this.ButtonType.TYPE_CANCEL:
        if (this.callback) {
          this.callback(false);
        }
        break;
    }
    this.setState({
      show: false,
      validation: true,
    });
  };

  openModal = (type, title, message, placeholder = "", callback) => {
    this.setState({
      title: title,
      message: message,
      placeholder: placeholder,
      modalType: type,
      show: true,
    });
    this.callback = callback;
    if (this.inputBox.current) this.inputBox.current.value = "";
  };

  alert = (message, callback) => {
    this.setState({
      title: "알림",
      message: message,
      placeholder: "",
      modalType: CommonConstList.Modal.TYPE_ALERT,
      show: true,
    });
    this.callback = callback;
    if (this.inputBox.current) this.inputBox.current.value = "";
  };

  confirm = (message, callback) => {
    this.setState({
      title: "확인",
      message: message,
      placeholder: "",
      modalType: CommonConstList.Modal.TYPE_CONFIRM,
      show: true,
    });
    this.callback = callback;
    if (this.inputBox.current) this.inputBox.current.value = "";
  };

  closeModal = () => {
    this.setState({
      show: false,
    });
  };

  strInputChanged = (e) => {
    this.setState({ show: true });
    this.strInput = e.target.value.trim();
  };

  inputRender = () => {
    return (
      <>
        <div className="commonModalInputTextWrap">
          <input
            className="commonModalInput"
            type={"text"}
            placeholder={this.state.placeholder}
            maxLength={50}
            onChange={this.strInputChanged.bind(this)}
            ref={this.inputBox}
          />
          <p className="commonModalInputText">
            {!this.state.validation && "내용을 입력해 주세요."}
          </p>
        </div>
      </>
    );
  };

  render() {
    return (
      <>
        <div
          className="commonModal default_modal_animation"
          style={{
            display: this.state.show ? "" : "none",
          }}
        >
          <div className="commonModalTit">
            <h3>{this.state.title}</h3>
            <i
              className="fas fa-times"
              onClick={this.buttonClicked.bind(this)}
              data-btntype={this.ButtonType.TYPE_CANCEL}
            />
          </div>
          <div className="commonModalText">
            <p>{this.state.message}</p>
          </div>

          {this.state.modalType === CommonConstList.Modal.TYPE_INPUT &&
            this.inputRender()}
          <div className="commonModalBtnWrap">
            <button
              className="default_button_Point"
              onClick={this.buttonClicked.bind(this)}
              data-btntype={this.ButtonType.TYPE_OK}
            >
              확인
            </button>
            {this.state.modalType !== CommonConstList.Modal.TYPE_ALERT && (
              <button
                className="default_button commonModalBtnCancel"
                onClick={this.buttonClicked.bind(this)}
                data-btntype={this.ButtonType.TYPE_CANCEL}
              >
                취소
              </button>
            )}
          </div>
        </div>
        <div
          className="commonModal_layer"
          style={{
            display: this.state.show ? "" : "none",
            zIndex: 1060,
          }}
        />
      </>
    );
  }
}

export default Modal;
/*

const Modal = ({ cb }) => {
  let [title, setTitle] = useState("제목");
  let [message, setMessage] = useState("로그아웃 하시겠습니까?");
  let [placeholder, setPlaceholder] = useState("Input your message here");
  let [validation, setValidation] = useState(true);
  let [strInput, setStrInput] = useState("");
  let [show, setShow] = useState(false);
  let [modalType, setModalType] = useState(CommonConstList.Modal.TYPE_INPUT);

  let callback = cb;

  let socket = new BuddibleSocket();
  const ButtonType = {
    TYPE_OK: "TYPE_OK",
    TYPE_CANCEL: "TYPE_CANCEL",
  };

  useEffect(() => {
    socket.waitingForConnect("InitCodeList", () => {});
  }, []);

  useEffect(() => {
    console.log("strInput changed", strInput);
  }, [strInput]);

  const buttonClicked = (e) => {
    let btnType = e.target.dataset.btnType;

    switch (btnType) {
      case ButtonType.TYPE_OK:
        //입력값이 필요한 경우

        if (modalType === CommonConstList.Modal.TYPE_INPUT) {
          if (callback) {
            callback(true, strInput);
          }
        } else {
          callback(true);
        }

        break;
      case ButtonType.TYPE_CANCEL:
        if (callback) {
          callback(false);
        }
        break;
    }
  };
  const openModal = () => {
    setShow(true);
    console.log("openModal");
  };
  const strInputChanged = (e) => {
    setStrInput(e.target.value);
  };

  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          background: "gray",
          display: show ? "" : "none",
        }}
      >
        <div style={{ width: "50vw", height: "50vh", background: "white" }}>
          <h4>{title}</h4>
          <p>{message}</p>
          {modalType === CommonConstList.Modal.TYPE_INPUT && (
            <>
              <input
                type={"text"}
                placeholder={placeholder}
                maxLength={50}
                onChange={strInputChanged.bind(this)}
              />
              <p style={{ color: "red" }}>
                {!validation && "내용을 입력해 주세요."}
              </p>
            </>
          )}
          <button
            onClick={buttonClicked.bind(this)}
            data-btnType={ButtonType.TYPE_OK}
          >
            확인
          </button>
          <button
            onClick={buttonClicked.bind(this)}
            data-btnType={ButtonType.TYPE_CANCEL}
          >
            취소
          </button>
        </div>
      </div>
    </>
  );
};

export default Modal;
*/
