import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuideRegistProfileDefaultProvided";

const serve_drive = [
  { key: "A", desc: "차량(자동차, ATV, 전동 스쿠터, 스노모빌 등) 운전" },
  {
    key: "B",
    desc: "보트 타기(전동 보트, 요트, 수상스키, 패러세일링, 배로 끄는 튜브 등)",
  },
  { key: "C", desc: "오토바이" },
  { key: "D", desc: "비행편(비행기, 헬리콥터, 열기구 등)" },
  { key: "E", desc: "여행에 이러한 사항이 포함되지 않습니다." },
];
const serve_driver = [
  { key: "G", desc: "가이드가 직접 운전합니다." },
  { key: "H", desc: "공동 가이드가 운전합니다." },
  { key: "I", desc: "오토바이" },
  {
    key: "J",
    desc: "저와 가이드 팀에서 게스트가 운행할 차량을 제공할 것입니다.",
  },
  {
    key: "K",
    desc: "게스트에게 대중교통이나 허가받은 제3자 운행사를 이동편으로 제공합니다.",
  },
];
let provided_data = {
  gl_serve_method: [], //차량제공사항 A|B|C|D|E
  gl_serve_driver: [], //운전자 A|B|C|D|E
  gl_serve_memo: [], //추가제공사항 담요|생수|차량
};

export default function GuideRegistProfileDefaultProvided(props) {
  const { guideData } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  let $controller = {
    serveMemo: useRef(),
  };

  useEffect(() => {
    if (guideData.gl_guide_uid) {
      provided_data = {
        gl_serve_method: guideData.gl_serve_method
          ? guideData.gl_serve_method
          : [], //차량제공사항 A|B|C|D|E
        gl_serve_driver: guideData.gl_serve_driver
          ? guideData.gl_serve_driver
          : [], //운전자 A|B|C|D|E
        gl_serve_memo: guideData.gl_serve_memo ? guideData.gl_serve_memo : [], //추가제공사항 담요|생수|차량
      };
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  //기본제공사항 데이터 핸들러
  const handleProvidedDataChange = (key, checked, value) => {
    if (checked) {
      if (value !== "E") {
        let dataList = [...provided_data[key], value];
        let setDataList = [];
        for (let i = 0; i < dataList.length; i++) {
          if (dataList[i] !== "E") {
            setDataList.push(dataList[i]);
          }
        }
        provided_data = {
          ...provided_data,
          [key]: setDataList,
        };
      } else {
        provided_data = {
          ...provided_data,
          [key]: [value],
        };
      }
    } else {
      let removeIndex = provided_data[key].findIndex((item) => item === value);
      provided_data[key].splice(removeIndex, 1);
    }
    setIsNeedUpdate(!isNeedUpdate);
    guideDataChange();
  };

  //추가제공사항 추가
  const handleAddServeMemo = () => {
    let value = $controller.serveMemo.current.value;
    if (!value) return;
    provided_data = {
      ...provided_data,
      gl_serve_memo: [...provided_data.gl_serve_memo, value],
    };
    $controller.serveMemo.current.value = "";
    setIsNeedUpdate(!isNeedUpdate);
    guideDataChange();
  };

  //추가 제공사항 삭제
  const handleRemoveServeMemo = (item, index) => {
    provided_data.gl_serve_memo.splice(index, 1);
    setIsNeedUpdate(!isNeedUpdate);
    guideDataChange();
  };

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      provided_data,
      () => {}
    );
  };

  return (
    <div className="col-xl-6">
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold">가이드 중 다음 사항이 포함되나요?</h6>
        <div className="py-3">
          {serve_drive.map((drive) => {
            return (
              <div className="py-2" key={`serve_drive_${drive.key}`}>
                <label
                  className="d-flex flex-row align-items-start"
                  htmlFor={`serve_drive_${drive.key}`}
                >
                  <input
                    id={`serve_drive_${drive.key}`}
                    className="mr-2 mt-1"
                    type="checkbox"
                    disabled={guideData.gl_confirm_admin === "S"}
                    onChange={(e) => {
                      handleProvidedDataChange(
                        "gl_serve_method",
                        e.target.checked,
                        drive.key
                      );
                    }}
                    checked={
                      provided_data.gl_serve_method.findIndex(
                        (serve) => serve === drive.key
                      ) > -1
                    }
                  />
                  <p>{drive.desc}</p>
                </label>
              </div>
            );
          })}
        </div>

        <div className="border rounded p-3 d-flex flex-row align-items-start">
          <div className="h2 mr-3">
            <i className="fas fa-exclamation-circle text-danger" />
          </div>
          <p>
            소지하신 운전면허와 자동차 보험이 현지 법적 요건에 부합하지 않는
            경우, 게스트를 태우고 직접 운전을 해서는 안 됩니다.{" "}
            <strong>플레이 영종 가이드 보호 보험</strong>의 적용 범위에 대해
            자세히 알아보세요.
          </p>
        </div>
      </div>

      {provided_data.gl_serve_method.length > 0 &&
        provided_data.gl_serve_method.findIndex((item) => item === "E") ===
          -1 && (
          <div className="py-3 border-bottom">
            <h6 className="font-weight-bold">
              차량 운행 시 운전자는 누구인가요?
            </h6>
            <div className="py-3">
              {serve_driver.map((driver) => {
                return (
                  <div className="py-2" key={`serve_driver_${driver.key}`}>
                    <label
                      className="d-flex flex-row align-items-start"
                      htmlFor={`serve_driver_${driver.key}`}
                    >
                      <input
                        id={`serve_driver_${driver.key}`}
                        className="mr-2 mt-1"
                        type="checkbox"
                        value={driver.key}
                        disabled={guideData.gl_confirm_admin === "S"}
                        onChange={(e) => {
                          handleProvidedDataChange(
                            "gl_serve_driver",
                            e.target.checked,
                            driver.key
                          );
                        }}
                        checked={
                          provided_data.gl_serve_driver.findIndex(
                            (serve) => serve === driver.key
                          ) > -1
                        }
                      />
                      <p>{driver.desc}</p>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold">
          추가로 제공할 사항이 있다면 입력해주세요. (선택사항)
        </h6>
        <div className="py-3">
          <div className="d-flex flex-row justify-content-start">
            <div className="flex-fill mr-2">
              <input
                className="form-control"
                type="text"
                placeholder="추가제공사항을 입력해주세요"
                ref={$controller.serveMemo}
                disabled={guideData.gl_confirm_admin === "S"}
                onKeyPress={(e) => {
                  if (e.charCode === 13) {
                    handleAddServeMemo();
                  }
                }}
              />
            </div>
            <button
              className="btn_1"
              disabled={guideData.gl_confirm_admin === "S"}
              onClick={(e) => {
                handleAddServeMemo();
              }}
            >
              추가
            </button>
          </div>
          {provided_data.gl_serve_memo.length > 0 && (
            <div className="d-flex flex-row justify-content-start flex-wrap">
              {provided_data.gl_serve_memo.map((item, index) => {
                return (
                  <div
                    key={`serveMemo_` + index}
                    className="px-3 py-2 mt-2 mr-2 bg-light rounded cursor_pointer"
                    onClick={() => {
                      if (guideData.gl_confirm_admin === "S") return;
                      handleRemoveServeMemo(item, index);
                    }}
                  >
                    {item}
                    &times;
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="py-3">
        <h6 className="font-weight-bold mb-2">참고사항</h6>
        <p>
          교통수단이나 장비를 외부 업체를 통해 제공하신다면 게스트가 미리 파악할
          수 있도록 그 업체의 이름이나 기타 정보를 입력해주세요. 운송업체와 장비
          대여업체에만 적용되는 사항이며 식음료 제공업체는 입력하실 필요가
          없습니다(입력하셔도 무방합니다).
        </p>
      </div>
    </div>
  );
}
