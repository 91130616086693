import React, { Component } from "react";
import Nav from "../common/Nav";
import Carousel from "./component/Carousel";
import TravelKind from "./component/TravelKind";
import BestTour from "./component/BestTour";
import RegistGuideInfo from "./component/RegistGuideInfo";
import News from "./component/News";
import Footer from "../common/Footer";
import ExperienceService from "./component/ExperienceService";

export default function Main(props) {
  return (
    <>
      <Nav />
      <main>
        <Carousel />
        <TravelKind />
        <ExperienceService />
        <BestTour />
        <RegistGuideInfo />
        <News />
      </main>
      <Footer />
    </>
  );
}
