import React, { useCallback, useEffect } from "react";

import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const RESERVE_STATUS = [
  { key: "All", title: "전체", cnt: 0 },
  { key: "CR", title: "요청", cnt: 0 },
  { key: "GA", title: "수락", cnt: 0 },
  { key: "CC", title: "취소", cnt: 0 },
  { key: "GC", title: "거절", cnt: 0 },
];

let selectResereStatus = "All";

export default function GuideAccountManage(props) {
  const {
    loginInfo,
    guideInfo,
    dashboardReserveCnt,
    dashboardReserveList,
    dashboardReferenceList,
    changedReservationStatus,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      selectResereStatus = "All";
    };
  }, []);

  const history = useHistory();

  const openGuideDetail = () => {
    if (guideInfo.gl_confirm_admin === "Y") {
      window.open(`/GuideDetail/${guideInfo.gl_idx}`, "_blank");
      return;
    }
    codeList.Modal.current.alert(
      "비승인 상태의 버디 상세페이지는 관리자와 해당 버디에게만 보여집니다.",
      () => {
        window.open(`/GuideDetail/${guideInfo.gl_idx}`, "_blank");
      }
    );
  };

  // 가이드 프로필 변경 이동
  const moveToGuideRegist = () => {
    codeList.Modal.current.confirm(
      "가이드 프로필 수정중에는 서비스 노출이 불가합니다. 수정하시겠습니끼?",
      (ret) => {
        if (ret) {
          history.push("/GuideRegist");
        }
      }
    );
  };

  const ReserveItemRenderer = useCallback((props) => {
    return <ReserveItem {...props} />;
  }, []);

  const ReferItemRenderer = useCallback((props) => {
    return <ReferItem {...props} />;
  }, []);

  let confirmClass = "badge-secondary";
  let confirm = "작성중";
  if (guideInfo) {
    if (guideInfo.gl_confirm_admin === "W") {
      confirmClass = "badge-secondary";
      confirm = t("confirmStatusWrite");
    }
    if (guideInfo.gl_confirm_admin === "S") {
      confirmClass = "badge-primary";
      confirm = t("confirmStatusSubmit");
    }
    if (guideInfo.gl_confirm_admin === "Y") {
      confirmClass = "badge-success";
      confirm = t("confirmStatusAgree");
    }
    if (guideInfo.gl_confirm_admin === "N") {
      confirmClass = "badge-danger";
      confirm = t("confirmStatusReject");
    }
    if (guideInfo.gl_confirm_admin === "C") {
      confirmClass = "badge-warning";
      confirm = t("confirmStatusCancel");
    }
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex flex-column flex-md-row justify-content-md-between mb-3 px-2">
        <div className="d-flex flex-row mb-3">
          <img
            style={{ width: 50, height: 50, objectFit: "cover" }}
            className="rounded-circle mr-2"
            src={util.changedResourceUrl(guideInfo.gl_pic)}
            alt="가이드 프로필"
          />
          <div>
            <span className={`badge badge-pill ${confirmClass}`}>
              {confirm}
            </span>
            <h3 className="font-weight-bold">
              {guideInfo.gl_name}님, 안녕하세요!
            </h3>
          </div>
        </div>
        <div className="text-right">
          <button
            className="btn py-2 btn-secondary font-size-08 font-weight-bold mr-2"
            style={{ width: 120, height: 34 }}
            onClick={openGuideDetail}
          >
            내 프로필 보기
          </button>
          <button
            className="btn py-2 btn-outline-secondary font-size-08 font-weight-bold"
            style={{ width: 120, height: 34 }}
            onClick={moveToGuideRegist}
          >
            프로필 변경
          </button>
        </div>
      </div>
      <div className="flex-grow-1">
        <div className="mb-5">
          <p className="h5 font-weight-bold px-2">이주의 예약</p>
          <div className="d-flex flex-row flex-wrap px-2">
            {RESERVE_STATUS.map((status, index) => {
              return (
                <span
                  key={status.key}
                  className={`font-size-08 font-weight-bold border rounded-pill cursor_pointer cursor_pointer_bg py-1 px-3 mr-2 mb-2 ${
                    selectResereStatus === status.key
                      ? "bg-buddible text-white border-buuddible"
                      : "text-secondary"
                  } `}
                  onClick={() => {
                    selectResereStatus = status.key;
                    changedReservationStatus(
                      status.key === "All" ? "" : status.key
                    );
                  }}
                >
                  {status.title} ( {dashboardReserveCnt[status.key]}건 )
                </span>
              );
            })}
          </div>
          <div className="rounded-xl bg-light p-2 mx-2">
            {dashboardReserveList.length === 0 && (
              <p className="text-center py-5">
                <i className="fa-regular fa-calendar" /> 주간 예약 내역이
                존재하지 않습니다.
              </p>
            )}
            {dashboardReserveList.length > 0 &&
              dashboardReserveList.map((item, index) => {
                const isLast = index === dashboardReserveList.length - 1;
                return (
                  <ReserveItemRenderer
                    key={item.rrl_idx}
                    item={item}
                    index={index}
                    isLast={isLast}
                  />
                );
              })}
          </div>
        </div>
        <div>
          <div className="d-flex flex-row justify-content-between px-2">
            <p className="h5 font-weight-bold">자료 및 팁</p>
            <Link to="/CommunityMain">
              자료센터 바로가기 <i className="fa-solid fa-arrow-right" />
            </Link>
          </div>

          <div className="row m-0 p-0">
            {dashboardReferenceList.map((item, index) => {
              return (
                <ReferItemRenderer
                  key={item.cm_idx}
                  item={item}
                  index={index}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const ReserveItem = React.memo((props) => {
  const { item, index, isLast } = props;

  const thisStatus = RESERVE_STATUS.find(
    (status) => status.key === item.rrl_status
  );

  let statusColor =
    thisStatus.key === "CR"
      ? "secondary"
      : thisStatus.key === "GA"
      ? "info"
      : "danger";

  return (
    <div className={`bg-white rounded-xl p-2  ${isLast ? "" : "mb-2"}`}>
      <div className="row m-0 p-0">
        <div className="col-12 col-sm-6 col-lg-3 m-0 p-0 pb-2 pb-lg-0">
          <p className="font-weight-bold font-size-08 text-secondary mb-2">
            예약 상태
          </p>
          <div className="text-left">
            <span className={`badge badge-${statusColor} mr-2`}>
              {thisStatus.title}
            </span>
            요청일 : {item.rrl_create_date}
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 m-0 p-0 pb-2 pb-lg-0">
          <p className="font-weight-bold font-size-08 text-secondary mb-1">
            서비스
          </p>
          <div className="d-flex flex-row">
            <img
              width={50}
              height={50}
              src={util.changedResourceUrl(item.rl_pic_main)}
              alt="서비스 메인이미지"
              style={{ objectFit: "cover" }}
              className="mr-3 rounded"
            />
            <div>
              <p className="font-weight-bold">{item.rl_title}</p>
              <Link
                to={`/TourDetail/${item.rl_idx}`}
                target="blank"
                className="btn btn-sm btn-outline-dark font-size-06"
              >
                상세보기{" "}
                <i className="fa-solid fa-arrow-up-right-from-square" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 m-0 p-0 pb-2 pb-lg-0">
          <div>
            <p className="font-weight-bold font-size-08 text-secondary mb-1">
              예약자 정보
            </p>

            <div className="d-flex flex-row align-items-center">
              <img
                width={40}
                height={40}
                src={util.changedResourceUrl(item.u_avata_pic)}
                alt="예약자 이미지"
                style={{ objectFit: "cover" }}
                className="mr-3 rounded-circle"
              />
              <div>
                <p className="font-weight-bold">
                  {item.u_name_full ||
                    item.u_name_first + " " + item.u_name_last}
                </p>
                <button
                  className="btn btn-sm btn_1 py-1 px-2 font-size-06"
                  onClick={() => {
                    codeList.Modal.current.alert("준비중인 서비스입니다.");
                  }}
                >
                  대화하기
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-lg-3 m-0 p-0 pb-2 pb-lg-0">
          <p className="font-weight-bold font-size-08 text-secondary mb-1">
            예약 정보
          </p>
          <p>서비스일 : {item.rrl_start_date}</p>
          <p>
            게스트 : 성인 {item.rrl_guest_adult_num}인
            {item.rrl_guest_child_num !== "0" &&
              `, 청소년 ${item.rrl_guest_child_num}인`}
            {item.rrl_guest_baby_num !== "0" &&
              `, 유아 ${item.rrl_guest_baby_num}인`}
          </p>
        </div>
      </div>
    </div>
  );
});

const ReferItem = React.memo((props) => {
  const { item, index } = props;
  const main_pic = util.changedResourceUrl(item.cm_main_pic);
  return (
    <div className="col-6 col-lg-3 m-0 p-0 px-2 pb-3">
      <Link to={`/CommunityDetail/${item.cm_idx}`} className="text-dark">
        <div className="border rounded-xl overflow-hidden">
          <div
            className="border-bottom"
            style={{
              height: 160,
              background: `url(${main_pic}) 50% 50%/cover no-repeat`,
            }}
          />
          <p className="mb-1 font-weight-bold p-2">{item.cm_title}</p>
        </div>
      </Link>
    </div>
  );
});
