import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let newsList = [];

let page = 0;
let rowsPerPage = 6;

export default function News() {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    requestNewsList();
  }, []);

  const requestNewsList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityListSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      cm_type: "NEWS",
      cm_use_yn: "Y",
      cm_del_yn: "N",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          newsList = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //뉴스콘텐츠 랜더
  const NewsItemRender = useCallback(({ item }) => {
    return (
      <NewsItem
        id={item.cm_idx}
        writer={item.u_name_full || item.u_name_first + " " + item.u_name_last}
        title={item.cm_title}
        contents={item.cm_sub_title}
        updateDate={item.cm_update_date}
        postImg={item.cm_main_pic}
      />
    );
  }, []);

  return (
    <div className="white_bg">
      <div className="container margin_60">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
          <div className="main_title text-center text-sm-left">
            <h2>
              {t("mainNewsTitleStart")} <span>{t("mainNewsTitleCenter")}</span>
            </h2>
            {t("mainNewsTitleEnd")}
            <p>{t("mainNewsDesc")}</p>
          </div>

          <div className="btn_home_align" style={{ minWidth: 110 }}>
            <Link to="/CommunityMain">
              <button className="btn_1 rounded">{t("showMore")}</button>
            </Link>
          </div>
        </div>
        <div className="row">
          {newsList.length > 0 &&
            newsList.map((item, index) => {
              return <NewsItemRender key={item.cm_idx} item={item} />;
            })}
        </div>
      </div>
    </div>
  );
}

const NewsItem = React.memo(
  ({ id, writer, title, contents, updateDate, postImg }) => {
    let postImgUrl = "";

    if (postImg.indexOf("?") > -1) {
      postImgUrl = postImg.split("?")[1];
    } else {
      postImgUrl = codeList.publicVar.imgServerUrl + postImg;
    }

    return (
      <div className="col-md-6 col-xl-4">
        <Link
          className="box_news shadow-sm rounded mb-3"
          to={`/CommunityDetail/${id}`}
        >
          <figure className="mb-0">
            <img
              src={postImgUrl}
              alt={postImgUrl}
              className="w-100 h-100"
              style={{ objectFit: "cover" }}
            />
          </figure>
          <div className="min-height-150 p-2 d-flex flex-column justify-content-between">
            <div>
              <ul>
                <li>{writer}</li>
                {/*<li>{util.getDateToFormat(updateDate, "YYYY-MM-DD")}</li>*/}
              </ul>
              <h4>{title}</h4>
              <p>{contents}</p>
            </div>
            <p className="text-right">
              {util.getDateToFormat(updateDate, "YYYY-MM-DD")}
            </p>
          </div>
        </Link>
      </div>
    );
  }
);
