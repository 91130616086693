import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();

export default function GuideDetailReview(props) {
  const { guideData, reviewData } = props;
  const { t } = useTranslation();
  const [isModalView, setIsModalView] = useState(false);
  const handleModalView = () => {
    setIsModalView(!isModalView);
  };

  const createRatings = (rating) => {
    let r = Number(rating);
    let ratings = [];
    for (let i = 1; i < 6; i++) {
      ratings.push(
        <i
          key={i}
          className={`${i <= r ? "icon-star voted" : "icon-star-empty"} ml-0`}
        />
      );
    }
    return ratings;
  };

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex flex-row justify-content-between">
          <p className="h5 font-weight-bold">
            <i className="icon-star voted text-warning" />{" "}
            {guideData.gl_ur_rating || 0} · {t("review")}{" "}
            {guideData.gl_ur_rating_cnt} {t("count")}
          </p>
        </div>
      </div>
      <div className="row px-3">
        {reviewData.length > 0 &&
          reviewData.map((item, index) => {
            return (
              <ReviewItem
                key={item.ur_idx}
                item={item}
                createRatings={createRatings}
                isDetail={true}
              />
            );
          })}
      </div>
      <div className="row mt-2">
        <div className="col">
          <div
            className="btn_1 text-secondary bg-light border border-secondary"
            onClick={() => handleModalView()}
          >
            {t("review")} {guideData.gl_rating_cnt} {t("count")} {t("showAll")}
          </div>
        </div>
      </div>
      <GuideDetailReviewModal
        guideData={guideData}
        isModalView={isModalView}
        handleModalView={handleModalView}
        reviewData={reviewData}
        createRatings={createRatings}
      />
    </>
  );
}

function GuideDetailReviewModal(props) {
  let { guideData, isModalView, handleModalView, reviewData, createRatings } =
    props;
  return (
    <div className={`modal_background ${isModalView ? "d-block" : "d-none"}`}>
      <div className="modal_body bg-white rounded d-flex flex-column">
        <div className="d-flex flex-row justify-content-between p-3 border-bottom">
          <p className="h5 font-weight-bold">
            후기 {guideData.gl_rating_cnt}개 모두 보기
          </p>
          <div className="cursor_pointer" onClick={() => handleModalView()}>
            &times;
          </div>
        </div>

        <div className="p-3 flex-fill overflow_scroll_y">
          {reviewData.length > 0 &&
            reviewData.map((item, index) => {
              return (
                <ReviewItem
                  key={item.ur_idx}
                  item={item}
                  createRatings={createRatings}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

const ReviewItem = React.memo(({ item, createRatings, isDetail = false }) => {
  const isShowImages = item.ur_pics !== "";
  let imgs = isShowImages ? item.ur_pics.split("|") : [];

  return (
    <div
      className={`${isDetail ? "col-12 col-lg-6" : ""} px-2 py-3`}
      key={item.ur_idx}
    >
      <div className="d-flex flex-row justify-content-start mb-2 w-100">
        <div className="mr-3">
          <img
            src={codeList.publicVar.imgServerUrl + item.ur_user_pic}
            alt="Image"
            className="rounded-circle border"
            style={{ width: 50, height: 50 }}
          />
        </div>
        <div className="flex-fill">
          <p className="h6 mb-0">{item.ur_user_name}</p>
          <p className="text-secondary">{item.ur_update_date}</p>
          <div className="d-flex flex-row align-items-baseline mb-2">
            {isShowImages && (
              <i className="fa-regular fa-images text-secondary mr-1" />
            )}
            <div className="text-warning">{createRatings(item.ur_rating)}</div>
          </div>
          {isShowImages && !isDetail && (
            <div className="row m-0 p-0">
              {imgs.map((item, index) => {
                return (
                  <div key={index} className="col-3 m-0 p-0">
                    <div
                      className="mr-1 mb-1"
                      style={{
                        height: 100,
                        background: `url(${
                          codeList.publicVar.imgServerUrl + item
                        }) 50% 50% /contain no-repeat`,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <p className={isDetail ? "ellipsis_2" : ""}>{item.ur_review}</p>
        </div>
      </div>
    </div>
  );
});
