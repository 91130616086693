import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

let guestMemo = "";

export default function ReserveServiceMemo(props) {
  const { handleRequestData } = props;
  const [isModalShow, setIsModalShow] = useState(false);
  const { t } = useTranslation();

  const handleGuestMessage = (msg) => {
    handleRequestData("rrl_memo_guest", msg);
    setIsModalShow(false);
  };

  return (
    <>
      <div className="border-bottom py-3">
        <p className="h4 mb-3">{t("requiredInformation")}</p>
        <div className="py-2 d-flex flex-row justify-content-between align-content-center">
          <div>
            <p className="font-weight-bold h5">{t("sendToMsgForBuddyNote")}</p>
            <p>{t("sendToMsgForBuddyNoteDesc")}</p>
            <p className={guestMemo ? "d-block" : "d-none"}>{guestMemo} </p>
          </div>

          <div style={{ minWidth: 70 }}>
            <span
              className="btn_1 bg-buddible"
              onClick={() => setIsModalShow(true)}
            >
              {guestMemo ? t("edit") : t("registration")}
            </span>
          </div>
        </div>
      </div>
      <ReserveServiceMemoModal
        isModalShow={isModalShow}
        setIsModalShow={setIsModalShow}
        handleGuestMessage={handleGuestMessage}
      />
    </>
  );
}

//메세지 입력 모달
function ReserveServiceMemoModal(props) {
  const { isModalShow, setIsModalShow, handleGuestMessage } = props;
  const { t } = useTranslation();
  const msg = useRef();
  return (
    <div className={`modal_background ${isModalShow ? "d-block" : "d-none"}`}>
      <div className="modal_body rounded d-flex flex-column">
        <div className="d-flex flex-row justify-content-between border-bottom p-3">
          <div>
            <p className="h5">{t("sendToMsgForBuddyNote")}</p>
            <p className="text-secondary">{t("sendToMsgForBuddyNoteDesc")}</p>
          </div>
          <span
            className="cursor_pointer"
            onClick={() => setIsModalShow(false)}
          >
            &times;
          </span>
        </div>
        <div className="px-3 flex-fill my-3">
          <textarea
            ref={msg}
            className="w-100 h-100 border rounded p-3"
            rows={5}
            placeholder={t("writeMsgNote")}
          />
        </div>
        <div className="p-3 d-flex flex-row justify-content-between">
          <div
            className="btn_1 btn_full bg-secondary mr-1"
            onClick={() => {
              guestMemo = "";
              msg.current.value = "";
              handleGuestMessage("");
            }}
          >
            {t("delete")}
          </div>
          <div
            className="btn_1 btn_full bg-buddible ml-1"
            onClick={() => {
              guestMemo = msg.current.value;
              handleGuestMessage(msg.current.value);
            }}
          >
            {t("save")}
          </div>
        </div>
      </div>
    </div>
  );
}
