import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "CommunityList";

let popularList = [];

let totalCount = 100; //게시글 총 수
let totalPageCount = 1; // 조회가능 페이지 수
let rowsPerPage = 10; // 한번에 받아올 데이터 수
let page = 0; //현재 조회 페이지

let filters = {
  SEARCH_WORD: "",
};

let loginInfo = null;

export default function CommunityList({ match, history }) {
  const { t } = useTranslation();
  const cm_type = match.params.hasOwnProperty("cm_type")
    ? match.params.cm_type
    : "";
  const [serverData, setServerData] = useState([]);
  const SEARCH_WORD = useRef();

  const CATEGORY = {
    NOTICE: t("notice"),
    GUIDE: t("guideTip"),
    GUEST: t("guestTip"),
    NEWS: t("news"),
    FORUM: t("communityForum"),
  };

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          loginInfo = n;
        }
      }
    );

    requestCommunityListPopularSelect((newData) => {
      popularList = newData["returnData"];
      requestCommunityList((newData) => {
        totalCount = parseInt(newData["pageSize"]);
        totalPageCount =
          parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
          (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
        setServerData(newData["returnData"]);
      });
    });

    return () => {
      totalCount = 100; //게시글 총 수
      totalPageCount = 1; // 조회가능 페이지 수
      rowsPerPage = 10; // 한번에 받아올 데이터 수
      page = 0; //현재 조회 페이지

      filters = {
        SEARCH_WORD: "",
      };

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, [cm_type]);

  const requestCommunityListPopularSelect = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityListPopularSelect.php",
      msgID: msgID,
      take: rowsPerPage,
      skip: page * rowsPerPage,
      cm_use_yn: "Y",
      cm_del_yn: "N",
      ...filters,
    };

    if (cm_type) socketMsg.cm_type = cm_type;

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 커뮤니티 리스트 받아오기
  const requestCommunityList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityListSelect.php",
      msgID: msgID,
      take: rowsPerPage,
      skip: page * rowsPerPage,
      cm_use_yn: "Y",
      cm_del_yn: "N",
      ...filters,
    };

    if (cm_type) socketMsg.cm_type = cm_type;

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 더보기
  const actionSeeMore = () => {
    page = page + 1;
    requestCommunityList((newData) => {
      setServerData([...data, ...newData["returnData"]]);
    });
  };

  // 검색
  const actionSearch = () => {
    totalCount = 100; //게시글 총 수
    totalPageCount = 1; // 조회가능 페이지 수
    rowsPerPage = 2; // 한번에 받아올 데이터 수
    page = 0; //현재 조회 페이지
    filters = {
      SEARCH_WORD: SEARCH_WORD.current.value,
    };
    requestCommunityList((newData) => {
      totalCount = parseInt(newData["pageSize"]);
      totalPageCount =
        parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
        (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
      setServerData(newData["returnData"]);
    });
  };

  // 글쓰기
  const clickWrite = () => {
    if (loginInfo === null) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }
    history.push("/CommunityWrite");
  };

  const data = useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const ItemRender = useCallback((props) => {
    return <Item {...props} />;
  }, []);

  const PopularItemRender = useCallback((props) => {
    return <PopularItem {...props} />;
  }, []);

  return (
    <>
      <Nav />
      <main
        className="container margin_60 position-relative"
        style={{ marginTop: 80, minHeight: "100vh" }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="row m-0 p-0">
              <div className="col-12 col-lg-8">
                <div className="p-3 border bg-white mb-3">
                  <div className="bg-light rounded d-flex flex-row px-3 py-1">
                    <input
                      type="text"
                      className="flex-fill bg-transparent"
                      style={{ height: 36 }}
                      placeholder={t("enterSearchWordMsg")}
                      ref={SEARCH_WORD}
                      onKeyPress={(e) => {
                        if (e.charCode === 13) {
                          actionSearch();
                        }
                      }}
                    />
                    <button
                      className="bg-transparent text-secondary h6 mb-0"
                      onClick={actionSearch}
                    >
                      <i className="fa-solid fa-magnifying-glass" />
                    </button>
                  </div>
                </div>
                {data.map((item, index) => (
                  <ItemRender
                    key={item.cm_uid}
                    item={item}
                    CATEGORY={CATEGORY}
                  />
                ))}

                <button
                  className="w-100 border bg-light text-secondary text-center p-3 font-weight-bold cursor_pointer"
                  disabled={page + 1 === totalPageCount}
                  onClick={actionSeeMore}
                >
                  {t("showMore")}{" "}
                  <i className="fa-solid fa-angle-down ml-1 mr-3" /> {page + 1}{" "}
                  / {totalPageCount}
                </button>
              </div>
              <div className="col-4 d-none d-lg-block">
                <div className="p-3 border bg-white">
                  <p className="h5 mb-3 font-weight-bold">
                    {t("popular")} {t("post")}
                  </p>
                  {popularList.map((item, index) => (
                    <PopularItemRender
                      key={item.cm_uid}
                      item={item}
                      index={index}
                      CATEGORY={CATEGORY}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="position-fixed btn_1 "
          style={{ bottom: 20, right: 20 }}
          onClick={clickWrite}
        >
          <i className="fa-solid fa-plus mr-2" />
          {t("write")}
        </button>
      </main>
      <Footer />
    </>
  );
}

const Item = React.memo(({ item, CATEGORY }) => {
  const { t } = useTranslation();
  const creatorName =
    item.u_name_full || item.u_name_first + " " + item.u_name_last;
  const creatorPic = util.changedResourceUrl(item.u_avata_pic);

  return (
    <div className="p-4 border bg-white mb-3 text-secondary">
      <div className="d-flex flex-row mb-4">
        <div
          className="rounded-circle border mr-2"
          style={{
            height: 40,
            width: 40,
            minWidth: 40,
            background: `url(${creatorPic}) 50% 50% / cover no-repeat`,
          }}
        />
        <div className="flex-fill d-flex flex-column flex-md-row justify-content-between">
          <div>
            <p className="h6 font-weight-bold text-dark mb-0">{creatorName}</p>
            <p>
              {CATEGORY[item.cm_type]} · {item.cm_create_date}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="mb-4">
        <p className="h5 font-weight-bolder text-dark">{item.cm_title}</p>
        <p className="h6">{item.cm_sub_title}</p>
      </div>
      <div
        className="overflow-hidden position-relative"
        style={{ maxHeight: 250 }}
      >
        <div
          className="mb-3 quill-wrap"
          style={{ fontSize: "1.2em" }}
          dangerouslySetInnerHTML={{ __html: item.cm_content }}
        />
        <div
          className="position-absolute w-100 h-25"
          style={{
            bottom: 0,
            left: 0,
            background:
              "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,0) 100%)",
          }}
        />
      </div>
      <div className="d-flex flex-row justify-content-between mt-3">
        <div className="font-weight-bold text-secondary">
          <span className="mr-2">
            {t("reply")} {util.addCommas(item.cm_reply_cnt)}
          </span>
          <span>
            {t("view")} {util.addCommas(item.cm_look_cnt)}
          </span>
        </div>
        <Link
          to={`/CommunityDetail/${item.cm_idx}`}
          className="font-weight-bold m-0 text-secondary"
        >
          {t("showDetail")} <i className="fa-solid fa-arrow-turn-up ml-1" />
        </Link>
      </div>
    </div>
  );
});

const PopularItem = React.memo(({ item, CATEGORY, index }) => {
  const isLast = index + 1 === popularList.length;
  const creatorName =
    item.u_name_full || item.u_name_first + " " + item.u_name_last;
  const creatorPic = util.changedResourceUrl(item.u_avata_pic);

  return (
    <Link
      to={`/CommunityDetail/${item.cm_idx}`}
      className={`font-weight-bold m-0 text-secondary d-block p-2 ${
        isLast ? "" : "mb-3"
      }`}
    >
      <div className="d-flex flex-row align-items-center">
        <p className="h3 mb-0 mr-3 font-weight-bold text-buddible">
          {index + 1}
        </p>
        <div
          className="rounded-circle border mr-3"
          style={{
            height: 40,
            width: 40,
            minWidth: 40,
            background: `url(${creatorPic}) 50% 50% / cover no-repeat`,
          }}
        />
        <div className="flex-fill d-flex flex-row justify-content-between">
          <div>
            <p className="h6 mb-0 ellipsis_1">{item.cm_title}</p>
            <p className="ellipsis_1">{item.cm_sub_title}</p>
            <p className="font-size-08 ellipsis_1">
              {CATEGORY[item.cm_type]} · {creatorName}
            </p>
          </div>
        </div>
      </div>
    </Link>
  );
});
