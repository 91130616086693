import React, { useMemo, useRef, useState } from "react";

import Select from "react-dropdown-select";
import $ from "jquery";

import CodeList from "../../../../../lib/CodeList";
import Utilities from "../../../../../lib/Utilities";

const codeList = new CodeList();
const util = new Utilities();

const times = [...codeList.publicVar.times];
let isLoad = false;
let rl_base_hour_duration = null;
export default function GuideScheduleEditModal(props) {
  const { loginInfo, guideRouteList, updateServiceSchedule } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const selectRef = useRef();
  const controller = {
    sl_route_uid: useRef(),
    sl_sdate: useRef(),
    sl_edate: useRef(),
    sl_stime: useRef(),
    sl_etime: useRef(),
  };

  // 서비스 선택
  const changedService = (item) => {
    const data = item[0];
    let sl_route_uid = "";
    let sl_stime = "";
    let sl_etime = "";
    let dTime = null;

    if (typeof data === "object" && data.hasOwnProperty("rl_idx")) {
      let sTime = times.find((time) => time.value === data.rl_base_hour_start);
      dTime = times.find((time) => time.value === data.rl_base_hour_duration);
      let endId = Number(sTime.id) + Number(dTime.id);
      if (endId > 1440) endId -= 1440;
      endId = endId.toString();

      let eTime = times.find((time) => time.id === endId);

      sl_route_uid = data.rl_route_uid;
      sl_stime = data.rl_base_hour_start;
      sl_etime = eTime.value;
    }

    controller.sl_route_uid.current.value = sl_route_uid;
    controller.sl_stime.current.value = sl_stime;
    controller.sl_etime.current.value = sl_etime;
    rl_base_hour_duration = dTime;
    setIsNeedUpdate((before) => !before);
  };

  // 서비스 시작일, 종료일 변환(시작일이 종료일보다 큰 경우)
  const handleScheduleDate = (event) => {
    const name = event.target.name;
    const sDate = +new Date(controller.sl_sdate.current.value);
    const eDate = +new Date(controller.sl_edate.current.value);
    if (sDate > eDate) {
      if (name === "sl_sdate") {
        controller.sl_edate.current.value = event.target.value;
      } else {
        controller.sl_sdate.current.value = event.target.value;
      }
    }
  };

  // 서비스 open 시간 변경
  const changedOpenTime = (e) => {
    if (rl_base_hour_duration === null) {
      codeList.Modal.current.alert("서비스를 우선 선택해주세요");
      controller.sl_stime.current.value = "00:00:00";
      return;
    }

    let value = e.target.value;
    let sTime = times.find((time) => time.value === value);

    let endID = Number(sTime.id) + Number(rl_base_hour_duration.id);
    if (endID > 1440) endID -= 1440;

    endID = endID.toString();

    let eTime = times.find((time) => time.id === endID);
    controller.sl_etime.current.value = eTime.value;
    setIsNeedUpdate((before) => !before);
  };

  // 유효성 검사
  const checkValidation = (data) => {
    if (data.sl_route_uid.trim() === "") {
      codeList.Modal.current.alert("OPEN 일정을 추가할 서비스를 선택해주세요");
      return false;
    }
    if (data.sl_sdate.trim() === "") {
      codeList.Modal.current.alert("서비스 OPEN 시작일을 선택해주세요", () => {
        controller.sl_sdate.current.focus();
      });

      return false;
    }

    if (data.sl_edate.trim() === "") {
      codeList.Modal.current.alert(
        "서비스 OPEN 종료일을 선택해주세요. 서비스 종료일자가 지나면 게스트에게 노출되지 않아요.",
        () => {
          controller.sl_edate.current.focus();
        }
      );

      return false;
    }

    if (+new Date(data.sl_sdate) > +new Date(data.sl_edate)) {
      codeList.Modal.current.alert(
        "OPEN 종료일을 시작일보다 이후날짜로 선택해주세요.",
        () => {
          controller.sl_sdate.current.focus();
        }
      );

      return false;
    }

    if (data.sl_stime.trim() === "") {
      codeList.Modal.current.alert(
        "서비스 시작 시작을 선택해주세요. 지정된 시간에 게스트에게 서비스를 제공합니다.",
        () => {
          controller.sl_stime.current.focus();
        }
      );

      return false;
    }
    return true;
  };

  // 저장
  const actionSave = () => {
    if (isLoad) return;
    isLoad = true;

    const updateData = {
      sl_guide_uid: loginInfo.u_uid,
      sl_route_uid: controller.sl_route_uid.current.value,
      sl_uid_type: "R",
      sl_sdate: controller.sl_sdate.current.value,
      sl_edate: controller.sl_edate.current.value,
      sl_stime: controller.sl_stime.current.value,
      sl_etime: controller.sl_etime.current.value,
      sl_open_yn: "Y",
      sl_del_yn: "N",
    };

    if (checkValidation(updateData)) {
      updateServiceSchedule(updateData, () => {
        codeList.Modal.current.alert("스케줄이 정상 등록되었습니다", () => {
          isLoad = false;
          closeModal();
        });
      });
    } else {
      isLoad = false;
    }
  };

  // 닫기 & 초기화
  const closeModal = () => {
    controller.sl_route_uid.current.value = "";
    controller.sl_sdate.current.value = util.todayDate("YYYY-MM-DD");
    controller.sl_edate.current.value = util.todayDate("YYYY-MM-DD");
    controller.sl_stime.current.value = "00:00:00";
    controller.sl_etime.current.value = "00:00:00";
    rl_base_hour_duration = null;
    selectRef.current && selectRef.current.clearAll();
    setIsNeedUpdate((before) => !before);
    $("#GuideScheduleEditModalClose").click();
  };

  const routeData = useMemo(() => {
    return guideRouteList.filter((item) => item.rl_confirm_admin === "Y");
  }, [guideRouteList]);

  return (
    <div
      className="modal fade"
      id="GuideScheduleEditModal"
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby="GuideScheduleEditModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="GuideScheduleEditModalLabel">
              서비스 일정 추가
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
              id={"GuideScheduleEditModalClose"}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="p-3">
            <div className="row mb-2">
              <div className="col-4">
                <p className="font-weight-bold">서비스 선택</p>
              </div>
              <div className="col-8">
                <Select
                  ref={selectRef}
                  options={routeData}
                  labelField="rl_title"
                  valueField="rl_route_uid"
                  required={true}
                  multi={false}
                  clearable={true}
                  autoFocus={false}
                  placeholder={"서비스를 선택해주세요"}
                  onChange={changedService}
                  itemRenderer={({
                    item,
                    itemIndex,
                    methods,
                    props,
                    state,
                  }) => {
                    const isLast = guideRouteList.length === itemIndex + 1;

                    let img = "";
                    if (item.rl_pic_main.indexOf("http") > -1) {
                      img = item.rl_pic_main.split("?")[1];
                    } else {
                      img = codeList.publicVar.imgServerUrl + item.rl_pic_main;
                    }

                    return (
                      <div
                        key={item.rl_route_uid}
                        className={`p-2 w-100 cursor_pointer_bg ${
                          isLast ? "" : "border-bottom"
                        }`}
                        style={{
                          backgroundColor:
                            state.cursor === itemIndex
                              ? "#edf0f9"
                              : "transparent",
                        }}
                      >
                        <div
                          role="option"
                          aria-selected="false"
                          tabIndex="-1"
                          className="react-dropdown-select-item css-148o527-ItemComponent evc32pp0"
                          onClick={() => {
                            methods.addItem(item);
                          }}
                        >
                          <div className="d-flex flex-row">
                            <img
                              src={img}
                              alt={item.rl_title}
                              width={40}
                              height={40}
                              className="rounded mr-3"
                              style={{ objectFit: "contain" }}
                            />

                            <div>
                              <p className="font-weight-bold">
                                {item.rl_title}{" "}
                                <span className="badge badge-success">
                                  승인
                                </span>{" "}
                              </p>
                              <div className="font-size-08">
                                <p>
                                  최소인원 : {item.rl_guest_min}명 · 최대인원 :{" "}
                                  {item.rl_guest_max}명
                                </p>
                                <p>소요시간 : {item.rl_base_hour_duration}</p>
                                <p>
                                  기본요금 :{" "}
                                  {util.addCommas(item.rl_price_base)}원
                                </p>
                                <p>
                                  추가요금(인원) :{" "}
                                  {util.addCommas(item.rl_price_per_people)}원
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
              <input
                type="text"
                ref={controller.sl_route_uid}
                className="d-none"
              />
            </div>

            <div className="row mb-2">
              <div className="col-4 font-weight-bold">서비스 OPEN 일정</div>
              <div className="col-8">
                <div className="input-group">
                  <input
                    className="form-control"
                    type="date"
                    min={util.todayDate("YYYY-MM-DD")}
                    defaultValue={util.todayDate("YYYY-MM-DD")}
                    ref={controller.sl_sdate}
                    name={"sl_sdate"}
                    onChange={handleScheduleDate}
                  />
                  <div className="input-group-text rounded-0 border-right-0 border-left-0">
                    -
                  </div>
                  <input
                    className="form-control"
                    type="date"
                    min={util.todayDate("YYYY-MM-DD")}
                    defaultValue={util.todayDate("YYYY-MM-DD")}
                    name={"sl_edate"}
                    ref={controller.sl_edate}
                    onChange={handleScheduleDate}
                  />
                </div>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-4 font-weight-bold">서비스 제공시간</div>
              <div className="col-8">
                <div className="input-group">
                  <select
                    className="form-control"
                    ref={controller.sl_stime}
                    onChange={changedOpenTime}
                  >
                    {times.map((time, index) => {
                      return (
                        <option key={index} value={time.value}>
                          {time.value}
                        </option>
                      );
                    })}
                  </select>
                  <div className="input-group-text rounded-0 border-right-0 border-left-0">
                    -
                  </div>
                  <select
                    className="form-control"
                    ref={controller.sl_etime}
                    disabled={true}
                  >
                    {times.map((time, index) => {
                      return (
                        <option key={index} value={time.value}>
                          {time.value}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={closeModal}
            >
              취소
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={actionSave}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
