/*global google*/
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

let mapApi = {
  map: null,
  infoWindow: null,
  userLocation: { lat: 0, lng: 0 },
  directionsService: null,
  directionsRenderer: null,
  makers: [],
  routeSubData: [],
};

export default function TourDetailLocation(props) {
  const { t } = useTranslation();
  const { tourData } = props;
  const [isUnDirectionInfo, setIsUnDirectionInfo] = useState(false);

  useEffect(() => {
    if (tourData.subData.length) {
      mapApi.routeSubData = [...tourData.subData];

      initMap();
    }
  }, []);

  const initMap = () => {
    mapApi.directionsService = new google.maps.DirectionsService();
    mapApi.directionsRenderer = new google.maps.DirectionsRenderer();
    mapApi.map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: -34.397, lng: 150.644 },
      zoom: 17,
      scrollwheel: true,
      navigationControl: false,
      mapTypeControl: false,
      scaleControl: false,
      draggable: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });
    mapApi.directionsRenderer.setMap(mapApi.map);

    mapApi.infoWindow = new google.maps.InfoWindow();
    const locationButton = document.createElement("button");
    locationButton.textContent = "Pan to Current Location";
    locationButton.classList.add("custom-map-control-button");
    mapApi.map.controls[google.maps.ControlPosition.TOP_CENTER].push(
      locationButton
    );
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mapApi.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          mapApi.map.setCenter(mapApi.userLocation);
          makeGoogleMapDirection();
        },
        () => {
          handleLocationError(true, mapApi.infoWindow, mapApi.map.getCenter());
        }
      );
    } else {
      handleLocationError(false, mapApi.infoWindow, mapApi.map.getCenter());
    }
  };

  const handleLocationError = () => {};

  const makeGoogleMapDirection = () => {
    let origin = {
      placeId: "",
    };
    let destination = {
      placeId: "",
    };

    const waypoints = [];

    let request = {
      origin: origin,
      destination: destination,
      travelMode: "DRIVING",
    };

    if (mapApi.routeSubData.length === 0) return;
    if (mapApi.routeSubData.length) {
      let center = {
        lat: mapApi.routeSubData[0].rs_addr_lat,
        lng: mapApi.routeSubData[0].rs_addr_log,
      };
      // this.setState({
      //     center: {
      //         ...center,
      //     },
      // });
    }

    if (mapApi.routeSubData.length === 2) {
      origin = { placeId: mapApi.routeSubData[0].rs_placeId };
      destination = {
        placeId: mapApi.routeSubData[mapApi.routeSubData.length - 1].rs_placeId,
      };

      request = {
        origin: origin,
        destination: destination,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
    }
    if (mapApi.routeSubData.length > 2) {
      origin = { placeId: mapApi.routeSubData[0].rs_placeId };
      destination = {
        placeId: mapApi.routeSubData[mapApi.routeSubData.length - 1].rs_placeId,
      };
      for (let i = 1; i < mapApi.routeSubData.length - 1; i++) {
        waypoints.push({
          location: { placeId: mapApi.routeSubData[i].rs_placeId },
          stopover: true,
        });
      }
      request = {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      };
    }

    for (let i = 0; i < mapApi.makers.length; i++) {
      mapApi.makers[i].setMap(null);
    }
    mapApi.makers = [];

    mapApi.directionsService.route(request, function (response, status) {
      //console.log(request, response, status);
      if (status === "OK") {
        mapApi.directionsRenderer.setDirections(response);
      } else {
        setIsUnDirectionInfo(true);
        addMakers();
      }
    });

    function addMakers() {
      //console.log("addMakers");
      let bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < mapApi.routeSubData.length; i++) {
        let marker = new google.maps.Marker({
          position: {
            lat: mapApi.routeSubData[i].rs_addr_lat,
            lng: mapApi.routeSubData[i].rs_addr_log,
          },

          title: mapApi.routeSubData[i].rs_street_name,
        });
        bounds.extend(marker.position);

        marker.setMap(mapApi.map);
        mapApi.makers.push(marker);
      }
      //console.log(bounds);
      mapApi.map.fitBounds(bounds);
    }
  };

  return (
    <div className="row py-3  m-1 border-top">
      <div className="col-12 mb-2">
        <p className="h5 pt-3 font-weight-bold">
          <i className="fa-solid fa-location-dot text-secondary mr-1" />
          {t("location")}
        </p>
        {isUnDirectionInfo && <p>{t("tourDetailLocationNoRouteNote")}</p>}
      </div>
      <div
        id={"map"}
        style={{ width: "100%", height: "500px" }}
        className="rounded-lg"
      />
    </div>
  );
}
