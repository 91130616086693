import React, { useEffect, useState } from "react";
import BuddibleSocket, {
  MsgIDList,
} from "../../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../../lib/Utilities";
import CodeList, { CommonConstList } from "../../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let prerequisite_data = {
  rl_guest_age_limit: 21, //게스트 최소연령
  rl_can_child: "", //2세유아 동반가능
  rl_accessibility_main: "", //e-기타, c-의사소통, v-환경
  rl_accessibility_etc: "", //f-냉장고, s-보조장치, r-휴식공간, e-시각장애, n-최소대기, t-휴식시간
  rl_accessibility_communication: "", // h-수화, e-시각장애, a-장애인지원, s-청각, l-청각언어
  rl_accessibility_environment: "", //s-계단없음, e-넓은입구, p-장애인주차, t-접근화장실, t-평평한부지, c-감각자극
  rl_tour_level: "", //여행의 강도
  route4PreRadio2: "",
  rl_tour_skill: "", //게스트스킬 요구
  rl_additional_demand: "", //추가요건
  rl_guest_validation_yn: "N", //게스트본인인증
};

let access_check_data = {};
let accessibility_main = {
  C: {
    key: "c",
    title: "의사소통 관련 접근성 기능",
    info: "체험에 참여한 게스트와 소통하는 방법입니다.",
    code: "ACCESS_COMM",
    dataKey: "rl_accessibility_communication",
  },
  V: {
    key: "v",
    title: "환경 접근성 기능",
    info: "다음은 체험 진행 장소의 특성에 대한 내용입니다.",
    dataKey: "rl_accessibility_environment",
    code: "ACCESS_ENV",
  },
  E: {
    key: "e",
    title: "기타 접근성 기능",
    info: "",
    dataKey: "rl_accessibility_etc",
    code: "ACCESS_ETC",
  },
};
let tour_level = [
  { key: "L", desc: "가벼움" },
  { key: "U", desc: "보통" },
  { key: "V", desc: "격렬함" },
  { key: "S", desc: "매우 격렬함" },
];
let tour_skill = [
  { key: "BE", desc: "초급" },
  { key: "MI", desc: "중급" },
  { key: "AD", desc: "상급" },
  { key: "VE", desc: "전문가" },
];
export default function GuideTourRegistPrerequisite(props) {
  const { tourData, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  (() => {
    for (let key in CommonConstList.Accessibility) {
      let arr = {};
      arr[key] = [];

      codeList.codeOffer.get(key).forEach((v, k) => {
        arr[key].push({
          co_code: v.co_code,
          co_name_kr: v.co_name_kr,
          co_name_en: v.co_name_en,
          co_desc_kr: v.co_desc_kr,
          co_desc_en: v.co_desc_en,
          co_ui_id: v.co_ui_id,
          co_ui_name: v.co_ui_name,
          co_ui_key: v.co_ui_key,
        });
      });

      access_check_data[key] = arr[key];
    }
  })();

  useEffect(() => {
    if (tourData) {
      for (let key in prerequisite_data) {
        prerequisite_data[key] = tourData[key]
          ? tourData[key]
          : prerequisite_data[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
    return () => {
      prerequisite_data = {
        rl_guest_age_limit: 21, //게스트 최소연령
        rl_can_child: "", //2세유아 동반가능
        rl_accessibility_main: "", //e-기타, c-의사소통, v-환경
        rl_accessibility_etc: "", //f-냉장고, s-보조장치, r-휴식공간, e-시각장애, n-최소대기, t-휴식시간
        rl_accessibility_communication: "", // h-수화, e-시각장애, a-장애인지원, s-청각, l-청각언어
        rl_accessibility_environment: "", //s-계단없음, e-넓은입구, p-장애인주차, t-접근화장실, t-평평한부지, c-감각자극
        rl_tour_level: "", //여행의 강도
        route4PreRadio2: "",
        rl_tour_skill: "", //게스트스킬 요구
        rl_additional_demand: "", //추가요건
        rl_guest_validation_yn: "N", //게스트본인인증
      };
    };
  }, []);

  //접근성 제공 핸들러
  const handleAccessibility = (e, key, item) => {
    let check = e.target.checked;
    let accessibilitySub = prerequisite_data[key];

    if (check) {
      if (accessibilitySub.length === 0) {
        accessibilitySub = item.key;
      } else {
        accessibilitySub = accessibilitySub + "|" + item.key;
      }
    } else {
      if (accessibilitySub.length === 0) return;
      let splitData = accessibilitySub.split("|");
      let del = splitData.findIndex((data) => data === item.key);
      splitData.splice(del, 1);
      accessibilitySub = splitData.join("|");
      if (key === "rl_accessibility_main") {
        prerequisite_data = {
          ...prerequisite_data,
          [item.dataKey]: "",
        };
      }
    }
    handlePrerequisite(key, accessibilitySub);
  };

  //게스트 필수조건 데이터 핸들러
  const handlePrerequisite = (key, value) => {
    prerequisite_data = {
      ...prerequisite_data,
      [key]: value,
    };
    handleTourDataChange(prerequisite_data);
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (val) => {
    guideTourRegistDataChange(prerequisite_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  //연령 선택 리스트 구현
  const ageList = () => {
    let dataList = [];
    for (let i = 21; i > 0; i--) {
      dataList.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return dataList;
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <h6 className="font-weight-bold">최소 연령</h6>
        <p>
          게스트 연령 제한을 설정하세요. 미성년자는 법적 보호자가 동반해야만
          참여할 수 있습니다.
        </p>
        <select
          className="form-control my-2"
          defaultValue={prerequisite_data.rl_guest_age_limit}
          disabled={tourData.rl_confirm_admin === "S"}
          onChange={(e) => {
            handlePrerequisite("rl_guest_age_limit", e.target.value);
          }}
        >
          {ageList()}
        </select>
        <div className="border rounded p-3">
          <h6>미성년 게스트 가이드하기</h6>
          <p>
            미성년에게 여행을 제공하는 경우, 가이드는 참여 게스트의 나이에
            적합하게 가이드 활동을 계획할 책임이 있습니다. 자세히 알아보기
          </p>
          <div className="mt-2">
            <label>
              <input
                type="checkbox"
                disabled={tourData.rl_confirm_admin === "S"}
                // defaultChecked={prerequisite_data.rl_can_child === "Y"}
                // onChange={(e) => {
                //   handlePrerequisite(
                //     "rl_can_child",
                //     e.target.checked ? "Y" : "N"
                //   );
                // }}
              />{" "}
              위 사항에 동의함
            </label>
          </div>
        </div>

        <label className="mt-3">
          <input
            type="checkbox"
            defaultChecked={prerequisite_data.rl_can_child === "Y"}
            disabled={tourData.rl_confirm_admin === "S"}
            onChange={(e) => {
              handlePrerequisite("rl_can_child", e.target.checked ? "Y" : "N");
            }}
          />{" "}
          2세 미만 유아 동반가능
        </label>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h6 className="font-weight-bold">
          가이드에 접근성 기능이 있나요? (선택 사항)
        </h6>
        <p>
          제공하는 기능/시설/장비를 모두 선택한 후, 모든 정보가 정확하고 최신
          상태인지 확인하세요.
        </p>

        <div>
          {Object.values(accessibility_main).map((item) => {
            return (
              <div key={item.key}>
                <label className="mt-3">
                  <input
                    className="mr-2"
                    type="checkbox"
                    value={item.key}
                    disabled={tourData.rl_confirm_admin === "S"}
                    checked={
                      prerequisite_data.rl_accessibility_main.indexOf(
                        item.key
                      ) > -1
                    }
                    onChange={(e) => {
                      handleAccessibility(e, "rl_accessibility_main", item);
                    }}
                  />{" "}
                  {item.title}
                </label>
                <div
                  className={`border rounded p-3 mt-1 mb-3 ${
                    prerequisite_data.rl_accessibility_main.indexOf(item.key) >
                    -1
                      ? ""
                      : "d-none"
                  }`}
                >
                  {access_check_data[item.code].map((detail) => {
                    let data = {
                      ...detail,
                      key: detail.co_code,
                    };

                    const isChecked =
                      prerequisite_data[item.dataKey]
                        .split("|")
                        .findIndex((dataKey) => dataKey === detail.co_code) >
                      -1;

                    return (
                      <div key={detail.co_ui_id}>
                        <label className="py-1">
                          <div className="d-flex flex-row justify-content-start align-items-start">
                            <div>
                              <input
                                className="mr-2"
                                type="checkbox"
                                disabled={tourData.rl_confirm_admin === "S"}
                                value={detail.co_code}
                                defaultChecked={isChecked}
                                onChange={(e) =>
                                  handleAccessibility(e, item.dataKey, data)
                                }
                              />
                            </div>
                            <div>
                              <p className="font-weight-bold">
                                {detail.co_name_kr}
                              </p>
                              <p>{detail.co_desc_kr}</p>{" "}
                            </div>
                          </div>
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h6 className="font-weight-bold">
          여행의 강도가 어떻게 되나요? (필수입력)
        </h6>
        <p>여행 진행 중 어느 강도의 신체 활동이 필요한지 생각해보세요.</p>

        {tour_level.map((level) => {
          return (
            <div className="mt-3" key={level.key}>
              <label>
                <input
                  type="radio"
                  name="tour_activity_level"
                  value={level.key}
                  disabled={tourData.rl_confirm_admin === "S"}
                  checked={prerequisite_data.rl_tour_level === level.key}
                  onChange={(e) => {
                    handlePrerequisite("rl_tour_level", e.target.value);
                  }}
                />{" "}
                {level.desc}
              </label>
            </div>
          );
        })}
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h6 className="font-weight-bold">
          여행의 난이도가 어떻게 되나요? (필수입력)
        </h6>
        <p>여행 진행에 있어 필요한 숙련도가 어느정도인지 생각해보세요.</p>
        {tour_skill.map((skill) => {
          return (
            <div className="mt-3" key={skill.key}>
              <label>
                <input
                  type="radio"
                  name="tour_activity"
                  value={skill.key}
                  disabled={tourData.rl_confirm_admin === "S"}
                  checked={prerequisite_data.rl_tour_skill === skill.key}
                  onChange={(e) => {
                    handlePrerequisite("rl_tour_skill", e.target.value);
                  }}
                />{" "}
                {skill.desc}
              </label>
            </div>
          );
        })}
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h6 className="font-weight-bold mb-2">추가 요건(선택 사항)</h6>
        <textarea
          className="form-control"
          rows={10}
          disabled={tourData.rl_confirm_admin === "S"}
          placeholder="예) 게스트가 서핑경험이 있어야함, 게스트가 스쿠버 자격증을 보유해야함, 개와 편안하게 지낼수 있어야 함 등"
          onChange={(e) => {
            handlePrerequisite("rl_additional_demand", e.target.value);
          }}
        />
      </div>
      <div className="mb-5 pb-3">
        <h6 className="font-weight-bold">게스트 본인 인증 필요</h6>
        <p>
          게스트 본인 인증을 요청하실 경우, 대표 예약자가 인증을 완료해야만
          예약자 및 동반 게스트가 참가할 수 있습니다.
        </p>
        <div className="mt-3">
          <label>
            <input
              type="checkbox"
              disabled={tourData.rl_confirm_admin === "S"}
              checked={prerequisite_data.rl_guest_validation_yn === "Y"}
              onChange={(e) => {
                handlePrerequisite(
                  "rl_guest_validation_yn",
                  e.target.checked ? "Y" : "N"
                );
              }}
            />{" "}
            예약자의 본인 인증이 필요합니다.
          </label>
        </div>
      </div>
    </div>
  );
}
