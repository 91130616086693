import React, { useEffect, useState } from "react";

let offer_data = {
  rl_inc_meal: "N", //식사
  rl_inc_meal_list: "",
  rl_inc_meal_memo: "",
  rl_inc_snack: "N", //간단한 간식
  rl_inc_alcohol: "N", //주류
  rl_inc_drink: "N", //음료수
  rl_inc_drink_list: "",
  rl_inc_drink_memo: "",
  rl_inc_ticket: "N", //티켓
  rl_inc_ticket_list: "",
  rl_inc_ticket_memo: "",
  rl_inc_vehicle: "N", //차량
  rl_inc_vehicle_list: "",
  rl_inc_vehicle_memo: "",
  rl_inc_vehicle_driver: "", // 운전자 형태
  rl_inc_etc: "", //기타 제공사항
};

const offers = [
  {
    key: "rl_inc_vehicle",
    desc: "차량 (자동차, ATV, 전동 스쿠터, 스노모빌 등) 운전",
    value: "A",
  },
  {
    key: "rl_inc_meal",
    desc: "식사",
    value: "B",
  },
  {
    key: "rl_inc_snack",
    desc: "간단한 간식",
    value: "C",
  },
  {
    key: "rl_inc_alcohol",
    desc: "주류",
    value: "D",
  },
  {
    key: "rl_inc_drink",
    desc: "음료수",
    value: "E",
  },
  {
    key: "rl_inc_ticket",
    desc: "티켓",
    value: "f",
  },
  {
    key: "none_checkbox",
    desc: "여행에 이러한 사항이 포함되지 않습니다.",
    value: "G",
  },
];

const offer_driver = [
  { key: "driver_AA", value: "AA", desc: "가이드가 직접 운전합니다." },
  { key: "driver_BB", value: "BB", desc: "공동 가이드가 운전합니다." },
  { key: "driver_CC", value: "CC", desc: "오토바이" },
  {
    key: "driver_DD",
    value: "DD",
    desc: "저와 가이드 팀에서 게스트가 운행할 차량을 제공할 것입니다.",
  },
  {
    key: "driver_EE",
    value: "EE",
    desc: "게스트에게 대중교통이나 허가받은 제3자 운행사를 이동편으로 제공합니다.",
  },
];

export default function GuideTourRegistOffer(props) {
  const { tourData, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (tourData) {
      for (let key in offer_data) {
        offer_data[key] = tourData[key] ? tourData[key] : offer_data[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
    return () => {
      offer_data = {
        rl_inc_meal: "N", //식사
        rl_inc_meal_list: "",
        rl_inc_meal_memo: "",
        rl_inc_snack: "N", //간단한 간식
        rl_inc_alcohol: "N", //주류
        rl_inc_drink: "N", //음료수
        rl_inc_drink_list: "",
        rl_inc_drink_memo: "",
        rl_inc_ticket: "N", //티켓
        rl_inc_ticket_list: "",
        rl_inc_ticket_memo: "",
        rl_inc_vehicle: "N", //차량
        rl_inc_vehicle_list: "",
        rl_inc_vehicle_memo: "",
        rl_inc_vehicle_driver: "", // 운전자 형태
        rl_inc_etc: "", //기타 제공사항
      };
    };
  }, []);

  //기본제공항목 선택
  const handleOfferChange = (e, inc) => {
    let check = e.target.checked;
    if (check) {
      if (inc.key === "none_checkbox") {
        offer_data = {
          ...offer_data,
          rl_inc_vehicle: "N",
          rl_inc_meal: "N",
          rl_inc_snack: "N",
          rl_inc_alcohol: "N",
          rl_inc_drink: "N",
          rl_inc_ticket: "N",
          [inc.key]: "Y",
        };
      } else {
        offer_data = {
          ...offer_data,
          [inc.key]: "Y",
        };
      }
    } else {
      offer_data = {
        ...offer_data,
        [inc.key]: "N",
      };
    }
    handleTourDataChange();
  };

  //운전자 선택
  const handleDriverChange = (e, driver) => {
    let check = e.target.checked;
    let rl_inc_vehicle_driver = "";

    if (check) {
      if (offer_data.rl_inc_vehicle_driver === "") {
        rl_inc_vehicle_driver = driver.value;
      } else {
        let drivers = [offer_data.rl_inc_vehicle_driver, driver.value];
        drivers = drivers.join("|");
        rl_inc_vehicle_driver = drivers;
      }
    } else {
      let drivers = offer_data.rl_inc_vehicle_driver;
      if (!drivers.length) return;
      let splitData = drivers.split("|");
      let removeIndex = splitData.findIndex((item) => item === driver.value);
      splitData.splice(removeIndex, 1);
      splitData = splitData.join("|");
      rl_inc_vehicle_driver = splitData;
    }
    offer_data = {
      ...offer_data,
      rl_inc_vehicle_driver: rl_inc_vehicle_driver,
    };

    handleTourDataChange();
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (val) => {
    guideTourRegistDataChange(offer_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  return (
    <>
      <div className="col-xl-6">
        <div className="mb-3 pb-3 border-bottom">
          <h6 className="font-weight-bold mb-2">
            가이드 중 다음 사항이 포함되나요? (다중 선택 가능)
          </h6>
          <div>
            {offers.map((item) => {
              return (
                <label
                  key={item.key}
                  className="d-flex flex-row align-items-start mb-2"
                >
                  <input
                    type="checkbox"
                    disabled={tourData.rl_confirm_admin === "S"}
                    className="mr-2 mt-1"
                    value={item.value}
                    checked={offer_data[item.key] === "Y"}
                    onChange={(e) => handleOfferChange(e, item)}
                  />
                  <p>{item.desc}</p>
                </label>
              );
            })}
          </div>
          {offer_data.rl_inc_vehicle === "Y" && (
            <div className="border rounded p-3 d-flex flex-row align-items-center mt-3">
              <div className="h1 mr-3">
                <i className="fas fa-exclamation-circle" />
              </div>
              <p>
                소지하신 운전면허와 자동차 보험이 현지 법적 요건에 부합하지 않는
                경우, 게스트를 태우고 직접 운전을 해서는 안 됩니다.{" "}
                <span>플레이 영종 가이드 보호 보험</span>의 적용 범위에 대해
                자세히 알아보세요.
              </p>
            </div>
          )}
        </div>

        {offer_data.rl_inc_vehicle === "Y" && (
          <div className="mb-3 pb-3 border-bottom">
            <h6 className="font-weight-bold mb-2">
              차량 운행 시 운전자는 누구인가요?
            </h6>
            <div>
              {offer_driver.map((item) => {
                return (
                  <label
                    key={item.key}
                    className="d-flex flex-row align-items-start mb-2"
                  >
                    <input
                      type="checkbox"
                      className="mr-2 mt-1"
                      value={item.value}
                      disabled={tourData.rl_confirm_admin === "S"}
                      defaultChecked={
                        offer_data.rl_inc_vehicle_driver.indexOf(item.value) >
                        -1
                      }
                      onChange={(e) => handleDriverChange(e, item)}
                    />
                    <p>{item.desc}</p>
                  </label>
                );
              })}
            </div>
          </div>
        )}

        <div className="mb-3 pb-3 border-bottom">
          <h6 className="font-weight-bold mb-2">추가 제공사항 및 제공상세</h6>
          <textarea
            rows={5}
            className="form-control"
            defaultValue={tourData.rl_inc_etc}
            placeholder={
              "위의 선택지외에 추가로 제공되는 항목에 대해 자유롭게 작성해주세요"
            }
            maxLength={300}
            onChange={(e) => {
              offer_data = {
                ...offer_data,
                rl_inc_etc: e.target.value,
              };
            }}
            onBlur={(e) => {
              handleTourDataChange(offer_data);
            }}
          />
        </div>

        <div>
          <h6 className="font-weight-bold mb-2">참고사항</h6>
          <p>
            교통수단이나 장비를 외부 업체를 통해 제공하신다면 게스트가 미리
            파악할 수 있도록 그 업체의 이름이나 기타 정보를 입력해주세요.
            운송업체와 장비 대여업체에만 적용되는 사항이며 식음료 제공업체는
            입력하실 필요가 없습니다(입력하셔도 무방합니다).
          </p>
        </div>
      </div>
    </>
  );
}
