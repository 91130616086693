import React, { useEffect, useState } from "react";
import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const reserve_time = [
  { id: "1", tit: "최소 30분전", time: "00:30:00" },
  { id: "2", tit: "최소 1시간 전", time: "01:00:00" },
  { id: "3", tit: "최소 2시간 전", time: "02:00:00" },
  { id: "4", tit: "최소 3시간 전", time: "03:00:00" },
  { id: "5", tit: "최소 4시간 전", time: "04:00:00" },
  { id: "6", tit: "최소 6시간 전", time: "06:00:00" },
  { id: "7", tit: "최소 8시간 전", time: "08:00:00" },
  { id: "8", tit: "최소 12시간 전", time: "12:00:00" },
  { id: "9", tit: "최소 18시간 전", time: "18:00:00" },
  { id: "10", tit: "최소 1일 전", time: "24:00:00" },
  { id: "11", tit: "최소 2일 전", time: "48:00:00" },
  { id: "12", tit: "최소 3일 전", time: "72:00:00" },
  { id: "13", tit: "최소 4일 전", time: "96:00:00" },
  { id: "14", tit: "최소 5일 전", time: "120:00:00" },
  { id: "15", tit: "최소 6일 전", time: "144:00:00" },
  { id: "16", tit: "최소 일주일 전", time: "168:00:00" },
];
const add_guest_time = [
  { id: "1", tit: "시작 시간 1시간전", time: "01:00:00" },
  { id: "2", tit: "시작 시간 2시간전", time: "02:00:00" },
  { id: "3", tit: "시작 시간 3시간전", time: "03:00:00" },
  { id: "4", tit: "시작 시간 4시간전", time: "04:00:00" },
  { id: "5", tit: "시작 시간 5시간전", time: "05:00:00" },
  { id: "6", tit: "시작 시간 6시간전", time: "06:00:00" },
  { id: "7", tit: "시작 시간 7시간전", time: "07:00:00" },
  { id: "8", tit: "시작 시간 8시간전", time: "08:00:00" },
  { id: "9", tit: "시작 시간 10시간전", time: "10:00:00" },
  { id: "10", tit: "시작 시간 12시간전", time: "12:00:00" },
  { id: "11", tit: "시작 시간 18시간전", time: "18:00:00" },
  { id: "12", tit: "시작 시간 1일 전", time: "24:00:00" },
  { id: "13", tit: "시작 시간 2일 전", time: "48:00:00" },
  { id: "14", tit: "시작 시간 3일 전", time: "72:00:00" },
  { id: "15", tit: "시작 시간 4일 전", time: "96:00:00" },
  { id: "16", tit: "시작 시간 5일 전", time: "120:00:00" },
  { id: "17", tit: "시작 시간 6일 전", time: "144:00:00" },
  { id: "18", tit: "시작 시간 일주일 전", time: "168:00:00" },
];

let reserve_data = {
  gpt_time_before: "",
  gpt_time_add_guest: "",
  gpt_request_yn: "",
};

export default function GuideTimeRegistReserve(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (partTimeData) {
      for (let key in reserve_data) {
        reserve_data[key] = partTimeData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  const handleReserveData = (key, val) => {
    reserve_data[key] = val;
    guideTimeRegistDataChange(reserve_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <h3 className="font-weight-bold">예약 설정</h3>
        <p>
          예약 마감 시간을 체험 시작 시간과 최대한 가깝게 설정하면 더 많은
          게스트의 예약을 받을 수 있습니다. 단, 게스트를 맞이하기 위한 준비
          시간을 충분히 가질 수 있도록 설정하세요.
        </p>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h4 className="font-weight-bold">
          최소 몇시간 전에는 예약을 받으시나요?
        </h4>
        <p>가이드의 이동시간과 위치를 참고 해서 최소시간을 잡아주세요.</p>
        <select
          className="w-100 my-2 border rounded-lg p-3"
          onChange={(e) => {
            handleReserveData("gpt_time_before", e.target.value);
          }}
          value={reserve_data.gpt_time_before}
        >
          {reserve_time.map((item) => {
            return (
              <option key={item.id} value={item.time}>
                {item.tit}
              </option>
            );
          })}
        </select>
        <div className="d-flex flex-row justify-content-between align-items-center mt-3">
          <span className="h1 mr-1">
            <i className="fas fa-chart-line text-buddible" />
          </span>
          <span className="ml-1">
            가이드의 진행 시간과 활동의 성격을 고려하여, 예약 마감 시한을 체험
            시작 1시간 전으로 설정하실 것을 권해드립니다. 나중에 언제든지
            변경하실 수 있습니다.
          </span>
        </div>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h4 className="font-weight-bold">추가 인원에 대한 예약 마감 시간</h4>
        <p>
          가이드의 개별 회차에 이미 예약이 있는 경우, 이 회차에 참여할 다른
          게스트가 체험 시작 얼마 전까지 예약할 수 있는지 설정하는 것입니다.
        </p>
        <select
          className="w-100 my-2 border rounded-lg p-3"
          onChange={(e) => {
            handleReserveData("gpt_time_add_guest", e.target.value);
          }}
          value={reserve_data.gpt_time_add_guest}
        >
          {add_guest_time.map((item) => {
            return (
              <option key={item.id} value={item.time}>
                {item.tit}
              </option>
            );
          })}
        </select>
        <div className="d-flex flex-row justify-content-between align-items-center mt-3">
          <span className="h1 mr-1">
            <i className="fas fa-chart-line text-buddible" />
          </span>
          <span className="ml-1">
            가이드의 진행 시간과 활동의 성격을 고려하여, 예약 마감 시한을 체험
            시작 1시간 전으로 설정하실 것을 권해드립니다. 나중에 언제든지
            변경하실 수 있습니다.
          </span>
        </div>
      </div>

      <div className="mb-3 pb-3">
        <h4 className="font-weight-bold">예약 가능 여부에 대한 요청</h4>
        <div>
          <label className="d-flex flex-row justify-content-start py-3">
            <input
              type="checkbox"
              className="mr-2"
              onChange={(e) => {
                handleReserveData(
                  "gpt_request_yn",
                  e.target.checked ? "Y" : "N"
                );
              }}
              checked={reserve_data.gpt_request_yn === "Y"}
            />
            일정에 없는 날짜/시간에 게스트가 예약을 요청할 수 있도록 허용합니다.
          </label>
        </div>
        <p>
          선택 해제하시면, 체험 페이지에는 사람들이 메시지를 보낼 수 있도록
          '호스트에게 연락하기'가 이전과 같이 표시되지만, '예약 가능 여부 요청'
          링크는 달력에서 삭제됩니다.
        </p>
      </div>
    </div>
  );
}
