import React, { useCallback, useEffect, useState } from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();
const countryList = codeList.codeCountry;

let itemViewCountBackup = 6;
let screenWidth = window.innerWidth;
if (screenWidth <= 480) {
  screenWidth = 1.1;
}
if (screenWidth > 480 && screenWidth <= 720) {
  screenWidth = 2.1;
}
if (screenWidth > 720 && screenWidth < 1140) {
  screenWidth = 3.1;
}
if (screenWidth >= 1140) {
  screenWidth = 5.1;
}
export default function TourPopular(props) {
  const { t } = useTranslation();
  const { routeItem } = props;
  const [itemViewCount, setItemViewCount] = useState(itemViewCountBackup);

  useEffect(() => {
    handleItemCount();
    window.addEventListener("resize", handleItemCount);
    return () => {
      window.removeEventListener("resize", handleItemCount);
    };
  }, []);

  let handleItemCount = () => {
    screenWidth = window.innerWidth;
    let changeViewCount = 0;
    //  모바일
    if (screenWidth <= 480) {
      changeViewCount = 1.1;
    }
    if (screenWidth > 480 && screenWidth <= 720) {
      changeViewCount = 2.1;
    }
    //  테블릿
    if (screenWidth > 720 && screenWidth < 1140) {
      changeViewCount = 3.1;
    }
    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 5.1;
    }
    if (itemViewCountBackup !== changeViewCount) {
      itemViewCountBackup = changeViewCount;
      setItemViewCount(changeViewCount);
    }
  };

  const OwlCarouselRenderer = useCallback(() => {
    return (
      <OwlCarousel
        style={{ marginTop: "-30px" }}
        className="owl-theme"
        loop
        margin={10}
        dots={false}
        nav
        items={itemViewCount}
        loadedClass={"owl-loaded custom_owl-loaded"}
        stageOuterClass={"owl-stage-outer custom_owl-stage-outer"}
        navClass={["moveBtn mr-1", "moveBtn ml-1"]}
        navContainerClass={"custom_owl-nav"}
        navText={[
          "<span key='prev' class='text-secondary'><i class=\"fa-solid fa-angle-left\"></i></span>",
          "<span key='next' class='text-secondary'><i class=\"fa-solid fa-angle-right\"></i></span>",
        ]}
      >
        {routeItem.map((item, index) => {
          let {
            rl_idx,
            rl_pic_main,
            u_name_full,
            rl_title,
            rl_rating,
            rl_rating_cnt,
            rl_addr_country,
            rl_price_base,
            rl_price_per_people,
            rl_desc,
          } = item;

          const picUrl = util.changedResourceUrl(rl_pic_main);

          return (
            <Link key={rl_idx} to={`/TourDetail/${rl_idx}`} className="item">
              <div className="tour_container" style={{ minHeight: 300 }}>
                <div className="img_container">
                  <div
                    className="owl_carousel_img"
                    style={{
                      background: `url(${picUrl})50% 50%/cover no-repeat`,
                    }}
                  />

                  <div className="short_info">
                    <span className="price">
                      <sup>&#8361;</sup> {util.addCommas(rl_price_base)}
                    </span>
                  </div>
                </div>
                <div className="tour_title">
                  <p className="overflow-hidden text-truncate">
                    {countryList.get(rl_addr_country) &&
                      countryList.get(rl_addr_country).name_ko}
                    · {u_name_full}
                  </p>
                  <p className="h6 ellipsis_1">{rl_title}</p>
                  <p className="ellipsis_2">{rl_desc}</p>
                  <div className="rating">
                    <i
                      className={
                        rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                      }
                    />
                    <i
                      className={
                        rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                      }
                    />
                    <i
                      className={
                        rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                      }
                    />
                    <i
                      className={
                        rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                      }
                    />
                    <i
                      className={
                        rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                      }
                    />
                    <small>({rl_rating_cnt})</small>
                  </div>
                  <div>
                    <p className="ellipsis_1">
                      {t("base")} &#8361; {util.addCommas(rl_price_base)} /{" "}
                      {t("addedPerson")} &#8361;
                      {util.addCommas(rl_price_per_people)}
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </OwlCarousel>
    );
  }, [routeItem]);

  return (
    <section className="container margin_60 font">
      <div className="d-flex flex-row justify-content-between align-items-end  w-75">
        <h3 className="font-weight-bold m-0">{t("tourListPopularTitle")}</h3>
      </div>
      {routeItem.length > 0 && <OwlCarouselRenderer />}
      {routeItem.length === 0 && (
        <p className="h5 text-secondary text-center rounded-lg p-5 bg-white">
          {t("emptyServiceNote")}
        </p>
      )}
    </section>
  );
}
