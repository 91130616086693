/*global google*/
import React, {
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
} from "react";
import GoogleMap from "google-map-react";
import GuideTourRegistTravelRoutePlaceSearch from "./GuideTourRegistTravelRoutePlaceSearch";
import $ from "jquery";
import CodeList from "../../../../../../lib/CodeList";
import UploadImageToS3WithReactS3 from "../../../../../../lib/UploadImageToS3WithReactS3";

const codeList = new CodeList();

let page = 0;
let apiReady = false;
let mapApi = {
  map: null,
  googleMaps: null,
};
let center = {
  lat: 0,
  lng: 0, //parseFloat(this.props.routeSubData.rs_addr_log),
};
let zoom = 18;
let defaultRouteSubData = {
  rs_route_sub_order: "",
  rs_route_sub_uid: "",
  rs_route_uid: "",
  rs_guide_uid: "",
  rs_name: "",
  rs_pic: "",
  rs_placeId: "",
  rs_addr_full: "",
  rs_addr_lat: 0,
  rs_addr_log: 0,
  rs_addr_name: "",
  rs_addr_custom_name: "",
  rs_addr_country: "",
  rs_addr_postal_code: "",
  rs_addr_area_level_1: "",
  rs_addr_sublocality_level_1: "",
  rs_addr_sublocality_level_2: "",
  rs_addr_premise: "",
  rs_area_radius: "",
  rs_street_name: "",
  rs_desc: "",
  rs_use_yn: "Y",
};
let routeData = { ...defaultRouteSubData };

function GuideTourRegistTravelRouteModal(props, ref) {
  const { changeRouteSubData } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const $controller = {
    rs_street_name: useRef(),
    rs_desc: useRef(),
  };
  const geocoder = new google.maps.Geocoder();
  let marker = null;

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      if (params !== null) {
        dataSet(params);
      }
    },
  }));

  //수정시 초기데이터 셋
  const dataSet = (data) => {
    for (let key in routeData) {
      routeData[key] = data[key];
      if ($controller.hasOwnProperty(key) && $controller[key].current) {
        $controller[key].current.value = data.hasOwnProperty(key) && data[key];
      }
    }
    center = {
      lat: parseFloat(data.rs_addr_lat),
      lng: parseFloat(data.rs_addr_log),
    };
  };

  //루트 서브데이터 변경
  const changeRouteSubLocationData = (value) => {
    const data = {
      rs_addr_lat: value.geometry.location.lat(),
      rs_addr_log: value.geometry.location.lng(),
      rs_addr_full: value.formatted_address,

      rs_addr_name: value.name,
      rs_addr_country: "",
      rs_addr_postal_code: "",
      rs_addr_area_level_1: "",
      rs_addr_sublocality_level_1: "",
      rs_addr_sublocality_level_2: "",
      rs_addr_premise: "",
      rs_addr_place_id: value.place_id,
      rs_placeId: value.place_id,
    };
    let address_components = value.address_components;
    for (let i = 0; i < address_components.length; i++) {
      if (address_components[i].hasOwnProperty("types")) {
        let types = address_components[i]["types"];
        if (types.findIndex((element) => element === "premise") > -1)
          data.rs_addr_premise = address_components[i].short_name;
        if (
          types.findIndex((element) => element === "sublocality_level_2") > -1
        )
          data.rs_addr_sublocality_level_2 = address_components[i].short_name;
        if (
          types.findIndex((element) => element === "sublocality_level_1") > -1
        )
          data.rs_addr_sublocality_level_1 = address_components[i].short_name;
        if (
          types.findIndex(
            (element) => element === "administrative_area_level_1"
          ) > -1
        )
          data.rs_addr_area_level_1 = address_components[i].short_name;
        if (types.findIndex((element) => element === "country") > -1)
          data.rs_addr_country = address_components[i].short_name;
        if (types.findIndex((element) => element === "postal_code") > -1)
          data.rs_addr_postal_code = address_components[i].short_name;
      }
    }
    handleRouteDataChange(data);
  };

  // 마커 이동 콜백
  const changedGeocode = (map, maps) => {
    center = {
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    };

    if (center.lat === 0 && center.lng === 0) return;

    geocoder
      .geocode({ location: center })
      .then((response) => {
        changeRouteSubLocationData(response.results[0]);
        // changeRouteSubLocationData(response.results[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // mapApi.map.setCenter(center);
  };

  // 마커 위치 변경
  const changedMarker = useCallback(
    (center, map, maps) => {
      // 기존 마커 초기화
      if (marker) {
        marker.setMap(null);
      }

      marker = new maps.Marker({
        position: center,
        map,
        draggable: true,
      });
      changedGeocode(map, maps);
      marker.addListener("dragend", () => changedGeocode(map, maps));
    },
    [marker]
  );

  //구글 맵 로드
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      mapApi = {
        map: map,
        googleMaps: maps,
      };
      // this.setState({ apiReady: true });
    }

    // let marker = new maps.Marker({
    //   position: center,
    //   map,
    // });
    apiReady = true;
    mapApi.map.setCenter(center);
    changedMarker(center, map, maps);
    setIsNeedUpdate(!isNeedUpdate);
    // return marker;
  };

  const handleRouteDataChange = (data, callback) => {
    routeData = {
      ...routeData,
      ...data,
    };
    callback && callback();
  };

  const uploadImageCallback = async (res) => {
    if (!res.ret) {
      codeList.Modal.current.alert(
        "이미지 업로드중 오류가 발생했습니다. 다시 시도해주세요"
      );
    }

    const file = `${res.realFileName}?${res.fileName}`;
    let files = routeData["rs_pic"];

    if (files !== "") {
      if (files.split("|").length >= 6) {
        codeList.Modal.current.alert("최대 6장까지만 업로드 가능합니다.");
        return;
      }
      files = `${files}|${file}`;
    } else {
      files = file;
    }

    await handleRouteDataChange({ rs_pic: files });
    setIsNeedUpdate((prevState) => !prevState);
  };

  //페이지 이동
  const handlePage = (key) => {
    if (key === "next") {
      if (checkValidation) {
        page = page + 1;
      } else {
        codeList.Modal.current.alert("내용을 입력해주세요.");
      }
    } else {
      page = page - 1;
    }
    setIsNeedUpdate(!isNeedUpdate);
  };

  //데이터 입력 확인
  const checkValidation = () => {
    switch (page) {
      case 0:
        if (routeData.rs_street_name.trim() === "") return false;
        break;
      case 1:
        if (routeData.rs_desc.trim() === "") return false;
        break;
      case 2:
        if (routeData.rs_pic.trim() === "") return false;
        break;
      case 3:
        if (routeData.rs_placeId.trim() === "") return false;
        break;
    }
    return true;
  };

  //경로데이터 저장
  const handleSave = () => {
    // $("#travelRouteModalClose").click();
    changeRouteSubData(routeData);
    clearData();
  };

  const clearData = () => {
    page = 0;
    $controller.rs_street_name.current.value = "";
    $controller.rs_desc.current.value = "";
    routeData = { ...defaultRouteSubData };
    setIsNeedUpdate(!isNeedUpdate);
    $("#travelRouteModalClose").click();
  };

  return (
    <div
      className="modal fade"
      id="travelRouteModal"
      tabIndex="-1"
      aria-labelledby="travelRouteModalLabel"
      data-backdrop="static"
      data-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="travelRouteModalLabel">
              <div className={page === 0 ? "" : "d-none"}>제목</div>
              <div className={page === 1 ? "" : "d-none"}>설명</div>
              <div className={page === 2 ? "" : "d-none"}>사진</div>
              <div className={page === 3 ? "" : "d-none"}>위치</div>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="travelRouteModalClose"
              onClick={() => {
                clearData();
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className={page === 0 ? "" : "d-none"}>
              <p>경유지 제목을 입력해주세요. </p>
              <input
                type="text"
                className="form-control mt-2"
                placeholder="최대 20글자 입니다."
                name="rs_street_name"
                maxLength="20"
                autoComplete="off"
                ref={$controller.rs_street_name}
                defaultValue={routeData.rs_street_name}
                onChange={(e) => {
                  handleRouteDataChange({ rs_street_name: e.target.value });
                }}
              />
            </div>
            <div className={page === 1 ? "" : "d-none"}>
              {" "}
              <p>경유지 제목에 대한 설명을 입력해주세요.</p>
              <textarea
                rows={10}
                className="form-control mt-2"
                placeholder="경유지의 분위기, 경관과 주변 등 특별한 점을 설명하세요."
                name="rs_desc"
                ref={$controller.rs_desc}
                defaultValue={routeData.rs_desc}
                onChange={(e) => {
                  handleRouteDataChange({ rs_desc: e.target.value });
                }}
              />
            </div>
            <div className={page === 2 ? "" : "d-none"}>
              {" "}
              <p>경유지 이미지를 첨부해 주세요.(최대 6장)</p>
              <div
                style={{ width: "100%" }}
                className="my-2 d-flex flex-row flex-wrap"
              >
                {routeData.rs_pic ? (
                  routeData.rs_pic.split("|").map((pic, index) => {
                    const splitData = pic.split("?");
                    const fileName = splitData[0];
                    const fileLink = splitData[1];
                    return (
                      <div className="p-1" key={index}>
                        <div
                          className="rounded"
                          style={{
                            background: `url(${fileLink}) 50% 50% / cover no-repeat`,
                            width: 80,
                            height: 80,
                          }}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div
                    className="h1 d-flex flex-row justify-content-center align-items-center w-100"
                    style={{ height: 150 }}
                  >
                    <i className="far fa-images" />
                  </div>
                )}
              </div>
              <UploadImageToS3WithReactS3
                region={"route_sub/rs_pic"}
                multi={true}
                max={6}
                disabled={
                  routeData.rs_pic !== "" &&
                  routeData.rs_pic.split("|").length >= 5
                }
                callback={uploadImageCallback}
              />
            </div>
            <div className={page === 3 ? "" : "d-none"}>
              <p>경유지 위치를 입력하세요.</p>
              {apiReady && (
                <GuideTourRegistTravelRoutePlaceSearch
                  map={mapApi.map}
                  mapApi={mapApi.googleMaps}
                  changedMarker={changedMarker}
                />
              )}
              <div
                className="googleMap mt-3"
                style={{ width: "100%", height: 350 }}
              >
                <GoogleMap
                  bootstrapURLKeys={{
                    key: "AIzaSyAAlAXoCY_oMvtaNJGx6V8sxRkx7I8VrXw",
                    libraries: "places",
                  }}
                  defaultZoom={zoom}
                  defaultCenter={center}
                  yesIWantToUseGoogleMapApiInternals={true}
                  onGoogleApiLoaded={({ map, maps }) =>
                    handleApiLoaded(map, maps)
                  }
                />
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex flex-row justify-content-between">
            <button
              type="button"
              className={`btn btn-secondary ${page === 0 ? "d-none" : ""}`}
              onClick={() => handlePage("prev")}
            >
              이전
            </button>
            <div />
            <button
              type="button"
              className={`btn btn-primary ${page < 3 ? "" : "d-none"}`}
              onClick={() => handlePage("next")}
            >
              다음
            </button>
            <button
              type="button"
              className={`btn btn-primary ${page === 3 ? "" : "d-none"}`}
              onClick={() => handleSave()}
            >
              저장
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(GuideTourRegistTravelRouteModal);
