import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();
const countryList = codeList.codeCountry;

const mClassName = "ExperienceService";
let guideList = null;

export default function ExperienceService() {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [itemViewCount, setItemViewCount] = useState(4);

  useEffect(() => {
    handleItemViewCount();
    window.addEventListener("resize", handleItemViewCount);

    requestMainBestGuide();
    return () => {
      window.removeEventListener("resize", handleItemViewCount);
    };
  }, []);

  //스크린 너비에 따른 가이드 View 개수 조절
  const handleItemViewCount = useCallback(() => {
    let screenWidth = window.innerWidth;
    let changeViewCount = 0;
    //  모바일
    if (screenWidth < 720) {
      changeViewCount = 1.1;
    }

    // if (screenWidth >= 400 && screenWidth <= 720) {
    //   changeViewCount = 2.1;
    // }
    //  테블릿
    if (screenWidth >= 720 && screenWidth < 1140) {
      changeViewCount = 3.1;
    }

    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 5.1;
    }

    if (itemViewCount !== changeViewCount) {
      setItemViewCount(changeViewCount);
    }
  }, [itemViewCount]);

  //인기 가이드 불러오기
  function requestMainBestGuide() {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_GetMainBestGuideList.php",
      msgID: msgID,
      data: {},
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          guideList = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  }

  const GuideItemRender = useCallback(({ item }) => {
    return (
      <GuideItem
        item={item}
        gl_idx={item.gl_idx}
        gl_pic={item.gl_pic}
        u_name_full={item.u_name_full}
        gl_title={item.gl_title}
        gl_rating={item.gl_rating}
        gl_rating_cnt={item.gl_rating_cnt}
        gl_addr_country={item.gl_addr_country}
        gl_price_base={item.gl_price_base}
        gl_price_per_hour={item.gl_price_per_hour}
        gl_desc={item.gl_desc}
      />
    );
  }, []);

  return (
    <div className="container margin_60" id="popularFreeGuide">
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center mb-2">
        <div className="main_title text-center text-sm-left mb-2">
          <h2>
            {t("mainExperienceServiceTitleStart")}{" "}
            <span>{t("mainExperienceServiceTitleCenter")}</span>
          </h2>
          <p>{t("mainExperienceServiceDesc")}</p>
        </div>

        <div className="text-center" style={{ minWidth: 100 }}>
          <Link to="/main/MainSearchByMap" className="btn_1">
            {t("showAll")}
          </Link>
        </div>
      </div>
      {guideList !== null && (
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          dots={false}
          nav
          navText={[
            "<span key='prev' class='text-secondary'><i class=\"fa-solid fa-angle-left\"></i></span>",
            "<span key='next' class='text-secondary'><i class=\"fa-solid fa-angle-right\"></i></span>",
          ]}
          navClass={["moveBtn mr-1", "moveBtn ml-1"]}
          items={itemViewCount}
        >
          {guideList.map((item) => {
            return <GuideItemRender key={item.gl_idx} item={item} />;
          })}
        </OwlCarousel>
      )}
    </div>
  );
}

const GuideItem = React.memo(
  ({
    gl_idx,
    gl_pic,
    u_name_full,
    gl_title,
    gl_rating,
    gl_rating_cnt,
    gl_addr_country,
    gl_price_base,
    gl_price_per_hour,
    gl_desc,
  }) => {
    const { t } = useTranslation();
    let guideImage = "";

    if (gl_pic.indexOf("?") > -1) {
      guideImage = gl_pic.split("?")[1];
    } else {
      guideImage = codeList.publicVar.imgServerUrl + gl_pic;
    }

    return (
      <Link to={`/GuideDetail/${gl_idx}`} className="item">
        <div className="tour_container" style={{ minHeight: 300 }}>
          <div className="img_container">
            <div
              className="owl_carousel_img"
              style={{
                background: `url(${guideImage})50% 50%/cover no-repeat`,
              }}
            />

            <div className="short_info">
              <span className="price">
                <sup>&#8361;</sup> {util.addCommas(gl_price_base)}
              </span>
            </div>
          </div>
          <div className="tour_title">
            <p className="overflow-hidden text-truncate">
              {countryList.get(gl_addr_country) &&
                countryList.get(gl_addr_country).name_ko}
              · {u_name_full}
            </p>
            <p className="overflow-hidden text-truncate h6">{gl_title}</p>
            <p className="ellipsis_2">{gl_desc}</p>
            <div className="rating">
              <i
                className={
                  gl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <small>({gl_rating_cnt})</small>
            </div>
            <div>
              <p>
                {t("base")} &#8361; {util.addCommas(gl_price_base)}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
);
