import React from "react";
import { useTranslation } from "react-i18next";

export default function GuideRegistIntroCarousel() {
  const { t } = useTranslation();
  return (
    <section
      className="parallax-window"
      data-parallax="scroll"
      data-image-src="/images/applyVisualImage2.jpg"
      data-natural-width="1400"
      data-natural-height="500"
      style={{
        background: `url(/images/applyVisualImage2.jpg) 50% 50%/cover no-repeat`,
        boxShadow: " 0px -50px 62px 44px black inset",
      }}
    >
      <div
        className="parallax-content-1 opacity-mask"
        data-opacity-mask="rgba(0, 0, 0, 0.6)"
      >
        <div className="animated fadeInDown">
          <h1 className="mb-2">{t("guideRegistIntroCarouselTitle")}</h1>
          <p>{t("guideRegistIntroCarouselDesc")}</p>
        </div>
      </div>
    </section>
  );
}
