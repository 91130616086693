import React, { useCallback, useEffect, useMemo, useState } from "react";
import Nav from "../common/Nav";
import TourListCarousel from "./component/TourListCarousel";
import TourCountryAndKind from "./component/tourCountryAndKind/TourCountryAndKind";
import TourPopular from "./component/tourPopular/TourPopular";
import TourWithinHour from "./component/tourWithinHour/TourWithinHour";
import TourNew from "./component/tourNew/TourNew";
import Footer from "../common/Footer";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();

let tourKind = [];
let tourPopular = [];
let tourHour = [];
let tourNew = [];

export default function TourList({ match }) {
  const travelType = match.params.hasOwnProperty("travelType")
    ? match.params.travelType
    : "";
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const { t } = useTranslation();

  const requestTourList = (filters, type, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tourList/JS_tour_list_select.php",
      msgID: msgID,
      routeItem: {},
      travel_type: type,
      ...filters,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  useEffect(() => {
    if (travelType === "") {
      alert(t("inaccessibleMsg"));
      window.location.replace("/");
    }

    requestTourList({ take: 12, skip: 0 }, travelType, (newData) => {
      tourKind = [...newData["returnData"]];
      requestTourList(
        { take: 12, skip: 0, rl_rating: 3 },
        travelType,
        (newData) => {
          tourPopular = [...newData["returnData"]];
          requestTourList({ take: 6, skip: 0 }, travelType, (newData) => {
            tourHour = [...newData["returnData"]];

            let rl_edit_date = new Date();
            rl_edit_date = rl_edit_date.setDate(rl_edit_date.getDate() - 366);
            rl_edit_date = new Date(rl_edit_date);
            rl_edit_date = util.getDateToFormat(rl_edit_date, "YYYY-MM-DD");
            requestTourList(
              { take: 5, skip: 0, rl_edit_date: rl_edit_date },
              travelType,
              (newData) => {
                tourNew = [...newData["returnData"]];
                setIsNeedUpdate((prevState) => !prevState);
              }
            );
          });
        }
      );
    });
  }, [travelType]);

  return (
    <>
      <Nav />
      <main>
        <TourListCarousel match={match} tourListTitle={travelType} />
        <TourCountryAndKind match={match} routeItem={tourKind} />
        <TourPopular match={match} routeItem={tourPopular} />
        <TourWithinHour match={match} routeItem={tourHour} />
        <TourNew match={match} routeItem={tourNew} />
      </main>
      <Footer />
    </>
  );
}
