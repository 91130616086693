import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Nav from "../common/Nav";
import Footer from "../common/Footer";
import ReserveServiceMemo from "./component/ReserveServiceMemo";
import ReserveServiceRefundPolicy from "./component/ReserveServiceRefundPolicy";
import ReserveServiceInfo from "./component/ReserveServiceInfo";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import Loading from "../../lib/Loading";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "'ReserveService";
const checkValue = [
  "rrl_route_uid",
  "rrl_user_uid",
  "rrl_guide_uid",

  "rrl_start_date",
  "rrl_end_date",

  "rrl_guest_adult_num",
  "rrl_guest_child_num",
  "rrl_guest_baby_num",
  // "rrl_payment_method",

  "rrl_cost_guide",
  "rrl_cost_fees_guide",
  "rrl_cost_fees_guest",
  "rrl_cost_paid",
  "rrl_cost_total",
];
let isReserveSuccess = false;
let rl_idx = null;
let tourData = null;
let loginInfo = null;
let request_data = {
  rrl_route_uid: "", //경로 UID
  rrl_user_uid: "", //고객 UID
  rrl_guide_uid: "", //가이드 UID

  rrl_start_date: "", //예약 일시 2021-01-01 09:00:00
  rrl_end_date: "", //종료 일시 2021-01-01 09:00:00

  rrl_guest_adult_num: 1, //성인인원수
  rrl_guest_child_num: 0, //미성년자
  rrl_guest_baby_num: 0, //2세

  rrl_payment_method: "", //결제메서드(카드)
  rrl_payment_order_num: "", //주문번호

  rrl_guest_over_cnt: 0, // 추가 인원 수
  rrl_cost_over_guest: 0, // 추가 인원에 대한 요금

  rrl_cost_over_hour: 0, //시간 초과에 대한 추가요금

  rrl_cost_guide: 0, //가이드 비용
  rrl_cost_fees_guide: 0, //가이드 수수료
  rrl_cost_fees_guest: 0, //고객수수료
  rrl_cost_paid: 0, // 수수료제외 총금액(가이드에게 지불될 금액)

  rrl_cost_total: 0, //총 금액(결제액)

  rrl_memo_guest: "", //게스트 요청사항
  rrl_memo_guide: "", //가이드 요청사항
  rrl_cost_discount: 0, //쿠폰 할인금액
  rrl_coupon_uid: "", //쿠폰 UID
  rrl_status: "CR",
};
let card_list = null;

let dataLoad = true;

export default function ReserveService(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const { t } = useTranslation();

  //데이터 세팅
  (() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      loginInfo = loginData;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
      }
    );

    if (props.location.state.hasOwnProperty("rrl_cost_total")) {
      request_data = { ...request_data, ...props.location.state };
      request_data.rrl_start_date = props.location.state.rrl_date_reserve;
    }

    if (!props.match.params.hasOwnProperty("rl_idx") || !request_data) {
      codeList.Modal.current.alert(t("inaccessibleMsg"), () => {
        props.history.replace("/");
      });
    } else {
      rl_idx = props.match.params.rl_idx;
    }
  })();

  //데이터 세팅
  useEffect(() => {
    if (rl_idx && loginInfo) {
      // GET_ROUTE_SERVICE();
      requestRouteService((newData) => {
        if (!newData || newData["returnData"].length < 1) {
          codeList.Modal.current.alert(t("errorMsg"));
          return;
        }

        tourData = newData["returnData"][0];
        calculationPrice(() => {
          dataLoad = false;
          setIsNeedUpdate(!isNeedUpdate);
        });
      });
    }
    return () => {
      request_data = {
        rrl_route_uid: null, //경로 UID
        rrl_user_uid: null, //고객 UID
        rrl_guide_uid: null, //가이드 UID

        rrl_start_date: null, //예약 일시 2021-01-01 09:00:00
        rrl_end_date: null, //종료 일시 2021-01-01 09:00:00
        rrl_guest_adult_num: 1, //성인인원수
        rrl_guest_child_num: 0, //미성년자
        rrl_guest_baby_num: 0, //2세
        rrl_payment_method: null, //결제메서드(카드)
        rrl_payment_order_num: null, //주문번호
        rrl_cost_total: 0, //총 금액(결제액)
        rrl_cost_guide: 0, //가이드 비용
        rrl_guest_over_cnt: 0, // 추가 인원 수
        rrl_cost_over_guest: 0, // 추가 인원에 대한 요금
        rrl_cost_over_hour: 0, //시간 초과에 대한 추가요금

        rrl_memo_guest: "", //게스트 요청사항
        rrl_memo_guide: "", //가이드 요청사항
        rrl_cost_discount: 0, //쿠폰 할인금액
        rrl_coupon_uid: "", //쿠폰 UID
        rrl_cost_fees_guide: 0, //가이드 수수료
        rrl_cost_fees_guest: 0, //고객수수료
        rrl_cost_paid: 0, // 수수료 제외 총금액
      };

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, [loginInfo]);

  const requestRouteService = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_detail_select.php",
      msgID: msgID,
      rl_idx: Number(rl_idx),
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] && newData["returnData"].length) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"), () => {
            window.location.replace(window.location.href);
          });
        }
      }
    });
  };

  const calculationPrice = (callback) => {
    //가이드 수수료비용 계산
    let rrl_cost_guide =
      Number(tourData.rl_price_base) +
      Number(request_data.rrl_cost_over_guest) +
      Number(request_data.rrl_cost_over_hour);
    let rrl_cost_fees_guide = rrl_cost_guide * 0.2;
    let rrl_cost_paid = rrl_cost_guide - rrl_cost_fees_guide;

    //서비스 종료 일시 계산
    let rrl_end_date = new Date(request_data.rrl_start_date);
    let rl_base_hour_duration = tourData.rl_base_hour_duration.split(":");

    let hours = Number(rl_base_hour_duration[0]) * 3600000;
    let min = Number(rl_base_hour_duration[1]) * 60000;
    let sec = Number(rl_base_hour_duration[2]) * 1000;
    let duration = hours + min + sec;

    rrl_end_date.setTime(rrl_end_date.getTime() + duration);

    let year = rrl_end_date.getFullYear();
    let month = rrl_end_date.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    let date = rrl_end_date.getDate();
    if (date < 10) {
      date = "0" + date;
    }

    let rrl_hour = rrl_end_date.getHours();
    let rrl_min = rrl_end_date.getMinutes();
    if (rrl_min < 10) {
      rrl_min = "0" + rrl_min;
    }
    let rrl_sec = rrl_end_date.getSeconds();
    if (rrl_sec < 10) {
      rrl_sec = "0" + rrl_sec;
    }

    rrl_end_date = `${year}-${month}-${date} ${rrl_hour}:${rrl_min}:${rrl_sec}`;

    request_data = {
      ...request_data,
      rrl_route_uid: tourData.rl_route_uid, //경로 UID
      rrl_guide_uid: tourData.rl_guide_uid, //가이드 UID
      rrl_end_date: rrl_end_date,
      rrl_cost_guide: rrl_cost_guide,
      rrl_cost_fees_guide: rrl_cost_fees_guide,
      rrl_cost_paid: rrl_cost_paid,
    };

    if (loginInfo) {
      request_data = {
        ...request_data,
        rrl_user_uid: loginInfo.u_uid,
      };
    }

    callback && callback();
  };

  //예약 요청데이터 핸들러
  const handleRequestData = (key, value) => {
    if (!request_data.hasOwnProperty(key)) return;
    request_data = {
      ...request_data,
      [key]: value,
    };
  };

  // 채팅방 생성
  const createChatRoom = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_createChatRoom.php",
      msgID: msgID,
      ...data,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 문의요청 메세지 전송
  const sendInquiryMsg = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_sendInquiryMsg.php",
      msgID: msgID,
      crt_main_uid: data.crm_main_uid,
      crt_msg_uid: msgID,
      crt_type: data.hasOwnProperty("crt_type") ? data.crt_type : "9",
      crt_msg: data.crm_last_msg,
      crt_file: "",
      crt_sender_uid: loginInfo.u_uid,
      crt_del_yn: "N",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  // 예약 데이터 통신
  const insertReserveRouteList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserve/JS_reserve_list_insert.php",
      msgID: msgID,
      ...loginInfo,
      ...tourData,
      ...request_data,
      rrl_reserve_uid: msgID,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          const data = {
            crm_main_uid: util.makeUUIDv4(),
            crm_guide_uid: tourData.rl_guide_uid,
            crm_service_type: "R",
            crm_service_uid: tourData.rl_route_uid,
            crm_reserve_uid: newData.rrl_reserve_uid,
            crm_status: "CR",
            crm_last_msg: `[${tourData.rl_title}]에 대한 예약요청이 도착했습니다.`,
            crm_last_time: new Date(),
            crm_last_u_uid: loginInfo.u_uid,
            crm_creator_uid: loginInfo.u_uid,
            crm_create_date: new Date(),
            crm_update_date: new Date(),
          };

          createChatRoom(data, (newData) => {
            sendInquiryMsg(data, (newData) => {
              if (socketMsg.rrl_memo_guest.trim() !== "") {
                sendInquiryMsg(
                  {
                    ...data,
                    crt_type: "1",
                    crm_last_msg: socketMsg.rrl_memo_guest,
                  },
                  (newData) => {
                    isReserveSuccess = true;
                    setIsLoading(false);
                  }
                );
                return;
              }

              isReserveSuccess = true;
              setIsLoading(false);
            });
          });
        } else {
          setIsLoading(false);
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  //예약 요청
  const handleInsertReserveRequest = () => {
    for (let i = 0; i < checkValue.length; i++) {
      let key = checkValue[i];
      if (request_data.hasOwnProperty(key)) {
        if (request_data[key] === "") {
          codeList.Modal.current.alert(t("missingInputMsg"));
          return;
        }
      }
    }
    setIsLoading(true);
    insertReserveRouteList();
    // INSERT_RESERVE_ROUTE_LIST();
  };

  return (
    <>
      {isLoading && <Loading />}
      <Nav />
      {!dataLoad && (
        <main className="white_bg pb-5 min-vh-100">
          <div className="container nav_margin_container">
            <div className="row pb-2 mb-3">
              <div className="col">
                <p className="h3">
                  <span
                    className="cursor_pointer"
                    onClick={() => props.history.goBack()}
                  >
                    <i className="fa-solid fa-angle-left" />
                  </span>
                  <span>{t("requestReservation")}</span>
                </p>
              </div>
            </div>

            <div className="row">
              <div className="mt-4 mt-md-0 order-last order-xl-first col-xl-8">
                <div
                  className={`border p-3 rounded-lg bg-light flex-column justify-content-center align-items-center h-100 ${
                    isReserveSuccess ? "d-flex" : "d-none"
                  }`}
                >
                  <p className="h4">{t("successReservationNote")}</p>
                  <p className="h5">{t("successReservationNoteDesc")}</p>
                  <div className="d-flex flex-row justify-content-center">
                    <Link to={"/TourList"} className="btn_1 bg-buddible mr-1">
                      {t("shortcuts")}
                    </Link>
                    <Link to={"/MyPage"} className="btn_1 bg-secondary mr-1">
                      {t("myPage")}
                    </Link>
                  </div>
                </div>
                <div className={!isReserveSuccess ? "d-block" : "d-none"}>
                  <ReserveServiceMemo handleRequestData={handleRequestData} />
                  <ReserveServiceRefundPolicy request_data={request_data} />
                  <div className="py-3">
                    <span
                      className="btn_1 bg-buddible"
                      onClick={() => handleInsertReserveRequest()}
                    >
                      {t("requestReservation")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="order-first order-xl-last col-xl-4">
                <ReserveServiceInfo
                  tourData={tourData}
                  request_data={request_data}
                />
              </div>
            </div>
          </div>
        </main>
      )}
      <Footer />
    </>
  );
}
