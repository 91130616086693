import React from "react";
import { useTranslation } from "react-i18next";
export default function CommunityCarousel(props) {
  const { t } = useTranslation();
  let selectedImage = "/images/paraglider-sunset.jpg";

  return (
    <section
      className="parallax-window"
      data-parallax="scroll"
      data-image-src="./img/parallax_bg_1.jpg"
      data-natural-width="1400"
      data-natural-height="470"
      style={{
        background: `url('${selectedImage}')50% 50% /cover no-repeat`,
      }}
    >
      <div
        className="parallax-content-1 opacity-mask"
        data-opacity-mask="rgba(0, 0, 0, 0.6)"
      >
        <div className="animated fadeInDown">
          <h1>{t("communityMainCarouselTitle")}</h1>
          <p>{t("communityMainCarouselDesc")}</p>
        </div>
      </div>
    </section>
  );
}
