import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

let guideList = null;
let viewItem = 1;

export default function BestTour() {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    requestTourBest();
  }, []);

  //베스트 가이드 받아오기
  const requestTourBest = () => {
    let msgID = util.makeUUIDv4();

    let socketMsg = {
      file: "/tour/JS_tour_detail_list_select.php",
      msgID: msgID,
      data: {},
      take: 12,
      skip: 0,
      rl_rating: 5,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["returnData"].length) {
          guideList = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //서비스 더 보기
  const handleViewItem = () => {
    viewItem = viewItem + 1;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //경로 서비스 랜더
  const RouteItemRender = useCallback(({ item }) => {
    return (
      <RouteItem
        rl_pics={item.rl_pics}
        rl_price_base={item.rl_price_base}
        rl_area_cd={item.rl_area_cd}
        rl_idx={item.rl_idx}
        rl_name={item.rl_name}
        rl_title={item.rl_title}
        rl_rating={item.rl_rating}
        rl_rating_cnt={item.rl_rating_cnt}
        rl_guest_min={item.rl_guest_min}
        rl_over_pay_people={item.rl_over_pay_people}
        rl_desc={item.rl_desc}
      />
    );
  }, []);

  return (
    <div className="white_bg">
      <div className="container margin_60" id="bestGuide">
        <div className="main_title text-center text-sm-left mb-2">
          <h2>
            {t("mainBestTourStart")} <span>{t("mainBestTourCenter")}</span>{" "}
            {t("mainBestTourEnd")}
          </h2>
          <p>{t("mainBestTourDesc")}</p>
        </div>

        <div className="row">
          {guideList !== null &&
            guideList.slice(0, viewItem * 4).map((item, index) => {
              return (
                <RouteItemRender
                  key={`${item.rl_idx}_${index}`}
                  item={item}
                  index={index}
                />
              );
            })}
        </div>

        <p className="text-center add_bottom_30">
          <span
            className={`btn_1 ${viewItem < 3 ? "d-inline-block" : "d-none"}`}
            onClick={() => handleViewItem()}
          >
            {t("showMore")}
          </span>
          <Link
            to="/TourList"
            className={`btn_1 ${viewItem === 3 ? "d-inline-block" : "d-none"}`}
          >
            {t("showAll")}
          </Link>
        </p>
      </div>
    </div>
  );
}

const RouteItem = React.memo(
  ({
    rl_pics,
    rl_price_base,
    rl_area_cd,
    rl_idx,
    rl_name,
    rl_title,
    rl_rating,
    rl_rating_cnt,
    rl_guest_min,
    rl_over_pay_people,
    rl_desc,
  }) => {
    const { t } = useTranslation();

    let images = null;
    if (rl_pics) {
      images = rl_pics.split("|");
    }

    return (
      <div className="col-lg-3 col-md-6">
        <div
          className="tour_container overflow-hidden"
          style={{ minHeight: 400 }}
        >
          <div className="ribbon_3 popular">
            <span>Best</span>
          </div>
          <div className="img_container_2 position-relative owl-nav-custom-center">
            {images !== null && (
              <OwlCarousel
                className="owl-theme"
                loop
                nav={images.length > 1}
                navText={[
                  "<span key='prev' class='text-secondary'><i class=\"fa-solid fa-angle-left\"></i></span>",
                  "<span key='next' class='text-secondary'><i class=\"fa-solid fa-angle-right\"></i></span>",
                ]}
                dots={false}
                items={1}
              >
                {images.map((img) => {
                  let imageUrl = "";
                  if (img.indexOf("?") > -1) {
                    imageUrl = img.split("?")[1];
                  } else {
                    imageUrl = codeList.publicVar.imgServerUrl + img;
                  }

                  return (
                    <div key={img} className="item">
                      <div
                        className="owl_carousel_img"
                        style={{
                          background: `url(${imageUrl})50% 50%/cover no-repeat`,
                        }}
                      />
                    </div>
                  );
                })}
              </OwlCarousel>
            )}
            <div className="short_info">
              <i className="icon_set_1_icon-44" />
              {rl_area_cd}
              <span className="price">
                <sup>&#8361;</sup> {util.addCommas(rl_price_base)}
              </span>
            </div>
          </div>

          <Link to={`/TourDetail/${rl_idx}`}>
            <div className="tour_title">
              <span>{rl_name ? rl_name : "가이드 명"}</span>
              <h3>
                <strong>{rl_title}</strong>
              </h3>
              <div className="rating">
                <i
                  className={
                    rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <small>({rl_rating_cnt})</small>
              </div>
              <span>
                {t("base")} {rl_guest_min ? rl_guest_min : "1"} {t("person")}{" "}
                &#8361;
                {util.addCommas(rl_price_base)} /
              </span>
              <span>
                {" "}
                {t("addedPerson")} &#8361;
                {util.addCommas(rl_over_pay_people)}
              </span>
            </div>
            <div className="tour_desc">
              <p className="ellipsis_2">{rl_desc}</p>
            </div>
          </Link>
        </div>
      </div>
    );
  }
);
