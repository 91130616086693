import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();

let counter = {
  travel_cnt: 0,
  nightlife_cnt: 0,
  rest_cnt: 0,
  recreation_cnt: 0,
};

export default function TravelKind() {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    requestCountTourKind();
  }, []);

  const requestCountTourKind = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_count_select.php",
      msgID: msgID,
      data: {},
      rl_confirm_admin: "Y",
      rl_use_yn: "Y",
      rl_del_yn: "N",
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length) {
            counter = newData["returnData"][0];
            setIsNeedUpdate(!isNeedUpdate);
          }
        }
      }
    });
  };

  return (
    <div className="white_bg">
      <div className="container margin_60">
        <div className="main_title text-left">
          <h2>
            {t("mainTravelKindStart")}
            <span> {t("mainTravelKindCenter")}</span>
            {t("mainTravelKindEnd")}
          </h2>
          <p>{t("mainTravelKindDesc")}</p>
        </div>

        <div className="row small-gutters categories_grid">
          <div className="col-sm-12 col-md-6">
            <Link to="/TourList/hotPlace">
              <div
                className="w-100 h-100 categories_grid_img"
                style={{
                  background: `url(/images/hotplace.jpg)50% 50%/cover no-repeat`,
                }}
              />
              <div className="wrapper">
                <h2>{t("hotPlace")}</h2>
                <p>{counter.travel_cnt} Services</p>
              </div>
            </Link>
          </div>
          <div className="col-sm-12 col-md-6">
            <div className="row small-gutters mt-md-0 mt-sm-2">
              <div className="col-sm-6">
                <Link to="/TourList/experience">
                  <div
                    className="w-100 h-100 categories_grid_img"
                    style={{
                      background: `url(/images/experience.jpg)50% 50%/cover no-repeat`,
                    }}
                  />
                  <div className="wrapper">
                    <h2>{t("experience")}</h2>
                    <p>{counter.nightlife_cnt} Services</p>
                  </div>
                </Link>
              </div>
              <div className="col-sm-6">
                <Link to="/TourList/food">
                  <div
                    className="w-100 h-100 categories_grid_img"
                    style={{
                      background: `url(/images/food.jpg)50% 50%/cover no-repeat`,
                    }}
                  />
                  <div className="wrapper">
                    <h2>{t("food")}</h2>
                    <p>{counter.rest_cnt} Services</p>
                  </div>
                </Link>
              </div>
              <div className="col-sm-12 mt-sm-2">
                <Link to="/TourList/cultureAndShopping">
                  <div
                    className="w-100 h-100 categories_grid_img"
                    style={{
                      background: `url(/images/culture.jpg)50% 50%/cover no-repeat`,
                    }}
                  />
                  <div className="wrapper">
                    <h2>{t("cultureAndShopping")}</h2>
                    <p>{counter.recreation_cnt} Services</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
