import React, { useEffect, useRef, useState } from "react";
import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import CommunityManageTable from "./CommunityManageTable";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "CommunityManage";

const POST_TYPE = [
  { key: "NOTICE", desc: "공지사항" },
  { key: "GUIDE", desc: "버디 팁" },
  { key: "GUEST", desc: "여행자 팁" },
  { key: "NEWS", desc: "새소식" },
  { key: "FORUM", desc: "커뮤니티 포럼" },
];

export default function CommunityManage(props) {
  const $SEARCH_WORD = useRef();
  const $SEARCH_LEVEL = useRef();
  const controller = {
    cm_type: useRef(),
    cm_show_yn: useRef(),
    cm_admin_yn: useRef(),
  };

  const addNewData = (e) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_COMMUNITY_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $SEARCH_WORD.current.value,
      SEARCH_LEVEL: $SEARCH_LEVEL.current.value,
      cm_type: controller.cm_type.current.value,
      cm_show_yn: controller.cm_show_yn.current.value,
      cm_admin_yn: controller.cm_admin_yn.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_COMMUNITY_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {/*필터 영역*/}
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                {/*간편기간 영역*/}

                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div
                    className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                    role="group"
                  >
                    <button
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      data-target="#CommunityEditModal"
                      onClick={() => {
                        addNewData();
                      }}
                      alt={"신규등록"}
                      aria-label={"신규등록"}
                      type="button"
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="flex-grow-1 d-flex flex-row justify-content-end order-1 order-md-1 order-lg-1  mb-2 md-sm-0">
                    <select
                      className="custom-select form-control mr-2"
                      ref={controller.cm_type}
                      onChange={() => searchFilterChanged()}
                      style={{ width: 150 }}
                    >
                      <option value="">-게시글 분류-</option>
                      {POST_TYPE.map((menu, index) => {
                        return (
                          <option key={menu.key} value={menu.key}>
                            {menu.desc}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      className="custom-select form-control mr-2"
                      ref={controller.cm_show_yn}
                      onChange={() => searchFilterChanged()}
                      style={{ width: 120 }}
                    >
                      <option value="">-공개여부-</option>
                      <option value="Y">공개</option>
                      <option value="N">비공개</option>
                    </select>
                    <select
                      className="custom-select form-control mr-2"
                      ref={controller.cm_admin_yn}
                      onChange={() => searchFilterChanged()}
                      style={{ width: 120 }}
                    >
                      <option value="">-게시자 분류-</option>
                      <option value="Y">관리자</option>
                      <option value="N">일반</option>
                    </select>
                    <div className="input-group">
                      <select
                        className="custom-select form-control"
                        ref={$SEARCH_LEVEL}
                        onChange={() => searchFilterChanged()}
                        style={{ minWidth: 120 }}
                      >
                        <option value="">-검색조건-</option>
                        <option value="cm_title">제목</option>
                        <option value="cm_cub_title">부제목</option>
                        <option value="cm_content">내용</option>
                      </select>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        style={{ minWidth: 150 }}
                        onKeyPress={(e) => {
                          // console.log(e);
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={$SEARCH_WORD}
                      />

                      <button
                        className="input-group-text btn btn-primary"
                        style={{
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                          borderLeft: 0,
                        }}
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <CommunityManageTable {...props} />
          </div>
        </div>
      </div>
    </div>
  );
}
