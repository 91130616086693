import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CodeList from "../../lib/CodeList";
import { useTranslation } from "react-i18next";
import MainLogoSVG from "../../lib/MainLogoSVG";

const codeList = new CodeList();

let setting_language = null;

export default function Footer() {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    let codeLanguage = codeList.codeLanguage;
    let setLanguage = [];
    if (codeLanguage) {
      codeLanguage = [...codeLanguage];
      codeLanguage.forEach((lang) => {
        let language = lang[1];
        setLanguage.push({
          id: language.id,
          name: language.name,
          name_ko: language.name_ko,
          level: "",
          glg_use_yn: "N",
        });
      });
    }
    if (Array.isArray(setLanguage) && setLanguage.length > 0) {
      setting_language = setLanguage;
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  const LanguageItemRender = useCallback(({ item }) => {
    return (
      <LanguageItem id={item.id} name={item.name} name_ko={item.name_ko} />
    );
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="revealed">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="py-3">
                <MainLogoSVG width={220} fill="#ffffff" />
              </div>
              <div className="d-flex flex-column flex-xl-row">
                <p>상호: ㈜플레이영종</p>
                <p className="d-none d-xl-inline-block px-1">|</p>
                <p>대표이사:김도윤</p>
              </div>

              <p>사업자등록번호 : 810-87-02575</p>
              <p>통신판매업신고: 제2021-인천중구-0847호</p>
              <div className="d-flex flex-column flex-xl-row">
                <p>
                  고객센터 : <a href="tel://032-747-0211">032-747-0211</a>
                </p>
                <p className="d-none d-xl-inline-block px-1">|</p>
                <p>
                  e-mail :{" "}
                  <a href="mailto:playyeongjong@gmail.com">
                    playyeongjong@gmail.com
                  </a>{" "}
                </p>
              </div>
              <p>주소 : 인천광역시 중구 흰바위로59번길8, 219호(운서동)</p>
              {/*            <h3 className="mb-2">{t("customerCenter")}</h3>
              <a href="tel://032-747-0211" id="phone">
                032-747-0211
              </a>
              <a href="mailto:cardlogis@gmail.com" id="email-footer">
                cardlogis@gmail.com
              </a>*/}
            </div>
            <div className="col-md-7">
              <div className="row">
                <div className="col-md-4">
                  <h3 className="mb-2">{t("hotPlace")}</h3>
                  <ul>
                    <li>
                      <Link to="/TourList/hotPlace">{t("hotPlace")}</Link>
                    </li>
                    <li>
                      <Link to="/TourList/hotPlace">{t("experience")}</Link>
                    </li>
                    <li>
                      <Link to="/TourList/food">{t("food")}</Link>
                    </li>
                    <li>
                      <Link to="/TourList/cultureAndShopping">
                        {t("cultureAndShopping")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/TourDetailList">{t("searchTour")}</Link>
                    </li>
                    <li>
                      <Link to="/SearchMap">{t("myLocation")}</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h3 className="mb-2">{t("community")}</h3>
                  <ul>
                    <li>
                      <Link to="/CommunityMain">{t("communityAll")}</Link>
                    </li>
                    <li>
                      <Link to="/CommunityList/NOTICE">{t("notice")}</Link>
                    </li>
                    <li>
                      <Link to="/CommunityList/NEWS">{t("news")}</Link>
                    </li>
                    <li>
                      <Link to="/CommunityList/GUIDE">{t("guideTip")}</Link>
                    </li>
                    <li>
                      <Link to="/CommunityList/GUEST">{t("guestTip")}</Link>
                    </li>
                    <li>
                      <Link to="/CommunityList/FORUM">
                        {t("communityForum")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/FAQ/FAQ01">{t("faq")}</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <h3 className="mb-2">{t("termsAndPolicies")}</h3>
                  <ul>
                    <li>
                      <Link to="/">{t("privacyPolicy")}</Link>
                    </li>
                    <li>
                      <Link to="/">{t("termsOfUse")}</Link>
                    </li>
                    <li>
                      <Link to="/">{t("refundPolicy")}</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div id="social-footer">
                <ul className="border-bottom border-white pb-2 mb-2">
                  <li className="text-center mb-0">
                    <a
                      href="https://www.youtube.com/@PlayYeongjong"
                      target="_blank"
                    >
                      <i className="icon-youtube-play" />
                    </a>
                  </li>
                </ul>
                <p className="text-center">
                  <Link to="/">개인정보 처리방침 및 이용동의약관</Link>{" "}
                  <span className="d-none d-sm-inline mx-2"> | </span>
                  <Link to="/">서비스이용약관</Link>
                </p>
                <p className="text-center">
                  © Copyright{" "}
                  <span className="font-weight-bold">PLAYYEONGJONG</span>. All
                  Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div id="toTop" onClick={scrollToTop} />
    </>
  );
}

const LanguageItem = React.memo(({ id, name, name_ko }) => {
  return (
    <option value={id}>
      {name} - {name_ko}
    </option>
  );
});
