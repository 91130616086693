import React from "react";

export default function MainLogoSVG({
  width = 160,
  fill = "#004060",
  isShadow = false,
}) {
  let style = {};
  if (isShadow) style.filter = "drop-shadow(0px 2px 4px rgba(0, 0 ,0 ,0.25)";

  return (
    <svg
      id="mainLogo"
      data-name="Main Logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1799.85 228.99"
      width={width}
      fill={fill}
      style={style}
    >
      <g>
        <path
          d="M296.44,541.19A114.72,114.72,0,0,1,67,541c0-63.16,51.42-114.23,114.9-114.21A114.24,114.24,0,0,1,296.44,541.19ZM73,542c.22,4.43.45,9.65,1,14.89.43,4,1.56,4.15,4.9,2.61Q129.56,536.11,180.32,513q40.49-18.56,81-37.14c5.59-2.57,5.62-2.74,1.61-7-26-27.88-58-40-95.78-35.25C113.18,440.39,73,486.87,73,542Zm186.77-56.05c-1.9,1.29-2.67,1.75-3.38,2.29Q199.29,532.12,142.17,576c-2.57,2-1.9,2.89.07,4.63q24.33,21.42,48.54,43c5.7,5,11.41,10.08,17.43,15.4C225.4,588,242.39,537.53,259.76,485.94Zm9.31-9.16c-19,56.38-37.75,112.1-56.7,168.38C285.13,624.09,314,536.27,269.07,476.78ZM208.38,646.42a29.78,29.78,0,0,1-2.8-1.83Q182,623.67,158.4,602.73c-2.9-2.57-5.86-6.8-9-7s-5.76,4.68-8.66,7.26c-3.44,3.07-5.75,2.83-8.08-1.09-5-8.38-10-16.72-14.76-25.23a8.34,8.34,0,0,0-6.54-4.59c-9.91-1.69-19.79-3.54-29.68-5.35-6.26-1.15-6.43-1.1-4.38,5,15,44.65,45.82,70.59,92.45,77.33C182.14,650.9,194.66,650.07,208.38,646.42ZM89.12,561.22l-.13.9c7.79,1.45,15.61,2.82,23.38,4.41a10.62,10.62,0,0,0,8.39-1.76q47.28-29.92,94.68-59.63c1.44-.91,2.81-1.91,4.21-2.87l-.35-.65Zm114-39.83-.54-.81c-2.49,1.55-5,3.1-7.47,4.66q-35,22-70,44.13c-1.23.78-4,1-2.44,3.73,2.83,4.87,5.7,9.71,8.56,14.57a9,9,0,0,0,0-4.73c-1-4-.16-6.76,3.65-9.28,6.5-4.29,12.45-9.4,18.64-14.16Zm-65.56,63.67c.42,3.05.79,6,1.23,8.93a7.85,7.85,0,0,0,.61,1.62C146.18,590.82,146,589.78,137.52,585.06Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M370.13,540.45q0-30.17,0-60.34c0-2.41-.14-4.22,3.38-4.18,19.1.22,38.22-.45,57.28.42,30,1.37,45.32,30.11,30.78,56.57-6,11-15.33,16.87-27.61,17.37-13.81.56-27.66.42-41.49.27-3.82,0-4.79,1.17-4.75,4.85.18,15.3,0,30.62.14,45.92,0,3.12-.83,4.34-4,4-4.43-.47-10.38,1.9-12.92-1-2.2-2.48-.74-8.27-.75-12.59Q370.09,566.12,370.13,540.45Zm17.65-26.92c0,6.3.11,12.59,0,18.88-.07,2.74.85,3.6,3.6,3.56,10.81-.15,21.62-.05,32.42-.11a35.58,35.58,0,0,0,9.76-1.49c11.47-3.34,16.16-10.86,15.05-24.11-.9-10.73-7.36-17.26-18.52-18.5-13-1.44-26.1-.33-39.14-.72-2.83-.08-3.17,1.37-3.14,3.61C387.83,501,387.78,507.24,387.78,513.53Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M678.4,605.29c-5.79,0-11.33-.12-16.86.06-2.67.09-2.77-1.86-3.32-3.44-3.65-10.62-7.33-21.23-10.77-31.93-.84-2.59-2-3.47-4.73-3.44q-21.87.21-43.75,0c-2.87,0-4.18.83-5.12,3.56-3.67,10.62-7.74,21.1-11.34,31.73-1,2.86-2.32,3.66-5.14,3.53-4.49-.21-9-.05-13.52-.05-.68-1.57.27-2.71.71-3.86q22.85-60.78,45.69-121.55c1.15-3.07,2.7-4.16,5.9-3.95,4.19.26,8.42.15,12.63,0a3.51,3.51,0,0,1,4,2.69Q655.29,541,677.93,603.27A18.45,18.45,0,0,1,678.4,605.29Zm-56.9-108.4-1,.07q-9.63,26.3-19.3,52.59c-.88,2.38,0,2.73,2.19,2.71,8.86-.07,17.72,0,26.58,0,10.61,0,10.63,0,7.16-10.09Q629.33,519.52,621.5,496.89Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1393.17,585c-7.85,10.76-17.24,18.6-30.09,21.53-20.14,4.59-39.21,3.09-55.48-11.22-10.11-8.9-15.21-20.72-17.81-33.6a102.38,102.38,0,0,1,.7-45.51c7.88-31.69,33.08-46.48,63.72-43.84,15.26,1.32,28.59,6.6,38.35,19,5,6.39,8.48,14.55,9.2,21.58a1.21,1.21,0,0,1-.42.16c-3.45.06-7.07.79-10.3,0-3.73-.92-2.87-5.41-4.32-8.18-7.38-14.1-19.34-21.25-34.78-22.05-28.53-1.48-46.57,14.52-50.59,44.2-1.85,13.63-2.26,27.23,2.74,40.41,13.73,36.21,52.27,36.4,71,21.54,12.22-9.69,16.06-23,16.57-37.78.09-2.91-1.78-2.57-3.58-2.57-11.73,0-23.46-.19-35.17.1-4,.1-5.23-1-5-5,.24-3.32-.31-5.84,4.72-5.72,15.48.37,31,.22,46.45.07,3.39,0,4.71.74,4.67,4.44-.17,19.51-.26,39,0,58.53.08,5.5-3.32,3.86-6.14,4.19-3.32.38-4.86-.47-4.61-4.28C1393.44,595.75,1393.17,590.38,1393.17,585Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1856.14,585c-7.75,10.88-17.27,18.82-30.27,21.67-22.26,4.88-42.89,2.58-59.18-15.35-8.42-9.26-12.43-20.68-14.44-32.88a103.39,103.39,0,0,1,1.71-43.69c7.73-29.33,32-45.15,63.5-42.3,15.44,1.4,28.84,6.83,38.53,19.53a52.14,52.14,0,0,1,8.67,18.62c.63,2.36,0,3-2.23,2.8-.6-.05-1.2,0-1.8,0-7.38,0-7.24,0-10.3-6.82-6.77-15.06-19-22.61-35-23.6-26.37-1.64-43.55,11.52-49.45,37.26-3.77,16.49-4.6,33,2,49.07,14.65,35.61,55,34.76,73.08,17.43,10.15-9.72,13.23-22.08,13.81-35.49.13-3-1.89-2.48-3.61-2.48-12,0-24.05-.21-36.07.1-4.16.11-4.29-1.86-4-4.84.22-2.71-1.26-5.94,4-5.84,15.92.31,31.86.19,47.79.05,3.17,0,4.07,1,4,4.13q-.18,29.48,0,59c0,3-.66,4.53-3.95,4.07a17.69,17.69,0,0,0-4.05,0c-2.24.22-2.79-.81-2.76-2.87C1856.21,596.64,1856.14,590.8,1856.14,585Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1262.34,587.38v-4.81c0-34.21.06-68.42-.08-102.63,0-3.59,1-4.52,4.5-4.42,7.92.22,7.93,0,7.93,8.18,0,38.86-.07,77.72.1,116.58,0,4.21-1.31,4.83-5.23,5.21-7,.67-10.84-2-14.63-7.9-21.73-33.63-44-66.93-66.06-100.33-.79-1.19-1.27-2.67-3.5-3.77V499c0,33.9-.07,67.81.08,101.72,0,3.78-1,4.83-4.75,4.7-7.67-.27-7.68-.06-7.68-7.92,0-39,.06-78-.09-117,0-3.92,1-4.71,4.89-5,6.83-.58,10.61,1.94,14.32,7.66,22,33.84,44.41,67.37,66.7,101,.82,1.23,1.69,2.41,2.54,3.62Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1648.58,493.58v4.77c0,33.92,0,67.84.08,101.76,0,3.83-.56,5.54-5,5.31-7.58-.41-7.6-.11-7.6-7.6,0-39.18,0-78.35-.08-117.52,0-3.63.83-4.5,4.59-4.89,7.19-.74,11.13,2,15,8,21.77,33.62,44.08,66.9,66.21,100.29.88,1.33,1.83,2.6,3.58,5.07,0-2.87,0-4.44,0-6,0-34.07.06-68.14-.07-102.21,0-3.7.5-5.31,4.79-5.09,7.79.4,7.81.1,7.81,7.83q0,58.76.06,117.52c0,3.35-.72,4.23-4.32,4.66-7.56.91-11.71-2-15.78-8.34-21.56-33.4-43.67-66.45-65.59-99.62l-2.74-4.1Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1622.6,540.08a82.34,82.34,0,0,1-5.42,31.59c-10.33,26.68-34.1,39.35-61.93,36.83-27.33-2.47-48.22-21.67-52.77-54.31-2.25-16.19-.9-32,5.53-47.19,10.33-24.33,33.92-37.43,60.26-34.7,29.42,3,48.35,23,53.25,52.91A82.78,82.78,0,0,1,1622.6,540.08Zm-12.18.18a103.27,103.27,0,0,0-2.45-20.5c-5.5-22.13-21.75-36.28-42.53-36.95-24.17-.78-41.09,11-48,33.81a81.67,81.67,0,0,0,.54,50c6.36,19.41,22.08,31.12,41.55,31.49,22.67.42,38.27-9.65,46-29.67C1609.07,559.37,1610.15,549.89,1610.42,540.26Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1039,541.18c.25-12.39,2-23.72,6.66-34.48,10.54-24.12,33.78-37.07,60-34.42,30.46,3.07,50,24.33,53.91,56.81,1.75,14.66.46,29-5,42.87-10.47,26.6-34,39-61.69,36.55s-47.18-21.72-52.38-51.09A126.41,126.41,0,0,1,1039,541.18Zm108.91-.77a82.5,82.5,0,0,0-2.86-22.23c-5.9-21.45-22-34.81-42.4-35.38-23.46-.66-40.17,10.61-47.26,32.4a80.2,80.2,0,0,0,.26,51.69c6.85,20.16,22.37,31.15,43.49,31.25,21.43.1,37.13-10.8,44.34-30.81A80.6,80.6,0,0,0,1147.9,540.41Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M947,540.46c0-20.11.06-40.22-.07-60.32,0-3.32.56-4.66,4.36-4.63q39,.27,78,0c4.49,0,4.28,2.14,4.21,5.16s.68,5.71-4.3,5.65c-21.79-.28-43.59-.05-65.38-.19-3.42,0-4.64.74-4.57,4.42.24,12.6.26,25.21,0,37.81-.08,4,1.33,4.62,4.89,4.59,19.08-.16,38.18-.1,57.27,0,1.59,0,4.11-1,4.61,1.3a15.22,15.22,0,0,1-.2,8.41c-.5,1.33-2.45.67-3.74.67-19.24,0-38.49.17-57.73-.09-4.32-.05-5.15,1.36-5.08,5.3q.33,20.93,0,41.86c-.06,3.65,1.11,4.45,4.55,4.43,22.25-.14,44.5-.09,66.75,0,1.51,0,3.84-.93,4.38,1a17.58,17.58,0,0,1,0,8.88c-.26,1-1.87.61-2.88.61-27.21,0-54.42,0-81.63.1-3.47,0-3.43-1.69-3.43-4.15Q947,570.84,947,540.46Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M666,476c6.93,0,12.77,0,18.61,0,2.56,0,3.1,2.07,4.06,3.66q16.23,27,32.41,54c.74,1.25,1.54,2.46,2.34,3.72,1.77-1,2.25-2.66,3.08-4,10.66-17.73,21.36-35.44,31.83-53.29,1.85-3.18,3.94-4.37,7.5-4.11a111.74,111.74,0,0,0,11.72,0c3-.09,3.9.59,2,3.43-3.5,5.24-6.8,10.61-10.17,15.93Q752,522.67,734.6,550a11.74,11.74,0,0,0-2,6.72c.08,14.85,0,29.71.1,44.56,0,3.06-.67,4.37-4,4.09a53.37,53.37,0,0,0-9.46,0c-3.46.3-4.6-.73-4.55-4.39.23-14.55,0-29.11.14-43.67a13.21,13.21,0,0,0-2.16-7.55Q690.84,515.43,669.07,481C668.21,479.65,667.38,478.26,666,476Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M491.42,533.12c0,17.4.12,34.8-.09,52.2,0,3.83,1.14,4.72,4.81,4.68,18.48-.18,37,0,55.44-.16,3.55,0,5,.61,4.9,4.57-.19,10.88,0,10.89-11.08,10.89-22.39,0-44.78-.09-67.16.08-3.58,0-4.44-1-4.43-4.46q.19-60.28,0-120.58c0-3.69,1.16-4.68,4.58-4.39a57.53,57.53,0,0,0,9,0c3.15-.24,4.13.93,4.1,4.07-.14,14.1-.06,28.2-.06,42.3Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M832.28,476.33c10.12-3.31,15.44,1,20.19,9.53,9.78,17.53,20.77,34.39,31.29,51.55,1.77-.8,2.23-2.49,3.06-3.81,11.34-18,22.71-36,33.85-54.19,1.83-3,3.77-4.21,7.19-3.87a88.8,88.8,0,0,0,8.95.06c-.15,2.21-1.42,3.38-2.28,4.72q-20.79,32.37-41.7,64.67a13.67,13.67,0,0,0-2.32,8c.11,16,0,32.11.13,48.17,0,3.37-.88,4.33-4.27,4.25-8.27-.18-8.27,0-8.27-8.19,0-14.56-.06-29.12,0-43.68a12.61,12.61,0,0,0-2.07-7.13q-20.52-32.55-40.89-65.18C834.17,479.58,833.22,477.91,832.28,476.33Z"
          transform="translate(-67.01 -426.74)"
        />
        <path
          d="M1487.85,529.15c-.36,13.51.78,30.17-.49,46.79-2,26.61-18.93,34.29-40.82,32.62-16.9-1.29-26.62-11.54-28.79-29.15-.5-4-.65-8.06-1-12.09-.2-2.16.37-3.16,2.89-3.29,9.5-.48,9.52-.58,9.73,8.86a37.8,37.8,0,0,0,1.87,11.48c2.25,6.64,6.79,10.86,13.67,12.34a36.15,36.15,0,0,0,14.76,0c9.15-1.81,13-8.4,14.74-16.72a58.06,58.06,0,0,0,1-12.07c0-29.25,0-58.5-.11-87.75,0-3.72.86-4.83,4.66-4.69,7.88.29,7.89.06,7.89,7.76Z"
          transform="translate(-67.01 -426.74)"
        />
      </g>
    </svg>
  );
}
