import React from "react";
import { useTranslation } from "react-i18next";
import Utilities from "../../../lib/Utilities";

const util = new Utilities();

export default function TourCostDetailModal(props) {
  const { rrl_data, tourData } = props;
  const { t } = useTranslation();
  return (
    <div
      className="modal fade mh-100"
      id="TourCostDetailModal"
      tabIndex="-1"
      aria-labelledby="TourCostDetailModalLabel"
      aria-hidden="true"
      data-backdrop="static"
      style={{ zIndex: "9999 !important", background: "rgba(0,0,0,0.3)" }}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <p className="h5 font-weight-bold mb-0">요금 상세</p>

            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="TourCostDetailModalClose"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table table_summary border-top-0 p-0 m-0">
              <tbody>
                <tr>
                  <td className="border-top-0">{t("baseRate")}</td>
                  <td className="text-right border-top-0">
                    &#8361; {util.addCommas(tourData.rl_price_base)}
                  </td>
                </tr>
                {rrl_data.rrl_guest_over_cnt > 0 && (
                  <tr>
                    <td>{t("tourDetailServiceInfoPriceAddPerFeeTitle")}</td>
                    <td className="text-right">
                      {rrl_data.rrl_guest_over_cnt} x &#8361;{" "}
                      {util.addCommas(
                        tourData.rl_over_pay_people
                          ? tourData.rl_over_pay_people
                          : 0
                      )}{" "}
                      = &#8361;{" "}
                      {util.addCommas(
                        rrl_data.rrl_guest_over_cnt *
                          Number(tourData.rl_over_pay_people)
                      )}
                    </td>
                  </tr>
                )}
                <tr>
                  <td>{t("serviceFee")}</td>
                  <td className="text-right">
                    &#8361; {util.addCommas(rrl_data.rrl_cost_fees_guest)}
                  </td>
                </tr>
                <tr>
                  <td>{t("vatAndFees")}</td>
                  <td className="text-right">
                    &#8361; {util.addCommas(rrl_data.rrl_cost_tax)}
                  </td>
                </tr>
                <tr className="total">
                  <td>{t("feeTotal")}</td>
                  <td className="text-right">
                    &#8361; {util.addCommas(rrl_data.rrl_cost_total)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
