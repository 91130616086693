import React, { useEffect, useState } from "react";
import UploadImageToS3WithReactS3 from "../../../../../../lib/UploadImageToS3WithReactS3";
import CodeList from "../../../../../../lib/CodeList";

const codeList = new CodeList();

let photo_data = {
  rl_pic_main: "",
  rl_pics: "",
};

export default function GuideTourRegistPhoto(props) {
  let { tourData, loginInfo, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (tourData) {
      for (let key in photo_data) {
        photo_data[key] = tourData[key] ? tourData[key] : photo_data[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
    return () => {
      photo_data = {
        rl_pic_main: "",
        rl_pics: "",
      };
    };
  }, []);

  //이미지 업로드
  const uploadImageCallback = async (key, res) => {
    if (!res.ret) {
      codeList.Modal.current.alert("이미지 업로드 중 오류가 발생했습니다.");
      return;
    }
    const file = `${res.realFileName}?${res.fileName}`;

    if (!res.multiple) {
      photo_data[key] = file;
    } else {
      if (photo_data[key].split("|").length >= 6) {
        codeList.Modal.current.alert(
          "갤러리사진은 최대 6장까지 등록가능합니다."
        );
        return;
      }

      photo_data[key] = photo_data[key] ? `${photo_data[key]}|${file}` : file;
    }

    guideTourRegistDataChange(photo_data, () => {
      setIsNeedUpdate((before) => !before);
    });
  };

  const removeImage = (index) => {
    let pics = photo_data.rl_pics.split("|");

    pics.splice(index, 1);
    photo_data.rl_pics = pics.join("|");

    guideTourRegistDataChange(photo_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-2">모든 사진의 필수 조건</h6>
        <div className="d-flex flex-row mb-2">
          <p>1. </p>
          <p>밝고 선명한 컬러 사진이어야 합니다.</p>
        </div>

        <div className="d-flex flex-row mb-2">
          <p>2. </p>
          <p>가이드 현장을 정확히 보여주는 사진이어야 합니다.</p>
        </div>

        <div className="d-flex flex-row mb-2">
          <p>3. </p>
          <p>
            {" "}
            카메라를 향해 포즈를 취하거나 셀카를 찍는 사람의 모습을 담은 사진은
            안 됩니다.
          </p>
        </div>

        <div className="d-flex flex-row mb-2">
          <p>4. </p>
          <p>
            필터, 텍스트/그래픽, 로고 또는 콜라주를 이용해 사진을 수정할 수
            없습니다.
          </p>
        </div>

        <div className="d-flex flex-row mb-2">
          <p>5. </p>
          <p>
            사진은 반드시 본인 소유여야 하며, 저작권이 있는 작품은 사용하면 안
            됩니다.
          </p>
        </div>
      </div>
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-2">
          커버 사진 : 여행에 참여하는 사람들이 서로 소통하는 모습을 담은 사진
        </h6>
        <p className="mb-1">
          사람이 1명 이상 포함되어 있으며 여행 전체의 모습을 보여주는 진솔한
          사진을 선택하세요. 셀카, 포즈를 취한 사진, 연출된 사진은 수락되지
          않습니다.
        </p>
        <UploadImageToS3WithReactS3
          region="route/pic_main"
          disabled={tourData.rl_confirm_admin === "S"}
          callback={(res) => {
            uploadImageCallback("rl_pic_main", res);
          }}
        />
        <div className="pt-2">
          {photo_data.rl_pic_main && (
            <div
              className="border rounded bg-light"
              style={{
                height: 250,
                background: `url(${
                  photo_data.rl_pic_main
                    ? photo_data.rl_pic_main.split("?")[1]
                    : ""
                })50% 50%/cover no-repeat`,
              }}
            />
          )}
        </div>
      </div>
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-2">
          갤러리 사진: 세부 사항을 상세히 또는 전체적으로 담은 다양한 사진
          컬렉션
        </h6>
        <p className="mb-1">
          여행의 다양한 면모를 강조할 수 있는 사진을 업로드
          <strong className="text-buddible">(최대 6장)</strong>하세요. 사진에는
          가이드/게스트의 상호작용 모습, 여행 장소, 가이드 진행 모습의
          클로즈업샷이 포함되어야 합니다.
        </p>
        <UploadImageToS3WithReactS3
          region="route/pics"
          multi={true}
          disabled={tourData.rl_confirm_admin === "S"}
          max={6}
          callback={(res) => {
            uploadImageCallback("rl_pics", res);
          }}
        />
        <div className="row m-0 p-0">
          {photo_data.rl_pics &&
            photo_data.rl_pics.split("|").map((img, index) => {
              if (!img) return;
              let splitData = img.split("?");
              let fileLink = splitData[1];
              return (
                <div
                  className="col-12 col-sm-6 m-0 p-1"
                  key={`${img}_${index}`}
                >
                  <div
                    className="border rounded bg-light mb-2 position-relative"
                    style={{
                      height: 250,
                      background: `url(${fileLink})50% 50%/cover no-repeat`,
                    }}
                  >
                    <button
                      className="position-absolute btn-danger rounded px-2"
                      style={{ right: 0 }}
                      disabled={tourData.rl_confirm_admin === "S"}
                      onClick={() => removeImage(index)}
                    >
                      삭제 &times;
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
