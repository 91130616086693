import React, { useEffect, useState } from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const util = new Utilities();
const codeList = new CodeList();

const backgroundImageList = {
  hotPlace: [
    "/images/tourList_carousel_hotplace_1.jpg",
    "/images/tourList_carousel_hotplace_2.jpg",
    "/images/tourList_carousel_hotplace_3.jpg",
  ],
  experience: [
    "/images/tourList_carousel_experience_1.jpg",
    "/images/tourList_carousel_experience_2.jpg",
    "/images/tourList_carousel_experience_3.png",
  ],
  food: [
    "/images/tourList_carousel_food_1.jpg",
    "/images/tourList_carousel_food_2.jpg",
    "/images/tourList_carousel_food_3.jpg",
  ],
  cultureAndShopping: [
    "/images/tourList_carousel_culture_1.jpg",
    "/images/tourList_carousel_culture_2.jpg",
    "/images/tourList_carousel_culture_3.jpg",
  ],
};

let current_carousel = {
  title: "",
  desc: "",
  img: "",
};

export default function TourListCarousel(props) {
  const { match, tourListTitle } = props;
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const carousel_title = {
    hotPlace: t("tourListCarouselTitle1"),
    experience: t("tourListCarouselTitle2"),
    food: t("tourListCarouselTitle3"),
    cultureAndShopping: t("tourListCarouselTitle4"),
  };

  useEffect(() => {
    const rndNum = util.getRandomInt(
      0,
      backgroundImageList[tourListTitle].length
    );
    current_carousel = {
      title: carousel_title[tourListTitle],
      desc: t("tourListCarouselDesc"),
      img: backgroundImageList[tourListTitle][rndNum],
    };
    setIsNeedUpdate(!isNeedUpdate);
  }, [tourListTitle]);

  return (
    <section
      className="parallax-window"
      data-parallax="scroll"
      data-image-src="./img/parallax_bg_1.jpg"
      data-natural-width="1400"
      data-natural-height="400"
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) ), url('${current_carousel.img}') 50% 50% /cover no-repeat`,
      }}
    >
      <div
        className="parallax-content-1 opacity-mask"
        data-opacity-mask="rgba(0, 0, 0, 0.6)"
      >
        <div className="animated fadeInDown">
          <h3 className="text-white">{current_carousel.title}</h3>
          <p className="mb-3">{current_carousel.desc}</p>
          <Link className="btn_1 bg-buddible" to="/TourDetailList">
            {t("detailedSearch")}
          </Link>
        </div>
      </div>
    </section>
  );
}
