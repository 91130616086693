import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
import $ from "jquery";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize";
import ImageCompress from "quill-image-compress";

import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

import { uploadFile } from "react-s3";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

Quill.register("modules/ImageResize", ImageResize);
Quill.register("modules/imageCompress", ImageCompress);

const S3_BUCKET = "playyj";
const ACCESS_KEY = "AKIAW7LFABWASIJAYZPF";
const SECRET_ACCESS_KEY = "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";
const REGION = "ap-northeast-2";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "FAQManageEdit";
const FAQ_TYPE = [
  { key: "FAQ01", desc: "플레이 영종 이용안내" },
  { key: "FAQ02", desc: "아이디/비밀번호 문의" },
  { key: "FAQ03", desc: "여행 문의" },
  { key: "FAQ04", desc: "불편 접수" },
  { key: "FAQ05", desc: "안전" },
];

const FAQ_SUB_TYPE = [
  "이용방법",
  "메세지 주고 받기",
  "계정 만들기",
  "계정관리",
  "신분증 및 인증",
  "계정 보안",
  "예약 관련",
  "취소/결제/환불",
  "불편 접수",
  "안전에 관한 문의",
  "안전 가이드 라인",
  "시작하기",
];

function FAQManageEdit(props, ref) {
  const { data = {}, requestUserSelect, loginInfo } = props;
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isEditorState, setIsEditorState] = useState("");
  const QuillRef = useRef();

  const controller = {
    faq_idx: useRef(),
    faq_category_lg: useRef(),
    faq_category_md: useRef(),
    faq_title: useRef(),
    faq_use_yn: useRef(),
  };

  const dataChange = (e, params) => {
    setUserData(params);
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      (b, n) => {
        setUserData({});
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );

      socket.removeLocalEventListener(
        MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (userData.hasOwnProperty("currentData")) {
      controller.faq_idx.current.disabled = true;
      controller.faq_idx.current.value = userData.currentData.faq_idx;
      controller.faq_category_lg.current.value =
        userData.currentData.faq_category_lg;
      controller.faq_category_md.current.value =
        userData.currentData.faq_category_md;
      controller.faq_title.current.value = userData.currentData.faq_title;
      controller.faq_use_yn.current.value = userData.currentData.faq_use_yn;
      setIsEditorState(userData.currentData.faq_content);
    } else {
      controller.faq_idx.current.disabled = true;
      controller.faq_idx.current.value = "";
      controller.faq_category_lg.current.value = "";
      controller.faq_category_md.current.value = "";
      controller.faq_title.current.value = "";
      controller.faq_use_yn.current.value = "";
      setIsEditorState("");
    }
  }, [userData]);

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      dataChange(e, params);
    },
    removeData(e, params) {
      deleteData(e, params);
    },
  }));

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/faq/JS_faqUpload.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상 처리되었습니다.", () => {
            setLoading(false);
            requestUserSelect();
            closeModal();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요",
            () => {
              setLoading(false);
            }
          );
        }
      }
    });
  };

  const deleteData = (e, params) => {
    const updateData = {
      ...params.currentData,
      faq_del_yn: "Y",
    };
    codeList.Modal.current.confirm(
      `[${params.currentData.faq_title}]을 삭제하시겠습니까?`,
      (ret) => {
        if (ret) {
          requestUserUpdate(updateData);
        }
      }
    );
  };

  const checkValidation = () => {
    if (controller.faq_category_lg.current.value.trim() === "") {
      codeList.Modal.current.alert("대분류를 선택해주세요", () => {});
      return false;
    }
    if (controller.faq_category_md.current.value.trim() === "") {
      codeList.Modal.current.alert("중분류를 선택해주세요", () => {});
      return false;
    }
    if (controller.faq_title.current.value.trim() === "") {
      codeList.Modal.current.alert("제목을 입력해주세요", () => {});
      return false;
    }
    if (isEditorState.trim() === "") {
      codeList.Modal.current.alert("내용을 입력해주세요", () => {});
      return false;
    }
    if (controller.faq_use_yn.current.value.trim() === "") {
      codeList.Modal.current.alert("노출여부를 선택해주세요", () => {});
      return false;
    }
    return true;
  };
  const actionSave = (e) => {
    setLoading(true);
    if (checkValidation()) {
      const uid = userData.hasOwnProperty("currentData")
        ? userData.currentData.faq_idx
        : "";

      const updateData = {
        faq_idx: uid,
        faq_category_lg: controller.faq_category_lg.current.value,
        faq_category_md: controller.faq_category_md.current.value,
        faq_title: controller.faq_title.current.value,
        faq_content: isEditorState,
        faq_use_yn: controller.faq_use_yn.current.value,
        faq_del_yn: "N",
      };

      requestUserUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  // 이미지를 업로드 하기 위한 함수
  const imageHandlerWithQuill = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      await uploadFileToS3WithS3(file[0], "faq/image", (returnData) => {
        const range = QuillRef.current?.getEditor().getSelection()?.index;
        if (range !== null && range !== undefined) {
          let quill = QuillRef.current?.getEditor();
          quill?.setSelection(range, 1);
          quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<img width="200" src=${returnData.fileName} alt=${returnData.realFileName} />`
          );

          $(".ql-editor img[src='" + returnData.fileName + "']").attr(
            "width",
            "200"
          );
        }
      });
    };
  };

  // 첨부파일 업로드
  const fileUploadHandlerWithQuill = () => {
    // 파일을 업로드 하기 위한 input 태그 생성
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "*");
    input.click();

    // 파일이 input 태그에 담기면 실행 될 함수
    input.onchange = async () => {
      const file = input.files;
      await uploadFileToS3WithS3(file[0], "faq/file", (returnData) => {
        const range = QuillRef.current?.getEditor().getSelection()?.index;
        if (range !== null && range !== undefined) {
          let quill = QuillRef.current?.getEditor();

          quill?.setSelection(range, 1);
          quill?.clipboard.dangerouslyPasteHTML(
            range,
            `<a style="color: #5f8ecc;" href=${returnData.fileName} download=${returnData.realFileName}>※ 첨부파일 :  ${returnData.realFileName} </a>`
          );
        }
      });
    };
  };

  // S3 파일 업로드
  const uploadFileToS3WithS3 = (file, region, callback) => {
    let customConfig = { ...config };
    customConfig.dirName = region;
    let name = file.name;
    name = name.replace(/\s+/g, "_");

    let reg = /[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim;
    name = name.replace(reg, "");
    if (file.size > 21000000) {
      alert("20MB 이하의 파일만 업로드 가능합니다.");
      return;
    }
    let newName =
      name.split(".")[0] +
      "_" +
      util.todayDateTime() +
      "." +
      name.split(".")[1];
    let blob = file.slice(0, file.size, file.type);
    let newFile = new File([blob], newName, { type: file.type });

    uploadFile(newFile, customConfig)
      .then((data) => {
        const returnData = {
          ret: true,
          fileName: `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${region}/${newName}`,
          realFileName: name,
        };
        callback && callback(returnData);
      })
      .catch((err) => {
        const returnData = {
          ret: false,
          fileName: ``,
          realFileName: ``,
        };
        callback && callback(returnData);
      })
      .finally(() => {});
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: ["small", false, "large", "huge"] }],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["image", "link"],
          [{ align: [] }, { color: [] }, { background: [] }],
          ["clean", "file"],
        ],
        handlers: {
          image: imageHandlerWithQuill,
          link: function (value) {
            if (value) {
              let href = prompt("URL을 입력해 주세요.");
              this.quill.format("link", href);
            } else {
              this.quill.format("link", false);
            }
          },
          file: fileUploadHandlerWithQuill,
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      ImageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize", "Toolbar"],
      },
    }),
    []
  );

  const formats = [
    //'font',
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
    "clean",
    "parchment",
    "alt",
    "height",
    "width",
    "style",
    "size",
  ];

  const closeModal = () => {
    controller.faq_idx.current.disabled = true;
    controller.faq_idx.current.value = "";
    controller.faq_category_lg.current.value = "";
    controller.faq_category_md.current.value = "";
    controller.faq_title.current.value = "";
    controller.faq_use_yn.current.value = "";
    setIsEditorState("");
    $("#FAQManageEditClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="FAQManageEdit"
        data-backdrop="static"
        data-keyboard="false"
        aria-hidden="true"
        aria-labelledby="FAQManageEdit"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>FAQ 등록</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="FAQManageEditClose"
                onClick={() => closeModal()}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  관리 번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="account"
                    className="form-control"
                    placeholder="ID"
                    ref={controller.faq_idx}
                  />
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  대분류
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    ref={controller.faq_category_lg}
                  >
                    <option value="">-분류선택-</option>
                    {FAQ_TYPE.map((type) => {
                      return (
                        <option key={type.key} value={type.key}>
                          {type.desc}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  중분류
                </label>
                <div className="col-sm-9">
                  <select
                    className="form-control"
                    ref={controller.faq_category_md}
                  >
                    <option value="">-중분류 선택-</option>
                    {FAQ_SUB_TYPE.map((type) => {
                      return (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">제목</label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="제목을 입력해주세요"
                    ref={controller.faq_title}
                  />
                </div>
              </div>

              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">내용</label>
                <div className="col-sm-9">
                  <div className="text-editor overflow_scroll_y border">
                    <ReactQuill
                      style={{
                        maxHeight: "250px",
                        marginBottom: 20,
                        width: "100%",
                        border: "none",
                      }}
                      theme={"snow"}
                      modules={modules}
                      formats={formats}
                      value={isEditorState || ""}
                      ref={(element) => {
                        if (element !== null) {
                          QuillRef.current = element;
                        }
                      }}
                      onChange={(content, delta, source, editor) => {
                        setIsEditorState(editor.getHTML());
                      }}
                      preserveWhitespace={true}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-3 row">
                <label className="col-form-label col-sm-3 text-end">
                  노출여부
                </label>
                <div className="col-sm-9">
                  <select className="form-control" ref={controller.faq_use_yn}>
                    <option value="">-노출여부-</option>
                    <option value="Y">노출</option>
                    <option value="N">비노출</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={closeModal}
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
                disabled={loading}
              >
                <i className="fa fa-check mr-2" />
                저장
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm ml-2"
                    role="status"
                    aria-hidden="true"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.forwardRef(FAQManageEdit);
