/*global google*/

import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import GuideTimeRegistPlaceSearch from "./GuideTimeRegistPlaceSearch";

import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let zoom = 15;
let point;
let myLatLng;

let apiReady = false;
let places = [];
let center = {
  lat: 37,
  lng: 127,
};

let mapApi = {
  map: null,
  googleMaps: null,
};

let place_data = {
  gpt_addr_lat: 0,
  gpt_addr_lng: 0,
  // gpt_addr_full: "",
  gpt_addr_name: "",
  gpt_area_explain: "",
  gpt_addr_country: "",
  gpt_addr_postal_code: "",
  gpt_addr_area_level_1: "",
  gpt_addr_sublocality_level_1: "",
  gpt_addr_sublocality_level_2: "",
  gpt_addr_premise: "",
  gpt_addr_place_id: "",
  gpt_guide_explain: "", // 가이드 알아보는 방법
};

export default function GuideTimeRegistPlace(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (partTimeData) {
      for (let key in place_data) {
        place_data[key] = partTimeData[key];
      }
    }
    if (partTimeData.gpt_addr_lat) {
      point = {
        lat: partTimeData.gpt_addr_lat,
        lng: partTimeData.gpt_addr_lng,
      };
      center = {
        lat: partTimeData.gpt_addr_lat,
        lng: partTimeData.gpt_addr_lng,
      };
    } else {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            point = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          },
          function (error) {
            console.error(error);
          },
          {
            enableHighAccuracy: false, // 배터리를 소모해 더 정확한 위치를 찾음(?)
            maximumAge: 0, // 한번 찾은 위치 정보를 해당 초만큼 캐싱
            timeout: Infinity, //주어진 초 안에 찾지 못하면 에러 발생
          }
        );
      } else {
        alert("위치 찾기 오류");
      }
    }
    setIsNeedUpdate(!isNeedUpdate);
  }, []);

  //구글 맵 Api 로드
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      mapApi = Object.assign(mapApi, {
        map: map,
        googleMaps: maps,
      });
      apiReady = true;
    }
    if (center.lat === "" || center.lng === "") center = point;
    let marker = new maps.Marker({
      position: center,
      map,
    });
    mapApi.map.setCenter(center);

    setIsNeedUpdate(!isNeedUpdate);
    return marker;
  };

  const addPlace = (data) => {
    if (data) {
      places = data;
    }
  };
  const initMap = () => {
    const latLng = point;

    const map = mapApi.map;
    myLatLng = latLng;
    mapApi.map.setCenter(myLatLng);
    mapApi.map.setZoom(18);
    new google.maps.Marker({
      position: myLatLng,
      map,
      title: "Hello World!",
    });
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (val) => {
    guideTimeRegistDataChange(val);
  };

  return (
    <div className="col-xl-6">
      <div>
        <p className="h3 font-weight-bold">
          가이드가 어느 지역에서 진행되나요?
        </p>
        <p>
          시간제 가이드를 진행할 수 있는 지역/장소를 검색을 통해 선택해주세요
        </p>
        {apiReady && (
          <GuideTimeRegistPlaceSearch
            map={mapApi.map}
            mapApi={mapApi.googleMaps}
            addPlace={addPlace}
            partTimeData={partTimeData}
            handleTourDataChange={handleTourDataChange}
          />
        )}
      </div>
      <div className="py-5 border-bottom">
        <p className="h4 font-weight-bold">
          상단에서 검색 후 시작 위치 지도로 확인하기
        </p>
        <p>상담의 장소 검색 시 해당되는 주소를 선택해주세요.</p>
        <div className="googleMap my-2" style={{ width: "100%", height: 400 }}>
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyDKXA_Dj4ZejIXaFA7I_p9Ry9o7c8s4QZQ",
              libraries: "places",
            }}
            defaultZoom={zoom}
            defaultCenter={center}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          />
        </div>
        <select className="w-100 p-3 border my-2">
          <option value={5}>선택위치에서 반경 5km 이내</option>
          <option value={10}>선택위치에서 반경 10km 이내</option>
          <option value={15}>선택위치에서 반경 15km 이내</option>
          <option value={20}>선택위치에서 반경 20km 이내</option>
        </select>

        <p>기본 반경 5km 부터 가능합니다. 활동가능한 지역까지 선택해주세요.</p>
      </div>
      <div className="py-5 border-bottom">
        <p className="h4 font-weight-bold">모임 장소 이름 지정하기</p>
        <p>
          게스트와 만나는 장소를 자세히 적어주세요. 예) 백마역 2번출구 국민은행
          앞.
          <br /> 단, 이 단계에서는 주소를 입력하지 않아도 됩니다.
        </p>
        <input
          type="text"
          className="form-control mt-2"
          placeholder="예) 00 카페 앞"
          defaultValue={place_data.gpt_area_explain}
          onChange={(e) => {
            handleTourDataChange({ gpt_area_explain: e.target.value });
          }}
        />
      </div>
      <div className="py-5">
        <p className="h4 font-weight-bold">
          도착 후 가이드님을 어떻게 알아볼 수 있는지 알려주세요.
        </p>
        <p>
          모이는 장소까지 찾아가는 방법을 상세히 설명하세요. 자세한 정보를
          제공할수록 게스트가 쉽게 찾아올 수 있습니다.
        </p>
        <textarea
          rows={5}
          className="form-control mt-2"
          defaultValue={place_data.gpt_guide_explain}
          placeholder="장소의 분위기, 경관과 주변 소리 등 특별한 점을 설명하세요. 바뀔 수 있는 정보는 chat을 통해서 바로 정보를 알려주세요. 도보, 자동차, 대중교통 이용 시 오는 방법과 주차 팁을 설명해주세요."
          onChange={(e) => {
            handleTourDataChange({ gpt_guide_explain: e.target.value });
          }}
        />
        <p className="mt-2">
          <i className="fas fa-exclamation-circle mr-2" />
          <span>유의사항</span> : 대부분의 여행자는 여행이 진행되는 도시에 대해
          잘 모르므로, 게스트에게 장소 선택을 맡기지 마세요. 게스트는 가이드가
          해당 지역에서 의미 있는 장소를 결정하리라 기대합니다.
        </p>
      </div>
    </div>
  );
}
