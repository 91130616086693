import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import $ from "jquery";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import Utilities from "../../../lib/Utilities";
import MainLogoSVG from "../../../lib/MainLogoSVG";
import { RegList } from "../../../lib/reg";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "RegistModal";
let checkID = false;
let checkPassReg = false;
let checkPassDouble = false;

export default function RegistModal() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const $controllers = {
    user_id: useRef(),
    user_pw: useRef(),
    user_pw_check: useRef(),
    user_last_name: useRef(),
    user_first_name: useRef(),
    user_birthday: useRef(),
    check_personal: useRef(),
  };

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  const checkRegPassword = (e) => {
    let value = e.target.value;
    let reg = RegList.PASSWORD.test(value);
    if (checkPassReg !== reg) {
      checkPassReg = reg;
      setIsNeedUpdate(!isNeedUpdate);
    }
  };

  const checkPassword = (e) => {
    let value = e.target.value;
    let check = value === $controllers.user_pw.current.value;
    if (checkPassDouble !== check) {
      checkPassDouble = check;
      setIsNeedUpdate(!isNeedUpdate);
    }
  };

  const actionSave = (e) => {
    setLoading(true);
    let msgID = util.makeUUIDv4();

    if (checkValidation()) {
      let updateData = {
        u_uid: msgID,
        user_id: $controllers.user_id.current.value,
        user_pw: util.buddibleEncrypt($controllers.user_pw.current.value),
        user_last_name: $controllers.user_last_name.current.value,
        user_first_name: $controllers.user_first_name.current.value,
        user_name_full:
          $controllers.user_first_name.current.value +
          $controllers.user_last_name.current.value,
        user_birthday: $controllers.user_birthday.current.value,
        u_oauth_type: "buddible",
        u_oauth_id: "",
        u_tokenID: "",
      };

      requestUserUpdate(updateData);
    } else {
      setLoading(false);
    }
  };

  const checkDuplicationEmail = () => {
    if ($controllers.user_id.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registWriteCheckEmailMsg"));
      return;
    }

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/join/JS_duplication_check_email.php",
      msgID: msgID,
      data: {},
      user_id: $controllers.user_id.current.value.trim(),
    };
    setLoading(true);
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        checkID = newData["ret"];
        codeList.Modal.current.alert(t("registDuplicationCheckSuccess"));
        setLoading(false);
      }
    });
  };
  // t("")
  const checkValidation = () => {
    if ($controllers.user_id.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registWriteCheckEmailMsg"), () => {});
      return false;
    }

    if (!checkID) {
      codeList.Modal.current.alert(t("registCheckEmailMsg"));
      return false;
    }

    if ($controllers.user_pw.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registWritePasswordMsg"), () => {});
      return false;
    }
    if ($controllers.user_pw_check.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registCheckPasswordMsg"), () => {});
      return false;
    }
    if ($controllers.user_last_name.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registCheckFirstNameMsg"), () => {});
      return false;
    }

    if ($controllers.user_first_name.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registCheckLastNameMsg"), () => {});
      return false;
    }

    if ($controllers.user_birthday.current.value.trim() === "") {
      codeList.Modal.current.alert(t("registCheckBirthdayMsg"), () => {});
      return false;
    }
    if (!$controllers.check_personal.current.checked) {
      codeList.Modal.current.alert(t("registCheckTermsMsg"), () => {});
      return false;
    }
    return true;
  };

  const requestUserUpdate = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/join/JS_join.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    setLoading(true);
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          codeList.Modal.current.alert(t("registSuccessMsg"), () => {});

          $("#registModalClose").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(t("registFailedMsg"), () => {});
        }
      }
      setLoading(false);
    });
  };

  const closeModal = () => {
    checkID = false;
    checkPassReg = false;
    checkPassDouble = false;
    for (let key in $controllers) {
      if ($controllers[key].current) {
        $controllers[key].current.value = "";
      }
    }
    setIsNeedUpdate(!isNeedUpdate);
  };
  return (
    <div
      className="modal fade"
      id="registModal"
      tabIndex="-1"
      aria-labelledby="registModalLabel"
      aria-hidden="true"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-md mt-0">
        <div className="modal-content max-vh-80 overflow-hidden">
          <div
            className="modal-header"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="col-2 m-0 p-0">
              <MainLogoSVG />
            </div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="registModalClose"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body overflow_scroll_y">
            <div className="container-fluid">
              <div className="text-lg-center">
                <div className="mb-3 row">
                  <div className="col-sm-12">
                    <div className="d-flex flex-row justify-content-between">
                      <input
                        type="email"
                        className="form-control mr-2"
                        placeholder={t("email")}
                        ref={$controllers.user_id}
                        disabled={checkID || loading}
                      />
                      <button
                        className="btn_login bg-buddible m-0"
                        disabled={checkID || loading}
                        onClick={checkDuplicationEmail}
                      >
                        {t("registDuplicationCheck")}{" "}
                        {checkID && <i className="fa-solid fa-check ml-2" />}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-sm-12">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={t("pw")}
                      ref={$controllers.user_pw}
                      disabled={loading}
                      onChange={(e) => {
                        checkRegPassword(e);
                      }}
                    />
                    {!checkPassReg && (
                      <p className="text-left text-danger mt-1">
                        <i className="fa-solid fa-circle-exclamation" />{" "}
                        {t("registPWValidationNote")}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-sm-12">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={t("pwCheck")}
                      ref={$controllers.user_pw_check}
                      disabled={loading}
                      onChange={(e) => checkPassword(e)}
                    />
                    {!checkPassDouble && (
                      <p className="text-left text-danger mt-1">
                        <i className="fa-solid fa-circle-exclamation" />{" "}
                        {t("registPWDuplicationNote")}
                      </p>
                    )}
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("firstName")}
                      ref={$controllers.user_last_name}
                      disabled={loading}
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("lastName")}
                      ref={$controllers.user_first_name}
                      disabled={loading}
                    />
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-sm-12">
                    <p className="text-left mb-1">{t("birthday")}</p>
                    <input
                      type="date"
                      className="form-control"
                      placeholder={t("birthday")}
                      max={"9999-12-31"}
                      ref={$controllers.user_birthday}
                      disabled={loading}
                    />
                    <div className="text-left mt-1">
                      <p>{t("registBirthdayNote")}</p>
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-12">
                    <div className="default_commonCheckbox commonCheckbox d-flex flex-row align-items-center">
                      <input
                        type="checkBox"
                        id="agree"
                        name="marketing"
                        disabled={loading}
                      />
                      <label
                        htmlFor="agree"
                        className="default_commonCheckbox_agreeLabel"
                      >
                        <span className="default_commonCheckbox_checkBoxWrap">
                          <i className="fas fa-check" />
                        </span>
                        {t("registReceiveMarketing")}
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="default_commonCheckbox commonCheckbox d-flex flex-row align-items-center">
                      <input
                        type="checkBox"
                        id="personal"
                        name="personal"
                        ref={$controllers.check_personal}
                        disabled={loading}
                      />
                      <label
                        htmlFor="personal"
                        className="default_commonCheckbox_agreeLabel"
                      >
                        <span className="default_commonCheckbox_checkBoxWrap">
                          <i className="fas fa-check" />
                        </span>
                        {t("registPrivacyPolicy")}
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  className="mb-1 row"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <div className="col-12">
                    <div className="text-center">
                      <button
                        style={{ background: "#004572" }}
                        type="submit"
                        className="btn_login"
                        disabled={loading}
                        onClick={() => actionSave()}
                      >
                        {t("join")}{" "}
                        {loading && (
                          <i className="fa-solid fa-circle-notch ml-2 rotate-infinite" />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
