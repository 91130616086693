import React, { useCallback, useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import GuideDetailGuideInfo from "./GuideDetailGuideInfo";
import GuideDetailReview from "./GuideDetailReview";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let bigImageData = {
  rl_price_base: "",
  rl_over_pay_people: "",
  rl_rating: "",
  rl_rating_cnt: "",
  rl_title: "",
  rl_desc: "",
};

export default function GuideDetailContent(props) {
  const { guideData, tourData, reviewData } = props;
  const { t } = useTranslation();
  const [bigImageList, setBigImageList] = useState(null);

  //이미지 선택
  const handleSelect = (
    e,
    value,
    rl_price_base,
    rl_over_pay_people,
    rl_rating,
    rl_rating_cnt,
    rl_title,
    rl_desc
  ) => {
    bigImageData = {
      rl_price_base: rl_price_base,
      rl_over_pay_people: rl_over_pay_people,
      rl_rating: rl_rating,
      rl_rating_cnt: rl_rating_cnt,
      rl_title: rl_title,
      rl_desc: rl_desc,
    };

    setBigImageList(value);
  };

  const TourItemRender = useCallback(({ item, handleSelect }) => {
    return (
      <TourItem
        rl_idx={item.rl_idx}
        rl_pics={item.rl_pics}
        rl_guest_min={item.rl_guest_min}
        rl_price_base={item.rl_price_base}
        rl_over_pay_people={item.rl_over_pay_people}
        rl_rating={item.rl_rating}
        rl_rating_cnt={item.rl_rating_cnt}
        rl_title={item.rl_title}
        rl_desc={item.rl_desc}
        handleSelect={handleSelect}
      />
    );
  }, []);

  return (
    <>
      <GuideDetailBigImage
        bigImageList={bigImageList}
        setBigImageList={setBigImageList}
      />
      <div id="guideDetail" className="container margin_60 border-bottom">
        <div className="row">
          <div
            className="col-lg-8 order-last order-lg-first"
            id="single_tour_desc"
          >
            <div id="single_tour_feat" className="mb-3 border-bottom">
              <div className="pb-2 w-100">
                <h3 className="m-0 p-0 font-weight-bold">
                  {t("guideDetailAboutServiceTitle1")}
                  {guideData.gl_name}
                  {t("guideDetailAboutServiceTitle2")}
                </h3>
                <div className="d-flex flex-row justify-content-start align-items-center">
                  <span className="pr-2 font-weight-bold">
                    {guideData.gl_country_cd} {guideData.gl_area_name}
                  </span>
                  <span className="pr-2">{guideData.gl_name}</span>
                  <span className="rating">
                    <i className="icon-star voted" />
                  </span>
                  {guideData.gl_rating} ({guideData.gl_rating_cnt})
                </div>
              </div>
            </div>
            {tourData.length > 0 &&
              tourData.map((tourItem) => {
                return (
                  <TourItemRender
                    key={tourItem.rl_route_uid}
                    item={tourItem}
                    handleSelect={handleSelect}
                  />
                );
              })}

            {tourData.length === 0 && (
              <div className="p-5 my-2 bg-light rounded-lg">
                <p className="h4 text-secondary text-center rounded ">
                  {t("emptyServiceNote")}
                </p>
              </div>
            )}
            {/*대표코스, 코스정보 END*/}

            <GuideDetailReview guideData={guideData} reviewData={reviewData} />
            {/*후기 END*/}
          </div>

          <GuideDetailGuideInfo guideData={guideData} />
          {/*가이드 인포 END*/}
        </div>
      </div>
    </>
  );
}

const TourItem = React.memo(
  ({
    rl_idx,
    rl_pics,
    rl_guest_min,
    rl_price_base,
    rl_over_pay_people,
    rl_rating,
    rl_rating_cnt,
    rl_title,
    rl_desc,
    handleSelect,
  }) => {
    const { t } = useTranslation();

    let images = rl_pics;
    if (images) {
      images = images.split("|");
    }

    let imgCnt = 0;
    if (Array.isArray(images) && images.length > 4) {
      imgCnt = images.length - 4;
      images = images.slice(0, 4);
    }

    return (
      <>
        <div className="post my-3">
          <div className="row px-2 position-relative">
            <div
              className={`btn_1 bg-light text-secondary position-absolute ${
                images ? "d-block" : "d-none"
              }`}
              style={{ right: 20, top: 20, zIndex: 3 }}
              onClick={(e) => {
                handleSelect(
                  e,
                  rl_pics.split("|"),
                  rl_price_base,
                  rl_over_pay_people,
                  rl_rating,
                  rl_rating_cnt,
                  rl_title,
                  rl_desc
                );
              }}
            >
              <i className="fa-solid fa-images mr-2" />
              {t("showBig")}
            </div>
            {Array.isArray(images) &&
              images.map((img, index) => {
                const picLink = util.changedResourceUrl(img);
                return (
                  <div
                    key={`${img}_${index}`}
                    className={`${index === 0 ? "col-12" : "col-4"} p-1 border`}
                  >
                    <div className="overflow-hidden">
                      <div
                        onClick={(e) => {
                          handleSelect(
                            e,
                            rl_pics.split("|"),
                            rl_price_base,
                            rl_over_pay_people,
                            rl_rating,
                            rl_rating_cnt,
                            rl_title,
                            rl_desc
                          );
                        }}
                        className="guideDetailImg cursor_pointer"
                        style={{
                          height: index === 0 ? 350 : 150,
                          width: "100%",
                          background: `url(${picLink})50% 50%/cover no-repeat`,
                        }}
                      >
                        <div
                          className={`w-100 h-100  ${
                            index === 3 && imgCnt ? "d-flex" : "d-none"
                          } flex-row justify-content-center align-items-center`}
                          style={{ background: "rgba(0,0,0,0.6)" }}
                        >
                          <span className="text-light h1">+ {imgCnt}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            {Array.isArray(images) &&
              images.length < 4 &&
              (() => {
                let item = [];
                for (let i = 0; i < 4 - images.length; i++) {
                  item.push(
                    <div className="col-4 p-1" key={i}>
                      <div
                        className="w-100 bg-light d-flex flex-row justify-content-center align-items-center"
                        style={{ height: 150 }}
                      >
                        <span className="text-secondary h6">
                          {t("image")} X
                        </span>
                      </div>
                    </div>
                  );
                }
                return item;
              })()}
            {!images && (
              <div
                className="col-12 d-flex flex-row justify-content-center align-items-center bg-light"
                style={{ height: 400 }}
              >
                <span className="h3 text-secondary text-center">
                  {t("emptyImageNote")}
                </span>
              </div>
            )}
          </div>
          <Link to={`/TourDetail/${rl_idx}`} className="w-100">
            <h2>{rl_title}</h2>
            <div className="post_info border-0 clearfix">
              <div className="post-left">
                <ul>
                  <li>
                    {" "}
                    · {t("minGuest")} : {rl_guest_min ? rl_guest_min : "1"}{" "}
                    {t("person")}
                  </li>
                  <li>
                    {" "}
                    · {t("baseRate")} : &#8361; {util.addCommas(rl_price_base)}
                  </li>
                  {rl_over_pay_people ? (
                    <li>
                      · {t("addedPerson")} : &#8361;{" "}
                      {util.addCommas(rl_over_pay_people)}
                    </li>
                  ) : (
                    <></>
                  )}
                </ul>
              </div>
              <div className="post-right">
                <div className="rating" style={{ fontSize: "15px" }}>
                  <i
                    className={
                      rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                    }
                  />
                  {rl_rating} {rl_rating_cnt ? `(${rl_rating_cnt})` : ""}
                </div>
              </div>
            </div>
            <p className="ellipsis_4">{rl_desc}</p>
            <span className="btn_1 bg-buddible">{t("showDetail")}</span>
          </Link>
        </div>
        <hr />
      </>
    );
  }
);

//큰이미지 슬라이드
function GuideDetailBigImage(props) {
  const { t } = useTranslation();
  const { bigImageList, setBigImageList } = props;
  let display = false;
  if (Array.isArray(bigImageList) && bigImageList.length > 0) {
    display = true;
  }

  return (
    <div
      className={`guideDetailBigImg ${
        display ? "d-flex" : "d-none"
      } flex-column justify-content-center align-items-center modal_background`}
    >
      <div className="text-white w-50 border-bottom pb-2 mb-3 d-flex flex-row justify-content-between align-items-start">
        <div>
          <p className="h4 m-0 p-0">{bigImageData.rl_title}</p>
          <div
            className="rating d-flex flex-row align-items-center"
            style={{ fontSize: "15px" }}
          >
            <span className="pr-1">
              <i className="icon-star voted" />{" "}
              {Number(bigImageData.rl_rating) ? (
                <>
                  {bigImageData.rl_rating} ({bigImageData.rl_rating_cnt}){" "}
                </>
              ) : (
                t("new")
              )}
            </span>
            <span className="pr-1">
              {t("baseRate")} {util.addCommas(bigImageData.rl_price_base)}
            </span>
            <span
              className={`pr-1 ${
                bigImageData.rl_over_pay_people ? "d-inline-block" : "d-none"
              }`}
            >
              | {t("addedPerson")}{" "}
              {util.addCommas(bigImageData.rl_over_pay_people)}
            </span>
          </div>
        </div>
        <span
          className="h1 cursor_pointer"
          onClick={() => setBigImageList(null)}
        >
          &times;
        </span>
      </div>

      <div className="w-50">
        {Array.isArray(bigImageList) && bigImageList.length > 0 && (
          <OwlCarousel
            className="owl-theme"
            loop={false}
            nav
            dots={false}
            items={1}
            navContainerClass="guideDetailBigImgNav"
            navClass={["moveBtn mr-2", "moveBtn ml-2"]}
          >
            {bigImageList.map((item, index) => {
              return (
                <div key={`${item}_${index}`} className="item w-100 h-100">
                  <div
                    className="owl_carousel_img"
                    style={{
                      height: 600,
                      background: `url(${
                        codeList.publicVar.imgServerUrl + item
                      }) 50% 50% / contain no-repeat`,
                    }}
                  />
                </div>
              );
            })}
          </OwlCarousel>
        )}
      </div>
    </div>
  );
}
