import React, { useCallback, useEffect, useState } from "react";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "MyPageReservation";

const today = +new Date();
export default function MyPageReservation(props) {
  const { u_reserve_list, updateReserveList } = props;
  const { t } = useTranslation();

  //예약 데이터 랜더
  const ReserveItemRender = useCallback((props) => {
    return <ReserveItem {...props} />;
  }, []);

  const handleReserveStatus = (rrl_idx) => {
    codeList.Modal.current.confirm(
      t("reserveManageConfirmCancelMsg"),
      (ret) => {
        let data = { rrl_idx: rrl_idx, rrl_status: "CC" };
        updateReserveList(data);
      }
    );
  };

  //   리뷰작성모달 데이터 변경
  const handleReviewModalDataChange = (item) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_MY_PAGE_REVIEW_DATA_CHANED,
      _mClassName,
      item,
      () => {}
    );
  };

  const reserveList = u_reserve_list
    .filter(
      (item) =>
        (item.rrl_status === "CR" || item.rrl_status === "GA") &&
        +new Date(item.rrl_start_date) >= today
    )
    .reverse();

  const successList = u_reserve_list.filter(
    (item) => item.rrl_status === "GA" && +new Date(item.rrl_start_date) < today
  );

  const cancelList = u_reserve_list.filter(
    (item) =>
      item.rrl_status === "CC" ||
      item.rrl_status === "GC" ||
      (item.rrl_status === "CR" && +new Date(item.rrl_start_date) < today)
  );

  return (
    <>
      <div>
        <div className="row mb-3">
          <div className="col-12 mb-2">
            <h4 className="font-weight-bold">{t("reservation")}</h4>
          </div>
          <div className="col-12">
            {reserveList.length === 0 && (
              <div className="p-5 bg-white rounded">
                <p className="font-weight-bold text-secondary text-center">
                  {t("myPageReservationNoServiceNote")}
                </p>
                <div className="text-center mt-2">
                  <Link to="/TourDetailList" className="btn_1 px-3 py-2">
                    {t("searchTour")}
                  </Link>
                </div>
              </div>
            )}
            {reserveList.map((item, index) => {
              return (
                <ReserveItemRender
                  key={item.id}
                  item={item}
                  isWait={true}
                  handleReserveStatus={handleReserveStatus}
                  handleReviewModalDataChange={handleReviewModalDataChange}
                />
              );
            })}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 mb-2">
            <h4 className="font-weight-bold">
              {t("myPageReservationSuccessService")}
            </h4>
          </div>
          <div className="col-12">
            {successList.length === 0 && (
              <div className="p-5 bg-white rounded">
                <p className="font-weight-bold text-secondary text-center">
                  {t("myPageReservationNoSuccessServiceNote")}
                </p>
              </div>
            )}
            {successList.map((item, index) => {
              return (
                <ReserveItemRender
                  key={item.id}
                  item={item}
                  handleReserveStatus={handleReserveStatus}
                  handleReviewModalDataChange={handleReviewModalDataChange}
                />
              );
            })}
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 mb-2">
            <h4 className="font-weight-bold">
              {t("myPageReservationCancelService")}
            </h4>
          </div>
          <div className="col-12">
            {cancelList.length === 0 && (
              <div className="p-5 bg-white rounded">
                <p className="font-weight-bold text-secondary text-center">
                  {t("myPageReservationNoCancelServiceNote")}
                </p>
              </div>
            )}
            {cancelList.map((item, index) => {
              return (
                <ReserveItemRender
                  key={item.id}
                  item={item}
                  handleReserveStatus={handleReserveStatus}
                  handleReviewModalDataChange={handleReviewModalDataChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

const ReserveItem = React.memo((props) => {
  const {
    item,
    isWait = false,
    handleReserveStatus,
    handleReviewModalDataChange,
  } = props;
  const { t } = useTranslation();

  const isCustomerRequest =
    item.rrl_status === "CR" && today < +new Date(item.rrl_start_date);
  const isSuccessService =
    item.rrl_status === "GA" && today > +new Date(item.rrl_end_date);

  const isDisableService =
    item.rl_use_yn === "N" ||
    item.rl_del_yn === "Y" ||
    item.rl_confirm_admin !== "Y";

  let status;
  let statusColor = "secondary";
  switch (item.rrl_status) {
    case "CR":
      status = t("reserveStatusCR");
      statusColor = "secondary";
      break;
    case "GA":
      status = t("reserveStatusGA");
      statusColor = "info";
      break;
    case "CC":
      status = t("reserveStatusCC");
      statusColor = "danger";
      break;
    case "GC":
      status = t("reserveStatusGC");
      statusColor = "danger";
      break;
  }

  return (
    <div className="mb-2">
      <div className="row m-0 p-3 bg-white rounded">
        <div className="col-12 col-md-5 m-0 mb-2 mb-md-0 p-0">
          <div className="d-flex flex-row">
            <img
              width={75}
              height={75}
              src={util.changedResourceUrl(item.rl_pic_main)}
              alt="서비스 메인이미지"
              style={{ objectFit: "cover" }}
              className="mr-3 rounded"
            />
            <div
              className="d-flex flex-row align-items-center pr-3"
              style={{ height: 75 }}
            >
              <div>
                {isWait && (
                  <span className={`badge badge-pill badge-${statusColor}`}>
                    {status}
                  </span>
                )}
                <p className="font-weight-bold ellipsis_1">{item.rl_title}</p>
                {isDisableService ? (
                  <button
                    className="btn btn-sm btn-outline-dark font-size-08"
                    onClick={() => {
                      codeList.Modal.current.alert(
                        "현재는 이용이 불가한 서비스입니다."
                      );
                    }}
                  >
                    {t("showDetail")}
                    <i className="fa-solid fa-arrow-up-right-from-square ml-2" />
                  </button>
                ) : (
                  <Link
                    to={`/TourDetail/${item.rl_idx}`}
                    target="blank"
                    className="btn btn-sm btn-outline-dark font-size-08"
                  >
                    {t("showDetail")}
                    <i className="fa-solid fa-arrow-up-right-from-square ml-2" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-5 m-0 mb-2 mb-md-0 p-0">
          <div className="h-100 d-flex flex-row justify-content-start align-items-center">
            <div>
              <p className="ellipsis_1">
                {t("reserveDate")} :{" "}
                <span className={`${isWait ? "font-weight-bold" : ""}`}>
                  {item.rrl_start_date}
                </span>
              </p>
              <p className="ellipsis_1">
                {t("adult")} {item.rrl_guest_adult_num}
                {t("person")}
                {}
                {item.rrl_guest_child_num !== "0" &&
                  `, ${t("child")} ${item.rrl_guest_child_num}${t("person")}`}
                {item.rrl_guest_baby_num !== "0" &&
                  `, ${t("baby")} ${item.rrl_guest_baby_num}${t("person")}`}
              </p>
              <p>
                {t("paymentCost")} : &#8361;{" "}
                {util.addCommas(item.rrl_cost_total)}
              </p>
            </div>
          </div>
        </div>
        <div className="col m-0 p-0">
          <div className="h-100 d-flex flex-row justify-content-end align-items-center">
            {isCustomerRequest && (
              <button
                className="btn btn-sm btn-outline-secondary mr-md-2"
                onClick={() => handleReserveStatus(item.rrl_idx)}
              >
                {t("cancelReserve")}
              </button>
            )}
            {isSuccessService && (
              <button
                className="btn btn-sm btn-outline-primary mr-md-2"
                data-toggle="modal"
                data-target="#myPageReservationReviewWriteModal"
                onClick={() => handleReviewModalDataChange(item)}
              >
                {item.ur_idx ? t("editReview") : t("writeReview")}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
