import React, { useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "GuideRegistProfilePrerequisite";

const talkMethodList = [
  {
    id: 1,
    title: "수화",
    desc: "게스트를 위한 기본적 또는 유창한 수화.",
    value: "A",
  },
  {
    id: 2,
    title: "시각 장애인용 안내 표시",
    desc: "대부분의 정보는 글자가 크게 표시된 인쇄물이나 점자로 제공됩니다.",
    value: "B",
  },
  {
    id: 3,
    title: "장애인 지원 기술",
    desc: "장애인 게스트를 위한 보조기기.",
    value: "C",
  },
  {
    id: 4,
    title: "청각 장애인 배려",
    desc: "청각 장애인 게스트와 소통할 수 있는 기술.",
    value: "D",
  },
  {
    id: 5,
    title: "청각적 또는 언어적 정보",
    desc: "시각 장애인을 위한 언어 커뮤니케이션.",
    value: "E",
  },
];
let prerequisite = {
  gl_guest_talk_method: [], //커뮤니케이션 지원기능 A|B|C|D|E
  gl_guest_age_limit: 1, //최소연령
  gl_agree_minor_yn: "N", //미성년 게스트 동의
  gl_guest_baby_yn: "N", // 2세유아가능
  gl_guest_need_memo: "", //추가 요건
  gl_guest_confirm: "N", // 게스트 본인인증
};

export default function GuideRegistProfilePrerequisite(props) {
  const { guideData } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isShowQuestion, setIsShowQuestion] = useState(
    guideData.gl_guest_talk_method.length > 0
  );

  (() => {
    if (guideData) {
      for (let key in prerequisite) {
        prerequisite[key] = guideData.hasOwnProperty(key)
          ? guideData[key]
          : prerequisite[key];
      }
    }
  })();

  const checkTalkMethode = (e, value) => {
    let checked = e.target.checked;
    if (checked) {
      prerequisite.gl_guest_talk_method.push(value);
    } else {
      let idx = prerequisite.gl_guest_talk_method.findIndex(
        (item) => item === value
      );
      idx > -1 && prerequisite.gl_guest_talk_method.splice(idx, 1);
    }
    handlePrerequisiteChange(
      "gl_guest_talk_method",
      prerequisite.gl_guest_talk_method
    );
  };

  const handlePrerequisiteChange = (key, value) => {
    prerequisite[key] = value;
    setIsNeedUpdate(!isNeedUpdate);
    guideDataChange();
  };

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      prerequisite,
      () => {}
    );
  };

  return (
    <div className="col-xl-6">
      <div className="py-3">
        <h6 className="font-weight-bold mb-2">
          여행에 참여할 수 있는 게스트의 연령조건은 무엇인가요?
        </h6>
        <p>
          다른 사람을 위해 가이드를 예약하는 게스트도 있다는 점을 기억하세요.
          참여에 엄격한 나이 제한, 필요한 준비물이 있다면 알려주세요.
        </p>
      </div>
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold mb-2">최소연령</h6>
        <p>
          게스트 연령 제한을 설정하세요. 미성년자는 법적 보호자가 동반해야만
          참여할 수 있습니다.
        </p>
        <div className="py-2">
          <select
            className="form-control"
            defaultValue={prerequisite.gl_guest_age_limit}
            disabled={guideData.gl_confirm_admin === "S"}
            onChange={(e) => {
              handlePrerequisiteChange("gl_guest_age_limit", e.target.value);
            }}
          >
            {(() => {
              let options = [];
              for (let i = 21; i > 0; i--) {
                options.push(
                  <option key={i} value={i}>
                    {i}세
                  </option>
                );
              }
              return options;
            })()}
          </select>
        </div>
        <div className="border rounded p-3 my-2">
          <p className="font-weight-bold">미성년 게스트 호스팅하기</p>
          <p className="my-2">
            어린이에게 적합한 체험으로 등록하는 경우, 가이드는 참여 게스트의
            나이에 적합하게 활동을 계획할 책임이 있습니다.{" "}
            <span>자세히 알아보기</span>
          </p>
          <label>
            <input
              type="checkbox"
              className="mr-2"
              defaultChecked={prerequisite.gl_agree_minor_yn === "Y"}
              disabled={guideData.gl_confirm_admin === "S"}
              onChange={(e) => {
                handlePrerequisiteChange(
                  "gl_agree_minor_yn",
                  e.target.checked ? "Y" : "N"
                );
              }}
            />{" "}
            위 사항에 동의함.
          </label>
        </div>

        <label>
          <input
            type="checkbox"
            className="mr-2"
            defaultChecked={prerequisite.gl_guest_baby_yn === "Y"}
            disabled={guideData.gl_confirm_admin === "S"}
            onChange={(e) => {
              handlePrerequisiteChange(
                "gl_guest_baby_yn",
                e.target.checked ? "Y" : "N"
              );
            }}
          />{" "}
          2세 미만 유아 동반가능
        </label>
      </div>

      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold mb-2">
          가이드에 접근성 기능이 있나요? (선택사항)
        </h6>
        <p>
          제공하는 기능선택한 후, 모든 정보가 정확하고 최신 상태인지 확인하세요.
        </p>
        <div className="py-3">
          <label>
            <input
              type="checkbox"
              className="mr-2"
              defaultChecked={prerequisite.gl_guest_talk_method.length > 0}
              disabled={guideData.gl_confirm_admin === "S"}
              onChange={(e) => {
                if (!e.target.checked) {
                  handlePrerequisiteChange("gl_guest_talk_method", []);
                }
                setIsShowQuestion(e.target.checked);
              }}
            />{" "}
            의사소통 관련 접근성 기능
          </label>
        </div>
      </div>

      {isShowQuestion && (
        <div className="py-3 border-bottom">
          <h6 className="font-weight-bold mb-2">커뮤니케이션 지원 기능</h6>
          <p>체험에 참여한 게스트와 소통하는 방법에 대해 생각해보세요.</p>
          <div className="py-3">
            {talkMethodList.map((tm) => {
              let isCheck =
                prerequisite.gl_guest_talk_method.findIndex(
                  (item) => item === tm.value
                ) > -1;

              return (
                <div key={tm.value}>
                  <label>
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={tm.value}
                      defaultChecked={isCheck}
                      disabled={guideData.gl_confirm_admin === "S"}
                      onChange={(e) => checkTalkMethode(e, tm.value)}
                    />{" "}
                    {tm.title}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold mb-2">추가 요건(선택 사항)</h6>
        <p>
          여행을 하기에 앞서 게스트에게 필요한 조건이 무엇인지 생각해보세요.
        </p>
        <div className="py-3">
          <textarea
            rows={5}
            className="form-control"
            defaultValue={prerequisite.gl_guest_need_memo}
            disabled={guideData.gl_confirm_admin === "S"}
            placeholder={
              "ex) 스쿠버다이빙 전문 버디로 여행에 참여하는 게스트는 개인 잠수복과 자격이 필요합니다."
            }
            onChange={(e) => {
              handlePrerequisiteChange("gl_guest_need_memo", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="py-3">
        <h6 className="font-weight-bold mb-2">게스트 본인 인증 필요</h6>
        <p>
          게스트 본인 인증을 요청하실 경우, 대표 예약자가 인증을 완료해야만
          예약자 및 동반 게스트가 참가할 수 있습니다.
        </p>
        <div className="py-3">
          <label>
            <input
              type="checkbox"
              className="mr-2"
              defaultChecked={prerequisite.gl_guest_confirm === "Y"}
              disabled={guideData.gl_confirm_admin === "S"}
              onChange={(e) => {
                handlePrerequisiteChange(
                  "gl_guest_confirm",
                  e.target.checked ? "Y" : "N"
                );
              }}
            />{" "}
            예약자의 본인 인증이 필요합니다.
          </label>
        </div>
      </div>
    </div>
  );
}
