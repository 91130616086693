import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function GuideRegistIntroStep({ checkLoginData }) {
  const { t } = useTranslation();

  return (
    <div className="container margin_60">
      <div className="main_title">
        <h2 className="mb-2">
          {t("guideRegistIntroStepTitleStart")}{" "}
          <span>{t("guideRegistIntroStepTitleEnd")}</span>
        </h2>
        <p>{t("guideRegistIntroStepDesc")}</p>
      </div>
      <hr />
      <ul className="cbp_tmtimeline">
        <li>
          <time className="cbp_tmtime" dateTime="09:30">
            <span>STEP</span> <span>01</span>
          </time>
          <div className="cbp_tmicon timeline_icon_point" />
          <div className="cbp_tmlabel">
            <h3 className="mb-2 border-bottom pb-3">
              {t("guideRegistIntroStep1Title")}
            </h3>
            <p>{t("guideRegistIntroStep1Desc")}</p>
          </div>
        </li>
        <li>
          <time className="cbp_tmtime" dateTime="11:30">
            <span>STEP</span> <span>02</span>
          </time>
          <div className="cbp_tmicon timeline_icon_pic" />
          <div className="cbp_tmlabel">
            <h3 className="mb-2 border-bottom pb-3">
              {t("guideRegistIntroStep2Title")}
            </h3>
            <p>{t("guideRegistIntroStep2Desc")}</p>
          </div>
        </li>
        <li>
          <time className="cbp_tmtime" dateTime="13:30">
            <span>STEP</span> <span>03</span>
          </time>
          <div className="cbp_tmicon timeline_icon_break" />
          <div className="cbp_tmlabel">
            <h3 className="mb-2 border-bottom pb-3">
              {t("guideRegistIntroStep3Title")}
            </h3>
            <p>{t("guideRegistIntroStep3Desc")}</p>
          </div>
        </li>
      </ul>
      <div className="d-flex flex-row justify-content-center mt-5">
        <button onClick={checkLoginData} className="btn_1">
          {t("guideRegistIntroCarouselTitle")}
        </button>
      </div>
    </div>
  );
}
