import React from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const info_personal_connection_with_guests = [
  {
    title: "사람들이 함께하는 자리를 마련해 새로운 인연을 만드는 것을 즐김",
    key: "E",
  },
  {
    title: "개인적인 경험을 다른 사람과 공유하는 것을 즐김",
    key: "M",
  },
  {
    title: "게스트와 너무 친밀하게 지내고 싶지는 않음",
    key: "N",
  },
];

const _mClassName = "GuideRegistFindGuideConsensus";
export default function GuideRegistFindGuideConsensus(props) {
  const { guideData } = props;

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = (value) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      { gl_emotion: value },
      () => {}
    );
  };

  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/expectationPage4.jpg")50% 50%/cover no-repeat`,
          }}
        />
        <p>
          사람들 사이의 연결고리를 만들어 모두가 즐거운 시간을 보낼 수 있도록
          도와줄 따뜻하고 친절한 가이드를 찾고 있습니다.
        </p>
      </div>
      <div className="py-3 border-bottom">
        <p>다음과 같은 사항이 여기에 해당 됩니다.</p>
        <ul className="pt-3">
          <li className="mb-3">· 게스트들의 소통과 교감 돕기</li>
          <li className="mb-3">· 개인적 이야기 들려주기</li>
          <li className="mb-3">· 잊을 수 없는 순간 만들기</li>
        </ul>
        <p>
          서로 모르는 사이였던 게스트들이 체험을 통해 친구가 될 수 있어야
          합니다.
        </p>
      </div>

      <div className="py-3">
        <h6 className="font-weight-bold">이전에 가이드하신 적이 있나요?</h6>
        <div className="py-3">
          {info_personal_connection_with_guests.map((item) => {
            return (
              <div key={item.key} className="mb-3">
                <label className="d-flex flex-row align-items-start">
                  <input
                    type="radio"
                    name="info_personal_connection_with_guests"
                    className="mt-1 mr-2"
                    value={item.key}
                    defaultChecked={guideData.gl_chance === item.key}
                    disabled={guideData.gl_confirm_admin === "S"}
                    onChange={(e) => {
                      guideDataChange(e.target.value);
                    }}
                  />{" "}
                  <p>{item.title}</p>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
