import React, { useCallback, useEffect, useRef, useState } from "react";
import BuddibleSocket from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function CommunityDetailReply({
  replyData,
  loginInfo,
  appendReply,
  deleteReply,
}) {
  const { t } = useTranslation();

  const ItemRenderer = useCallback((props) => {
    return <ItemWrapper {...props} />;
  });

  if (replyData.length === 0)
    return (
      <div className="p-4 mt-3 rounded bg-light">
        <p className="font-weight-bold text-secondary text-center">
          {t("communityReplyEmptyNote")}
        </p>
      </div>
    );

  const mainCnt = replyData.length;

  return (
    <div className="mt-2">
      {replyData.map((data, index) => {
        return (
          <ItemRenderer
            key={data.cr_uid}
            data={data}
            index={index}
            mainCnt={mainCnt}
            loginInfo={loginInfo}
            appendReply={appendReply}
            deleteReply={deleteReply}
          />
        );
      })}
    </div>
  );
}

const ItemWrapper = React.memo(
  ({ data, index, loginInfo, mainCnt, appendReply, deleteReply }) => {
    const { t } = useTranslation();
    const item = { ...data };
    const [isShowReply, setIsShowReply] = useState(false);
    const replyRef = useRef();

    return (
      <div
        className={`px-1 py-2 px-md-2 py-md-3 ${
          mainCnt > index + 1 ? "border-bottom" : ""
        }`}
      >
        <Item data={data} loginInfo={loginInfo} deleteReply={deleteReply} />
        {item.replySub &&
          item.replySub.length > 0 &&
          item.replySub.map((item) => {
            return (
              <Item
                key={item.cr_uid}
                data={item}
                loginInfo={loginInfo}
                deleteReply={deleteReply}
              />
            );
          })}
        {item.cr_del_yn === "N" && (
          <div>
            {isShowReply ? (
              <div>
                <div className="mb-2 p-2 h6">
                  <div className="d-flex flex-row">
                    <div style={{ width: 40 }} className="mr-3" />

                    <textarea
                      className="border-0 flex-grow-1"
                      placeholder={t("communityReplyEmptyNote")}
                      ref={replyRef}
                    />
                  </div>
                </div>
                <div className="text-right">
                  <button
                    className="btn_1 bg-secondary mr-2"
                    onClick={() => {
                      setIsShowReply(false);
                    }}
                  >
                    {t("cancel")}
                  </button>
                  <button
                    className="btn_1"
                    onClick={() => {
                      appendReply(
                        item.cr_main_uid,
                        item.cr_uid,
                        replyRef.current.value
                      );
                      setIsShowReply(false);
                    }}
                  >
                    {t("registration")}
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex">
                <div style={{ width: 40 }} className="mr-3" />
                <button
                  className="border-0 bg-transparent font-weight-bold text-secondary"
                  disabled={!loginInfo}
                  onClick={() => setIsShowReply((before) => !before)}
                >
                  <i className="fa-solid fa-arrow-turn-up rotate-90 mr-2" />{" "}
                  {t("communityAddReply")}
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

const Item = React.memo(({ data, loginInfo, deleteReply }) => {
  const item = { ...data };
  const { t } = useTranslation();
  const isLoginUser = loginInfo && loginInfo.u_uid === item.cr_u_uid;
  let profileImage = util.changedResourceUrl(item.cr_u_avata_pic);

  if (item.cr_del_yn === "Y") {
    profileImage = codeList.publicVar.unProfileImage;
    item.cr_u_name_full = "unnamed";
    item.cr_reply = t("communityDeleteReplyMsg");
  }

  return (
    <>
      <div className="d-flex flex-row justify-content-between mb-2">
        <div>
          <img
            src={profileImage}
            alt={profileImage}
            width={40}
            height={40}
            className="rounded-circle"
            style={{ objectFit: "cover", minWidth: 40 }}
          />
        </div>
        <div className="flex-grow-1 mx-3">
          <p className="font-weight-bold">{item.cr_u_name_full}</p>
          <p>{item.cr_create_date}</p>
        </div>
        <div>
          {isLoginUser && item.cr_del_yn === "N" && (
            <button
              className="btn btn-sm btn-light font-size-06 font-weight-bold text-secondary"
              onClick={() => deleteReply(item)}
            >
              {t("delete")} <i className="fa-regular fa-trash-can ml-2" />
            </button>
          )}
        </div>
      </div>
      <div className="d-flex flex-row">
        <div style={{ width: 40 }} className="mr-3" />
        <div className="flex-grow-1">
          <p className="h6 mb-3 bg-light p-2 rounded">{item.cr_reply}</p>
        </div>
      </div>
    </>
  );
});
