import React, { useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import UploadImageToS3WithReactS3 from "../../../lib/UploadImageToS3WithReactS3";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "ChatRoomFooter";

let sendFiles = [];

export default function ChatRoomFooter(props) {
  const { _loginInfo, item } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const msgInput = useRef();

  // 파일업로드
  const uploadFileCallback = async (response) => {
    if (!response.ret) {
      codeList.Modal.current.alert("오류가 발생했습니다 다시 시도해주세요");
      return;
    }
    const file = `${response.fileName}?${response.realFileName}`;
    await sendFiles.push(file);
    setIsNeedUpdate((prevState) => !prevState);
  };

  // 전송파일 삭제
  const deleteUploadFile = (index) => {
    if (index < 0) return;
    sendFiles.splice(index, 1);
    setIsNeedUpdate((prevState) => !prevState);
  };

  // 메세지 전송
  const sendMessage = () => {
    let msgID = util.makeUUIDv4();
    let fileMsg = sendFiles.length > 0;
    if (!fileMsg && msgInput.current.value.trim() === "") return;

    const msgData = {
      crm_main_uid: item.crm_main_uid,
      crt_main_uid: item.crm_main_uid,
      crt_msg_uid: msgID,
      crt_type: fileMsg ? "2" : "1",
      crt_msg: msgInput.current.value,
      crt_file: fileMsg ? sendFiles.join("|") : "",
      crt_sender_uid: _loginInfo.u_uid,
      crt_del_yn: "N",
    };

    msgInput.current.value = "";
    sendFiles = [];
    socket.fireLocalEventListener(
      MsgIDList.EVENT_CHAT_MESSAGE_SEND,
      _mClassName,
      msgData,
      () => {}
    );
  };

  const FileItem = ({ file }) => {
    const fileSplit = file.split("?");
    const url = fileSplit[0];
    const name = fileSplit[1];
    const fileContent = util.checkFileType(name);
    const index = sendFiles.findIndex((f) => f === file);

    return (
      <div className="p-1">
        <button
          className="bg-transparent"
          onClick={() => deleteUploadFile(index)}
        >
          {fileContent.isImage && (
            <img src={url} alt={name} className="square-50 rounded avata_pic" />
          )}
          {!fileContent.isImage && (
            <div className="mr-2">
              <span className="text-buddible">
                <i className={fileContent.icon} />
              </span>
              <span className="ml-2">{name}</span>
            </div>
          )}
        </button>
      </div>
    );
  };

  return (
    <div className="chat-footer position-relative border-top px-3 child-center">
      {sendFiles.length > 0 && (
        <div
          className="position-absolute py-3 px-2 border-top border-bottom bg-white w-100"
          style={{ bottom: "100%" }}
        >
          <p className="font-weight-bold">📁 첨부파일</p>
          <div className="d-flex flex-row flex-wrap">
            {sendFiles
              .filter((f) => util.checkFileType(f).isImage)
              .map((file) => {
                return <FileItem key={file} file={file} />;
              })}
          </div>
          <div className="d-flex flex-row flex-wrap">
            {sendFiles
              .filter((f) => !util.checkFileType(f).isImage)
              .map((file) => {
                return <FileItem key={file} file={file} />;
              })}
          </div>
        </div>
      )}
      <div className="w-100 d-flex flex-row justify-content-between align-items-center">
        <UploadImageToS3WithReactS3
          region={`playyj/chat/${item.crm_main_uid}`}
          callback={uploadFileCallback}
          multi={true}
        >
          <span className="square-30 rounded-circle child-center bg-transparent cursor_pointer cursor_pointer_bg h6 mb-0">
            <i className="fa-regular fa-folder" />
          </span>
        </UploadImageToS3WithReactS3>
        <div className="flex-grow-1 px-3">
          <textarea
            className="w-100 border-0 height-30 bg-light rounded p-1"
            placeholder="메세지를 입력해주세요"
            rows={1}
            ref={msgInput}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                sendMessage();
              }
            }}
          />
        </div>
        <button
          className="square-30 rounded-circle bg-transparent cursor_pointer_bg h6 mb-0"
          onClick={sendMessage}
        >
          <i className="fa-regular fa-paper-plane" />
        </button>
      </div>
    </div>
  );
}
