import React from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "GuideRegistFindGuideOpportunity";

const info_score_insider_access_theme = [
  {
    title: "매우 독특해서 제 도움 없이는 게스트가 할 수 없는 여행입니다.",
    key: "E",
  },
  {
    title:
      "게스트가 스스로 할 수 있는 여행이지만, 저는 이에 대한 독특한 시각을 갖고 있습니다.",
    key: "M",
  },
  {
    title: "가이드의 도움 없이도 게스트가 스스로 할 수 있는 여행입니다.",
    key: "N",
  },
];

export default function GuideRegistFindGuideOpportunity(props) {
  let { guideData, loginInfo } = props;

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = (value) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      { gl_chance: value },
      () => {}
    );
  };

  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 min-height-350 w-100"
          style={{
            background: `url("/images/ExpectationPage3.jpg")50% 50%/cover no-repeat`,
          }}
        />
        <p>
          게스트가 다른 곳에서는 경험할 수 없는, 도시의 특별한 면모를 보여줄 수
          있는 '인사이더' 가이드를 찾고 있습니다.
        </p>
      </div>
      <div className="py-3 border-bottom">
        <p>다음과 같은 사항이 여기에 해당 됩니다.</p>
        <ul className="pt-3">
          <li className="mb-3">· 현지인만 아는 장소</li>
          <li className="mb-3">· 흥미를 자극하는 사람들</li>
          <li className="mb-3">· 쉽게 찾을 수 없는 특별한 물품</li>
        </ul>
        <p>게스트는 뻔한 관광 코스가 아닌 색다른 것을 경험하고 싶어 합니다.</p>
      </div>

      <div className="py-3">
        <h6 className="font-weight-bold">
          다음 중 가이드의 여행을 가장 잘 설명하는 것을 골라주세요.
        </h6>
        <div className="py-3">
          {info_score_insider_access_theme.map((item) => {
            return (
              <div key={item.key} className="mb-3">
                <label className="d-flex flex-row align-items-start">
                  <input
                    type="radio"
                    name="info_score_insider_access_theme"
                    className="mr-2 mt-1"
                    value={item.key}
                    defaultChecked={guideData.gl_chance === item.key}
                    disabled={guideData.gl_confirm_admin === "S"}
                    onChange={(e) => {
                      guideDataChange(e.target.value);
                    }}
                  />{" "}
                  <p>{item.title}</p>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
