import React, { useEffect, useRef, useState } from "react";

import { uploadFile } from "react-s3";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import Utilities from "../../../lib/Utilities";
import UploadImageToS3WithReactS3 from "../../../lib/UploadImageToS3WithReactS3";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "MyPageProfile";

const S3_BUCKET = "buddible";

const ACCESS_KEY = "AKIAXLYEGEZ72GFMRNOV";
const SECRET_ACCESS_KEY = "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";
const REGION = "ap-northeast-2";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
};

let imgUrl = null;

export default function MyPageProfile(props) {
  const { loginInfo, country_list, language_list, updateUserProfile } = props;
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const $controllers = {
    u_email: useRef(),
    u_name_first: useRef(),
    u_name_last: useRef(),
    u_phone: useRef(),
    u_gender: useRef(),
    u_birthday: useRef(),
    u_addr_country_cd: useRef(),
    u_language: useRef(),
    u_avata_pic: useRef(),
  };

  useEffect(() => {
    if (loginInfo) {
      imgUrl =
        util.changedResourceUrl(loginInfo.u_avata_pic) ||
        codeList.publicVar.unProfileImage;
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  //파일첨부 업데이트
  const uploadCallback = (data) => {
    if (data.ret) {
      $controllers.u_avata_pic.current.src = data.fileName;
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      codeList.Modal.current.alert(t("errorMsg"));
    }
  };

  //프로필 정보 저장
  const actionSave = () => {
    let data = {};
    for (let key in $controllers) {
      if ($controllers[key].current) {
        if (key === "u_avata_pic") {
          data[key] = $controllers[key].current.src;
        } else {
          data[key] = $controllers[key].current.value;
        }
      }
    }
    data.u_uid = loginInfo.u_uid;
    if (!data.u_uid) {
      codeList.Modal.current.alert(t("errorMsg"));
      return;
    }
    codeList.Modal.current.confirm(t("confirmChangeProfileMsg"), (ret) => {
      if (ret) {
        updateUserProfile(data);
      }
    });
  };

  return (
    <>
      <div className="box_style_1 shadow-none">
        <div className="row mb-3">
          <div className="col-12">
            <h4 className="mb-0">{t("myPageProfile")}</h4>
            <p>{t("myPageProfileDesc")}</p>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 my-3 d-flex flex-row justify-content-center"
            style={{
              height: "150px",
            }}
          >
            <UploadImageToS3WithReactS3
              region={"images/profile"}
              callback={uploadCallback}
            >
              <div
                className="position-relative"
                style={{
                  height: "150px",
                  width: "150px",
                }}
              >
                <img
                  src={imgUrl}
                  style={{
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  className="img-fluid styled m-0 p-0"
                  ref={$controllers.u_avata_pic}
                />

                <div
                  className="position-absolute d-flex flex-row justify-content-center align-items-center rounded-circle border cursor_pointer bg-white"
                  style={{
                    left: "75%",
                    top: "80%",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <i className="icon-camera" />
                </div>
              </div>
            </UploadImageToS3WithReactS3>
          </div>

          <div className="col-lg-12">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("firstName")}</label>
                  <input
                    className="form-control mt-1"
                    type="text"
                    ref={$controllers.u_name_last}
                    defaultValue={
                      loginInfo !== null ? loginInfo.u_name_last : ""
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("lastName")}</label>
                  <input
                    className="form-control mt-1"
                    type="text"
                    ref={$controllers.u_name_first}
                    defaultValue={
                      loginInfo !== null ? loginInfo.u_name_first : ""
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("email")}</label>
                  <input
                    className="form-control mt-1"
                    type="email"
                    ref={$controllers.u_email}
                    defaultValue={loginInfo !== null ? loginInfo.u_email : ""}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("tel")}</label>
                  <input
                    className="form-control mt-1"
                    type="tel"
                    ref={$controllers.u_phone}
                    defaultValue={loginInfo !== null ? loginInfo.u_phone : ""}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("gender")}</label>
                  <select
                    className="form-control mt-1"
                    ref={$controllers.u_gender}
                    defaultValue={loginInfo !== null ? loginInfo.u_gender : ""}
                  >
                    <option value="M">{t("male")}</option>
                    <option value="F">{t("female")}</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("birthday")}</label>
                  <input
                    type="date"
                    className="form-control mt-1"
                    placeholder={t("birthday")}
                    ref={$controllers.u_birthday}
                    defaultValue={
                      loginInfo !== null ? loginInfo.u_birthday : ""
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("nationality")}</label>
                  <select
                    className="form-control mt-1"
                    ref={$controllers.u_addr_country_cd}
                    defaultValue={loginInfo.u_addr_country_cd}
                  >
                    <option value="">{t("unselected")}</option>
                    {country_list &&
                      Object.values(country_list).map((item) => {
                        return (
                          <option
                            key={item.id + "_" + item.name}
                            value={item.id}
                          >
                            {item.name_ko} [{item.name}]
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{t("useLanguage")}</label>
                  <select
                    className="form-control mt-1"
                    ref={$controllers.u_language}
                    defaultValue={loginInfo.u_language}
                  >
                    <option value="">{t("unselected")}</option>
                    {language_list &&
                      Object.values(language_list).map((item) => {
                        return (
                          <option
                            key={item.id + "_" + item.name}
                            value={item.id}
                          >
                            {item.name_ko} [{item.name}]
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div
            className="col-md-12"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <button
              type="submit"
              className="btn_1"
              onClick={() => actionSave()}
            >
              {t("save")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
