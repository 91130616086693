import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ko } from "date-fns/esm/locale";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

import MainLogoSVG from "../../lib/MainLogoSVG";
import LoginModal from "./component/LoginModal";
import RegistModal from "./component/RegistModal";
import CountrySelect from "../../lib/CountrySelect";
import DatePicker from "react-datepicker";
import FindPWDModal from "./component/FindPWDModal";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const mClassName = "Nav";

const languages = [
  {
    id: "EN",
    name: "English",
    name_ko: "영어",
  },
  {
    id: "KO",
    name: "Korean",
    name_ko: "한국어",
  },
];

let userInfo = null;
let isLogin = false;
let mobileNavClass = "";
let searchOpenBackup = "";

let submenuClass = {
  searchGuide: "",
  dataCenter: "",
};

let plainPage = [
  "/SearchMap",
  "/NewsMain/",
  "/GuideTipMain/",
  "/FAQ/",
  "/CommunityWrite/",
  "/ReserveService/",
  "/MyPage",
  "/GuideTipDetail",
  "/TravelTipDetail",
  "/NewsDetail",
  "/GuideDetail",
  "/TourDetail",
  "/CommunityWrite",
  "/CommunityDetail",
  "/CommunityList",
  "/CommunityMain/",
];
export default function Nav(props) {
  const { t, i18n } = useTranslation();
  const [searchOpen, setSearchOpen] = useState("");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [headerID, setHeaderID] = useState("");
  const [selectedLang, setSelectedLang] = useState({
    id: "KO",
    name: "Korean",
    name_ko: "한국어",
  });
  const history = useHistory();

  (() => {
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      userInfo = loginData;
      isLogin = true;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      mClassName,
      (b, n) => {
        if (!n) return;
        userInfo = n;
        isLogin = true;
      }
    );
  })();

  useEffect(() => {
    if (window.innerWidth < 992) {
      let layer = document.getElementById("layer");
      if (mobileNavClass === "show") {
        submenuClass = {
          searchGuide: "",
          dataCenter: "",
        };
        layer.className = "layer";
        mobileNavClass = "";
        setIsMobileMenuOpen(!isMobileMenuOpen);
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    let _headerID = "";
    for (let i = 0; i < plainPage.length; i++) {
      if (window.location.pathname.indexOf(plainPage[i]) > -1) {
        _headerID = "plain";
        break;
      }
    }
    setHeaderID(_headerID);

    window.addEventListener("scroll", handleIsScroll);
    window.addEventListener("resize", resizeMobileNavHandler);

    return () => {
      let layer = document.getElementById("layer");
      if (mobileNavClass === "show") {
        submenuClass = {
          searchGuide: "",
          dataCenter: "",
        };
        layer.className = "layer";
        mobileNavClass = "";
        setIsMobileMenuOpen(!isMobileMenuOpen);
      }

      window.removeEventListener("scroll", handleIsScroll);
      window.removeEventListener("resize", resizeMobileNavHandler);

      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        mClassName
      );
    };
  }, []);

  //스크롤 여부확인
  const handleIsScroll = () => {
    let windowScroll = window.scrollY;

    if (windowScroll >= 6) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  //검색창 open close
  const handleSearchOpen = () => {
    searchOpenBackup = searchOpenBackup ? "" : "open";
    setSearchOpen(searchOpenBackup);
  };

  //로그인 여부 핸들러
  const handleLogin = (value) => {
    isLogin = value;
    setIsNeedUpdate(!isNeedUpdate);
  };

  //모바일 nav 핸들러
  const resizeMobileNavHandler = () => {
    if (window.innerWidth >= 992) {
      let layer = document.getElementById("layer");
      layer.className = "layer";
      mobileNavClass = "";
      setIsNeedUpdate(!isNeedUpdate);
    }
  };

  //모바일 nav 활성화 핸들러
  const mobileNavHandler = () => {
    if (window.innerWidth >= 992) return;
    let layer = document.getElementById("layer");
    if (mobileNavClass === "") {
      layer.className = "layer layer-is-visible";
      mobileNavClass = "show";
    } else {
      submenuClass = {
        searchGuide: "",
        dataCenter: "",
      };
      layer.className = "layer";
      mobileNavClass = "";
    }
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  //모바일 nav submenu 열기 닫기
  const handleMobileSubMenu = (className) => {
    submenuClass = {
      ...submenuClass,
      [className]: submenuClass[className] ? "" : "show_normal",
    };
    setIsNeedUpdate(!isNeedUpdate);
  };

  //로그아웃
  const logout = (e) => {
    e.preventDefault();
    codeList.Modal.current.confirm("로그아웃 하시겠습니까?", (ret) => {
      if (ret) {
        userInfo = null;
        sessionStorage.removeItem("loginInfo");
        localStorage.removeItem("loginInfo");
        window.location.replace("/");
      }
    });
  };

  // 언어변경
  const changedLanguage = (e, item) => {
    e.preventDefault();
    let id = item.id.toLowerCase();
    i18n.changeLanguage(id, (err, t) => {});

    setSelectedLang(item);
  };

  let confirmClass = "badge-secondary";
  let confirm = t("confirmStatusWrite");
  if (userInfo) {
    if (userInfo.gl_confirm_admin === "W") {
      confirmClass = "badge-secondary";
      confirm = t("confirmStatusWrite");
    }
    if (userInfo.gl_confirm_admin === "S") {
      confirmClass = "badge-primary";
      confirm = t("confirmStatusSubmit");
    }
    if (userInfo.gl_confirm_admin === "Y") {
      confirmClass = "badge-success";
      confirm = t("confirmStatusAgree");
    }
    if (userInfo.gl_confirm_admin === "N") {
      confirmClass = "badge-danger";
      confirm = t("confirmStatusReject");
    }
    if (userInfo.gl_confirm_admin === "C") {
      confirmClass = "badge-warning";
      confirm = t("confirmStatusCancel");
    }
  }

  return (
    <>
      <LoginModal handleLogin={handleLogin} />
      <RegistModal />
      <FindPWDModal />
      <SearchOverlay
        searchOpen={searchOpen}
        handleSearchOpen={handleSearchOpen}
      />
      <header id={headerID} className="vw-100">
        <div id="top_line" className="visible_on_mobile">
          <div className="container">
            <div className="row p-0 m-0">
              <div className="col-12 p-0 m-0">
                <ul id="top_links">
                  <li>
                    {isLogin && (
                      <a
                        className={`d-block`}
                        href="#"
                        onClick={(e) => {
                          logout(e);
                        }}
                      >
                        <i className="fa-solid fa-arrow-right-from-bracket" />{" "}
                        {t("logout")}
                      </a>
                    )}

                    {!isLogin && (
                      <a
                        href="#"
                        data-toggle="modal"
                        data-target="#loginModal"
                        className={`d-block`}
                      >
                        <i className="fa-solid fa-user" /> {t("login")}
                      </a>
                    )}
                  </li>
                  <li>
                    <div className="dropdown dropdown-mini">
                      <a
                        href="#"
                        data-toggle="dropdown"
                        id="lang_link"
                        className={headerID !== "" || isScroll ? "" : ""}
                      >
                        Languages
                        {/*{selectedLang.id === "KO"
                          ? selectedLang.name_ko
                          : selectedLang.name}*/}
                      </a>
                      <div className="dropdown-menu">
                        <ul id="lang_menu">
                          {languages.length > 0 &&
                            languages.map((lang) => {
                              return (
                                <li key={lang.id}>
                                  <a
                                    href="#0"
                                    onClick={(e) => changedLanguage(e, lang)}
                                  >
                                    {lang.name}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </li>
                  {/*

                  <li>
                    <div className="dropdown dropdown-mini">
                      <a
                        href="#"
                        data-toggle="dropdown"
                        id="currency_link"
                        className={
                          headerID !== "" || isScroll ? "" : ""
                        }
                      >
                        KRW
                      </a>
                      <div className="dropdown-menu">
                        <ul id="lang_menu">
                          <li>
                            <a href="#0">USD</a>
                          </li>
                          <li>
                            <a href="#0">GBP</a>
                          </li>
                          <li>
                            <a href="#0">EUR</a>
                          </li>
                          <li>
                            <a href="#0">CHF</a>
                          </li>
                          <li>
                            <a href="#0">BRL</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className={`row p-0 m-0`}>
            <div className="col-auto p-0 m-0 mr-3">
              <div className="w-100 h-100 d-flex align-items-center">
                <Link to="/" className="d-xl-none">
                  <MainLogoSVG
                    width={180}
                    fill={isScroll || headerID !== "" ? "#004060" : "#ffffff"}
                    isShadow={!isScroll && headerID === ""}
                  />
                </Link>
                <Link to="/" className="d-none d-xl-inline-block">
                  <MainLogoSVG
                    width={240}
                    fill={isScroll || headerID !== "" ? "#004060" : "#ffffff"}
                    isShadow={!isScroll && headerID === ""}
                  />
                </Link>
              </div>
            </div>
            <nav className="col p-0 m-0">
              <a
                className={`cmn-toggle-switch cmn-toggle-switch__htx mt-xl-3`}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  mobileNavHandler();
                }}
              >
                <span>Menu mobile</span>
              </a>

              <div
                className={`main-menu mt-lg-3 d-flex flex-column d-md-block ${mobileNavClass}`}
              >
                <div className={`d-lg-none mb-2 p-2`}>
                  <Link to="/">
                    <MainLogoSVG />
                  </Link>
                  <a href="#" className="open_close" id="close_in">
                    <i
                      className="icon_set_1_icon-77"
                      onClick={() => {
                        mobileNavHandler();
                      }}
                    />
                  </a>
                </div>
                <ul className="position-relative flex-fill">
                  <li className="submenu">
                    <a
                      href="#"
                      className={`show-submenu`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleMobileSubMenu("searchGuide");
                      }}
                    >
                      {t("travelQuest")}{" "}
                      <i
                        className={
                          submenuClass.searchGuide
                            ? "icon-up-open-mini"
                            : "icon-down-open-mini"
                        }
                      />
                    </a>
                    <ul className={submenuClass.searchGuide}>
                      <li>
                        <Link to="/TourList/hotPlace">{t("hotPlace")}</Link>
                      </li>
                      <li>
                        <Link to="/TourList/experience">{t("experience")}</Link>
                      </li>
                      <li>
                        <Link to="/TourList/food">{t("food")}</Link>
                      </li>
                      <li>
                        <Link to="/TourList/cultureAndShopping">
                          {t("cultureAndShopping")}
                        </Link>
                      </li>

                      <li>
                        <Link to="/TourDetailList">{t("searchTour")}</Link>
                      </li>
                      <li>
                        <Link to="/SearchMap">{t("myLocation")}</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="submenu">
                    <a
                      href="#"
                      className={`show-submenu`}
                      onClick={(e) => {
                        e.preventDefault();
                        handleMobileSubMenu("dataCenter");
                      }}
                    >
                      {t("community")}
                      <i
                        className={
                          submenuClass.dataCenter
                            ? "icon-up-open-mini"
                            : "icon-down-open-mini"
                        }
                      />
                    </a>

                    <ul className={submenuClass.dataCenter}>
                      <li>
                        <Link to="/CommunityMain">{t("communityAll")}</Link>
                      </li>
                      <li>
                        <Link to="/CommunityList/NOTICE">{t("notice")}</Link>
                      </li>
                      <li>
                        <Link to="/CommunityList/NEWS">{t("news")}</Link>
                      </li>
                      <li>
                        <Link to="/CommunityList/GUIDE">{t("guideTip")}</Link>
                      </li>
                      <li>
                        <Link to="/CommunityList/GUEST">{t("guestTip")}</Link>
                      </li>
                      <li>
                        <Link to="/CommunityList/FORUM">
                          {t("communityForum")}
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to="/FAQ/FAQ01">{t("faq")}</Link>
                  </li>
                  <li>
                    <Link to="/GuideRegistIntro">{t("registGuide")}</Link>
                  </li>

                  {/*모바일 개인정보 관리*/}
                  <li
                    className="d-block d-sm-none position-absolute w-100"
                    style={{ bottom: 0 }}
                  >
                    {isLogin && (
                      <div>
                        <strong>
                          <a
                            className="d-inline-block w-100 border-bottom"
                            href="#"
                            onClick={(e) => {
                              logout(e);
                            }}
                          >
                            <i className="fa-solid fa-right-from-bracket" />
                            {t("logout")}
                          </a>
                        </strong>
                        <strong>
                          <Link
                            className="d-inline-block w-100 border-bottom"
                            to="/MyPage"
                          >
                            <i className="fa-solid fa-user" />
                            {t("myPage")}
                          </Link>
                        </strong>
                        {/*            <strong>
                          <Link
                            className="d-inline-block w-100 border-bottom"
                            to="/MyPage"
                          >
                            <i className="fa-solid fa-user" />
                            {t("chat")}
                          </Link>
                        </strong>*/}
                        {userInfo && userInfo.gl_guide_uid && (
                          <>
                            <strong>
                              <Link
                                to="/GuideRegist"
                                className="d-inline-block w-100 border-bottom"
                              >
                                <i className="fa-solid fa-list-check" />
                                {t("guideProfileManagement")}
                              </Link>
                            </strong>
                            <strong>
                              <Link
                                to="/GuideManagement"
                                className="d-inline-block w-100 border-bottom"
                              >
                                <i className="fa-solid fa-list-check" />
                                {t("tourManagement")}
                              </Link>
                            </strong>
                          </>
                        )}
                      </div>
                    )}
                    {!isLogin && (
                      <div>
                        <strong>
                          <a
                            className="d-inline-block w-100 border-bottom"
                            href="#"
                            data-toggle="modal"
                            data-target="#loginModal"
                          >
                            <i className="fa-solid fa-right-to-bracket" />
                            {t("login")}
                          </a>
                        </strong>
                        <strong>
                          <a
                            href="#"
                            data-toggle="modal"
                            data-target="#registModal"
                            id="registModalBtn"
                          >
                            <i className="fa-solid fa-user-plus" />
                            {t("join")}
                          </a>
                        </strong>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
              <ul id="top_tools" className="mt-xl-3">
                <li>
                  <span
                    onClick={handleSearchOpen}
                    className={`search-overlay-menu-btn cursor_pointer ${
                      headerID !== "" || isScroll ? "text-dark" : " text-white"
                    }`}
                  >
                    <i className={`icon_search `} />
                  </span>
                </li>
                <li>
                  <div className="dropdown dropdown-cart">
                    <a href="#" data-toggle="dropdown" className={`cart_bt`}>
                      <i className="fa-solid fa-user" />
                    </a>
                    <ul className="dropdown-menu" id="cart_items">
                      <li>
                        <>
                          <strong className={isLogin ? "d-block" : "d-none"}>
                            <a
                              href="#"
                              style={{ margin: "5px 0" }}
                              onClick={(e) => {
                                logout(e);
                              }}
                            >
                              {t("logout")}
                            </a>
                          </strong>
                          <strong className={isLogin ? "d-block" : "d-none"}>
                            <Link to="/MyPage" style={{ margin: "5px 0" }}>
                              {t("myPage")}
                            </Link>
                          </strong>
                          {/*<strong className={isLogin ? "d-block" : "d-none"}>
                            <Link to="/Chat" style={{ margin: "5px 0" }}>
                              {t("chat")}
                            </Link>
                          </strong>*/}
                          {userInfo && userInfo.gl_guide_uid && (
                            <>
                              <strong className="w-100 d-flex flex-row justify-content-between align-items-center">
                                <Link
                                  to="/GuideRegist"
                                  style={{ margin: "5px 0" }}
                                  className="flex-fill"
                                >
                                  {t("guideProfileManagement")}
                                </Link>

                                <span
                                  className={`badge badge-pill ${confirmClass}`}
                                >
                                  {confirm}
                                </span>
                              </strong>
                              <strong>
                                <Link
                                  to="/GuideManagement"
                                  style={{ margin: "5px 0" }}
                                >
                                  {t("tourManagement")}
                                </Link>
                              </strong>
                            </>
                          )}
                        </>
                        <>
                          <strong className={!isLogin ? "d-block" : "d-none"}>
                            <a
                              href="#"
                              style={{ margin: "5px 0" }}
                              data-toggle="modal"
                              data-target="#loginModal"
                            >
                              {t("login")}
                            </a>
                          </strong>
                          <strong className={!isLogin ? "d-block" : "d-none"}>
                            <a
                              href="#"
                              style={{ margin: "5px 0" }}
                              data-toggle="modal"
                              data-target="#registModal"
                              id="registModalBtn"
                            >
                              {t("join")}
                            </a>
                          </strong>
                        </>
                      </li>
                      <li>
                        <strong>
                          <Link to="/FAQ">{t("faq")}</Link>
                        </strong>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

function SearchOverlay(props) {
  const { searchOpen, handleSearchOpen } = props;
  const { t } = useTranslation();
  const [selectDate, setSelectDate] = useState(undefined);
  const history = useHistory();

  const controller = {
    rl_addr_country: useRef(),
    SEARCH_DATE: useRef(),
    rl_guest_max: useRef(),
  };

  //DatePicker 커스텀 인풋
  const DatePickerInput = React.forwardRef(({ onClick }, ref) => {
    return (
      <button
        ref={ref}
        onClick={onClick}
        className="w-100 text-left bg-transparent"
        style={{ height: 36 }}
      >
        {selectDate
          ? util.getDateToFormat(selectDate, "YYYY-MM-DD")
          : "날짜 선택"}
      </button>
    );
  });

  const changeDate = (dates) => {
    const [start] = dates;
    controller.SEARCH_DATE.current.setOpen(false);
    setSelectDate(start);
  };

  const actionSearch = () => {
    history.push("/TourDetailList", {
      rl_addr_country: controller.rl_addr_country.current?.state.values,
      SEARCH_DATE: selectDate,
      rl_guest_max: controller.rl_guest_max.current?.value,
    });
    handleSearchOpen();
  };

  return (
    <div className={`search-overlay-menu  ${searchOpen}`}>
      <span className="search-overlay-close" onClick={handleSearchOpen}>
        <i className="icon_set_1_icon-77" />
      </span>
      <div id="search-content" className="bg-white rounded shadow text-left">
        <div className="row m-0 p-0 py-2 py-lg-0">
          <div className="col-12 col-lg-4 m-0 p-2 rounded">
            <div className="px-3">
              <p className="font-weight-bold font-size-08 mb-1">위치</p>
              <CountrySelect
                selectRef={controller.rl_addr_country}
                placeholder="여행지 검색"
                className="border-0 bg-transparent h-auto"
                // callback={(values) => {
                //   let value = values[0]?.id;
                //   changedFilters({ rl_addr_country: value || "" });
                // }}
              />
            </div>
          </div>
          <div className="col-12 col-lg-4 m-0 p-2 rounded">
            <div className="px-3">
              <p className="font-weight-bold font-size-08 mb-1">날짜</p>
              <div className="custom_date_picker">
                <DatePicker
                  onChange={changeDate}
                  ref={controller.SEARCH_DATE}
                  // selected={startDate}
                  // startDate={startDate}
                  // endDate={startDate}
                  selectsRange
                  // selectsStart={isSelectsStart}
                  showPopperArrow={false} //초기 삼각형 존재여부
                  locale={ko} // 달력 언어 설정
                  monthsShown={1} //보여지는 월의 갯수
                  dateFormat="MM월 dd일" // 날짜 표시 형식
                  minDate={new Date()} // 과거 날짜 disable
                  shouldCloseOnSelect={false} //날짜 선택 후 사라지지 않음
                  customInput={<DatePickerInput />} // input 커스텀
                  // calendarContainer={DatePickerContainer}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => {
                    return (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          className="bg-transparent h6 m-0"
                          onClick={decreaseMonth}
                          disabled={prevMonthButtonDisabled}
                        >
                          <i className="fa-solid fa-chevron-left" />
                        </button>
                        <div className="flex-grow-1">
                          <p className="h6 font-weight-bold mb-0 text-center text-dark">
                            {date.getFullYear()}년 {date.getMonth() + 1}월
                          </p>
                        </div>
                        <button
                          className="bg-transparent h6 m-0"
                          onClick={increaseMonth}
                          disabled={nextMonthButtonDisabled}
                        >
                          <i className="fa-solid fa-chevron-right" />
                        </button>
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 m-0 p-2 rounded">
            <div className="d-flex flex-column flex-lg-row justify-content-between">
              <div className="px-3">
                <p className="font-weight-bold font-size-08 mb-1">총 인원</p>
                <div className="numbers-row">
                  <input
                    type="text"
                    readOnly={true}
                    min={1}
                    defaultValue={1}
                    className="qty2 form-control bg-white text-center"
                    ref={controller.rl_guest_max}
                  />
                  <div
                    className="inc button_inc"
                    onClick={() => {
                      controller.rl_guest_max.current.value =
                        Number(controller.rl_guest_max.current.value) + 1;
                    }}
                  >
                    +
                  </div>
                  <div
                    className="dec button_inc"
                    onClick={() => {
                      if (controller.rl_guest_max.current.value > 1) {
                        controller.rl_guest_max.current.value =
                          Number(controller.rl_guest_max.current.value) - 1;
                      }
                    }}
                  >
                    -
                  </div>
                </div>
              </div>
              <div className="px-3 px-lg-0">
                <button
                  className="d-none d-lg-flex btn_1 rounded text-center p-0 flex-row justify-content-center align-items-center"
                  style={{ width: 56, height: 56 }}
                  onClick={actionSearch}
                >
                  <span className="h6 mb-0">
                    <i className="fa-solid fa-magnifying-glass" />
                  </span>
                </button>
                <button
                  className="b-flex d-lg-none btn_1 rounded text-center flex-row justify-content-center align-items-center w-100 mt-2"
                  onClick={actionSearch}
                >
                  <span className="h6 mb-0">
                    검색하기
                    <i className="fa-solid fa-magnifying-glass ml-2" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
