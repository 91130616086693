import React, { useEffect, useState } from "react";
import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let guest_data = {
  gpt_guest_max: 1,
  gpt_guest_base: 1,
};

export default function GuideTimeRegistGuest(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (partTimeData) {
      for (let key in guest_data) {
        guest_data[key] = partTimeData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  const handleGuestCount = (key, val) => {
    guest_data[key] = val;
    guideTimeRegistDataChange(guest_data);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <h3 className="font-weight-bold">가이드 활동에 기본 인원 수</h3>
        <p>
          가이드를 진행하기에 적합한 기본 인원 수를 설정해 주세요. 그리고 기본
          인원수로 요금책정을 해주세요.
        </p>
        <select
          className="mt-2 w-100 border rounded-lg p-3"
          onChange={(e) => handleGuestCount("gpt_guest_base", e.target.value)}
          value={guest_data.gpt_guest_base}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
        </select>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h3 className="font-weight-bold">
          가이드 활동에 수용 가능한 최대 인원 수{" "}
        </h3>
        <p>
          가이드를 진행하기에 가장 적합한 최대 인원이 몇 명일지 생각해보세요.
          소규모로 오붓하게 진행하는 게 좋을까요? 아니면, 여러 사람이 함께해야
          더욱 재미있을까요?
        </p>
        <select
          className="mt-2 w-100 border rounded-lg p-3"
          onChange={(e) => handleGuestCount("gpt_guest_max", e.target.value)}
          value={guest_data.gpt_guest_max}
        >
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
        </select>
      </div>
    </div>
  );
}
