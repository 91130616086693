import React, { useCallback, useState } from "react";
import { Link, useHistory } from "react-router-dom";

import CodeList from "../../../../../lib/CodeList";
import Utilities from "../../../../../lib/Utilities";

const codeList = new CodeList();
const util = new Utilities();

export default function GuideServiceManage(props) {
  const {
    guideInfo,
    guideRouteList,
    updateUseYNRouteService,
    deleteRouteService,
  } = props;
  const [confirmAdmin, setConfirmAdmin] = useState("");
  const history = useHistory();

  const RouteServiceRenderer = useCallback(
    (props) => {
      return <RouteService {...props} />;
    },
    [guideRouteList]
  );

  const checkConfirmAdmin = () => {
    if (guideInfo.gl_confirm_admin !== "Y") {
      codeList.Modal.current.alert("버디등록 승인 후 가능한 서비스입니다.");
      return;
    }

    history.push("/GuideTourRegist");
  };

  let renderService = [...guideRouteList];
  if (confirmAdmin !== "") {
    renderService = renderService.filter(
      (item) => item.rl_confirm_admin === confirmAdmin
    );
  }

  return (
    <div className="container-fluid">
      <div className="row mb-2 mb-xl-3">
        <div className="col">
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center pb-3 border-bottom">
            <h3 className="mb-1">
              <strong>서비스 관리</strong>
            </h3>
            <div>
              <button
                onClick={() => checkConfirmAdmin()}
                className="btn_1 mr-1"
              >
                투어서비스 등록
              </button>
            </div>
          </div>

          <div className="py-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h4>투어서비스</h4>
              <div>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setConfirmAdmin(e.target.value);
                  }}
                >
                  <option value="">-상태-</option>
                  <option value="W">작성중</option>
                  <option value="S">검토대기</option>
                  <option value="Y">승인</option>
                  <option value="N">반려</option>
                </select>
              </div>
            </div>
            <div className="row">
              {renderService.length > 0 ? (
                renderService.map((route) => {
                  if (
                    confirmAdmin !== "" &&
                    confirmAdmin !== route.rl_confirm_admin
                  ) {
                    return null;
                  }

                  return (
                    <RouteServiceRenderer
                      key={route.rl_route_uid}
                      route={route}
                      updateUseYNRouteService={updateUseYNRouteService}
                      deleteRouteService={deleteRouteService}
                    />
                  );
                })
              ) : (
                <div className="flex-fill p-5 d-flex flex-column align-routes-center justify-content-center">
                  <p className="h6 text-center">
                    {guideInfo.gl_confirm_admin !== "Y"
                      ? "버디 등록 승인 후 이용가능한 서비스입니다."
                      : "등록된 서비스가 없습니다."}
                  </p>
                  <div className="d-flex flex-row justify-content-center">
                    <button
                      className="btn_1 ml-1"
                      onClick={() => checkConfirmAdmin()}
                    >
                      투어서비스 등록
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const RouteService = React.memo((props) => {
  const { route, updateUseYNRouteService, deleteRouteService } = props;

  let confirmClass = "badge-secondary";
  let confirm = "작성중";

  if (route.rl_confirm_admin === "W") {
    confirmClass = "badge-secondary";
    confirm = "작성중";
  }
  if (route.rl_confirm_admin === "S") {
    confirmClass = "badge-primary";
    confirm = "검토대기";
  }
  if (route.rl_confirm_admin === "Y") {
    confirmClass = "badge-success";
    confirm = "승인";
  }
  if (route.rl_confirm_admin === "N") {
    confirmClass = "badge-danger";
    confirm = "거부";
  }
  if (route.rl_confirm_admin === "C") {
    confirmClass = "badge-warning";
    confirm = "등록취소";
  }

  return (
    <div className="col-12 col-md-6 col-xl-3 p-2" style={{ maxWidth: 800 }}>
      <div className="rounded overflow-hidden border border-light shadow-sm h-100 d-flex flex-column justify-content-between">
        <div>
          <div
            className="text-center d-flex flex-row justify-content-center align-items-center bg-light position-relative"
            style={{
              height: 200,
              background: `url(${
                route.rl_pic_main ? route.rl_pic_main.split("?")[1] : ""
              })50% 50%/cover no-repeat`,
            }}
          >
            {route.rl_pic_main ? "" : "사진"}

            <button
              type="button"
              className="btn_1 ml-1 flex-grow-1 bg-danger position-absolute font-size-08"
              onClick={() => deleteRouteService({ ...route, rl_del_yn: "Y" })}
              style={{ top: "5px", right: "5px" }}
            >
              <i className="fa-regular fa-trash-can mr-2" />
              삭제하기
            </button>
          </div>
          <div className="px-3 pt-3">
            <span className={`badge badge-pill ${confirmClass} mb-1`}>
              {confirm}
            </span>
            <h3 className="ellipsis_1 mt-0 mb-2">
              {route.rl_title ? route.rl_title : "타이틀이 입력되지 않았습니다"}
            </h3>

            <div className="mb-2">
              <p className="ellipsis_2 mb-2">
                {route.rl_desc || "설명이 입력되지 않았습니다."}
              </p>
              <div className="rating">
                {route.rl_rating > 0 ? (
                  <i className="icon-star voted ml-0 pl-0" />
                ) : (
                  <i className="icon-star-empty ml-0 pl-0" />
                )}
                {route.rl_rating > 1 ? (
                  <i className="icon-star voted ml-0 pl-0" />
                ) : (
                  <i className="icon-star-empty ml-0 pl-0" />
                )}
                {route.rl_rating > 2 ? (
                  <i className="icon-star voted ml-0 pl-0" />
                ) : (
                  <i className="icon-star-empty ml-0 pl-0" />
                )}
                {route.rl_rating > 3 ? (
                  <i className="icon-star voted ml-0 pl-0" />
                ) : (
                  <i className="icon-star-empty ml-0 pl-0" />
                )}
                {route.rl_rating > 4 ? (
                  <i className="icon-star voted ml-0 pl-0" />
                ) : (
                  <i className="icon-star-empty ml-0 pl-0" />
                )}
                <small>({route.rl_rating_cnt})</small>
              </div>

              <p>기준 인원 : {route.rl_guest_min}</p>
              <p>
                기본 요금 :{" "}
                {route.rl_price_base
                  ? util.addCommas(route.rl_price_base)
                  : "0"}
                원
              </p>
            </div>
          </div>
        </div>
        <div className="row m-0 px-3 pb-3">
          <div className="col-6 m-0 p-0 pr-1">
            <Link
              className="btn_1 w-100 text-center"
              to={{
                pathname: "/GuideTourRegist",
                state: {
                  rl_route_uid: route.rl_route_uid,
                },
              }}
            >
              <i className="fas fa-pen-square mr-2" />
              수정하기
            </Link>
          </div>
          <div className="col-6 m-0 p-0 pl-1">
            <select
              className="form-control cursor_pointer"
              defaultValue={route.rl_use_yn}
              onChange={(e) =>
                updateUseYNRouteService({ ...route, rl_use_yn: e.target.value })
              }
              style={{ height: 35 }}
            >
              <option value="Y">사용</option>
              <option value="N">중지</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
});
