import React from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";
const util = new Utilities();
const codeList = new CodeList();
export default function TourDetailListCarousel(props) {
  const { t } = useTranslation();
  const { match } = props;
  const backgroundImageList = {
    hotPlace: [
      "/images/tourList_carousel_hotplace_1.jpg",
      "/images/tourList_carousel_hotplace_2.jpg",
      "/images/tourList_carousel_hotplace_3.jpg",
    ],
    experience: [
      "/images/tourList_carousel_experience_1.jpg",
      "/images/tourList_carousel_experience_2.jpg",
      "/images/tourList_carousel_experience_3.png",
    ],
    food: [
      "/images/tourList_carousel_food_1.jpg",
      "/images/tourList_carousel_food_2.jpg",
      "/images/tourList_carousel_food_3.jpg",
    ],
    cultureAndShopping: [
      "/images/tourList_carousel_culture_1.jpg",
      "/images/tourList_carousel_culture_2.jpg",
      "/images/tourList_carousel_culture_3.jpg",
    ],
  };
  let selectedImage = "/images/paraglider-sunset.jpg";

  if (match.params.hasOwnProperty("travelType")) {
    const rndNum = util.getRandomInt(
      0,
      backgroundImageList[match.params.travelType].length
    );
    selectedImage = backgroundImageList[match.params.travelType][rndNum];
  }

  return (
    <section
      className="parallax-window"
      data-parallax="scroll"
      data-image-src="./img/parallax_bg_1.jpg"
      data-natural-width="1400"
      data-natural-height="470"
      style={{
        background: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2) ), url('${selectedImage}') 50% 50% /cover no-repeat`,
      }}
    >
      <div
        className="parallax-content-1 opacity-mask"
        data-opacity-mask="rgba(0, 0, 0, 0.6)"
      >
        <div className="animated fadeInDown">
          <h1>{t("tourDetailListCarouselTitle")}</h1>
          <p>{t("tourDetailListCarouselDesc")}</p>
        </div>
      </div>
    </section>
  );
}
