import React from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import FAQCarousel from "./component/FAQCarousel";
import FAQContent from "./component/FAQContent";

export default function FAQ(props) {
  return (
    <>
      <Nav />
      <main>
        <FAQCarousel />
        <FAQContent {...props} />
      </main>
      <Footer />
    </>
  );
}
