import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Utilities from "../../../lib/Utilities";

const util = new Utilities();

export default function WishList(props) {
  const { u_wish_cnt, u_wish_list, updateWishList, moveToTourDetail } = props;
  const { t } = useTranslation();

  //경로 서비스 랜더
  const WishItemRender = useCallback((props) => {
    return <WishItem {...props} />;
  }, []);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-12">
          <div className="d-flex flex-row justify-content-between">
            <h4 className="mb-0 font-weight-bold">
              {t("wish")} {u_wish_cnt}
              {t("count")}
            </h4>
          </div>
        </div>
      </div>
      <div className="row">
        {u_wish_list.map((item) => {
          return (
            <WishItemRender
              key={item.wl_idx}
              item={item}
              moveToTourDetail={moveToTourDetail}
              updateWishList={updateWishList}
            />
          );
        })}
      </div>
    </div>
  );
}

const WishItem = React.memo((props) => {
  const { item, moveToTourDetail, updateWishList } = props;
  const { t } = useTranslation();
  const picUrl = util.changedResourceUrl(item.rl_pic_main);

  return (
    <div className="col-12 col-md-6 col-xl-4 m-0 px-3 ">
      <a
        href="#"
        className="text-dark"
        onClick={(e) => {
          e.preventDefault();
          if (item.wl_use_yn === "Y") {
            moveToTourDetail(item.rl_idx);
          }
        }}
      >
        <div
          className="tour_container overflow-hidden position-relative"
          style={{ minHeight: 400 }}
        >
          {item.wl_use_yn === "N" && (
            <div
              className="position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center"
              style={{ zIndex: 3, background: "rgba(0,0,0,0.6)" }}
            >
              <p className="text-white font-weight-bold mb-2">
                {t("disableServiceNote")}
              </p>
              <button
                className="btn btn-sm btn-danger font-size-08"
                onClick={() => updateWishList({ ...item, wl_del_yn: "Y" })}
              >
                {t("save")} {t("cancel")}
              </button>
            </div>
          )}
          <div className="img_container_2 position-relative">
            <div
              className="w-100"
              style={{
                height: 300,
                background: `url(${picUrl}) 50% 50%/cover no-repeat`,
              }}
            />
            <div className="short_info">
              <i className="icon_set_1_icon-44" />
              {item.rl_area_cd}
              <span className="price">
                <sup>&#8361;</sup> {util.addCommas(item.rl_price_base)}
              </span>
            </div>
          </div>

          <div className="tour_title">
            <span>{item.rl_name ? item.rl_name : "가이드 명"}</span>
            <h3>
              <strong>{item.rl_title}</strong>
            </h3>
            <div className="rating">
              <i
                className={
                  item.rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  item.rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <small>({item.rl_rating_cnt})</small>
            </div>
            <span>
              {t("base")} {item.rl_guest_min ? item.rl_guest_min : "1"}{" "}
              {t("person")} &#8361;
              {util.addCommas(item.rl_price_base)} /
            </span>
            <span>
              {" "}
              {t("addedPerson")} &#8361;
              {util.addCommas(item.rl_over_pay_people)}
            </span>
          </div>
          <div className="tour_desc">
            <p className="ellipsis_2">{item.rl_desc}</p>
          </div>
        </div>
      </a>
    </div>
  );
});
