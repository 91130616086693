import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import CommunityManageEditModal from "./CommunityManageEditModal";

import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "CommunityManageTable";

const POST_TYPE = {
  NOTICE: "공지사항",
  GUIDE: "버디 팁",
  GUEST: "여행자 팁",
  NEWS: "새소식",
  FORUM: "커뮤니티 포럼",
};
const headCells = [
  {
    field: "cm_idx",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "cm_type",
    headerName: "분류",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {POST_TYPE[params.data]}
      </span>
    ),
  },
  {
    field: "cm_title",
    headerName: "제목",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "cm_sub_title",
    headerName: "부제목",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data}</span>;
    },
  },
  {
    field: "cm_look_cnt",
    headerName: "조회수",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.addCommas(params.data)} 회</span>;
    },
  },
  {
    field: "cm_show_yn",
    headerName: "공개여부",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data === "Y" ? "공개" : "비공개"}</span>;
    },
  },
  {
    field: "cm_admin_yn",
    headerName: "게시자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{params.data === "Y" ? "관리자" : "일반"}</span>;
    },
  },
  {
    field: "cm_create_date",
    headerName: "게시일",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "ROWNUM",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span>
          <button
            type="button"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#CommunityEditModal"
            onClick={(e) => params.openEdit(e, params)}
          >
            <i className="fas fa-pen" />
          </button>
          <button
            type="button"
            className="btn btn-danger ml-2"
            onClick={(e) => params.deleteData(e, params)}
          >
            <i className="far fa-trash-alt" />
          </button>
        </span>
      );
    },
  },
];

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function Row(props) {
  const { row, openEdit, deleteData } = props;
  const classes = useRowStyles;

  return (
    <TableRow
      className={[classes.root, "text-truncate"].join(" ")}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
    >
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.field}
          align={headCell.numeric ? "right" : "left"} //text 정렬
          padding={headCell.disablePadding ? "none" : "normal"} //패딩 값 부여 여부
        >
          {headCell.hasOwnProperty("renderCell") // headCell에 "renderCell" 속성 포함여부 확인
            ? headCell.renderCell({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEdit: openEdit,
                deleteData: deleteData,
              })
            : headCell.hasOwnProperty("valueGetter")
            ? headCell.valueGetter({
                data: row[headCell.field],
                currentData: row,
                currentHeader: headCell.field,
                headCells: headCells,
                openEdit: openEdit,
                deleteData: deleteData,
              })
            : row[headCell.field]}
        </TableCell>
      ))}
    </TableRow>
  );
}
export default function CommunityManageTable(props) {
  const { isServerPaging = true, isServerSort = true } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("cm_create_date");

  const [dense, setDense] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "cm_create_date",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});
  const editModal = useRef();

  useEffect(() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_COMMUNITY_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_COMMUNITY_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, filters, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityListSelect.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      cm_del_yn: "N",
      ...filters,
    };
    setLoading(true);
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openEdit = (e, params) => {
    editModal.current.openModal(e, params);
  };
  const deleteData = (e, params) => {
    editModal.current.removeData(e, params);
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = { openEdit, deleteData };

  return (
    <>
      <CommunityManageEditModal
        ref={editModal}
        requestUserSelect={requestUserSelect}
        {...props}
      />
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />

          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={`row_${index}`}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={`row_${index}`}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    key={`row_${index}`}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  key={`row_${index}`}
                  row={row}
                  index={index}
                  {...rowProps}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              // console.log(value - 1); //apiRef.current.setPage(value - 1)
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}
