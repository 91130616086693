import React from "react";
import TourDetailReview from "./TourDetailReview";
import TourDetailInfo from "./TourDetailInfo";
import TourDetailLocation from "./TourDetailLocation";
import TourDetailSuggestionOther from "./TourDetailSuggestionOther";
import TourSideNav from "./TourSideNav";

export default function TourDetailContent(props) {
  const { tourData, loginInfo, reviewData, reserveData } = props;

  return (
    <>
      <div className="container">
        <div className="row py-3">
          <div className="col-xl-9" id="tourInfo">
            <TourDetailInfo tourData={tourData} loginInfo={loginInfo} />
            <TourDetailReview tourData={tourData} reviewData={reviewData} />
          </div>
          <TourSideNav
            tourData={tourData}
            loginInfo={loginInfo}
            reserveData={reserveData}
          />
        </div>
        <TourDetailLocation tourData={tourData} />
        <TourDetailSuggestionOther tourData={tourData} />
      </div>
    </>
  );
}
