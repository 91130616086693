import React, { useEffect, useRef, useState } from "react";

let cost_data = {
  rl_guest_max: 1,
  rl_guest_min: 1,
  rl_price_base: 0,
  rl_over_pay_hour: 0,
  rl_over_pay_people: 0,
};

export default function GuideTourRegistSettingCost(props) {
  const { tourData, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const $controller = {
    rl_price_base_amount: useRef(),
    rl_over_pay_hour_amount: useRef(),
    rl_over_pay_people_amount: useRef(),
    rl_price_base_revenue: useRef(),
    rl_over_pay_hour_revenue: useRef(),
    rl_over_pay_people_revenue: useRef(),
  };

  useEffect(() => {
    for (let key in cost_data) {
      cost_data[key] = Number(tourData[key]);
    }
    setIsNeedUpdate(!isNeedUpdate);
  }, []);

  const handleCostDataChange = (e) => {
    let key = e.target.name;
    let value = Number(e.target.value);

    let revenueKey = key + "_revenue";
    let amountKey = key + "_amount";
    if (
      $controller.hasOwnProperty(amountKey) &&
      $controller[amountKey].current
    ) {
      $controller[amountKey].current.value = Math.round(value * 1.15);
    }
    if (
      $controller.hasOwnProperty(revenueKey) &&
      $controller[revenueKey].current
    ) {
      $controller[revenueKey].current.value = parseInt(value * 0.8, 0);
    }

    cost_data = {
      ...cost_data,
      [key]: value,
    };
    handleTourDataChange();
    setIsNeedUpdate(!isNeedUpdate);
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = () => {
    guideTourRegistDataChange(cost_data);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <div className="mb-3 pb-3">
          <h6 className="font-weight-bold mb-2">여행에 기본 인원 수</h6>
          <p className="mb-2">
            여행에 적합한 기본 인원 수를 설정해 주세요. 그리고 기본 인원수로
            요금책정을 해주세요.
          </p>
          <select
            className="form-control"
            value={cost_data.rl_guest_min}
            name="rl_guest_min"
            disabled={tourData.rl_confirm_admin === "S"}
            onChange={(e) => handleCostDataChange(e)}
          >
            <option value={1}>1 인</option>
            <option value={2}>2 인</option>
            <option value={3}>3 인</option>
            <option value={4}>4 인</option>
            <option value={5}>5 인</option>
            <option value={6}>6 인</option>
            <option value={7}>7 인</option>
            <option value={8}>8 인</option>
            <option value={9}>9 인</option>
            <option value={10}>10 인</option>
          </select>
        </div>

        <div>
          <h6 className="font-weight-bold mb-2">
            여행에 수용 가능한 최대 인원 수
          </h6>
          <p className="mb-2">
            여행을 진행하기에 가장 적합한 최대 인원이 몇 명일지 생각해보세요.
            소규모로 오붓하게 진행하는 게 좋을까요? 아니면, 여러 사람이 함께해야
            더욱 재미있을까요?
          </p>
          <select
            className="form-control"
            value={cost_data.rl_guest_max}
            name="rl_guest_max"
            disabled={tourData.rl_confirm_admin === "S"}
            onChange={(e) => handleCostDataChange(e)}
          >
            <option value={1}>1 인</option>
            <option value={2}>2 인</option>
            <option value={3}>3 인</option>
            <option value={4}>4 인</option>
            <option value={5}>5 인</option>
            <option value={6}>6 인</option>
            <option value={7}>7 인</option>
            <option value={8}>8 인</option>
            <option value={9}>9 인</option>
            <option value={10}>10 인</option>
          </select>
        </div>
      </div>
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-2">요금 책정</h6>
        <p className="mb-2">
          요금은 가이드가 원하는 대로 책정할 수 있습니다. 예상 수입을 확인하려면
          각 게스트에게 청구하고자 하는 요금을 입력하세요.
        </p>
        <p className="mb-2 font-weight-bold">
          기본 인원 수를 정하고 요금 책정해주세요. 평균 50,000 ~ 60,000원으로
          책정하고 있습니다.
        </p>
        <div className="py-3 border-bottom">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-start align-items-sm-center">
            <div className="pb-2 p-sm-2 flex-grow-1">
              <p className="font-weight-bold min-width-100">기본요금</p>
              {/*<p>만 19세 이상</p>*/}
            </div>
            <div className="pb-2 p-sm-2 flex-grow-1">
              <p>결제 금액</p>
              <input
                className="form-control"
                type="number"
                name="rl_price_base"
                min={0}
                defaultValue={Math.round(tourData.rl_price_base * 1.15)}
                ref={$controller.rl_price_base_amount}
                disabled
                // onChange={(e) => handleCostDataChange(e)}
              />
            </div>
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p>금액</p>
              <input
                className="form-control"
                type="number"
                name="rl_price_base"
                min={0}
                defaultValue={tourData.rl_price_base}
                disabled={tourData.rl_confirm_admin === "S"}
                onChange={(e) => handleCostDataChange(e)}
              />
            </div>
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p>예약 수입</p>
              <input
                className="form-control"
                type="number"
                min={0}
                disabled
                defaultValue={parseInt(tourData.rl_price_base * 0.8)}
                ref={$controller.rl_price_base_revenue}
              />
            </div>
          </div>
        </div>
        <div className="py-3 border-bottom">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-start align-items-sm-center">
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p className="font-weight-bold min-width-100">시간 초과당 요금</p>
              <p>1시간 단위</p>
            </div>
            <div className="pb-2 p-sm-2 flex-grow-1">
              <p>결제 금액</p>
              <input
                className="form-control"
                type="number"
                name="rl_price_base"
                min={0}
                disabled
                defaultValue={Math.round(tourData.rl_over_pay_hour * 1.15)}
                ref={$controller.rl_over_pay_hour_amount}
              />
            </div>
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p>금액</p>
              <input
                className="form-control"
                type="number"
                name="rl_over_pay_hour"
                min={0}
                defaultValue={tourData.rl_over_pay_hour}
                disabled={tourData.rl_confirm_admin === "S"}
                onChange={(e) => handleCostDataChange(e)}
              />
            </div>

            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p>예약 수입</p>
              <input
                className="form-control"
                type="number"
                min={0}
                disabled
                defaultValue={parseInt(tourData.rl_over_pay_hour * 0.8)}
                ref={$controller.rl_over_pay_hour_revenue}
              />
            </div>
          </div>
        </div>
        <div className="py-3 border-bottom">
          <div className="d-flex flex-column flex-sm-row justify-content-sm-start align-items-sm-center">
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p className="font-weight-bold min-width-100">인원 추가시 요금</p>
              <p>1명 추가시</p>
            </div>
            <div className="pb-2 p-sm-2 flex-grow-1">
              <p>결제 금액</p>
              <input
                className="form-control"
                type="number"
                name="rl_over_pay_people"
                min={0}
                disabled
                defaultValue={Math.round(tourData.rl_over_pay_people * 1.15)}
                ref={$controller.rl_over_pay_people_amount}
              />
            </div>
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p>금액</p>
              <input
                className="form-control"
                type="number"
                name="rl_over_pay_people"
                min={0}
                disabled={tourData.rl_confirm_admin === "S"}
                defaultValue={tourData.rl_over_pay_people}
                onChange={(e) => handleCostDataChange(e)}
              />
            </div>
            <div className=" pb-2 p-sm-2 flex-grow-1">
              <p>예약 수입</p>
              <input
                className="form-control"
                type="number"
                min={0}
                disabled
                defaultValue={parseInt(tourData.rl_over_pay_people * 0.8)}
                ref={$controller.rl_over_pay_people_revenue}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-row mt-2">
          <span className="mr-1">※ </span>
          <p>
            게스트 결제 예정금액은 기본요금에 플랫폼 수수료를 가산한 금액으로
            부가세가 미포함되어있어 실제 결제금액과 약간의 차이가 발생할 수
            있습니다.
          </p>
        </div>
      </div>
    </div>
  );
}
