import React, { Component } from "react";
import "./stylesheet/Loading.css";

class Loading extends Component {
  componentDidMount() {
    document.querySelector("body").style.height = "100vh";
    document.querySelector("body").style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.querySelector("body").style.height = "unset";
    document.querySelector("body").style.overflow = "unset";
  }

  render() {
    return (
      <div
        className="position-fixed vw-100 vh-100 d-flex flex-row justify-content-center align-items-center"
        style={{ background: "rgba(0,0,0,.6)", zIndex: 1060 }}
      >
        <div className="rotate-infinite">
          <p className="h1 mb-0 text-light">
            <i className="fa-solid fa-spinner" />
          </p>
        </div>
      </div>
    );
  }
}

export default Loading;
