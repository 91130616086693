import React from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";

let socket = new BuddibleSocket();
let util = new Utilities();

export default class CodeList {
  constructor(data) {
    if (CodeList.exists) {
      return CodeList.instance;
    }
    CodeList.instance = this;
    CodeList.exists = true;
    this.codeCountry = new Map();
    this.codeLanguage = new Map();
    this.codeSubject = new Map();
    this.codeHashtag = new Map();
    this.codeHashtagObj = [];
    this.codeHashtagByName = new Map();
    this.codeOffer = new Map();

    this.publicVar = {
      imgServerUrl: "https://msgserver.buddible.com:4000",
      defualt_profile_image: "/img/img_cat_home_1.jpg",
      unProfileImage:
        "https://playyj.s3.ap-northeast-2.amazonaws.com/images/profile/blankprofilepicture973460_500_20231016103737.png", // 프로필 기본이미지
      times: [
        {
          id: "0",
          name: "00:00",
          value: "00:00:00",
        },
        {
          id: "30",
          name: "00:30",
          value: "00:30:00",
        },
        {
          id: "60",
          name: "01:00",
          value: "01:00:00",
        },
        {
          id: "90",
          name: "01:30",
          value: "01:30:00",
        },
        {
          id: "120",
          name: "02:00",
          value: "02:00:00",
        },
        {
          id: "150",
          name: "02:30",
          value: "02:30:00",
        },
        {
          id: "180",
          name: "03:00",
          value: "03:00:00",
        },
        {
          id: "210",
          name: "03:30",
          value: "03:30:00",
        },
        {
          id: "240",
          name: "04:00",
          value: "04:00:00",
        },
        {
          id: "270",
          name: "04:30",
          value: "04:30:00",
        },
        {
          id: "300",
          name: "05:00",
          value: "05:00:00",
        },
        {
          id: "330",
          name: "05:30",
          value: "05:30:00",
        },
        {
          id: "360",
          name: "06:00",
          value: "06:00:00",
        },
        {
          id: "390",
          name: "06:30",
          value: "06:30:00",
        },
        {
          id: "420",
          name: "07:00",
          value: "07:00:00",
        },
        {
          id: "450",
          name: "07:30",
          value: "07:30:00",
        },
        {
          id: "480",
          name: "08:00",
          value: "08:00:00",
        },
        {
          id: "510",
          name: "08:30",
          value: "08:30:00",
        },
        {
          id: "540",
          name: "09:00",
          value: "09:00:00",
        },
        {
          id: "570",
          name: "09:30",
          value: "09:30:00",
        },
        {
          id: "600",
          name: "10:00",
          value: "10:00:00",
        },
        {
          id: "630",
          name: "10:30",
          value: "10:30:00",
        },
        {
          id: "660",
          name: "11:00",
          value: "11:00:00",
        },
        {
          id: "690",
          name: "11:30",
          value: "11:30:00",
        },
        {
          id: "720",
          name: "12:00",
          value: "12:00:00",
        },
        {
          id: "750",
          name: "12:30",
          value: "12:30:00",
        },
        {
          id: "780",
          name: "13:00",
          value: "13:00:00",
        },
        {
          id: "810",
          name: "13:30",
          value: "13:30:00",
        },
        {
          id: "840",
          name: "14:00",
          value: "14:00:00",
        },
        {
          id: "870",
          name: "14:30",
          value: "14:30:00",
        },
        {
          id: "900",
          name: "15:00",
          value: "15:00:00",
        },
        {
          id: "930",
          name: "15:30",
          value: "15:30:00",
        },
        {
          id: "960",
          name: "16:00",
          value: "16:00:00",
        },
        {
          id: "990",
          name: "16:30",
          value: "16:30:00",
        },
        {
          id: "1020",
          name: "17:00",
          value: "17:00:00",
        },
        {
          id: "1050",
          name: "17:30",
          value: "17:30:00",
        },
        {
          id: "1080",
          name: "18:00",
          value: "18:00:00",
        },
        {
          id: "1110",
          name: "18:30",
          value: "18:30:00",
        },
        {
          id: "1140",
          name: "19:00",
          value: "19:00:00",
        },
        {
          id: "1170",
          name: "19:30",
          value: "19:30:00",
        },
        {
          id: "1200",
          name: "20:00",
          value: "20:00:00",
        },
        {
          id: "1230",
          name: "20:30",
          value: "20:30:00",
        },
        {
          id: "1260",
          name: "21:00",
          value: "21:00:00",
        },
        {
          id: "1290",
          name: "21:30",
          value: "21:30:00",
        },
        {
          id: "1320",
          name: "22:00",
          value: "22:00:00",
        },
        {
          id: "1350",
          name: "22:30",
          value: "22:30:00",
        },
        {
          id: "1380",
          name: "23:00",
          value: "23:00:00",
        },
        {
          id: "1410",
          name: "23:30",
          value: "23:30:00",
        },
        {
          id: "0",
          name: "00:00",
          value: "00:00:00",
        },
        {
          id: "30",
          name: "00:30",
          value: "00:30:00",
        },
        {
          id: "60",
          name: "01:00",
          value: "01:00:00",
        },
        {
          id: "90",
          name: "01:30",
          value: "01:30:00",
        },
        {
          id: "120",
          name: "02:00",
          value: "02:00:00",
        },
        {
          id: "150",
          name: "02:30",
          value: "02:30:00",
        },
        {
          id: "180",
          name: "03:00",
          value: "03:00:00",
        },
        {
          id: "210",
          name: "03:30",
          value: "03:30:00",
        },
        {
          id: "240",
          name: "04:00",
          value: "04:00:00",
        },
        {
          id: "270",
          name: "04:30",
          value: "04:30:00",
        },
        {
          id: "300",
          name: "05:00",
          value: "05:00:00",
        },
        {
          id: "330",
          name: "05:30",
          value: "05:30:00",
        },
        {
          id: "360",
          name: "06:00",
          value: "06:00:00",
        },
        {
          id: "390",
          name: "06:30",
          value: "06:30:00",
        },
        {
          id: "420",
          name: "07:00",
          value: "07:00:00",
        },
        {
          id: "450",
          name: "07:30",
          value: "07:30:00",
        },
        {
          id: "480",
          name: "08:00",
          value: "08:00:00",
        },
        {
          id: "510",
          name: "08:30",
          value: "08:30:00",
        },
        {
          id: "540",
          name: "09:00",
          value: "09:00:00",
        },
        {
          id: "570",
          name: "09:30",
          value: "09:30:00",
        },
        {
          id: "600",
          name: "10:00",
          value: "10:00:00",
        },
        {
          id: "630",
          name: "10:30",
          value: "10:30:00",
        },
        {
          id: "660",
          name: "11:00",
          value: "11:00:00",
        },
        {
          id: "690",
          name: "11:30",
          value: "11:30:00",
        },
        {
          id: "720",
          name: "12:00",
          value: "12:00:00",
        },
        {
          id: "750",
          name: "12:30",
          value: "12:30:00",
        },
        {
          id: "780",
          name: "13:00",
          value: "13:00:00",
        },
        {
          id: "810",
          name: "13:30",
          value: "13:30:00",
        },
        {
          id: "840",
          name: "14:00",
          value: "14:00:00",
        },
        {
          id: "870",
          name: "14:30",
          value: "14:30:00",
        },
        {
          id: "900",
          name: "15:00",
          value: "15:00:00",
        },
        {
          id: "930",
          name: "15:30",
          value: "15:30:00",
        },
        {
          id: "960",
          name: "16:00",
          value: "16:00:00",
        },
        {
          id: "990",
          name: "16:30",
          value: "16:30:00",
        },
        {
          id: "1020",
          name: "17:00",
          value: "17:00:00",
        },
        {
          id: "1050",
          name: "17:30",
          value: "17:30:00",
        },
        {
          id: "1080",
          name: "18:00",
          value: "18:00:00",
        },
        {
          id: "1110",
          name: "18:30",
          value: "18:30:00",
        },
        {
          id: "1140",
          name: "19:00",
          value: "19:00:00",
        },
        {
          id: "1170",
          name: "19:30",
          value: "19:30:00",
        },
        {
          id: "1200",
          name: "20:00",
          value: "20:00:00",
        },
        {
          id: "1230",
          name: "20:30",
          value: "20:30:00",
        },
        {
          id: "1260",
          name: "21:00",
          value: "21:00:00",
        },
        {
          id: "1290",
          name: "21:30",
          value: "21:30:00",
        },
        {
          id: "1320",
          name: "22:00",
          value: "22:00:00",
        },
        {
          id: "1350",
          name: "22:30",
          value: "22:30:00",
        },
        {
          id: "1380",
          name: "23:00",
          value: "23:00:00",
        },
        {
          id: "1410",
          name: "23:30",
          value: "23:30:00",
        },
      ],
    };
    this.Modal = React.createRef();

    this.setModal = (e) => {
      CodeList.instance.Modal.current = e;
    };

    return this;
  }
  requestHashtagList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      method: "GetHashtagList",
      data: {},
    };
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          this.codeHashtagObj = [];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            this.codeHashtag.set(obj.htc_key, obj.htc_name);
            this.codeHashtagByName.set(obj.htc_name, obj.htc_key);
            this.codeHashtagObj.push({
              htc_key: obj.htc_key,
              htc_name: obj.htc_name,
            });
          }
          if (callback) callback(true);
          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_HASHTAG,
            "InitCodeList",
            this.codeHashtag,
            () => {}
          );
        }
      }
    });
  };
}

export const CommonConstList = {
  Modal: {
    TYPE_ALERT: "TYPE_ALERT",
    TYPE_CONFIRM: "TYPE_CONFIRM",
    TYPE_INPUT: "TYPE_INPUT",
  },
  RouteOffer: {
    OFFER_FOOD: "OFFER_FOOD",
    OFFER_DRINK: "OFFER_DRINK",
    OFFER_TICKET: "OFFER_TICKET",
    OFFER_EQUIP: "OFFER_EQUIP",
    OFFER_DRIVER: "OFFER_DRIVER",
  },
  Accessibility: {
    ACCESS_MAIN: "ACCESS_MAIN",
    ACCESS_ETC: "ACCESS_ETC",
    ACCESS_COMM: "ACCESS_COMM",
    ACCESS_ENV: "ACCESS_ENV",
  },
};
