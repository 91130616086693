import React, { useState, useEffect } from "react";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const STATUS_TYPE = {
  CR: "요청",
  GA: "승인",
  CC: "취소",
  GC: "거절",
};
const SUBJECT_CODE = {
  NAT: "자연·환경 관광", //<i className="icon_set_1_icon-24"//
  SOC: "사회·문화 관광", //<i className="icon_set_1_icon-1"//
  SHO: "쇼핑 관광", //<i className="icon_set_1_icon-50"//
  RES: "맛집 휴양", //<i className="icon_set_1_icon-14"//
  BEC: "해변 휴양", //<i className="icon_set_2_icon-108"//
  NMK: "야간 시장", //<i className="icon_set_3_restaurant-9"//
  BEV: "야간 술집", //<i className="icon_set_1_icon-60"//
  NVW: "야간 풍경", //<i className="icon_set_1_icon-24"//
  OAT: "야외 활동", //<i className="icon_set_1_icon-30"//
  SPO: "스포츠 활동", //<i className="icon_set_1_icon-40"//
  ETM: "오락 활동", //<i className="icon_set_1_icon-3"//
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const _mClassName = "GuideReserveManageTable";
const headCellsBackup = [
  {
    field: "rrl_idx",
    headerName: "예약 번호",
    numeric: false,
    disablePadding: false,
    minWidth: 100,
  },
  {
    field: "rl_title",
    headerName: "서비스명",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break ellipsis_1">
          {params.data}{" "}
          <button
            className="btn_1 px-2 py-1 font-size-08 ml-2"
            onClick={() => {
              window.open(
                `/TourDetail/${params.currentData.rl_idx}`,
                "tourDetail",
                "width=1200, height=800"
              );
            }}
          >
            보기
          </button>
        </span>
      );
    },
  },
  {
    field: "u_name_full",
    headerName: "예약자명",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "rrl_guest_adult_num",
    headerName: "인원",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          성인 {params.data}인
          {Number(params.currentData.rrl_guest_child_num) > 0 &&
            `, 청소년 ${params.currentData.rrl_guest_child_num}인`}
          {Number(params.currentData.rrl_guest_baby_num) > 0 &&
            `, 유아 ${params.currentData.rrl_guest_baby_num}인`}
        </span>
      );
    },
  },
  {
    field: "rrl_memo_guest",
    headerName: "요청사항",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data || "-"}
      </span>
    ),
  },
  {
    field: "rrl_cost_paid",
    headerName: "정산예정금액",
    minWidth: 100,
    numeric: true,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break text-right text-buddible font-weight-bold">
        &#8361; {util.addCommas(params.data)}
      </span>
    ),
  },
  {
    field: "rrl_date_reserve",
    headerName: "예약 요청 일자",
    minWidth: 100,
    numeric: true,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {util.getDateToFormat(params.data, "YYYY-MM-DD")}
        </span>
      );
    },
  },
  {
    field: "rrl_start_date",
    headerName: "이용 일자",
    minWidth: 100,
    numeric: true,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data}
        </span>
      );
    },
  },
  {
    field: "rrl_status",
    headerName: "상태",
    minWidth: 50,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let textColor =
        params.data === "CR"
          ? "text-secondary"
          : params.data === "GA"
          ? "text-info"
          : "text-danger";
      return (
        <p
          className={`text-truncate text-wrap text-break font-weight-bold text-center ${textColor}`}
        >
          {STATUS_TYPE[params.data]}
        </p>
      );
    },
  },
];
let headCells = [...headCellsBackup];
let headCellsLength = 8;

let isLoading = false;
let reserve_list = [];
let totalCount = 100;
let totalPageCount = 1;
let rowsPerPage = 10;
let order = "desc";
let orderBy = "rrl_start_date";
let page = 0;
let filters = {};

let dense = false;
let sortModel = [
  {
    field: "rrl_start_date",
    dir: "desc",
  },
];

export default function GuideReserveManageTable({
  isServerPaging = true,
  isServerSort = true,
  loginInfo,
  requestReserveCountWithMonth,
}) {
  const classes = useStyles();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isChangeCells, setIsChangeCells] = useState(false);

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        if (!n) return;
        filters = { ...n };
        requestReserveListSelect();
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        isLoading = false;
      }
    );
  })();

  useEffect(() => {
    handleHeadCells();
    window.addEventListener("resize", handleHeadCells);
    requestReserveListSelect();
    return () => {
      window.removeEventListener("resize", handleHeadCells);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
    };
  }, []);

  const handleHeadCells = () => {
    let cells = [];
    let windowWidth = document.body.offsetWidth;

    if (windowWidth < 576) {
      //  모바일
      cells = [headCellsBackup[2], headCellsBackup[7], headCellsBackup[8]];
    } else if (windowWidth >= 576 && windowWidth < 1200) {
      //  태블릿
      cells = [
        headCellsBackup[1],
        headCellsBackup[2],
        headCellsBackup[5],
        headCellsBackup[7],
        headCellsBackup[8],
      ];
    } else {
      //  데스크탑
      cells = [...headCellsBackup];
    }

    if (cells.length !== headCellsLength) {
      headCellsLength = cells.length;
      headCells = [...cells];
      setIsChangeCells((isChangeCells) => !isChangeCells);
    }
  };

  //예약정보 받아오기
  const requestReserveListSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserve/JS_reserve_list_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      gl_guide_uid: loginInfo.u_uid,
      ...filters,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"]) {
            reserve_list = newData["returnData"];
            totalCount = parseInt(newData.pageSize, 10);
            isLoading = false;
            totalPageCount =
              parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
            setIsNeedUpdate(!isNeedUpdate);
          }
        }
      }
    });
  };

  //채팅방 예약 상태 업데이트
  const requestChatRomStatusUpdate = (status, rowData) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/chat/JS_chatRoomStatusUpdate.php",
      msgID: msgID,
      rrl_idx: rowData.rrl_idx,
      rrl_reserve_uid: rowData.rrl_reserve_uid,
      rrl_status: status,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상 처리되었습니다.", () => {
            requestReserveCountWithMonth();
            requestReserveListSelect();
          });
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  // 예약 상태 업데이트
  const requestReserveStatusUpdate = (status, rowData, callback) => {
    let rrl_date_agree = new Date();
    let rrl_date_agree_date = util.getDateToFormat(
      rrl_date_agree,
      "YYYY-MM-DD"
    );
    let rrl_date_agree_time = `${rrl_date_agree.getHours()}:${
      rrl_date_agree.getMinutes() < 10
        ? "0" + rrl_date_agree.getMinutes()
        : rrl_date_agree.getMinutes()
    }:${
      rrl_date_agree.getSeconds() < 10
        ? "0" + rrl_date_agree.getSeconds()
        : rrl_date_agree.getSeconds()
    }`;

    rrl_date_agree = `${rrl_date_agree_date} ${rrl_date_agree_time}`;

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/reserve/JS_reserve_list_update.php",
      msgID: msgID,
      rrl_idx: rowData.rrl_idx,
      rrl_reserve_uid: rowData.rrl_reserve_uid,
      rrl_status: status,
      rrl_date_agree: rrl_date_agree,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(status, rowData);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  const handleUpdateReserve = (status, rowData) => {
    codeList.Modal.current.confirm(
      `해당요청을 ${status === "GC" ? "거절" : "승인"}하시겠습니까? ${
        status === "GC" ? "거절" : "승인"
      } 후 변경이 불가합니다.`,
      (ret) => {
        if (ret) {
          requestReserveStatusUpdate(status, rowData, () =>
            requestChatRomStatusUpdate(status, rowData)
          );
        }
      }
    );
  };

  const data = React.useMemo(() => {
    return [...reserve_list];
  }, [reserve_list, isChangeCells]);

  // 정렬 기준 변경

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    order = isAsc ? "desc" : "asc";
    orderBy = property;
    sortModel = [
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ];
    requestReserveListSelect();
  };

  // 페이지 이동
  const handleChangePage = (event, newPage) => {
    page = newPage;
    setIsNeedUpdate(!isNeedUpdate);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowParams = {
    handleUpdateReserve: handleUpdateReserve,
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            rowCount={data.length}
            handleRequestSort={handleRequestSort}
          />
          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.rrl_reserve_uid + "_" + index}
                    row={row}
                    index={index}
                    {...rowParams}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.rrl_reserve_uid + "_" + index}
                    row={row}
                    index={index}
                    {...rowParams}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => {
                  return (
                    <Row
                      key={row.rrl_reserve_uid + "_" + index}
                      row={row}
                      index={index}
                      {...rowParams}
                    />
                  );
                })}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => {
                return (
                  <Row
                    key={row.rrl_reserve_uid + "_" + index}
                    row={row}
                    index={index}
                    {...rowParams}
                  />
                );
              })}

            {isLoading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 1}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !isLoading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length + 1}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length + 1} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, handleRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, handleUpdateReserve } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        role="checkbox"
        tabIndex={-1}
        size={"small"}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <i className="fa-solid fa-chevron-up" />
            ) : (
              <i className="fa-solid fa-chevron-down" />
            )}
          </IconButton>
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.field + "_" + index}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            style={{ minWidth: headCell.minWidth }}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  open: open,
                  setOpen: setOpen,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  open: open,
                  setOpen: setOpen,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <ReserveDetail
                rowData={row}
                setOpen={setOpen}
                handleUpdateReserve={handleUpdateReserve}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

//예약정보 디테일
const ReserveDetail = (props) => {
  const { rowData, handleUpdateReserve } = props;

  const status_text_color =
    rowData.rrl_status === "CR"
      ? "text-secondary"
      : rowData.rrl_status === "GA"
      ? "text-info"
      : "text-danger";

  const serviceImage =
    rowData.rl_pic_main.indexOf("?") > -1
      ? rowData.rl_pic_main.split("?")[1]
      : codeList.publicVar.imgServerUrl + rowData.rl_pic_main;

  const reservationImage = rowData.u_avata_pic
    ? util.changedResourceUrl(rowData.u_avata_pic)
    : codeList.publicVar.defualt_profile_image;

  return (
    <div className="d-flex flex-column flex-xl-row justify-content-between">
      <div className="p-2 flex-grow-1">
        <p className="h5 font-weight-bold">요청 서비스</p>
        <div
          className="w-100 rounded border"
          style={{
            height: 250,
            background: `url(${serviceImage})50% 50%/cover no-repeat`,
          }}
        />
        <p className="mt-2">
          {SUBJECT_CODE[rowData.rl_subject]}{" "}
          <i className="icon-star voted text-warning" />
          {rowData.rl_rating}점 ({rowData.rl_rating_cnt}개)
        </p>
        <p className="h4 m-0 p-0 mb-1">{rowData.rl_title}</p>
        <p>{rowData.rl_addr_full}</p>
        <hr />

        <div className="row m-0 p-0 mb-2">
          <div className="col m-0 p-0 text-secondary">소요시간</div>
          <div className="col m-0 p-0 text-right">
            <p>{rowData.rl_base_hour_duration}</p>
          </div>
        </div>
        <div className="row m-0 p-0 mb-2">
          <div className="col m-0 p-0 text-secondary">기본인원</div>
          <div className="col m-0 p-0 text-right">
            <p>{rowData.rl_guest_min} 인</p>
          </div>
        </div>
        <div className="row m-0 p-0 mb-2">
          <div className="col m-0 p-0 text-secondary">최대인원</div>
          <div className="col m-0 p-0 text-right">
            <p>{rowData.rl_guest_max} 인</p>
          </div>
        </div>
        <div className="row m-0 p-0 mb-2">
          <div className="col m-0 p-0 text-secondary">기본요금</div>
          <div className="col m-0 p-0 text-right">
            <p>{util.addCommas(rowData.rrl_cost_guide)} 원</p>
          </div>
        </div>
        <div className="row m-0 p-0 mb-2">
          <div className="col m-0 p-0 text-secondary">추가인원 요금</div>
          <div className="col m-0 p-0 text-right">
            <p>&#8361; {util.addCommas(rowData.rl_over_pay_people)}</p>
          </div>
        </div>
        <div className="row m-0 p-0 mb-2">
          <div className="col m-0 p-0 text-secondary">추가시간 요금</div>
          <div className="col m-0 p-0 text-right">
            <p>&#8361; {util.addCommas(rowData.rl_over_pay_hour)}</p>
          </div>
        </div>
      </div>
      <div className="border-bottom d-xl-none" />
      <div
        className="py-2 flex-grow-1 d-flex flex-xl-row"
        style={{ minWidth: "33.333333%" }}
      >
        <div className="border-right d-none d-xl-block" />
        <div className="flex-fill px-3">
          <p className="h5 font-weight-bold">예약 상세</p>
          <div className="border rounded-lg text-center mb-3">
            <div className="border-bottom p-3 font-weight-bold bg-light">
              예약상태
            </div>
            <div className={`p-3 ${status_text_color} font-weight-bold`}>
              {STATUS_TYPE[rowData.rrl_status]}
            </div>
          </div>

          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">서비스 시작일시</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rrl_start_date}</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">서비스 종료일시</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rrl_end_date}</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">성인</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rrl_guest_adult_num} 인</p>
            </div>
          </div>
          <div
            className={`row m-0 p-0 mb-2 ${
              rowData.rrl_guest_child_num !== "0" ? "d-flex" : "d-none"
            }`}
          >
            <div className="col m-0 p-0 text-secondary">어린이</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rrl_guest_child_num} 인</p>
            </div>
          </div>
          <div
            className={`row m-0 p-0 mb-2 ${
              rowData.rrl_guest_baby_num !== "0" ? "d-flex" : "d-none"
            }`}
          >
            <div className="col m-0 p-0 text-secondary">유아</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rrl_guest_baby_num} 인</p>
            </div>
          </div>

          <hr />

          <div className={`row m-0 p-0 mb-2 `}>
            <div className="col m-0 p-0 text-secondary">기본요금</div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rrl_cost_guide)}</p>
            </div>
          </div>
          <div
            className={`row m-0 p-0 mb-2 ${
              rowData.rrl_cost_over_guest !== "0" ? "d-flex" : "d-none"
            }`}
          >
            <div className="col m-0 p-0 text-secondary">
              추가인원 {rowData.rrl_guest_over_cnt}명 x &#8361;{" "}
              {util.addCommas(rowData.rl_over_pay_people)}
            </div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rrl_cost_over_guest)}</p>
            </div>
          </div>
          <div
            className={`row m-0 p-0 mb-2 ${
              rowData.rrl_cost_over_hour !== "0" ? "d-flex" : "d-none"
            }`}
          >
            <div className="col m-0 p-0 text-secondary">
              추가시간 {rowData.rrl_cost_over_hour}시간 x &#8361;{" "}
              {util.addCommas(rowData.rl_over_pay_hour)}
            </div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rrl_cost_over_hour)}</p>
            </div>
          </div>

          <div
            className={`row m-0 p-0 mb-2 h6 text-secondary font-weight-bold`}
          >
            <div className="col m-0 p-0">총금액</div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rrl_cost_guide)}</p>
            </div>
          </div>
          <div className={`row m-0 p-0 mb-2 h6 text-danger font-weight-bold`}>
            <div className="col m-0 p-0">플랫폼 수수료</div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rrl_cost_fees_guide)}</p>
            </div>
          </div>
          <div className={`row m-0 p-0 mb-2 h6 text-buddible font-weight-bold`}>
            <div className="col m-0 p-0">정산예정금액</div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rrl_cost_paid)}</p>
            </div>
          </div>

          <div className="border rounded mt-3">
            <div className="bg-light p-3 border-bottom font-weight-bold text-center">
              요구 및 문의사항
            </div>
            <div className="p-3">{rowData.rrl_memo_guest || "-"}</div>
          </div>
        </div>
        <div className="border-right d-none d-xl-block" />
      </div>
      <div className="border-bottom d-xl-none" />

      <div
        className="p-2 flex-grow-1 d-flex flex-column justify-content-between"
        style={{ minWidth: "33.333333%" }}
      >
        <div>
          <p className="h5 font-weight-bold">예약자 정보</p>
          <div
            className="w-100 rounded-lg border mb-3"
            style={{
              height: 200,
              background: `url(${reservationImage}) 50% 50% / cover no-repeat`,
            }}
          />

          <div className={`row m-0 p-0 mb-2`}>
            <div className="col m-0 p-0 text-secondary">예약자명</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.u_name_full}</p>
            </div>
          </div>
          <div className={`row m-0 p-0 mb-2`}>
            <div className="col m-0 p-0 text-secondary">성별</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.u_gender === "M" ? "남성" : "여성"}</p>
            </div>
          </div>
          <div className={`row m-0 p-0 mb-2`}>
            <div className="col m-0 p-0 text-secondary">본인인증 여부</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.u_isVerified === "Y" ? "인증" : "미인증"}</p>
            </div>
          </div>
        </div>
        {rowData.rrl_status === "CR" && (
          <div className={`d-flex flex-row justify-content-end`}>
            <div
              className="btn_1 bg-danger mr-1"
              onClick={() => {
                handleUpdateReserve("GC", rowData);
              }}
            >
              거절
            </div>
            <div
              className="btn_1 bg-buddible ml-1"
              onClick={() => {
                handleUpdateReserve("GA", rowData);
              }}
            >
              승인
            </div>
          </div>
        )}
        {rowData.rrl_status !== "CR" && (
          <div className={`border rounded p-3`}>
            이미 승인 또는 취소/거절된 요청은 수정 처리할 수 없습니다.
          </div>
        )}
      </div>
    </div>
  );
};
