import React from "react";

export default function GuideRegistSafetyAvoidTouch(props) {
  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/safety3GuideLine.jpg")50% 50%/cover no-repeat`,
          }}
        />
      </div>
      <div>
        <p>게스트와 직접적인 신체 접촉을 피하세요.</p>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div>· </div>
              <p>
                가이드가 진행되는 동안 게스트와 일정 거리를 유지하고, 게스트 간
                거리에도 주의를 기울이세요.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div>· </div>
              <p>
                실내에서 진행되는 여행의 경우, 게스트 간 충분한 거리를 둘 수
                있도록 참여 인원을 제한해야 합니다.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div>· </div>
              <p>
                게스트와 직접적인 접촉을 피하고, 게스트에게도 다른 사람과의 직접
                접촉을 피하라고 안내하세요. 악수나 포옹 같은 직접적인 접촉을
                피하면서도 인사를 나눌 수 있는 방법을 활용하세요.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
