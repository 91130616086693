/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
const _mClassName = "HeaderAccController";
const EDIT_TYPE = {
  UPDATE: "UPDATE",
  INSERT: "INSERT",
  DELETE: "DELETE",
};

export default function HeaderAccController(props, ref) {
  console.log("HeaderAccController", props, ref);
  const { data = {} } = props;
  const [userData, setUserData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const $inputLoginID = useRef();
  const $inputLoginPWD = useRef();

  const $inputPWD = useRef();
  const $inputPWDRe = useRef();

  useEffect(() => {
    console.log("useEffect");
    let userdata = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userdata) {
      if (userdata.ret) {
        setUserInfo({ ...userdata["returnData"][0] });
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        console.log("EVENT_LOGIN_AUTO_SUCCESS");
        //console.log("HeaderAccController EVENT_LOGIN_AUTO_SUCCESS", n);
        if (n.ret) {
          setUserInfo({ ...n["returnData"][0] });
        }
      }
    );
    //
    // socket.addLocalEventListener(
    //   MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
    //   _mClassName,
    //   (b, n) => {
    //     console.log("EVENT_USER_MANAGE_EDIT_MODAL_CALL");
    //     setUserData({});
    //   }
    // );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      // socket.removeLocalEventListener(
      //   MsgIDList.EVENT_USER_MANAGE_EDIT_MODAL_CALL,
      //   _mClassName
      // );
    };
  }, []);

  const actionSave = (e) => {
    // console.log(codeList.Modal.current);
    console.log("actionSave");
    setLoading(true);
    if (checkValidation()) {
      let updateData = {
        uPWD: $inputPWD.current.value,
        uPWDRe: $inputPWDRe.current.value,
        uID: userInfo.uID,
      };

      requestPasswordUpdate(updateData, EDIT_TYPE.UPDATE);
    } else {
      setLoading(false);
    }
  };

  const checkValidation = () => {
    console.log("checkValidation");
    if ($inputPWDRe.current.value.trim() !== "") {
      if ($inputPWD.current.value.trim() !== $inputPWDRe.current.value.trim()) {
        codeList.Modal.current.alert(
          "비밀번호가 다릅니다. 비밀번호 확인을 다시 입력해주세요",
          () => {}
        );
        return false;
      }
    } else {
      codeList.Modal.current.alert("비밀번호 재확인을 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestPasswordUpdate = (updateData, TYPE = EDIT_TYPE.UPDATE) => {
    console.log("requestPasswordUpdate");
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/account/JS_password_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        // console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);

          codeList.Modal.current.alert(
            "사용자 정보를 업데이트 하였습니다.",
            () => {}
          );

          $("#btnCloseStaticPWDChangeModal").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "사용자 정보 업데이트 중 오류가 발생하였습니다.",
            () => {}
          );
        }
      }
    });
  };

  const actionLogin = (e) => {
    console.log("actionLogin");
    setLoading(true);
    if (checkLoginValidation()) {
      let updateData = {
        userID: util.buddibleEncrypt($inputLoginID.current.value),
        userPWD: util.buddibleEncrypt($inputLoginPWD.current.value),
      };

      requestLogin(updateData);
    } else {
      setLoading(false);
    }
  };

  const checkLoginValidation = () => {
    console.log("checkLoginValidation");
    if ($inputLoginID.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디를 입력해주세요", () => {});
      return false;
    }
    if ($inputLoginPWD.current.value.trim() === "") {
      codeList.Modal.current.alert("비밀번호를 입력해주세요", () => {});
      return false;
    }

    return true;
  };

  const requestLogin = (updateData) => {
    console.log("requestLogin");
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/login/JS_login.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };
    //setLoading(true);
    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        // console.log(newData["ret"]);
        if (newData["ret"] === true) {
          setLoading(false);

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            _mClassName,
            newData,
            () => {}
          );
          let date = new Date();
          newData["storageLifeTime"] = date.setDate(date.getDate() + 1);
          sessionStorage.setItem(
            "loginInfo",
            util.buddibleEncrypt(JSON.stringify(newData))
          );

          codeList.Modal.current.alert("로그인 되었습니다.", () => {
            $inputLoginID.current.value = "";
            $inputLoginPWD.current.value = "";
          });

          $("#btnCloseStaticLoginModal").click();
        } else {
          setLoading(false);
          codeList.Modal.current.alert(
            "로그인실패, 아이디 또는 비밀번호를 다시 입력해주세요.",
            () => {}
          );
        }
      }
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="staticPWDChangeModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticPWDChangeModal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>비밀번호 변경</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row justify-content-end">
                <label className="col-form-label col-sm-3 text-sm-end">
                  비밀번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    ref={$inputPWD}
                  />
                </div>
              </div>

              <div className="mb-3 row justify-content-end">
                <label className="col-form-label col-sm-3 text-sm-end">
                  비밀번호 확인
                </label>
                <div className="col-sm-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    ref={$inputPWDRe}
                  />
                </div>
                <label className="col-form-label col-sm-9 text-sm-end">
                  입력시 비밀번호가 변경됩니다.
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseStaticPWDChangeModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionSave(e)}
              >
                <i className="fa fa-check mr-2" />
                저장
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticLoginModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        aria-labelledby="staticLoginModal"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title">
                <strong>로그인</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3 row justify-content-end">
                <label className="col-form-label col-sm-3 text-sm-end">
                  아이디
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="ID"
                    autoComplete="off"
                    ref={$inputLoginID}
                  />
                </div>
              </div>

              <div className="mb-3 row justify-content-end">
                <label className="col-form-label col-sm-3 text-sm-end">
                  비밀번호
                </label>
                <div className="col-sm-9">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="off"
                    ref={$inputLoginPWD}
                    onKeyPress={(e) => {
                      // console.log(e);
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <label className="col-form-label col-sm-9 text-sm-end">
                  입력시 비밀번호가 변경됩니다.
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseStaticLoginModal"
              >
                <i className="fa fa-times mr-2" />
                취소
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => actionLogin(e)}
              >
                <i className="fa fa-check mr-2" />
                로그인
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
