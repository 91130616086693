// IE9의 경우
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

// IE11의 경우
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./config/lang/i18n";
import BuddibleSocket, { MsgIDList } from "./lib/BuddibleSocket";
import Websocket from "react-websocket";

import AutoLogin from "./lib/AutoLogin";
import CodeList from "./lib/CodeList";
import CommonUI from "./lib/CommonUI";
import App from "./App";

import "./index.css";
import "./DefaultStylesheet.css";

const socket = new BuddibleSocket();
const codeList = new CodeList();

ReactDOM.render(
  <>
    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
    <iframe
      width="0"
      height="0"
      src="https://msgserver.buddible.com:3100"
      style={{ display: "none" }}
    />
    <Websocket
      url="wss://msgserver.buddible.com:3100/"
      onMessage={socket.handleData}
      reconnect={true}
      onOpen={socket.handleOpen}
      onClose={socket.handleClose}
      ref={(Websocket) => {
        socket.changeServer(Websocket);
      }}
    />
    <AutoLogin />
    <CommonUI
      ref={(e) => {
        codeList.setModal(e);
      }}
    />
    <App />
  </>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
