import React, { useRef, useEffect } from "react";
import $ from "jquery";
import Utilities from "../../../lib/Utilities";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import MainLogoSVG from "../../../lib/MainLogoSVG";
import { useTranslation } from "react-i18next";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

let util = new Utilities();
let codeList = new CodeList();
const socket = new BuddibleSocket();

export default function LoginModal({ handleLogin }) {
  const { t } = useTranslation();
  let $userID = useRef();
  let $userPW = useRef();
  let $saveID = useRef();

  useEffect(() => {
    let saveID = localStorage.getItem("userID");
    if (saveID) {
      if ($userID.current && $saveID.current) {
        saveID = util.buddibleDecrypt(saveID);
        $userID.current.value = saveID;
        $saveID.current.checked = true;
      }
    }
  }, []);

  //login 이벤트 실행
  const actionLogin = (e) => {
    if (checkLoginValidation()) {
      let updateData = {
        user_id: util.buddibleEncrypt($userID.current.value),
        user_pw: util.buddibleEncrypt($userPW.current.value),
        u_oauth_type: "buddible",
      };

      sendLoginToServer(updateData);
    } else {
    }
  };

  //아이디 패스워드 입력 검사
  const checkLoginValidation = () => {
    if ($userID.current.value.trim() === "") {
      codeList.Modal.current.alert(t("loginCheckIDMsg"), () => {});
      return false;
    }
    if ($userPW.current.value.trim() === "") {
      codeList.Modal.current.alert(t("loginCheckPWMsg"), () => {});
      return false;
    }

    return true;
  };

  const sendLoginToServer = (updateData) => {
    let loginSaveCheck = $saveID.current.checked;
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/login/JS_login.php",
      msgID: msgID,
      data: { ...updateData },
      ...updateData,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"] === true) {
          $("#loginModalClose").click();

          let data = socketMsg.data;
          let loginInfo = {
            ...newData["returnData"][0],
            data,
            storageLifeTime: +new Date() + 3600000,
          };

          let encrypt = JSON.stringify(loginInfo);
          encrypt = util.buddibleEncrypt(encrypt);

          sessionStorage.setItem("loginInfo", encrypt);
          if (loginSaveCheck) {
            const userID = updateData.user_id;
            localStorage.setItem("userID", userID);
          } else {
            localStorage.removeItem("userID");
          }

          codeList.Modal.current.alert(t("loginSuccessMsg"), () => {
            $userID.current.value = "";
            $userPW.current.value = "";
            handleLogin(true);
            window.location.replace("/Main");
          });
        } else {
          codeList.Modal.current.alert(t("loginFailedMsg"), () => {});
        }
      }
    });
  };

  const closeModal = () => {
    let saveID = localStorage.getItem("userID");
    if (saveID) {
      if ($userID.current && $saveID.current) {
        saveID = util.buddibleDecrypt(saveID);
        $userID.current.value = saveID;
        $saveID.current.checked = true;
      }
    } else {
      $userID.current.value = "";
      $userPW.current.value = "";
      $saveID.current.checked = false;
    }
    $("#loginModalClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="col-2 m-0 p-0">
                <MainLogoSVG />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="loginModalClose"
                onClick={closeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="sign-in-wrapper">
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="text"
                    placeholder={t("id")}
                    ref={(ref) => {
                      $userID.current = ref;
                    }}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="password"
                    placeholder={t("pw")}
                    ref={$userPW}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        actionLogin();
                      }
                    }}
                  />
                </div>

                <div className="clearfix mb-2">
                  <div className="d-flex flex-row justify-content-between">
                    <div className="checkboxes float-left">
                      <label className="container_check" htmlFor="saveID">
                        {t("loginSaveID")}
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="saveID"
                          ref={$saveID}
                        />
                        <span className="checkmark" />
                      </label>
                    </div>
                    <div>
                      <button
                        className="font-size-09 text-secondary cursor_pointer bg-transparent border-0 p-0"
                        data-target="#FindPWDModal"
                        data-toggle="modal"
                        onClick={() => closeModal()}
                      >
                        비밀번호 찾기
                      </button>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <input
                    style={{ background: "#004572" }}
                    type="submit"
                    value={t("login")}
                    className="btn_login"
                    onClick={() => actionLogin()}
                  />
                </div>
                <div className="text-center">
                  <input
                    style={{ background: "#004572" }}
                    type="button"
                    value={t("join")}
                    className="btn_login"
                    onClick={() => closeModal()}
                    data-target="#registModal"
                    data-toggle="modal"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
