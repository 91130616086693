import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import GuidePicSocialCheckModal from "./GuidePicSocialCheckModal";

import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "GuideManageTable";
const GENDER_TYPE = {
  F: "여성",
  M: "남성",
  f: "여성",
  m: "남성",
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const headCells = [
  {
    field: "gl_confirm_admin",
    headerName: "상태",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let confirmClass = "badge-secondary";
      let confirm = "작성중";

      if (params.data === "W") {
        confirmClass = "badge-secondary";
        confirm = "작성중";
      }
      if (params.data === "S") {
        confirmClass = "badge-primary";
        confirm = "검토대기";
      }
      if (params.data === "Y") {
        confirmClass = "badge-success";
        confirm = "승인";
      }
      if (params.data === "N") {
        confirmClass = "badge-danger";
        confirm = "거부";
      }
      if (params.data === "C") {
        confirmClass = "badge-warning";
        confirm = "등록취소";
      }
      return (
        <p className="text-center">
          <span className={`badge badge-pill ${confirmClass} px-2 py-1`}>
            {confirm}
          </span>
        </p>
      );
    },
  },

  {
    field: "gl_idx",
    minWidth: 70,
    headerName: "No.",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "gl_title",
    headerName: "한줄소개",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let str = params.data;
      if (str && str.length >= 12) {
        str = str.slice(0, 12) + "...";
      }
      return (
        <span className="text-truncate text-wrap text-break">{str || "-"}</span>
      );
    },
  },
  {
    field: "gl_name",
    headerName: "이름",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data || "-"}
        </span>
      );
    },
  },

  {
    field: "gl_addr_full",
    headerName: "활동지역",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let str = params.data;
      if (str && str.length >= 12) {
        str = str.slice(0, 12) + "...";
      }
      return (
        <span className="text-truncate text-wrap text-break">{str || "-"}</span>
      );
    },
  },
  {
    field: "gl_lang_main",
    headerName: "언어",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span className="text-truncate text-wrap text-break">
          {params.data ? codeList.codeLanguage.get(params.data)?.name_ko : "-"}
        </span>
      );
    },
  },
  {
    field: "gl_gender",
    headerName: "성별",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data ? GENDER_TYPE[params.data] : "-"}
      </span>
    ),
  },
  {
    field: "gl_editDate",
    headerName: "최종수정일",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {util.getDateToFormat(params.data, "YYYY-MM-DD")}
      </span>
    ),
  },
  {
    field: "gl_regDate",
    headerName: "기능",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      const { currentData, updateConfirmAdmin, openConfirmDetail } = params;
      const isConfirmDisabled =
        currentData.gl_confirm_admin === "W" ||
        currentData.gl_confirm_admin === "C";

      return (
        <div className="d-flex flex-row">
          <div className="d-flex flex-row justify-content-end min-width-200">
            {currentData.gl_confirm_admin === "S" && (
              <button
                className="btn btn-sm btn-secondary mr-2"
                data-toggle="modal"
                data-target="#GuidePicSocialCheckModal"
                onClick={(e) => params.openEdit(e, params)}
              >
                신분증 검토
              </button>
            )}
            <button
              className="btn btn-sm btn-secondary mr-2"
              onClick={() => openConfirmDetail(currentData.gl_idx)}
            >
              화면 검토
            </button>
          </div>
          <select
            className="form-control"
            style={{ maxWidth: 200 }}
            disabled={isConfirmDisabled}
            value={currentData.gl_confirm_admin}
            onChange={(e) => {
              updateConfirmAdmin({
                ...currentData,
                gl_confirm_admin: e.target.value,
              });
            }}
          >
            <option value="W" disabled={!isConfirmDisabled}>
              작성중
            </option>
            <option value="S" disabled={!isConfirmDisabled}>
              제출
            </option>
            <option value="Y">승인</option>
            <option value="N">거절</option>
            <option value="C" disabled={!isConfirmDisabled}>
              취소(가이드)
            </option>
          </select>
        </div>
      );
    },
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, openEdit, updateConfirmAdmin, openConfirmDetail } = props;
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        key={row.id}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  updateConfirmAdmin: updateConfirmAdmin,
                  openConfirmDetail: openConfirmDetail,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  updateConfirmAdmin: updateConfirmAdmin,
                  openConfirmDetail: openConfirmDetail,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
    </>
  );
}

export default function GuideManageTable({
  isServerPaging = true,
  isServerSort = true,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});
  const [serverData, setServerData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("gl_editDate");

  const [dense, setDense] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "gl_editDate",
      dir: "desc",
    },
  ]);
  const [filters, setFilters] = useState({});
  const editModal = useRef();

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      setLoginInfo(userData);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!n["returnData"].length) return;
        setLoginInfo(n["returnData"][0]);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_GUIDE_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
      }
    );
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_GUIDE_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, filters, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_list_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  // 관리자 검토 상태 업데이트
  const updateConfirmAdmin = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_confirm_admin.php",
      msgID: msgID,
      gl_idx: updateData.gl_idx,
      gl_confirm_admin: updateData.gl_confirm_admin,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상처리되었습니다", () => {
            requestUserSelect();
          });
        } else {
          codeList.Modal.current.alert(
            "가이드 등록 검토상태 변경중 오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  // 검토화면 열기
  const openConfirmDetail = (idx) => {
    window.open(
      `/GuideDetail/${idx}`,
      "guideConfirm",
      "width=1680,height=1080"
    );
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const openEdit = (e, params) => {
    editModal.current.openModal(e, params);
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);
  const rowProps = {
    updateConfirmAdmin,
    openConfirmDetail,
    openEdit,
  };
  return (
    <>
      <GuidePicSocialCheckModal ref={editModal} loginInfo={loginInfo} />
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={`row_${index}`}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={`row_${index}`}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    key={`row_${index}`}
                    row={row}
                    index={index}
                    {...rowProps}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  key={`row_${index}`}
                  row={row}
                  index={index}
                  {...rowProps}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}
