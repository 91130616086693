/*global google*/

import React, { Component } from "react";

import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

class GuideTimeRegistPlaceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: props.position,
      selected: [],
    };
  }

  componentDidMount({ map, mapApi } = this.props) {
    this.searchBox = new mapApi.places.Autocomplete(this.input);
    // searchBox 에서 장소 선택 시 이벤트 발생(places_changed 는 구글에서 제공 되는 이벤트)
    this.searchBox.addListener("place_changed", this.onPlacesMultiChanged);
    //searhBox 에서 결과가 map 화면에 뿌려주며 화면을 이동
    this.searchBox.bindTo("bounds", map);
  }

  componentWillUnmount({ map, mapApi } = this.props) {
    mapApi.event.clearInstanceListeners(this.input);
  }

  //여러개의 마커를 표시
  onPlacesMultiChanged = ({ map, addPlace, mapApi } = this.props) => {
    const selected = this.searchBox.getPlace();

    if (!selected.hasOwnProperty("address_components")) {
      codeList.Modal.current.alert("검색 목록에서 선택 해 주세요.");
      const sendLocation = {
        gpt_addr_lat: 0,
        gpt_addr_lng: 0,
        // gpt_addr_full: "",
        gpt_addr_name: "",
        gpt_addr_country: "",
        gpt_addr_postal_code: "",
        gpt_addr_area_level_1: "",
        gpt_addr_sublocality_level_1: "",
        gpt_addr_sublocality_level_2: "",
        gpt_addr_premise: "",
        gpt_addr_place_id: "",
      };
      this.props.handleTourDataChange(sendLocation);
      return;
    }

    let bounds = new mapApi.LatLngBounds();
    const latLng = {
      lat: selected.geometry.location.lat(),
      lng: selected.geometry.location.lng(),
    };

    if (!selected.geometry) {
      return;
    }
    if (selected.geometry.viewport) {
      bounds.union(selected.geometry.viewport);
    } else {
      bounds.extend(selected.geometry.location);
    }
    map.fitBounds(bounds);
    addPlace(selected);

    new google.maps.Marker({
      position: latLng,
      map,
      title: "다중 위치",
    });

    const sendLocation = {
      gpt_addr_lat: selected.geometry.location.lat(),
      gpt_addr_lng: selected.geometry.location.lng(),
      // gpt_addr_full: selected.formatted_address,

      gpt_addr_name: selected.name,
      gpt_addr_country: "",
      gpt_addr_postal_code: "",
      gpt_addr_area_level_1: "",
      gpt_addr_sublocality_level_1: "",
      gpt_addr_sublocality_level_2: "",
      gpt_addr_premise: "",
      gpt_addr_place_id: selected.place_id,
    };
    let address_components = selected.address_components;
    for (let i = 0; i < address_components.length; i++) {
      if (address_components[i].hasOwnProperty("types")) {
        let types = address_components[i]["types"];
        if (types.findIndex((element) => element === "premise") > -1)
          sendLocation.gpt_addr_premise = address_components[i].short_name;
        if (
          types.findIndex((element) => element === "sublocality_level_2") > -1
        )
          sendLocation.gpt_addr_sublocality_level_2 =
            address_components[i].short_name;
        if (
          types.findIndex((element) => element === "sublocality_level_1") > -1
        )
          sendLocation.gpt_addr_sublocality_level_1 =
            address_components[i].short_name;
        if (
          types.findIndex(
            (element) => element === "administrative_area_level_1"
          ) > -1
        )
          sendLocation.gpt_addr_area_level_1 = address_components[i].short_name;
        if (types.findIndex((element) => element === "country") > -1)
          sendLocation.gpt_addr_country = address_components[i].short_name;
        if (types.findIndex((element) => element === "postal_code") > -1)
          sendLocation.gpt_addr_postal_code = address_components[i].short_name;
      }
    }
    this.props.handleTourDataChange(sendLocation);
  };

  render() {
    return (
      <input
        id="pac-input"
        className="form-control mt-3"
        type="text"
        defaultValue={this.props.partTimeData.gpt_addr_name}
        maxLength="20"
        ref={(ref) => (this.input = ref)}
      />
    );
  }
}

export default GuideTimeRegistPlaceSearch;
