import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TourListCategory(props) {
  const { t } = useTranslation();

  const TRAVEL_NAME = {
    travel: t("hotPlace"),
    rest: t("food"),
    nightlife: t("experience"),
    recreation: t("cultureAndShopping"),
  };

  let isShowSubMenu = false;
  let key = "";
  if (props.match.params.hasOwnProperty("travelType")) {
    isShowSubMenu = true;
    key = props.match.params.travelType;
  }

  return (
    <div id="position">
      <div className="container">
        <ul>
          <li>
            <Link to="/">{t("mainPage")}</Link>
          </li>
          <li>
            <Link to="/TourDetailList">{t("tour")}</Link>
          </li>
          {isShowSubMenu && (
            <li>
              <p>{TRAVEL_NAME[key]}</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
