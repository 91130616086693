import React, { useEffect, useState } from "react";
import BuddibleSocket, {
  MsgIDList,
} from "../../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../../lib/Utilities";
import CodeList from "../../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function GuideTourRegistTitle(props) {
  let { tourData, loginInfo, guideTourRegistDataChange } = props;

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (val) => {
    guideTourRegistDataChange({ rl_title: val });
  };
  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold">
          여행 주제에 맞는 제목을 입력하세요.
        </h6>
        <input
          defaultValue={tourData.rl_title}
          type="text"
          className="form-control mt-3"
          placeholder="여기에 제목을 입력하세요"
          disabled={tourData.rl_confirm_admin === "S"}
          onChange={(e) => {
            handleTourDataChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
}
