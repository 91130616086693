import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const countryList = codeList.codeCountry;

const mClassName = "GuideDetailSuggestionOther";
let guideList = null;

export default function GuideDetailSuggestionOther(props) {
  const { guideData } = props;
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [itemViewCount, setItemViewCount] = useState(4);

  useEffect(() => {
    handleItemViewCount();
    window.addEventListener("resize", handleItemViewCount);

    const data = socket.getLocalDataSet(
      MsgIDList.EVENT_MAIN_PART_TIME_GUIDE_DATA_CHANGED
    );
    if (data) {
      guideList = data;
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      socket.waitingForConnect(mClassName, () => {
        GET_FREE_GUIDE_LIST();
      });
    }
    return () => {
      window.removeEventListener("resize", handleItemViewCount);
    };
  }, []);

  //스크린 너비에 따른 가이드 View 개수 조절
  const handleItemViewCount = () => {
    let screenWidth = window.innerWidth;
    let changeViewCount = 0;
    //  모바일
    if (screenWidth < 400) {
      changeViewCount = 1.1;
    }

    if (screenWidth >= 400 && screenWidth <= 720) {
      changeViewCount = 2.1;
    }
    //  테블릿
    if (screenWidth > 720 && screenWidth < 1140) {
      changeViewCount = 3.1;
    }

    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 5.1;
    }
    if (itemViewCount !== changeViewCount) {
      setItemViewCount(changeViewCount);
    }
  };

  //인기 가이드 불러오기
  function GET_FREE_GUIDE_LIST() {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_list_select.php",
      msgID: msgID,
      data: {},
      take: 20,
      skip: 0,
      gl_rating: 5,
      gl_confirm_admin: "Y",
      current_uid: guideData.gl_guide_uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          guideList = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  }

  const GuideItemRender = useCallback(({ item }) => {
    return (
      <GuideItem
        item={item}
        gl_idx={item.gl_idx}
        gl_guide_uid={item.gl_guide_uid}
        gl_pic={item.gl_pic}
        gl_name={item.gl_name}
        gl_title={item.gl_title}
        gl_desc={item.gl_desc}
        gl_rating={item.gl_rating}
        gl_rating_cnt={item.gl_rating_cnt}
        gl_addr_country={item.gl_addr_country}
        gl_price_base={item.gl_price_base}
        gl_price_per_hour={item.gl_price_per_hour}
      />
    );
  }, []);

  return (
    <div className="container margin_60 ">
      <p className="h5">{t("guideDetailSuggestionOtherTitle")}</p>
      {guideList !== null && (
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          dots={false}
          nav
          navClass={["moveBtn mr-1", "moveBtn ml-1"]}
          items={itemViewCount}
        >
          {guideList.map((item) => {
            return <GuideItemRender key={item.gl_idx} item={item} />;
          })}
        </OwlCarousel>
      )}
    </div>
  );
}

const GuideItem = React.memo(
  ({
    gl_idx,
    gl_guide_uid,
    gl_pic,
    gl_name,
    gl_title,
    gl_desc,
    gl_rating,
    gl_rating_cnt,
    gl_addr_country,
    gl_price_base,
    gl_price_per_hour,
  }) => {
    const { t } = useTranslation();
    return (
      <Link to={`/GuideDetail/${gl_idx}`} className="item">
        <div className="tour_container" style={{ minHeight: 300 }}>
          <div className="img_container">
            <div
              className="owl_carousel_img"
              style={{
                background: `url(${util.changedResourceUrl(
                  gl_pic
                )})50% 50%/cover no-repeat`,
              }}
            />

            <div className="short_info">
              <span className="price">
                <sup>&#8361;</sup> {util.addCommas(gl_price_base)}
              </span>
            </div>
          </div>
          <div className="tour_title">
            <h3 className="overflow-hidden text-truncate">
              <strong>
                {countryList.get(gl_addr_country) &&
                  countryList.get(gl_addr_country).name_ko}
                · {gl_name}
              </strong>
            </h3>
            <p className="overflow-hidden text-truncate">
              {gl_title || gl_desc || <br />}
            </p>
            <div className="rating">
              <i
                className={
                  gl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  gl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <small>({gl_rating_cnt})</small>
            </div>
            {/*
            <div>
              <p>
                &#8361; {util.addCommas(gl_price_base)} / 추가 1시간당 &#8361;
                {util.addCommas(gl_price_per_hour)}
              </p>
            </div>
          */}
          </div>
        </div>
      </Link>
    );
  }
);
