import React, { useCallback, useMemo } from "react";

import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";

const util = new Utilities();
const codeList = new CodeList();

const CHAT_STATUS = {
  CI: { desc: "문의", color: "secondary" },
  CR: { desc: "요청", color: "secondary" },
  GA: { desc: "승인", color: "primary" },
  GC: { desc: "거절", color: "warning" },
  CC: { desc: "취소", color: "danger" },
  SS: { desc: "완료", color: "success" },
  CE: { desc: "종료", color: "secondary" },
};

export default function ChatList(props) {
  const { _chatList, getChatDataByUser } = props;

  const ChatRoomItemRender = useCallback((props) => {
    return <ChatRoomItem {...props} />;
  }, []);

  const chatList = useMemo(() => {
    return Object.values(_chatList);
  }, []);

  return (
    <>
      <div className="p-3 border-bottom text-secondary chat-header">
        <span className="h6 mb-0 font-weight-bold">메세지</span>
      </div>
      <div className="chat-list-end overflow_scroll_y">
        {chatList.map((item) => {
          return (
            <ChatRoomItemRender
              key={item.crm_main_uid}
              item={item}
              getChatDataByUser={getChatDataByUser}
            />
          );
        })}
      </div>
    </>
  );
}
const today = new Date();
const todayStart = +new Date(`${util.todayDate("YYYY-MM-DD")} 00:00:00`);

const ChatRoomItem = React.memo((props) => {
  const { item, getChatDataByUser } = props;

  let toUserName = item.isCreator ? item.crm_guide_name : item.crm_creator_name;
  let toUserPic = util.changedResourceUrl(
    item.isCreator ? item.crm_guide_avata_pic : item.crm_creator_avata_pic
  );
  if (toUserPic === "") {
    toUserPic = codeList.publicVar.unProfileImage;
  }
  let lastTime = "";

  const date = new Date(item.crm_last_time);
  if (+new Date(item.crm_last_time) <= todayStart) {
    if (today.getFullYear() > date.getFullYear()) {
      lastTime = util.getDateToFormat(item.crm_last_time, "YYYY.MM.DD");
    } else {
      lastTime = util.getDateToFormat(item.crm_last_time, "MM.DD");
    }
  } else {
    const hour = date.getHours();
    const min = date.getMinutes();
    lastTime = `${hour > 12 ? "오후 " + hour - 12 : "오전 " + hour}:${min}`;
  }

  return (
    <div
      className="p-3 mb-2 cursor_pointer cursor_pointer_bg"
      onClick={() => getChatDataByUser(item)}
    >
      <div className="d-flex flex-row align-items-center">
        <img
          src={toUserPic}
          alt="이미지"
          width={50}
          height={50}
          className="rounded-circle avata_pic border"
        />
        <div className="pl-3 flex-fill">
          <div className="d-flex flex-row justify-content-between">
            <p className="font-weight-bold">
              <span
                className={`badge badge-${
                  CHAT_STATUS[item.crm_status].color
                } mr-1`}
              >
                {CHAT_STATUS[item.crm_status].desc}
              </span>
              {toUserName}
            </p>
            {/*<span className="badge badge-primary font-size-08">3</span>*/}
          </div>
          <div className="d-flex flex-row justify-content-between align-items-end">
            <span className="ellipsis_1" style={{ maxWidth: 200 }}>
              {item.crm_last_msg}
            </span>
            <p className="font-size-08 font-weight-bold text-secondary">
              {lastTime}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
