import React, { useState, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";
import CodeList, { CommonConstList } from "./CodeList";

let util = new Utilities();
let codeList = new CodeList();

const InitCodeList = ({ initFinished }) => {
  let socket = new BuddibleSocket();
  let initMaxCnt = 5;
  let initCurrentCnt = 0;

  useEffect(() => {
    socket.waitingForConnect("InitCodeList", () => {
      requestCountry(initDone);
      requestLanguage(initDone);
      requestSubject(initDone);
      codeList.requestHashtagList(initDone);
      requestCodeOffer(initDone);
    });
  }, []);

  const initDone = () => {
    initCurrentCnt++;
    //console.log("initDone", initCurrentCnt);
    if (initMaxCnt === initCurrentCnt) initFinished();
  };

  const requestCodeOffer = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/common/JS_code_offer_list_select.php",
      msgID: msgID,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let returnData = newData["returnData"];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];

            let value = codeList.codeOffer.get(obj.co_type) || new Map();
            value.set(obj.co_code, {
              co_code: obj.co_code,
              co_name_kr: obj.co_name_kr,
              co_name_en: obj.co_name_en,
              co_desc_kr: obj.co_desc_kr,
              co_desc_en: obj.co_desc_en,
              co_ui_id: obj.co_ui_id,
              co_ui_name: obj.co_ui_name,
              co_ui_key: obj.co_ui_key,
            });

            codeList.codeOffer.set(obj.co_type, value);
          }

          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_OFFER,
            "InitCodeList",
            codeList.codeOffer,
            () => {}
          );
          callback && callback(newData);
        }
      }
    });
  };

  /*  const requestHashtagList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      method: "GetHashtagList",
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            codeList.codeHashtag.set(obj.htc_key, obj.htc_name);
          }

          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_HASHTAG,
            "InitCodeList",
            codeList.codeHashtag,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };*/

  const requestSubject = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      msgID: msgID,
      method: "GetCodeSubject",
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            codeList.codeSubject.set(obj.cs_code, {
              cs_code: obj.cs_code,
              cs_name_kr: obj.cs_name_kr,
              cs_name_en: obj.cs_name_en,
            });
          }

          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_SUBJECT,
            "InitCodeList",
            codeList.codeSubject,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };

  const requestCountry = (callback) => {
    let msgID = util.makeUUIDv4();

    let socketMsg = {
      msgID: msgID,
      method: "GetCodeCountry",
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            codeList.codeCountry.set(obj.cc_code2, {
              id: obj.cc_code2,
              id3: obj.cc_code3,
              name: obj.cc_en_name,
              name_ko: obj.cc_ko_name,
            });
          }

          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_COUNTRY,
            "InitCodeList",
            codeList.codeCountry,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };
  const requestLanguage = (callback) => {
    let msgID = util.makeUUIDv4();

    let socketMsg = {
      msgID: msgID,
      method: "GetCodeLanguage",
      data: {},
    };

    socket.sendMessage(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["Ret"]) {
          let returnData = newData["ReturnData"];
          for (let i = 0; i < returnData.length; i++) {
            let obj = returnData[i];
            codeList.codeLanguage.set(obj.cl_code, {
              id: obj.cl_code,
              name: obj.cl_en_name,
              name_ko: obj.cl_ko_name,
            });
          }

          socket.sendLocalMessage(
            MsgIDList.EVENT_INIT_CODE_LANGUAGE,
            InitCodeList,
            codeList.codeLanguage,
            () => {}
          );
          callback && callback();
        }
      }
    });
  };

  return <></>;
};

export default InitCodeList;
