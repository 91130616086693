import React, { useEffect, useRef, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";
import UploadImageToS3WithReactS3 from "../../../../../lib/UploadImageToS3WithReactS3";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuideRegistProfileIntro";

let profileData = {
  gl_pic: "",
};

export default function GuideRegistProfileIntro(props) {
  const { guideData, loginInfo } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (guideData.gl_guide_uid) {
      profileData.gl_pic = guideData.gl_pic;
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  const uploadProfileCallback = (res) => {
    if (res.ret) {
      let file = `${res.realFileName}?${res.fileName}`;
      profileData.gl_pic = file;
      guideDataChange("gl_pic", file);
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      codeList.Modal.current.alert(
        "이미지 업로드중 오류가 발생했습니다. 다시 시도해주세요"
      );
    }
  };

  //가이드데이터 변경
  const guideDataChange = (key, val) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      { [key]: val },
      () => {}
    );
  };
  return (
    <div className="col-xl-6">
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold mb-2">성명</h6>
        <p className="mb-3">
          가이드활동에 사용될 닉네임을 작성해주세요. 작성된 닉네임은 여행자들이
          가이드님을 부를 때 사용됩니다.
          {/*
          게스트는 진행하는 가이드에 대해 알고 싶어 합니다.
          회사명이 아니라
          가이드의 실명을 사용해야 합니다. 가이드루트 페이지에는 (성을 제외한)
          이름만 표시됩니다. 공동 가이드가 있다면, 공동 가이드 정보는 나중에
          추가하실 수 있습니다.*/}
        </p>
        <div className="d-flex flex-column flex-md-row justify-content-between mb-2">
          <div className="flex-fill d-flex flex-row mb-2">
            <div className="flex-fill">
              <input
                className="form-control"
                placeholder="가이드명을 입력해주세요"
                defaultValue={guideData.gl_name}
                disabled={guideData.gl_confirm_admin === "S"}
                onChange={(e) => {
                  guideDataChange("gl_name", e.target.value);
                }}
              />
            </div>
            {/*            <div className="flex-fill ml-2 mx-md-2">
              <input
                className="form-control"
                placeholder="이름"
                disabled={guideData.gl_confirm_admin === "S"}
                defaultValue={loginInfo.u_name_last}
              />
            </div>*/}
          </div>
          {/*          <button
            className="btn_1 flex-grow-1 d-block mb-2 min-width-150"
            disabled={guideData.gl_confirm_admin === "S"}
            onClick={updateUserInfo}
          >
            이름 저장
          </button>*/}
        </div>

        <h6 className="font-weight-bold mb-2">연락처(선택사항)</h6>
        <p className="mb-3">
          가이드활동에 사용될 연락처를 선택기재해주세요. 기재된 연락처는
          플레이영종의 버디 지원에 사용됩니다.
        </p>
        <div>
          <input
            className="form-control"
            placeholder="연락처를 입력해주세요"
            defaultValue={guideData.gl_mobile}
            disabled={guideData.gl_confirm_admin === "S"}
            onChange={(e) => {
              guideDataChange("gl_mobile", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold mb-2">프로필 이미지</h6>
        <p className="mb-3">
          게스트가 가이드의 얼굴을 확인할 수 있어야 합니다. 회사 로고,
          반려동물의 사진, 아무것도 없는 이미지 등은 올릴 수 없습니다. 가이드의
          모습이 나오지 않은 사진은 허용되지 않습니다.
        </p>
        <div className="mb-3">
          <div
            className="rounded-circle border d-flex flex-row justify-content-center align-items-center mb-3"
            style={{
              width: 150,
              height: 150,
              background: `url(${
                profileData.gl_pic ? profileData.gl_pic.split("?")[1] : ""
              })50% 50%/cover no-repeat`,
            }}
          >
            {!profileData.gl_pic && (
              <p className="text-secondary text-center">프로필 이미지</p>
            )}
          </div>
          <div className="d-inline-block">
            <UploadImageToS3WithReactS3
              region={`images/guide/${loginInfo.u_email}`}
              callback={uploadProfileCallback}
              disabled={guideData.gl_confirm_admin === "S"}
            />
          </div>
        </div>
      </div>
      <div className="py-3 border-bottom">
        <h6 className="font-weight-bold mb-2">가이드에 대한 설명</h6>
        <p className="mb-2">
          1. 먼저, 가이드에서 어떤 활동을 하게 될지 간략히 설명하세요. 비슷한
          다른 체험과 차별되는 특별한 점은 무엇인가요?
        </p>
        <p className="mb-2">
          2. 이제 좀 더 구체적으로 설명할 차례입니다. 가이드를 어떻게
          시작하시겠어요? 가이드 진행되는 동안 게스트의 참여를 독려하기 위해
          어떻게 하시겠어요?
        </p>
        <p className="mb-2">
          3. 마지막으로, 가이드를 마친 게스트가 얻어갔으면 하는 점이 무엇인지
          적어보세요. 새로 사귄 친구, 새롭게 알게 된 지식, 여행지의 문화에 대한
          이해 등 가이드의 장점을 강력하게 내세우며 마무리하세요.
        </p>
        <textarea
          rows="10"
          className="form-control"
          defaultValue={guideData.gl_desc}
          disabled={guideData.gl_confirm_admin === "S"}
          onChange={(e) => {
            guideDataChange("gl_desc", e.target.value);
          }}
        />
      </div>
      <div className="py-3">
        <h6 className="font-weight-bold mb-2">
          다음 중 본인과 가이드하실 체험에 대해 가장 잘 설명하는 것을
          선택하세요.
        </h6>
        <div>
          <label className="d-block" htmlFor="single_guide">
            <input
              onClick={(e) => {
                guideDataChange("gl_alone_yn", "Y");
              }}
              type="radio"
              id="single_guide"
              name="gl_alone_yn"
              className="mr-2"
              disabled={guideData.gl_confirm_admin === "S"}
              defaultChecked={guideData.gl_alone_yn === "Y"}
            />
            혼자서 가이드합니다.
          </label>
          <label className="d-block" htmlFor="team_guide">
            <input
              onClick={(e) => {
                guideDataChange("gl_alone_yn", "N");
              }}
              type="radio"
              id="team_guide"
              className="mr-2"
              name="gl_alone_yn"
              disabled={guideData.gl_confirm_admin === "S"}
              defaultChecked={guideData.gl_alone_yn === "N"}
            />
            가이드을 도와주는 팀이 있습니다.
          </label>
        </div>
      </div>
    </div>
  );
}
