import React from "react";
import PropTypes from "prop-types";

export default function Caution(props) {
  const { desc = "" } = props;

  return (
    <div className="d-flex flex-row mt-3">
      <p className="min-width-90">
        <i className="fas fa-exclamation-circle mr-2" />
        <span>유의사항 :</span>
      </p>
      <p>{desc}</p>
    </div>
  );
}

Caution.propTypes = {
  desc: PropTypes.string.isRequired,
};
