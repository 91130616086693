import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { Link } from "react-router-dom";
import CountrySelect from "../../../lib/CountrySelect";
import LanguageSelect from "../../../lib/LanguageSelect";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

let country_list = [];
let travel_list = [];

let totalCount = 100;
let totalPageCount = 1;
let rowsPerPage = 12;
let page = 0;

const filters_default = {
  rl_rating: 0, // 별점 검색
  rl_guest_max: 1, //인원수 검색
};
let filters = {
  SUBJECT: "",
  SEARCH_DATE: "", //시작일
  sl_edate: "", //종료일
  rl_addr_country: "", //국가
  gl_lang_main: "", //사용언어
  rl_price_base: "", //금액 검색
  rl_rating: 0, // 별점 검색

  //기본제공
  rl_inc_meal: "", // 식사제공
  rl_inc_snack: "", //간식제공
  rl_inc_drink: "", //음료제공
  rl_inc_ticket: "", //티켓제공
  rl_inc_vehicle: "", //탈 것 제공

  rl_guest_max: 1, //인원수 검색
  rl_can_child: "", //영유아 동반
};

export default function TourDetailListContents(props) {
  const { t } = useTranslation();
  const [sortModel, setSortModel] = useState([
    {
      field: "rl_rating_cnt",
      dir: "desc",
    },
  ]);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isFilterCollapse, setIsFilterCollapse] = useState(false);
  const params = props.match.params;
  const classes = useStyles();
  const $controller = {
    SEARCH_DATE: useRef(),
    sl_edate: useRef(),
    rl_addr_country: useRef(),
    gl_lang_main: useRef(),
    rl_price_base: useRef(),
    rl_inc_meal: useRef(),
    rl_inc_snack: useRef(),
    rl_inc_drink: useRef(),
    rl_inc_ticket: useRef(),
    rl_inc_vehicle: useRef(),
    rl_guest_max: useRef(),
    rl_can_child: useRef(),
  };

  const SORT_MODEL = [
    {
      key: "rl_rating_cnt",
      sort: "desc",
      title: t("tourDetailListSortFamous"),
    },
    {
      key: "rl_price_base",
      sort: "asc",
      title: t("tourDetailListSortLowCost"),
    },
    {
      key: "rl_price_base",
      sort: "desc",
      title: t("tourDetailListSortHighCost"),
    },
    { key: "rl_rating", sort: "desc", title: t("tourDetailListSortRating") },
  ];

  const TRAVEL_TYPE = [
    {
      key: "all",
      title: t("showAll"),
      keywords: [],
      icon: "icon_set_1_icon-51",
    },
    {
      key: "hotPlace",
      title: t("hotPlace"),
      keywords: ["NAT", "SOC", "SHO"],
      icon: "icon_set_1_icon-3",
    },
    {
      key: "experience",
      title: t("experience"),
      keywords: ["RES", "BEC"],
      icon: "icon_set_1_icon-4",
    },
    {
      key: "food",
      title: t("food"),
      keywords: ["NMK", "BEV", "NVW"],
      icon: "icon_set_1_icon-44",
    },
    {
      key: "cultureAndShopping",
      title: t("cultureAndShopping"),
      keywords: ["OAT", "SPO", "ETM"],
      icon: "icon_set_1_icon-37",
    },
  ];

  const BUILT_IN_SERVICE = [
    { key: "rl_inc_meal", title: t("tourIncMeal") },
    { key: "rl_inc_snack", title: t("tourIncSnack") },
    { key: "rl_inc_drink", title: t("tourIncDrink") },
    { key: "rl_inc_ticket", title: t("tourIncTicket") },
    { key: "rl_inc_vehicle", title: t("tourIncVehicle") },
  ];

  (() => {
    let _country_list = util.changedMapToArray(codeList.codeCountry);
    if (_country_list.result) {
      country_list = _country_list.returnArray;
    }
  })();

  useEffect(() => {
    let filters = {};
    if (props.location.state) {
      filters.rl_addr_country = props.location.state.rl_addr_country[0]?.id;
      filters.SEARCH_DATE = props.location.state.SEARCH_DATE
        ? util.getDateToFormat(props.location.state.SEARCH_DATE, "YYYY-MM-DD")
        : "";
      filters.rl_guest_max = Number(props.location.state.rl_guest_max);

      $controller.rl_addr_country.current.state.values =
        props.location.state.rl_addr_country;
      $controller.SEARCH_DATE.current.value = util.getDateToFormat(
        props.location.state.SEARCH_DATE,
        "YYYY-MM-DD"
      );
      $controller.rl_guest_max.current.value = Number(
        props.location.state.rl_guest_max
      );
      window.history.replaceState(null, "");
    }
    changedFilters(filters);

    return () => {
      country_list = [];
      travel_list = [];

      totalCount = 100;
      totalPageCount = 1;
      rowsPerPage = 12;
      page = 0;
      filters = {
        SUBJECT: "",
        SEARCH_DATE: "", //시작일
        sl_edate: "", //종료일
        rl_addr_country: "", //국가
        gl_lang_main: "", //사용언어
        rl_price_base: "", //금액 검색
        rl_rating: 0, // 별점 검색

        //기본제공
        rl_inc_meal: "", // 식사제공
        rl_inc_snack: "", //간식제공
        rl_inc_drink: "", //음료제공
        rl_inc_ticket: "", //티켓제공
        rl_inc_vehicle: "", //탈 것 제공

        rl_guest_max: 1, //인원수 검색
        rl_can_child: "", //영유아 동반
      };
    };
  }, []);

  useEffect(() => {
    SELECT_TOUR_LIST(filters);
  }, [sortModel]);

  const changedCategory = (keywords) => {
    let subjects = "";
    if (keywords.length > 0) {
      subjects = keywords.join("|");
    }
    filters["SUBJECT"] = subjects;
    SELECT_TOUR_LIST();
  };

  const changedFilters = (data) => {
    filters = {
      ...filters,
      ...data,
    };
    SELECT_TOUR_LIST();
  };

  //경로 서비스 데이터 받기
  const SELECT_TOUR_LIST = () => {
    let msgID = util.makeUUIDv4();

    let socketMsg = {
      file: "/tour/JS_tour_detail_list_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      rl_confirm_admin: "Y",
      rl_use_yn: "Y",
      rl_del_yn: "N",
      gl_confirm_admin: "Y",
      ...filters,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let dataList = newData["returnData"];
          totalCount = parseInt(newData.pageSize, 10);
          totalPageCount =
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
            (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0);
          travel_list = dataList;
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...travel_list];
  }, [travel_list]);

  //정렬기준 변경
  const handleSortModel = (sort) => {
    setSortModel([...sort]);
  };

  //경로 서비스 랜더
  const RouteItemRender = useCallback(({ item }) => {
    return (
      <RouteItem
        rl_pic_main={item.rl_pic_main}
        rl_price_base={item.rl_price_base}
        rl_area_cd={item.rl_area_cd}
        rl_idx={item.rl_idx}
        rl_name={item.rl_name}
        rl_title={item.rl_title}
        rl_rating={item.rl_rating}
        rl_rating_cnt={item.rl_rating_cnt}
        rl_guest_min={item.rl_guest_min}
        rl_over_pay_people={item.rl_over_pay_people}
        rl_desc={item.rl_desc}
      />
    );
  }, []);

  //페이지네이션 이동
  const handleChangePage = (event, newPage) => {
    page = newPage;
    window.scrollTo({ top: 0, behavior: "auto" });
    SELECT_TOUR_LIST();
  };

  const resetFilters = () => {
    for (let key in filters) {
      filters[key] = "";
      if ($controller.hasOwnProperty(key) && $controller[key].current) {
        let current = $controller[key].current;
        if (current.hasOwnProperty("clearAll")) {
          current.clearAll();
        } else {
          if (current.type === "checkbox") {
            current.checked = false;
          } else {
            filters[key] = filters_default[key] || "";
            current.value = filters_default[key] || "";
          }
        }
      }
    }

    if (params.hasOwnProperty("travelType")) {
      filters.SUBJECT = TRAVEL_TYPE.find(
        (item) => item.key === params.travelType
      ).keywords.join("|");
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    SELECT_TOUR_LIST();
  };

  const createdRatingHTML = () => {
    let ratings = [];
    for (let i = 0; i < 5; i++) {
      ratings.push(
        <i
          key={i}
          className={`${
            filters.rl_rating > i ? "icon-star voted" : "icon-star-empty"
          }  cursor_pointer`}
          onClick={() => {
            changedFilters({ rl_rating: i + 1 });
          }}
        />
      );
    }
    return ratings;
  };

  return (
    <div className="container margin_60">
      <div className="row">
        <aside className="col-lg-3">
          <p className="h4">{t("tourDetailListCategoryTitle")}</p>
          <div className="box_style_cat">
            <ul id="cat_nav">
              {TRAVEL_TYPE.map((item) => {
                const _key = item.key === "all" ? "" : item.key;
                let activeClass = "";
                if (
                  (params.hasOwnProperty("travelType") &&
                    params.travelType === item.key) ||
                  (!params.hasOwnProperty("travelType") && item.key === "all")
                ) {
                  activeClass = "active";
                }

                return (
                  <li key={item.key}>
                    <Link
                      to={`/TourDetailList/${_key}`}
                      className={activeClass}
                      onClick={() => changedCategory(item.keywords)}
                    >
                      <i className={item.icon} />
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>

          <p className="h4">{t("detailedSearch")}</p>
          <div id="filters_col">
            <a
              data-toggle="collapse"
              href="#collapseFilters"
              aria-expanded="false"
              aria-controls="collapseFilters"
              id="filters_col_bt"
              className="d-flex flex-row justify-content-between align-items-center"
              onClick={() => setIsFilterCollapse((prevState) => !prevState)}
            >
              <div>
                <i className="icon_set_1_icon-65" />
                {t("filter")}
              </div>
              {isFilterCollapse ? (
                <i className="fa-solid fa-chevron-down mr-2" />
              ) : (
                <i className="fa-solid fa-chevron-up mr-2" />
              )}
            </a>
            <div className="collapse show" id="collapseFilters">
              <div className="filter_type">
                <h6>{t("tourDetailListFilterDate")}</h6>
                <input
                  type="date"
                  className="bg-light w-100 py-2 px-3 rounded mb-2"
                  min={util.todayDate("YYYY-MM-DD")}
                  defaultValue={util.todayDate("YYYY-MM-DD")}
                  onChange={(e) =>
                    changedFilters({ SEARCH_DATE: e.target.value })
                  }
                  ref={$controller.SEARCH_DATE}
                />
              </div>{" "}
              <div className="filter_type">
                <h6>{t("tourDetailListFilterLocation")}</h6>
                <CountrySelect
                  selectRef={$controller.rl_addr_country}
                  placeholder={t("tourDetailListFilterLocationPlaceholder")}
                  className="border-0 bg-light"
                  callback={(values) => {
                    let value = values[0]?.id;
                    changedFilters({ rl_addr_country: value || "" });
                  }}
                />
              </div>
              <div className="filter_type">
                <h6>{t("tourDetailListFilterLanguage")}</h6>
                <LanguageSelect
                  selectRef={$controller.gl_lang_main}
                  placeholder={t("tourDetailListFilterLanguagePlaceholder")}
                  className="border-0 bg-light"
                  callback={(values) => {
                    let value = values[0]?.id;
                    changedFilters({ gl_lang_main: value || "" });
                  }}
                />
              </div>
              <div className="filter_type">
                <h6>{t("tourDetailListFilterCost")}</h6>
                <div className="d-flex flex-row align-items-center">
                  <input
                    type="number"
                    className="bg-light flex-fill py-2 px-3 rounded"
                    placeholder={t("tourDetailListFilterCostPlaceholder")}
                    onBlur={(e) => {
                      changedFilters({ rl_price_base: e.target.value });
                    }}
                    ref={$controller.rl_price_base}
                  />
                  <p className="text-right px-2">
                    {t("tourDetailListFilterCostNote")}
                  </p>
                </div>
              </div>
              <div className="filter_type">
                <h6>{t("tourDetailListFilterRating")}</h6>
                <div className="d-flex flex-row align-items-center">
                  <div className="rating h1 flex-fill">
                    {createdRatingHTML()}
                  </div>
                  <p className="text-right px-2">
                    {t("tourDetailListFilterRatingNote")}
                  </p>
                </div>
              </div>
              <div className="filter_type">
                <h6>{t("tourDetailListFilterInt")}</h6>
                {BUILT_IN_SERVICE.map((item) => {
                  return (
                    <label
                      key={item.key}
                      htmlFor={item.key}
                      className={`d-inline-block rounded-pill border py-1 px-2 mr-2 cursor_pointer ${
                        filters[item.key] === "Y"
                          ? "bg-buddible text-white"
                          : ""
                      }`}
                    >
                      <input
                        id={item.key}
                        type="checkbox"
                        className="mr-2 d-none"
                        ref={$controller[item.key]}
                        onChange={(e) => {
                          changedFilters({
                            [item.key]: e.target.checked ? "Y" : "",
                          });
                        }}
                      />
                      {filters[item.key] === "Y" && (
                        <i className="fa-solid fa-check mr-2" />
                      )}
                      {item.title}
                    </label>
                  );
                })}
              </div>
              <div className="filter_type">
                <h6>{t("tourDetailListFilterPersonnel")}</h6>
                <div className="d-flex flex-row justify-content-between py-2">
                  <label>{t("tourDetailListFilterPersonnelTotal")}</label>
                  <div className="numbers-row">
                    <input
                      type="text"
                      readOnly={true}
                      min={1}
                      value={filters.rl_guest_max}
                      className="qty2 form-control bg-white"
                      ref={$controller.rl_guest_max}
                    />
                    <div
                      className="inc button_inc"
                      onClick={() => {
                        changedFilters({
                          rl_guest_max: filters.rl_guest_max + 1,
                        });
                      }}
                    >
                      +
                    </div>
                    <div
                      className="dec button_inc"
                      onClick={() => {
                        if (filters.rl_guest_max > 1) {
                          changedFilters({
                            rl_guest_max: filters.rl_guest_max - 1,
                          });
                        }
                      }}
                    >
                      -
                    </div>
                  </div>
                </div>
                <label className="d-block d-flex flex-row align-items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    ref={$controller.rl_can_child}
                    onChange={(e) => {
                      changedFilters({
                        rl_can_child: e.target.checked ? "Y" : "",
                      });
                    }}
                  />{" "}
                  {t("tourDetailListFilterPersonnelTeenager")}
                </label>
              </div>
              <button
                className="btn_1 btn_full bg-secondary mt-1"
                onClick={resetFilters}
              >
                {t("searchReset")}
              </button>
            </div>
          </div>
          <div className="box_style_2 d-none d-sm-block">
            <i className="icon_set_1_icon-57" />
            <h4>
              <span>{t("customerCenter")}</span>
            </h4>
            <a href="tel://032-747-0211" className="phone">
              032-747-0211
            </a>
            <small>
              {t("sun")}-{t("fri")} 9.00am - 7.30pm
            </small>
          </div>
        </aside>

        <div className="col-lg-9">
          <div className="row p-0 m-0">
            <div className="col-12 p-0 px-md-2">
              <div className="d-flex flex-column  flex-sm-row justify-content-between">
                <p className="h5 mb-2 mb-md-0 font-weight-bold">
                  <span className="text-buddible">{totalCount}</span>
                  {t("tourDetailListCntService")}
                </p>
                <div className="d-flex flex-row justify-content-end flex-wrap">
                  {SORT_MODEL.map((item, index) => {
                    let key = item.key + "_" + item.sort;
                    let isSelected =
                      sortModel[0].field === item.key &&
                      sortModel[0].dir === item.sort;

                    return (
                      <div
                        key={key}
                        className={`px-3 py-1 mb-1 mr-1 ${
                          isSelected ? "text-buddible font-weight-bold" : ""
                        }  cursor_pointer border rounded-pill bg-white`}
                        onClick={() =>
                          handleSortModel([
                            {
                              field: item.key,
                              dir: item.sort,
                            },
                          ])
                        }
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row p-0 m-0">
            {data.length > 0 &&
              data.map((item, index) => {
                return (
                  <RouteItemRender
                    key={`${item.rl_idx}_${index}`}
                    item={item}
                    index={index}
                  />
                );
              })}
            {data.length === 0 && (
              <div className="col-12 mt-3 p-5 rounded bg-light child-center">
                <p className="h5 text-secondary font-weight-bold mb-0">
                  조건에 맞는 서비스가 존재하지 않습니다.
                </p>
              </div>
            )}
          </div>
          <div className="mb-3 mt-3 pr-3">
            <Pagination
              style={{ justifyContent: "flex-end" }}
              className={classes.pagination}
              color="primary"
              count={totalPageCount}
              page={page + 1}
              allin={"right"}
              onChange={(event, value) => {
                handleChangePage(event, value - 1);
              }}
              showFirstButton
              showLastButton
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const RouteItem = React.memo(
  ({
    rl_pic_main,
    rl_price_base,
    rl_area_cd,
    rl_idx,
    rl_name,
    rl_title,
    rl_rating,
    rl_rating_cnt,
    rl_guest_min,
    rl_over_pay_people,
    rl_desc,
  }) => {
    const { t } = useTranslation();
    const picUrl = util.changedResourceUrl(rl_pic_main);
    return (
      <div className="col-12 col-md-6 col-xl-4 m-0 px-2 py-1">
        <Link className="text-dark" to={`/TourDetail/${rl_idx}`}>
          <div className="tour_container shadow-none overflow-hidden">
            <div className="img_container_2 position-relative rounded-xl overflow-hidden">
              <div
                className="w-100"
                style={{
                  height: 300,
                  background: `url(${picUrl}) 50% 50%/cover no-repeat`,
                }}
              />
              <div className="short_info">
                <i className="icon_set_1_icon-44" />
                {rl_area_cd}
                <span className="price">
                  <sup>&#8361;</sup> {util.addCommas(rl_price_base)}
                </span>
              </div>
            </div>

            <div className="tour_title p-1 mt-2">
              <div className="row m-0 p-0">
                <div className="col-8 m-0 p-0">
                  <p className="h6 font-weight-bold ellipsis_1">{rl_title}</p>
                </div>
                <div className="col-4 m-0 p-0 text-right">
                  <i className="icon-star voted text-warning" /> {rl_rating}
                </div>
              </div>
              <span>{rl_name ? rl_name : "-"}</span>
              <div className="rating">
                <i
                  className={
                    rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <i
                  className={
                    rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                  }
                />
                <small>({rl_rating_cnt})</small>
              </div>
            </div>
            <div className="tour_desc p-1">
              <p className="ellipsis_2">{rl_desc}</p>
              <p className="font-weight-bold">
                {t("base")} {rl_guest_min ? rl_guest_min : "1"} {t("person")}{" "}
                &#8361;
                {util.addCommas(rl_price_base)} / {t("addedPerson")} &#8361;
                {util.addCommas(rl_over_pay_people)}
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  }
);
