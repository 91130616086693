import React, { useEffect, useState } from "react";
import CodeList from "../../../lib/CodeList";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import { useTranslation } from "react-i18next";

const codeList = new CodeList();
const socket = new BuddibleSocket();
const util = new Utilities();

export default function GuideDetailGuideInfo(props) {
  const { guideData } = props;
  const { t } = useTranslation();
  const [language, setLanguage] = useState([]);
  const [position, setPosition] = useState({
    position: "unset",
    top: "unset",
  });
  const [guideInfoWidth, setGuideInfoWidth] = useState("auto");
  const [isProfileView, setIsProfileView] = useState(false);
  const [isCertificationView, setIsCertificationView] = useState(false);
  const [isLanguageView, setIsLanguageView] = useState(false);

  useEffect(() => {
    handleGuideInfoSize();
    languageSet();
    window.addEventListener("resize", handleGuideInfoSize);
    window.addEventListener("scroll", handleGuideInfoPosition);
    return () => {
      window.removeEventListener("resize", handleGuideInfoSize);
      window.removeEventListener("scroll", handleGuideInfoPosition);
    };
  }, []);

  useEffect(() => {
    handleGuideInfoPosition();
  }, [isCertificationView, isLanguageView]);

  //가이드 정보 width
  const handleGuideInfoSize = () => {
    if (window.innerWidth >= 992) {
      let setWidth = document.getElementById("sidebar").clientWidth - 30;
      setGuideInfoWidth(setWidth);
    } else {
      setGuideInfoWidth("auto");
    }
  };

  //가이드 정보 위치
  const handleGuideInfoPosition = () => {
    let scrollValue = window.scrollY;
    if (!document.getElementById("guideDetail")) return;
    let wrapHeight = document.getElementById("guideDetail").offsetHeight;

    if (window.innerWidth > 991) {
      if (scrollValue < 600) {
        setPosition({
          position: "static",
          bottom: "unset",
        });
      }

      if (scrollValue > 600) {
        setPosition({
          position: "fixed",
          top: 100,
        });
      }

      if (scrollValue > wrapHeight - 200) {
        setPosition({
          position: "absolute",
          bottom: 0,
        });
      }
    } else {
      setPosition({
        position: "unset",
        bottom: "unset",
      });
    }
  };

  // 사용언어
  const languageSet = () => {
    console.log(guideData);

    if (guideData.gl_lang_main) {
      setLanguage((language) => [...language, [guideData.gl_lang_main, "5"]]);
    }
    let langList = guideData.gl_lang_sub;
    if (langList) {
      langList = langList.split("|");

      for (let i = 0; i < langList.length; i++) {
        let lang = langList[i].split(",");

        setLanguage((language) => [...language, lang]);
      }
    }
  };

  //todo 채팅페이지 구현 후 이동
  const contactGuide = () => {
    codeList.Modal.current.confirm(t("confirmInquiryMsg"), (ret) => {
      console.log("채팅페이지 이동 구현");
    });
  };

  console.log(guideData);

  return (
    <>
      <aside className="col-lg-4 order-first order-lg-last" id="sidebar">
        <div className="theiaStickySidebar">
          <div
            className="box_style_1 expose rounded"
            id="booking_box"
            style={{
              // ...position,
              width: guideInfoWidth,
            }}
          >
            <div className="row py-3 d-flex flex-row justify-content-center">
              <div
                className="summaryGuideImg border d-flex flex-row justify-content-center align-items-center"
                style={{
                  background: `url(${util.changedResourceUrl(
                    guideData.gl_pic
                  )}) 50% 50%/cover no-repeat`,
                  height: "250px",
                  width: "250px",
                  borderRadius: "50%",
                  backgroundSize: "cover",
                  cursor: "pointer",
                }}
                onClick={() => setIsProfileView(true)}
              >
                {guideData.gl_pic === "" && <span>{t("image")}</span>}
              </div>
            </div>
            <div className="row d-flex flex-row justify-content-center mb-2">
              <p className="h5">
                <i className="fas fa-flag mr-2" />
                {guideData.gl_name} {t("guide")}
              </p>
            </div>
            <div className="border-bottom py-3 my-2">
              <h4>{guideData.gl_title}</h4>
              <p>{guideData.gl_desc}</p>
            </div>
            {/*<div className="py-2 my-2 border-bottom">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <h4 className="text-center">
                  <i
                    className="xi-shield-checked"
                    style={{ color: "#0095f5" }}
                  />{" "}
                  {t("guideDetailCertificationCompletedItems")}
                </h4>
                <span
                  className="text-secondary cursor_pointer"
                  onClick={() => {
                    setIsCertificationView(!isCertificationView);
                  }}
                >
                  {!isCertificationView ? t("open") : t("close")}
                </span>
              </div>
              <div
                className={`container ${
                  isCertificationView ? "d-block" : "d-none"
                }`}
              >
                <div
                  className={`row py-3 border-bottom ${
                    guideData.gl_university_name ? "d-block" : "d-none"
                  }`}
                >
                  <div className="col">
                    <i className="xi-school mr-2" />
                    {t("university")}
                  </div>
                  <div className="col text-right text-truncate">
                    {guideData.gl_university_name}
                  </div>
                </div>
                <div
                  className={`row py-3 border-bottom ${
                    guideData.gl_university_year ? "d-block" : "d-none"
                  }`}
                >
                  <div className="col">
                    <i className="xi-school mr-2" />
                    {t("admission")}
                  </div>
                  <div className="col text-right text-truncate">
                    {guideData.gl_university_year}
                  </div>
                </div>
                <div
                  className={`row py-3 ${
                    guideData.gl_country_cd ? "d-block" : "d-none"
                  }`}
                >
                  <div className="col">
                    <i className="xi-globus mr-2" />
                    {t("nationality")}
                  </div>
                  <div className="col text-right text-truncate">
                    {guideData.gl_country_cd}
                  </div>
                </div>
              </div>
            </div>*/}
            {language.length > 0 && (
              <div className="py-2 my-3 border-bottom">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <h4 className="text-center">
                    <i
                      className="fa-solid fa-earth-africa mr-1"
                      style={{ color: "#0095f5" }}
                    />
                    {t("useLanguage")}
                  </h4>
                  <span
                    className="text-secondary cursor_pointer"
                    onClick={() => {
                      setIsLanguageView(!isLanguageView);
                    }}
                  >
                    {!isLanguageView ? t("open") : t("close")}
                  </span>
                </div>
                <div
                  className={`overflow_scroll_y container ${
                    isLanguageView ? "d-block" : "d-none"
                  }`}
                  style={{ maxHeight: 200 }}
                >
                  {language.map((lang, index) => {
                    let langName = "";
                    if (codeList.codeLanguage.get(lang[0])) {
                      langName = codeList.codeLanguage.get(lang[0]).name_ko;
                    }
                    let langKey = lang[0];
                    let langLevel = lang[1];
                    return (
                      <div
                        key={`${langKey}_${index}`}
                        className="row py-3 border-bottom"
                      >
                        <div className="col">{langName}</div>
                        <div className="col text-right">
                          Lv. {langLevel}{" "}
                          <i className="icon-star voted text-warning" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {/*<button
              className="m-0 btn_1 btn_full bg-buddible"
              onClick={contactGuide}
            >
              {t("inquiry")}
            </button>*/}
          </div>
        </div>
      </aside>
      <GuideBigProfile
        guideData={guideData}
        isProfileView={isProfileView}
        setIsProfileView={setIsProfileView}
      />
    </>
  );
}

//가이드 프로필 이미지
function GuideBigProfile(props) {
  const { guideData, isProfileView, setIsProfileView } = props;
  return (
    <div
      className={`guideDetailBigImg ${
        isProfileView && guideData.gl_pic ? "d-flex" : "d-none"
      } flex-column justify-content-center align-items-center`}
      style={{
        background: "rgba(50,50,50,0.8)",
        position: "fixed",
        height: "100vh",
        width: "100vw",
        top: 0,
        left: 0,
        zIndex: 999999999999999999,
      }}
    >
      <div>
        <div className="w-100 text-right mb-2">
          <span
            className="h2 text-white cursor_pointer"
            onClick={() => setIsProfileView(false)}
          >
            &times;
          </span>
        </div>
        <img
          src={codeList.publicVar.imgServerUrl + guideData.gl_pic}
          alt={guideData.gl_pic}
        />
      </div>
    </div>
  );
}
