import React, { useCallback, useEffect, useRef } from "react";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { MsgIDList } from "../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const DateSplitter = ({ date }) => {
  return (
    <div className="d-flex flex-row align-items-center w-100 mb-3">
      <div className="border-bottom flex-grow-1" />
      <div className="px-2 font-size-09">{date}</div>
      <div className="border-bottom flex-grow-1" />
    </div>
  );
};

const SenderUserImageRender = React.memo(({ item, sameBefore }) => {
  if (sameBefore) return <div className="square-40" />;

  return (
    <img
      src={util.changedResourceUrl(item.crt_sender_avata_pic)}
      alt={item.crt_sender_name}
      className="square-40 avata_pic rounded-circle border"
    />
  );
});
const SenderUserNameRender = React.memo(({ item, sameBefore }) => {
  if (sameBefore) return <></>;
  return (
    <p className="font-weight-bold text-secondary">{item.crt_sender_name}</p>
  );
});

const BasicMessageRender = React.memo(({ item, msgAlign, msgWrapClass }) => {
  return <>{item.crt_msg}</>;
});

const FileMessageRender = React.memo(({ item }) => {
  const filesList = item.crt_file.split("|");
  const files = filesList.filter(
    (f) => !util.checkFileType(f).isImage && !util.checkFileType(f).isVideo
  );
  const images = filesList.filter((f) => util.checkFileType(f).isImage);
  const videos = filesList.filter((f) => util.checkFileType(f).isVideo);

  const FileItem = ({ file }) => {
    const fileSplit = file.split("?");
    if (fileSplit.length > 2) return;

    const url = fileSplit[0];
    const name = fileSplit[1];
    const fileContent = util.checkFileType(name);

    const clickFile = () => {
      if (fileContent.isImage) {
        window.open(url, "_blank");
        return;
      }
      codeList.Modal.current.confirm(
        `[${name}]을 다운로드 하시겠습니까?`,
        (ret) => {
          if (ret) {
            util.downloadAs(url, name);
          }
        }
      );
    };

    if (fileContent.isVideo) {
      return (
        <video width="250" height="240" controls style={{ zIndex: 0 }}>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    }

    return (
      <div className="p-1 cursor_pointer" onClick={clickFile}>
        {!fileContent.isImage && (
          <div className="bg-white p-2 d-flex flex-row align-items-center rounded">
            <span className="text-buddible h4 mb-0">
              <i className={fileContent.icon} />
            </span>
            <span className="ml-2">{name}</span>
          </div>
        )}
        {fileContent.isImage && (
          <img src={url} alt={name} className="square-100 rounded avata_pic" />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex flex-row flex-wrap">
        {images.map((file) => {
          return <FileItem key={file} file={file} />;
        })}
      </div>
      {files.map((file) => {
        return <FileItem key={file} file={file} />;
      })}

      {videos.map((file, index) => {
        return <FileItem key={file} file={file} />;
      })}
    </div>
  );
});

const SystemMessageRender = React.memo((props) => {
  const { item } = props;
  return (
    <div className="w-100 border rounded p-3 bg-light font-weight-bold text-center mb-3">
      {item.crt_msg}
    </div>
  );
});

const _mClassName = "ChatRoomMsgList";
let beforeScrollHeight = 0;
export default function ChatRoomMsgList(props) {
  const { _loginInfo, item, msgList } = props;

  // 이전 메세지 불러오기
  const requestBeforeMessage = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const wrap = document.querySelector("#msgWrap");
        beforeScrollHeight = wrap.scrollHeight;
        socket.fireLocalEventListener(
          MsgIDList.EVENT_CHAT_REQUEST_BEFORE_MESSAGE,
          _mClassName,
          item,
          () => {}
        );
      }
    });
  };

  (() => {})();

  useEffect(() => {
    // socket.addLocalEventListener(
    //   MsgIDList.EVENT_CHAT_MESSAGE_SEND,
    //   _mClassName,
    //   (b, n) => {
    //     if (!n) return;
    //     if (_loginInfo.u_uid === n.crt_sender_uid) {
    //       const wrap = document.querySelector("#msgWrap");
    //       console.log("EVENT_CHAT_MESSAGE_SEND", wrap.scrollHeight);
    //       wrap.scrollTo({
    //         left: 0,
    //         top: wrap.scrollHeight,
    //         behavior: "smooth",
    //       });
    //     }
    //   }
    // );

    const wrap = document.querySelector("#msgWrap");
    if (wrap.scrollHeight < beforeScrollHeight) {
      beforeScrollHeight = 0;
    }
    wrap.scrollTop = wrap.scrollHeight - beforeScrollHeight;

    if (msgList.length >= 50) {
      // 타겟 요소 viewport 위치 감지
      const observer = new IntersectionObserver(requestBeforeMessage, {
        threshold: 1,
      });
      const target = document.querySelector(".observer_target");
      observer.observe(target);
    }

    // return () => {
    //   socket.removeLocalEventListener(
    //     MsgIDList.EVENT_CHAT_MESSAGE_SEND,
    //     _mClassName
    //   );
    // };
  }, [msgList]);

  const ChatMessageRender = (props) => {
    const { item, index } = props;

    let sameBefore = false;
    if (index > 0) {
      if (item.crt_sender_uid === msgList[index - 1].crt_sender_uid) {
        sameBefore = true;
      }
    }

    let sameDate = true;
    if (
      util.getDateToFormat(item.crt_create_date, "YYYY-MM-DD") !==
      util.getDateToFormat(msgList[index - 1]?.crt_create_date, "YYYY-MM-DD")
    ) {
      sameDate = false;
    }

    if (props.item.crt_type === "9") {
      return (
        <div className={index === 0 ? "observer_target" : ""}>
          {!sameDate && (
            <DateSplitter
              date={util.getDateToFormat(item.crt_create_date, "YYYY-MM-DD")}
            />
          )}
          <SystemMessageRender {...props} />
        </div>
      );
    }

    const msgAlign = item.isSender ? "end" : "start";
    const msgWrapClass = item.isSender
      ? "chat-msg-sender"
      : "chat-msg-receiver";

    let sendTime = "";
    let nextTime = "";
    let isSameTime = false;

    const date = new Date(item.crt_create_date);
    let hour = date.getHours();
    let min = date.getMinutes();
    sendTime = `${hour > 12 ? `오후${hour - 12}` : `오전${hour}`}:${min}`;

    const nextDate = new Date(msgList[index + 1]?.crt_create_date);
    hour = nextDate.getHours();
    min = nextDate.getMinutes();
    nextTime = `${hour > 12 ? `오후${hour - 12}` : `오전${hour}`}:${min}`;

    isSameTime =
      sendTime === nextTime &&
      item.crt_sender_uid === msgList[index + 1]?.crt_sender_uid;

    const MessageItem = React.memo((props) => {
      if (props.item.crt_type === "1") {
        return <BasicMessageRender {...props} />;
      }
      if (props.item.crt_type === "2") {
        return <FileMessageRender {...props} />;
      }

      return <></>;
    });

    return (
      <div className={index === 0 ? "observer_target" : ""}>
        {!sameDate && (
          <DateSplitter
            date={util.getDateToFormat(item.crt_create_date, "YYYY-MM-DD")}
          />
        )}

        <div className={`d-flex flex-row ${isSameTime ? "mb-1" : "mb-2"}`}>
          {!item.isSender && (
            <SenderUserImageRender item={item} sameBefore={sameBefore} />
          )}

          <div className="px-2 w-100">
            {!item.isSender && (
              <SenderUserNameRender item={item} sameBefore={sameBefore} />
            )}
            <div
              className={`w-100 mt-1 d-flex flex-row justify-content-${msgAlign} align-items-end`}
            >
              <div
                className={`p-2 chat-msg ${msgWrapClass} ${
                  item.isSender ? "order-last" : ""
                }`}
              >
                <MessageItem
                  item={item}
                  msgAlign={msgAlign}
                  msgWrapClass={msgWrapClass}
                />
              </div>
              {!isSameTime && <p className="px-2 font-size-06">{sendTime}</p>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div id="msgWrap" className="chat-list-center p-3 overflow_scroll_y">
      {msgList.map((item, index) => {
        return (
          <ChatMessageRender key={item.crt_msg_uid} item={item} index={index} />
        );
      })}
    </div>
  );
}
