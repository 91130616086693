import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Utilities from "../../../lib/Utilities";

const util = new Utilities();

export default function CommunityForum({ dataList }) {
  const ItemRender = useCallback((props) => <Item {...props} />, []);

  if (dataList.length === 0) return <></>;

  return (
    <div className="row m-0 p-0">
      {dataList.map((data, index) => {
        return <ItemRender key={data.cm_idx} data={data} index={index} />;
      })}
    </div>
  );
}

const Item = React.memo(({ data, index }) => {
  const {
    cm_idx,
    cm_look_cnt,
    cm_main_pic,
    cm_title,
    cm_sub_title,
    cm_update_date,
    cm_reply_cnt,
  } = data;
  const { t } = useTranslation();

  return (
    <div className="col-6 col-lg-3 m-0 p-0 pr-2 pb-2">
      <Link to={`/CommunityDetail/${cm_idx}`} className="text-dark">
        <div className="rounded-xl bg-light p-3 w-100">
          <h6 className="mb-1 font-weight-bold ellipsis_1">{cm_title}</h6>
          <p className="mb-2 ellipsis_3">
            {cm_sub_title || (
              <>
                <br />
                <br />
                <br />
              </>
            )}
          </p>
          <div className="d-flex flex-row justify-content-between font-size-08 font-weight-bold text-secondary">
            <p>
              <span className="mr-2">
                {t("view")} {util.addCommas(cm_look_cnt)}
              </span>
              {t("reply")} {util.addCommas(cm_reply_cnt)}
            </p>
            <p>{util.getDateToFormat(cm_update_date, "YYYY-MM-DD")}</p>
          </div>
        </div>
      </Link>
    </div>
  );
});
