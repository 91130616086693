import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TourNewToolbar(props) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
      <h3 className="font-weight-bold m-0">{t("tourListNewTitle")}</h3>
      <Link
        className="bg-white py-1 px-2 rounded-pill cursor_pointer border text-dark"
        to="/TourDetailList"
      >
        {t("detailedSearch")}
      </Link>
    </div>
  );
}
