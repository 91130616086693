import React, { useState, useEffect, useLayoutEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import CommunityCarousel from "./components/CommunityCarousel";
import CommunityNotice from "./components/CommunityNotice";
import CommunityNews from "./components/CommunityNews";
import CommunityGuideTip from "./components/CommunityGuideTip";
import CommunityGuestTip from "./components/CommunityGuestTip";
import CommunityForum from "./components/CommunityForum";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "CommunityMain";

const COMMUNITY_TYPES = [
  {
    key: "NOTICE",

    dataList: [],
    filters: { cm_type: "NOTICE", take: 4, skip: 0 },
    component: (params) => <CommunityNotice {...params} />,
  },
  {
    key: "GUIDE",

    dataList: [],
    filters: { cm_type: "GUIDE", take: 4, skip: 0 },
    component: (params) => <CommunityGuideTip {...params} />,
  },
  {
    key: "GUEST",

    dataList: [],
    filters: { cm_type: "GUEST", take: 4, skip: 0 },
    component: (params) => <CommunityGuestTip {...params} />,
  },
  {
    key: "NEWS",

    dataList: [],
    filters: { cm_type: "NEWS", take: 3, skip: 0 },
    component: (params) => <CommunityNews {...params} />,
  },
  {
    key: "FORUM",

    dataList: [],
    filters: { cm_type: "FORUM", take: 8, skip: 0 },
    component: (params) => <CommunityForum {...params} />,
  },
];

let loginInfo = null;
export default function CommunityMain(props) {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(true);
  const history = useHistory();

  const COMMUNITY_TITLES = {
    NOTICE: t("notice"),
    GUIDE: t("guideTip"),
    GUEST: t("guestTip"),
    NEWS: t("news"),
    FORUM: t("communityForum"),
  };

  useLayoutEffect(() => {}, []);

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) loginInfo = userData;

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) loginInfo = n;
      }
    );

    for (let i = 0; i < COMMUNITY_TYPES.length; i++) {
      const section = COMMUNITY_TYPES[i];
      requestCommunity(section.filters, (newData) => {
        COMMUNITY_TYPES[i].dataList = newData["returnData"];
        setIsNeedUpdate((before) => !before);
      });
    }

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  // 커뮤니티 게시글 받아오기
  const requestCommunity = (filters, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/community/JS_communityMainSelect.php",
      msgID: msgID,
      cm_use_yn: "Y",
      cm_del_yn: "N",
      ...filters,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const clickWrite = () => {
    if (loginInfo === null) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }
    history.push("/CommunityWrite");
  };

  return (
    <>
      <Nav />
      <main className="bg-white position-relative">
        <CommunityCarousel />
        <div className="container margin_60">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              {COMMUNITY_TYPES.map((type) => {
                return (
                  <div className="container mb-5" key={type.key}>
                    <div className="d-flex flex-row justify-content-between mb-3 mx-2">
                      <h3 className="font-weight-bold">
                        {COMMUNITY_TITLES[type.key]}
                      </h3>
                      <Link
                        to={`/CommunityList/${type.key}`}
                        className="cursor_pointer"
                      >
                        {COMMUNITY_TITLES[type.key]} {t("showMore")}{" "}
                        <i className="fa-solid fa-chevron-right" />
                      </Link>
                    </div>
                    {type.dataList.length > 0 ? (
                      type.component({ dataList: type.dataList })
                    ) : (
                      <div className="p-5 bg-light d-flex flex-row justify-content-center align-items-center mx-2">
                        <p>{t("emptyServiceNote")}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <button
          className="position-fixed btn_1 "
          style={{ bottom: 20, right: 20 }}
          onClick={clickWrite}
        >
          <i className="fa-solid fa-plus mr-2" />
          {t("write")}
        </button>
      </main>
      <Footer />
    </>
  );
}
