import React from "react";
import Nav from "../common/Nav";
import TourDetailListCarousel from "./component/TourDetailListCarousel";
import TourDetailListCategory from "./component/TourDetailListCategory";
import TourDetailListContents from "./component/TourDetailListContents";

import Footer from "../common/Footer";

export default function TourDetailList(props) {
  return (
    <>
      <Nav />
      <main className="bg-white">
        <TourDetailListCarousel {...props} />
        <TourDetailListCategory {...props} />
        <TourDetailListContents {...props} />
      </main>
      <Footer />
    </>
  );
}
