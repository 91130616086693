import React, { useEffect, useState } from "react";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const util = new Utilities();
const codeList = new CodeList();

export default function TourDetailReview(props) {
  const { tourData, reviewData } = props;
  const { t } = useTranslation();
  const [isModalView, setIsModalView] = useState(false);

  const handleModalView = () => {
    setIsModalView(!isModalView);
  };

  const createRatings = (rating) => {
    let r = Number(rating);
    let ratings = [];
    for (let i = 1; i < 6; i++) {
      ratings.push(
        <i
          key={i}
          className={`${i <= r ? "icon-star voted" : "icon-star-empty"} ml-0`}
        />
      );
    }
    return ratings;
  };

  return (
    <>
      <div className="row py-3">
        <div className="col-12 d-flex flex-row justify-content-between">
          <p className="h5 font-weight-bold">
            <i className="icon-star voted text-warning" />{" "}
            <span className={tourData.rl_rating ? "d-inline-block" : "d-none"}>
              {tourData.rl_rating}
              {t("score")} · {t("review")} {tourData.rl_rating_cnt} {t("count")}
            </span>
            <span className={!tourData.rl_rating ? "d-inline-block" : "d-none"}>
              NEW
            </span>
          </p>
        </div>
      </div>
      <div className="row px-3">
        {reviewData.length > 0 &&
          reviewData.map((item, index) => {
            return (
              <ReviewItem
                key={item.ur_idx}
                item={item}
                createRatings={createRatings}
                isDetail={true}
              />
            );
          })}
        {!tourData.rl_rating && (
          <div className="col-12 p-5 bg-light border rounded-lg mb-3">
            <p className="h5 font-weight-bold text-center text-secondary">
              {t("emptyServiceNote")}
            </p>
          </div>
        )}
      </div>
      <div
        className={`row mt-2 mb-3 mb-md-0 ${
          tourData.rl_rating ? "d-block" : "d-none"
        }`}
      >
        <div className="col">
          <div
            className="btn_1 text-secondary bg-light border border-secondary"
            onClick={() => {
              if (!tourData.rl_rating_cnt) {
                codeList.Modal.current.alert(
                  t("tourDetailReviewEmptyReviewMsg")
                );
                return;
              }
              handleModalView();
            }}
          >
            {t("review")} {tourData.rl_rating_cnt ? tourData.rl_rating_cnt : 0}
            {t("count")} {t("showAll")}
          </div>
        </div>
      </div>
      <TourDetailReviewModal
        tourData={tourData}
        reviewData={reviewData}
        createRatings={createRatings}
        isModalView={isModalView}
        handleModalView={handleModalView}
      />
    </>
  );
}

function TourDetailReviewModal(props) {
  let { tourData, isModalView, handleModalView, reviewData, createRatings } =
    props;
  return (
    <div className={`modal_background ${isModalView ? "d-block" : "d-none"}`}>
      <div className="modal_body bg-white rounded d-flex flex-column">
        <div className="d-flex flex-row justify-content-between p-3 border-bottom">
          <p className="h5 font-weight-bold">
            후기 {tourData.rl_rating_cnt}개 모두 보기
          </p>
          <div className="cursor_pointer" onClick={() => handleModalView()}>
            &times;
          </div>
        </div>

        <div className="p-3 flex-fill overflow_scroll_y">
          {reviewData.length > 0 &&
            reviewData.map((item, index) => {
              return (
                <ReviewItem
                  key={item.ur_idx}
                  item={item}
                  createRatings={createRatings}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

const ReviewItem = React.memo(({ item, createRatings, isDetail = false }) => {
  const isShowImages = item.ur_pics !== "";
  let imgs = isShowImages ? item.ur_pics.split("|") : [];

  return (
    <div
      className={`${isDetail ? "col-12 col-lg-6" : ""} px-2 py-3`}
      key={item.ur_idx}
      style={{ maxWidth: 400 }}
    >
      <div className="d-flex flex-row justify-content-start mb-2 w-100">
        <div className="mr-3">
          <img
            src={util.changedResourceUrl(item.ur_user_pic)}
            alt="Image"
            className="rounded-circle border"
            style={{ width: 50, height: 50 }}
          />
        </div>
        <div className="flex-fill">
          <p className="h6 mb-0">{item.ur_user_name}</p>
          <p className="text-secondary">{item.ur_update_date}</p>
          <div className="d-flex flex-row align-items-baseline mb-2">
            {isShowImages && (
              <i className="fa-regular fa-images text-secondary mr-1" />
            )}
            <div className="text-warning">{createRatings(item.ur_rating)}</div>
          </div>
          {isShowImages && !isDetail && (
            <div className="d-flex flex-row flex-wrap m-0 p-0">
              {imgs.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className="mr-1 mb-1 border"
                      style={{
                        height: 50,
                        width: 50,
                        background: `url(${util.changedResourceUrl(
                          item
                        )}) 50% 50% /cover no-repeat`,
                      }}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <p className={isDetail ? "ellipsis_2" : ""}>{item.ur_review}</p>
        </div>
      </div>
    </div>
  );
});
