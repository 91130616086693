import React, { useEffect, useRef, useState } from "react";
import Nav from "../common/Nav";
import Footer from "../common/Footer";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import Utilities from "../../lib/Utilities";
import GuideDetailCarousel from "./component/GuideDetailCarousel";
import GuideDetailContent from "./component/GuideDetailContent";
import GuideDetailLocation from "./component/GuideDetailLocation";
import GuideDetailSuggestionOther from "./component/GuideDetailSuggestionOther";

import CodeList from "../../lib/CodeList";
import { useTranslation } from "react-i18next";

const codeList = new CodeList();
const socket = new BuddibleSocket();
const util = new Utilities();

const _mClassName = "GuideDetail";

let loginInfo = null;
let guideData = null;
let tourData = null;
let reviewData = null;

export default function GuideDetail(props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      loginInfo = userData;
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
      }
    );

    let filters = {};
    if (props.match.params.hasOwnProperty("gl_idx")) {
      filters = { gl_idx: Number(props.match.params.gl_idx) };
    } else if (
      props.location.hasOwnProperty("state") &&
      props.location.state.hasOwnProperty("rl_guide_uid")
    ) {
      filters = { gl_guide_uid: props.location.state.rl_guide_uid };
    }
    requestGuideData(filters, (newData) => {
      if (newData["returnData"].length === 0) {
        codeList.Modal.current.alert(t("errorMsg"), () => {
          props.history.back();
        });
      }

      if (
        newData["returnData"][0].gl_confirm_admin !== "Y" &&
        (!loginInfo || Number(loginInfo.u_Auth) !== 8)
      ) {
        alert(t("inaccessibleMsg"));
        window.location.replace("/");
        return;
      }

      guideData = { ...newData["returnData"][0] };

      requestGuideService(guideData.gl_guide_uid, (newData) => {
        tourData = newData["returnData"];
        requestGuideReviewData(
          { ur_service_uid: guideData.gl_guide_uid },
          (newData) => {
            reviewData = newData["returnData"];
            window.scrollTo({ top: 0 });
            setIsLoading(false);
          }
        );
      });
    });

    return () => {
      loginInfo = null;
      guideData = null;
      tourData = null;
      reviewData = null;
      setIsLoading(true);
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, [props.match.params.gl_idx]);

  // 가이드 프로필정보 받아오기
  const requestGuideData = (filter, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guideDetailSelect.php",
      msgID: msgID,
      data: {},
      ...filter,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 가이드 승인된 서비스 받아오기
  const requestGuideService = (gl_guide_uid, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_list_select.php",
      msgID: msgID,
      data: {},
      rl_guide_uid: gl_guide_uid,
      rl_confirm_admin: "Y",
      rl_use_yn: "Y",
      rl_del_yn: "N",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 가이드 리뷰 목록 받아오기
  const requestGuideReviewData = (filters, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reviewList/JS_user_review_list_select.php",
      msgID: msgID,
      data: {},
      ur_service_type: "G",
      ...filters,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  return (
    <>
      {!isLoading && guideData && (
        <>
          <Nav />
          <main className="bg-white">
            <GuideDetailCarousel guideData={guideData} />
            <GuideDetailContent
              guideData={guideData}
              tourData={tourData}
              reviewData={reviewData}
            />
            <GuideDetailLocation guideData={guideData} />
            <GuideDetailSuggestionOther guideData={guideData} />
          </main>
          <Footer />
        </>
      )}
    </>
  );
}
