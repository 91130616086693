import React, { useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "TourDetailInfo";

export default function TourDetailInfo(props) {
  const { t } = useTranslation();
  const { tourData, loginInfo } = props;
  const [wishList, setWishList] = useState(
    loginInfo && loginInfo.wish_list !== null ? loginInfo.wish_list : {}
  );

  let addr = tourData.rl_addr_full;
  if (!tourData.rl_addr_full) {
    for (let i = 0; i < tourData.subData.length; i++) {
      if (tourData.subData[i].rs_addr_full) {
        addr = tourData.subData[i].rs_addr_full;
        break;
      }
    }
  }

  // 공유하기(url 복사)
  const shareUrl = async () => {
    let clipboard = navigator.clipboard;
    if (clipboard === undefined) {
      console.log("https 리디렉션 필요");
      return;
    }

    try {
      let url = window.location.href;
      await clipboard.writeText(url);
      codeList.Modal.current.alert(t("copyLinkMsg"));
    } catch (e) {
      codeList.Modal.current.alert(t("copyLinkErrorMsg"));
    }
  };

  // 좋아요 클릭
  const clickSaveWishList = () => {
    if (!loginInfo || !loginInfo.hasOwnProperty("u_uid")) {
      codeList.Modal.current.alert(t("checkLoginMsg"));
      return;
    }

    if (!tourData) {
      codeList.Modal.current.alert(t("errorMsg"));
      return;
    }

    if (wishList.hasOwnProperty(tourData.rl_route_uid)) {
      codeList.Modal.current.confirm(t("cancelSaveMsg"), (ret) => {
        if (ret) {
          insertWishData("Y");
        }
      });
      return;
    }

    insertWishData("N");
  };

  // 좋아요 통신
  const insertWishData = (del) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/wishList/JS_wish_list_update.php",
      msgID: msgID,
      wl_user_uid: loginInfo.u_uid,
      wl_service_uid: tourData.rl_route_uid,
      wl_type: "R",
      wl_use_yn: "Y",
      wl_del_yn: del,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let wish_list = {
            ...wishList,
          };
          if (del === "N") {
            wish_list[tourData.rl_route_uid] = "";
          } else {
            delete wish_list[tourData.rl_route_uid];
          }

          setWishList(wish_list);

          let msg =
            del === "Y"
              ? t("successMsg")
              : `[${tourData.rl_title}]${t("successSaveMsg")}`;

          codeList.Modal.current.alert(msg);

          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            _mClassName,
            { ...loginInfo, wish_list },
            () => {}
          );
        } else {
          codeList.Modal.current.alert(t("errorMsg"));
        }
      }
    });
  };

  return (
    <>
      <div id="single_tour_feat">
        <div
          id="single_tour_feat"
          className="row pb-2 mb-3 border-bottom align-items-end"
        >
          <div className="col-md-8 pb-2 w-100">
            <h3 className="mb-1 font-weight-bold">{tourData.rl_title}</h3>
            <div className="d-flex flex-column flex-md-row justify-content-start align-items-start align-items-md-center">
              <div>
                <span className="mr-3">
                  <i className="icon-star voted text-warning" />
                  {tourData.rl_rating
                    ? `${tourData.rl_rating} (${tourData.rl_rating_cnt})`
                    : "NEW"}
                </span>

                <Link
                  className="mr-3 font-weight-bold cursor_pointer"
                  to={{
                    pathname: "/GuideDetail",
                    state: { rl_guide_uid: tourData.rl_guide_uid },
                  }}
                >
                  <i className="fa-solid fa-flag text-buddible mr-1" />
                  {tourData.rl_name}
                </Link>
              </div>
              <span className="mr-3">
                <i className="fa-solid fa-location-dot text-buddible mr-1" />
                {addr}
              </span>
            </div>
          </div>
          <div className="order-first order-md-last col-md-4 mb-2 text-right">
            <span className="mx-2 cursor_pointer" onClick={shareUrl}>
              {t("share")}
              <i className="fa-solid fa-share ml-1" />
            </span>
            <span className="mx-2 cursor_pointer" onClick={clickSaveWishList}>
              {t("save")}
              {!wishList.hasOwnProperty(tourData.rl_route_uid) ? (
                <i className="fa-regular fa-heart ml-1" />
              ) : (
                <i className="fa-solid fa-heart text-danger ml-1" />
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="row py-3 mt-3">
        <div className="col-lg-3">
          <h3 className="mb-2">{t("tourDetailServiceDesc")}</h3>
        </div>
        <div className="col-lg-9">
          <h4 className="mb-2">{tourData.rl_title}</h4>
          <p className="mb-4">{tourData.rl_desc}</p>

          <div
            className={`${
              tourData.rl_inc_drink === "Y" ||
              tourData.rl_inc_ticket === "Y" ||
              tourData.rl_inc_meal === "Y" ||
              tourData.rl_inc_vehicle === "Y"
                ? "d-block"
                : "d-none"
            }`}
          >
            <h4>{t("tourDetailServiceDescServicesIncluded")}</h4>

            <div className="row py-3">
              <div className="col-md-6">
                <ul className="list_ok">
                  {tourData.rl_inc_drink === "Y" && (
                    <li>{t("tourIncDrink")}</li>
                  )}
                  {tourData.rl_inc_ticket === "Y" && (
                    <li>{t("tourIncTicket")}</li>
                  )}
                  {tourData.rl_inc_meal === "Y" && <li>{t("tourIncMeal")}</li>}
                  {tourData.rl_inc_vehicle === "Y" && (
                    <li>{t("tourIncVehicle")}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              tourData.rl_inc_etc && tourData.rl_inc_etc.trim() !== ""
                ? "d-block"
                : "d-none"
            }`}
          >
            <h4>{t("tourDetailServiceDescIncludedDetail")}</h4>
            <p className="py-3">{tourData.rl_inc_etc}</p>
          </div>
          <div className="px-3 px-md-0">
            {tourData.subData.length > 0 &&
              tourData.subData.map((item, index) => {
                return (
                  <TourSubItem
                    key={item.rs_route_sub_uid + "_" + index}
                    item={item}
                    index={index}
                  />
                );
              })}
          </div>
        </div>
      </div>
      <hr />
      <div className="row py-3">
        <div className="col-lg-3">
          <h3>{t("tourDetailServiceInfoSectionTitle")}</h3>
        </div>
        <div className="col-lg-9">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th colSpan="2">{t("tourDetailServiceInfo")}</th>
                </tr>
              </thead>
              <tbody>
                {tourData.rl_guest_age_limit && (
                  <tr>
                    <td>{t("tourDetailServiceInfoAgeTitle")}</td>
                    <td>
                      {t("tourDetailServiceInfoAgeFirst")}{" "}
                      {tourData.rl_guest_age_limit}
                      {t("tourDetailServiceInfoAgeLast")}
                    </td>
                  </tr>
                )}
                {tourData.rl_guest_min && (
                  <tr>
                    <td>{t("minGuest")}</td>
                    <td>
                      {tourData.rl_guest_min} {t("person")}
                    </td>
                  </tr>
                )}
                {tourData.rl_guest_max && (
                  <tr>
                    <td>{t("maxGuest")}</td>
                    <td>
                      {tourData.rl_guest_max} {t("person")}
                    </td>
                  </tr>
                )}
                {tourData.rl_price_base && (
                  <tr>
                    <td>{t("tourDetailServiceInfoPriceBaseTitle")}</td>
                    <td>&#8361; {util.addCommas(tourData.rl_price_base)}</td>
                  </tr>
                )}
                {tourData.rl_over_pay_people && (
                  <tr>
                    <td>{t("tourDetailServiceInfoPriceAddPerFeeTitle")}</td>
                    <td>
                      &#8361; {util.addCommas(tourData.rl_over_pay_people)}
                    </td>
                  </tr>
                )}
                {tourData.rl_over_pay_hour && (
                  <tr>
                    <td>{t("tourDetailServiceInfoPriceAddTimeFeeTitle")}</td>
                    <td>&#8361; {util.addCommas(tourData.rl_over_pay_hour)}</td>
                  </tr>
                )}
                {tourData.rl_base_hour_start && (
                  <tr>
                    <td>{t("tourDetailServiceInfoStartTimeTitle")}</td>
                    <td>{tourData.rl_base_hour_start}</td>
                  </tr>
                )}
                {tourData.rl_base_hour_duration && (
                  <tr>
                    <td>{t("tourDetailServiceInfoDurationTimeTitle")}</td>
                    <td>{tourData.rl_base_hour_duration}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div>
            <p>★ {t("tourDetailNotice1")}</p>
            <p>★ {t("tourDetailNotice2")}</p>
            <p>★ {t("tourDetailNotice3")}</p>
          </div>
          <div className={tourData.rl_way_explain ? "d-block mt-2" : "d-none"}>
            <p className="h6 font-weight-bold">
              ※ {t("tourDetailServiceInfoHowToDiscernTitle")}
            </p>
            <p>{tourData.rl_way_explain}</p>
          </div>
          <div className="mt-2">
            <p className="h6 font-weight-bold">
              ※ {t("tourDetailServiceInfoCautionTitle")}
            </p>
            <p>
              - {t("tourDetailServiceInfoCaution1")}
              <br />- {t("tourDetailServiceInfoCaution2")}
              <br />- {t("tourDetailServiceInfoCaution3")}
              <br />- {t("tourDetailServiceInfoCaution4")}
              <br />
            </p>
          </div>
          <div className="mt-2">
            <p className="h6 font-weight-bold">
              ※ {t("tourDetailServiceInfoRefundPolicyTitle")}
            </p>
            <p>
              {t("tourDetailServiceInfoRefundPolicy1")}
              <br />
              {t("tourDetailServiceInfoRefundPolicy2")}
              <br />
              {t("tourDetailServiceInfoRefundPolicy3")}
              <br />
              {t("tourDetailServiceInfoRefundPolicy4")}
              <br />
            </p>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

//투어 서브아이템
const TourSubItem = ({ item, index }) => {
  const { t } = useTranslation();
  const pics = item.rs_pic !== "" ? item.rs_pic.split("|") : null;

  return (
    <div className="row shadow-sm border border-light m-0 p-2 rounded mb-3">
      <div
        className="col-12 col-md-4 m-0 p-0 rounded overflow-hidden position-relative owl-nav-custom-center"
        style={{
          minHeight: 200,
        }}
      >
        {pics !== null && (
          <>
            <span
              className="d-inline-block position-absolute font-weight-bold px-2 font-size-08 rounded"
              style={{
                top: 5,
                right: 5,
                zIndex: 3,
                background: "rgba(255,255,255,0.6)",
              }}
            >
              + {pics.length - 1}
              <i className="fa-regular fa-images ml-1" />
            </span>
            <OwlCarousel
              className="owl-theme"
              style={{ minHeight: "100%" }}
              loop
              nav={pics.length > 1}
              dots={false}
              items={1}
            >
              {pics.map((pic, index) => {
                const splitData = pic.split("?");
                const fileLink = splitData[1];

                return (
                  <div
                    key={index}
                    className="item rounded border bg-light d-flex flex-row justify-content-center align-items-center"
                    style={{
                      height: "100%",
                      minHeight: 200,
                      minWidth: 200,
                      background: `url(${
                        pic.indexOf("?") > -1
                          ? fileLink
                          : util.changedResourceUrl(pic)
                      }) 50% 50%/cover no-repeat`,
                    }}
                  />
                );
              })}
            </OwlCarousel>
          </>
        )}
      </div>

      <div className="col-12 col-md-8 p-3">
        <p className="h5 font-weight-bold">
          {item.rs_name ? item.rs_name : item.rs_street_name}
        </p>
        <p className="mb-2">
          <i className="fa-solid fa-location-dot text-buddible mr-1" />
          {item.rs_addr_full}
        </p>
        <p>{item.rs_desc}</p>
      </div>
    </div>
  );
};
