import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";

import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import { Pagination } from "@material-ui/lab";
import CodeList from "../../../../lib/CodeList";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "ReservationManageTable";

const SUBJECT_CODE = {
  NAT: "자연·환경 관광", //<i className="icon_set_1_icon-24"//
  SOC: "사회·문화 관광", //<i className="icon_set_1_icon-1"//
  SHO: "쇼핑 관광", //<i className="icon_set_1_icon-50"//
  RES: "맛집 휴양", //<i className="icon_set_1_icon-14"//
  BEC: "해변 휴양", //<i className="icon_set_2_icon-108"//
  NMK: "야간 시장", //<i className="icon_set_3_restaurant-9"//
  BEV: "야간 술집", //<i className="icon_set_1_icon-60"//
  NVW: "야간 풍경", //<i className="icon_set_1_icon-24"//
  OAT: "야외 활동", //<i className="icon_set_1_icon-30"//
  SPO: "스포츠 활동", //<i className="icon_set_1_icon-40"//
  ETM: "오락 활동", //<i className="icon_set_1_icon-3"//
};

const STATUS_TYPE = {
  CR: "요청",
  GA: "승인",
  CC: "취소",
  GC: "거절",
};
const STATUS_TYPE_COLOR = {
  CR: "secondary",
  GA: "info",
  CC: "danger",
  GC: "danger",
};
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const headCells = [
  {
    field: "rl_idx",
    headerName: "서비스 번호",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "rl_title",
    headerName: "서비스명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "u_name_full",
    headerName: "예약자명",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "rrl_status",
    headerName: "상태",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span
        className={`text-truncate text-wrap text-break font-weight-bold text-${
          STATUS_TYPE_COLOR[params.data]
        }`}
      >
        {STATUS_TYPE[params.data]}
      </span>
    ),
  },
  {
    field: "rrl_date_agree",
    headerName: "확정일시",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data || "-"}
      </span>
    ),
  },
  {
    field: "rrl_date_reserve",
    headerName: "예약 요청 일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "rrl_end_date",
    headerName: "이용 일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "rrl_guide_payment_yn",
    headerName: "정산여부",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return (
        <span
          className={`font-weight-bold ${
            params.data === "Y" ? "text-primary" : "text-secondary"
          }`}
        >
          {params.data === "Y" ? "지급" : "미지급"}
        </span>
      );
    },
  },
];

let sendFileData = [];
export default function ReservationManageTable({
  isServerPaging = true,
  isServerSort = true,
  rowData,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});
  const [serverData, setServerData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("rrl_create_date");

  const [dense, setDense] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "rrl_create_date",
      dir: "desc",
    },
  ]);

  const [filters, setFilters] = useState({});

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!n["returnData"].length) return;
        setLoginInfo(n["returnData"][0]);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setFilters({ ...n });
        // requestUserSelect();
      }
    );
  })();

  useEffect(() => {
    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, filters, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserve/JS_reserve_list_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    parentData={rowData}
                    requestUserSelect={requestUserSelect}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    parentData={rowData}
                    requestUserSelect={requestUserSelect}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    parentData={rowData}
                    requestUserSelect={requestUserSelect}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  parentData={rowData}
                  requestUserSelect={requestUserSelect}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const {
    row,
    openEdit,
    openCopy,
    requestUserSelect,
    isMultiSelect = false,
  } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState([]);
  const classes = useRowStyles();

  const isSelected = (name) => {
    return selected.indexOf(name) !== -1;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (isMultiSelect) {
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
    } else {
      if (selectedIndex === -1) {
        newSelected = [name];
      }
    }
    setSelected(newSelected);
  };

  const isItemSelected = isSelected(row.id);

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        hover
        onClick={(event) => handleClick(event, row.id)}
        role="checkbox"
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={row.id}
        selected={isItemSelected}
        size={"small"}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <i className="fa-solid fa-chevron-up" />
            ) : (
              <i className="fa-solid fa-chevron-down" />
            )}
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  openCopy: openCopy,
                  open: open,
                  setOpen: setOpen,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  openEdit: openEdit,
                  openCopy: openCopy,
                  open: open,
                  setOpen: setOpen,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/*<Table size="small" aria-label="purchases">*/}
              {/*  <TableBody>*/}
              <ReserveDetail
                rowData={row}
                setOpen={setOpen}
                requestUserSelect={requestUserSelect}
              />
              {/*</TableBody>*/}
              {/*</Table>*/}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ReserveDetail = (props) => {
  const { rowData } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  let rrl_data = {
    rrl_guest_adult_num: 1, //성인 인원
    rrl_guest_child_num: 0, //어린이 인원
    rrl_guest_baby_num: 0, //유아 인원
    rrl_cost_total: 0, //총금액
    rrl_cost_fees_guest: 0, //게스트 부담 서비스수수료
    rrl_guest_over_cnt: 0, //초과 인원 수
    rrl_cost_over_guest: 0, // //초가 인원 요금
    rrl_cost_over_hour: 0, //초과 시간 요금
    rrl_date_reserve: null, //예약일자
    //  -----------------
    rrl_cost_tax_free: 0,
    rrl_cost_tax: 0,
    rrl_guest_total_num: 1,
  };

  useEffect(() => {
    handleGuestCnt("rrl_guest_adult_num", 0);
  }, []);

  const handleGuestCnt = (key, calc) => {
    // calc 0 = minus, 1 = plus
    if (!key) return;
    if (calc && Number(rowData.rl_guest_max) <= rrl_data.rrl_guest_total_num)
      return;

    let cnt = calc ? ++rrl_data[key] : --rrl_data[key];
    if (cnt < 0) cnt = 0;
    if (key === "rrl_guest_adult_num" && cnt === 0) cnt = 1;
    rrl_data[key] = cnt;

    //게스트 총인원
    rrl_data.rrl_guest_total_num =
      rrl_data.rrl_guest_adult_num +
      rrl_data.rrl_guest_child_num +
      rrl_data.rrl_guest_baby_num;

    //게스트 초과인원 수 및 금액
    rrl_data.rrl_guest_over_cnt = 0;
    if (rrl_data.rrl_guest_total_num > Number(rowData.rl_guest_min)) {
      rrl_data.rrl_guest_over_cnt =
        rrl_data.rrl_guest_total_num - Number(rowData.rl_guest_min);
    }
    rrl_data.rrl_cost_over_guest =
      rrl_data.rrl_guest_over_cnt * Number(rowData.rl_over_pay_people);

    //서비스 수수료
    let cost_total =
      Number(rowData.rl_price_base) + rrl_data.rrl_cost_over_guest;
    rrl_data.rrl_cost_fees_guest = Math.round(cost_total * 0.15);

    //부가세 제외 금액
    rrl_data.rrl_cost_tax_free =
      Number(rowData.rl_price_base) + rrl_data.rrl_cost_fees_guest;
    if (rrl_data.rrl_cost_over_guest) {
      rrl_data.rrl_cost_tax_free += rrl_data.rrl_cost_over_guest;
    }

    //부가세
    rrl_data.rrl_cost_tax = rrl_data.rrl_cost_tax_free * 0.1;

    //총 결제 금액
    rrl_data.rrl_cost_total =
      rrl_data.rrl_cost_tax_free + rrl_data.rrl_cost_tax;

    rrl_data.rrl_cost_tax = Math.floor(rrl_data.rrl_cost_tax);
    rrl_data.rrl_cost_tax_free = Math.floor(rrl_data.rrl_cost_tax_free);
    rrl_data.rrl_cost_total = Math.floor(rrl_data.rrl_cost_total);

    console.log(rrl_data);
    setIsNeedUpdate(!isNeedUpdate);
  };

  const status_text_color =
    rowData.rrl_status === "CR"
      ? "text-secondary"
      : rowData.rrl_status === "GA"
      ? "text-info"
      : "text-danger";

  const serviceImage =
    rowData.rl_pic_main.indexOf("?") > -1
      ? rowData.rl_pic_main.split("?")[1]
      : codeList.publicVar.imgServerUrl + rowData.rl_pic_main;

  const reservationImage = rowData.u_avata_pic
    ? util.changedResourceUrl(rowData.u_avata_pic)
    : codeList.publicVar.defualt_profile_image;

  return (
    <div className="container" id={rowData.rrl_idx}>
      <div className="d-flex flex-column flex-xl-row justify-content-between">
        <div className="p-2 flex-grow-1">
          <p className="h5 font-weight-bold">요청 서비스</p>
          <div
            className="w-100 rounded border"
            style={{
              height: 250,
              background: `url(${serviceImage})50% 50%/cover no-repeat`,
            }}
          />
          <p className="mt-2">
            {SUBJECT_CODE[rowData.rl_subject]}{" "}
            <i className="icon-star voted text-warning" />
            {rowData.rl_rating}점 ({rowData.rl_rating_cnt}개)
          </p>
          <p className="h4 m-0 p-0 mb-1">{rowData.rl_title}</p>
          <p>{rowData.rl_addr_full}</p>
          <hr />

          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">소요시간</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rl_base_hour_duration}</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">기본인원</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rl_guest_min} 인</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">최대인원</div>
            <div className="col m-0 p-0 text-right">
              <p>{rowData.rl_guest_max} 인</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">기본요금</div>
            <div className="col m-0 p-0 text-right">
              <p>{util.addCommas(rowData.rrl_cost_guide)} 원</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">추가인원 요금</div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rl_over_pay_people)}</p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2">
            <div className="col m-0 p-0 text-secondary">추가시간 요금</div>
            <div className="col m-0 p-0 text-right">
              <p>&#8361; {util.addCommas(rowData.rl_over_pay_hour)}</p>
            </div>
          </div>
        </div>
        <div className="border-bottom d-xl-none" />
        <div
          className="py-2 flex-grow-1 d-flex flex-xl-row"
          style={{ minWidth: "33.333333%" }}
        >
          <div className="border-right d-none d-xl-block" />
          <div className="flex-fill px-3">
            <p className="h5 font-weight-bold">예약 상세</p>
            <div className="border rounded-lg text-center mb-3">
              <div className="border-bottom p-3 font-weight-bold bg-light">
                예약상태
              </div>
              <div className={`p-3 ${status_text_color} font-weight-bold`}>
                {STATUS_TYPE[rowData.rrl_status]}
              </div>
            </div>

            <div className="row m-0 p-0 mb-2">
              <div className="col m-0 p-0 text-secondary">서비스 시작일시</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.rrl_start_date}</p>
              </div>
            </div>
            <div className="row m-0 p-0 mb-2">
              <div className="col m-0 p-0 text-secondary">서비스 종료일시</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.rrl_end_date}</p>
              </div>
            </div>
            <div className="row m-0 p-0 mb-2">
              <div className="col m-0 p-0 text-secondary">성인</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.rrl_guest_adult_num} 인</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 ${
                rowData.rrl_guest_child_num !== "0" ? "d-flex" : "d-none"
              }`}
            >
              <div className="col m-0 p-0 text-secondary">어린이</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.rrl_guest_child_num} 인</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 ${
                rowData.rrl_guest_baby_num !== "0" ? "d-flex" : "d-none"
              }`}
            >
              <div className="col m-0 p-0 text-secondary">유아</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.rrl_guest_baby_num} 인</p>
              </div>
            </div>

            <hr />

            <div className={`row m-0 p-0 mb-2 `}>
              <div className="col m-0 p-0 text-secondary">기본요금</div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_guide)}</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 ${
                rowData.rrl_cost_over_guest !== "0" ? "d-flex" : "d-none"
              }`}
            >
              <div className="col m-0 p-0 text-secondary">
                추가인원 {rowData.rrl_guest_over_cnt}명 x &#8361;{" "}
                {util.addCommas(rowData.rl_over_pay_people)}
              </div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_over_guest)}</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 ${
                rowData.rrl_cost_over_hour !== "0" ? "d-flex" : "d-none"
              }`}
            >
              <div className="col m-0 p-0 text-secondary">
                추가시간 {rowData.rrl_cost_over_hour}시간 x &#8361;{" "}
                {util.addCommas(rowData.rl_over_pay_hour)}
              </div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_over_hour)}</p>
              </div>
            </div>

            <div
              className={`row m-0 p-0 mb-2 h6 text-secondary font-weight-bold`}
            >
              <div className="col m-0 p-0">기본요금 총금액</div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_guide)}</p>
              </div>
            </div>
            <div className={`row m-0 p-0 mb-2 h6 text-danger font-weight-bold`}>
              <div className="col m-0 p-0">서비스 수수료</div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_guide * 0.15)}</p>
              </div>
            </div>
            <div className={`row m-0 p-0 mb-2 h6 text-danger font-weight-bold`}>
              <div className="col m-0 p-0">부가세와 수수료</div>
              <div className="col m-0 p-0 text-right">
                <p>
                  &#8361;{" "}
                  {util.addCommas(
                    (Number(rowData.rrl_cost_guide) +
                      rowData.rrl_cost_guide * 0.15) *
                      0.1
                  )}
                </p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 h6 text-buddible font-weight-bold`}
            >
              <div className="col m-0 p-0">총 결제 금액</div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_guide)}</p>
              </div>
            </div>
            {/*
            <div className={`row m-0 p-0 mb-2 h6 text-danger font-weight-bold`}>
              <div className="col m-0 p-0">플랫폼 수수료</div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_fees_guide)}</p>
              </div>
            </div>
            <div
              className={`row m-0 p-0 mb-2 h6 text-buddible font-weight-bold`}
            >
              <div className="col m-0 p-0">정산예정금액</div>
              <div className="col m-0 p-0 text-right">
                <p>&#8361; {util.addCommas(rowData.rrl_cost_paid)}</p>
              </div>
            </div>
*/}
            <div className="border rounded mt-3">
              <div className="bg-light p-3 border-bottom font-weight-bold text-center">
                요구 및 문의사항
              </div>
              <div className="p-3">{rowData.rrl_memo_guest || "-"}</div>
            </div>
          </div>
          <div className="border-right d-none d-xl-block" />
        </div>
        <div className="border-bottom d-xl-none" />

        <div
          className="p-2 flex-grow-1 d-flex flex-column justify-content-between"
          style={{ minWidth: "33.333333%" }}
        >
          <div>
            <p className="h5 font-weight-bold">예약자 정보</p>
            <div
              className="w-100 rounded-lg border mb-3"
              style={{
                height: 200,
                background: `url(${reservationImage}) 50% 50% / cover no-repeat`,
              }}
            />

            <div className={`row m-0 p-0 mb-2`}>
              <div className="col m-0 p-0 text-secondary">예약자명</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.u_name_full}</p>
              </div>
            </div>
            <div className={`row m-0 p-0 mb-2`}>
              <div className="col m-0 p-0 text-secondary">성별</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.u_gender === "M" ? "남성" : "여성"}</p>
              </div>
            </div>
            <div className={`row m-0 p-0 mb-2`}>
              <div className="col m-0 p-0 text-secondary">본인인증 여부</div>
              <div className="col m-0 p-0 text-right">
                <p>{rowData.u_isVerified === "Y" ? "인증" : "미인증"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
