import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import Utilities from "../../../lib/Utilities";
import { useTranslation } from "react-i18next";

const util = new Utilities();

export default function CommunityNews({ dataList }) {
  const ItemRender = useCallback((props) => <Item {...props} />, []);

  if (dataList.length === 0) return <></>;

  return (
    <div className="row m-0 p-0">
      {dataList.map((data, index) => {
        return <ItemRender key={data.cm_idx} data={data} index={index} />;
      })}
    </div>
  );
}

const Item = React.memo(({ data, index }) => {
  const {
    cm_idx,
    cm_look_cnt,
    cm_main_pic,
    cm_title,
    cm_sub_title,
    cm_update_date,
    cm_reply_cnt,
  } = data;
  const { t } = useTranslation();
  const main_pic = util.changedResourceUrl(cm_main_pic);
  return (
    <div className="col-lg-4 m-0 p-0 px-2 pb-3">
      <Link to={`/CommunityDetail/${cm_idx}`} className="text-dark">
        <div
          className="rounded rounded-xl border mb-1"
          style={{
            height: 200,
            background: `url(${main_pic}) 50% 50%/cover no-repeat`,
          }}
        />
        <h6 className="mb-1 ellipsis_1">{cm_title}</h6>
        <p className="mb-2 ellipsis_1">{cm_sub_title || <br />}</p>
        <div className="d-flex flex-row justify-content-between font-size-08 font-weight-bold text-secondary">
          <p>
            <span className="mr-2">
              {t("view")} {util.addCommas(cm_look_cnt)}
            </span>
            {t("reply")} {util.addCommas(cm_reply_cnt)}
          </p>
          <p>{util.getDateToFormat(cm_update_date, "YYYY-MM-DD")}</p>
        </div>
      </Link>
    </div>
  );
});
