import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import Utilities from "../../../lib/Utilities";
import { useTranslation } from "react-i18next";

const util = new Utilities();

export default function CommunityGuideTip({ dataList }) {
  const ItemRender = useCallback((props) => <Item {...props} />, []);

  if (dataList.length === 0) return <></>;

  return (
    <div className="row m-0 p-0">
      {dataList.map((data, index) => {
        return <ItemRender key={data.cm_idx} data={data} index={index} />;
      })}
    </div>
  );
}

const Item = React.memo(({ data, index }) => {
  const {
    cm_idx,
    cm_look_cnt,
    cm_main_pic,
    cm_title,
    cm_sub_title,
    cm_update_date,
    cm_reply_cnt,
  } = data;
  const { t } = useTranslation();
  const main_pic = util.changedResourceUrl(cm_main_pic);
  return (
    <div className="col-6 col-lg-3 m-0 p-0 px-2 pb-3">
      <Link
        to={`/CommunityDetail/${cm_idx}`}
        className="d-flex flex-row text-dark bg-light p-3 rounded-xl"
      >
        <div
          className="rounded-circle border d-none d-md-block mr-3"
          style={{
            background: `url(${main_pic}) 50% 50%/cover no-repeat`,
            minWidth: 50,
            width: 50,
            height: 50,
          }}
        />
        <div className="flex-fill">
          <h6 className="mb-1 font-weight-bold ellipsis_1">{cm_title}</h6>
          <p className="mb-2 ellipsis_1">{cm_sub_title || <br />}</p>
          <div className="d-flex flex-column flex-md-row justify-content-between font-size-08 font-weight-bold text-secondary">
            <p>
              <span className="mr-2">
                {t("view")} {util.addCommas(cm_look_cnt)}
              </span>
              {t("reply")} {util.addCommas(cm_reply_cnt)}
            </p>
            <p>{util.getDateToFormat(cm_update_date, "YYYY-MM-DD")}</p>
          </div>
        </div>
      </Link>
    </div>
  );
});
