import React from "react";
import { useTranslation } from "react-i18next";

export default function GuideRegistIntroContent() {
  const { t } = useTranslation();
  return (
    <div className="container margin_60">
      <div className="main_title">
        <h2>
          {t("guideRegistIntroContentTitleStart")}{" "}
          <span>{t("guideRegistIntroContentTitleEnd")}</span>
        </h2>
        <p>{t("guideRegistIntroContentDesc")}</p>
      </div>

      <div className="row">
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div className="feature" style={{ minHeight: 150 }}>
            <i className="icon_set_1_icon-30" />
            <h3 className="mt-0 mb-2">
              <span>{t("guideRegistIntroContent1Title")}</span>
            </h3>
            <p>{t("guideRegistIntroContent1Desc")}</p>
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.2s">
          <div className="feature" style={{ minHeight: 150 }}>
            <i className="icon_set_1_icon-41" />
            <h3 className="mt-0 mb-2">
              <span>{t("guideRegistIntroContent2Title")}</span>
            </h3>
            <p>{t("guideRegistIntroContent2Desc")}</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.3s">
          <div className="feature" style={{ minHeight: 150 }}>
            <i className="icon_set_1_icon-61" />
            <h3 className="mt-0 mb-2">
              <span>{t("guideRegistIntroContent3Title")}</span>
            </h3>
            <p>{t("guideRegistIntroContent3Desc")}</p>
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.4s">
          <div className="feature" style={{ minHeight: 150 }}>
            <i className="icon_set_1_icon-22" />
            <h3 className="mt-0 mb-2">
              <span>{t("guideRegistIntroContent4Title")}</span>
            </h3>
            <p>{t("guideRegistIntroContent4Desc")}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <h4 className="font-weight-bold mb-2">
            {t("guideRegistIntroContent5Title")}
          </h4>
          <p>{t("guideRegistIntroContent5Desc")}</p>
        </div>
        <div className="col-lg-6">
          <h4 className="font-weight-bold mb-2">
            {t("guideRegistIntroContent6Title")}
          </h4>
          <p>{t("guideRegistIntroContent6Desc")}</p>
        </div>
      </div>
    </div>
  );
}
