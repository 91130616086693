import React from "react";

export default function GuideRegistSafetyRule(props) {
  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/safety1Overview2.jpg")50% 50%/cover no-repeat`,
          }}
        />
      </div>
      <div>
        <p>
          가이드 중에는 감염병 예방을 위해 되도록 마스크를 착용하고 손을 자주
          씻으세요.
        </p>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">· </div>
              <p>
                가이드 전후와 진행 중에 손을 깨끗이 씻으세요. 비누를 사용하여
                따뜻한 물로 최소 20초 이상 꼼꼼하게 손을 씻으세요.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">· </div>
              <p>손 소독제를 휴대하고 게스트에게도 제공하세요.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
