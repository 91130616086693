import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from "react";
import FAQSideWidget from "./FAQSideWidget";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let category;
let title;
let category_md;
let subTitle;
let content;

let serverData;

export default function FAQContent(props) {
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const param = useParams();

  useLayoutEffect(() => {
    if (!param.faqType) {
      category = "FAQ01";
    } else {
      category = param.faqType;
    }

    switch (category) {
      case "FAQ01":
        title = t("faqTitle1");
        break;
      case "FAQ02":
        title = t("faqTitle2");
        break;
      case "FAQ03":
        title = t("faqTitle3");
        break;
      case "FAQ04":
        title = t("faqTitle4");
        break;
      case "FAQ05":
        title = t("faqTitle5");
        break;
      default:
        break;
    }

    requestFaqList();
  }, [param]);

  const requestFaqList = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/faq/JS_faq_select.php",
      msgID: msgID,
      data: {},
      faq_category_lg: category,
      faq_use_yn: "Y",
      faq_del_yn: "N",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"]) {
            let categoryMd = [];
            for (let i = 0; i < newData["returnData"].length; i++) {
              categoryMd.push(newData["returnData"][i].faq_category_md);
            }
            category_md = new Set(categoryMd);
            serverData = newData["returnData"];
            setIsNeedUpdate(!isNeedUpdate);
          }
        }
      }
    });
  };

  const FaqItemRender = useCallback(({ item, category_md }) => {
    return (
      <FaqItem
        id={item.id}
        faq_idx={item.faq_idx}
        faq_category_lg={item.faq_category_lg}
        faq_category_md={item.faq_category_md}
        faq_title={item.faq_title}
        faq_content={item.faq_content}
        category_md={category_md}
      />
    );
  }, []);

  return (
    <>
      <div className="container margin_60">
        <div className="row">
          <FAQSideWidget params={param} />

          <div className="col-lg-9 box_style_1" id="faq">
            <h2>{title}</h2>
            {category_md &&
              category_md.map((item, index) => {
                return (
                  <div className="mb-4" key={index}>
                    <h3 className="nomargin_top mb-3">{item}</h3>
                    {serverData &&
                      serverData.map((item2, index) => {
                        return (
                          <FaqItemRender
                            item={item2}
                            category_md={item}
                            key={item2.faq_idx}
                          />
                        );
                      })}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

const FaqItem = React.memo(
  ({
    id,
    faq_idx,
    faq_category_lg,
    faq_category_md,
    faq_title,
    faq_content,
    category_md,
  }) => {
    // console.log("faq_category_md", faq_category_md, "category_md", category_md);
    if (faq_category_md === category_md) {
      return (
        <>
          <div id={`faq_${faq_category_lg}`} className="accordion_styled">
            <div className="card" key={`${faq_category_lg}_${id}`}>
              <div className="card-header py-3">
                <h4 style={{ paddingTop: 0 }}>
                  <a
                    className="accordion-toggle"
                    data-toggle="collapse"
                    data-parent={`#faq_${faq_category_lg}`}
                    href={`#collapse${id}_${faq_category_lg}`}
                  >
                    {faq_title}
                    <i className="indicator icon-plus float-right"></i>
                  </a>
                </h4>
              </div>
              <div
                id={`collapse${id}_${faq_category_lg}`}
                className="collapse"
                data-parent={`#faq_${faq_category_lg}`}
              >
                <div className="card-body">
                  <div dangerouslySetInnerHTML={{ __html: faq_content }} />
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
);
