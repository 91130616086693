import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function RegistGuideInfo() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="container margin_60">
        <div className="main_title text-center text-sm-left mb-2">
          <h2>
            {t("mainRegistGuideInfoStart")}{" "}
            <span>{t("mainRegistGuideInfoCenter")}</span>{" "}
            {t("mainRegistGuideInfoEnd")}
          </h2>
          <p>{t("mainRegistGuideInfoDesc")}</p>
        </div>

        <div className="banner_2">
          <div
            className="wrapper d-flex align-items-center opacity-mask"
            data-opacity-mask="rgba(0, 0, 0, 0.3)"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
          >
            <div>
              <h3 className="mb-2">
                {t("mainRegistGuideInfoSectionTitleStart")}
                <br />
                {t("mainRegistGuideInfoSectionTitleEnd")}
              </h3>
              <Link to="/GuideRegistIntro" className="btn_1">
                {t("start")}
              </Link>
            </div>
          </div>
        </div>
        <div className="row feature_home_2">
          <div className="col-md-4 text-center">
            <img
              className="my-3"
              src="./img/adventure_icon_1.svg"
              alt=""
              width="75"
              height="75"
            />
            <h3>{t("mainRegistGuideInfoBanner1Title")}</h3>
            <p>{t("mainRegistGuideInfoBanner1Desc")}</p>
          </div>
          <div className="col-md-4 text-center">
            <img
              className="my-3"
              src="./img/adventure_icon_2.svg"
              alt=""
              width="75"
              height="75"
            />
            <h3>{t("mainRegistGuideInfoBanner2Title")}</h3>
            <p>{t("mainRegistGuideInfoBanner2Desc")}</p>
          </div>
          <div className="col-md-4 text-center">
            <img
              className="my-3"
              src="./img/adventure_icon_3.svg"
              alt=""
              width="75"
              height="75"
            />
            <h3>{t("mainRegistGuideInfoBanner3Title")}</h3>
            <p>{t("mainRegistGuideInfoBanner3Desc")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
