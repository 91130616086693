import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Collapse,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@material-ui/core";

import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import { Pagination } from "@material-ui/lab";
import CodeList from "../../../../lib/CodeList";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "TourManageTable";
const TOUR_TYPE = {
  BEC: "해변 휴양",
  BEV: "야간 술집",
  ETM: "오락 활동",
  NAT: "자연·환경 관광",
  NMK: "야간 시장",
  NVW: "야간 풍경",
  OAT: "야외 활동",
  RES: "맛집 휴양",
  SHO: "쇼핑 관광",
  SOC: "사회·문화 관광",
  SPO: "스포츠 활동",
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      padding: 11,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  pagination: {
    "& ul": {
      justifyContent: "flex-end",
    },
  },
}));

const headCells = [
  {
    field: "rl_confirm_admin",
    headerName: "상태",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      let confirmClass = "badge-secondary";
      let confirm = "작성중";

      if (params.data === "W") {
        confirmClass = "badge-secondary";
        confirm = "작성중";
      }
      if (params.data === "S") {
        confirmClass = "badge-primary";
        confirm = "검토대기";
      }
      if (params.data === "Y") {
        confirmClass = "badge-success";
        confirm = "승인";
      }
      if (params.data === "N") {
        confirmClass = "badge-danger";
        confirm = "거부";
      }
      if (params.data === "C") {
        confirmClass = "badge-warning";
        confirm = "등록취소";
      }
      return (
        <p className="text-center">
          <span className={`badge badge-pill ${confirmClass} px-2 py-1`}>
            {confirm}
          </span>
        </p>
      );
    },
  },
  {
    field: "rl_idx",
    headerName: "NO",
    numeric: false,
    disablePadding: false,
  },
  {
    field: "rl_subject",
    headerName: "분류",
    minWidth: 100,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {TOUR_TYPE[params.data]}
      </span>
    ),
  },
  {
    field: "rl_title",
    headerName: "제목",
    minWidth: 150,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "rl_area_cd",
    headerName: "국가",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "gl_name",
    headerName: "가이드명",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">{params.data}</span>
    ),
  },
  {
    field: "rl_use_yn",
    headerName: "사용유무",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => (
      <span className="text-truncate text-wrap text-break">
        {params.data === "Y" ? "사용" : params.data === "N" ? "미사용" : ""}
      </span>
    ),
  },
  {
    field: "rl_reg_date",
    headerName: "생성일자",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: (params) => {
      return <span>{util.getDateToFormat(params.data, "YYYY-MM-DD")}</span>;
    },
  },
  {
    field: "rl_edit_date",
    headerName: "기능",
    minWidth: 110,
    numeric: false,
    disablePadding: false,
    renderCell: ({ currentData, updateConfirmAdmin, openConfirmDetail }) => {
      const isConfirmDisabled =
        currentData.rl_confirm_admin === "W" ||
        currentData.rl_confirm_admin === "C";

      return (
        <div className="d-flex flex-row">
          <button
            className="btn btn-sm btn-secondary mr-2"
            onClick={() => openConfirmDetail(currentData.rl_idx)}
          >
            화면 검토
          </button>
          <select
            className="form-control"
            style={{ maxWidth: 200 }}
            disabled={isConfirmDisabled}
            value={currentData.rl_confirm_admin}
            onChange={(e) => {
              updateConfirmAdmin({
                ...currentData,
                rl_confirm_admin: e.target.value,
              });
            }}
          >
            <option value="W" disabled={!isConfirmDisabled}>
              작성중
            </option>
            <option value="S" disabled={!isConfirmDisabled}>
              제출
            </option>
            <option value="Y">승인</option>
            <option value="N">거절</option>
            <option value="C" disabled={!isConfirmDisabled}>
              취소(가이드)
            </option>
          </select>
        </div>
      );
    },
  },
];

export default function TourManageTable({
  isServerPaging = true,
  isServerSort = true,
  rowData,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({});
  const [serverData, setServerData] = useState([]);
  const [totalCount, setTotalCount] = useState(100);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("rl_reg_date");

  const [dense, setDense] = useState(false);
  const [sortModel, setSortModel] = useState([
    {
      field: "rl_reg_date",
      dir: "desc",
    },
  ]);

  const [filters, setFilters] = useState({});

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (!n["returnData"].length) return;
        setLoginInfo(n["returnData"][0]);
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_TOUR_MANAGE_FILTER_CHANGED,
      _mClassName,
      (b, n) => {
        setPage(0);
        setFilters({ ...n });
        // requestUserSelect();
      }
    );
  })();

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      setLoginInfo(userData);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_SOCKET_DATA_ERROR,
      _mClassName,
      (b, n) => {
        setLoading(false);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_SOCKET_DATA_ERROR,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_TOUR_MANAGE_FILTER_CHANGED,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    requestUserSelect();
  }, [page, filters, sortModel]);

  const requestUserSelect = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_list_select.php",
      msgID: msgID,
      data: {},
      take: rowsPerPage,
      skip: page * rowsPerPage,
      page: page + 1,
      pageSize: 5,
      sort: [...sortModel],
      ...filters,
    };
    setLoading(true);
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          // console.log(newData["returnData"]);
          setServerData(newData["returnData"]);
          setTotalCount(parseInt(newData.pageSize, 10));
          setLoading(false);
          setTotalPageCount(
            parseInt(parseInt(newData.pageSize, 10) / rowsPerPage, 10) +
              (parseInt(newData.pageSize, 10) % rowsPerPage > 0 ? 1 : 0)
          );
        }
      }
    });
  };

  // 관리자 검토 상태 업데이트
  const updateConfirmAdmin = (updateData) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_confirm_admin.php",
      msgID: msgID,
      rl_idx: updateData.rl_idx,
      rl_confirm_admin: updateData.rl_confirm_admin,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert("정상처리되었습니다", () => {
            requestUserSelect();
          });
        } else {
          codeList.Modal.current.alert(
            "가이드 등록 검토상태 변경중 오류가 발생했습니다. 다시 시도해주세요"
          );
        }
      }
    });
  };

  // 검토화면 열기
  const openConfirmDetail = (idx) => {
    window.open(`/TourDetail/${idx}`, "tourConfirm", "width=1680,height=1080");
  };

  const data = React.useMemo(() => {
    return [...serverData];
  }, [serverData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setSortModel([
      {
        field: property,
        dir: isAsc ? "desc" : "asc",
      },
    ]);
  };

  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - totalCount);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
          />
          <TableBody>
            {!isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    parentData={rowData}
                    requestUserSelect={requestUserSelect}
                    updateConfirmAdmin={updateConfirmAdmin}
                    openConfirmDetail={openConfirmDetail}
                  />
                ))}
            {!isServerPaging &&
              isServerSort &&
              util
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    parentData={rowData}
                    requestUserSelect={requestUserSelect}
                    updateConfirmAdmin={updateConfirmAdmin}
                    openConfirmDetail={openConfirmDetail}
                  />
                ))}

            {isServerPaging &&
              !isServerSort &&
              util
                .stableSort(data, util.getComparator(order, orderBy))
                .map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    index={index}
                    parentData={rowData}
                    requestUserSelect={requestUserSelect}
                    updateConfirmAdmin={updateConfirmAdmin}
                    openConfirmDetail={openConfirmDetail}
                  />
                ))}

            {isServerPaging &&
              isServerSort &&
              data.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  parentData={rowData}
                  requestUserSelect={requestUserSelect}
                  updateConfirmAdmin={updateConfirmAdmin}
                  openConfirmDetail={openConfirmDetail}
                />
              ))}

            {loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-grow text-secondary"
                      role="status"
                    />
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length === 0 && !loading && (
              <TableRow style={{ height: (dense ? 33 : 54) * rowsPerPage }}>
                <TableCell colSpan={headCells.length}>
                  <div className="d-flex justify-content-center">
                    <div>해당 데이터가 존재하지 않습니다.</div>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {data.length > 0 && emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 54) * emptyRows }}>
                <TableCell colSpan={headCells.length} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <div className="mb-3 mt-3 pr-3">
          <Pagination
            style={{ justifyContent: "flex-end" }}
            className={classes.pagination}
            color="primary"
            count={totalPageCount}
            page={page + 1}
            allin={"right"}
            onChange={(event, value) => {
              handleChangePage(event, value - 1);
            }}
            showFirstButton
            showLastButton
          />
        </div>
      </TableContainer>
    </>
  );
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : "asc"}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.headerName}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row, requestUserSelect, updateConfirmAdmin, openConfirmDetail } =
    props;
  const classes = useRowStyles();

  return (
    <>
      <TableRow
        className={[classes.root, "text-truncate"].join(" ")}
        key={row.id}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.hasOwnProperty("renderCell")
              ? headCell.renderCell({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,
                  requestUserSelect: requestUserSelect,
                  updateConfirmAdmin: updateConfirmAdmin,
                  openConfirmDetail: openConfirmDetail,
                })
              : headCell.hasOwnProperty("valueGetter")
              ? headCell.valueGetter({
                  data: row[headCell.field],
                  currentData: row,
                  currentHeader: headCell.field,
                  headCells: headCells,

                  requestUserSelect: requestUserSelect,
                  updateConfirmAdmin: updateConfirmAdmin,
                  openConfirmDetail: openConfirmDetail,
                })
              : row[headCell.field]}
          </TableCell>
        ))}
      </TableRow>
      {/*
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={headCells.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세정보
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableBody>
                  <TourDetail
                    rowData={row}
                    setOpen={setOpen}
                    requestUserSelect={requestUserSelect}
                    updateConfirmAdmin={updateConfirmAdmin}
                    openConfirmDetail={openConfirmDetail}
                  />
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      */}
    </>
  );
}

const TourDetail = (props) => {
  const INC_TYPE = {
    F: "창작 활동을 위한 물품",
    K: "스포츠 장비",
    L: "야외 장비",
    M: "안전 장비",
    O: "카메라",
    P: "사진 촬영",
  };
  let { rowData, requestUserSelect, setOpen, replyRowData } = props;
  let parentData = { ...rowData };
  const [picArr, setPicArr] = useState([]);
  const [incArr, setIncArr] = useState([]);

  let $controller = {
    rl_use_yn: useRef(),
    rl_del_yn: useRef(),
  };

  useEffect(() => {
    if (parentData.id !== "") {
      for (let key in parentData) {
        if ($controller.hasOwnProperty(key)) {
          if ($controller[key].current) {
            $controller[key].current.value = parentData[key];
          }
        }
      }
    }

    picFunc();
    incFunc();
    return () => {};
  }, []);

  const picFunc = () => {
    let _picArr = [];

    if (rowData.rl_pic_main) {
      _picArr.push(rowData.rl_pic_main);
    }
    if (rowData.rl_pics) {
      let rl_pics = rowData.rl_pics.split("|");
      _picArr = [..._picArr, ...rl_pics];
    }

    setPicArr(_picArr);
  };

  const incFunc = () => {
    if (rowData.rl_inc_vehicle_list) {
      let arr = [];
      let item = rowData.rl_inc_vehicle_list.split("|");
      for (let i = 0; i < item.length; i++) {
        arr.push(INC_TYPE[item[i]]);
      }
      setIncArr(arr);
    }
  };

  const tourManageDataUpdate = () => {
    let updateData = {};
    for (let key in $controller) {
      if ($controller[key].current) {
        updateData[key] = $controller[key].current.value;
      }
    }
    updateData.rl_idx = parentData.rl_idx;

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_list_update.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    codeList.Modal.current.confirm("저장 하시겠습니까?", (ret) => {
      if (ret) {
        socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
          if (newData) {
            if (newData["ret"]) {
              codeList.Modal.current.alert(newData["MSG"]);
              requestUserSelect();
            } else {
              codeList.Modal.current.alert(
                "문제가 발생하였습니다. 관리자에게 문의 주세요."
              );
            }
          }
        });
      } else {
        codeList.Modal.current.alert("취소하였습니다.");
      }
    });
  };

  return (
    <tr className="container" id={parentData.id}>
      <td colSpan="12" className="bg-light">
        <div className="card mb-1">
          <div className="card-header">
            <div className="card-title font-weight-bold">투어 상세</div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">관리번호</h6>
                <p>{rowData.rl_idx}</p>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">여행 사진</h6>
                {picArr.length !== 0 ? (
                  picArr.map((item, index) => {
                    return (
                      <img
                        key={index}
                        src={codeList.publicVar.imgServerUrl + item}
                        style={{
                          height: "230px",
                          margin: "0 5px",
                          border: "1px solid black",
                        }}
                      />
                    );
                  })
                ) : (
                  <p>없음</p>
                )}
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">투어명</h6>
                <p className="font-weight-bold">{rowData.rl_title}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">분류</h6>
                <p>{TOUR_TYPE[rowData.rl_subject]}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">여행국가</h6>
                <p>{rowData.rl_area_cd}</p>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">가이드</h6>
                <p>{rowData.gl_name}</p>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">소개글</h6>
                <p>{rowData.rl_desc}</p>
              </div>
              <div className="col-12 col-xxl-3 mb-4">
                <hr />
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">최대인원</h6>
                <p>{rowData.rl_guest_max} 명</p>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">최소인원</h6>
                <p>{rowData.rl_guest_min} 명</p>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">유아동반유무</h6>
                <p>
                  {rowData.rl_can_child === "Y"
                    ? "가능"
                    : rowData.rl_can_child === "N"
                    ? "불가능"
                    : ""}
                </p>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">기본 요금</h6>
                <p>&#8361; {util.addCommas(rowData.rl_price_base)} 원</p>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">인당 추가 요금</h6>
                <p>&#8361; {util.addCommas(rowData.rl_price_per_people)} 원</p>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">투어 평점</h6>
                {/*<p>{guideData.gl_rating}</p>*/}
                <div className="rating" style={{ fontSize: "15px" }}>
                  {rowData.rl_rating > 0 ? (
                    <i className="fa-solid fa-star" />
                  ) : (
                    <i className="fa-regular fa-star" />
                  )}
                  {rowData.rl_rating > 1 ? (
                    <i className="fa-solid fa-star" />
                  ) : (
                    <i className="fa-regular fa-star" />
                  )}
                  {rowData.rl_rating > 2 ? (
                    <i className="fa-solid fa-star" />
                  ) : (
                    <i className="fa-regular fa-star" />
                  )}
                  {rowData.rl_rating > 3 ? (
                    <i className="fa-solid fa-star" />
                  ) : (
                    <i className="fa-regular fa-star" />
                  )}
                  {rowData.rl_rating > 4 ? (
                    <i className="fa-solid fa-star" />
                  ) : (
                    <i className="fa-regular fa-star" />
                  )}
                </div>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">이동차량 제공 유무</h6>
                <p>{rowData.rl_inc_vehicle === "Y" ? "제공" : "미제공"}</p>
              </div>
              <div className="col-4 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">지원 장비</h6>
                <p>{incArr && incArr.join(", ")}</p>
              </div>
              <div className="col-4 col-xxl-3 mb-4"></div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">사용 유무</h6>
                <select
                  className="form-control w-25"
                  ref={$controller.rl_use_yn}
                >
                  <option value="">선택</option>
                  <option value="Y">사용</option>
                  <option value="N">미사용</option>
                </select>
              </div>
              <div className="col-6 col-xxl-3 mb-4">
                <h6 className="font-weight-bold">삭제 여부</h6>
                <select
                  className="form-control w-25"
                  ref={$controller.rl_del_yn}
                >
                  <option value="">선택</option>
                  <option value="Y">삭제</option>
                  <option value="N">사용중</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex flex-row justify-content-end">
                <div
                  className="btn btn-primary"
                  onClick={() => {
                    tourManageDataUpdate();
                  }}
                >
                  저장
                </div>
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
};
