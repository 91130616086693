import Utilities from "./Utilities";
import axios from "axios";

export const MsgIDList = {
  EVENT_SET_FILTER: "msgid",
  EVENT_CHAT_PAGE_DETAIL_CHANGED: "EVENT_CHAT_PAGE_DETAIL_CHANGED", // chat 페이지 화면크기에 따른 예약 상세 컴포넌트 조정
  EVENT_CHAT_PAGE_MSG_LIST_CHANGED: "EVENT_CHAT_PAGE_MSG_LIST_CHANGED", // chat 페이지 화면크기에 따른 채팅 리스트 컴포넌트 조정
  EVENT_CHAT_PAGE_MSG_LIST_EVENT: "EVENT_CHAT_PAGE_MSG_LIST_EVENT", //chat 최신 메세지 업로드(ChatMainGuideMessage)
  EVENT_CHAT_PAGE_MSG_EVENT: "SendMessageToUser", //chat 최신 메세지 업로드(ChatMainConversation)
  EVENT_CHAT_PAGE_MSG_SEND_INFO: "EVENT_CHAT_PAGE_MSG_SEND_INFO", //chat 현재 메세지 정보
  EVENT_CHAT_PAGE_CHAT_USER_CHANGE: "EVENT_CHAT_PAGE_CHAT_USER_CHANGE", //채팅 상태 변경
  EVENT_SET_FILTER3: "USER_REGISTER3",
  EVENT_INIT_CODE_COUNTRY: "EVENT_INIT_CODE_COUNTRY",
  EVENT_INIT_CODE_LANGUAGE: "EVENT_INIT_CODE_LANGUAGE",
  EVENT_INIT_CODE_SUBJECT: "EVENT_INIT_CODE_SUBJECT",
  EVENT_INIT_CODE_HASHTAG: "EVENT_INIT_CODE_HASHTAG",
  EVENT_INIT_CODE_OFFER: "EVENT_INIT_CODE_OFFER",
  EVENT_LOGIN_AUTO_FAIL: "EVENT_LOGIN_AUTO_FAIL",
  EVENT_LOGIN_AUTO_SUCCESS: "EVENT_LOGIN_AUTO_SUCCESS",
  EVENT_AUTO_RECONNECTED: "EVENT_AUTO_RECONNECTED",
  EVENT_SBM_GUIDE_DATA_CHANGED: "EVENT_SBM_GUIDE_DATA_CHANGED",
  EVENT_SBM_HASHTAG_CHANGED: "EVENT_SBM_HASHTAG_CHANGED",
  EVENT_ROUTE_DETAIL_DATA: "EVENT_ROUTE_DETAIL_DATA",
  EVENT_GUIDE_DETAIL_DATA: "EVENT_GUIDE_DETAIL_DATA",
  EVENT_NAV_SEARCH_DATA_CHANGED: "EVENT_NAV_SEARCH_DATA_CHANGED",
  EVENT_MAIN_BEST_ROUTE_DATA_CHANGED: "EVENT_MAIN_BEST_ROUTE_DATA_CHANGED",
  EVENT_MAIN_PART_TIME_GUIDE_DATA_CHANGED:
    "EVENT_MAIN_PART_TIME_GUIDE_DATA_CHANGED",
  EVENT_RESERVATION_MAIN_SERVICE_DATA: "EVENT_RESERVATION_MAIN_SERVICE_DATA",
  EVENT_REGISTER_MAIN_CARD_DATA: "EVENT_REGISTER_MAIN_CARD_DATA",
  EVENT_RESERVATION_MAIN_PRICE_DATA: "EVENT_RESERVATION_MAIN_PRICE_DATA",
  EVENT_ACCOUNT_PERSONAL_USER_INFO: "EVENT_ACCOUNT_PERSONAL_USER_INFO",
  EVENT_GUIDE_MANAGEMENT_SERVICE_DATA: "EVENT_GUIDE_MANAGEMENT_SERVICE_DATA",
  EVENT_GUIDE_MANAGEMENT_SCHEDULE_DATA: "EVENT_GUIDE_MANAGEMENT_SCHEDULE_DATA",
  EVENT_GUIDE_MANAGEMENT_RESERVE_DATA: "EVENT_GUIDE_MANAGEMENT_RESERVE_DATA",
  EVENT_GUIDE_MANAGEMENT_RESERVE_DETAIL_MODAL_DATA:
    "EVENT_GUIDE_MANAGEMENT_RESERVE_DETAIL_MODAL_DATA",
  EVENT_LOADING_INDICATOR: "EVENT_LOADING_INDICATOR",

  EVENT_SEARCHMAP_CENTER_CAHNGED: "EVENT_SEARCHMAP_CENTER_CAHNGED",

  EVENT_GUIDE_REGIST_DATA_CHANGE: "EVENT_GUIDE_REGIST_DATA_CHANGE",
  EVENT_GUIDE_TOUR_REGIST_DATA_CHANGE: "EVENT_GUIDE_TOUR_REGIST_DATA_CHANGE",

  EVENT_ERP_PAGE_CHANGE_REMOTE: "EVENT_ERP_PAGE_CHANGE_REMOTE",
  EVENT_ERP_PAGE_CHANGED: "EVENT_ERP_PAGE_CHANGED",

  EVENT_FAQ_MANAGE_FILTER_CHANGED: "EVENT_FAQ_MANAGE_FILTER_CHANGED",
  EVENT_FAQ_MANAGE_DATA_UPDATED: "EVENT_FAQ_MANAGE_DATA_UPDATED",
  EVENT_FAQ_MANAGE_EDIT_MODAL_CALL: "EVENT_FAQ_MANAGE_EDIT_MODAL_CALL",

  EVENT_GUIDE_MANAGE_FILTER_CHANGED: "EVENT_GUIDE_MANAGE_FILTER_CHANGED",

  EVENT_RESERVATION_MANAGE_FILTER_CHANGED:
    "EVENT_RESERVATION_MANAGE_FILTER_CHANGED",

  EVENT_PRODUCT_MANAGE_FILTER_CHANGED: "EVENT_PRODUCT_MANAGE_FILTER_CHANGED",

  EVENT_PARTTIME_MANAGE_FILTER_CHANGED: "EVENT_PARTTIME_MANAGE_FILTER_CHANGED",

  EVENT_TOUR_MANAGE_FILTER_CHANGED: "EVENT_TOUR_MANAGE_FILTER_CHANGED",

  EVENT_SOCKET_DATA_ERROR: "EVENT_SOCKET_DATA_ERROR",

  EVENT_GUIDE_DETAIL_PIC_MODAL_CALL: "EVENT_GUIDE_DETAIL_PIC_MODAL_CALL",

  //  --------------------------
  EVENT_TOUR_DETAIL_REQUEST_RESERVE: "EVENT_TOUR_DETAIL_REQUEST_RESERVE",

  // ------------
  EVENT_MY_PAGE_REVIEW_DATA_CHANED: "EVENT_MY_PAGE_REVIEW_DATA_CHANED",

  //----- ERP 새소식 관리 -----
  EVENT_COMMUNITY_NEW_MANAGE_EDIT_MODAL_CALL:
    "EVENT_COMMUNITY_NEW_MANAGE_EDIT_MODAL_CALL",

  EVENT_COMMUNITY_MANAGE_FILTER_CHANGED:
    "EVENT_COMMUNITY_MANAGE_FILTER_CHANGED",
  EVENT_COMMUNITY_MANAGE_EDIT_MODAL_CALL:
    "EVENT_COMMUNITY_MANAGE_EDIT_MODAL_CALL",
  //   채팅
  EVENT_CHAT_MESSAGE_SEND: "EVENT_CHAT_MESSAGE_SEND",
  EVENT_CHAT_REQUEST_BEFORE_MESSAGE: "EVENT_CHAT_REQUEST_BEFORE_MESSAGE",
};

let isDebug = false;

export default class BuddibleSocket {
  constructor() {
    if (BuddibleSocket.exists) {
      return BuddibleSocket.instance;
    }
    this._socketServer = null;
    this._eventListener = {};
    this._localEventListener = {};
    this._instanceEventListener = {};
    this._Dataset = {};
    this._localDataset = new Map();
    this._connectWaitingList = [];
    this._util = Utilities;
    this._isConnected = false;
    this._isReconnect = false;
    BuddibleSocket.instance = this;
    BuddibleSocket.exists = true;
    this.dataDserverUrl = "https://play.gbts.co.kr/backside";
    return this;
  }

  //change socket ref
  changeServer = (str) => {
    this._socketServer = str;
  };

  isConnected = () => {
    return this._isConnected;
  };

  addLocalEventListener = (msgID, className, callback) => {
    //isDebug && console.log('addLocalEventListener', className);
    let listeners = [];
    if (this._localEventListener[msgID]) {
      let isFind = false;
      for (let i = 0; i < this._localEventListener[msgID].length; i++) {
        if (this._localEventListener[msgID][i].className === className) {
          isFind = true;
          this._localEventListener[msgID][i].callback = callback;
        }
      }
      if (isFind === false) {
        listeners = [
          ...this._localEventListener[msgID],
          {
            className: className,
            callback: callback,
          },
        ];
        this._localEventListener = {
          ...this._localEventListener,
          [msgID]: listeners,
        };
      }
    } else {
      listeners = [
        {
          className: className,
          callback: callback,
        },
      ];
      this._localEventListener = {
        ...this._localEventListener,
        [msgID]: listeners,
      };
    }

    //isDebug && console.log('_localEventListener',this._localEventListener);
  };

  removeLocalEventListener = (msgID, className) => {
    //isDebug && console.log('removeLocalEventListener',this._localEventListener);
    if (Array.isArray(this._localEventListener[msgID]))
      this._localEventListener[msgID] = this._localEventListener[msgID].filter(
        (item) => item.className !== className
      );
  };

  //send message to server
  sendLocalMessage = (msgID, className, data, callback) => {
    this.fireLocalEventListener(msgID, className, data, callback);
    //isDebug && console.log(e);
  };

  fireLocalEventListener = (msgID, className, newData, callback) => {
    let beforeData = this._localDataset.get(msgID);
    this._localDataset.set(msgID, newData);
    //isDebug && console.log(this._localEventListener,msgID,beforeData,newData);
    if (this._localEventListener.hasOwnProperty(msgID)) {
      //isDebug && console.log('noneListener in normal');

      if (this._localEventListener[msgID]) {
        for (let i = 0; i < this._localEventListener[msgID].length; i++) {
          try {
            if (this._localEventListener[msgID][i].className !== className)
              this._localEventListener[msgID][i].callback(beforeData, newData);
          } catch (e) {}
        }
      }
    }
  };

  getLocalDataSet = (msgID) => {
    return this._localDataset.get(msgID);
  };

  //send message to server
  sendMessage = (e, msgID, callback) => {
    /*    axios
      .post("https://msgserver.buddible.com:3100/api", e)
      .then(function (response) {
        console.log("axios");
        console.log(response);
        let message = JSON.stringify(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    return;*/

    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    isDebug && console.log("sendMessage", e);
    let message = JSON.stringify(e);
    this._socketServer.sendMessage(message);
    this.writeLog();
  };

  handleData = (data) => {
    let result = JSON.parse(data);
    isDebug && console.log("socket handleData", result);
    if (result.method === MsgIDList.EVENT_CHAT_PAGE_MSG_EVENT) {
      this.fireLocalEventListener(
        result.method,
        this._Dataset[result.msgId],
        result
      );
    }
    this.fireEventListener(
      result.msgId ? result.msgId : result.socketMsgID,
      this._Dataset[result.msgId],
      result,
      () => {}
    );
  };

  addEventListener = (msgID, className, callback) => {
    if (!this._eventListener[msgID]) {
      this._eventListener = {
        ...this._eventListener,
        [msgID]: [
          {
            className: className,
            callback: callback,
          },
        ],
      };
    } else {
      this._eventListener[msgID] = [
        ...this._eventListener[msgID],
        {
          className: className,
          callback: callback,
        },
      ];
    }
    isDebug && console.log("_eventListener", this._eventListener);
  };

  addInstanceEventListener = (msgID, callback) => {
    if (!this._instanceEventListener[msgID]) {
      isDebug && console.log("no listener", this._instanceEventListener);
      this._instanceEventListener = {
        ...this._instanceEventListener,
        [msgID]: [
          {
            callback: callback,
          },
        ],
      };
      isDebug && console.log("no listener2", this._instanceEventListener);
    } else {
      isDebug && console.log("yes listener", this._instanceEventListener);
      this._instanceEventListener[msgID] = [
        ...this._instanceEventListener[msgID],
        {
          _instanceEventListener: callback,
        },
      ];
      isDebug && console.log("yes listener2", this._instanceEventListener);
    }
  };

  removeInstanceEventListener = (msgID) => {
    isDebug && console.log("removeInstanceEventListener", msgID);
    delete this._instanceEventListener[msgID];
  };

  removeEventListener = (msgID, className) => {
    this._eventListener[msgID] = this._eventListener[msgID].filter(
      (item) => item.className !== className
    );
  };

  fireEventListener = (msgID, beforeData, newData, callback) => {
    //console.log('fireEventListener msgID',msgID);
    //console.log(this._eventListener);
    // eslint-disable-next-line array-callback-return
    if (!this._eventListener[msgID]) {
      //console.log('noneListener in normal');
      //console.log(this._instanceEventListener);
      if (this._instanceEventListener[msgID]) {
        this._instanceEventListener[msgID].map((e) => {
          //console.log('fired event to instance event listener');
          try {
            e.callback(beforeData, newData);
          } catch (e) {}

          if (newData.socketMsgID) {
            this.removeInstanceEventListener(newData.socketMsgID);
          }
          return true;
        });
      }
    } else {
      //console.log('hihihi');
      this._eventListener[msgID].forEach((e) => {
        try {
          e.callback(beforeData, newData);
        } catch (e) {}
      });
      this._Dataset[msgID] = newData;
    }
  };

  writeLog = () => {
    /*
        this._socketServer = null;
        this._eventListener = {};
        this._instanceEventListener = {};
        this._Dataset = {};
         */
    if (isDebug) {
      console.log("_socketServer", this._socketServer);
      console.log("_eventListener", this._eventListener);
      console.log("_instanceEventListener", this._instanceEventListener);
      console.log("_Dataset", this._Dataset);
    }
  };

  waitingForConnect(className, callback) {
    if (this._isConnected) callback(true);

    this._connectWaitingList.push({ className: className, callback: callback });
  }

  responseWaitingForConnect() {
    for (let i = 0; i < this._connectWaitingList.length; i++) {
      let listItem = this._connectWaitingList[i];
      if (listItem.hasOwnProperty("callback")) listItem["callback"](true);
    }
    this._connectWaitingList = [];
  }

  handleOpen = () => {
    this._isConnected = true;
    if (!this._isReconnect) {
      this._isReconnect = true;
    } else {
      this.sendLocalMessage(
        MsgIDList.EVENT_AUTO_RECONNECTED,
        "BuddibleSocket",
        {},
        () => {}
      );
    }
    this.responseWaitingForConnect();
    isDebug && console.log("connected:)");
  };
  handleClose = () => {
    this._isConnected = false;
    isDebug && console.log("disconnected:(");
  };

  //send message to server
  sendMessageToDB = (e, msgID, callback) => {
    if (msgID !== undefined && callback !== undefined) {
      this.addInstanceEventListener(msgID, callback);
      e.socketMsgID = msgID;
    }
    //isDebug && console.log("sendMessage", e);
    //let message = JSON.stringify(e);
    //this._socketServer.sendMessage(message);
    axios
      .post(
        this.dataDserverUrl + e.file,
        {
          message: e,
        },
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((response) => {
        //console.log("axios handleData");
        // isDebug && console.log("axios handleData", response.data);
        // isDebug && console.log(this._instanceEventListener);
        let result = response.data;
        this.fireEventListener(
          result.msgId ? result.msgId : result.socketMsgID,
          this._Dataset[result.msgId],
          result,
          () => {}
        );
      })
      .catch((response) => {
        console.log("Error!", response);

        //alert("데이터 통신중 오류가 발생하였습니다. 관리자에게 문의 바랍니다.");
        this.fireEventListener(
          MsgIDList.EVENT_SOCKET_DATA_ERROR,
          {},
          {},
          () => {}
        );
      });

    this.writeLog();
  };
}
