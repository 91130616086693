import React, { useEffect, useRef, useState } from "react";
import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import TourManageTable from "./TourManageTable";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "TourManage";
const TOUR_TYPE = [
  { key: "BEC", desc: "해변 휴양" },
  { key: "BEV", desc: "야간 술집" },
  { key: "ETM", desc: "오락 활동" },
  { key: "NAT", desc: "자연·환경 관광" },
  { key: "NMK", desc: "야간 시장" },
  { key: "NVW", desc: "야간 풍경" },
  { key: "OAT", desc: "야외 활동" },
  { key: "RES", desc: "맛집 휴양" },
  { key: "SHO", desc: "쇼핑 관광" },
  { key: "SOC", desc: "사회·문화 관광" },
  { key: "SPO", desc: "스포츠 활동" },
];

const SEARCH_KEYWORDS = [
  { key: "rl_title", desc: "제목" },
  { key: "gl_name", desc: "가이드명" },
];

let cnt = 0;
let status_list = [
  { key: "W", count: 0, status: "작성중", color: "secondary" },
  { key: "S", count: 0, status: "제출", color: "primary" },
  { key: "Y", count: 0, status: "승인", color: "success" },
  { key: "N", count: 0, status: "거부", color: "danger" },
  { key: "C", count: 0, status: "취소(가이드)", color: "warning" },
];

export default function TourManage(props) {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  let $searchText = useRef();
  let $searchLevel = useRef();

  useEffect(() => {
    requestStatusTotal((newData) => {
      if (newData["returnData"].length < 1) return;
      let data = newData["returnData"][0];
      cnt = Number(data["A"]);
      for (let i = 0; i < status_list.length; i++) {
        status_list[i].count = Number(data[status_list[i].key]);
      }
      setIsNeedUpdate(!isNeedUpdate);
    });
  }, []);
  const requestStatusTotal = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_confirm_status_total_select.php",
      msgID: msgID,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  const searchFilterChanged = (e, data) => {
    let filters = {
      SEARCH_WORD: $searchText.current.value,
      SEARCH_LEVEL: $searchLevel.current.value,
    };
    if (data) {
      filters = {
        ...filters,
        ...data,
      };
    }

    socket.sendLocalMessage(
      MsgIDList.EVENT_TOUR_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <>
      <div className="container-fluid">
        <div className="content">
          <div className="container-florder_uid p-0">
            <div className="row ">
              <div className="col">
                <div className="card">
                  <div className="card-header">
                    {/*필터 영역*/}
                    <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                      {/*간편기간 영역*/}

                      <div
                        className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                        role="toolbar"
                      >
                        <div
                          className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                          role="toolbar"
                        >
                          <div
                            className="btn-group mr-2 order-2 order-md-2 order-lg-1 mb-2 bm-sm-0"
                            role="group"
                            aria-label="status_total"
                          >
                            <button
                              className={`btn btn-sm btn-outline-primary`}
                              onClick={(e) => {
                                searchFilterChanged(e, {
                                  rl_confirm_admin: "",
                                });
                              }}
                            >
                              전체{" "}
                              <span className="font-weight-bold">
                                {util.addCommas(cnt)}
                              </span>
                              건
                            </button>
                            {status_list.map((item) => {
                              return (
                                <button
                                  key={item.key}
                                  className={`btn btn-sm btn-outline-primary`}
                                  onClick={(e) => {
                                    searchFilterChanged(e, {
                                      rl_confirm_admin: item.key,
                                    });
                                  }}
                                >
                                  {item.status}{" "}
                                  <span className={`font-weight-bold`}>
                                    {util.addCommas(item.count)}
                                  </span>{" "}
                                  건
                                </button>
                              );
                            })}
                          </div>
                        </div>

                        <div className="d-flex flex-row">
                          <select
                            className="custom-select form-control mr-2"
                            style={{ width: 200 }}
                            onChange={(e) => {
                              searchFilterChanged(e, {
                                rl_subject: e.target.value,
                              });
                            }}
                          >
                            <option value="">여행주제</option>
                            {TOUR_TYPE.map((menu) => {
                              return (
                                <option key={menu.key} value={menu.key}>
                                  {menu.desc}
                                </option>
                              );
                            })}
                          </select>

                          <div>
                            <div className="input-group order-1 order-md-1 order-lg-1  mb-2 bm-sm-0">
                              <select
                                className="custom-select form-control"
                                ref={$searchLevel}
                                onChange={(e) => searchFilterChanged(e)}
                                style={{ minWidth: 150 }}
                              >
                                <option value="">키워드</option>
                                {SEARCH_KEYWORDS.map((menu, index) => {
                                  return (
                                    <option key={index} value={menu.key}>
                                      {menu.desc}
                                    </option>
                                  );
                                })}
                              </select>

                              <input
                                type="text"
                                className="form-control"
                                placeholder="검색어"
                                aria-label="검색어"
                                aria-describedby="btnGroupAddon2"
                                onKeyPress={(e) => {
                                  if (e.charCode === 13) {
                                    searchFilterChanged(e);
                                  }
                                }}
                                ref={$searchText}
                              />

                              <button
                                className="input-group-text btn btn-primary"
                                style={{
                                  borderBottomLeftRadius: 0,
                                  borderTopLeftRadius: 0,
                                  borderLeft: 0,
                                }}
                                onClick={(e) => searchFilterChanged(e)}
                              >
                                <i className="fas fa-search mr-2" />
                                검색
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </h6>
                  </div>
                  <TourManageTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
