/*global google*/

import React, { useState } from "react";
import GoogleMap from "google-map-react";
import { useTranslation } from "react-i18next";

let zoom = 18;
let point;
let myLatLng;

let apiReady = false;

let mapApi = {
  map: null,
  googleMaps: null,
  places: [],
  center: {
    lat: 0,
    lng: 0,
  },
};

export default function GuideDetailLocation(props) {
  const { guideData } = props;
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  let center = {
    lat: parseFloat(guideData.gl_addr_lat),
    lng: parseFloat(guideData.gl_addr_log),
  };

  //지도 api 로드
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      mapApi = { ...mapApi, map: map, googleMaps: maps };
      apiReady = true;
    }
    if (center.lat === "" || center.lng === "") center = point;
    let marker = new maps.Marker({
      position: center,
      map,
    });
    mapApi.map.setCenter(center);
    setIsNeedUpdate(!isNeedUpdate);
    return marker;
  };

  return (
    <>
      {guideData.gl_addr_lat && (
        <div className="container margin_60 border-bottom">
          <p className="h5">{t("guideDetailLocationTitle")}</p>
          <div
            className="googleMap w-100 rounded border"
            style={{ height: 400 }}
          >
            <GoogleMap
              bootstrapURLKeys={{
                key: "AIzaSyDKXA_Dj4ZejIXaFA7I_p9Ry9o7c8s4QZQ",
                libraries: "places",
              }}
              defaultZoom={zoom}
              defaultCenter={center}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
            />
          </div>
        </div>
      )}
    </>
  );
}
