import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useMemo,
} from "react";
import $ from "jquery";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuidePicSocialCheckModal";
function GuidePicSocialCheckModal(props, ref) {
  const { data = {}, loginInfo } = props;
  const [pic1, setPic1] = useState("");
  const [pic2, setPic2] = useState("");

  // 신분증 사진 요청
  const requestGuideSocialPicture = (e, data) => {
    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/guide/JS_getGuideSocialPicWithAdmin.php",
      msgID: msgID,
      data: {},
      u_Auth: loginInfo.u_Auth,
      gl_guide_uid: data.currentData.gl_guide_uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          if (newData["returnData"].length === 0) {
            codeList.Modal.current.alert(
              "오류가 발생했습니다. 다시 시도해주세요"
            );
            return;
          }
          setPic1(
            util.changedResourceUrl(newData["returnData"][0]["gl_pic_social1"])
          );
          setPic2(
            util.changedResourceUrl(newData["returnData"][0]["gl_pic_social2"])
          );
        } else {
          codeList.Modal.current.alert(newData["MSG"], () => {
            closeModal();
          });
        }
      }
    });
  };

  useImperativeHandle(ref, () => ({
    openModal(e, params) {
      requestGuideSocialPicture(e, params);
    },
  }));

  const closeModal = () => {
    setPic1("");
    setPic2("");
    $("#GuidePicSocialCheckModalClose").click();
  };

  return (
    <>
      <div
        className="modal fade"
        id="GuidePicSocialCheckModal"
        data-backdrop="static"
        data-keyboard="false"
        aria-hidden="true"
        aria-labelledby="GuidePicSocialCheckModal"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h3 className="modal-title" id="staticBackdropLabel">
                <strong>신분증 검토</strong>
              </h3>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                aria-label="Close"
                id="GuidePicSocialCheckModalClose"
                onClick={() => closeModal()}
              >
                <i className="fas fa-plus fa-times" />
              </button>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-6">
                  <div
                    className="w-100 min-height-300 rounded"
                    style={{
                      background: `url(${pic1}) 50% 50%/cover no-repeat`,
                    }}
                  />
                </div>
                <div className="col-6">
                  <div
                    className="w-100 min-height-300 rounded"
                    style={{
                      background: `url(${pic2}) 50% 50%/cover no-repeat`,
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                id="btnCloseModal"
                onClick={closeModal}
              >
                <i className="fa fa-times mr-2" />
                닫기
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.forwardRef(GuidePicSocialCheckModal);
