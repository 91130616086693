import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function FAQSideWidget(props) {
  const { t } = useTranslation();
  let category = props.params.faqType;
  const [isActive, setIsActive] = useState({
    FAQ01: "active",
    FAQ02: "",
    FAQ03: "",
    FAQ04: "",
    FAQ05: "",
  });

  useEffect(() => {
    ActiveMenuSet();
  }, [props]);

  //활성화 메뉴 설정
  const ActiveMenuSet = () => {
    let menu = { ...isActive };
    if (category) {
      for (let key in menu) {
        if (key === category) {
          menu[key] = "active";
        } else {
          menu[key] = "";
        }
      }
    } else {
      menu = {
        FAQ01: "active",
        FAQ02: "",
        FAQ03: "",
        FAQ04: "",
        FAQ05: "",
      };
    }
    setIsActive(menu);
  };

  return (
    <aside className="col-lg-3">
      <div className="box_style_1 p-0">
        <ul id="cat_nav">
          <li>
            <Link to="/FAQ/FAQ01" id={isActive.FAQ01}>
              <i className="xi-lightbulb-o"></i>
              {t("faqTitle1")}
            </Link>
          </li>
          <li>
            <Link to="/FAQ/FAQ02" id={isActive.FAQ02}>
              <i className="xi-user-o"></i>
              {t("faqTitle2")}
            </Link>
          </li>
          <li>
            <Link to="/FAQ/FAQ03" id={isActive.FAQ03}>
              <i className="xi-flight-takeoff"></i>
              {t("faqTitle3")}
            </Link>
          </li>
          <li>
            <Link to="/FAQ/FAQ04" id={isActive.FAQ04}>
              <i className="xi-emoticon-sad-o"></i>
              {t("faqTitle4")}
            </Link>
          </li>
          <li>
            <Link to="/FAQ/FAQ05" id={isActive.FAQ05}>
              <i className="xi-error-o"></i>
              {t("faqTitle5")}
            </Link>
          </li>
        </ul>
      </div>

      <div className="box_style_1 p-0">
        <div className="box_style_2" style={{ border: "none" }}>
          <i className="icon_set_1_icon-57"></i>
          <h4>{t("customerCenter")}</h4>
          <a href="tel://032-747-0211" className="phone">
            032-747-0211
          </a>
          <small>
            {t("mon")}-{t("fri")} 9.00am - 6.00pm
          </small>
        </div>
      </div>
    </aside>
  );
}
