import React, { useEffect, useState } from "react";
import BuddibleSocket, {
  MsgIDList,
} from "../../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../../lib/Utilities";
import CodeList from "../../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let ExtraPersonDeadline = [
  { id: 1, tit: "시작 시간 1시간전" },
  { id: 2, tit: "시작 시간 2시간전" },
  { id: 3, tit: "시작 시간 3시간전" },
  { id: 4, tit: "시작 시간 4시간전" },
  { id: 5, tit: "시작 시간 5시간전" },
  { id: 6, tit: "시작 시간 6시간전" },
  { id: 7, tit: "시작 시간 7시간전" },
  { id: 8, tit: "시작 시간 8시간전" },
  { id: 10, tit: "시작 시간 10시간전" },
  { id: 12, tit: "시작 시간 12시간전" },
  { id: 18, tit: "시작 시간 18시간전" },
  { id: 24, tit: "시작 시간 1일 전" },
  { id: 48, tit: "시작 시간 2일 전" },
  { id: 72, tit: "시작 시간 3일 전" },
  { id: 96, tit: "시작 시간 4일 전" },
  { id: 120, tit: "시작 시간 5일 전" },
  { id: 144, tit: "시작 시간 6일 전" },
  { id: 168, tit: "시작 시간 일주일 전" },
];

let reserve_data = {
  rl_reservation_people_time: 0, // 추가 인원에 대한 예약 마감 시간
  rl_reservation_time: 0, // 첫번째게스트 마감시간
  rl_book_allow_otherday_yn: "N", //예약 가능 여부에 대한 요청
};

export default function GuideTourRegistSettingReserve(props) {
  let { tourData, loginInfo, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (tourData) {
      for (let key in reserve_data) {
        reserve_data[key] = tourData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (key, val) => {
    reserve_data[key] = val;
    setIsNeedUpdate(!isNeedUpdate);
    guideTourRegistDataChange(reserve_data);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <div className="mb-3">
          <h6 className="font-weight-bold mb-2">추가인원 승인여부</h6>
          <p className="mb-3">
            가이드의 개별 회차에 이미 예약이 있는 경우, 이 회차에 참여할 다른
            게스트가 여행 시작 얼마 전까지 예약할 수 있는지 설정하는 것입니다.
          </p>

          <div className="d-flex flex-row">
            <label className="mr-3">
              <input
                disabled={tourData.rl_confirm_admin === "S"}
                type="radio"
                value="Y"
                name={"rl_book_allow_otherday_yn"}
                checked={reserve_data.rl_book_allow_otherday_yn === "Y"}
                onChange={(e) => {
                  handleTourDataChange(
                    "rl_book_allow_otherday_yn",
                    e.target.value
                  );
                }}
              />
              허용
            </label>
            <label className="mr-3">
              <input
                type="radio"
                value="N"
                name={"rl_book_allow_otherday_yn"}
                disabled={tourData.rl_confirm_admin === "S"}
                checked={reserve_data.rl_book_allow_otherday_yn === "N"}
                onChange={(e) => {
                  reserve_data.rl_reservation_people_time = 0;
                  handleTourDataChange(
                    "rl_book_allow_otherday_yn",
                    e.target.value
                  );
                }}
              />
              비허용
            </label>
          </div>
        </div>
        <h6 className="font-weight-bold mb-2">
          추가 인원에 대한 예약 마감 시간
        </h6>

        <select
          className="form-control"
          value={reserve_data.rl_reservation_people_time}
          disabled={
            tourData.rl_confirm_admin === "S" ||
            reserve_data.rl_book_allow_otherday_yn === "N"
          }
          onChange={(e) => {
            handleTourDataChange("rl_reservation_people_time", e.target.value);
          }}
        >
          <option value={0}>추가 인원 마감시간</option>
          {ExtraPersonDeadline.map((data) => {
            return (
              <option key={data.id} id={data.id} value={data.id}>
                {data.tit}
              </option>
            );
          })}
        </select>

        <div className="mt-3 d-flex flex-row justify-content-start align-items-centers">
          <div className="h3 mr-2">
            <i className="fas fa-chart-line" />
          </div>
          <p className="flex-fil">
            가이드의 진행 시간과 활동의 성격을 고려하여, 예약 마감 시한을 여행
            시작 1시간 전으로 설정하실 것을 권해드립니다. 나중에 언제든지
            변경하실 수 있습니다.
          </p>
        </div>
      </div>
    </div>
  );
}
