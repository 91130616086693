import React, { useEffect, useRef, useState } from "react";
import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import ReservationManageTable from "./ReservationManageTable";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const SERVICE_TYPE = [
  { key: "rl_title", desc: "서비스명" },
  { key: "u_name_full", desc: "예약자명" },
];
const RESERVE_TYPE = [
  { key: "CR", desc: "요청" },
  { key: "GA", desc: "승인" },
  { key: "CC", desc: "취소" },
  { key: "GC", desc: "거절" },
];

const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};

export default function ReservationManage(props) {
  let tempDate = new Date();
  tempDate.setDate(new Date().getDate() - new Date().getDay());
  const [searchStartDate, setSearchStartDate] = useState(
    util.getDateToFormat(tempDate, "YYYY-MM-DD")
  );

  const [searchEndDate, setSearchEndDate] = useState(
    util.todayDate("YYYY-MM-DD")
  );

  const dateBtnList = [
    { id: "week", desc: "이번주", value: DateRangeList.RANGE_THIS_WEEK },
    { id: "month", desc: "이번달", value: DateRangeList.RANGE_THIS_MONTH },
    { id: "3month", desc: "3개월", value: DateRangeList.RANGE_THREE_MONTH },
    { id: "6month", desc: "6개월", value: DateRangeList.RANGE_SIX_MONTH },
    { id: "year", desc: "1년", value: DateRangeList.RANGE_ONE_YEAR },
  ];

  const $controller = {
    searchText: useRef(),
    searchService: useRef(),
    searchReserve: useRef(),
    searchStartDate: useRef(),
    searchEndDate: useRef(),
    searchHistoryStartDate: useRef(),
    searchHistoryEndDate: useRef(),
  };

  const changeDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    setSearchStartDate(util.getDateToFormat(startDate, "YYYY-MM-DD"));
    setSearchEndDate(util.getDateToFormat(endDate, "YYYY-MM-DD"));
    $controller.searchStartDate.current.value = util.getDateToFormat(
      startDate,
      "YYYY-MM-DD"
    );
    $controller.searchEndDate.current.value = util.getDateToFormat(
      endDate,
      "YYYY-MM-DD"
    );
    searchFilterChanged();
  };

  const searchFilterChanged = (e) => {
    const filters = {
      SEARCH_WORD: $controller.searchText.current.value,
      // SEARCH_LEVEL: $controller.searchState.current.value,
      searchStartDate: $controller.searchStartDate.current.value,
      searchEndDate: $controller.searchEndDate.current.value,
      searchService: $controller.searchService.current.value,
      SEARCH_LEVEL: $controller.searchReserve.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
      "ReservationManage",
      filters,
      () => {}
    );
  };

  const searchWordClear = () => {
    $controller.searchText.current.value = "";
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {/*필터 영역*/}
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                {/*간편기간 영역*/}

                <div
                  className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                  role="toolbar"
                >
                  <div className="btn-group mt-2" role="group">
                    {dateBtnList.map((btn, index) => {
                      return (
                        <button
                          key={index}
                          className="btn  btn-outline-info"
                          data-range={btn.value}
                          onClick={changeDatePicker}
                        >
                          {btn.desc}
                        </button>
                      );
                    })}
                  </div>

                  <div className="d-flex flex-row justify-content-end flex-wrap">
                    <div className="input-group mt-2 mr-0 mr-md-2">
                      <input
                        className="form-control"
                        type="date"
                        value={searchStartDate}
                        ref={$controller.searchStartDate}
                        onChange={(e) => {
                          setSearchStartDate(e.target.value);
                          if (e.target.value > searchEndDate) {
                            setSearchEndDate(e.target.value);
                          }
                          searchFilterChanged();
                        }}
                      />
                      <span className="input-group-append">
                        <div className="input-group-text">-</div>
                      </span>
                      <input
                        className="form-control"
                        type="date"
                        value={searchEndDate}
                        ref={$controller.searchEndDate}
                        onChange={(e) => {
                          setSearchEndDate(e.target.value);
                          if (e.target.value < searchStartDate) {
                            setSearchStartDate(e.target.value);
                          }
                          searchFilterChanged();
                        }}
                      />
                    </div>
                    <div className="input-group order-1 order-md-1 order-lg-1 mt-2">
                      <select
                        className="custom-select form-control"
                        ref={$controller.searchReserve}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">분류</option>
                        {RESERVE_TYPE.map((menu, index) => {
                          return (
                            <option key={index} value={menu.key}>
                              {menu.desc}
                            </option>
                          );
                        })}
                      </select>

                      <select
                        className="custom-select form-control"
                        ref={$controller.searchService}
                        style={{ minWidth: 150 }}
                      >
                        {SERVICE_TYPE.map((menu, index) => {
                          return (
                            <option key={index} value={menu.key}>
                              {menu.desc}
                            </option>
                          );
                        })}
                      </select>

                      <input
                        ref={$controller.searchText}
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          // console.log(e);
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        onClick={() => {
                          searchWordClear();
                        }}
                      />

                      <button
                        className="input-group-text btn btn-primary"
                        style={{
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                          borderLeft: 0,
                        }}
                        onClick={() => {
                          searchFilterChanged();
                        }}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <ReservationManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
