import React, { useCallback, useEffect, useState } from "react";
import "./css/ruang-admin.css";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CommonSlideNavbar from "./CommonSlideNavbar";
import CommonHeader from "./CommonHeader";
import GuideManage from "./pages/guideManage/GuideManage";
import TourManage from "./pages/tourManage/TourManage";
import FAQManage from "./pages/faqManage/FAQManage";
import ReservationManage from "./pages/reservationManage/ReservationManage";

import CommunityManage from "./pages/communityManage/CommunityManage";

const socket = new BuddibleSocket();
const _mClassName = "ERP";

const pages = [
  { name: "type", title: "예약 관리", uLevel: 0 },
  {
    name: "ReservationManage",
    title: "예약 현황",
    page: (props) => <ReservationManage {...props} />,
    uLevel: 0,
  },
  { name: "type", title: "요청 관리", uLevel: 0 },
  {
    name: "GuideManage",
    title: "버디 등록관리",
    page: (props) => <GuideManage {...props} />,
    uLevel: 0,
  },
  {
    name: "TourManage",
    title: "투어 등록관리",
    page: (props) => <TourManage {...props} />,
    uLevel: 0,
  },
  // {
  //   name: "ParttimeManage",
  //   title: "시간제 가이드 관리",
  //   page: (props)=><ParttimeManage {...props} />,
  //   uLevel: 0,
  // },
  // { name: "type", title: "등록상품 관리", uLevel: 0 },
  // {
  //   name: "ProductManage",
  //   title: "등록상품 관리",
  //   page: (props)=><ProductManage {...props} />,
  //   uLevel: 0,
  // },
  { name: "type", title: "게시글 관리", uLevel: 0 },
  {
    name: "CommunityManage",
    title: "게시글 관리",
    page: (props) => <CommunityManage {...props} />,
    uLevel: 0,
  },
  { name: "type", title: "FAQ 관리", uLevel: 0 },
  {
    name: "FAQManage",
    title: "FAQ 관리",
    page: (props) => <FAQManage {...props} />,
    uLevel: 0,
  },
];
let pageOpen = {
  // 예약 관리
  ReservationManage: true,
  // 요청관리
  GuideManage: false,
  TourManage: false,
  ParttimeManage: false,
  // 게시글관리
  CommunityManage: false,
  // FAQ 관리
  FAQManage: false,
};
let loginInfo = null;

export default function ERP({ history, match }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    const userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      if (Number(userData.u_Auth) < 8) {
        alert("관리자만 접근 가능한 서비스입니다.");
        window.location.replace("/");
      } else {
        loginInfo = userData;
        setIsLoading(false);
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (b, n) => {
        if (n) {
          console.log(n);
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (n) {
          loginInfo = n;
          setIsLoading(false);
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_ERP_PAGE_CHANGE_REMOTE,
      _mClassName,
      (beforeData, newData) => {
        let pageName = newData.pageName;

        let newPages = { ...pageOpen };
        for (let key in pageOpen) {
          newPages[key] = key === pageName;
        }
        pageOpen = newPages;
        setIsNeedUpdate(!isNeedUpdate);

        socket.sendLocalMessage(
          MsgIDList.EVENT_ERP_PAGE_CHANGED,
          _mClassName,
          { pageName: pageName },
          () => {}
        );
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_ERP_PAGE_CHANGE_REMOTE,
        _mClassName
      );
    };
  }, []);

  const changedPage = (name, callback) => {
    let pageList = { ...pageOpen };
    for (let key in pageList) {
      pageList[key] = key === name;
    }
    pageOpen = pageList;
    setIsNeedUpdate(!isNeedUpdate);

    if (callback) {
      callback();
    }
  };

  const MappedComponent = useCallback((props) => {
    return <MappedComponentMemo {...props} />;
  }, []);

  const MappedComponentMemo = React.memo((props) => {
    const { p, index, pageOpen } = props;
    return (
      <div
        style={!pageOpen[p.name] ? { display: "none" } : { display: "" }}
        className="main"
      >
        {p.page(props)}
      </div>
    );
  });

  if (isLoading && loginInfo === null) return <></>;

  const renderPages = pages.filter(
    (item) => item.name !== "type" && item.uLevel < Number(loginInfo.u_Auth)
  );

  return (
    <div id="wrapper" className="wrapper erp">
      {/*<AutoLogin />*/}

      <CommonSlideNavbar
        changedPage={changedPage}
        pages={pages}
        pageOpen={pageOpen}
      />

      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <CommonHeader />
          {renderPages.map((p, index) => {
            return (
              <MappedComponent
                key={p.name}
                p={p}
                index={index}
                pageOpen={pageOpen}
                loginInfo={loginInfo}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
