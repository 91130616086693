import React from "react";
import Select from "react-dropdown-select";

import Utilities from "./Utilities";
import CodeList from "./CodeList";

const util = new Utilities();
const codeList = new CodeList();

export default function LanguageSelect({
  selectRef,
  placeholder = "언어를 선택해주세요",
  className,
  callback,
}) {
  const lang = util.changedMapToArray(codeList.codeLanguage);

  return (
    <div className="custom-default-dropdown-select">
      <Select
        ref={selectRef}
        clearable={true}
        valueField={"id"}
        labelField={"name_ko"}
        options={lang.returnArray}
        placeholder={placeholder}
        className={className}
        onChange={callback}
      />
    </div>
  );
}
