import React, { useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import Utilities from "../../../../lib/Utilities";
import CodeList from "../../../../lib/CodeList";
import MainLogoSVG from "../../../../lib/MainLogoSVG";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

export default function GuideRegistNav(props) {
  let {
    pages,
    activeMenu,
    pageOpen,

    handleChangeMenu,
    handleSaveOut,
  } = props;
  return (
    <div className="bg-light shadow shadow-sm h-100 d-none d-lg-flex flex-column">
      <div className="flex-fill overflow_scroll_y">
        <div className="py-3 px-4">
          <MainLogoSVG width={240} />
        </div>
        {pages.map((item, index) => {
          if (item.type === "kind") {
            return (
              <p
                className="py-3 px-4 border-bottom font-weight-bold cursor_pointer d-flex flex-row justify-content-between align-items-center"
                key={index}
                onClick={() => {
                  handleChangeMenu(item.kind, "");
                }}
              >
                <span className="mr-5">{item.title}</span>

                {item.kind === activeMenu ? (
                  <i className="fa-solid fa-angle-up" />
                ) : (
                  <i className="fa-solid fa-angle-down" />
                )}
              </p>
            );
          }
          if (item.type === "page" && item.kind === activeMenu) {
            return (
              <p
                className={`py-3 px-4 cursor_pointer ${
                  pageOpen[item.key]
                    ? "bg-buddible text-white font-weight-bold"
                    : ""
                }`}
                key={item.key + index}
                onClick={() => {
                  handleChangeMenu(item.kind, item.key);
                }}
              >
                {item.title}
              </p>
            );
          }
        })}
      </div>
      <div
        className="cursor_pointer font-weight-bold py-3 px-5"
        onClick={() => {
          handleSaveOut();
        }}
      >
        <i className="fa-solid fa-arrow-right-from-bracket mr-2" />
        저장 후 나가기
      </div>
    </div>
  );
}
