import React from "react";
export default function GuideRegistFindGuideIntro() {
  return (
    <div className="col-xl-6">
      <div className="mb-5">
        <h3 className="font-weight-bold">플레이 영종이 찾고있는 가이드</h3>
        <div
          className="rounded min-height-350 w-100 my-2"
          style={{
            background: `url("/images/ExpectationPage1.jpg") 50% 50%/cover no-repeat`,
          }}
        />
        <p>
          가이드는 주제에 대해 열정을 가진 현지인 대학생으로, 게스트가 편안한
          분위기 속에서 새로운 경험을 할 수 있도록 도와줍니다. 가이드는 다음과
          같은 기준을 만족해야 합니다.
        </p>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-1">· </div>
              <div>
                <span className="font-weight-bold"> 전문성</span> : 뛰어난 기술,
                능력 및 배경을 갖추지 못하더라도 언어에 대해서는 능통할 것.
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-1">· </div>
              <div>
                <span className="font-weight-bold"> 특별한 기회 제공</span> :
                게스트가 혼자서는 할 수 없는 특별한 경험이나 해보지 못한것을
                제공.
              </div>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-1">· </div>
              <div>
                <span className="font-weight-bold"> 교감</span> : 진정성 있는
                소통과 교감을 만들 것.
              </div>
            </div>
          </li>
        </ul>
        <p>그럼 각 사항에 대해 자세히 살펴보겠습니다.</p>
      </div>
    </div>
  );
}
