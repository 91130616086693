import React from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();
const _mClassName = "GuideRegistSafetyAgree";
export default function GuideRegistSafetyAgree(props) {
  const { guideData } = props;

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = (value) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      { gl_safe_agree: value ? "Y" : "N" },
      () => {}
    );
  };
  return (
    <div className="col-xl-6">
      <div className="pb-3">
        <div
          className="rounded my-2 w-100 min-height-350"
          style={{
            background: `url("/images/safety7Agreement.png")50% 50%/cover no-repeat`,
          }}
        />
      </div>
      <div>
        <ul className="pt-3">
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">· </div>
              <p>
                플레이 영종의 안전 및 가이드라인을 읽고 이해했으며, 본인과 공동
                가이드가 이 기준을 따르는 데 동의합니다.
              </p>
            </div>
          </li>
          <li className="mb-3">
            <div className="d-flex flex-row">
              <div className="mr-2">· </div>
              <p>
                지역 방역 당국 및 기타 정부 기관이 발표한 안전 및 위생 수칙을
                포함해 체험에 적용되는 현지 지침 및 법률을 준수할 책임이
                본인에게 있음을 이해합니다.
              </p>
            </div>
          </li>
        </ul>
        <div>
          <label>
            <input
              type="checkbox"
              className="mr-2"
              defaultChecked={guideData.gl_safe_agree === "Y"}
              disabled={guideData.gl_confirm_admin === "S"}
              onChange={(e) => {
                guideDataChange(e.target.checked);
              }}
            />
            네, 동의합니다.
          </label>
        </div>
      </div>
    </div>
  );
}
