import React, { useEffect, useState } from "react";
import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();
let submit_data = {
  gpt_agree_yn: "",
};

export default function GuideTimeRegistSubmit(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (partTimeData) {
      for (let key in submit_data) {
        submit_data[key] = partTimeData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  const handleSubmitData = (key, val) => {
    submit_data[key] = val;
    guideTimeRegistDataChange(submit_data);
    setIsNeedUpdate(!isNeedUpdate);
  };
  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <h3 className="font-weight-bold mb-3">검토할 마지막 단계</h3>
        <h4 className="font-weight-bold">
          제출하기 전에 한번 더 확인해야 합니다.
        </h4>
        <p>
          지금 제출하시는 내용을 플레이 영종에서 검토할 것입니다. 제출하시기
          전에 모든 사항이 제대로 작성되었는지 확인하세요. 제출 전에는 언제든지
          뒤로 돌아가 내용을 수정하실 수 있습니다.
        </p>
      </div>
      <div className="mb-3 pb-3">
        <h4 className="font-weight-bold">
          '제출'하면 다음 내용이 사실임을 확인하는 것입니다.
        </h4>
        <p>
          현지 법규에 따라 적법하게 가이드를 운영합니다. 가이드에 적용될 수 있는
          기타 법규(영업허가 등)에 대해 자세히 알아보세요.
          <br />
          <br />
          설명과 사진 모두 본인 소유이며, 진행할 가이드를 정확하게 반영함을
          확인합니다.
        </p>
        <div className="d-flex flex-row justify-content-start align-items-center py-3">
          <input
            className="mr-2"
            type="checkbox"
            onChange={(e) => {
              handleSubmitData("gpt_agree_yn", e.target.checked ? "Y" : "N");
            }}
            checked={submit_data.gpt_agree_yn === "Y"}
          />
          <p>
            플레이 영종 가이드 추가 이용 약관 및 게스트 환불 정책에 동의합니다.
          </p>
        </div>
      </div>
    </div>
  );
}
