import React from "react";
import Caution from "../../commonUI/Caution";
export default function GuideTourRegistDescription(props) {
  const { tourData, guideTourRegistDataChange } = props;

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (val) => {
    guideTourRegistDataChange({ rl_desc: val });
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-2">여행에 대한 설명</h6>

        <div className="d-flex flex-row mb-2">
          <p className="mr-2">1.</p>
          <p>
            먼저, 여행에서 어떤 활동을 하게 될지 간략히 설명하세요. 비슷한 다른
            여행과 차별되는 특별한 점은 무엇인가요?
          </p>
        </div>

        <div className="d-flex flex-row mb-2">
          <p className="mr-2">2.</p>
          <p>
            이제 좀 더 구체적으로 설명할 차례입니다. 가이드를 어떻게
            시작하시겠어요? 여행이 진행되는 동안 게스트의 참여를 독려하기 위해
            어떻게 하시겠어요?
          </p>
        </div>

        <div className="d-flex flex-row mb-2">
          <p className="mr-2">3.</p>
          <p>
            마지막으로, 여행을 마친 게스트가 얻어갔으면 하는 점이 무엇인지
            적어보세요. 새로 사귄 친구, 새롭게 알게 된 지식, 여행지의 문화에
            대한 이해 등 가이드의 장점을 강력하게 내세우며 마무리하세요.
          </p>
        </div>

        <textarea
          rows={5}
          disabled={tourData.rl_confirm_admin === "S"}
          defaultValue={tourData.rl_desc}
          className="form-control mt-3"
          placeholder="여행의 중심이되는 활동이 무엇인가요? 구체적으로 작성하세요"
          spellCheck={false}
          onChange={(e) => {
            handleTourDataChange(e.target.value);
          }}
        />
        <Caution
          desc={
            "가이드 진행 일정이 구체적이고 명확해야 합니다. 여러 여행 중에서 게스트가 선택할 수 있는 방식은 허용되지 않습니다."
          }
        />
      </div>
    </div>
  );
}
