import React, { useEffect, useState } from "react";
import GuideTourRegistNav from "./compnent/GuideTourRegistNav";
import GuideTourRegistSubject from "./compnent/myTravel/guideTourRegistSubject/GuideTourRegistSubject";
import GuideTourRegistTitle from "./compnent/myTravel/guideTourRegistTitle/GuideTourRegistTitle";
import GuideTourRegistDescription from "./compnent/myTravel/guideTourRegistDescription/GuideTourRegistDescription";
import GuideTourRegistPlace from "./compnent/myTravel/guideTourRegistPlace/GuideTourRegistPlace";
import GuideTourRegistPhoto from "./compnent/myTravel/guideTourRegistPhoto/GuideTourRegistPhoto";
import GuideTourRegistOffer from "./compnent/myTravel/guideTourRegistOffer/GuideTourRegistOffer";
import GuideTourRegistPrerequisite from "./compnent/myTravel/guideTourRegistPrerequisite/GuideTourRegistPrerequisite";

import GuideTourRegistTravelRoute from "./compnent/myRoute/guideTourRegistTravelRoute/GuideTourRegistTravelRoute";

import GuideTourRegistSettingCost from "./compnent/setting/guideTourRegistSettingCost/GuideTourRegistSettingCost";
import GuideTourRegistSettingTime from "./compnent/setting/guideRegistSettingTime/guideTourRegistSettingTime";
import GuideTourRegistSettingReserve from "./compnent/setting/guideTourRegistSettingReserve/GuideTourRegistSettingReserve";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Loading from "../../../lib/Loading";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const pages = [
  { type: "kind", kind: "myTravel", title: "나의 여행지역" },
  {
    type: "page",
    kind: "myTravel",
    title: "주제",
    subTitle:
      "가이드에서 주로 하게 될 활동을 가장 잘 나타내는 주제를 선택하시면, 게스트가 가이드를 찾아 예약하는 데 도움이 됩니다.",
    desc: "",
    key: "GuideTourRegistSubject",
    component: (params) => <GuideTourRegistSubject {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "제목",
    subTitle:
      "간결하면서도 여행을 잘 표현하며, 흥미를 유발하는 제목을 작성하세요",
    desc: "",
    key: "GuideTourRegistTitle",
    component: (params) => <GuideTourRegistTitle {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "설명",
    subTitle:
      "여행 경로 제목에 대한 설명을 잘 설명하면 게스트의 예약을 유도할 수 있습니다. 잘 짜여진 이야기처럼 명확한 시작, 전개, 결론이 있는 설명을 구성하세요.",
    desc: "",
    key: "GuideTourRegistDescription",
    component: (params) => <GuideTourRegistDescription {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "위치",
    subTitle: "여행의 시작 장소가 어디인가요?",
    desc: "모이는 장소는 누구나 찾기 쉬운 곳이어야 합니다. 정확한 주소는 예약이 완료되기 전까지 게스트에게 공개되지 않습니다.",
    key: "GuideTourRegistPlace",
    component: (params) => <GuideTourRegistPlace {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "사진",
    subTitle: "여행 사진 추가하기",
    desc: "게스트가 실제로 참여하면 어떨지 파악할 수 있도록 퀄리티 높은 사진을 7장 이상 추가하세요. 사진이 플레이 영종 가이드 기준에 부합하는지 확인하기 위해 담당 팀에서 검토하는 과정을 거치게 됩니다.",
    key: "GuideTourRegistPhoto",
    component: (params) => <GuideTourRegistPhoto {...params} />,
  },
  {
    type: "page",
    kind: "myTravel",
    title: "제공사항",
    subTitle: "제공 항목에 대한 자세한 설명을 입력하세요",
    desc: "",
    key: "GuideTourRegistOffer",
    component: (params) => <GuideTourRegistOffer {...params} />,
  },
  {
    subTitle: "어느 정도 수준의 기술이 필요한가요? (필수입력)",
    desc: "다른 사람을 위해 가이드를 예약하는 게스트도 있다는 점을 기억하세요. 참여에 엄격한 나이 제한, 필요한 기술 또는 자격증이 있다면 알려주세요.",
    type: "page",
    kind: "myTravel",
    title: "게스트 필수조건",
    key: "GuideTourRegistPrerequisite",
    component: (params) => <GuideTourRegistPrerequisite {...params} />,
  },

  { type: "kind", kind: "myRoute", title: "나의 여행경로" },
  {
    type: "page",
    kind: "myRoute",
    title: "나의 여행 경로",
    subTitle: "선택한 나의 여행 지역에서 경유지를 등록해 주세요.",
    desc: "",
    key: "GuideTourRegistTravelRoute",
    component: (params) => <GuideTourRegistTravelRoute {...params} />,
  },
  { type: "kind", kind: "setting", title: "설정" },
  {
    type: "page",
    kind: "setting",
    title: "인원 및 요금",
    subTitle: "",
    desc: "",
    key: "GuideTourRegistSettingCost",
    component: (params) => <GuideTourRegistSettingCost {...params} />,
  },
  {
    type: "page",
    kind: "setting",
    title: "소요시간",
    subTitle: "",
    desc: "",
    key: "GuideTourRegistSettingTime",
    component: (params) => <GuideTourRegistSettingTime {...params} />,
  },
  {
    type: "page",
    kind: "setting",
    title: "예약설정",
    subTitle:
      "예약 마감 시간을 가이드 시작 시간과 최대한 가깝게 설정하면 더 많은 게스트의 예약을 받을 수 있습니다. 단, 게스트를 맞이하기 위한 준비 시간을 충분히 가질 수 있도록 설정하세요.",
    desc: "",
    key: "GuideTourRegistSettingReserve",
    component: (params) => <GuideTourRegistSettingReserve {...params} />,
  },
];
const _mClassName = "GuideTourRegist";
const tourDataFormat = {
  rl_idx: null,

  rl_route_uid: "",
  rl_guide_uid: "",
  rl_name: "", // 가이드 이름

  rl_subject: "", // 주제
  rl_hashtag: [], //해시태그

  rl_title: "", //서비스 타이틀
  rl_desc: "", // 서비스 메인설명

  rl_addr_place_id: "", // google map 장소 id
  rl_addr_lat: "37.6849216", //위도
  rl_addr_log: "126.81864420000101", //경도
  rl_addr_full: "", // 전체주소
  rl_addr_name: "",
  rl_addr_custom_name: "",
  rl_addr_country: "",
  rl_addr_postal_code: "",
  rl_addr_area_level_1: "",
  rl_addr_sublocality_level_1: "",
  rl_addr_sublocality_level_2: "",
  rl_addr_premise: "",
  rl_way_explain: "", // 약속장소 대기 설정

  rl_pic_main: "", // 메인이미지
  rl_pics: "", //이미지 목록
  rl_rating: 0, // 별점
  rl_rating_cnt: 0, // 리뷰수
  rl_area_cd: "", // 서비스 지역코드
  rl_price_base: 0, // 기본요금
  rl_price_per_people: 0, // 인원 추가요금
  rl_reg_date: new Date(), // 생성일
  rl_edit_date: new Date(),

  rl_guest_min: 1, // 게스트 최소인원
  rl_guest_max: 1, // 게스트 최대인원
  rl_base_hour_duration: "", // 서비스 소요시간
  rl_base_hour_start: "", // 서비스 운영시작 시간
  rl_reservation_people_time: 0, // 추가 인원에 대한 예약 마감 시간
  rl_reservation_time: 0, // 첫번째게스트 마감시간
  rl_book_allow_otherday_yn: "N", //예약 가능 여부에 대한 요청

  rl_inc_meal: "",
  rl_inc_meal_list: "",
  rl_inc_meal_memo: "",
  rl_inc_snack: "",
  rl_inc_alcohol: "",
  rl_inc_drink: "",
  rl_inc_drink_list: "",
  rl_inc_drink_memo: "",
  rl_inc_ticket: "",
  rl_inc_ticket_list: "",
  rl_inc_ticket_memo: "",
  rl_inc_vehicle: "",
  rl_inc_vehicle_list: "",
  rl_inc_vehicle_memo: "",
  rl_inc_vehicle_driver: "",
  rl_inc_etc: "",

  rl_duration: "",
  rl_over_pay_hour: 0, // 시간당 추가요금
  rl_over_pay_people: 0, // 추가인원당 추가요금
  rl_can_child: "N", //미성년자 이용가능 여부
  rl_child_min: "",
  rl_guest_age_limit: "", //게스트 나이제한

  rl_accessibility_main: "",
  rl_accessibility_etc: "",
  rl_accessibility_communication: "",
  rl_accessibility_environment: "",

  rl_tour_level: "",
  rl_tour_skill: "",
  rl_additional_demand: "",
  rl_guest_validation_yn: "",

  rl_use_yn: "Y", //서비스 사용여부
  rl_agree_minor_yn: "N",
  rl_agree_refund_yn: "N",
  rl_del_yn: "N", // 서비스 삭제여부
  rl_confirm_admin: "W", //관리자 승인 여부
};

let loading = true;
let pageOpen = {
  GuideTourRegistSubject: true,
  GuideTourRegistTitle: false,
  GuideTourRegistDescription: false,
  GuideTourRegistPlace: false,
  GuideTourRegistPhoto: false,
  GuideTourRegistOffer: false,
  GuideTourRegistPrerequisite: false,

  GuideTourRegistTravelRoute: false,

  GuideTourRegistSettingCost: false,
  GuideTourRegistSettingTime: false,
  GuideTourRegistSettingReserve: false,
};
let activePage = "GuideTourRegistSubject";
let activeMenu = "myTravel";

let loginInfo = null;
let tourData = {
  ...tourDataFormat,
};

export default function GuideTourRegist(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const requestTourData = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_detail_select.php",
      msgID: msgID,
      rl_guide_uid: tourData.rl_guide_uid,
      rl_route_uid: tourData.rl_route_uid,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  //투어 등록데이터 체인지
  const guideTourRegistDataChange = (val, callback) => {
    tourData = {
      ...tourData,
      ...val,
    };
    callback && callback();
  };

  // 경로서비스 정보 업데이트
  const updateRouteData = (updateData, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tour/JS_tour_detail_update.php",
      msgID: msgID,
      ...updateData,
      ...loginInfo,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };
  const actionSave = (callback) => {
    let guideRouteInfo = {};
    for (let key in tourData) {
      if (key.startsWith("rl_")) {
        if (key === "rl_hashtag") {
          guideRouteInfo[key] = tourData[key].join("|");
        } else {
          guideRouteInfo[key] = tourData[key];
        }
      }
    }
    if (!guideRouteInfo.rl_guide_uid || !guideRouteInfo.rl_route_uid) return;
    updateRouteData(guideRouteInfo, callback);
  };

  // 데이터 초기화
  const clearRouteData = (callback) => {
    loading = true;
    pageOpen = {
      GuideTourRegistSubject: true,
      GuideTourRegistTitle: false,
      GuideTourRegistDescription: false,
      GuideTourRegistPlace: false,
      GuideTourRegistPhoto: false,
      GuideTourRegistOffer: false,
      GuideTourRegistPrerequisite: false,

      GuideTourRegistTravelRoute: false,

      GuideTourRegistSettingCost: false,
      GuideTourRegistSettingTime: false,
      GuideTourRegistSettingReserve: false,
    };
    activePage = "GuideTourRegistSubject";
    activeMenu = "myTravel";

    loginInfo = null;
    tourData = {
      ...tourDataFormat,
    };
    callback && callback();
  };

  //저장 후 나가기
  const handleSaveOut = () => {
    codeList.Modal.current.confirm(
      "여행경로를 저장 후 나가시겠습니까?",
      (ret) => {
        if (ret) {
          if (tourData.rl_confirm_admin === "S") {
            clearRouteData(() => {
              props.history.replace("/GuideManagement");
            });
            return;
          }
          actionSave(() => {
            clearRouteData(() => {
              props.history.replace("/GuideManagement");
            });
          });
        }
      }
    );
  };

  const actionSubmit = () => {
    if (tourData.rl_confirm_admin === "S") {
      props.history.replace("/GuideManagement");
      return;
    }

    codeList.Modal.current.confirm(
      "제출 후 검토중에는 수정이 불가합니다. 제출하시겠습니까?",
      (ret) => {
        if (ret) {
          tourData.rl_confirm_admin = "S";
          setIsLoading(true);
          actionSave((newData) => {
            setIsLoading(false);
            codeList.Modal.current.alert(
              "경로등록 요청이 정상처리되었습니다.",
              () => {
                clearRouteData(() => {
                  props.history.replace("/GuideManagement");
                });
              }
            );
          });
        }
      }
    );
  };

  //하단 페이지 이동
  const handlePageChange = (move) => {
    let pageOpenData = [];
    for (let key in pageOpen) {
      pageOpenData.push(key);
    }
    let currentIndex = pageOpenData.findIndex((item) => item === activePage);

    let nextPage = pageOpenData[currentIndex];
    if (move === "next") {
      nextPage = pageOpenData[currentIndex + 1];
      if (isPageValidated()) {
        codeList.Modal.current.alert(
          "현재 페이지의 정보 입력후 페이지 이동이 가능합니다."
        );
        return;
      }
    } else if (move === "prev") {
      nextPage = pageOpenData[currentIndex - 1];
    }
    let currentPage = null;
    pages.forEach((item) => {
      if (item.hasOwnProperty("key") && item.key === nextPage) {
        currentPage = item;
      }
    });
    let changePageOpen = {};
    for (let key in pageOpen) {
      changePageOpen[key] = key === nextPage;
    }
    activeMenu = currentPage.kind;
    pageOpen = changePageOpen;
    activePage = nextPage;
    setIsNeedUpdate(!isNeedUpdate);
    if (tourData.rl_confirm_admin !== "S") {
      actionSave();
    }
  };

  // 페이지 데이터 유효성검사
  const isPageValidated = (key = activePage) => {
    switch (key) {
      case "GuideTourRegistSubject":
        if (tourData.rl_subject.trim() !== "") return false;
        break;

      case "GuideTourRegistTitle":
        if (tourData.rl_title.trim() !== "") return false;
        break;

      case "GuideTourRegistDescription":
        if (tourData.rl_desc.trim() !== "") return false;
        break;

      case "GuideTourRegistPlace":
        if (
          tourData.rl_addr_full.trim() !== "" &&
          tourData.rl_addr_place_id.trim() !== "" &&
          tourData.rl_addr_custom_name.trim() !== "" &&
          tourData.rl_way_explain.trim() !== ""
        )
          return false;
        break;
      case "GuideTourRegistPhoto":
        if (
          tourData.rl_pic_main.trim() !== "" &&
          tourData.rl_pics.trim() !== ""
        )
          return false;
        break;
      /*
                case "GuideTourRegistOffer":
                  // 필수 입력값 X
                  return false;
                  break;
                  */
      case "GuideTourRegistPrerequisite":
        if (
          tourData.rl_tour_level.trim() !== "" &&
          tourData.rl_tour_skill.trim() !== ""
        )
          return false;
        break;

      /*
                  case "GuideTourRegistTravelRoute":
                    // 필수 입력값 X
                    return false;
                    break;
                    */

      case "GuideTourRegistSettingCost":
        if (
          tourData.rl_guest_max > 0 &&
          tourData.rl_guest_min > 0 &&
          tourData.rl_price_base > 0
        )
          return false;
        break;

      default:
        return false;
    }

    return true;
  };
  //내비 페이지 이동
  const handleChangeMenu = (kind, menuKey) => {
    let moveKey = menuKey;
    let moveKind = kind;

    if (moveKey !== "") {
      let currentIndex = pages.findIndex((item) => item.key === activePage);
      let moveIndex = pages.findIndex((item) => item.key === moveKey);

      if (moveIndex - currentIndex === 1 && isPageValidated(activePage)) {
        //현재 페이지에서 바로 다음 페이지로 이동할 경우
        codeList.Modal.current.alert(
          "현재 페이지의 정보 입력 후 페이지 이동이 가능합니다."
        );
        return;
      }

      if (moveIndex - currentIndex > 1) {
        //현재 페이지에서 바로 다음페이지를 제외한 이후 페이지로 이동할 경우
        let isCheck = false;
        let modifyPageKey = "";
        for (let i = 0; i < pages.length; i++) {
          let page = pages[i];

          if (page.key === moveKey) {
            break;
          }

          if (isPageValidated(page.key)) {
            codeList.Modal.current.alert(
              "이전 페이지의 정보가 입력되지 않았습니다."
            );
            moveKind = page.kind;
            modifyPageKey = page.key;
            isCheck = true;
            break;
          }
        }
        if (isCheck) {
          moveKey = modifyPageKey;
        }
      }
      let pageOpenData = {};
      for (let key in pageOpen) {
        pageOpenData[key] = key === moveKey;
      }
      pageOpen = pageOpenData;
      activePage = moveKey;
    }
    activeMenu = moveKind;
    if (tourData.rl_confirm_admin === "S") {
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      actionSave();
    }
  };

  useEffect(() => {
    //브라우저 페이지 이동 제어
    window.onpopstate = function (event) {
      if (event) {
        clearRouteData();
      }
    };

    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      loginInfo = loginData;
      if (props) {
        if (typeof props.location.state === "object") {
          if (props.location.state.hasOwnProperty("rl_route_uid")) {
            tourData.rl_route_uid = props.location.state.rl_route_uid;
          }
        } else {
          tourData.rl_route_uid = util.makeUUIDv4();
        }
      } else {
        tourData.rl_route_uid = util.makeUUIDv4();
      }
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
        setIsNeedUpdate(!isNeedUpdate);
      }
    );

    if (loginInfo !== null) {
      if (loginInfo.hasOwnProperty("gl_guide_uid")) {
        tourData.rl_guide_uid = loginInfo.gl_guide_uid;
        requestTourData((newData) => {
          let getData = newData["returnData"][0];
          for (let key in getData) {
            tourData[key] = getData[key] ? getData[key] : tourData[key];
          }
          tourData.rl_name = `${loginInfo.u_name_first} ${loginInfo.u_name_last}`;
          loading = false;
          setIsNeedUpdate(!isNeedUpdate);
        });
      } else {
        codeList.Modal.current.alert(
          "가이드 등록 완료 후 여행등록이 가능합니다."
        );
      }
    }

    return () => {
      pageOpen = {
        GuideTourRegistSubject: true,
        GuideTourRegistTitle: false,
        GuideTourRegistDescription: false,
        GuideTourRegistPlace: false,
        GuideTourRegistPhoto: false,
        GuideTourRegistOffer: false,
        GuideTourRegistPrerequisite: false,

        GuideTourRegistTravelRoute: false,

        GuideTourRegistSettingCost: false,
        GuideTourRegistSettingTime: false,
        GuideTourRegistSettingReserve: false,
      };
      activePage = "GuideTourRegistSubject";
      activeMenu = "myTravel";

      tourData = { ...tourDataFormat };
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, [props]);

  if (loading) return <></>;

  return (
    <>
      {isLoading && <Loading />}
      <div className="bg-white d-flex flex-row justify-content-start w-100 vh-100">
        <GuideTourRegistNav
          pages={pages}
          activeMenu={activeMenu}
          pageOpen={pageOpen}
          handleChangeMenu={handleChangeMenu}
          handleSaveOut={handleSaveOut}
        />
        <div className="flex-fill d-flex flex-column justify-content-between">
          {pages.map((item, index) => {
            if (item.type === "page") {
              return (
                <div
                  key={item.kind + index}
                  className={`container-fluid flex-fill overflow_scroll_y py-4 py-md-5 ${
                    pageOpen[item.key] ? "d-block" : "d-none"
                  }`}
                >
                  <div className="row justify-content-center px-3 px-md-5 mb-3">
                    <div className="col-xl-6">
                      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                        <h3 className="font-weight-bold">{item.title}</h3>
                        <span
                          className="cursor_pointer text-buddible font-weight-bold d-lg-none"
                          onClick={handleSaveOut}
                        >
                          <i className="fa-solid fa-arrow-right-from-bracket mr-2" />
                          저장 후 나가기
                        </span>
                      </div>
                      {item.subTitle && (
                        <h6 className="font-weight-bold mb-1">
                          {item.subTitle}
                        </h6>
                      )}
                      {item.desc && <p>{item.desc}</p>}
                    </div>
                  </div>
                  <div className="row justify-content-center px-3 px-md-5">
                    {item.component({
                      tourData: tourData,
                      loginInfo: loginInfo,
                      guideTourRegistDataChange: guideTourRegistDataChange,
                    })}
                  </div>
                </div>
              );
            }
          })}
          <div className="border-top p-3 d-flex flex-row justify-content-between">
            {activePage !== "GuideTourRegistSubject" ? (
              <div
                className="btn_1 white border"
                onClick={() => {
                  handlePageChange("prev");
                }}
              >
                이전
              </div>
            ) : (
              <div />
            )}

            {activePage !== "GuideTourRegistSettingReserve" ? (
              <button
                className="btn_1"
                onClick={() => {
                  handlePageChange("next");
                }}
              >
                다음
              </button>
            ) : (
              <button
                className="btn_1"
                disabled={isLoading}
                onClick={actionSubmit}
              >
                {tourData.rl_confirm_admin === "S" ? "나가기" : "제출"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
