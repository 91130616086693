import React, { useEffect, useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

const _mClassName = "GuideRegistSubmitCheck";
const questionList = [
  {
    id: 1,
    name: "license",
    question: "다음 중 가이드하는 여행과 본인에게 해당하는 항목은 무엇인가요?",
    desc: "본인은 정책 준수를 증빙하는 자료(해당 허가증, 라이선스 또는 승인 포함) 또는 가이드를 위에서 설명한 유형의 법규나 요건에서 규제하는 대상이 아님을 보여주는 증빙 자료를 요구할 권리가 플레이 영종에 있음을 이해합니다. 플레이 영종이 요구할 때 증빙 자료를 제출하지 못하면 가이드소개나 가이드루트가 플레이 영종 플랫폼에서 삭제될 수 있습니다.",
    answerOption: [
      {
        id: 1,
        name: "gl_agree_license",
        value: "A",
        desc: "여행가이드나 관광 서비스에 적용되는 필수 면허 및 인허가를 모두 보유하고 있습니다.",
        stateKey: "gl_agree_license",
        type: "radio",
      },
      {
        id: 2,
        name: "gl_agree_license",
        value: "B",
        desc: "여행가이드를 진행하는 데 여행가이드 또는 관광 서비스에 적용되는 면허 및 인허가가 필요하지 않습니다.",
        stateKey: "gl_agree_license",
        type: "radio",
      },
      {
        id: 3,
        name: "gl_agree_license",
        value: "C",
        desc: "여행을 진행하는 데 필요한 면허 및 인허가를 보유하고 있지 않습니다.",
        stateKey: "gl_agree_license",
        type: "radio",
      },
    ],
  },
  {
    id: 2,
    name: "alcohol",
    question: "모든 주류가 허용된 장소(레스토랑, 바 등)에서 제공되나요?",
    desc: "가이드에 적용될 수 있는 현지 주류법을 확인하세요.",
    answerOption: [
      {
        id: 1,
        name: "gl_agree_alcohol_yn",
        value: "N",
        desc: "아니요",
        stateKey: "gl_agree_alcohol_yn",
        type: "radio",
      },
      {
        id: 2,
        name: "gl_agree_alcohol_yn",
        value: "Y",
        desc: "예",
        stateKey: "gl_agree_alcohol_yn",
        type: "radio",
      },
    ],
  },
];
const termsConditions = [
  {
    id: 1,
    name: "gl_agree_law_yn",
    type: "checkbox",
    desc: "현지 법규에 따라 적법하게 체험을 운영합니다. 가이드에 적용될 수 있는 기타 법규(영업허가 등)에 대해 자세히 알아보세요.",
  },
  {
    id: 2,
    name: "gl_agree_info_yn",
    type: "checkbox",
    desc: "설명과 사진 모두 본인 소유이며, 진행할 가이드를 정확하게 반영함을 확인합니다.",
  },
  {
    id: 3,
    name: "gl_agree_refund_yn",
    type: "checkbox",
    desc: "플레이 영종 개인정보처리방침 및 게스트 환불 정책에 동의합니다.",
  },
];
let submitData = {
  gl_agree_license: "", //가이드 면허 여부
  gl_agree_alcohol_yn: "", //주류 포함 여부
  gl_agree_law_yn: "", //법규 확인
  gl_agree_info_yn: "", //설명확인
  gl_agree_refund_yn: "", //환불정책 확인
  gl_mobile: "", //전화번호
};
export default function GuideRegistSubmitCheck(props) {
  const { guideData } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (guideData) {
      for (let key in submitData) {
        submitData[key] = guideData.hasOwnProperty(key)
          ? guideData[key]
          : submitData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  //제출 정보 변경
  const handleUserInfoChange = (key, value) => {
    submitData = {
      ...submitData,
      [key]: value,
    };
    setIsNeedUpdate(!isNeedUpdate);
    guideDataChange();
  };

  //제공사항 부모컴포넌트 상태 저장
  const guideDataChange = () => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      submitData,
      () => {}
    );
  };
  return (
    <div className="col-xl-6">
      <div className="py-3 border-bottom">
        <h6 className="mb-2 font-weight-bold">
          가이드가 현지 관광법과 장소 요건을 준수하고 있나요?
        </h6>
        <p className="mb-3">
          많은 지역에는 여행, 여행가이드 및 관광객에게 제공되는 기타 서비스를
          규제하는 법률이 있습니다. 가이드하려면 면허나 허가가 필요할 수 있으며,
          가이드 장소의 관할 기관 또는 지역사회로부터 정식 허가를 받아야 할 수도
          있습니다.
        </p>
        <p>
          법의 적용 범위는 지역에 따라 다릅니다. 관련 법규는 명소나 유명
          관광지에만 적용되기도 하고, 가이드를 동반한 모든 활동에 적용될 수도
          있습니다. 또한, 가이드하는 장소에 따라 적용되는 규칙이나 문화적 규범이
          있을 수 있습니다. 체험에 영향을 주는 <em>모든 현지 법규</em>에 대해
          알아보고 이를 준수할 책임은 가이드에게 있습니다.
        </p>
        <div className="py-3 ">
          {questionList.map((question) => {
            return (
              <div key={question.name}>
                <h6 className="mb-2 font-weight-bold">{question.question}</h6>
                <div>
                  {question.answerOption.map((answer, index) => {
                    return (
                      <div key={answer.name + "_" + index}>
                        <label
                          className=" d-flex flex-row align-items-start"
                          htmlFor={answer.name + answer.id}
                        >
                          <input
                            onChange={(e) => {
                              handleUserInfoChange(answer.name, e.target.value);
                            }}
                            className="mr-2 mt-1"
                            type={answer.type}
                            id={answer.name + answer.id}
                            name={answer.name}
                            value={answer.value}
                            disabled={guideData.gl_confirm_admin === "S"}
                            checked={submitData[answer.name] === answer.value}
                          />{" "}
                          <p>{answer.desc}</p>
                        </label>
                      </div>
                    );
                  })}
                </div>
                <p className="p-3 mb-5 border rounded shadow-sm">
                  {question.desc}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="py-3">
        <h3 className="font-weight-bold mb-3">
          제출 전에 플레이 영종 정책을 확인해주세요
        </h3>

        <h6 className="mb-2">최소 참여 인원</h6>
        <p className="mb-3">
          플레이 영종 가이드의 최소 인원은 1명입니다. 따라서 예약자가 단 1명인
          경우에도 가이드하셔야 합니다.
        </p>
        <h6 className="mb-2">서비스 수수료</h6>
        <p className="mb-3">
          플레이 영종은 각 예약당 요금의 20%에 해당하는 수수료를 받습니다. 모든
          결제는 플레이 영종를 통해 진행되며, 고객센터를 통한 24시간 지원과 함께
          대부분의 체험에 대해 책임 보험도 제공하고 있습니다. 서비스 수수료에
          대해 자세히 알아보세요.
        </p>
        <h6 className="mb-2">플레이 영종 회원만을 위한 체험</h6>
        <p className="mb-3">
          플레이 영종을 통해 체험 예약을 받는 날에는 플레이 영종 게스트만
          가이드하셔야 합니다. 다른 플랫폼을 통해 예약하고 결제한 게스트는
          별도로 가이드해야 합니다.
        </p>
        <h6 className="mb-2">
          '제출'하면 다음 내용이 사실임을 확인하는 것입니다.
        </h6>
        <div className="mb-3">
          {termsConditions.map((terms) => {
            return (
              <div key={terms.name} className="py-1">
                <label
                  htmlFor={terms.name + terms.id}
                  className="d-flex flex-row align-items-start"
                >
                  <input
                    type={terms.type}
                    id={terms.name + terms.id}
                    name={terms.name}
                    checked={submitData[terms.name] === "Y"}
                    disabled={guideData.gl_confirm_admin === "S"}
                    className="mt-1 mr-2"
                    onChange={(e) => {
                      let check = e.target.checked ? "Y" : "N";
                      handleUserInfoChange(terms.name, check);
                    }}
                  />{" "}
                  <p>{terms.desc}</p>
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
