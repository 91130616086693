import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ReserveServiceRefundPolicy(props) {
  const { request_data } = props;
  const [refundDate, setRefundDate] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    let reserveDate = request_data.rrl_date_reserve;
    if (!reserveDate) return;
    reserveDate = reserveDate.split(" ");
    let setDate = +new Date(reserveDate[0]) + 86400000 * 14;
    setDate = new Date(setDate);

    let year = setDate.getFullYear();
    let month = setDate.getMonth() + 1;
    let date = setDate.getDate();

    let refund = `${year}년 ${month < 10 ? "0" + month : month}월 ${
      date < 10 ? "0" + date : date
    }일`;

    refund = `${refund} ${reserveDate[1]}`;
    setRefundDate(refund);
  }, []);

  return (
    <>
      {" "}
      <div className="border-bottom py-3 d-flex flex-row justify-content-start">
        <div className="bg-buddible mr-3" style={{ width: 10 }} />

        <div>
          <p className="h4">{t("refundPolicy")}</p>
          <p>
            {t("refundPolicyNote1-1")}
            <span className="font-weight-bold"> {refundDate}</span>
            {t("refundPolicyNote1")}
          </p>
          <p className="mb-2">
            {t("refundPolicyNote2-1")}
            <span className="font-weight-bold"> {refundDate}</span>{" "}
            {t("refundPolicyNote2")}
            <span className="font-weight-bold underline">
              {" "}
              {t("showDetail")}
            </span>
          </p>
          <p>
            {t("refundPolicyNote3")}
            <span className="font-weight-bold underline">
              {" "}
              {t("showDetail")}
            </span>
          </p>
        </div>
      </div>
      <div className="py-3">
        <div className="d-flex flex-row justify-content-start align-items-center mb-3">
          <div
            className="mr-2"
            style={{
              width: 50,
              height: 50,
              background:
                'url("/images/free-icon-calendar-2535877.png") 50% 50% / contain no-repeat',
            }}
          />
          <p>
            {t("refundPolicyNote4")}
            <br />
            {t("refundPolicyNote5")}
          </p>
        </div>
        <p>{t("refundPolicyNote6")}</p>
      </div>
    </>
  );
}
