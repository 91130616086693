import React, { useState, useEffect } from "react";
import BuddibleSocket, { MsgIDList } from "./BuddibleSocket";
import Utilities from "./Utilities";

let util = new Utilities();
let loginMSG = {};

const AutoLogin = () => {
  let socket = new BuddibleSocket();
  // console.log("AutoLogin Ready!");
  // console.log(sessionStorage, localStorage);
  let loginInfo = sessionStorage.getItem("loginInfo");
  if (loginInfo === null) loginInfo = localStorage.getItem("loginInfo");

  try {
    loginInfo = util.buddibleDecrypt(loginInfo);
    loginInfo = JSON.parse(loginInfo);
  } catch (e) {
    loginInfo = null;
    sessionStorage.removeItem("loginInfo");
    localStorage.removeItem("loginInfo");
  }
  // if (loginInfo === null) {
  //   loginInfo = localStorage.getItem("loginInfo");
  //   try {
  //     loginInfo = util.buddibleDecrypt(loginInfo);
  //     loginInfo = JSON.parse(loginInfo);
  //   } catch (e) {
  //     loginInfo = null;
  //     localStorage.removeItem("loginInfo");
  //   }
  // }

  if (loginInfo === null) {
    socket.sendLocalMessage(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      "AutoLogin",
      {},
      () => {}
    );
    console.log("loginInfo is null");
  }
  let now = +new Date();
  if (loginInfo) {
    // console.log(loginInfo);
    // console.log(loginInfo.storageLifeTime);
    // console.log(now);
    if (loginInfo.storageLifeTime < now) {
      loginInfo = null;
      localStorage.removeItem("loginInfo");
    }
  }

  useEffect(() => {
    //console.log("AutoLogin waitingForConnect!");
    if (loginInfo === null) {
      socket.sendLocalMessage(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        "AutoLogin",
        {},
        () => {}
      );
      console.log("loginInfo is null");
      return;
    }
    socket.waitingForConnect("AutoLogin", () => {
      //초기 소켓 연결대기 후 초기값 요청

      //console.log("AutoLogin Start");
      // this.GetSingleDataForGuide();
      loginToServer();
    });
    socket.addLocalEventListener(
      MsgIDList.EVENT_AUTO_RECONNECTED,
      "AutoLogin",
      (beforeData, newData) => {
        loginToServer();
      }
    );
  }, []);

  const loginToServer = () => {
    let msgID = util.makeUUIDv4();
    // console.log(loginInfo);

    if (
      !loginInfo.hasOwnProperty("u_oauth_type") ||
      !loginInfo.hasOwnProperty("u_pwd")
    ) {
      sessionStorage.removeItem("loginInfo");
      localStorage.removeItem("loginInfo");
      return;
    }

    // if (!loginInfo.hasOwnProperty("data")) {
    //   sessionStorage.removeItem("loginInfo");
    //   localStorage.removeItem("loginInfo");
    //   return;
    // } else {
    //   if (
    //     !loginInfo.data.hasOwnProperty("u_oauth_type") ||
    //     !loginInfo.data.hasOwnProperty("u_pwd")
    //   ) {
    //     sessionStorage.removeItem("loginInfo");
    //     localStorage.removeItem("loginInfo");
    //     return;
    //   }
    // }
    // loginMSG = {
    //   method: "Login",
    //   data: {
    //     u_oauth_type: loginInfo.data.u_oauth_type, // facebook, google, buddible
    //     u_tokenID: loginInfo.data.u_tokenID, // tokenId
    //     u_oauth_id: loginInfo.data.u_oauth_id, // googleId, facebookId
    //     u_accessToken: loginInfo.data.u_accessToken,
    //     u_email: loginInfo.data.u_email, //필수
    //     u_pwd:
    //       loginInfo.data.u_oauth_type === "buddible"
    //         ? loginInfo.data.u_pwd
    //         : "", //필수
    //   },
    // };

    // console.log(loginInfo);

    let pw;
    if (loginInfo.u_oauth_type === "google") {
      pw = "";
    } else {
      pw = util.buddibleDecrypt(loginInfo.u_pwd);
    }

    let updateData = {
      user_id: util.buddibleEncrypt(loginInfo.u_email),
      user_pw: pw,
      u_oauth_type: loginInfo.u_oauth_type,
      u_tokenID: loginInfo.u_tokenID,
      u_oauth_id: loginInfo.u_oauth_id,
    };

    let socketMsg = {
      file: "/login/JS_login.php",
      msgID: msgID,
      data: {},
      ...updateData,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        // console.log("newData", newData);
        if (newData["ret"]) {
          let loginInfo = newData["returnData"][0];
          // let data = socketMsg["data"];
          loginInfo = { ...loginInfo };
          //console.log("socket.sendMessage", loginInfo);
          let encrypt = JSON.stringify(loginInfo);
          encrypt = util.buddibleEncrypt(encrypt);

          sessionStorage.setItem("loginInfo", encrypt);
          localStorage.setItem("loginInfo", encrypt);
          /*if (loginSaveCheck) {
            localStorage.setItem("loginInfo", loginInfo);
          }*/
          // console.log(encrypt);
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "AutoLogin",
            loginInfo,
            () => {}
          );
        } else {
          socket.sendLocalMessage(
            MsgIDList.EVENT_LOGIN_AUTO_FAIL,
            "AutoLogin",
            {},
            () => {}
          );
        }
      }
      this.loginErrCheck(newData);
    });
  };
  return <></>;
};

export default AutoLogin;
