/*global google*/
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Nav from "../common/Nav";
import MapList from "./component/MapList";

import Utilities from "../../lib/Utilities";
import CodeList from "../../lib/CodeList";
import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "SearchMap";

let mapApi = {
  map: null,
  infoWindow: null,
  infoWindows: [],
  detailInfoWindow: null,
  userLocation: { lat: 37.663381958, lng: 126.799115781 },
  directionsService: null,
  directionsRenderer: null,
  timer: null,
  mapLocation: { lat: 37.663381958, lng: 126.799115781 },
  popup: null,
  lastCenter: { lat: 0, lng: 0 },
  lastZoom: 8,
  defaultZoom: 8,
  markerCluster: null,
  CustomOverlay: null,
  keyword: {},
};
// let hashTag = [];
let isMapView = true;
let filter = {
  SEARCH_WORD: "",
  rl_guest_min: 1,
  rl_rating: 0,
};
export default function SearchMap(props) {
  const { t, i18n } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [serviceList, setServiceList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (!mapApi.map) {
      getUserLocation()
        .then(() => {
          // console.log(mapApi);
        })
        .catch((e) => {
          console.log("오류로 인해 위치기반 지도를 불러오지 못함", e);
        });
    }

    if (!props.location.state) {
      getUserLocation()
        .then(() => {
          // console.log(mapApi);
        })
        .catch((e) => {
          console.log("오류로 인해 위치기반 지도를 불러오지 못함", e);
        });
      //  지도 검색데이터가 같이 안넘어온 경우
    } else {
      //  검색 데이터가 같이 넘어온 경우
      let searchData = { ...props.location.state };

      getUserLocation()
        .then(() => {
          // console.log(mapApi);
        })
        .catch((e) => {
          console.log("오류로 인해 위치기반 지도를 불러오지 못함", e);
        });
      //  지도 검색데이터가 같이 안넘어온 경우
    }

    /*    socket.addLocalEventListener(
      MsgIDList.EVENT_SBM_HASHTAG_CHANGED,
      _mClassName,
      (oldData, newData) => {
        hashTag = newData;
        requestTourByMap();
      }
    );*/

    socket.addLocalEventListener(
      MsgIDList.EVENT_NAV_SEARCH_DATA_CHANGED,
      _mClassName,
      (oldData, newData) => {
        if (newData.hasOwnProperty("searchCountry")) {
          if (newData.searchCountry.hasOwnProperty("location")) {
            mapApi.map.setZoom(mapApi.defaultZoom);
            mapApi.map.setCenter(newData.searchCountry.location);
          }
        }
      }
    );

    socket.addLocalEventListener(
      MsgIDList.EVENT_SEARCHMAP_CENTER_CAHNGED,
      _mClassName,
      (b, n) => {
        if (!mapApi.map) return;
        mapApi.map.setCenter(n);
        mapApi.map.setZoom(12);
      }
    );
    handleMobileView();
    window.addEventListener("resize", handleMobileView);
    return () => {
      window.removeEventListener("resize", handleMobileView);
      /*      socket.removeLocalEventListener(
        MsgIDList.EVENT_SBM_HASHTAG_CHANGED,
        _mClassName
      );*/
      socket.removeLocalEventListener(
        MsgIDList.EVENT_NAV_SEARCH_DATA_CHANGED,
        _mClassName
      );

      mapApi = {
        map: null,
        infoWindow: null,
        infoWindows: [],
        detailInfoWindow: null,
        userLocation: { lat: 37.663381958, lng: 126.799115781 },
        directionsService: null,
        directionsRenderer: null,
        timer: null,
        mapLocation: { lat: 37.663381958, lng: 126.799115781 },
        popup: null,
        lastCenter: { lat: 0, lng: 0 },
        lastZoom: 8,
        defaultZoom: 8,
        markerCluster: null,
        CustomOverlay: null,
        keyword: {},
      };
      // hashTag = [];
      isMapView = false;
    };
  }, []);

  // 모바일 화면 핸들러
  const handleMobileView = () => {
    let windowWidth = window.innerWidth;
    if (windowWidth >= 992) {
      isMapView = false;
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  //사용자 위치정보 받아오기
  const getUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          mapApi.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          mapApi.mapLocation.lat = position.coords.latitude;
          mapApi.mapLocation.lng = position.coords.longitude;
          initMap();
        },
        (err) => {
          initMap();
          // console.log("getUserLocation", err);
        }
      );
    } else {
      // Browser doesn't support Geolocation
      // handleLocationError(false, mapApi.infoWindow, mapApi.map.getCenter());
    }
  };

  //지도 그리기
  const initMap = () => {
    if (!mapApi.map) {
      mapApi.map = new google.maps.Map(document.getElementById("map"), {
        zoom: mapApi.lastZoom,
        center: mapApi.mapLocation,
      });
    }

    requestTourByMap();

    mapApi.map.addListener("zoom_changed", () => {
      if (mapApi.timer) clearTimeout(mapApi.timer);
      mapApi.timer = setTimeout(() => {
        let zoom = mapApi.map.getZoom();

        if (mapApi.lastZoom > zoom) {
          mapApi.lastZoom = zoom;

          requestTourByMap();
        }
      }, 3000);
    });

    mapApi.map.addListener("bounds_changed", () => {
      if (mapApi.timer) clearTimeout(mapApi.timer);
      mapApi.timer = setTimeout(() => {
        if (mapApi.map) {
          let mapCenter = mapApi.map.getCenter();

          if (
            Math.abs(mapApi.mapLocation.lat - mapCenter.lat()) +
              Math.abs(mapApi.mapLocation.lng - mapCenter.lng()) >
            0.05
          ) {
            mapApi.mapLocation.lat = mapCenter.lat();
            mapApi.mapLocation.lng = mapCenter.lng();

            requestTourByMap();
          }
        }
      }, 3000);
    });
    //
  };

  //커스텀 마커생성
  const addInfoWindow = (data, callback) => {
    if (!data) {
      mapApi.map.setCenter(mapApi.userLocation);
    } else {
      for (let i = 0; i < mapApi.infoWindows.length; i++) {
        mapApi.infoWindows[i].close();
      }
      mapApi.infoWindows = [];

      data.map((item, i) => {
        let infoWindow = new google.maps.InfoWindow({
          disableAutoPan: true,
          isHidden: false,
          pixelOffset: new google.maps.Size(-10, -10),
          closeBoxURL: "",
          pane: "mapPane",
          enableEventPropagation: true,
          zIndex: 0,
        });
        let location = {
          lat: item.rl_addr_lat,
          lng: item.rl_addr_log,
        };

        infoWindow.setPosition(location);

        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");
        bubbleAnchor.classList.add("popup-bubble-anchor");

        let containerDiv = document.createElement("div");
        containerDiv.id = `content_${item.rl_idx}`;
        containerDiv.classList.add("searchByMapMap_Title");
        containerDiv.classList.add("cursor_pointer");
        containerDiv.innerText = util.CheckMaxString(item.rl_title, 20);
        containerDiv.appendChild(bubbleAnchor);

        infoWindow.setContent(containerDiv);
        infoWindow.open(mapApi.map);
        mapApi.infoWindows.push(infoWindow);

        google.maps.event.addDomListener(containerDiv, "click", clickedEvent);
        google.maps.event.addDomListener(containerDiv, "mouseover", (e) => {
          infoWindow.setZIndex(10);
        });
        google.maps.event.addDomListener(containerDiv, "mouseout", (e) => {
          infoWindow.setZIndex(0);
        });

        function clickedEvent() {
          if (mapApi.detailInfoWindow) {
            mapApi.detailInfoWindow.close();
            mapApi.detailInfoWindow = null;
          }

          const SUBJECT_CODE = {
            NAT: t("codeNAT"),
            SOC: t("codeSOC"),
            SHO: t("codeSHO"),
            RES: t("codeRES"),
            BEC: t("codeBEC"),
            NMK: t("codeNMK"),
            BEV: t("codeBEV"),
            NVW: t("codeNVW"),
            OAT: t("codeOAT"),
            SPO: t("codeSPO"),
            ETM: t("codeETM"),
          };

          let infoWindow = new google.maps.InfoWindow({
            disableAutoPan: true,
            isHidden: false,
            pixelOffset: new google.maps.Size(-10, -10),
            closeBoxURL: "",
            pane: "mapPane",
            enableEventPropagation: true,
            zIndex: 1000,
          });
          let location = {
            lat: item.rl_addr_lat,
            lng: item.rl_addr_log,
          };
          infoWindow.setPosition(location);

          let iconClass = "";

          switch (item.rl_subject) {
            case "NAT":
              iconClass = "icon_set_1_icon-24";
              break;
            case "SOC":
              iconClass = "icon_set_1_icon-1";
              break;
            case "SHO":
              iconClass = "icon_set_1_icon-50";
              break;
            case "RES":
              iconClass = "icon_set_1_icon-14";
              break;
            case "BEC":
              iconClass = "icon_set_2_icon-108";
              break;
            case "NMK":
              iconClass = "icon_set_3_restaurant-9";
              break;
            case "BEV":
              iconClass = "icon_set_1_icon-60";
              break;
            case "NVW":
              iconClass = "icon_set_1_icon-24";
              break;
            case "OAT":
              iconClass = "icon_set_1_icon-30";
              break;
            case "SPO":
              iconClass = "icon_set_1_icon-40";
              break;

            case "ETM":
              iconClass = "icon_set_1_icon-3";
              break;

            default:
              iconClass = "";
              break;
          }

          // This zero-height div is positioned at the bottom of the bubble.
          const bubbleAnchor = document.createElement("div");
          bubbleAnchor.classList.add("popup-bubble-anchor");

          const img = util.changedResourceUrl(item.rl_pic_main);

          let containerDiv = document.createElement("div");
          containerDiv.id = `map_content`;
          containerDiv.classList.add("map_content_wrap");
          containerDiv.innerHTML = `
            <a href="/TourDetail/${
              item.rl_idx
            }" target="_blank" class="map_content">
                <div class="map_content_img rounded rounded-lg " style="background: url('${img}') 50% 50% / cover no-repeat;"></div>
                <div class="text-center p-2">
                <h6 class="mt-1 font-weight-bold">${item.rl_title}</h6>
                <div class="d-flex flex-row justify-content-between align-items-center">
                      <div><i class="${iconClass}"></i>${t(
            "code" + item.rl_subject
          )}</div>
                      <div class="rating"><small><i class="icon-star voted"></i>(${
                        item.rl_rating_cnt
                      })</small>
                      </div>
                </div>
                <p class="mb-3 text-left"><i class="fa-solid fa-people-group text-secondary"></i> ${t(
                  "base"
                )} ${item.rl_guest_min}${t(
            "person"
          )} / &#8361; ${util.addCommas(item.rl_price_base)}</p>
                
                <p class="ellipsis_2 mb-2">${item.rl_desc}</p>
                <div class="btn_1 py-1 px-3 font-size-08">${t(
                  "showDetail"
                )}</div>
                </div>
            </a>
          `;
          containerDiv.appendChild(bubbleAnchor);

          infoWindow.setContent(containerDiv);
          infoWindow.open(mapApi.map);
          mapApi.detailInfoWindow = infoWindow;
          function movePage(url) {
            this.props.history.push(url);
          }
        }
      });
    }
    setIsLoading(false);
    setServiceList(data);
    callback && callback();
  };

  // 여행 정보 가져오기
  const requestTourByMap = () => {
    setIsLoading(true);
    let isConnected = socket.isConnected();
    if (!isConnected) {
      setTimeout(requestTourByMap, 1000);
      return;
    }

    let msgID = util.makeUUIDv4();
    let bound = mapApi.map.getBounds();

    let socketMsg = {
      file: "/searchMap/JS_search_map_tour_select.php",
      msgID: msgID,
      data: {},
      rl_use_yn: "Y",
      rl_del_yn: "N",
      rl_confirm_admin: "Y",
      ...filter,
    };

    if (bound) {
      let setBound = { ...bound };
      socketMsg = {
        ...socketMsg,
        east: setBound.Ia?.hi, //동
        west: setBound.Ia?.lo, //서
        south: setBound.Ua?.lo, //남
        north: setBound.Ua?.hi, //북
      };
    }

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let returnData = newData["returnData"];
          for (let i = 0; i < returnData.length; i++) {
            returnData[i].rl_addr_lat =
              parseFloat(returnData[i].rl_addr_lat) +
              (Math.random() * (0.006 - 0.001) -
                Math.random() * (0.006 - 0.001));
            returnData[i].rl_addr_log =
              parseFloat(returnData[i].rl_addr_log) +
              (Math.random() * (0.006 - 0.001) -
                Math.random() * (0.006 - 0.001));
          }

          addInfoWindow(returnData);
        } else {
          setTimeout(requestTourByMap, 1000);
        }
      }
    });
  };

  // 검색 조건 변경
  const changedFilters = (data) => {
    filter = {
      ...filter,
      ...data,
    };
    requestTourByMap();
  };

  return (
    <>
      <Nav />
      <div
        className={`position-fixed h-100 w-100 text-white ${
          isLoading ? "d-flex" : "d-none"
        } flex-column justify-content-center align-items-center`}
        style={{ zIndex: 999, background: "rgba(0,0,0,0.2)" }}
      >
        <p className="h1">
          <i className="fa-solid fa-spinner loading" />
        </p>
        <p className="h1">Loading..</p>
      </div>
      <div className="container-fluid full-height">
        <div className="row row-height">
          <div
            id="mapListWrap"
            className={`col-lg-6 bg-white p-0 ${
              isMapView ? "d-none" : "d-block"
            }`}
            style={{ maxHeight: "100vh" }}
          >
            <MapList
              serviceList={serviceList}
              changedFilters={changedFilters}
            />
          </div>

          <div
            className={`col-lg-6 map-right h-100 ${
              isMapView || !isMobile ? "d-block" : "d-none"
            }`}
          >
            <div id="test" className="sbMapbox h-100">
              <div
                className="googleMap"
                style={{ height: "100vh" }}
                key="AIzaSyDKXA_Dj4ZejIXaFA7I_p9Ry9o7c8s4QZQ"
              >
                <div
                  id={"map"}
                  className="googleMap_map"
                  style={{ height: "100vh" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && (
        <div
          className={`btn btn_1 rounded-pill shadow `}
          style={{
            position: "fixed",
            bottom: "50px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 99999,
          }}
          onClick={() => {
            isMapView = !isMapView;
            setIsNeedUpdate(!isNeedUpdate);
          }}
        >
          {isMapView ? t("list") : t("byMap")}
        </div>
      )}
    </>
  );
}
