import React, { useState } from "react";
import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import CodeList from "../../../../../lib/CodeList";
import UploadImageToS3WithReactS3 from "../../../../../lib/UploadImageToS3WithReactS3";

const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "GuideRegistBasicInfoProgram";
export default function GuideRegistBasicInfoProgram(props) {
  const { guideData, loginInfo } = props;
  const [picMain, setPicMain] = useState(guideData.gl_pic_main);

  const changedGuideInfo = (key, value) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      { [key]: value },
      () => {}
    );
  };

  const uploadCallback = (response) => {
    if (!response.ret) {
      codeList.Modal.current.alert(
        "오류가 발생했습니다. 다시 시도해주세요.",
        () => {}
      );
      return;
    }

    const file = `${response.realFileName}?${response.fileName}`;
    changedGuideInfo("gl_pic_main", file);
    setPicMain(file);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3">
        <p className="h6 font-weight-bold">대표이미지</p>

        {picMain && (
          <div
            className="my-2 rounded min-height-300"
            style={{
              background: `url(${
                picMain?.split("?")[1]
              }) 50% 50%/cover no-repeat`,
            }}
          />
        )}

        <UploadImageToS3WithReactS3
          region={`images/guide/${loginInfo.u_email}`}
          callback={uploadCallback}
          disabled={guideData.gl_confirm_admin === "S"}
        />
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <p className="h6 font-weight-bold">소개글</p>
        <input
          className="form-control mb-3"
          defaultValue={guideData.gl_title}
          placeholder="소개글을 작성해주세요"
          maxLength={100}
          disabled={guideData.gl_confirm_admin === "S"}
          onChange={(e) => changedGuideInfo("gl_title", e.target.value)}
        />
      </div>
      <p className="h6 font-weight-bold">※ 참고사항 및 예시화면</p>
      <p>
        대표이미지와 소개글은 인기버디 목록의 버디 프로필 및 가이드 상세보기의
        상단 이미지와 타이틀로 표시 됩니다. 인물 사진이 아닌 버디활동을하며
        여행자에게 제공하는 서비스에 대해 대표하는 사진을 업로드 해주세요
      </p>
      <p className="font-size-09 mb-3">
        ex) 주 투어 관광지 사진, 체험 액티비티 이미지
      </p>
      <section
        className="parallax-window rounded overflow-hidden"
        data-parallax="scroll"
        data-image-src="https://playyj.s3.ap-northeast-2.amazonaws.com/images/guide/Amber.Jung@buddible.com/hotplace_20231207171600.jpg"
        data-natural-width="100%"
        data-natural-height="300"
        style={{
          height: 300,
          minHeight: "unset",
          background: `url(https://playyj.s3.ap-northeast-2.amazonaws.com/images/guide/Amber.Jung@buddible.com/hotplace_20231207171600.jpg)50% 50%/cover no-repeat`,
        }}
      >
        <div className="parallax-content-2">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <p className="h4 font-weight-bold mb-1">소개글 영역입니다.</p>
                <span className="pr-2">서비스 지역</span>
                <span className="pr-2">가이드 닉네임</span>
                <span className="rating">
                  <i
                    className={
                      guideData.gl_rating > 0
                        ? "icon-star voted"
                        : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      guideData.gl_rating > 1
                        ? "icon-star voted"
                        : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      guideData.gl_rating > 2
                        ? "icon-star voted"
                        : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      guideData.gl_rating > 3
                        ? "icon-star voted"
                        : "icon-star-empty"
                    }
                  />
                  <i
                    className={
                      guideData.gl_rating > 4
                        ? "icon-star voted"
                        : "icon-star-empty"
                    }
                  />

                  <small>(0)</small>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
