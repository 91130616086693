import React, { useEffect, useRef, useState } from "react";
import GuideReserveManageTable from "./GuideReserveManageTable";

import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuideReserveManage";

const SERVICE_TYPE = [
  { key: "rl_title", desc: "서비스명" },
  { key: "u_name_full", desc: "예약자명" },
];
const RESERVE_TYPE = [
  { key: "CR", desc: "요청" },
  { key: "GA", desc: "승인" },
  { key: "CC", desc: "취소" },
  { key: "GC", desc: "거절" },
];
const DateRangeList = {
  RANGE_THIS_WEEK: "RANGE_THIS_WEEK",
  RANGE_THIS_MONTH: "RANGE_THIS_MONTH",
  RANGE_THREE_MONTH: "RANGE_THREE_MONTH",
  RANGE_SIX_MONTH: "RANGE_SIX_MONTH",
  RANGE_ONE_YEAR: "RANGE_ONE_YEAR",
};
let dateBtnList = [
  { id: "week", desc: "이번주", value: DateRangeList.RANGE_THIS_WEEK },
  { id: "month", desc: "이번달", value: DateRangeList.RANGE_THIS_MONTH },
  { id: "3month", desc: "3개월", value: DateRangeList.RANGE_THREE_MONTH },
  { id: "6month", desc: "6개월", value: DateRangeList.RANGE_SIX_MONTH },
  { id: "year", desc: "1년", value: DateRangeList.RANGE_ONE_YEAR },
];
let reserve_current_month = {
  CR: { count: 0, cost: 0 },
  GA: { count: 0, cost: 0 },
  CC: { count: 0, cost: 0 },
};

let filter = {
  SEARCH_WORD: "",
  SEARCH_LEVEL: "",
  searchState: "",
  searchStartDate: util.getDateToFormat(new Date().setDate(1), "YYYY-MM-DD"),
  searchEndDate: util.getDateToFormat(new Date(), "YYYY-MM-DD"),
};
let current_month =
  new Date().getMonth() + 1 < 10
    ? "0" + (new Date().getMonth() + 1)
    : new Date().getMonth() + 1;

export default function GuideReserveManage(props) {
  const { loginInfo } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  const $controller = {
    searchText: useRef(),
    searchState: useRef(),
    searchLevel: useRef(),
    searchStartDate: useRef(),
    searchEndDate: useRef(),
  };

  useEffect(() => {
    requestReserveCountWithMonth();
  }, []);

  //예약정보 받아오기
  const requestReserveCountWithMonth = () => {
    reserve_current_month = {
      CR: { count: 0, cost: 0 },
      GA: { count: 0, cost: 0 },
      CC: { count: 0, cost: 0 },
    };

    let endDate;
    let currentDate = new Date();
    let endYear = currentDate.getFullYear();
    let endMonth = currentDate.getMonth();
    endDate = new Date(endYear, endMonth, 0).getDate();

    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/reserve/JS_reserve_list_select.php",
      msgID: msgID,
      data: {},
      gl_guide_uid: loginInfo.u_uid,
      searchStartDate: util.getDateToFormat(
        new Date().setDate(1),
        "YYYY-MM-DD"
      ),
      searchEndDate: util.getDateToFormat(
        new Date().setDate(endDate),
        "YYYY-MM-DD"
      ),
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          for (let i = 0; i < newData["returnData"].length; i++) {
            const item = newData["returnData"][i];
            switch (item.rrl_status) {
              case "CC":
                reserve_current_month = {
                  ...reserve_current_month,
                  CC: {
                    count: ++reserve_current_month.CC.count,
                    cost:
                      reserve_current_month.CC.cost +
                      Number(item.rrl_cost_paid),
                  },
                };
                break;
              case "GC":
                reserve_current_month = {
                  ...reserve_current_month,
                  CC: {
                    count: ++reserve_current_month.CC.count,
                    cost:
                      reserve_current_month.CC.cost +
                      Number(item.rrl_cost_paid),
                  },
                };
                break;
              default:
                reserve_current_month = {
                  ...reserve_current_month,
                  [item.rrl_status]: {
                    count: ++reserve_current_month[item.rrl_status].count,
                    cost:
                      reserve_current_month[item.rrl_status].cost +
                      Number(item.rrl_cost_paid),
                  },
                };
                break;
            }
          }
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  //버튼 날짜 변경
  const changeDatePicker = (e) => {
    const value = e.target.dataset.range;
    const today = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let theDayOfWeek = today.getDay();

    switch (value) {
      case DateRangeList.RANGE_THIS_WEEK:
        startDate.setDate(startDate.getDate() - theDayOfWeek);
        break;
      case DateRangeList.RANGE_THIS_MONTH:
        startDate.setDate(1);
        break;
      case DateRangeList.RANGE_THREE_MONTH:
        startDate.setMonth(startDate.getMonth() - 3);
        break;
      case DateRangeList.RANGE_SIX_MONTH:
        startDate.setMonth(startDate.getMonth() - 6);
        break;
      case DateRangeList.RANGE_ONE_YEAR:
        startDate.setMonth(startDate.getMonth() - 12);
        break;
    }
    filter = {
      ...filter,
      searchStartDate: util.getDateToFormat(startDate, "YYYY-MM-DD"),
      searchEndDate: util.getDateToFormat(endDate, "YYYY-MM-DD"),
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
      _mClassName,
      filter,
      () => {}
    );
    setIsNeedUpdate(!isNeedUpdate);
  };

  //검색
  const searchFilterChanged = (data) => {
    filter = {
      ...filter,
      ...data,
    };
    socket.fireLocalEventListener(
      MsgIDList.EVENT_RESERVATION_MANAGE_FILTER_CHANGED,
      _mClassName,
      filter,
      () => {}
    );
  };

  //검색 필터 초기화
  const searchWordClear = () => {
    $controller.searchText.current.value = "";
  };

  return (
    <div className="container-fluid mw-100">
      <div className="content">
        <div className="container-florder_uid p-0">
          <div className="row m-0 p-0 mb-2 mb-xl-3">
            <div className="col-auto d-none d-block p-0">
              <h3 className="font-weight-bold">
                {current_month}월 요청/승인/취소/거절 현황
              </h3>
            </div>
          </div>
          <div className="row m-0 p-0">
            <div
              className="col-12 col-md-3 col-xl-2 border m-0 p-3 rounded-lg mr-3 mb-3"
              style={{ height: 150 }}
            >
              <p className="h5 font-weight-bold text-secondary">요청</p>
              <p className="h4 font-weight-bold text-buddible text-right">
                {reserve_current_month.CR.count
                  ? reserve_current_month.CR.count
                  : "-"}{" "}
                건
              </p>
              <p className="h4 font-weight-bold text-buddible text-right">
                {reserve_current_month.CR.cost
                  ? util.addCommas(reserve_current_month.CR.cost)
                  : "-"}{" "}
                원
              </p>
            </div>
            <div
              className="col-12 col-md-3 col-xl-2 border m-0 p-3 rounded-lg mr-3 mb-3"
              style={{ height: 150 }}
            >
              <p className="h5 font-weight-bold text-secondary">승인</p>
              <p className="h4 font-weight-bold text-buddible text-right">
                {reserve_current_month.GA.count
                  ? reserve_current_month.GA.count
                  : "-"}{" "}
                건
              </p>
              <p className="h4 font-weight-bold text-buddible text-right">
                {reserve_current_month.GA.cost
                  ? util.addCommas(reserve_current_month.GA.cost)
                  : "-"}{" "}
                원
              </p>
            </div>
            <div
              className="col-12 col-md-3 col-xl-2 border m-0 p-3 rounded-lg mr-3 mb-3"
              style={{ height: 150 }}
            >
              <p className="h5 font-weight-bold text-secondary">취소 / 거절</p>
              <p className="h4 font-weight-bold text-buddible text-right">
                {reserve_current_month.CC.count
                  ? reserve_current_month.CC.count
                  : "-"}{" "}
                건
              </p>
              <p className="h4 font-weight-bold text-buddible text-right">
                {reserve_current_month.CC.cost
                  ? util.addCommas(reserve_current_month.CC.cost)
                  : "-"}{" "}
                원
              </p>
            </div>
          </div>
          <div className="row m-0 p-0 mb-2 mb-xl-3">
            <div className="col-auto m-0 p-0 d-none d-block">
              <h3 className="font-weight-bold">예약 관리</h3>
            </div>
          </div>
          <div className="row m-0 p-0 ">
            <div className="col m-0 p-0 p-0">
              <div className="card">
                <div className="card-header">
                  {/*필터 영역*/}
                  <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                    {/*간편기간 영역*/}
                    <div
                      className="btn-toolbar mb-3 justify-content-md-between justify-content-end"
                      role="toolbar"
                    >
                      <div
                        className="d-flex flex-row m-0 p-0 flex-wrap mt-2"
                        role="group"
                      >
                        {dateBtnList.map((btn, index) => {
                          return (
                            <div className="mr-2 mb-2 flex-fill" key={index}>
                              <button
                                className={`btn btn-outline-info w-100 px-3`}
                                data-range={btn.value}
                                onClick={changeDatePicker}
                              >
                                {btn.desc}
                              </button>
                            </div>
                          );
                        })}
                      </div>

                      <div className="d-flex flex-row m-0 p-0 justify-content-end flex-wrap pt-2">
                        <div
                          className="input-group border rounded overflow-hidden mr-2 mb-2"
                          style={{ maxWidth: "100%", height: 40 }}
                        >
                          <input
                            className="form-control py-0 m-0 rounded border-0"
                            style={{ height: 40 }}
                            type="date"
                            value={filter.searchStartDate}
                            ref={$controller.searchStartDate}
                            onChange={(e) => {
                              filter.searchStartDate = e.target.value;
                              if (e.target.value > filter.searchEndDate) {
                                filter.searchEndDate = e.target.value;
                              }
                              setIsNeedUpdate(!isNeedUpdate);
                            }}
                          />
                          <div
                            className="input-group-text border rounded-0"
                            style={{ height: 40 }}
                          >
                            -
                          </div>
                          <input
                            className="form-control py-0 m-0 border-0"
                            style={{ height: 40 }}
                            type="date"
                            value={filter.searchEndDate}
                            ref={$controller.searchEndDate}
                            onChange={(e) => {
                              filter.searchEndDate = e.target.value;
                              if (e.target.value < filter.searchStartDate) {
                                filter.searchStartDate = e.target.value;
                              }

                              setIsNeedUpdate(!isNeedUpdate);
                            }}
                          />
                        </div>
                        <div className="d-inline-block mr-2 mb-2">
                          <select
                            className="custom-select form-control w-100"
                            ref={$controller.searchState}
                            onChange={(e) =>
                              searchFilterChanged({
                                searchState: e.target.value,
                              })
                            }
                            style={{ minWidth: 100 }}
                          >
                            <option value="">-상태-</option>
                            {RESERVE_TYPE.map((menu, index) => {
                              return (
                                <option
                                  key={menu.key + "_" + index}
                                  value={menu.key}
                                >
                                  {menu.desc}
                                </option>
                              );
                            })}
                          </select>
                        </div>

                        <div className="input-group flex-wrap order-1 order-md-1 order-lg-1 mr-2">
                          <select
                            className="custom-select form-control mb-2"
                            ref={$controller.searchLevel}
                            onChange={(e) =>
                              searchFilterChanged({
                                SEARCH_LEVEL: e.target.value,
                              })
                            }
                            style={{ minWidth: 100 }}
                          >
                            {SERVICE_TYPE.map((menu, index) => {
                              return (
                                <option
                                  key={menu.key + "_" + index}
                                  value={menu.key}
                                >
                                  {menu.desc}
                                </option>
                              );
                            })}
                          </select>

                          <input
                            type="text"
                            className="form-control flex-fill mb-2"
                            placeholder="검색어"
                            aria-label="검색어"
                            aria-describedby="btnGroupAddon2"
                            ref={$controller.searchText}
                            onKeyPress={(e) => {
                              if (e.charCode === 13) {
                                searchFilterChanged({
                                  SEARCH_WORD: e.target.value,
                                });
                              }
                            }}
                            onClick={() => {
                              searchWordClear();
                            }}
                            style={{ minWidth: 100 }}
                          />
                        </div>
                        <div className="flex-grow-1 order-last mr-2">
                          <button
                            className="btn_1 btn_full"
                            onClick={() => searchFilterChanged({})}
                          >
                            <i className="fas fa-search mr-2" />
                            검색
                          </button>
                        </div>
                      </div>
                    </div>
                  </h6>
                </div>
                <GuideReserveManageTable
                  loginInfo={loginInfo}
                  setIsNeedUpdate={setIsNeedUpdate}
                  isNeedUpdate={isNeedUpdate}
                  requestReserveCountWithMonth={requestReserveCountWithMonth}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
