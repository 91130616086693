import React, { useEffect, useState } from "react";

const experience_duration = [
  {
    id: "3",
    name: "3시간",
    value: "03:00:00",
  },
  {
    id: "3.5",
    name: "3.5시간",
    value: "03:30:00",
  },
  {
    id: "4",
    name: "4시간",
    value: "04:00:00",
  },
  {
    id: "4.5",
    name: "4.5시간",
    value: "04:30:00",
  },
  {
    id: "5",
    name: "5시간",
    value: "05:00:00",
  },
  {
    id: "5.5",
    name: "5.5시간",
    value: "05:30:00",
  },
  {
    id: "6",
    name: "6시간",
    value: "06:00:00",
  },
  {
    id: "6.5",
    name: "6.5시간",
    value: "06:30:00",
  },
  {
    id: "7",
    name: "7시간",
    value: "07:00:00",
  },
  {
    id: "7.5",
    name: "7.5시간",
    value: "07:30:00",
  },
  {
    id: "8",
    name: "8시간",
    value: "08:00:00",
  },
  {
    id: "3",
    name: "3시간",
    value: "03:00:00",
  },
  {
    id: "3.5",
    name: "3.5시간",
    value: "03:30:00",
  },
  {
    id: "4",
    name: "4시간",
    value: "04:00:00",
  },
  {
    id: "4.5",
    name: "4.5시간",
    value: "04:30:00",
  },
  {
    id: "5",
    name: "5시간",
    value: "05:00:00",
  },
  {
    id: "5.5",
    name: "5.5시간",
    value: "05:30:00",
  },
  {
    id: "6",
    name: "6시간",
    value: "06:00:00",
  },
  {
    id: "6.5",
    name: "6.5시간",
    value: "06:30:00",
  },
  {
    id: "7",
    name: "7시간",
    value: "07:00:00",
  },
  {
    id: "7.5",
    name: "7.5시간",
    value: "07:30:00",
  },
  {
    id: "8",
    name: "8시간",
    value: "08:00:00",
  },
];

const experience_default_time = [
  {
    id: "0",
    name: "00:00",
    value: "00:00:00",
  },
  {
    id: "30",
    name: "00:30",
    value: "00:30:00",
  },
  {
    id: "60",
    name: "01:00",
    value: "01:00:00",
  },
  {
    id: "90",
    name: "01:30",
    value: "01:30:00",
  },
  {
    id: "120",
    name: "02:00",
    value: "02:00:00",
  },
  {
    id: "150",
    name: "02:30",
    value: "02:30:00",
  },
  {
    id: "180",
    name: "03:00",
    value: "03:00:00",
  },
  {
    id: "210",
    name: "03:30",
    value: "03:30:00",
  },
  {
    id: "240",
    name: "04:00",
    value: "04:00:00",
  },
  {
    id: "270",
    name: "04:30",
    value: "04:30:00",
  },
  {
    id: "300",
    name: "05:00",
    value: "05:00:00",
  },
  {
    id: "330",
    name: "05:30",
    value: "05:30:00",
  },
  {
    id: "360",
    name: "06:00",
    value: "06:00:00",
  },
  {
    id: "390",
    name: "06:30",
    value: "06:30:00",
  },
  {
    id: "420",
    name: "07:00",
    value: "07:00:00",
  },
  {
    id: "450",
    name: "07:30",
    value: "07:30:00",
  },
  {
    id: "480",
    name: "08:00",
    value: "08:00:00",
  },
  {
    id: "510",
    name: "08:30",
    value: "08:30:00",
  },
  {
    id: "540",
    name: "09:00",
    value: "09:00:00",
  },
  {
    id: "570",
    name: "09:30",
    value: "09:30:00",
  },
  {
    id: "600",
    name: "10:00",
    value: "10:00:00",
  },
  {
    id: "630",
    name: "10:30",
    value: "10:30:00",
  },
  {
    id: "660",
    name: "11:00",
    value: "11:00:00",
  },
  {
    id: "690",
    name: "11:30",
    value: "11:30:00",
  },
  {
    id: "720",
    name: "12:00",
    value: "12:00:00",
  },
  {
    id: "750",
    name: "12:30",
    value: "12:30:00",
  },
  {
    id: "780",
    name: "13:00",
    value: "13:00:00",
  },
  {
    id: "810",
    name: "13:30",
    value: "13:30:00",
  },
  {
    id: "840",
    name: "14:00",
    value: "14:00:00",
  },
  {
    id: "870",
    name: "14:30",
    value: "14:30:00",
  },
  {
    id: "900",
    name: "15:00",
    value: "15:00:00",
  },
  {
    id: "930",
    name: "15:30",
    value: "15:30:00",
  },
  {
    id: "960",
    name: "16:00",
    value: "16:00:00",
  },
  {
    id: "990",
    name: "16:30",
    value: "16:30:00",
  },
  {
    id: "1020",
    name: "17:00",
    value: "17:00:00",
  },
  {
    id: "1050",
    name: "17:30",
    value: "17:30:00",
  },
  {
    id: "1080",
    name: "18:00",
    value: "18:00:00",
  },
  {
    id: "1110",
    name: "18:30",
    value: "18:30:00",
  },
  {
    id: "1140",
    name: "19:00",
    value: "19:00:00",
  },
  {
    id: "1170",
    name: "19:30",
    value: "19:30:00",
  },
  {
    id: "1200",
    name: "20:00",
    value: "20:00:00",
  },
  {
    id: "1230",
    name: "20:30",
    value: "20:30:00",
  },
  {
    id: "1260",
    name: "21:00",
    value: "21:00:00",
  },
  {
    id: "1290",
    name: "21:30",
    value: "21:30:00",
  },
  {
    id: "1320",
    name: "22:00",
    value: "22:00:00",
  },
  {
    id: "1350",
    name: "22:30",
    value: "22:30:00",
  },
  {
    id: "1380",
    name: "23:00",
    value: "23:00:00",
  },
  {
    id: "1410",
    name: "23:30",
    value: "23:30:00",
  },
  {
    id: "0",
    name: "00:00",
    value: "00:00:00",
  },
  {
    id: "30",
    name: "00:30",
    value: "00:30:00",
  },
  {
    id: "60",
    name: "01:00",
    value: "01:00:00",
  },
  {
    id: "90",
    name: "01:30",
    value: "01:30:00",
  },
  {
    id: "120",
    name: "02:00",
    value: "02:00:00",
  },
  {
    id: "150",
    name: "02:30",
    value: "02:30:00",
  },
  {
    id: "180",
    name: "03:00",
    value: "03:00:00",
  },
  {
    id: "210",
    name: "03:30",
    value: "03:30:00",
  },
  {
    id: "240",
    name: "04:00",
    value: "04:00:00",
  },
  {
    id: "270",
    name: "04:30",
    value: "04:30:00",
  },
  {
    id: "300",
    name: "05:00",
    value: "05:00:00",
  },
  {
    id: "330",
    name: "05:30",
    value: "05:30:00",
  },
  {
    id: "360",
    name: "06:00",
    value: "06:00:00",
  },
  {
    id: "390",
    name: "06:30",
    value: "06:30:00",
  },
  {
    id: "420",
    name: "07:00",
    value: "07:00:00",
  },
  {
    id: "450",
    name: "07:30",
    value: "07:30:00",
  },
  {
    id: "480",
    name: "08:00",
    value: "08:00:00",
  },
  {
    id: "510",
    name: "08:30",
    value: "08:30:00",
  },
  {
    id: "540",
    name: "09:00",
    value: "09:00:00",
  },
  {
    id: "570",
    name: "09:30",
    value: "09:30:00",
  },
  {
    id: "600",
    name: "10:00",
    value: "10:00:00",
  },
  {
    id: "630",
    name: "10:30",
    value: "10:30:00",
  },
  {
    id: "660",
    name: "11:00",
    value: "11:00:00",
  },
  {
    id: "690",
    name: "11:30",
    value: "11:30:00",
  },
  {
    id: "720",
    name: "12:00",
    value: "12:00:00",
  },
  {
    id: "750",
    name: "12:30",
    value: "12:30:00",
  },
  {
    id: "780",
    name: "13:00",
    value: "13:00:00",
  },
  {
    id: "810",
    name: "13:30",
    value: "13:30:00",
  },
  {
    id: "840",
    name: "14:00",
    value: "14:00:00",
  },
  {
    id: "870",
    name: "14:30",
    value: "14:30:00",
  },
  {
    id: "900",
    name: "15:00",
    value: "15:00:00",
  },
  {
    id: "930",
    name: "15:30",
    value: "15:30:00",
  },
  {
    id: "960",
    name: "16:00",
    value: "16:00:00",
  },
  {
    id: "990",
    name: "16:30",
    value: "16:30:00",
  },
  {
    id: "1020",
    name: "17:00",
    value: "17:00:00",
  },
  {
    id: "1050",
    name: "17:30",
    value: "17:30:00",
  },
  {
    id: "1080",
    name: "18:00",
    value: "18:00:00",
  },
  {
    id: "1110",
    name: "18:30",
    value: "18:30:00",
  },
  {
    id: "1140",
    name: "19:00",
    value: "19:00:00",
  },
  {
    id: "1170",
    name: "19:30",
    value: "19:30:00",
  },
  {
    id: "1200",
    name: "20:00",
    value: "20:00:00",
  },
  {
    id: "1230",
    name: "20:30",
    value: "20:30:00",
  },
  {
    id: "1260",
    name: "21:00",
    value: "21:00:00",
  },
  {
    id: "1290",
    name: "21:30",
    value: "21:30:00",
  },
  {
    id: "1320",
    name: "22:00",
    value: "22:00:00",
  },
  {
    id: "1350",
    name: "22:30",
    value: "22:30:00",
  },
  {
    id: "1380",
    name: "23:00",
    value: "23:00:00",
  },
  {
    id: "1410",
    name: "23:30",
    value: "23:30:00",
  },
];

let time_data = {
  rl_base_hour_duration: 0, //기본 진행시간
  rl_base_hour_start: 0, // 시작시간
};

export default function GuideTourRegistSettingTime(props) {
  const { tourData, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (tourData) {
      for (let key in time_data) {
        time_data[key] = tourData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }

    return () => {
      setIsNeedUpdate(!isNeedUpdate);
    };
  }, []);

  const handleTimeDataChange = (key, val) => {
    time_data = {
      ...time_data,
      [key]: val,
    };
    setIsNeedUpdate(!isNeedUpdate);
    handleTourDataChange();
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = () => {
    guideTourRegistDataChange(time_data);
  };
  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3">
        <h3 className="font-weight-bold">가이드 진행 시간이 어떻게 되나요?</h3>
        <p>대부분의 가이드시간은 3시간 이상입니다.</p>
      </div>
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-2">가이드 진행 시간</h6>
        <select
          className="form-control"
          value={time_data.rl_base_hour_duration}
          disabled={tourData.rl_confirm_admin === "S"}
          onChange={(e) =>
            handleTimeDataChange("rl_base_hour_duration", e.target.value)
          }
        >
          <option value="-1" disabled>
            가이드 진행 시간
          </option>
          {experience_duration.map((data, index) => {
            return (
              <option key={`${data.id}_${index}`} value={data.value}>
                {data.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold">
          보통 몇 시에 가이드를 시작하실 건가요?
        </h6>
        <p>
          예 : 오후 4시에 시작하고 2시간이 소요되는 경우, 시작 시간을 4시로,
          끝나는 시간을 6시로 선택해주세요.
        </p>

        <h6 className="font-weight-bold mt-3 mb-2">가이드 시작 시간</h6>
        <select
          className="form-control"
          value={time_data.rl_base_hour_start}
          disabled={tourData.rl_confirm_admin === "S"}
          onChange={(e) =>
            handleTimeDataChange("rl_base_hour_start", e.target.value)
          }
        >
          <option value="-1" disabled>
            가이드 진행 시간
          </option>
          {experience_default_time.map((data, index) => {
            return (
              <option key={`${data.id}_${index}`} value={data.value}>
                {data.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
