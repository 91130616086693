import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import InitCodeList from "./lib/InitCodeList";
import ScrollToTop from "./lib/ScrollToTop";
import Loading from "./lib/Loading";
import BuddibleSocket, { MsgIDList } from "./lib/BuddibleSocket";

import Main from "./newBuddible/main/Main";
import SearchMap from "./newBuddible/searchMap/SearchMap";

import TourList from "./newBuddible/tourList/TourList";
import TourDetailList from "./newBuddible/tourDetailList/TourDetailList";
import TourDetail from "./newBuddible/tourDetail/TourDetail";
import GuideDetail from "./newBuddible/guideDetail/GuideDetail";

import ReserveService from "./newBuddible/reserveService/ReserveService";

import GuideManagement from "./newBuddible/guideManage/guideManagement/GuideManagement";

import GuideRegistIntro from "./newBuddible/guideRegistIntro/GuideRegistIntro";
import GuideRegist from "./newBuddible/guideManage/guideRegist/GuideRegist";
import GuideTourRegist from "./newBuddible/guideManage/guideTourRegist/GuideTourRegist";
import GuideTimeRegist from "./newBuddible/guideManage/guideTimeRegist/GuideTimeRegist";

import FAQ from "./newBuddible/faq/FAQ";

import ERP from "./newBuddible/erp/ERP";

import MyPage from "./newBuddible/myPage/MyPage";

import Terms from "./newBuddible/terms/Terms";

// 커뮤니티
import CommunityMain from "./newBuddible/community/CommunityMain";
import CommunityList from "./newBuddible/community/CommunityList";
import CommunityDetail from "./newBuddible/community/CommunityDetail";
import CommunityWrite from "./newBuddible/community/CommunityWrite";

import "./newBuddible/CommonStylesheet.css";

// -------다국어
import "./config/lang/i18n";
import Chat from "./newBuddible/chat/Chat";

class App extends Component {
  constructor(props) {
    super(props);
    this.socket = new BuddibleSocket();
    this.state = {
      isLoadingFinished: false,
      loadingOpen: false,
    };
  }

  componentDidMount() {
    this.socket.fireLocalEventListener(
      MsgIDList.EVENT_LOADING_INDICATOR,
      "App",
      this.loadingOpenHandler
    );
  }

  initFinished = () => {
    this.setState({ isLoadingFinished: true });
    document.getElementById("LoadingModalPage").remove();
  };

  loadingOpenHandler = (v) => {
    this.setState({
      loadingOpen: v,
    });
  };

  render() {
    return (
      <div>
        <InitCodeList initFinished={this.initFinished} />
        {this.state.loadingOpen && <Loading />}
        {this.state.isLoadingFinished && <AppMain />}
      </div>
    );
  }
}

function AppMain(props) {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/main" component={Main} />
        <Route exact path="/SearchMap" component={SearchMap} />
        <Route exact path="/TourList" component={TourList} />
        <Route exact path="/TourList/:travelType" component={TourList} />
        <Route exact path="/TourDetailList" component={TourDetailList} />{" "}
        <Route
          exact
          path="/TourDetailList/:travelType"
          component={TourDetailList}
        />
        <Route exact path="/TourDetail" component={TourDetail} />
        <Route exact path="/TourDetail/:rl_idx" component={TourDetail} />
        <Route exact path="/ReserveService" component={ReserveService} />
        <Route
          exact
          path="/ReserveService/:rl_idx"
          component={ReserveService}
        />
        <Route exact path="/GuideDetail" component={GuideDetail} />
        <Route exact path="/GuideDetail/:gl_idx" component={GuideDetail} />
        <Route exact path="/GuideManagement" component={GuideManagement} />
        <Route exact path="/GuideRegistIntro" component={GuideRegistIntro} />
        <Route exact path="/GuideRegist" component={GuideRegist} />
        <Route exact path="/GuideTourRegist" component={GuideTourRegist} />
        <Route exact path="/GuideTimeRegist" component={GuideTimeRegist} />
        <Route exact path="/FAQ" component={FAQ} />
        <Route exact path="/FAQ/:faqType" component={FAQ} />
        <Route exact path="/ERP" component={ERP} />
        <Route exact path="/MyPage" component={MyPage} />
        <Route exact path="/Terms" component={Terms} />
        <Route exact path="/Terms/:termsType" component={Terms} />
        <Route exact path="/GuideDetail" component={GuideDetail} />
        <Route exact path="/GuideDetail/:guide_idx" component={GuideDetail} />
        <Route exact path="/CommunityMain" component={CommunityMain} />
        <Route exact path="/CommunityList" component={CommunityList} />
        <Route exact path="/CommunityList/:cm_type" component={CommunityList} />
        <Route exact path="/CommunityDetail" component={CommunityDetail} />
        <Route
          exact
          path="/CommunityDetail/:cm_idx"
          component={CommunityDetail}
        />
        <Route exact path="/CommunityWrite" component={CommunityWrite} />
        <Route
          exact
          path="/CommunityWrite/:cm_idx"
          component={CommunityWrite}
        />{" "}
        <Route exact path="/Chat" component={Chat} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
