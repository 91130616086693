import React, { useEffect, useState } from "react";
import BuddibleSocket from "../../../../../lib/BuddibleSocket";
import Utilities from "../../../../../lib/Utilities";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

let photo_data = {
  gpt_pic_main: "",
  gpt_pics: "",
};

export default function GuideTimeRegistPhoto(props) {
  let { partTimeData, loginInfo, guideTimeRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);

  useEffect(() => {
    if (partTimeData) {
      for (let key in photo_data) {
        photo_data[key] = partTimeData[key];
      }
      setIsNeedUpdate(!isNeedUpdate);
    }
  }, []);

  //이미지 서버 업로드
  const handleFileUpload = (e) => {
    util.handleFileOnChange(e, (imageType, img, fileType) => {
      handleUploadFile(imageType, img, fileType);
    });
  };

  //업로드 이미지 데이터 세팅
  const handleUploadFile = (imageType, img, fileType) => {
    util.compressHandler(img, fileType).then((response) => {
      let setImage;
      if (imageType === "gpt_pic_main") {
        setImage = `/img/${response.data.fileName}`;
      } else if (imageType === "gpt_pics") {
        setImage = photo_data[imageType];
        if (setImage) {
          let imgArr = [];
          imgArr = [setImage];
          imgArr.push(`/img/${response.data.fileName}`);
          setImage = imgArr.join("|");
        } else {
          setImage = `/img/${response.data.fileName}`;
        }
      }
      photo_data[imageType] = setImage;
      guideTimeRegistDataChange(photo_data);
      setIsNeedUpdate(!isNeedUpdate);
    });
  };

  //갤러리 이미지 삭제
  const handleDeleteGalleryPhoto = (index) => {
    let photo_list = photo_data.gpt_pics ? photo_data.gpt_pics.split("|") : [];
    photo_list.splice(index, 1);
    photo_data.gpt_pics = photo_list.join("|");
    setIsNeedUpdate(!isNeedUpdate);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3 border-bottom">
        <p className="h3 font-weight-bold">가이드여행 사진 추가하기</p>
        <p>
          게스트가 실제로 참여하면 어떨지 파악할 수 있도록 퀄리티 높은 사진을
          7장 이상 추가하세요. 사진이 플레이 영종 가이드 기준에 부합하는지
          확인하기 위해 담당 팀에서 검토하는 과정을 거치게 됩니다.
        </p>
      </div>
      <div className="mb-3 pb-3">
        <p className="h4 font-weight-bold mb-2">모든 사진의 필수 조건</p>
        <p>1. 밝고 선명한 컬러 사진이어야 합니다.</p>
        <p>2. 가이드 현장을 정확히 보여주는 사진이어야 합니다.</p>
        <p>
          3. 카메라를 향해 포즈를 취하거나 셀카를 찍는 사람의 모습을 담은 사진은
          안 됩니다.
        </p>
        <p>
          4. 필터, 텍스트/그래픽, 로고 또는 콜라주를 이용해 사진을 수정할 수
          없습니다.
        </p>
        <p>
          5. 사진은 반드시 본인 소유여야 하며ㅛ, 저작권이 있는 작품은 사용하면
          안 됩니다.
        </p>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <p className="h5 font-weight-bold">
          커버 사진 : 여행에 참여하는 사람들이 서로 소통하는 모습을 담은 사진
        </p>
        <p className="mb-2">
          사람이 1명 이상 포함되어 있으며 여행 전체의 모습을 보여주는 진솔한
          사진을 선택하세요. 셀카, 포즈를 취한 사진, 연출된 사진은 수락되지
          않습니다.
        </p>
        <label className="border rounded-lg">
          <span className="d-inline-block" style={{ minWidth: 150 }} />
          <span className="d-inline-block p-2 border-left">파일 선택</span>
          <input
            name="gpt_pic_main"
            className="d-none"
            type="file"
            onChange={(e) => handleFileUpload(e)}
          />
        </label>
        {photo_data.gpt_pic_main && (
          <div
            className="my-2 w-100 shadow-sm rounded"
            style={{
              height: 350,
              background: `url(${
                codeList.publicVar.imgServerUrl + photo_data.gpt_pic_main
              }) 50% 50%/ cover no-repeat`,
            }}
          />
        )}
      </div>
      <div className="mb-3 pb-3">
        <p className="h5 font-weight-bold">
          갤러리 사진: 세부 사항을 상세히 또는 전체적으로 담은 다양한 사진
          컬렉션
        </p>
        <p className="mb-2">
          여행의 다양한 면모를 강조할 수 있는 사진을 6장 이상 업로드하세요.
          사진에는 가이드/게스트의 상호작용 모습, 여행 장소, 가이드 진행 모습의
          클로즈업샷이 포함되어야 합니다.
        </p>
        <label className="border rounded-lg">
          <span className="d-inline-block" style={{ minWidth: 150 }} />
          <span className="d-inline-block p-2 border-left">파일 선택</span>
          <input
            name="gpt_pics"
            className="d-none"
            type="file"
            onChange={(e) => handleFileUpload(e)}
          />
        </label>
        {photo_data.gpt_pics !== "" &&
          photo_data.gpt_pics.split("|").map((item, index) => {
            return (
              <div key={`${item}_${index}`} className="position-relative">
                <span
                  className="d-inline-block position-absolute cursor_pointer bg-danger text-white px-3 py-2 rounded-pill shadow hoverOpacity"
                  style={{ right: 20, top: 20 }}
                  onClick={() => handleDeleteGalleryPhoto(index)}
                >
                  이미지 삭제
                </span>
                <div
                  className="my-2 w-100 shadow-sm rounded "
                  style={{
                    height: 350,
                    background: `url(${
                      codeList.publicVar.imgServerUrl + item
                    }) 50% 50%/ cover no-repeat`,
                  }}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
