/*global google*/
import React, { useCallback, useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import GuideTourRegistPlaceSearch from "./GuideTourRegistPlaceSearch";
import Caution from "../../commonUI/Caution";
import BuddibleSocket from "../../../../../../lib/BuddibleSocket";

const socket = new BuddibleSocket();

let zoom = 15;
let point;
let myLatLng;

let apiReady = false;
let places = [];

let mapApi = {
  map: null,
  googleMaps: null,
  places: [],
  center: {
    lat: 0,
    lng: 0,
  },
};

let place_data = {
  rl_addr_lat: 0,
  rl_addr_log: 0,
  rl_addr_full: "",
  rl_addr_name: "",
  rl_addr_custom_name: "",
  rl_addr_country: "",
  rl_addr_postal_code: "",
  rl_addr_area_level_1: "",
  rl_addr_sublocality_level_1: "",
  rl_addr_sublocality_level_2: "",
  rl_addr_premise: "",
  rl_addr_place_id: "",
  rl_way_explain: "", // 가이드 알아보는 방법
};

export default function GuideTourRegistPlace(props) {
  const { tourData, guideTourRegistDataChange } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState("");
  let marker = null;
  let center = {
    lat: parseFloat(tourData.rl_addr_lat),
    lng: parseFloat(tourData.rl_addr_log),
  };

  (() => {
    if (tourData) {
      for (let key in place_data) {
        place_data[key] = tourData[key];
      }
    }
  })();

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = () => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            point = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
          },
          function (error) {
            console.error(error);
          },
          {
            enableHighAccuracy: false, // 배터리를 소모해 더 정확한 위치를 찾음(?)
            maximumAge: 0, // 한번 찾은 위치 정보를 해당 초만큼 캐싱
            timeout: Infinity, //주어진 초 안에 찾지 못하면 에러 발생
          }
        );
      } else {
        alert("위치 찾기 오류");
      }
    } catch (e) {
      console.log("geolocation", e);
    }
  };

  const changedRouteData = (value) => {
    const data = {
      rl_addr_lat: value.geometry.location.lat(),
      rl_addr_log: value.geometry.location.lng(),
      rl_addr_full: value.formatted_address,

      rl_addr_name: value.name,
      rl_addr_country: "",
      rl_addr_postal_code: "",
      rl_addr_area_level_1: "",
      rl_addr_sublocality_level_1: "",
      rl_addr_sublocality_level_2: "",
      rl_addr_premise: "",
      rl_addr_place_id: value.place_id,
    };
    let address_components = value.address_components;
    for (let i = 0; i < address_components.length; i++) {
      if (address_components[i].hasOwnProperty("types")) {
        let types = address_components[i]["types"];
        if (types.findIndex((element) => element === "premise") > -1)
          data.rl_addr_premise = address_components[i].short_name;
        if (
          types.findIndex((element) => element === "sublocality_level_2") > -1
        )
          data.rl_addr_sublocality_level_2 = address_components[i].short_name;
        if (
          types.findIndex((element) => element === "sublocality_level_1") > -1
        )
          data.rl_addr_sublocality_level_1 = address_components[i].short_name;
        if (
          types.findIndex(
            (element) => element === "administrative_area_level_1"
          ) > -1
        )
          data.rl_addr_area_level_1 = address_components[i].short_name;
        if (types.findIndex((element) => element === "country") > -1)
          data.rl_addr_country = address_components[i].short_name;
        if (types.findIndex((element) => element === "postal_code") > -1)
          data.rl_addr_postal_code = address_components[i].short_name;
      }
    }
    guideTourRegistDataChange(data, () => {
      setSelectedPlace(data.rl_addr_full);
    });
  };

  // 마커 이동 콜백
  const changedGeocode = (map, maps) => {
    center = {
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    };

    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: center })
      .then((response) => {
        changedRouteData(response.results[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // mapApi.map.setCenter(center);
  };

  // 마커 위치 변경
  const changedMarker = useCallback(
    (center, map, maps) => {
      // 기존 마커 초기화
      if (marker) {
        marker.setMap(null);
      }

      marker = new maps.Marker({
        position: center,
        map,
        draggable: true,
      });
      changedGeocode(map, maps);
      marker.addListener("dragend", () => changedGeocode(map, maps));
    },
    [marker]
  );

  //지도 api 로드
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      mapApi = { ...mapApi, map: map, googleMaps: maps };
      apiReady = true;
    }
    if (center.lat === "" || center.lng === "") center = point;

    changedMarker(center, map, maps);
    mapApi.map.setCenter(center);
    setIsNeedUpdate(!isNeedUpdate);
  };

  const addPlace = (places, latLng) => {
    if (places) {
      mapApi = { ...mapApi, places: places };
      mapApi.map.setCenter(latLng);
      mapApi.map.setZoom(18);
    }
  };

  //부모컴포넌트 데이터 변경
  const handleTourDataChange = (val) => {
    guideTourRegistDataChange(val);
  };

  return (
    <div className="col-xl-6">
      <div className="mb-3 pb-3">
        {apiReady && (
          <GuideTourRegistPlaceSearch
            map={mapApi.map}
            mapApi={mapApi.googleMaps}
            tourData={tourData}
            addPlace={addPlace}
            handleTourDataChange={handleTourDataChange}
            changedMarker={changedMarker}
          />
        )}
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h6>상단에서 검색 후 시작 위치 지도로 확인하기</h6>
        <p>상담의 장소 검색 시 해당되는 주소를 선택해주세요.</p>
        {selectedPlace !== "" && (
          <p className="p-2 rounded border text-center font-weight-bold mt-2">
            {selectedPlace}
          </p>
        )}
        <div className="googleMap my-2" style={{ width: "100%", height: 400 }}>
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyDKXA_Dj4ZejIXaFA7I_p9Ry9o7c8s4QZQ",
              libraries: "places",
            }}
            defaultZoom={zoom}
            defaultCenter={center}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          />
        </div>
        <p>예약이 확정된 게스트만 정확한 주소를 볼 수 있습니다.</p>
      </div>
      <div className="mb-3 pb-3 border-bottom">
        <h6 className="font-weight-bold mb-1">모임 장소 이름 지정하기</h6>
        <p>
          게스트와 만나는 장소를 자세히 적어주세요. 예) 백마역 2번출구 국민은행
          앞.
          <br />
          단, 이 단계에서는 주소를 입력하지 않아도 됩니다.
        </p>
        <input
          type="text"
          className="form-control mt-2"
          placeholder="예) 00 카페 앞"
          defaultValue={place_data.rl_addr_custom_name}
          disabled={tourData.rl_confirm_admin === "S"}
          onChange={(e) => {
            handleTourDataChange({ rl_addr_custom_name: e.target.value });
          }}
        />
      </div>
      <div className="mb-3 pb-3">
        <h6 className="font-weight-bold mb-1">
          도착 후 가이드님을 어떻게 알아볼 수 있는지 알려주세요.
        </h6>
        <p>
          모이는 장소까지 찾아가는 방법을 상세히 설명하세요. 자세한 정보를
          제공할수록 게스트가 쉽게 찾아올 수 있습니다.
        </p>
        <textarea
          rows={5}
          className="form-control mt-2"
          defaultValue={place_data.rl_way_explain}
          disabled={tourData.rl_confirm_admin === "S"}
          placeholder="장소의 분위기, 경관과 주변 소리 등 특별한 점을 설명하세요. 바뀔 수 있는 정보는 chat을 통해서 바로 정보를 알려주세요. 도보, 자동차, 대중교통 이용 시 오는 방법과 주차 팁을 설명해주세요."
          onChange={(e) => {
            handleTourDataChange({ rl_way_explain: e.target.value });
          }}
        />
        <Caution
          desc={
            "대부분의 여행자는 여행이 진행되는 도시에 대해 잘 모르므로, 게스트에게 장소 선택을 맡기지 마세요. 게스트는 가이드가 해당 지역에서 의미 있는 장소를 결정하리라 기대합니다"
          }
        />
      </div>
    </div>
  );
}
