import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function TourWithinHourToolbar(props) {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column flex-md-row justify-content-between align-items-end mb-3">
      <h3 className="align-self-start font-weight-bold m-0">
        {t("tourListWithHourTitle")}
      </h3>
      <Link
        className="bg-white py-1 px-2 rounded-pill cursor_pointer border"
        to="/TourDetailList"
      >
        {t("detailedSearch")}
      </Link>
    </div>
  );
}
