import React, { useState } from "react";

import ChatRoomHeader from "./ChatRoomHeader";
import ChatRoomMsgList from "./ChatRoomMsgList";
import ChatRoomFooter from "./ChatRoomFooter";
import ChatRoomServiceInfo from "./ChatRoomServiceInfo";

export default function ChatRoom(props) {
  const { _loginInfo, item, closeChatRoom } = props;
  const [openServiceInfo, setOpenServiceInfo] = useState(false);

  return (
    <div className="h-100 d-flex flex-column bg-white">
      <ChatRoomHeader
        item={item}
        closeChatRoom={closeChatRoom}
        openServiceInfo={openServiceInfo}
        setOpenServiceInfo={setOpenServiceInfo}
      />
      <div className="flex-grow-1 d-flex flex-row justify-content-between">
        <div className="border-right flex-fill d-none d-lg-flex flex-column">
          <ChatRoomMsgList
            _loginInfo={_loginInfo}
            item={item}
            msgList={item.msgList}
          />
          <ChatRoomFooter _loginInfo={_loginInfo} item={item} />
        </div>
        {!openServiceInfo && (
          <div className="d-lg-none border-right flex-fill d-flex flex-column">
            <ChatRoomMsgList
              _loginInfo={_loginInfo}
              item={item}
              msgList={item.msgList}
            />
            <ChatRoomFooter _loginInfo={_loginInfo} item={item} />
          </div>
        )}
        {openServiceInfo && (
          <ChatRoomServiceInfo
            item={item}
            setOpenServiceInfo={setOpenServiceInfo}
          />
        )}
      </div>
    </div>
  );
}
