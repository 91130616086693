import React, { useCallback, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import { useTranslation } from "react-i18next";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const countryList = codeList.codeCountry;

const mClassName = "TourDetailSuggestionOther";
let tourList = null;

export default function TourDetailSuggestionOther(props) {
  const { guideData, tourData } = props;
  const { t } = useTranslation();
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [itemViewCount, setItemViewCount] = useState(4);

  useEffect(() => {
    handleItemViewCount();
    window.addEventListener("resize", handleItemViewCount);

    requestOtherService();
    return () => {
      window.removeEventListener("resize", handleItemViewCount);
    };
  }, []);

  //스크린 너비에 따른 가이드 View 개수 조절
  const handleItemViewCount = () => {
    let screenWidth = window.innerWidth;
    let changeViewCount = 0;
    //  모바일
    if (screenWidth < 600) {
      changeViewCount = 1.1;
    }

    if (screenWidth >= 600 && screenWidth <= 720) {
      changeViewCount = 2.1;
    }
    //  테블릿
    if (screenWidth > 720 && screenWidth < 1140) {
      changeViewCount = 3.1;
    }

    //  데스크 탑
    if (screenWidth >= 1140) {
      changeViewCount = 5.1;
    }
    if (itemViewCount !== changeViewCount) {
      setItemViewCount(changeViewCount);
    }
  };

  const requestOtherService = () => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/tourList/JS_tour_list_select.php",
      msgID: msgID,
      rl_subject: tourData.rl_subject,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          tourList = newData["returnData"];
          setIsNeedUpdate(!isNeedUpdate);
        }
      }
    });
  };

  const TourItemRender = useCallback(({ item }) => {
    return (
      <TourItem
        rl_idx={item.rl_idx}
        rl_pic={item.rl_pic_main}
        u_name_full={item.u_name_full}
        rl_title={item.rl_title}
        rl_rating={item.rl_rating}
        rl_rating_cnt={item.rl_rating_cnt}
        rl_addr_country={item.rl_addr_country}
        rl_price_base={item.rl_price_base}
        rl_over_pay_people={item.rl_over_pay_people}
      />
    );
  }, []);

  return (
    <div className="container margin_60 ">
      <p className="h5">{t("tourDetailSuggestionOtherTitle")}</p>
      {tourList !== null && (
        <OwlCarousel
          className="owl-theme"
          loop
          margin={10}
          dots={false}
          nav
          navClass={["moveBtn mr-1", "moveBtn ml-1"]}
          items={itemViewCount}
        >
          {tourList.map((item) => {
            return <TourItemRender key={item.rl_idx} item={item} />;
          })}
        </OwlCarousel>
      )}
    </div>
  );
}

const TourItem = React.memo(
  ({
    rl_idx,
    rl_pic,
    u_name_full,
    rl_title,
    rl_rating,
    rl_rating_cnt,
    rl_addr_country,
    rl_price_base,
    rl_over_pay_people,
  }) => {
    const { t } = useTranslation();
    const picUrl = util.changedResourceUrl(rl_pic);
    return (
      <Link to={`/TourDetail/${rl_idx}`} className="item">
        <div className="tour_container" style={{ minHeight: 300 }}>
          <div className="img_container">
            <div
              className="owl_carousel_img"
              style={{
                background: `url(${picUrl})50% 50%/cover no-repeat`,
              }}
            />

            <div className="short_info">
              <span className="price">
                <sup>&#8361;</sup> {util.addCommas(rl_price_base)}
              </span>
            </div>
          </div>
          <div className="tour_title">
            <h3 className="overflow-hidden text-truncate">
              <strong>
                {countryList.get(rl_addr_country) &&
                  countryList.get(rl_addr_country).name_ko}
                · {u_name_full}
              </strong>
            </h3>
            <p className="overflow-hidden text-truncate">{rl_title}</p>
            <div className="rating">
              <i
                className={
                  rl_rating > 0 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  rl_rating > 1 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  rl_rating > 2 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  rl_rating > 3 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <i
                className={
                  rl_rating > 4 ? "icon-star voted" : "icon-star-empty"
                }
              />
              <small>({rl_rating_cnt})</small>
            </div>
            <div>
              <p className="ellipsis_1">
                {t("base")} &#8361; {util.addCommas(rl_price_base)} /{" "}
                {t("addedPerson")} &#8361;
                {util.addCommas(rl_over_pay_people)}
              </p>
            </div>
          </div>
        </div>
      </Link>
    );
  }
);
