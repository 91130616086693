import React, { useEffect, useState, useRef } from "react";
import Utilities from "../../../../lib/Utilities";
import BuddibleSocket, { MsgIDList } from "../../../../lib/BuddibleSocket";
import CodeList from "../../../../lib/CodeList";
import FAQManageTable from "./FAQManageTable";

const util = new Utilities();
const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "FAQManage";

const FAQ_TYPE = [
  { key: "FAQ01", desc: "플레이 영종 이용안내" },
  { key: "FAQ02", desc: "아이디/비밀번호 문의" },
  { key: "FAQ03", desc: "여행 문의" },
  { key: "FAQ04", desc: "불편 접수" },
  { key: "FAQ05", desc: "안전" },
];

const FAQ_SUB_TYPE = [
  "이용방법",
  "메세지 주고 받기",
  "계정 만들기",
  "계정관리",
  "신분증 및 인증",
  "계정 보안",
  "예약 관련",
  "취소/결제/환불",
  "불편 접수",
  "안전에 관한 문의",
  "안전 가이드 라인",
  "시작하기",
];
export default function FAQManage(props) {
  const controller = {
    faq_category_lg: useRef(),
    faq_category_md: useRef(),
    faq_use_yn: useRef(),
    SEARCH_LEVEL: useRef(),
    SEARCH_WORD: useRef(),
  };

  const addNewData = (e) => {
    socket.fireLocalEventListener(
      MsgIDList.EVENT_FAQ_MANAGE_EDIT_MODAL_CALL,
      _mClassName,
      {},
      () => {}
    );
  };

  const searchFilterChanged = (e) => {
    const filters = {
      faq_category_lg: controller.faq_category_lg.current.value,
      faq_category_md: controller.faq_category_md.current.value,
      faq_use_yn: controller.faq_use_yn.current.value,
      SEARCH_LEVEL: controller.SEARCH_LEVEL.current.value,
      SEARCH_WORD: controller.SEARCH_WORD.current.value,
    };

    socket.sendLocalMessage(
      MsgIDList.EVENT_FAQ_MANAGE_FILTER_CHANGED,
      _mClassName,
      filters,
      () => {}
    );
  };

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col">
          <div className="card">
            <div className="card-header">
              {/*필터 영역*/}
              <h6 className="card-subtitle pt-3 text-muted font-weight-bold">
                {/*간편기간 영역*/}

                <div
                  className="btn-toolbar mb-3 justify-content-between"
                  role="toolbar"
                >
                  <div className="btn-group" role="group">
                    <button
                      className="btn btn-outline-primary "
                      data-toggle="modal"
                      data-target="#FAQManageEdit"
                      onClick={addNewData}
                      alt={"신규등록"}
                      aria-label={"신규등록"}
                      type="button"
                    >
                      <i className="fas fa-plus" /> 신규등록
                    </button>
                  </div>

                  <div className="d-flex flex-row">
                    <div className="mr-2">
                      <select
                        className="custom-select form-control"
                        ref={controller.faq_category_lg}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-대분류-</option>
                        {FAQ_TYPE.map((menu, index) => {
                          return (
                            <option key={index} value={menu.key}>
                              {menu.desc}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mr-2">
                      <select
                        className="custom-select form-control"
                        ref={controller.faq_category_md}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-중분류-</option>
                        {FAQ_SUB_TYPE.map((value, index) => {
                          return (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mr-2">
                      <select
                        className="custom-select form-control"
                        ref={controller.faq_use_yn}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 120 }}
                      >
                        <option value="">-노출여부-</option>
                        <option value="Y">노출</option>
                        <option value="N">비노출</option>
                      </select>
                    </div>
                    <div className="input-group">
                      <select
                        className="custom-select form-control"
                        ref={controller.SEARCH_LEVEL}
                        onChange={searchFilterChanged}
                        style={{ minWidth: 150 }}
                      >
                        <option value="">-검색조건-</option>
                        <option value="faq_title">제목</option>
                        <option value="faq_content">내용</option>
                      </select>

                      <input
                        type="text"
                        className="form-control"
                        placeholder="검색어"
                        aria-label="검색어"
                        aria-describedby="btnGroupAddon2"
                        onKeyPress={(e) => {
                          if (e.charCode === 13) {
                            searchFilterChanged(e);
                          }
                        }}
                        ref={controller.SEARCH_WORD}
                      />

                      <button
                        className="input-group-text btn btn-primary border-left-0 rounded-rounded-0"
                        /*style={{
                          borderBottomLeftRadius: 0,
                          borderTopLeftRadius: 0,
                          borderLeft: 0,
                        }}*/
                        onClick={searchFilterChanged}
                      >
                        <i className="fas fa-search mr-2" />
                        검색
                      </button>
                    </div>
                  </div>
                </div>
              </h6>
            </div>
            <FAQManageTable />
          </div>
        </div>
      </div>
    </div>
  );
}
