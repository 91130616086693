import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Nav from "../common/Nav";
import Footer from "../common/Footer";
import MyPageProfile from "./component/MyPageProfile";
import Post from "./post/Post";
import WishList from "./wishList/WishList";
import MyPageReservation from "./reservation/MyPageReservation";

import MyPageReservationReviewWriteModal from "./reservation/MyPageReservationReviewWriteModal";

// import MyPagePayment from "./component/MyPagePayment";

import BuddibleSocket, { MsgIDList } from "../../lib/BuddibleSocket";
import CodeList from "../../lib/CodeList";
import Utilities from "../../lib/Utilities";

const socket = new BuddibleSocket();
const codeList = new CodeList();
const util = new Utilities();

const _mClassName = "MyPage";

let loginInfo = null;

// let u_card_list = [];
let u_community_cnt = 0;
let u_community_list = [];
let u_wish_cnt = 0;
let u_wish_list = [];
let u_reserve_list = [];

let country_list = {};
let language_list = {};

let pageOpen = {
  MyPageProfile: true,
  Post: false,
  // MyPagePayment: false,
  WishList: false,
  MyPageReservation: false,
};
export default function MyPage(props) {
  const { history } = props;
  const { t } = useTranslation();

  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const pages = [
    {
      title: (
        <>
          <i className="fa-solid fa-user-gear mr-2" />
          {t("myPageProfile")}
        </>
      ),
      key: "MyPageProfile",
      component: (params) => <MyPageProfile {...params} />,
    },
    {
      title: (
        <>
          <i className="fa-regular fa-clone mr-2" />
          {t("post")}
        </>
      ),
      key: "Post",
      component: (params) => <Post {...params} />,
    },
    {
      title: (
        <>
          <i className="fa-regular fa-heart mr-2" />
          {t("wish")}
        </>
      ),
      key: "WishList",
      component: (params) => <WishList {...params} />,
    },
    // {
    //   title: (
    //     <>
    //       <i className="fa-solid fa-credit-card mr-2" />
    //       결제수단
    //     </>
    //   ),
    //   key: "MyPagePayment",
    //   component: (params) => <MyPagePayment {...params} />,
    // },
    {
      title: (
        <>
          <i className="fa-solid fa-clock mr-2" />
          {t("tour")}
        </>
      ),
      key: "MyPageReservation",
      component: (params) => <MyPageReservation {...params} />,
    },
  ];

  useEffect(() => {
    //국가목록 데이터
    let country = [...codeList.codeCountry];
    if (country.length > 0) {
      for (let i = 0; i < country.length; i++) {
        let data = [...country[i]];
        country_list[data[0]] = data[1];
      }
    }

    //언어목록 데이터
    let language = [...codeList.codeLanguage];
    if (language.length > 0) {
      for (let i = 0; i < language.length; i++) {
        let data = [...language[i]];
        language_list[data[0]] = data[1];
      }
    }
    //로그인정보 받기
    let loginData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (loginData) {
      loginInfo = loginData;
      setIsLogin(true);
    }

    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        loginInfo = n;
        setIsLogin(true);
      }
    );

    return () => {
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
    };
  }, []);

  useEffect(() => {
    if (loginInfo) {
      if (loginInfo.hasOwnProperty("u_uid")) {
        requestCommunityList((newData) => {
          u_community_cnt = newData["pageSize"];
          u_community_list = newData["returnData"];
          requestWishList((newData) => {
            u_wish_cnt = newData["pageSize"];
            u_wish_list = newData["returnData"];
            requestReserveList((newData) => {
              u_reserve_list = newData["returnData"];
              setIsNeedUpdate(!isNeedUpdate);
            });
          });
        });
      }
    }
  }, [isLogin]);

  //사용자 프로필정보 업데이트
  const updateUserProfile = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/myPage/JS_myPage_update.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          let changLoginData = {};
          for (let key in loginInfo) {
            changLoginData[key] = newData.hasOwnProperty(key)
              ? newData[key]
              : loginInfo[key];
          }
          loginInfo = changLoginData;
          socket.fireLocalEventListener(
            MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
            "AutoLogin",
            changLoginData,
            () => {}
          );
        }
        codeList.Modal.current.alert(newData.MSG);
      }
      setIsNeedUpdate(!isNeedUpdate);
    });
  };

  // 사용자 게시글 받아오기
  const requestCommunityList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/myPage/JS_myPageCommunitySelect.php",
      msgID: msgID,
      data: {},
      cm_create_uid: loginInfo.u_uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 사용자 저장목록 요청
  const requestWishList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/myPage/JS_myPageWishListSelect.php",
      msgID: msgID,
      data: {},
      wl_user_uid: loginInfo.u_uid,
      wl_del_yn: "N",
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 저장 목록 저장 취소
  const updateWishList = (wish) => {
    codeList.Modal.current.confirm(
      `[${wish.rl_title}] 서비스 저장을 취소하시겠습니까?`,
      (ret) => {
        if (!ret) return;

        let msgID = util.makeUUIDv4();
        let socketMsg = {
          file: "/myPage/JS_myPageWishListUpdate.php",
          msgID: msgID,
          data: {},
          ...wish,
        };
        socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
          if (newData) {
            if (newData["ret"]) {
              codeList.Modal.current.alert("정상처리되었습니다.", () => {
                const idx = u_wish_list.findIndex(
                  (item) => item.wl_idx === wish.wl_idx
                );
                u_wish_list.splice(idx, 1);
                --u_wish_cnt;
                setIsNeedUpdate(!isNeedUpdate);
              });
            } else {
              codeList.Modal.current.alert(t("errorMsg"));
            }
          }
        });
      }
    );
  };

  //예약정보 받아오기
  const requestReserveList = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/myPage/JS_myPage_reserve_list_select.php",
      msgID: msgID,
      data: {},
      USER_UID: loginInfo.u_uid,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  //예약정보 업데이트
  const updateReserveList = (data) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/myPage/JS_myPage_reserve_update.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(newData["MSG"], () => {
            let update_index = u_reserve_list.findIndex(
              (item) => item.rrl_idx === data.rrl_idx
            );
            u_reserve_list[update_index].rrl_status = data.rrl_status;
            setIsNeedUpdate(!isNeedUpdate);
          });
        } else {
          codeList.Modal.current.alert(t("errorMsg"), () => {});
        }
      }
    });
  };

  // 후기 등록 수정
  const updateRouteReview = (data, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/myPage/JS_myPageReviewUpdate.php",
      msgID: msgID,
      data: {},
      ...data,
    };
    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          requestReserveList((reserveData) => {
            u_reserve_list = reserveData["returnData"];
            setIsNeedUpdate(!isNeedUpdate);
          });
          callback && callback();
        } else {
          codeList.Modal.current.alert(t("errorMsg"), () => {});
        }
      }
    });
  };

  //메뉴 이동
  const handleChangeMenu = (menuKey) => {
    let pageOpenData = {};
    for (let key in pageOpen) {
      pageOpenData[key] = key === menuKey;
    }
    pageOpen = pageOpenData;
    setIsNeedUpdate(!isNeedUpdate);
  };

  // 투어 디테일페이지 이동
  const moveToTourDetail = (rl_idx) => {
    history.push(`/TourDetail/${rl_idx}`);
  };

  return (
    <>
      <Nav />
      <MyPageReservationReviewWriteModal
        loginInfo={loginInfo}
        updateRouteReview={updateRouteReview}
      />
      {loginInfo && (
        <main style={{ marginTop: 100, minHeight: window.innerHeight - 300 }}>
          <div className="container margin_60">
            <div className="row p-0 m-0">
              <div className="col-12 col-md-3 mb-5">
                {pages.map((item, index) => {
                  let isLastClass =
                    index + 1 === pages.length ? "" : "border-bottom";
                  let isActive = pageOpen[item.key]
                    ? "text-buddible font-weight-bold"
                    : "";
                  return (
                    <div
                      key={"Nav_" + item.key}
                      className={`cursor_pointer h6 p-3 m-0 ${isLastClass} ${isActive}`}
                      onClick={() => handleChangeMenu(item.key)}
                    >
                      {item.title}
                    </div>
                  );
                })}
              </div>
              <div className="col-12 col-md-9">
                {pages.map((item, index) => {
                  if (pageOpen[item.key]) {
                    return (
                      <div key={item.key + "_" + index}>
                        {item.component({
                          loginInfo: loginInfo,
                          country_list: country_list,
                          language_list: language_list,
                          updateUserProfile: updateUserProfile,
                          u_community_cnt: u_community_cnt,
                          u_community_list: u_community_list,
                          u_wish_cnt: u_wish_cnt,
                          u_wish_list: u_wish_list,
                          updateWishList: updateWishList,
                          u_reserve_list: u_reserve_list,
                          updateReserveList: updateReserveList,
                          moveToTourDetail: moveToTourDetail,
                        })}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </main>
      )}
      <Footer />
    </>
  );
}
/*

 //결제카드 리스트 받아오기
 const SELECT_MYPAGE_CARD = (u_uid) => {
   let msgID = util.makeUUIDv4();
   let socketMsg = {
     file: "/myPage/JS_myPage_card_select.php",
     msgID: msgID,
     data: {},
     u_uid: loginInfo.u_uid,
     uc_del_yn: "N",
   };
   socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
     if (newData) {
       if (newData["ret"]) {
         u_card_list = newData["returnData"];
         requestReserveList();
       }
     }
   });
 };
 //카드 등록
 const INSERT_MYPAGE_USER_CARD = (requestData, callback) => {
   let msgID = util.makeUUIDv4();
   let socketMsg = {
     file: "/myPage/JS_myPage_card_insert.php",
     msgID: msgID,
     data: {},
     ...requestData,
   };
   socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
     if (newData) {
       if (newData["ret"]) {
         codeList.Modal.current.alert(
           "카드가 정상적으로 등록 되었습니다.",
           () => {}
         );
         u_card_list = [
           {
             uc_row_num: requestData.uc_row_num,
             uc_card_uid: requestData.uc_card_uid,
             uc_user_uid: requestData.uc_user_uid,
             uc_card_name: requestData.uc_card_name,
             uc_card_num: requestData.uc_card_num,
             uc_reg_date: requestData.uc_reg_date,
             uc_modify_date: requestData.uc_modify_date,
             uc_del_yn: requestData.uc_del_yn,
           },
           ...u_card_list,
         ];
         setIsNeedUpdate(!isNeedUpdate);
       } else {
         codeList.Modal.current.alert(
           "카드가 등록 중 오류가 발생했습니다.",
           () => {}
         );
       }
     }
   });
 };

 //카드삭제
 const UPDATE_MYPAGE_USER_CARD = (requestData) => {
   let msgID = util.makeUUIDv4();
   let socketMsg = {
     file: "/myPage/JS_myPage_card_update.php",
     msgID: msgID,
     data: {},
     ...requestData,
   };
   socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
     if (newData) {
       if (newData["ret"]) {
         codeList.Modal.current.alert(
           "카드가 정상적으로 삭제 되었습니다.",
           () => {
             let delete_index = u_card_list.findIndex(
               (item) => item.uc_card_uid === requestData.CARD_UID
             );
             u_card_list.splice(delete_index, 1);
             setIsNeedUpdate(!isNeedUpdate);
           }
         );
       } else {
         codeList.Modal.current.alert(
           "카드가 삭제 중 오류가 발생했습니다.",
           () => {}
         );
       }
     }
   });
 };
*/
