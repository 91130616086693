import React, { useRef, useEffect, useState } from "react";
import $ from "jquery";
import Utilities from "../../../lib/Utilities";
import BuddibleSocket from "../../../lib/BuddibleSocket";
import CodeList from "../../../lib/CodeList";
import MainLogoSVG from "../../../lib/MainLogoSVG";
import { useTranslation } from "react-i18next";
import Loading from "../../../lib/Loading";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const util = new Utilities();
const codeList = new CodeList();
const socket = new BuddibleSocket();

export default function FindPWDModal() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const $userID = useRef();

  const requestFindPWD = () => {
    if ($userID.current.value.trim() === "") {
      codeList.Modal.current.alert("아이디를 입력해주세요", () => {
        $userID.current.focus();
      });
      return;
    }
    setIsLoading(true);

    const msgID = util.makeUUIDv4();
    const socketMsg = {
      file: "/user/JS_sendFindPWDLink.php",
      msgID: msgID,
      data: {
        u_email: $userID.current.value,
      },
      u_email: $userID.current.value,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          codeList.Modal.current.alert(
            "등록된 이메일주소로 비밀번호 재설정 링크가 전송되었습니다",
            () => {
              $("#FindPWDModalClose").click();
            }
          );
        } else {
          codeList.Modal.current.alert("등록되지 않은 이메일주소입니다.");
        }
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      {isLoading && <Loading />}
      <div
        className="modal fade"
        id="FindPWDModal"
        tabIndex="-1"
        aria-labelledby="FindPWDModalLabel"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div
              className="modal-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="col-2 m-0 p-0">
                <MainLogoSVG />
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="FindPWDModalClose"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="sign-in-wrapper">
                <div className="form-group">
                  <input
                    className="form-control p-3"
                    type="text"
                    placeholder={t("id")}
                    ref={$userID}
                  />
                  <p className="mt-1">
                    ※ 등록된 이메일을 통해 비밀번호 재설정 링크가 전송됩니다.
                  </p>
                </div>

                <div className="text-center">
                  <button
                    className="btn_login bg-buddible"
                    onClick={() => requestFindPWD()}
                  >
                    메일전송
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
