import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import GuideRegistNav from "./component/GuideRegistNav";

import GuideRegistBasicInfoPlace from "./component/basicInfoPlace/GuideRegistBasicInfoPlace_Amber";
import GuideRegistBasicInfoLanguage from "./component/basicInfoLanguage/GuideRegistBasicInfoLanguage";
import GuideRegistBasicInfoProgram from "./component/basicInfoProgram/GuideRegistBasicInfoProgram";

import GuideRegistProfileIntro from "./component/profile/GuideRegistProfileIntro";
import GuideRegistProfileDefaultProvided from "./component/profile/GuideRegistProfileDefaultProvided";
import GuideRegistProfilePrerequisite from "./component/profile/GuideRegistProfilePrerequisite";

import GuideRegistFindGuideIntro from "./component/findGuide/GuideRegistFindGuideIntro";
import GuideRegistFindGuideOpportunity from "./component/findGuide/GuideRegistFindGuideOpportunity";
import GuideRegistFindGuideProfessionalism from "./component/findGuide/GuideRegistFindGuideProfessionalism";
import GuideRegistFindGuideConsensus from "./component/findGuide/GuideRegistFindeGuideConsensus";

import GuideRegistSafetyIntro from "./component/safety/GuideRegistSafetyIntro";
import GuideRegistSafetyRule from "./component/safety/GuideRegistSafetyRule";
import GuideRegistSafetyAvoidTouch from "./component/safety/GuideRegistSafetyAvoidTouch";
import GuideRegistSafetyChangePlace from "./component/safety/GuideRegistSafetyChangePlace";
import GuideRegistSafetyClean from "./component/safety/GuideRegistSafetyClean";
// import GuideRegistSafetyQuiz from "./component/safety/GuideRegistSafetyQuiz";
import GuideRegistSafetyAgree from "./component/safety/GuideRegistSafetyAgree";

import GuideRegistSubmitUserInfo from "./component/sibmit/GuideRegistSubmitUserInfo";
import GuideRegistSubmitCheck from "./component/sibmit/GuideRegistSubmitCheck";

import BuddibleSocket, { MsgIDList } from "../../../lib/BuddibleSocket";
import Utilities from "../../../lib/Utilities";
import CodeList from "../../../lib/CodeList";
import Loading from "../../../lib/Loading";

const socket = new BuddibleSocket();
const util = new Utilities();
const codeList = new CodeList();

const _mClassName = "GuideRegist";
const pages = [
  { type: "kind", kind: "basicInfo", desc: "", title: "기본정보" },

  {
    type: "page",
    kind: "basicInfo",
    title: "위치",
    desc: "가이드 활동할 위치를 입력하세요",
    key: "GuideRegistBasicInfoPlace",
    component: (params) => <GuideRegistBasicInfoPlace {...params} />,
  },
  {
    type: "page",
    kind: "basicInfo",
    title: "언어",
    desc: "",
    key: "GuideRegistBasicInfoLanguage",
    component: (params) => <GuideRegistBasicInfoLanguage {...params} />,
  },
  {
    type: "page",
    kind: "basicInfo",
    title: "대표이미지 & 소개글",
    desc: "대표이미지를 등록하고 가이드활동에 대한 간략한 소개글을 적어주세요. \n작성된 소개글과 이미지는 서비스 메인에 노출됩니다.",
    key: "GuideRegistBasicInfoProgram",
    component: (params) => <GuideRegistBasicInfoProgram {...params} />,
  },
  //  --------------------------------------------------------------
  { type: "kind", kind: "guideProfile", desc: "", title: "가이드 프로필 상세" },

  {
    type: "page",
    kind: "guideProfile",
    title: "가이드 프로필",
    desc: "",
    key: "GuideRegistProfileIntro",
    component: (params) => <GuideRegistProfileIntro {...params} />,
  },
  {
    type: "page",
    kind: "guideProfile",
    title: "기본 제공사항",
    desc: "",
    key: "GuideRegistProfileDefaultProvided",
    component: (params) => <GuideRegistProfileDefaultProvided {...params} />,
  },
  {
    type: "page",
    kind: "guideProfile",
    title: "게스트 필수 조건",
    desc: "",
    key: "GuideRegistProfilePrerequisite",
    component: (params) => <GuideRegistProfilePrerequisite {...params} />,
  },
  //  ---------------------------------------------------------------------
  {
    type: "kind",
    kind: "findGuide",
    desc: "",
    title: "플레이 영종이 찾고있는 가이드",
  },

  {
    type: "page",
    kind: "findGuide",
    title: "개요",
    desc: "",
    key: "GuideRegistFindGuideIntro",
    component: (params) => <GuideRegistFindGuideIntro {...params} />,
  },
  {
    type: "page",
    kind: "findGuide",
    title: "전문성",
    desc: "",
    key: "GuideRegistFindGuideProfessionalism",
    component: (params) => <GuideRegistFindGuideProfessionalism {...params} />,
  },
  {
    type: "page",
    kind: "findGuide",
    title: "특별한 기회",
    desc: "",
    key: "GuideRegistFindGuideOpportunity",
    component: (params) => <GuideRegistFindGuideOpportunity {...params} />,
  },
  {
    type: "page",
    kind: "findGuide",
    title: "교감",
    desc: "",
    key: "GuideRegistFindGuideConsensus",
    component: (params) => <GuideRegistFindGuideConsensus {...params} />,
  },
  //  --------------------------------------------------------------------
  { type: "kind", kind: "safety", desc: "", title: "안전 가이드 라인" },

  {
    type: "page",
    kind: "safety",
    title: "개요",
    desc: "",
    key: "GuideRegistSafetyIntro",
    component: (params) => <GuideRegistSafetyIntro {...params} />,
  },
  {
    type: "page",
    kind: "safety",
    title: "위생수칙 지키기",
    desc: "",
    key: "GuideRegistSafetyRule",
    component: (params) => <GuideRegistSafetyRule {...params} />,
  },
  {
    type: "page",
    kind: "safety",
    title: "신체접촉 피하기",
    desc: "",
    key: "GuideRegistSafetyAvoidTouch",
    component: (params) => <GuideRegistSafetyAvoidTouch {...params} />,
  },
  {
    type: "page",
    kind: "safety",
    title: "장소 변경",
    desc: "",
    key: "GuideRegistSafetyChangePlace",
    component: (params) => <GuideRegistSafetyChangePlace {...params} />,
  },
  {
    type: "page",
    kind: "safety",
    title: "청결 강화 기준 준수",
    desc: "",
    key: "GuideRegistSafetyClean",
    component: (params) => <GuideRegistSafetyClean {...params} />,
  },
  // {
  //   type: "page",
  //   kind: "safety",
  //   title: "퀴즈를 통한 지식 확인",
  //desc:"",
  //   key: "GuideRegistSafetyQuiz",
  //   component: (params) => <GuideRegistSafetyQuiz {...params} />,
  // },
  {
    type: "page",
    kind: "safety",
    title: "동의사항",
    desc: "",
    key: "GuideRegistSafetyAgree",
    component: (params) => <GuideRegistSafetyAgree {...params} />,
  },
  //  -----------------------------------------------------------------

  { type: "kind", kind: "submit", desc: "", title: "제험 제출안" },
  {
    type: "page",
    kind: "submit",
    title: "내 정보",
    desc: "제출하기 전에 몇 가지 정보를 제공해주세요. 이는 플레이 영종 커뮤니티를 안전하게 보호하기 위한 조치입니다.",
    key: "GuideRegistSubmitUserInfo",
    component: (params) => <GuideRegistSubmitUserInfo {...params} />,
  },
  {
    type: "page",
    kind: "submit",
    title: "검토 후 제출",
    desc: "지금 제출하시는 내용을 플레이 영종에서 검토할 것입니다",
    key: "GuideRegistSubmitCheck",
    component: (params) => <GuideRegistSubmitCheck {...params} />,
  },
];

let loading = false;
let pageOpen = {
  GuideRegistBasicInfoPlace: true,
  GuideRegistBasicInfoLanguage: false,
  GuideRegistBasicInfoProgram: false,

  GuideRegistProfileIntro: false,
  GuideRegistProfileDefaultProvided: false,
  GuideRegistProfilePrerequisite: false,

  GuideRegistFindGuideIntro: false,
  GuideRegistFindGuideProfessionalism: false,
  GuideRegistFindGuideOpportunity: false,
  GuideRegistFindGuideConsensus: false,

  GuideRegistSafetyIntro: false,
  GuideRegistSafetyRule: false,
  GuideRegistSafetyAvoidTouch: false,
  GuideRegistSafetyChangePlace: false,
  GuideRegistSafetyClean: false,
  // GuideRegistSafetyQuiz: false,
  GuideRegistSafetyAgree: false,

  GuideRegistSubmitUserInfo: false,
  GuideRegistSubmitCheck: false,
};

let activePage = "GuideRegistBasicInfoPlace";
let activeMenu = "basicInfo";
let loginInfo = null;
let isLoad = true;
let guideData = {
  gl_guide_uid: "", // user 고유 uid

  gl_addr_full: "", // 전체주소
  gl_addr_lat: "37.6849216", // 위도
  gl_addr_log: "126.81864420000101", // 경도
  gl_addr_name: "",
  gl_addr_country: "",
  gl_addr_postal_code: "",
  gl_addr_area_level_1: "",
  gl_addr_sublocality_level_1: "",
  gl_addr_sublocality_level_2: "s",
  gl_addr_premise: "",
  gl_addr_place_id: "",
  gl_name: "",

  gl_lang_main: "", //언어 메인

  gl_pic_main: "", // 대표이미지
  gl_title: "", // 프로그램 설명

  gl_expert: "", // 전문성
  gl_chance: "", //특별한 기회
  gl_emotion: "", // 교감

  gl_pic: "", //가이드 이미지
  gl_desc: "", // 자기소개
  gl_alone_yn: "", //자기소개 - 혼자 Y, 팀 N

  gl_serve_method: [], //차량제공사항 A|B|C|D|E
  gl_serve_driver: [], //운전자 A|B|C|D|E
  gl_serve_memo: [], //추가제공사항 담요|생수|차량

  questionCheckBox: false, //접근성 지원여부
  gl_guest_talk_method: [], //커뮤니케이션 지원기능 A|B|C|D|E
  gl_guest_age_limit: 1, //최소연령
  gl_agree_minor_yn: "N", //미성년 게스트 동의
  gl_guest_baby_yn: "N", // 2세유아가능
  gl_guest_need_memo: "", //추가 요건
  gl_guest_confirm: "N", // 게스트 본인인증

  gl_safe_agree: "N", //안전 동의여부

  gl_pic_social1: "", //신분증
  gl_pic_social2: "",
  gl_pic_university1: "", //학생증
  gl_pic_university2: "",
  gl_pic_university_doc1: "", //재학증명
  gl_pic_university_doc2: "",

  gl_university_name: "", //학교명
  gl_university_year: "", //입학년도
  gl_university_dept: "", //학과

  gl_agree_license: "", //가이드 면허 여부
  gl_agree_alcohol_yn: "", //주류 포함 여부
  gl_agree_law_yn: "", //법규 확인
  gl_agree_info_yn: "", //설명확인
  gl_agree_refund_yn: "", //환불정책 확인
  gl_mobile: "", //전화번호

  gl_confirm_admin: "W", // 관리자 승인 여부
};

export default function GuideRegist(props) {
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  (() => {
    socket.addLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      (b, n) => {
        if (!n) return;
        if (guideData.gl_confirm_admin === "S") {
          codeList.Modal.current.alert(
            "제출 후 검토중에는 정보 변경이 불가합니다."
          );
          return;
        }

        guideData = { ...guideData, ...n };
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
      _mClassName,
      (b, n) => {
        if (!n) return;
        guideData = { ...guideData, gl_guide_uid: n.u_uid };
        loginInfo = n;
        handleGuideInfo();
      }
    );
    socket.addLocalEventListener(
      MsgIDList.EVENT_LOGIN_AUTO_FAIL,
      _mClassName,
      (b, n) => {
        if (n) {
          codeList.Modal.current.alert("로그인 후 이용가능합니다.", () => {
            props.history.replace("/");
          });
        }
      }
    );
  })();

  useEffect(() => {
    let userData = socket.getLocalDataSet(MsgIDList.EVENT_LOGIN_AUTO_SUCCESS);
    if (userData) {
      guideData = { ...guideData, gl_guide_uid: userData.u_uid };
      loginInfo = userData;
      handleGuideInfo();
    }
    return () => {
      activePage = "GuideRegistBasicInfoPlace";
      activeMenu = "basicInfo";
      loginInfo = null;
      isLoad = true;
      guideData = {
        gl_guide_uid: "", // user 고유 uid

        gl_addr_full: "", // 전체주소
        gl_addr_lat: "37.6849216", // 위도
        gl_addr_log: "126.81864420000101", // 경도
        gl_addr_name: "",
        gl_addr_country: "",
        gl_addr_postal_code: "",
        gl_addr_area_level_1: "",
        gl_addr_sublocality_level_1: "",
        gl_addr_sublocality_level_2: "",
        gl_addr_premise: "",
        gl_addr_place_id: "",

        gl_name: "", //가이드명
        gl_lang_main: "", //언어 메인

        gl_pic_main: "",
        gl_title: "", // 프로그램 설명
        gl_expert: "", // 전문성
        gl_chance: "", //특별한 기회
        gl_emotion: "", // 교감

        gl_pic: "", //가이드 이미지
        gl_desc: "", // 자기소개
        gl_alone_yn: "", //자기소개 - 혼자 Y, 팀 N

        gl_serve_method: [], //차량제공사항 A|B|C|D|E
        gl_serve_driver: [], //운전자 A|B|C|D|E
        gl_serve_memo: [], //추가제공사항 담요|생수|차량

        questionCheckBox: false, //접근성 지원여부
        gl_guest_talk_method: [], //커뮤니케이션 지원기능 A|B|C|D|E
        gl_guest_age_limit: 1, //최소연령
        gl_agree_minor_yn: "N", //미성년 게스트 동의
        gl_guest_baby_yn: "N", // 2세유아가능
        gl_guest_need_memo: "", //추가 요건
        gl_guest_confirm: "N", // 게스트 본인인증

        gl_safe_agree: "N", //안전 동의여부

        gl_pic_social1: "", //신분증
        gl_pic_social2: "",
        gl_pic_university1: "", //학생증
        gl_pic_university2: "",
        gl_pic_university_doc1: "", //재학증명
        gl_pic_university_doc2: "",

        gl_university_name: "", //학교명
        gl_university_year: "", //입학년도
        gl_university_dept: "", //학과

        gl_agree_license: "", //가이드 면허 여부
        gl_agree_alcohol_yn: "", //주류 포함 여부
        gl_agree_law_yn: "", //법규 확인
        gl_agree_info_yn: "", //설명확인
        gl_agree_refund_yn: "", //환불정책 확인
        gl_mobile: "", //전화번호

        gl_confirm_admin: "W", // 관리자 승인 여부
      };
      socket.removeLocalEventListener(
        MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_SUCCESS,
        _mClassName
      );
      socket.removeLocalEventListener(
        MsgIDList.EVENT_LOGIN_AUTO_FAIL,
        _mClassName
      );
    };
  }, []);

  const handleGuideInfo = () => {
    requestGuideInfo((newData) => {
      if (newData["returnData"][0]) {
        let guideInfoSet = {};
        guideInfoSet = { ...newData["returnData"][0] };
        let arrayDataKeys = [
          "gl_serve_method",
          "gl_serve_driver",
          "gl_serve_memo",
          "gl_guest_talk_method",
        ];
        for (let i = 0; i < arrayDataKeys.length; i++) {
          if (guideInfoSet.hasOwnProperty(arrayDataKeys[i]))
            guideInfoSet[arrayDataKeys[i]] = guideInfoSet[arrayDataKeys[i]]
              ? guideInfoSet[arrayDataKeys[i]].split("|")
              : [];
        }

        for (let key in guideData) {
          guideData[key] = guideInfoSet.hasOwnProperty(key)
            ? guideInfoSet[key]
            : guideData[key];
        }
      }
      loading = true;
      requestGuideQuizAnswer((newData) => {
        let stateSet = {};
        let quizList = [];
        let returnQuizList = newData["returnData"];
        quizList = newData["returnData"];
        returnQuizList.forEach((quizObj) => {
          stateSet[quizObj.gqa_quiz_key] = quizObj.gqa_quiz_answer;
        });

        guideData = { ...guideData, ...stateSet, gqa_quiz_list: quizList };
        isLoad = false;
        setIsNeedUpdate(!isNeedUpdate);
      });
    });
  };

  // 가이드 기본정보
  const requestGuideInfo = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_GetGuideInfoOnReg.php",
      msgID: msgID,
      gl_guide_uid: loginInfo.u_uid,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  // 가이드 안전퀴즈 답변
  const requestGuideQuizAnswer = (callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_GetGuideQuizAnswer.php",
      msgID: msgID,
      gqa_guide_uid: loginInfo.u_uid,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
        }
      }
    });
  };

  //저장
  const actionSave = (callback) => {
    let guideInfo = { ...guideData };

    //게스트 유아 동반 가능 셋팅
    if (guideInfo.hasOwnProperty("gl_guest_baby_yn"))
      guideInfo["gl_guest_baby_yn"] = guideInfo.gl_guest_baby_yn ? "Y" : "N";
    //게스트 본인확인 셋팅
    if (guideInfo.hasOwnProperty("gl_guest_confirm"))
      guideInfo["gl_guest_confirm"] = guideInfo.gl_guest_confirm ? "Y" : "N";

    let data = {};
    for (let key in guideInfo) {
      if (guideInfo[key] && key.startsWith("gl_")) {
        if (Array.isArray(guideInfo[key])) {
          data[key] = guideInfo[key].length > 0 ? guideInfo[key].join("|") : "";
          continue;
        }

        data[key] = guideInfo[key];
      }
    }
    updateGuideInfo(data, callback);
  };

  // 가이드 정보 통신
  const updateGuideInfo = (updateData, callback) => {
    let msgID = util.makeUUIDv4();
    let socketMsg = {
      file: "/guide/JS_guide_info_update.php",
      msgID: msgID,
      gl_guide_uid: loginInfo.u_uid,
      ...loginInfo,
      ...updateData,
    };

    socket.sendMessageToDB(socketMsg, msgID, (beforeData, newData) => {
      if (newData) {
        if (newData["ret"]) {
          callback && callback(newData);
          setIsNeedUpdate(!isNeedUpdate);
        } else {
          codeList.Modal.current.alert(
            "제출 중 오류가 발생했습니다. 다시 시도해주세요."
          );
        }
      }
    });
  };

  //내비 페이지 이동
  const handleChangeMenu = (kind, menuKey) => {
    let moveKey = menuKey;
    let moveKind = kind;

    if (moveKey !== "") {
      let currentIndex = pages.findIndex((item) => item.key === activePage);
      let moveIndex = pages.findIndex((item) => item.key === moveKey);

      if (moveIndex - currentIndex === 1 && isPageValidated(activePage)) {
        //현재 페이지에서 바로 다음 페이지로 이동할 경우
        codeList.Modal.current.alert(
          "현재 페이지의 정보 입력 후 페이지 이동이 가능합니다."
        );
        return;
      }

      if (moveIndex - currentIndex > 1) {
        //현재 페이지에서 바로 다음페이지를 제외한 이후 페이지로 이동할 경우
        let isCheck = false;
        let modifyPageKey = "";
        for (let i = 0; i < pages.length; i++) {
          let page = pages[i];

          if (page.key === moveKey) {
            break;
          }

          if (isPageValidated(page.key)) {
            codeList.Modal.current.alert(
              "이전 페이지의 정보가 입력되지 않았습니다."
            );
            moveKind = page.kind;
            modifyPageKey = page.key;
            isCheck = true;
            break;
          }
        }
        if (isCheck) {
          moveKey = modifyPageKey;
        }
      }
      let pageOpenData = {};
      for (let key in pageOpen) {
        pageOpenData[key] = key === moveKey;
      }
      pageOpen = pageOpenData;
      activePage = moveKey;
    }
    activeMenu = moveKind;
    if (guideData.gl_confirm_admin === "S") {
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      actionSave();
    }
  };

  //하단 페이지 이동
  const handlePageChange = (move) => {
    let pageOpenData = [];
    for (let key in pageOpen) {
      pageOpenData.push(key);
    }
    let currentIndex = pageOpenData.findIndex((item) => item === activePage);

    let nextPage = pageOpenData[currentIndex];
    if (move === "next") {
      nextPage = pageOpenData[currentIndex + 1];
      if (isPageValidated()) {
        codeList.Modal.current.alert(
          "현재 페이지의 정보 입력후 페이지 이동이 가능합니다."
        );
        return;
      }
    } else if (move === "prev") {
      nextPage = pageOpenData[currentIndex - 1];
    }
    let currentPage = null;
    pages.forEach((item) => {
      if (item.hasOwnProperty("key") && item.key === nextPage) {
        currentPage = item;
      }
    });
    let changePageOpen = {};
    for (let key in pageOpen) {
      changePageOpen[key] = key === nextPage;
    }
    activeMenu = currentPage.kind;
    pageOpen = changePageOpen;
    activePage = nextPage;
    if (guideData.gl_confirm_admin === "S") {
      setIsNeedUpdate(!isNeedUpdate);
    } else {
      actionSave();
    }
  };

  //필수 작성란 유효성 검사
  const isPageValidated = (key = activePage) => {
    switch (key) {
      //기본정보
      case "GuideRegistBasicInfoPlace":
        if (guideData.gl_addr_lat && guideData.gl_addr_log) return false;
        break;
      case "GuideRegistBasicInfoLanguage":
        if (guideData.gl_lang_main) return false;
        break;
      case "GuideRegistBasicInfoProgram":
        if (guideData.gl_title && guideData.gl_pic_main) return false;
        break;

      //  가이드프로필 상세
      case "GuideRegistProfileIntro":
        if (
          guideData.gl_pic &&
          guideData.gl_desc &&
          guideData.gl_alone_yn &&
          guideData.gl_name &&
          guideData.gl_mobile
        )
          return false;
        break;
      case "GuideRegistProfileDefaultProvided":
        if (guideData.gl_serve_method.length > 0) return false;
        break;
      case "GuideRegistProfilePrerequisite":
        if (guideData.gl_guest_age_limit) return false;
        break;

      //  플레이 영종이 찾고있는 가이드
      case "GuideRegistFindGuideProfessionalism":
        if (guideData.gl_expert) return false;
        break;
      case "GuideRegistFindGuideOpportunity":
        if (guideData.gl_chance) return false;
        break;
      case "GuideRegistFindGuideConsensus":
        if (guideData.gl_emotion) return false;
        break;
      case "GuideRegistSafetyAgree":
        if (guideData.gl_safe_agree === "Y") return false;
        break;

      //  체험 제출안
      case "GuideRegistSubmitUserInfo":
        if (guideData.gl_pic_social1 && guideData.gl_pic_social2) return false;
        break;
      case "GuideRegistSubmitCheck":
        if (
          guideData.gl_agree_license &&
          guideData.gl_agree_alcohol_yn &&
          guideData.gl_agree_law_yn &&
          guideData.gl_agree_law_yn !== "N" &&
          guideData.gl_agree_info_yn &&
          guideData.gl_agree_info_yn !== "N" &&
          guideData.gl_agree_refund_yn &&
          guideData.gl_agree_refund_yn !== "N"
        )
          return false;
        break;
      default:
        return false;
    }
    return true;
  };

  //저장 후 나가기
  const handleSaveOut = () => {
    codeList.Modal.current.alert(
      "저장 후 나가기를 원하신다면 확인을 눌러주세요. \n최종 제출은 [체험 제출안 > 검토 후 제출] 후측 하단 제출 버튼을 통해 가능합니다.",
      (e) => {
        if (e) {
          if (guideData.gl_confirm_admin === "S") {
            history.replace("/GuideManagement");
          } else {
            actionSave(() => {
              history.replace("/GuideManagement");
            });
          }
        }
      }
    );
  };

  // 최종 제출
  const finalSubmit = () => {
    if (isPageValidated()) {
      codeList.Modal.current.alert(
        "현재페이지의 내용을 모두 작성해야 제출이 가능합니다."
      );
      return;
    }
    codeList.Modal.current.confirm(
      "검토요청 상태에서는 정보변경이 불가합니다. 제출하시겠습니까?",
      (ret) => {
        if (ret) {
          guideData["gl_confirm_admin"] = "S";
          setIsLoading(true);
          actionSave(() => {
            setIsLoading(false);
            codeList.Modal.current.alert(
              "정상적으로 제출이 완료되었습니다. \n검토 진행 현황은 가이드 관리 메뉴에서 확인할 수 있습니다",
              () => {
                history.replace("/GuideManagement");
              }
            );
          });
        }
      }
    );
  };

  if (isLoad) return <></>;

  return (
    <>
      {isLoading && <Loading />}
      <div className="bg-white d-flex flex-row justify-content-start w-100 vh-100">
        <GuideRegistNav
          pages={pages}
          activeMenu={activeMenu}
          pageOpen={pageOpen}
          handleChangeMenu={handleChangeMenu}
          handleSaveOut={handleSaveOut}
        />
        <div className="flex-fill d-flex flex-column justify-content-between">
          {loading &&
            pages.map((item, index) => {
              if (item.type === "page") {
                return (
                  <div
                    key={item.kind + index}
                    className={`container-fluid flex-fill overflow_scroll_y p-3 p-md-5 ${
                      pageOpen[item.key] ? "d-block" : "d-none"
                    }`}
                  >
                    <div className="row mb-3">
                      <div className="col-xl-3" />
                      <div className="col-xl-6">
                        <div className="d-flex flex-row flex-wrap justify-content-between align-items-start">
                          <h3 className="font-weight-bold mb-1">
                            {item.title}
                          </h3>
                          <span
                            className="d-lg-none font-weight-bold cursor_pointer text-buddible"
                            onClick={handleSaveOut}
                          >
                            <i className="fa-solid fa-arrow-right-from-bracket mr-2" />
                            저장 후 나가기
                          </span>
                        </div>
                        {item.desc && <p className="h6 mt-2">{item.desc}</p>}
                      </div>
                      <div className="col-xl-3" />
                    </div>
                    <div className="row">
                      <div className="col-xl-3" />
                      {item.component({
                        guideData: guideData,
                        loginInfo: loginInfo,
                      })}
                      <div className="col-xl-3" />
                    </div>
                  </div>
                );
              }
            })}
          <div className="border-top p-3 d-flex flex-row justify-content-between">
            {activePage !== "GuideRegistBasicInfoPlace" ? (
              <div
                className="btn_1 white border"
                onClick={() => {
                  handlePageChange("prev");
                }}
              >
                이전
              </div>
            ) : (
              <div />
            )}

            {activePage !== "GuideRegistSubmitCheck" ? (
              <div
                className="btn_1"
                onClick={() => {
                  handlePageChange("next");
                }}
              >
                다음
              </div>
            ) : (
              <div
                className="btn_1"
                onClick={() => {
                  finalSubmit();
                }}
              >
                제출
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
