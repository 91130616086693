/*global google*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import GoogleMap from "google-map-react";

import GuideRegistBasicInfoPlaceSearchBox from "./GuideRegistBasicInfoPlaceSearchBox";

import BuddibleSocket, { MsgIDList } from "../../../../../lib/BuddibleSocket";
import CodeList from "../../../../../lib/CodeList";

const socket = new BuddibleSocket();
const codeList = new CodeList();

const _mClassName = "GuideRegistBasicInfoPlace";

let isCheckLocationAccess = true;

let zoom = 14;
let point;
let myLatLng;

let apiReady = false;

let mapApi = {
  map: null,
  googleMaps: null,
  places: [],
  center: {
    lat: 0,
    lng: 0,
  },
};

export default function GuideRegistBasicInfoPlace(props) {
  const { guideData } = props;
  const [isNeedUpdate, setIsNeedUpdate] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(
    guideData.gl_addr_full || ""
  );

  let marker = null;
  let center = {
    lat: parseFloat(guideData.gl_addr_lat),
    lng: parseFloat(guideData.gl_addr_log),
  };

  useEffect(() => {
    getUserLocation();
  }, []);

  const getUserLocation = (callback) => {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          function (position) {
            point = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            callback && callback();
          },
          function (error) {
            if (error.code === 1 && (!isCheckLocationAccess || callback)) {
              codeList.Modal.current.alert(
                "우측 상단에 위치 정보 엑세스를 허용해주세요.",
                () => {
                  isCheckLocationAccess = true;
                }
              );
            } else {
              console.log("getUserLocation errorCallback", error);
            }
          },
          {
            enableHighAccuracy: false, // 배터리를 소모해 더 정확한 위치를 찾음(?)
            maximumAge: 0, // 한번 찾은 위치 정보를 해당 초만큼 캐싱
            timeout: Infinity, //주어진 초 안에 찾지 못하면 에러 발생
          }
        );
      } else {
        alert("위치 찾기 오류");
      }
    } catch (e) {
      console.log("getUserLocation error", e);
    }
  };

  // 가이드 데이터 변경
  const changedGuideData = (value) => {
    let data = {
      gl_addr_lat: value.geometry.location.lat(),
      gl_addr_log: value.geometry.location.lng(),
      gl_addr_full: value.formatted_address,

      gl_addr_name: value.name,
      gl_addr_country: "",
      gl_addr_postal_code: "",
      gl_addr_area_level_1: "",
      gl_addr_sublocality_level_1: "",
      gl_addr_sublocality_level_2: "",
      gl_addr_premise: "",
      gl_addr_place_id: value.place_id,
    };
    let address_components = value.address_components;
    if (address_components) {
      for (let i = 0; i < address_components.length; i++) {
        if (address_components[i].hasOwnProperty("types")) {
          let types = address_components[i]["types"];
          if (types.findIndex((element) => element === "premise") > -1)
            data.gl_addr_premise = address_components[i].short_name;
          if (
            types.findIndex((element) => element === "sublocality_level_2") > -1
          )
            data.gl_addr_sublocality_level_2 = address_components[i].short_name;
          if (
            types.findIndex((element) => element === "sublocality_level_1") > -1
          )
            data.gl_addr_sublocality_level_1 = address_components[i].short_name;
          if (
            types.findIndex(
              (element) => element === "administrative_area_level_1"
            ) > -1
          )
            data.gl_addr_area_level_1 = address_components[i].short_name;
          if (types.findIndex((element) => element === "country") > -1)
            data.gl_addr_country = address_components[i].short_name;
          if (types.findIndex((element) => element === "postal_code") > -1)
            data.gl_addr_postal_code = address_components[i].short_name;
        }
      }
    }

    socket.fireLocalEventListener(
      MsgIDList.EVENT_GUIDE_REGIST_DATA_CHANGE,
      _mClassName,
      data
    );

    mapApi.map.setCenter({ lat: data.gl_addr_lat, lng: data.gl_addr_log });
    setSelectedPlace(data.gl_addr_full);
  };

  // 마커 이동 콜백
  const changedGeocode = (map, maps) => {
    center = {
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    };

    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: center })
      .then((response) => {
        changedGuideData(response.results[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // mapApi.map.setCenter(center);
  };

  // 마커 위치 변경
  const changedMarker = useCallback(
    (center, map, maps) => {
      // 기존 마커 초기화
      if (marker) {
        marker.setMap(null);
      }

      marker = new maps.Marker({
        position: center,
        map,
        draggable: true,
      });
      changedGeocode(map, maps);
      marker.addListener("dragend", () => changedGeocode(map, maps));
    },
    [marker]
  );
  //지도 api 로드
  const handleApiLoaded = (map, maps) => {
    if (map && maps) {
      mapApi = { ...mapApi, map: map, googleMaps: maps };
      apiReady = true;
    }
    if (center.lat === "" || center.lng === "") center = point;

    changedMarker(center, map, maps);

    mapApi.map.setCenter(center);
    setIsNeedUpdate(!isNeedUpdate);
  };

  const addPlace = (places, latLng) => {
    if (places) {
      mapApi = { ...mapApi, places: places };
      mapApi.map.setCenter(latLng);
      mapApi.map.setZoom(18);
    }
  };

  //현재위치 보기
  const initMap = () => {
    if (point === undefined) {
      getUserLocation(() => {
        initMap();
      });
      return;
    }

    try {
      const latLng = point;
      const map = mapApi.map;
      myLatLng = latLng;
      mapApi.map.setCenter(myLatLng);
      mapApi.map.setZoom(18);

      changedMarker(latLng, map, mapApi.googleMaps);
    } catch (e) {
      console.log("initMap", e);
    }
  };

  return (
    <div className="col-xl-6">
      <div className="mb-4">
        {mapApi.map && mapApi.googleMaps && (
          <GuideRegistBasicInfoPlaceSearchBox
            guideData={guideData}
            map={mapApi.map}
            mapApi={mapApi.googleMaps}
            addPlace={addPlace}
            changedMarker={changedMarker}
          />
        )}
        <p className="mt-2">
          여행자에게 서비스를 제공하는 주 활동위치를 입력해주세요.
        </p>
        <p className="font-size-09 mt-1">ex) 대한민국 인천광역시 중구 운서동</p>
      </div>
      <div>
        <h4 className="mb-2 font-weight-bold">
          지도에서 가이드할 지역을 선택할 수 있습니다.
        </h4>
        {selectedPlace !== "" && (
          <p className="p-2 rounded border text-center font-weight-bold mb-2">
            {selectedPlace}
          </p>
        )}
        <div className="googleMap w-100 rounded border" style={{ height: 350 }}>
          <GoogleMap
            bootstrapURLKeys={{
              key: "AIzaSyDKXA_Dj4ZejIXaFA7I_p9Ry9o7c8s4QZQ",
              libraries: "places",
            }}
            defaultZoom={zoom}
            defaultCenter={center}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          />
        </div>
        <button
          className="btn_1 mt-3"
          disabled={guideData.gl_confirm_admin === "S"}
          onClick={initMap}
        >
          현재위치보기
        </button>
      </div>
    </div>
  );
}
